import React, { HTMLAttributes, useId } from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';

import { fallbackImage } from '../constants/shared.constants';
import { useModal } from '../contexts/modal.context';
import { Button } from './button.component';

const Border = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 0.5rem;
  z-index: 1;
  border: 1px solid ${(p) => p.theme.lightGray};
`;

const PreviewButton = styled.button.attrs({
  type: 'button',
  children: <span className="icon-search" />,
})`
  display: none;
  position: absolute;
  top: 0.625rem;
  right: 0.625rem;
  width: 1.875rem !important;
  height: 1.875rem !important;
  justify-content: center;
  align-items: center;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.25);
  background: ${(p) => p.theme.lightGray3};
  color: ${(p) => p.theme.black};
  font-size: 1.25rem;
  border: 0;
  border-radius: 0.5rem;
  z-index: 3;
  &:hover {
    background: ${(p) => p.theme.primary};
    color: ${(p) => p.theme.white};
    cursor: pointer;
  }
`;

const Wrapper = styled.div<{ active?: boolean; overlay?: string; disableClick?: boolean }>`
  position: relative;
  width: 7.1875rem;
  height: 10rem;
  border-radius: 0.5rem;
  & img {
    width: 100%;
    height: 100%;
    border-radius: 0.5rem;
  }

  ${({ theme, disableClick }) =>
    !disableClick &&
    css`
      &:hover {
        cursor: pointer;
        & > ${Border} {
          border: 2px solid ${theme.primary};
        }
        & > ${PreviewButton} {
          display: flex;
        }
      }
    `}

  ${({ theme, overlay }) =>
    overlay &&
    css`
      &:hover {
        &::before {
          content: '${overlay}';
          display: flex;
          justify-content: center;
          align-items: center;
          background: ${theme.infoGray};
          color: ${theme.white};
          position: absolute;
          width: 100%;
          height: 100%;
          border-radius: 0.5rem;
          font-size: 0.875rem;
          font-weight: 600;
          line-height: 1.1875rem;
          z-index: 1;
        }
      }
    `}

  ${({ theme, active }) =>
    active &&
    css`
      & > ${Border} {
        border: 2px solid ${theme.primary};
      }
    `}
`;

const TemplatePreviewWrapper = styled.div`
  position: relative;
  border-radius: 1rem;
  width: 450px;
  height: 600px;
  overflow: hidden;
  & > img {
    max-width: 100%;
  }
  & > button {
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
  }
`;

interface IProps extends HTMLAttributes<HTMLDivElement> {
  src?: string;
  overlay?: string;
  active?: boolean;
}

export const TemplateCard = ({ src, ...rest }: IProps) => {
  const { t } = useTranslation();
  const id = useId();
  const modal = useModal();

  const handleOpenPreview = (e: React.MouseEvent) => {
    e.stopPropagation();

    const content = (
      <TemplatePreviewWrapper>
        <img src={src || fallbackImage} alt={'template_preview'} />
        <Button
          onClick={(event) => {
            modal.close();
            if (rest.onClick) {
              rest.onClick(event);
            }
          }}
          icon={'check'}
        >
          {t('action.selectTemplate')}
        </Button>
      </TemplatePreviewWrapper>
    );

    modal.open(content, {
      width: 450,
      withoutPadding: true,
    });
  };

  return (
    <>
      <Wrapper disableClick={!rest.onClick} {...rest}>
        <img src={src || fallbackImage} alt={`template_${id}`} />
        <PreviewButton onClick={handleOpenPreview} />
        <Border />
      </Wrapper>
    </>
  );
};

import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import { register, registerConfirm, resendCode } from '../../requests/auth.request';
import { IUser, RegisterStep } from '../../interfaces/auth.interface';
import * as amplitude from '@amplitude/analytics-browser';
import logo from '../../assets/images/logo.svg';
import { ReactComponent as SparkleLeft } from '../../assets/images/sparkle-left.svg';
import { ReactComponent as SparkleRight } from '../../assets/images/sparkle-right.svg';
import { ReactComponent as Congrats } from '../../assets/images/congrats.svg';
import { Headline } from '../../components/headline.component';
import { Text } from '../../components/text.component';
import { Button } from '../../components/button.component';
import Input from '../../components/input.component';
import Components, { LeftColumn, RightColumn, WhiteBox, Form, DoneWrapper } from './register.style';
import { useAuth } from '../../contexts/auth.context';
import { ColumnContent } from '../login/login.style';
import CircleLoader2 from '../../components/circle-loader2';
import { useMobileCheck } from '../../contexts/mobile-check.context';
import MobileSuccess from '../mobile/components/mobile.success.comoponent';

export const RegisterPage = () => {
  const { t } = useTranslation();
  const auth = useAuth();
  const { isMobile } = useMobileCheck();
  const navigate = useNavigate();
  const [step, setStep] = useState<RegisterStep>('register');
  const [error, setError] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<string>('');
  const [nameError, setNameError] = useState<string>('');
  const [passwordError, setPasswordError] = useState<string>('');
  const [isPasswordsSame, setIsPasswordsSame] = useState<boolean>(false);
  const [codeError, setCodeError] = useState<string>('');
  const [code, setCode] = useState<string>('');
  const [repeatPassword, setRepeatPassword] = useState<string>('');
  const [form, setForm] = useState<IUser>({
    email: '',
    password: '',
    name: '',
  });
  const [registerLoading, setRegisterLoading] = useState<boolean>(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const [timer, setTimer] = useState<number>(60);
  const [isResendDisabled, setIsResendDisabled] = useState<boolean>(true);

  const handleChange = (key: keyof IUser) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setForm((prevState) => ({ ...prevState, [key]: value }));
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    if (registerLoading) return;
    event.preventDefault();
    setError(false);
    setIsPasswordsSame(false);
    setNameError('');
    setEmailError('');
    setPasswordError('');

    const isValidEmail = /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/.test(form.email);
    const isCiryllicPassword = /[а-яА-Я]/g.test(form.password);
    if (!form.name) {
      setNameError('Заполните ФИО');
      return;
    }
    if (!form.email || !isValidEmail) {
      setEmailError('Введите валидный email');
      return;
    }
    if (isCiryllicPassword) {
      setPasswordError('Пароль должен содержать латинские буквы');
      setIsPasswordsSame(false);
      return;
    }
    if (repeatPassword !== form.password) {
      setIsPasswordsSame(true);
      setPasswordError('');
      return;
    }
    switch (step) {
      case 'register': {
        handleRegister();
        break;
      }
      case 'confirm': {
        handleRegisterConfirm();
        break;
      }
    }
  };

  const handleRegister = () => {
    setRegisterLoading(true);
    register({ ...form, email: form.email.toLocaleLowerCase() })
      .then(() => {
        setStep('confirm');
        setRegisterLoading(false);
      })
      .catch(() => {
        setError(true);
        setRegisterLoading(false);
      });
  };

  const handleRegisterConfirm = () => {
    if (registerLoading) return;
    setRegisterLoading(true);

    const invitationCode = searchParams.get('invitationCode');

    registerConfirm({ email: form.email, code: code, invitationCode })
      .then(() => {
        setStep('done');
        if (!isMobile) {
          setTimeout(
            () => auth.signIn(form, () => navigate('/my-projects', { replace: true })),
            5000,
          );
        }
      })
      .catch((error) => {
        setCodeError(error.response.data.msg);
        if (error.response.data.msg === 'Срок действия кода истек') {
          setTimer(0);
          setIsResendDisabled(false);
        }
      })
      .finally(() => setRegisterLoading(false));
  };

  const handleLoginClick = () => {
    amplitude.track('ecom_webapp_register_click');
    navigate('/login');
  };

  const handleResendClick = () => {
    resendCode(form.email.toLocaleLowerCase()).then(() => {
      setIsResendDisabled(true);
      setTimer(60);

      setTimeout(() => {
        setIsResendDisabled(false);
      }, 60000);
    });
  };

  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    if (step === 'confirm') {
      if (timer > 0 && isResendDisabled) {
        intervalId = setInterval(() => {
          setTimer((prevTimer) => prevTimer - 1);
        }, 1000);
      } else {
        setIsResendDisabled(false);
      }
    }

    return () => clearInterval(intervalId);
  }, [timer, isResendDisabled, step]);

  useEffect(() => {
    amplitude.track('ecom_webapp_register_page_in');
  }, []);

  return (
    <Components.Wrapper>
      {step === 'register' && (
        <>
          <LeftColumn isMobile={isMobile}>
            <img src={logo} alt={'mark it'} />
            <WhiteBox isMobile={isMobile}>
              <Headline>{t('register.title')}</Headline>
              <Form onSubmit={handleSubmit}>
                <Text color={'gray'} fontSize="14px">
                  {t('form.required')}
                </Text>
                <Input
                  label="ФИО"
                  type="name"
                  error={!!nameError}
                  errorText={nameError}
                  value={form.name}
                  onChange={handleChange('name')}
                  showButtons={false}
                />
                <Input
                  label="Email"
                  type="email"
                  error={!!emailError}
                  errorText={emailError}
                  value={form.email}
                  onChange={handleChange('email')}
                  showButtons={false}
                />
                {error && <Text color="black">{t('register.emailexist')}</Text>}
                <Input
                  label="Пароль"
                  type="password"
                  minlength={5}
                  error={!!passwordError}
                  errorText={passwordError}
                  value={form.password}
                  onChange={handleChange('password')}
                  showButtons={false}
                  maxlength={20}
                />
                <Input
                  label="Повторите пароль"
                  name="repeatPassword"
                  type="password"
                  error={!!isPasswordsSame}
                  errorText={'Пароли не совпадают. Проверьте правильность.'}
                  value={repeatPassword}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setRepeatPassword(e.target.value)
                  }
                  showButtons={false}
                  maxlength={20}
                />
                <Button type="submit" onClick={() => amplitude.track('ecom_webapp_login_click')}>
                  {registerLoading ? <CircleLoader2 /> : t('register.action')}
                </Button>
                <Components.PrivacyPolicy>
                  <Trans i18nKey="register.privacyPolicy">
                    Нажимая на кнопку, вы даете согласие на обработку персональных данных и
                    соглашаетесь c
                    <a href={'/policy'} target="_blank" rel="noreferrer">
                      политикой конфиденциальности
                    </a>
                  </Trans>
                </Components.PrivacyPolicy>
              </Form>
            </WhiteBox>
          </LeftColumn>
          {!isMobile && (
            <RightColumn>
              <ColumnContent>
                <Headline color={'white'} mb={16}>
                  {t('login.welcome')}
                </Headline>
                <Text color={'white'}>{t('login.description')}</Text>
                <Components.SparkleWrapper>
                  <SparkleLeft />
                  <Button btnStyle={'inverse'} onClick={handleLoginClick}>
                    {t('login.action')}
                  </Button>
                  <SparkleRight />
                </Components.SparkleWrapper>
              </ColumnContent>
            </RightColumn>
          )}
        </>
      )}
      {step === 'confirm' && (
        <>
          <img
            src={logo}
            alt={'mark it'}
            height={'62.04px'}
            width={'98.18px'}
            style={{ position: 'absolute', left: '20px', top: '18px' }}
          />
          <WhiteBox>
            <Headline>{t('register.confirmEmail')}</Headline>
            <Form onSubmit={handleSubmit}>
              <Input
                label="Код подтверждения"
                type="code"
                value={code}
                error={!!codeError}
                errorText={codeError}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setCodeError('');
                  setCode(e.target.value);
                }}
                showButtons={false}
                name="code"
              />
              <Button
                btnStyle="gray"
                onClick={handleResendClick}
                disabled={isResendDisabled}
                className={isResendDisabled ? 'inactive' : ''}
              >
                {!isResendDisabled
                  ? t('register.resend')
                  : t('register.requestResend', { sec: timer })}
              </Button>
              <Text style={{ wordWrap: 'break-word' }}>
                {t('register.confirm', { email: form.email })}
              </Text>
              <Button
                type="submit"
                onClick={() => amplitude.track('ecom_webapp_login_click')}
                disabled={!code || !!codeError}
                isLoading={registerLoading}
              >
                {t('register.sendCode')}
              </Button>
            </Form>
          </WhiteBox>
        </>
      )}
      {step === 'done' &&
        (isMobile ? (
          <MobileSuccess success />
        ) : (
          <>
            <img
              src={logo}
              alt={'mark it'}
              height={'62.04px'}
              width={'98.18px'}
              style={{ position: 'absolute', left: '20px', top: '18px' }}
            />
            <WhiteBox>
              <Headline>{t('register.title')}</Headline>
              <DoneWrapper>
                <Congrats />
                <Text>{t('register.done')}</Text>
              </DoneWrapper>
            </WhiteBox>
          </>
        ))}
    </Components.Wrapper>
  );
};

import React from 'react';
import styled from 'styled-components';
import SkeletonLoader from '../../../components/skeleton-loader.component';
import { Flex } from '../../../components/flexbox.component';
import {
  MarketPlaceType,
  TImageDetail,
  TProductPageMobile,
} from '../../../interfaces/preview-image.interface';
import { LabelFromOzon, LabelsFromOzon, StyledIconHeart } from '../preview-image.style';
import WildberriesLoaderMobile from './wildberries-loader-mobile';
import YandexLoaderMobile from './yandex-loader-mobile';
import OzonLoaderMobile from './ozon-loader-mobile';
import YandexLoaderMobile2 from './yandex-loader-mobile-2';
import NoPreview from '../components/no-preview';

const ImageListMobileWrapper = styled.div`
  width: 320px;
  height: 568px;
  background-color: #fff;
  box-shadow: 0 6px 20px 0 #00000026;
  display: flex;
  align-items: center;
  margin: 92px auto;
  flex-direction: column;
  flex-wrap: wrap;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 0;
  }
  -ms-overflow-style: none;
`;

const ImageListMobile: React.FC<TProductPageMobile> = ({
  imagesSrc,
  yandexType,
  marketPlace,
  imageDetails,
  loadingImages,
  canvasRatio,
  ozonType
}) => {
  const imagesHeight = imageDetails
    .map((image: TImageDetail) => image.details?.height)
    .filter((height: number | undefined) => height !== undefined);

  const marketPlaceGap = (marketPlace: MarketPlaceType) => {
    switch (marketPlace) {
      case 'Wildberries':
        return '20px 5px';
      case 'YandexMarket':
        return yandexType === 'Тип 1' ? '15px 6px' : '30px 6px';
      case 'Ozon':
        return '10px 7px';
      default:
        return '';
    }
  };

  const isSquaredWB = () => canvasRatio === 'square' && marketPlace === 'Wildberries';
  const isSquaredOzon = () => canvasRatio === 'square' && marketPlace === 'Ozon' && ozonType === 'Тип 2';

  return !loadingImages ? (
    <ImageListMobileWrapper>
      <Flex
        wrapItems
        style={{ gap: marketPlaceGap(marketPlace), margin: '10px 0' }}
        justifyContent="center"
      >
        {Array.from({ length: 8 }).map((_, idx) => (
          <>
            <div
              key={idx}
              style={{
                display: 'flex',
                gap: marketPlaceGap(marketPlace),
                flexDirection: idx % 2 === 0 ? 'row-reverse' : 'row',
              }}
            >
              <div>
                <div
                  style={{
                    width:
                      marketPlace === 'YandexMarket'
                        ? yandexType === 'Тип 1'
                          ? '145px'
                          : '145px'
                        : marketPlace === 'Ozon'
                          ? canvasRatio === 'square' && ozonType === 'Тип 1'
                            ? "auto"
                            : '150px'
                          : '150px',
                    height:
                      marketPlace === 'YandexMarket'
                        ? yandexType === 'Тип 1'
                          ? '193px'
                          : '145px'
                        : marketPlace === 'Ozon'
                          ? ozonType === 'Тип 1'
                            ? '150px'
                            : '200px'
                          : '200px',
                    borderRadius: ozonType === 'Тип 1' ? '10px' : '10px 10px 0px 0px',
                    position: 'relative',
                    background: isSquaredWB() || isSquaredOzon() ? "none" : '#f5f5f5',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  {
                    isSquaredWB() || isSquaredOzon()
                      ? <NoPreview style={{ borderRadius: marketPlace === 'Ozon' && isSquaredOzon() ? '10px 10px 0px 0px' : '10px' }} text={marketPlace} />
                      : <img
                        src={imagesSrc[imageDetails[0].modifiedImageId]}
                        alt={`${marketPlace}_image`}
                        style={{
                          borderRadius:
                            marketPlace === 'Ozon'
                              ? canvasRatio === 'square' && ozonType === 'Тип 1'
                                ? '10px'
                                : ozonType === 'Тип 2'
                                  ? '10px 10px 0 0'
                                  : '0px'
                              : marketPlace === 'Wildberries'
                                ? '10px'
                                : '0px',
                          width:
                            marketPlace === 'YandexMarket'
                              ? yandexType === 'Тип 1'
                                ? canvasRatio === 'square'
                                  ? '145px'
                                  : '139px'
                                : canvasRatio === 'square'
                                  ? '137px'
                                  : '103px'
                              : marketPlace === 'Ozon'
                                ? canvasRatio === 'square'
                                  ? '145px'
                                  : 'auto'
                                : '100%',
                          height:
                            marketPlace === 'YandexMarket'
                              ? yandexType === 'Тип 1'
                                ? canvasRatio === 'square'
                                  ? '145px'
                                  : '185px'
                                : '137px'
                              : marketPlace === 'Wildberries' && canvasRatio === 'square'
                                ? '150px'
                                : '100%',
                        }}
                      />
                  }

                  {!isSquaredWB() && marketPlace === 'Wildberries' && (
                    <LabelsFromOzon left={6} bottom={6} start gap={1}>
                      <>
                        <LabelFromOzon fontSize={6} br={4} paddingX={3} paddingY={2} lineHeightNone>
                          ТУТ МОГУТ
                        </LabelFromOzon>
                        <LabelFromOzon fontSize={6} br={4} paddingX={3} paddingY={2} lineHeightNone>
                          БЫТЬ ЛЕЙБЛЫ
                        </LabelFromOzon>
                      </>
                    </LabelsFromOzon>
                  )}
                  {!isSquaredOzon() && marketPlace === 'Ozon' && (
                    <LabelsFromOzon left={3} bottom={3} start gap={1}>
                      <>
                        <LabelFromOzon lineHeightNone fontSize={6} br={4} paddingX={3} paddingY={2}>
                          ТУТ МОГУТ
                        </LabelFromOzon>
                        <LabelFromOzon lineHeightNone fontSize={6} br={4} paddingX={3} paddingY={2}>
                          БЫТЬ ЛЕЙБЛЫ
                        </LabelFromOzon>
                      </>
                    </LabelsFromOzon>
                  )}
                  {marketPlace === 'YandexMarket' && (
                    <LabelsFromOzon left={6} bottom={4} start gap={1}>
                      <LabelFromOzon br={4} lineHeightNone fontSize={6} paddingX={3} paddingY={2}>
                        СКИДКА
                      </LabelFromOzon>
                    </LabelsFromOzon>
                  )}
                  {!isSquaredWB() && !isSquaredOzon() && <StyledIconHeart top="6px" right="6px" />}
                  {/* <StyledIconHeart bottom="6px" right="6px" /> */}
                </div>
                {marketPlace === 'Wildberries' && <WildberriesLoaderMobile noneAnimation />}
                {marketPlace === 'YandexMarket' && yandexType === 'Тип 1' ? (
                  <YandexLoaderMobile noneAnimation />
                ) : (
                  yandexType === 'Тип 2' &&
                  marketPlace === 'YandexMarket' && <YandexLoaderMobile2 noneAnimation />
                )}
                {marketPlace === 'Ozon' && <OzonLoaderMobile noneAnimation ozonType={ozonType} />}
              </div>
              {marketPlace === 'Wildberries' ? (
                <>
                  <Flex
                    wrapItems
                    style={{ gap: marketPlaceGap(marketPlace) }}
                    justifyContent="center"
                  >
                    {Array.from({ length: 1 }).map((_, idx) => (
                      <div key={idx}>
                        <>
                          {isSquaredWB()
                            ? <NoPreview style={{ width: '150px', height: '200px', borderRadius: '10px' }} text={marketPlace} />
                            : <SkeletonLoader noneAnimation width="150px" height="200px" />}
                        </>
                        <WildberriesLoaderMobile noneAnimation />
                      </div>
                    ))}
                  </Flex>
                </>
              ) : marketPlace === 'Ozon' ? (
                <Flex
                  wrapItems
                  style={{ gap: marketPlaceGap(marketPlace), margin: marketPlace === 'Ozon' ? '0' : '10px 0' }}
                  justifyContent="center"
                >
                  {Array.from({ length: 1 }).map((_, idx) => (
                    <div key={idx}>
                      <Flex
                        style={{
                          width: '150px',
                          height: ozonType === 'Тип 2' ? '200px' : '150px',
                          background: 'none',
                          borderRadius: ozonType === 'Тип 2' ? ' 10px 10px 0 0' : '10px',
                        }}
                        alignItems="center"
                        justifyContent="center"
                      >
                        {
                          isSquaredOzon()
                            ? <NoPreview style={{ width: '150px', height: '200px', borderRadius: '10px 10px 0 0' }} text={marketPlace} />
                            : <SkeletonLoader
                              width="100%"
                              height={ozonType === 'Тип 2' ? '200px' : '150px'}
                              borderRadius={ozonType === 'Тип 2' ? ' 10px 10px 0 0' : '10px'}
                              noneAnimation
                            />
                        }
                      </Flex>
                      <OzonLoaderMobile noneAnimation ozonType={ozonType} />
                    </div>
                  ))}
                </Flex>
              ) : marketPlace === 'YandexMarket' ? (
                <Flex
                  wrapItems
                  style={{ gap: marketPlaceGap(marketPlace), margin: '0' }}
                  justifyContent="center"
                >
                  {Array.from({ length: 1 }).map((_, idx) => (
                    <div key={idx}>
                      <Flex
                        style={{
                          width: '145px',
                          height: yandexType === 'Тип 1' ? '193px' : '145px',
                          background: '#F5F5F5',
                          borderRadius: '10px',
                        }}
                        alignItems="center"
                        justifyContent="center"
                      >
                        <SkeletonLoader
                          width={yandexType === 'Тип 1' ? canvasRatio === 'square' ? '145px' : '137px' : canvasRatio === 'square' ? '137px' : '103px'}
                          height={yandexType === 'Тип 1' ? canvasRatio === 'square' ? '145px' : '185px' : '137px'}
                          borderRadius="0"
                          noneAnimation
                        />
                      </Flex>
                      {yandexType === 'Тип 1' ? (
                        <YandexLoaderMobile noneAnimation />
                      ) : (
                        <YandexLoaderMobile2 noneAnimation />
                      )}
                    </div>
                  ))}
                </Flex>
              ) : null}
            </div>

            {/* {marketPlace === 'Wildberries' ? (
              <Flex wrapItems style={{ gap: marketPlaceGap(marketPlace) }} justifyContent="center">
                {Array.from({ length: 2 }).map((_, idx) => (
                  <div key={idx}>
                    <SkeletonLoader noneAnimation width="150px" height="200px" />
                    <WildberriesLoaderMobile noneAnimation />
                  </div>
                ))}
              </Flex>
            ) : marketPlace === 'Ozon' ? (
              <Flex
                wrapItems
                style={{ gap: marketPlaceGap(marketPlace), marginTop: '10px' }}
                justifyContent="center"
              >
                {Array.from({ length: 2 }).map((_, idx) => (
                  <div key={idx}>
                    <Flex
                      style={{
                        width: '142px',
                        height: '142px',
                        background: '#F5F5F5',
                        borderRadius: '10px',
                      }}
                      alignItems="center"
                      justifyContent="center"
                    >
                      <SkeletonLoader
                        noneAnimation
                        width="106.67px"
                        height="142px"
                        borderRadius="0"
                      />
                    </Flex>
                    <OzonLoaderMobile noneAnimation />
                  </div>
                ))}
              </Flex>
            ) : marketPlace === 'YandexMarket' ? (
              <Flex
                wrapItems
                style={{ gap: marketPlaceGap(marketPlace), margin: '10px 0' }}
                justifyContent="center"
              >
                {Array.from({ length: 2 }).map((_, idx) => (
                  <div key={idx}>
                    <Flex
                      style={{
                        width: '145px',
                        height: yandexType === 'Тип 1' ? '193px' : '145px',
                        background: '#F5F5F5',
                        borderRadius: '10px',
                      }}
                      alignItems="center"
                      justifyContent="center"
                    >
                      <SkeletonLoader
                        width={yandexType === 'Тип 1' ? '139px' : '103px'}
                        height={yandexType === 'Тип 1' ? '185px' : '137px'}
                        borderRadius="0"
                        noneAnimation
                      />
                    </Flex>
                    {yandexType === 'Тип 1' ? (
                      <YandexLoaderMobile noneAnimation />
                    ) : (
                      <YandexLoaderMobile2 noneAnimation />
                    )}
                  </div>
                ))}
              </Flex>
            ) : null} */}
          </>
        ))}
      </Flex>
    </ImageListMobileWrapper>
  ) : (
    <ImageListMobileWrapper>
      {marketPlace === 'Wildberries' ? (
        <Flex
          wrapItems
          style={{ gap: marketPlaceGap(marketPlace), margin: '10px 0' }}
          justifyContent="center"
        >
          {Array.from({ length: 6 }).map((_, idx) => (
            <div key={idx}>
              <SkeletonLoader width="150px" height="200px" />
              <WildberriesLoaderMobile />
            </div>
          ))}
        </Flex>
      ) : marketPlace === 'Ozon' ? (
        <Flex
          wrapItems
          style={{ gap: marketPlaceGap(marketPlace), margin: '10px 0' }}
          justifyContent="center"
        >
          {Array.from({ length: 6 }).map((_, idx) => (
            <div key={idx}>
              <Flex
                style={{
                  width: '150px',
                  height: '150px',
                  background: '#F5F5F5',
                  borderRadius: '10px',
                }}
                alignItems="center"
                justifyContent="center"
              >
                <SkeletonLoader width="106.67px" height="150px" borderRadius="0" />
              </Flex>
              <OzonLoaderMobile />
            </div>
          ))}
        </Flex>
      ) : marketPlace === 'YandexMarket' ? (
        yandexType === 'Тип 1' ? (
          <Flex
            wrapItems
            style={{ gap: marketPlaceGap(marketPlace), margin: '10px 0' }}
            justifyContent="center"
          >
            {Array.from({ length: 6 }).map((_, idx) => (
              <div key={idx}>
                <Flex
                  style={{
                    width: '145px',
                    height: '193px',
                    background: '#F5F5F5',
                    borderRadius: '10px',
                  }}
                  alignItems="center"
                  justifyContent="center"
                >
                  <SkeletonLoader width="139px" height="185px" borderRadius="0" />
                </Flex>
                <YandexLoaderMobile />
              </div>
            ))}
          </Flex>
        ) : (
          <Flex
            wrapItems
            style={{ gap: marketPlaceGap(marketPlace), margin: '10px 0' }}
            justifyContent="center"
          >
            {Array.from({ length: 6 }).map((_, idx) => (
              <div key={idx}>
                <Flex
                  style={{
                    width: '145px',
                    height: '145px',
                    background: '#F5F5F5',
                    borderRadius: '10px',
                  }}
                  alignItems="center"
                  justifyContent="center"
                >
                  <SkeletonLoader width="103px" height="137px" borderRadius="0" />
                </Flex>
                <YandexLoaderMobile2 />
              </div>
            ))}
          </Flex>
        )
      ) : null}
    </ImageListMobileWrapper>
  );
};

export default ImageListMobile;

import React, { useCallback, useEffect, useState } from 'react';
import { fabric } from 'fabric';
import FabricTypes from 'fabric/fabric-impl';

import { useCanvasHistory } from '../../contexts/canvas-history.context';
import useDebounce from '../../hooks/use-debounce.hooks';
import { ToolWrapper } from './object-toolbar.style';
import { IBasicEditorProps } from '../../interfaces/editor.interface';
import { Range } from '../range.component';
import { AngleInput } from '../angle-input.component';
import { useCanvasFocus } from '../../contexts/canvas-focus.context';
import { getHiddenShapeImage } from '../../utils/editor.utils';

export const RotateTool = ({
  editor,
  activeObject,
  angleOutside,
  setAngleOutside,
  isImageInShape,
}: IBasicEditorProps & {
  angleOutside?: string;
  setAngleOutside?: (value: string) => void;
  isImageInShape?: boolean;
}) => {
  const { historySaveAction } = useCanvasHistory();
  const { setIsInputFocused } = useCanvasFocus();

  const [didMount, setDidMount] = useState(false);
  const [angle, setAngle] = useState(
    activeObject?.angle ? `${Math.round(activeObject?.angle * 100) / 100}` : '0',
  );
  const currentAngle = angleOutside || angle;
  const setCurrentAngle = setAngleOutside || setAngle;
  const debouncedAngle = useDebounce(currentAngle, 1000);

  useEffect(() => setDidMount(true), []);

  const handleChangeAngle = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value !== '' ? Math.round(+event.target.value * 100) / 100 : 0;
    if (value > 360) {
      setCurrentAngle('360');
    } else if (value < 0) {
      setCurrentAngle('0');
    } else {
      setCurrentAngle(`${value}`);
    }
  };

  const changeObjectAngle = useCallback(() => {
    if (didMount && editor && activeObject) {
      const { canvas } = editor;
      const imageObject = isImageInShape && getHiddenShapeImage(canvas, activeObject);
      if (imageObject) {
        const imgWidth = imageObject.width as number;
        const imgHeight = imageObject.height as number;

        const radians = fabric.util.degreesToRadians(+currentAngle);
        const cos = Math.cos(radians);
        const sin = Math.sin(radians);
        const offsetX = imgWidth / 2;
        const offsetY = imgHeight / 2;

        const transformMatrix = [
          cos,
          sin,
          -sin,
          cos,
          offsetX - cos * offsetX + sin * offsetY,
          offsetY - sin * offsetX - cos * offsetY,
        ];
        const pattern = activeObject.get('fill') as FabricTypes.Pattern;
        pattern.setOptions({ ...pattern, patternTransform: transformMatrix });
        activeObject.set('fill', pattern);
      } else {
        activeObject.rotate(+currentAngle);
      }
      canvas.requestRenderAll();
    }
  }, [currentAngle, isImageInShape]);

  useEffect(changeObjectAngle, [currentAngle, changeObjectAngle]);

  useEffect(() => {
    if (didMount) {
      historySaveAction(editor);
    }
  }, [debouncedAngle]);

  return (
    <ToolWrapper>
      <AngleInput
        value={currentAngle}
        onChange={handleChangeAngle}
        onFocus={() => setIsInputFocused(true)}
        onBlur={() => setIsInputFocused(false)}
      />
      <Range min={0} max={360} step={45} value={currentAngle} onChange={handleChangeAngle} />
    </ToolWrapper>
  );
};

import styled, { css } from 'styled-components';

export const ToolbarBtn = styled.button.attrs({ type: 'button' })<{
  active?: boolean;
  light?: boolean;
  shadow?: boolean;
}>`
  ${({ shadow }) =>
    shadow &&
    css`
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
    `}

  position: relative;
  padding: 0.9375rem;
  background-color: ${(p) => (p.light ? p.theme.white : p.theme.lightGray2)};
  color: ${(p) => p.theme.black};
  border: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background-color 0.2s linear, color 0.2s linear;
  & > span {
    font-size: 1.25rem;
  }
  &:disabled {
    & > * {
      opacity: 0.2;
    }
  }
  &:hover:not(:disabled) {
    cursor: pointer;
    background-color: ${({ light, theme }) => (light ? theme.lightPrimary : theme.primary)};
    color: ${({ light, theme }) => (light ? theme.primary : theme.white)};
    &::after {
      opacity: 0;
    }
  }
  ${({ active, theme, light }) =>
    active &&
    css`
      background-color: ${light ? theme.lightPrimary : theme.primary};
      color: ${light ? theme.primary : theme.white};
      &::after {
        opacity: 0;
      }
    `};
`;

export const ToolbarWrapper = styled.div<{ align?: string; light?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  box-shadow: ${(p) =>
    p.light ? '0 6px 20px rgba(0, 0, 0, 0.15)' : '0 1px 2px rgba(0, 0, 0, 0.25)'};
  border-radius: 1rem;
  & > * {
    &:not(:first-child) {
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 9px;
        display: block;
        height: 1px;
        width: calc(100% - 18px);
        background: ${(p) => (p.light ? p.theme.lightGray2 : '#b3b3b3')};
      }
    }
    &.active,
    &:hover {
      &::after {
        opacity: 0;
      }
      & + * {
        &::after {
          opacity: 0;
        }
      }
    }
    &:disabled {
      &:hover {
        &::after {
          opacity: 1;
        }
        & + * {
          &::after {
            opacity: 1;
          }
        }
      }
    }
  }
  & > * {
    &:first-child {
      border-top-left-radius: 1rem;
      border-top-right-radius: 1rem;
      & ${ToolbarBtn} {
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;
      }
    }
    &:last-child {
      border-bottom-left-radius: 1rem;
      border-bottom-right-radius: 1rem;
      & ${ToolbarBtn} {
        border-bottom-left-radius: 1rem;
        border-bottom-right-radius: 1rem;
      }
    }
  }
  ${(p) =>
    p.align === 'left' &&
    css`
      position: absolute;
      top: 0;
      left: 1.25rem;
    `};
  ${(p) =>
    p.align === 'right' &&
    css`
      position: absolute;
      top: 0;
      right: 1.25rem;
    `};
`;

export const ToolbarRow = styled(ToolbarWrapper)`
  flex-direction: row;
  & > * {
    border-radius: 0 !important;
    &:first-child {
      border-radius: 1rem 0 0 1rem !important;
      & ${ToolbarBtn} {
        border-radius: 1rem 0 0 1rem !important;
      }
    }
    &:last-child {
      border-radius: 0 1rem 1rem 0 !important;
      & ${ToolbarBtn} {
        border-radius: 0 1rem 1rem 0 !important;
      }
    }
    &:only-child {
      border-radius: 1rem !important;
    }
    &:not(:first-child) {
      &::after {
        top: 9px;
        left: 0;
        height: calc(100% - 18px);
        width: 1px;
      }
    }
  }
`;

export const ThumbnailRow = styled.div<{
  overflowNone?: boolean;
  heightPX?: number;
  bottomSpace?: boolean;
  isHighlighting?: boolean;
}>`
  display: flex;
  flex-wrap: wrap;
  gap: 1.0625rem;

  overflow: ${(p) => (p.overflowNone ? 'none' : 'auto')};
  margin: -1px -12px -1px -1px;
  padding: 1px;

  ${({ bottomSpace }) =>
    bottomSpace &&
    css`
      padding-bottom: 2.5rem;
    `}

  ${({ isHighlighting, heightPX }) =>
    !isHighlighting
      ? css`
          max-height: ${heightPX ? heightPX : '360'}px;
        `
      : css`
          max-height: calc(${heightPX ? heightPX : '360'}px - 71px);
        `}
`;

export const SearchWrapper = styled.div<{ isSearchFocused: boolean; noneFullWidth?: boolean }>`
  max-height: 50px;
  z-index: 5;
  transition: width 0.3s ease-in-out, margin-left 0.3s ease-in-out;

  ${(p) =>
    p.isSearchFocused
      ? !p.noneFullWidth &&
        `
    margin-left: -162px;
    width: 332px;
  `
      : !p.noneFullWidth &&
        `margin-left: 8px;
  width: 100%;`}

  ${(p) => p.noneFullWidth && `width: 100%;`}
`;

export const Thumbnail = styled.div<{ active?: boolean; src?: string; type?: string }>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4.375rem;
  height: 4.375rem;
  background: ${(p) => p.theme.white};
  border-radius: 8px;
  border: 1px solid ${(p) => (p.active ? p.theme.primary : p.theme.lightGray)};
  outline: 1px solid ${(p) => (p.active ? p.theme.primary : 'transparent')};
  font-size: 1.25rem;
  color: ${(p) => p.theme.black};
  cursor: pointer;
  &:hover {
    border-color: ${(p) => p.theme.primary};
    outline-color: ${(p) => p.theme.primary};
  }
  ${({ src }) =>
    src &&
    css`
      background: url(${src}) center center / contain no-repeat,
        repeating-conic-gradient(#ccc 0% 25%, transparent 0% 50%) 50% / 10px 10px;
    `};

  ${({ type }) =>
    type === 'error' &&
    css`
      border: 1px solid rgba(199, 32, 82, 0.2);
      cursor: default;
      &:hover {
        border: 1px solid rgba(199, 32, 82, 0.2);
        outline: 0;
      }
    `}
`;

import React, { useId } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import CircleLoader2 from './circle-loader2';

const Label = styled.label<{ withoutMargin?: boolean; flexible?: boolean; dragOver?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: ${(p) => (p.flexible ? '1' : '')};
  width: ${(p) => (p.flexible ? '' : '20.75rem')};
  padding: 0.8125rem 1.25rem;
  background: ${(p) => p.theme.primary};
  color: ${(p) => p.theme.white};
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5em;
  cursor: pointer;
  transition: background-color 250ms linear, color 250ms linear;
  margin-bottom: ${(p) => (p.withoutMargin ? '' : '1.25em')};
  &:hover {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
      ${(p) => p.theme.primary};
  }
  & span[class^='icon-'] {
    margin-left: 0.5rem;
    font-size: 1.25rem;
  }

  ${(p) =>
    p.dragOver &&
    `
    background: transparent;
    color: ${p.theme.primary};
    outline: 2px dashed ${p.theme.primary};
  `}
`;

interface IProps {
  handleUpload: (event: React.ChangeEvent<HTMLInputElement>) => void;
  accept?: string;
  multiple?: boolean;
  btnText?: string;
  withoutMargin?: boolean;
  flexible?: boolean;
  dragOver?: boolean;
  isLoading?: boolean;
}

export const UploadButton = ({
  handleUpload,
  accept = '.jpeg, .jpg, .png',
  multiple = false,
  btnText,
  withoutMargin = false,
  flexible = false,
  dragOver = false,
  isLoading,
}: IProps) => {
  const { t } = useTranslation();
  const id = useId();

  return (
    <>
      <input
        id={`imageInput_${id}`}
        style={{ display: 'none' }}
        type="file"
        multiple={multiple}
        accept={accept}
        onChange={handleUpload}
      />
      <Label
        htmlFor={`imageInput_${id}`}
        withoutMargin={withoutMargin}
        flexible={flexible}
        dragOver={dragOver}
      >
        {isLoading ? (
          <CircleLoader2 />
        ) : (
          <>
            {t(btnText ?? 'action.uploadImage')}
            <span className="icon-upload" />
          </>
        )}
      </Label>
    </>
  );
};

import React, { useEffect, useState, SetStateAction } from 'react';
import styled, { css } from 'styled-components';
import { fabric } from 'fabric';
import { useTranslation } from 'react-i18next';
import FabricTypes from 'fabric/fabric-impl';
import { IBasicEditorProps } from '../interfaces/editor.interface';
import { Checkbox } from './checkbox.component';
import { Range } from './range.component';
import { IMAGE_FILTERS, INITIAL_ENABLE_FILTER } from '../constants';

const Filters = styled.div<{ modalFilter?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 200px;
  box-shadow: ${(p) => (p.modalFilter ? 'none' : '0 6px 20px rgba(0, 0, 0, 0.15)')};
  border-radius: 1rem !important;
  background: ${(p) => (p.modalFilter ? 'none' : p.theme.white)};
`;

const FilterItem = styled.div<{ modalFilter?: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 0.625rem 0.9375rem;
  gap: 10px;

  ${(p) =>
    !p.modalFilter &&
    css`
      &:not(:last-child) {
        border-bottom: 1px solid ${(p) => p.theme.lightGray2};
      }
      & > input[type='range'] {
        margin: 0.8125rem 0 0.3125rem;
      }
    `};
`;

export const ImageFilters = ({
  editor,
  activeObject,
  modalFilter,
  filter,
  setFilter,
}: IBasicEditorProps & {
  modalFilter?: boolean;
  filter: { [key: string]: number | number[] };
  setFilter: React.Dispatch<SetStateAction<{ [key: string]: number | number[] }>>;
}) => {
  const { t } = useTranslation();
  const [didMount, setDidMount] = useState(false);
  const [enableFilter, setEnableFilter] = useState<{ [key: string]: boolean }>({
    ...INITIAL_ENABLE_FILTER,
  });

  useEffect(() => setDidMount(true), []);

  useEffect(() => {
    const obj: any = activeObject;

    if (obj?.filters) {
      setEnableFilter(INITIAL_ENABLE_FILTER);
      obj.filters.forEach((f: any) => {
        Object.keys(f).some((key) => {
          if (key in filter) {
            setFilter((prevState) => ({
              ...prevState,
              [key]: f[key],
            }));

            setEnableFilter((prevState) => ({
              ...prevState,
              [key]: key === 'gamma' ? f[key] && f[key][0] !== 1 : f[key] !== 0,
            }));
          }
        });
      });
    }

    if (obj?.get('opacity') < 1) {
      setFilter((prevState) => ({
        ...prevState,
        opacity: obj.get('opacity'),
      }));
      setEnableFilter((prevState) => ({
        ...prevState,
        opacity: obj.get('opacity') < 1,
      }));
    }
  }, [activeObject]);

  const handleToggleFilter =
    (key: (typeof IMAGE_FILTERS)[number]) =>
    ({ target }: React.ChangeEvent<HTMLInputElement>) => {
      setEnableFilter((prevState) => ({ ...prevState, [key]: target.checked }));
      if (!target.checked) {
        switch (key) {
          case 'gamma':
            setFilter((prevState) => ({
              ...prevState,
              [key]: [1, 1, 1],
            }));
            break;
          case 'opacity':
            setFilter((prevState) => ({
              ...prevState,
              [key]: activeObject?.get('opacity') as number,
            }));
            break;
          default:
            setFilter((prevState) => ({
              ...prevState,
              [key]: 0,
            }));
            break;
        }
        removeFilter(key);
        return;
      }

      if (activeObject && key === 'opacity') {
        activeObject.set('opacity', filter.opacity as number);
        editor.canvas.renderAll();
      } else if (key === 'gamma') {
        applyGamma();
      } else {
        applyFilter(key)();
      }
    };

  const getFilterEntity = (key: string) => {
    switch (key) {
      case 'blur':
        return fabric.Image.filters.Blur;
      case 'brightness':
        return fabric.Image.filters.Brightness;
      case 'contrast':
        return fabric.Image.filters.Contrast;
      case 'saturation':
        return fabric.Image.filters.Saturation;
      default:
        return fabric.Image.filters.BaseFilter;
    }
  };

  const applyFilter = (key: (typeof IMAGE_FILTERS)[number]) => () => {
    if (editor && didMount) {
      const { canvas } = editor;

      const object: any = activeObject;
      if (object?.filters) {
        const filterEntity: any = getFilterEntity(key);
        const filterWithOptions = new filterEntity({ [key]: filter[key] });
        const index = object.filters.findIndex((f: any) => f[key] !== undefined);
        if (index > -1) {
          object.filters[index] = filterWithOptions;
        } else {
          object.filters.push(filterWithOptions);
        }

        if (canvas._objects[0] && canvas._objects[0].width) {
          (canvas._objects[0] as FabricTypes.Image).applyFilters();
        }
        object.applyFilters();
        canvas.renderAll();
      }
    }
  };

  const applyGamma = () => {
    if (editor && didMount) {
      const { canvas } = editor;
      const object: any = activeObject;

      if (object?.filters) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const filterWithOptions = new fabric.Image.filters.Gamma({ gamma: filter.gamma });
        const index = object.filters.findIndex((f: any) => f['gamma'] !== undefined);
        if (index > -1) {
          object.filters[index] = filterWithOptions;
        } else {
          object.filters.push(filterWithOptions);
        }
        if (canvas._objects[0] && canvas._objects[0].width) {
          (canvas._objects[0] as FabricTypes.Image).applyFilters();
        }
        object.applyFilters();
        canvas.renderAll();
      }
    }
  };

  const removeFilter = (key: (typeof IMAGE_FILTERS)[number]) => {
    const { canvas } = editor;
    const object: any = activeObject;

    if (key == 'opacity') {
      object.set('opacity', 1);
      canvas.renderAll();
      return;
    }

    if (object?.filters) {
      const index = object.filters.findIndex((f: any) => f[key] !== undefined);
      if (index > -1) {
        object.filters.splice(index, 1);
      }
      object.applyFilters();
      canvas.renderAll();
    }
  };

  const applyOpacity = () => {
    if (editor && didMount) {
      const object: any = activeObject;
      if (object?.filters) {
        object.set('opacity', filter.opacity as number);
        editor.canvas.renderAll();
      }
    }
  };

  useEffect(applyFilter('brightness'), [filter.brightness]);
  useEffect(applyFilter('contrast'), [filter.contrast]);
  useEffect(applyFilter('saturation'), [filter.saturation]);
  useEffect(applyFilter('blur'), [filter.blur]);
  useEffect(applyGamma, [filter.gamma]);
  useEffect(applyOpacity, [filter.opacity]);

  const handleChangeFilter = (key: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = +event.target.value;
    setFilter((prevState) => ({ ...prevState, [key]: value }));
  };

  const handleChangeGamma = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = +event.target.value;
    const gammaCopy = [...(filter.gamma as number[])];
    gammaCopy[0] = value;
    setFilter((prevState) => ({ ...prevState, ['gamma']: gammaCopy }));
  };

  useEffect(() => {
    if (!editor || !fabric) {
      return;
    }
    if (editor.canvas._objects[0]) {
      editor.canvas._objects[0].set('opacity', filter.opacity as number);
      if (editor.canvas._objects[0]) {
        (editor.canvas._objects[0] as FabricTypes.Image).applyFilters();
      }
      editor.canvas.renderAll();
    }
  }, [filter.opacity]);

  return (
    <Filters modalFilter={modalFilter}>
      {IMAGE_FILTERS.filter((key) => key !== 'gamma').map((key) => (
        <FilterItem key={key} modalFilter={modalFilter}>
          <Checkbox
            id={key}
            label={t(`editor.filters.${key}`) as string}
            checked={enableFilter[key]}
            onChange={handleToggleFilter(key)}
          />
          {enableFilter[key] && (
            <Range
              id={key}
              name={key}
              min={['opacity', 'blur'].includes(key) ? '0' : '-1'}
              max="1"
              value={filter[key] as number}
              step="0.01"
              onChange={handleChangeFilter(key)}
            />
          )}
        </FilterItem>
      ))}
      <FilterItem>
        <Checkbox
          id={'gamma'}
          label={t('editor.filters.warmth') as string}
          checked={enableFilter['gamma']}
          onChange={handleToggleFilter('gamma')}
        />
        {enableFilter['gamma'] && (
          <Range
            id={'gamma'}
            min="0.5"
            max="1.5"
            value={(filter.gamma as number[])[0]}
            step="0.01"
            onChange={handleChangeGamma}
          />
        )}
      </FilterItem>
    </Filters>
  );
};

import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import SortDropdown from './sort-dropdown.component';
import { useLocation } from 'react-router-dom';
import { ASCSort, SortOption } from '../interfaces/editor.interface';
import useDebounce from '../hooks/use-debounce.hooks';
import { FILE_SORT, FOLDER_SORT } from '../constants/shared.constants';

export const PageHeaderLayout = styled.div<{ noneBackground: boolean }>`
  width: 100%;
  background: ${(p) => !p.noneBackground && '#ffffff'};
  min-height: ${(p) => !p.noneBackground && '70px'};
  border-radius: ${(p) => !p.noneBackground && '16px'};
  margin-bottom: ${(p) => !p.noneBackground && '10px'};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${(p) => !p.noneBackground && '0 20px'};
`;

const PageTitle = styled.span`
  font-size: 23px;
  font-weight: 700;
  line-height: 31px;
`;

const MultipleHighlight = styled.div<{ active?: boolean; disabled?: boolean }>`
  margin-left: auto;
  font-size: 14px;
  line-height: 19px;
  font-weight: 600;
  color: ${(p) => (p.active ? '#6620C7' : 'inherit')};
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  margin-right: 20px;
  cursor: pointer;
  transition: color 0.2s ease-in-out;
  &:hover {
    color: #6620c7;
  }
  ${(p) =>
    p.disabled &&
    css`
      color: gray;
    `}
`;

const Count = styled.span`
  color: #00000066;
`;

type sortType = 'fileSort' | 'folderSort';

const PageHeader: React.FC<{
  pageTitle: string;
  sortType: sortType;
  selectedOption: SortOption;
  sortOrder: ASCSort;
  setSortOrder: (sort: ASCSort) => void;
  setSelectedOption: (option: SortOption) => void;
  noneBackground?: boolean;
  highlight?: boolean;
  setHighlight?: (highlight: boolean) => void;
  count?: number;
  isLoading?: boolean;
}> = ({
  pageTitle,
  sortType,
  selectedOption,
  sortOrder,
  setSortOrder,
  setSelectedOption,
  noneBackground = false,
  highlight = false,
  setHighlight,
  count,
  isLoading,
}) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const searchValue = params.get('q');
  const [searchQuery, setSearchQuery] = useState<string>('');
  const query = useDebounce(searchQuery, 500);

  useEffect(() => {
    setSearchQuery(searchValue || '');
  }, [location]);

  return (
    <PageHeaderLayout noneBackground={noneBackground}>
      <div>
        <PageTitle>
          {pageTitle}
          {count !== undefined && !isLoading && <Count> ({count})</Count>}
          {query ? `: результаты поиска «${query}»` : ''}
        </PageTitle>
      </div>
      <MultipleHighlight
        active={highlight}
        disabled={isLoading}
        onClick={() => setHighlight && !isLoading && setHighlight(!highlight)}
      >
        <span style={{ fontSize: '16px' }}>Выбрать несколько</span>
        <span className="icon-select-all" style={{ fontSize: '20px' }}></span>
      </MultipleHighlight>
      <div>
        <SortDropdown
          selection={sortType === 'folderSort' ? FOLDER_SORT : FILE_SORT}
          setSelectedOption={setSelectedOption}
          selectedOption={selectedOption.title}
          sortOrder={sortOrder}
          setSortOrder={setSortOrder}
          width={sortType === 'fileSort' ? '163.83px' : '181.09px'}
          fontSize="16px"
          selectionFontSize="16px"
          iconFontSize="20px"
        />
      </div>
    </PageHeaderLayout>
  );
};

export default PageHeader;

import React, { InputHTMLAttributes } from 'react';
import styled, { css } from 'styled-components';
import { Text } from './text.component';
import {useTranslation} from 'react-i18next';

const ToggleContainer = styled.label`
  position: relative;
  display: flex;
  cursor: pointer;
  & > ${Text} {
    margin-right: 0.5rem;
  }
`;

const ToggleInput = styled.input`
  display: none;
`;

const ToggleSliderThumb = styled.span`
  position: absolute;
  top: 2px;
  left: 2px;
  width: 1rem;
  height: 1rem;
  background-color: ${p => p.theme.black};
  border-radius: 50%;
  transition: 0.4s;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
`;

const ToggleSlider = styled.span<{ active: boolean }>`
  display: block;
  position: relative;
  width: 2.5rem;
  height: 1.25rem;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${p => p.theme.lightGray2};
  border-radius: 24px;
  transition: 0.4s;
  
  ${p => p.active && css`
    background: linear-gradient(0deg, rgba(102, 32, 199, 0.4), rgba(102, 32, 199, 0.4)), ${p.theme.white};
    & > ${ToggleSliderThumb} {
      left: calc(100% - 1rem - 2px);
    }
  `}
`;

interface IProps extends InputHTMLAttributes<any> {
  label?: string,
}

const ToggleSwitch = ({ label, ...rest }: IProps) => {
  const { t } = useTranslation();

  return (
    <ToggleContainer>
      {label && <Text small>{t(label)}</Text>}
      <ToggleInput
        type="checkbox"
        {...rest}
      />
      <ToggleSlider active={rest.checked as boolean}>
        <ToggleSliderThumb />
      </ToggleSlider>
    </ToggleContainer>
  );
};

export default ToggleSwitch;

import styled, { css } from 'styled-components';
import { ModalDescription } from '../../object-toolbar/infographic-tool/infographic.style';

export const TemplateItem = styled.div<{ active?: boolean; height: string, withOutline?: boolean }>`
  margin: 6px 0;
  width: 115px;
  height: ${({ height }) => height};
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid transparent;

  & img {
    border: 1px solid #00000033;
    width: 100%;
    height: 100%;
    border-radius: 8px;
  }

  &:hover {
    border: none;
    & img {
      border: 2px solid #6620c7;
      width: 100%;
      height: 100%;
      border-radius: 8px;
    }
  }

  ${({ withOutline }) => withOutline && css`
    border: none;
    position: relative;

    .absolute-border {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 8px;
    }
    
    & img {
      border: none;
    }

    &:hover {

      & img {
        border: 0;
      }
      .absolute-border {
        border: 2px solid #6620c7;
      }
    }
  `}


  ${({ active }) =>
    active &&
    css`
      border: none;
      & img {
        border: 2px solid #6620c7;
        border-radius: 8px;
      }
    `}}
`;

export const TemplateList = styled.div`
  display: flex;
  gap: 10px;
  max-height: 375px;
  width: 100%;
  height: fit-content;
  flex-wrap: wrap;
  user-select: none;
  overflow-y: auto;
`;

export const TemplateModalSubTitle = styled(ModalDescription)`
  margin: 10px 0 20px 0;
`;

export const ModalFooter = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

export const StyledBtnLink = styled.div`
  cursor: pointer;
  &:hover {
    color: rgb(102, 32, 199);
  }
`;

export const ListContainer = styled.div`
  height: fit-content;
  max-height: 500px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
  gap: 17px;
  margin-top: 22px;
  padding-left: 1px;
`;

export const HiddenSelect = styled.div`
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  position: absolute;
  top: 10px;
  right: 10px;
  transition: opacity 0.5s ease;
`;

export const StyledImage = styled.img<{ active: boolean }>`
  border-radius: 10px;
  margin: 1px 1px 0px 1px;
  border: 1px solid #dfdfdf;

  ${({ active }) =>
    !active &&
    css`
      &:hover {
        outline: 1px solid #6620c7;
        border: 1px solid #6620c7;
      }
    `}

  ${({ active }) =>
    active &&
    css`
      border: 2px solid #6620c7;
    `}
`;

export const ListItem = styled.div<{ height: string }>`
  margin: 1px 0 2px 0;
  width: 160px;
  height: ${({ height }) => height};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  position: relative;

  &:hover ${HiddenSelect} {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
    &:hover img {
       {
        border: 2px solid #6620c7;
      }
    }
  }
`;

export const TemplatePopoverWrapper = styled.div`
  width: 352px;
  height: fit-content;
`;

export const Favourite = styled.div`
  font-size: 20px;
  font-weight: 400;
  background-color: #e6e6e6;
  padding: 5px;
  border-radius: 8px;
  cursor: pointer;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
`;

export const ErrorText = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: #000;
`;

import React, { FC, useState } from 'react'
import styled from 'styled-components'
import { Text } from './text.component'

interface Props {
  item: {
    question: string
    answer: string
  }
}

const ItemWrapper = styled.div`
  min-height: 2.625rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    width: 100%;
    min-height: 2.625rem;
    span {
      font-size: 1.25rem;
    }
  };

  .answer {
    padding: 0 0.9375rem 0.9375rem 0.9375rem;
  }
`

const FAQItem: FC<Props> = ({ item }) => {
  const [open, setOpen] = useState(false);

  return (
    <ItemWrapper onClick={() => setOpen(!open)}>
      <div className="header">
        <Text fontWeight={600}>{item.question}</Text>
        <span className={open ? 'icon-chevron-up' : 'icon-chevron-down'} />
      </div>
      {
        open && <div className='answer'>
          <Text fontWeight={600}>{item.answer}</Text>
        </div>
      }

    </ItemWrapper>
  )
}

export default FAQItem
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { fabric } from 'fabric';
import { FabricJSEditor } from 'fabricjs-react';
import { useTranslation } from 'react-i18next';
import privateListNotFound from '../../../assets/images/template-private-not-found.png';
import privateListSearchNotFound from '../../../assets/images/template-private-search.png';
import { FavOrPrivateSort, GlobalSort } from '../../../constants/infographic.constants';
import { useModal } from '../../../contexts/modal.context';
import useDebounce from '../../../hooks/use-debounce.hooks';
import useInfiniteScroll from '../../../hooks/use-infinite-scroll';
import useSortSearch from '../../../hooks/use-sort-search.hooks';
import { CanvasRatio, IBasicEditorProps } from '../../../interfaces/editor.interface';
import {
  MyInfoTemplatesSettings,
  TAllTemplateLists,
  TGetListInfoGraphic,
  TGetListResponse,
  sortingTypeFavOrPrivate,
  sortingTypeGlobal,
} from '../../../interfaces/infographics.interface';
import { IProject, IProjectItem } from '../../../interfaces/projects.interface';
import { InfoGraphicRequest } from '../../../requests/infographic.request';
import { addProjectItem, handleSaveNewPage } from '../../../requests/project.requests';
import { Modal } from '../../modal.component';
import SkeletonLoader from '../../skeleton-loader.component';
import PopoverTab from '../popover-tab';
import TemplateConfirm from './template-confirm-modal';
import TemplateRemoveModal from './template-remove-modal';
import { TemplateSettings } from './template-settings';
import TemplateUpdateModal from './template-update-modal';
import { useShared } from '../../../contexts/shared.context';
import {
  ErrorText,
  Favourite,
  HiddenSelect,
  ListContainer,
  ListItem,
  StyledImage,
  TemplatePopoverWrapper,
} from './template-popover.style';
import { handleSelectGroupItem } from '../../../utils/editor.utils';
import CircleLoader2 from '../../circle-loader2';
import styled from 'styled-components';

export const ImageWrapper = styled.div<{ height: string; isLoading: boolean }>`
  width: 160px;
  height: ${({ height }) => height};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &.selected {
    outline: 1px solid ${(p) => p.theme.primary};
  }
`;

interface ITemplatePopover extends IBasicEditorProps {
  canvasRatio: CanvasRatio;
  project: IProject;
  setProject: Dispatch<SetStateAction<IProject>>;
  setActiveIndex: Dispatch<SetStateAction<number | undefined>>;
  handleSavePage: () => Promise<boolean>;
}

const templateType = 'CANVAS';

const TemplatePopover: React.FC<ITemplatePopover> = ({
  canvasRatio,
  editor,
  project,
  setProject,
  setActiveIndex,
  handleSavePage,
}) => {
  const height = canvasRatio === 'square' ? '160px' : '213px';
  const modal = useModal();
  const { t } = useTranslation();
  const { recentTemplateIds } = useShared();

  const [tuneModalOpen, setTuneModalOpen] = useState<{ open: boolean; id: number }>({
    open: false,
    id: 0,
  });
  const [templateLists, setTemplateLists] = useState<TAllTemplateLists>({
    privateList: [],
    favoriteList: [],
    globalList: [],
  });
  const privateSort = useSortSearch(FavOrPrivateSort[0], 'DESC');
  const favoriteSort = useSortSearch(FavOrPrivateSort[0]);
  const publicSort = useSortSearch(GlobalSort[0], 'DESC');
  const debouncedSearchPublic = useDebounce(publicSort.search, 500);
  const debouncedSearchFavourite = useDebounce(favoriteSort.search, 500);
  const debouncedSearchPrivate = useDebounce(privateSort.search, 500);
  const [privateListLoading, setPrivateListLoading] = useState<boolean>(false);
  const [globalListLoading, setGlobalListLoading] = useState<boolean>(false);
  const [favoriteListLoading, setFavoriteListLoading] = useState<boolean>(false);
  const [isRemoving, setIsRemoving] = useState<boolean>(false);
  const [isFavouriteHover, setIsFavoriteHover] = useState<boolean>(false);
  const [templateLoading, setTemplateLoading] = useState<{ loading: boolean; id: number }>({
    loading: false,
    id: 0,
  });

  const { privateList, favoriteList, globalList } = templateLists;
  const fetchPrivateList = ({ keyWord, orderType, page, sortingType }: TGetListInfoGraphic) => {
    setPrivateListLoading(true);
    const keyWordExist = keyWord ? keyWord : undefined;
    InfoGraphicRequest.getPrivateList({
      keyWord: keyWordExist,
      orderType,
      page,
      sortingType,
      templateType,
    })
      .then((res) => {
        privateSort.setHasMore(res.list.length > 0);
        setTemplateLists((prev) => ({
          ...prev,
          privateList: [...prev.privateList, ...res.list],
        }));
      })
      .finally(() => setPrivateListLoading(false));
  };

  const fetchGlobalList = ({ page, keyWord, orderType, sortingType }: TGetListInfoGraphic) => {
    setGlobalListLoading(true);
    const keyWordExist = keyWord ? keyWord : undefined;
    InfoGraphicRequest.getGlobalList({
      keyWord: keyWordExist,
      orderType,
      page,
      sortingType,
      templateType,
    })
      .then((res) => {
        // На будущее, если нужно будет обновлять картинки, а то удаляю и заново писать приходится

        // res.list.map((item) => {
        //   setTimeout(() => {
        //     InfoGraphicRequest.getInfoGraphic(item.id).then((res) => {
        //       refreshImagesTemplate(res.details[0]).then((image: unknown) => {
        //         const blobImage = image as Blob;
        //         InfoGraphicRequest.updateImageTemplate({
        //           id: res.id,
        //           image: blobImage,
        //         });
        //       });
        //     });
        //   }, 500);
        // });
        publicSort.setHasMore(!(res.list.length < 24));
        setTemplateLists((prev) => ({
          ...prev,
          globalList: [...prev.globalList, ...res.list],
        }));
      })
      .finally(() => setGlobalListLoading(false));
  };

  const fetchFavoriteList = ({ keyWord, orderType, page, sortingType }: TGetListInfoGraphic) => {
    const keyWordExist = keyWord ? keyWord : undefined;
    setFavoriteListLoading(true);
    InfoGraphicRequest.getFavoriteInfoGraphicList({
      keyWord: keyWordExist,
      orderType,
      page,
      sortingType,
      templateType,
    })
      .then((res) => {
        favoriteSort.setHasMore(res.list.length > 0);
        setTemplateLists((prev) => ({
          ...prev,
          favoriteList: [...prev.favoriteList, ...res.list],
        }));
      })
      .finally(() => setFavoriteListLoading(false));
  };

  const getPrivateList = () => {
    fetchPrivateList({
      keyWord: privateSort.search,
      orderType: privateSort.sortAsc,
      page: privateSort.page,
      sortingType: privateSort.selectedOption.value as sortingTypeFavOrPrivate,
      templateType,
    });
  };

  const getFavouriteList = () => {
    fetchFavoriteList({
      keyWord: favoriteSort.search,
      orderType: favoriteSort.sortAsc,
      page: favoriteSort.page,
      sortingType: favoriteSort.selectedOption.value as sortingTypeFavOrPrivate,
      templateType,
    });
  };

  const getGlobalList = () => {
    fetchGlobalList({
      keyWord: publicSort.search,
      orderType: publicSort.sortAsc,
      page: publicSort.page,
      sortingType: publicSort.selectedOption.value as sortingTypeGlobal,
      templateType,
    });
  };

  useEffect(() => {
    if (favoriteSort.page !== 0) getFavouriteList();
  }, [favoriteSort.page]);

  useEffect(() => {
    if (publicSort.page !== 0) getGlobalList();
  }, [publicSort.page]);

  useEffect(() => {
    if (privateSort.page !== 0) getPrivateList();
  }, [privateSort.page]);

  const refetchPublicData = () => {
    publicSort.setPage(0);
    setTemplateLists((prev) => ({
      ...prev,
      globalList: [],
    }));
    fetchGlobalList({
      keyWord: publicSort.search,
      orderType: publicSort.sortAsc,
      page: 0,
      sortingType: publicSort.selectedOption.value as sortingTypeGlobal,
      templateType,
    });
  };

  const refetchPrivateData = () => {
    privateSort.setPage(0);
    setTemplateLists((prev) => ({
      ...prev,
      privateList: [],
    }));
    fetchPrivateList({
      keyWord: privateSort.search,
      orderType: privateSort.sortAsc,
      page: 0,
      sortingType: privateSort.selectedOption.value as sortingTypeFavOrPrivate,
      templateType,
    });
  };

  const refetchFavoriteData = () => {
    favoriteSort.setPage(0);
    setTemplateLists((prev) => ({
      ...prev,
      favoriteList: [],
    }));
    fetchFavoriteList({
      keyWord: favoriteSort.search,
      orderType: favoriteSort.sortAsc,
      page: 0,
      sortingType: favoriteSort.selectedOption.value as sortingTypeFavOrPrivate,
      templateType,
    });
  };

  const refetchAllData = () => {
    refetchPublicData();
    refetchPrivateData();
    refetchFavoriteData();
  };

  useEffect(() => {
    refetchPublicData();
  }, [publicSort.selectedOption, publicSort.sortAsc, publicSort.sortAsc, debouncedSearchPublic]);

  useEffect(() => {
    refetchPrivateData();
  }, [privateSort.selectedOption, privateSort.sortAsc, debouncedSearchPrivate]);

  useEffect(() => {
    refetchFavoriteData();
  }, [favoriteSort.selectedOption, favoriteSort.sortAsc, debouncedSearchFavourite]);

  const privateInfiniteScroll = useInfiniteScroll(
    privateListLoading,
    privateSort.hasMore,
    privateSort.setPage,
  );

  const globalInfiniteScroll = useInfiniteScroll(
    globalListLoading,
    publicSort.hasMore,
    publicSort.setPage,
  );

  const favouriteInfiniteScroll = useInfiniteScroll(
    favoriteListLoading,
    favoriteSort.hasMore,
    favoriteSort.setPage,
  );

  const templateTune = (id: number) => {
    setTuneModalOpen({ open: true, id });
  };

  const removeTemplate = (id: number) => {
    const content = <TemplateRemoveModal isRemoving={isRemoving} />;
    modal.open(content, {
      width: 460,
      handleOk: () => {
        setIsRemoving(true);
        InfoGraphicRequest.deleteInfoGraphic(id)
          .then(() => {
            fetchPrivateList({
              keyWord: privateSort.search,
              orderType: privateSort.sortAsc,
              page: privateSort.page,
              sortingType: privateSort.selectedOption.value as sortingTypeFavOrPrivate,
              templateType,
            });
          })
          .finally(() => {
            modal.close();
            setIsRemoving(false);
          });
      },
      handleCancel: () => modal.close(),
    });
  };

  const addTemplate = async (id: number, type: 'new' | 'exist', callback?: () => void) => {
    setTemplateLoading({
      loading: true,
      id,
    });
    await InfoGraphicRequest.getInfoGraphic(id)
      .then((projectItem) => {
        if (type === 'new') {
          addProjectItem(project.id)
            .then(({ data }) => {
              const { canvas } = editor as FabricJSEditor;
              canvas.discardActiveObject();
              canvas.renderAll();
              const details = projectItem.details[0];
              const newItem = { ...data, details } as IProjectItem;
              setProject((prevState) => ({
                ...prevState,
                projectItems: [...project.projectItems, newItem],
              }));
              setActiveIndex(project.projectItems.length);
              handleSaveNewPage(editor as FabricJSEditor, newItem);
            })
            .finally(() =>
              setTemplateLoading({
                loading: false,
                id: 0,
              }),
            );
        } else {
          editor.canvas.clear();

          fabric.util.enlivenObjects(
            projectItem.details[0].objects,
            (enlivenedObjects: any) => {
              enlivenedObjects.forEach((obj: any) => {
                if (obj.type === 'group') {
                  editor.canvas.add(obj);
                  obj.off('mousedblclick');
                  obj.on('mousedblclick', handleSelectGroupItem(editor.canvas));
                } else {
                  editor.canvas.add(obj);
                }
              });

              editor.canvas.renderAll();
            },
            'fabric',
          );

          editor.canvas.setBackgroundColor(projectItem.details[0].background, () => {
            editor.canvas.renderAll();
          });

          setTimeout(() => {
            editor.canvas.renderAll();
            handleSavePage().finally(() =>
              setTemplateLoading({
                loading: false,
                id: 0,
              }),
            );
          }, 100);
        }
      })
      .then(() => {
        callback && callback();
      })
      .catch(() =>
        setTemplateLoading({
          loading: false,
          id: 0,
        }),
      );
  };

  const handleOpenConfirmModal = (id: number) => {
    if (editor.canvas.getObjects().length > 0) {
      const content = <TemplateConfirm id={id} addTemplate={addTemplate} />;

      modal.open(content, {
        width: 540,
        handleCancel: () => modal.close(),
      });
      return;
    }

    addTemplate(id, 'exist');
  };

  const refetchFavouriteAddingData = () => {
    refetchPublicData();
    refetchFavoriteData();
  };

  const addToFavourite = (id: number) =>
    InfoGraphicRequest.addFavoriteInfoGraphic(id).then(() => refetchFavouriteAddingData());

  const removeFavourite = (id: number) =>
    InfoGraphicRequest.removeFavoriteInfoGraphic(id).then(() => refetchFavouriteAddingData());

  const ListLoader = Array.from({ length: 6 }).map((_, index) => (
    <SkeletonLoader key={index} width="160px" height={height} />
  ));

  const myInfoGraphicSettingsOptions: MyInfoTemplatesSettings[] = [
    {
      name: 'Ключевые слова',
      function: templateTune,
    },
    {
      name: 'Удалить',
      function: removeTemplate,
    },
  ];

  const renderList = (list: TGetListResponse[]) => (
    <ListContainer
      onScroll={
        list === privateList
          ? privateInfiniteScroll.handleScroll
          : list === globalList
          ? globalInfiniteScroll.handleScroll
          : list === favoriteList
          ? favouriteInfiniteScroll.handleScroll
          : undefined
      }
    >
      {list.length > 0 ? (
        list.map((item) => (
          <ListItem key={item.id} height={height}>
            {list === privateList && (
              <HiddenSelect>
                <TemplateSettings
                  dropDownWidth="144px"
                  settingOptions={myInfoGraphicSettingsOptions}
                  id={item.id}
                />
              </HiddenSelect>
            )}
            {list === globalList && (
              <HiddenSelect>
                <Favourite
                  onClick={() =>
                    !item.isFavourite ? addToFavourite(item.id) : removeFavourite(item.id)
                  }
                  onMouseEnter={() => setIsFavoriteHover(true)}
                  onMouseLeave={() => setIsFavoriteHover(false)}
                  className={
                    isFavouriteHover
                      ? 'icon-heart-filled'
                      : item.isFavourite
                      ? 'icon-heart-filled'
                      : 'icon-heart'
                  }
                ></Favourite>
              </HiddenSelect>
            )}
            {list === favoriteList && (
              <HiddenSelect>
                <Favourite
                  onClick={() => removeFavourite(item.id)}
                  onMouseEnter={() => setIsFavoriteHover(true)}
                  onMouseLeave={() => setIsFavoriteHover(false)}
                  className={
                    isFavouriteHover
                      ? 'icon-heart-filled'
                      : item.isFavourite
                      ? 'icon-heart-filled'
                      : 'icon-heart'
                  }
                ></Favourite>
              </HiddenSelect>
            )}
            <ImageWrapper
              onClick={() => handleOpenConfirmModal(item.id)}
              height={height}
              isLoading={templateLoading.id === item.id}
            >
              <StyledImage
                width={'160px'}
                height={height}
                src={`/api/project/load/image/png?imageId=${item.imageId}&imageSizeType=NORMAL`}
                active={recentTemplateIds.ids.includes(item.id)}
              />
              {templateLoading.id === item.id && (
                <span
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                  }}
                >
                  <CircleLoader2 shineColor="#6620C7" />
                </span>
              )}
            </ImageWrapper>
          </ListItem>
        ))
      ) : (
        <>
          {list === privateList && !privateListLoading && !debouncedSearchPrivate ? (
            <>
              <div>
                <ErrorText>{t('editor.templates.privateListNotFound')}</ErrorText>
              </div>
              <img src={privateListNotFound} width={'100%'} height={'100%'} />
            </>
          ) : (
            list === privateList &&
            !privateListLoading && (
              <>
                <div>
                  <ErrorText>{t('editor.templates.privateListSearchNotFound')}</ErrorText>
                </div>
                <img src={privateListSearchNotFound} width={'100%'} height={'100%'} />
              </>
            )
          )}
          {list === globalList && !globalListLoading && (
            <ErrorText>
              По вашему запросу ничего не найдено. Повторите попытку, набрав другие слова.
            </ErrorText>
          )}
          {list === favoriteList && !favoriteListLoading && (
            <ErrorText>{t('editor.infographic.favListError')}</ErrorText>
          )}
        </>
      )}
      {list === privateList && privateListLoading && <>{ListLoader}</>}
      {list === globalList && globalListLoading && <>{ListLoader}</>}
      {list === favoriteList && favoriteListLoading && <>{ListLoader}</>}
    </ListContainer>
  );

  const PrivateTemplate = renderList(privateList);
  const FavoriteTemplate = renderList(favoriteList);
  const GlobalTemplate = renderList(globalList);

  return (
    <TemplatePopoverWrapper>
      {tuneModalOpen.open && (
        <Modal
          width="540px"
          height="fit-content"
          style={{ minHeight: '419px' }}
          onClose={() => setTuneModalOpen({ open: false, id: 0 })}
        >
          <TemplateUpdateModal
            id={tuneModalOpen.id}
            onClose={() => setTuneModalOpen({ open: false, id: 0 })}
            refetchAllData={refetchAllData}
          />
        </Modal>
      )}

      <PopoverTab
        width="fit-content"
        searchPlaceholder="Поиск инфографики"
        tabs={[
          {
            title: 'Все шаблоны',
            sort: {
              selection: GlobalSort,
              selectedOption: publicSort.selectedOption,
              setSelectedOption: publicSort.setSelectedOption,
              sortOrder: publicSort.sortAsc,
              setSortOrder: publicSort.setSortAsc,
              search: publicSort.search,
              setSearch: publicSort.setSearch,
            },
            content: GlobalTemplate,
            loading: globalListLoading,
          },
          {
            title: 'Избранное',
            sort: {
              selection: FavOrPrivateSort,
              selectedOption: favoriteSort.selectedOption,
              setSelectedOption: favoriteSort.setSelectedOption,
              sortOrder: favoriteSort.sortAsc,
              setSortOrder: favoriteSort.setSortAsc,
              search: favoriteSort.search,
              setSearch: favoriteSort.setSearch,
            },
            content: FavoriteTemplate,
            loading: favoriteListLoading,
          },
          {
            title: 'Мои шаблоны',
            sort: {
              selection: FavOrPrivateSort,
              selectedOption: privateSort.selectedOption,
              setSelectedOption: privateSort.setSelectedOption,
              sortOrder: privateSort.sortAsc,
              setSortOrder: privateSort.setSortAsc,
              search: privateSort.search,
              setSearch: privateSort.setSearch,
            },
            content: PrivateTemplate,
            loading: privateListLoading,
          },
        ]}
      />
    </TemplatePopoverWrapper>
  );
};

export default TemplatePopover;

import styled, { css, keyframes } from 'styled-components';
import { Headline } from '../../components/headline.component';
import { ColumnContent } from '../login/login.style';

export const Wrapper = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  & > ${Headline} {
    color: ${(p) => p.theme.white};
  }
  & > ${Headline} {
    margin-bottom: 0.625rem;
  }
`;

export const SparkleWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  margin-top: 1.875rem;
`;

const ColumnTemplate = styled.div`
  position: relative;
  min-height: 1px;
  display: grid;
`;

const hide = keyframes`
  0% { opacity: 0 }
  100% { opacity: 1 }
`;

const moveleft = keyframes`
  0%   {left: 40%; max-width: 60%; }
  50%  {left: 20%; max-width: 40%; }
  100% {left: 0%; max-width: 60%; }
`;

const moveright = keyframes`
  0%   {right: 60%; max-width: 40%; flex: 0 0 40%;}
  50%  {right: 20%; max-width: 60%; flex: 0 0 60%;}
  100% {right: 0%; max-width: 40%; flex: 0 0 40%;}
`;

const buttonanimation = keyframes`
  0% { width: auto }
  25%  {width: 130px}
  50%  {width: 170px}
  75%  {width: 130px}
  100% { width: auto }
`;

export const RightColumn = styled(ColumnTemplate)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex: 0 0 40%;
  padding: 2rem;
  background: ${(p) =>
    `linear-gradient(0deg, ${p.theme.primary}, ${p.theme.primary}), ${p.theme.white}`};
  animation: ${moveright} 1s ease-in forwards;
  & > * {
    animation: ${hide} 2s ease-in forwards;
  }
  & > ${ColumnContent} > ${SparkleWrapper} > button {
    animation: ${buttonanimation} 1s ease-in forwards;
  }
`;

export const LeftColumn = styled(ColumnTemplate)<{ isMobile?: boolean }>`
  flex: 0 0 60%;

  ${({ isMobile }) =>
    !isMobile &&
    css`
      animation: ${moveleft} 1s ease-in forwards;
      max-width: 60%;
      justify-content: center;
      align-content: center;

      & > img {
        left: 20px;
      }
    `}

  @media (max-height: 665px) {
    ${({ isMobile }) =>
      isMobile &&
      css`
        display: block;
      `}
  }
  & > * {
    animation: ${hide} 2s ease-in-out forwards;
  }
  & > img {
    position: absolute;
    top: 13px;
    height: 3.75rem;
    filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.25));
  }
`;

export const WhiteBox = styled.div<{ isMobile?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #ffffff;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
  width: 380px;
  height: max-content;
  padding: 20px;
  gap: 10px;
  margin: auto;

  button.inactive {
    background: ${(p) => p.theme.white};
    border: 1px solid ${(p) => p.theme.lightBlack2};
    box-shadow: none;
    color: ${(p) => p.theme.infoGray};
  }

  @media (max-height: 665px) {
    ${({ isMobile }) =>
      isMobile &&
      css`
        margin-top: 73px;
      `}
  }
`;
export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  gap: 20px;
  width: 100%;
`;
export const DoneWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

export const PrivacyPolicy = styled.div`
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.1875rem;
  color: ${(p) => p.theme.black};
  margin-top: -10px;
  & a {
    color: ${(p) => p.theme.primary};
    cursor: pointer;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`;

const components = {
  Wrapper,
  SparkleWrapper,
  LeftColumn,
  RightColumn,
  Form,
  DoneWrapper,
  PrivacyPolicy,
};

export default components;

import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Button } from '../../button.component';
import { Flex, FlexCol } from '../../flexbox.component';
import { IModalOptions } from '../../../interfaces/modal.interface';

const StyledBtnLink = styled.div`
  cursor: pointer;
  &:hover {
    color: rgb(102, 32, 199);
  }
`;

const InfoGraphicRemoveModal: React.FC<IModalOptions & { isRemoving?: boolean }> = ({
  handleCancel,
  handleOk,
  isRemoving,
}) => {
  const { t } = useTranslation();

  return (
    <FlexCol gap={20}>
      <div>Вы действительно ходите удалить инфографику?</div>
      <Flex justifyContent="space-between" alignItems="center">
        <StyledBtnLink
          onClick={() => {
            if (handleCancel) {
              handleCancel();
            }
          }}
        >
          {t('action.cancel')}
        </StyledBtnLink>
        <Button
          icon="trash"
          isLoading={isRemoving}
          disabled={isRemoving}
          onClick={() => {
            if (handleOk) {
              handleOk();
            }
          }}
        >
          {t('action.delete')}
        </Button>
      </Flex>
    </FlexCol>
  );
};

export default InfoGraphicRemoveModal;

import React, { ChangeEvent, useState, useEffect } from 'react';
import { InfoGraphicRequest } from '../../../requests/infographic.request';
import { Flex, FlexCol } from '../../flexbox.component';
import { useTranslation } from 'react-i18next';
import ToggleSwitch from '../../toggle.component';
import { Button } from '../../button.component';
import { TUpdateInfoGraphic } from '../../../interfaces/infographics.interface';
import TagInput from '../../tag-input.component';
import Tags from '../../tags.component';
import {
  FooterModal,
  ModalDescription,
  ModalTitle,
} from '../../object-toolbar/infographic-tool/infographic.style';
import SkeletonLoader from '../../skeleton-loader.component';
import { useNotification } from '../../../contexts/notification.context';
import { useUserContext } from '../../../contexts/user.context';

const InfoGraphicUpdateModal: React.FC<{
  id: number;
  onClose: () => void;
  refetchAllData: () => void;
}> = ({ id, onClose, refetchAllData }) => {
  const { t } = useTranslation();
  const { setNotification } = useNotification();
  const { userData } = useUserContext();
  const [infoGraphic, setInfoGraphic] = useState<TUpdateInfoGraphic>({
    accessStatus: '',
    keyWords: [],
    details: [{}],
    imageId: 0,
    id: 0,
  });
  const [accessStatusPrev, setAccessStatusPrev] = useState<'PUBLIC' | 'PRIVATE' | ''>('');
  const [loading, setLoading] = useState<boolean>(true);
  const [enableLayout, setEnableLayout] = useState(false);
  const handleChangeToggle = (e: ChangeEvent<HTMLInputElement>) => {
    setEnableLayout(e.target.checked);
    setInfoGraphic((prev) => ({ ...prev, accessStatus: e.target.checked ? 'PUBLIC' : 'PRIVATE' }));
  };
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getInfoGraphicById = (id: number) => {
    InfoGraphicRequest.getInfoGraphic(id)
      .then((res) => {
        setInfoGraphic(res);
        setEnableLayout(res.accessStatus === 'PUBLIC');
        setAccessStatusPrev(res.accessStatus);
      })
      .finally(() => setLoading(false));
  };

  const handleRemove = (index: number) => {
    setInfoGraphic((prev: any) => ({
      ...prev,
      keyWords: prev.keyWords.filter((_: number, i: number) => i !== index),
    }));
  };

  useEffect(() => {
    getInfoGraphicById(id);
  }, []);

  const checkAccessStatus = () => {
    if (accessStatusPrev === 'PRIVATE' && infoGraphic.accessStatus === 'PUBLIC') {
      setNotification({ text: 'Теперь инфографика доступна всем пользователям Mark It.' });
    }
    if (accessStatusPrev === 'PUBLIC' && infoGraphic.accessStatus === 'PRIVATE') {
      setNotification({ text: 'Теперь инфографика доступна только вам' });
    }
  };

  const handleEditInfoGraphic = () => {
    if (infoGraphic.keyWords !== null && infoGraphic.keyWords.length === 0) return;
    setIsLoading(true);
    InfoGraphicRequest.updateInfographic(infoGraphic).finally(() => {
      setIsLoading(false);
      checkAccessStatus();
      refetchAllData();
      onClose();
    });
  };

  const isDesigner = userData?.role === 'EDITOR';

  return (
    <>
      <FlexCol gap={20}>
        <FlexCol gap={20}>
          <ModalTitle>{t('editor.editInfographic')}</ModalTitle>
          <ModalDescription>
            Добавьте ключевые слова. Правильные ключевые слова позволят вам и другим пользователям
            легко найти инфографику с помощью функции поиска. Слова должно буквально соответствовать
            содержанию.
          </ModalDescription>
        </FlexCol>
        <TagInput setInfoUpdateGraphic={setInfoGraphic} />
        {infoGraphic.keyWords !== null && (
          <Flex wrapItems gap={5} style={{ maxHeight: '120px', overflowY: 'auto' }}>
            {!loading
              ? infoGraphic.keyWords.map((tag: string, index: number) => (
                  <Tags tag={tag} key={index} handleRemove={() => handleRemove(index)} />
                ))
              : Array.from({ length: 5 }).map((_, index) => (
                  <SkeletonLoader key={index} width="75px" height="35px" />
                ))}
          </Flex>
        )}
        {loading ? (
          <SkeletonLoader width="300px" height="20px" />
        ) : (
          isDesigner && (
            <ToggleSwitch
              checked={enableLayout}
              onChange={handleChangeToggle}
              label={'editor.infographic.publicAccess'}
            />
          )
        )}

        <FooterModal>
          <Flex>
            <Button
              btnStyle={'cancel'}
              onClick={() => {
                onClose();
              }}
            >
              {t('action.cancel')}
            </Button>
            {loading ? (
              <SkeletonLoader width="121.48px" height="50px" borderRadius="1rem" />
            ) : (
              <Button
                icon="check"
                disabled={infoGraphic.keyWords !== null && infoGraphic.keyWords.length === 0}
                iconPosition={undefined}
                onClick={handleEditInfoGraphic}
                isLoading={isLoading}
              >
                Готово
              </Button>
            )}
          </Flex>
        </FooterModal>
      </FlexCol>
    </>
  );
};

export default InfoGraphicUpdateModal;

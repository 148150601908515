import React, { useEffect, useRef, useState } from 'react';
import { IBasicEditorProps, CanvasRatio } from '../../interfaces/editor.interface';
import { fabric } from 'fabric';
import IconRequest, { colorTypes, iconData, shapeTypes } from '../../requests/icons.request';
import Search from '../search.component';
import useDebounce from '../../hooks/use-debounce.hooks';
import CircleLoader2 from '../circle-loader2';
import styled from 'styled-components';
import { Flex, FlexCol } from '../flexbox.component';
import SortDropdown from '../sort-dropdown.component';

import {
  ICON_COLOR_SORT_VALUES,
  ICON_POPULAR_SORT_VALUES,
  ICON_STYLE_SORT_VALUES,
  IconColorSort,
  IconSort,
  IconStyleSort,
} from '../../constants/editor.constants';

interface IIconsPopover extends IBasicEditorProps {
  addToCanvas?: (shape: any) => void;
  canvasRatio: CanvasRatio;
}

const ThumbnailRowIcon = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  max-height: 40vh;
  overflow-y: auto;
`;

const FlexCenter = styled.div`
  max-height: 665px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #00000066;
`;

const ErrorText = styled.span`
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
`;

const useIcons = (
  debouncedSearchValue: string,
  styleColor: colorTypes,
  orderBy: 'recent' | 'relevance',
  styleShape: shapeTypes,
  ImpossibleVariants: boolean,
) => {
  const [icons, setIcons] = useState<iconData[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [hasMore, setHasMore] = useState<boolean>(true);

  const fetchIcons = async (page: number) => {
    setLoading(true);
    const icons = await IconRequest.getIcons(
      debouncedSearchValue,
      page,
      orderBy,
      styleColor === '' ? undefined : styleColor,
      styleShape === '' ? undefined : styleShape,
    );
    if (icons.meta.pagination.current_page === icons.meta.pagination.last_page) {
      setHasMore(false);
    }
    if (hasMore) {
      setIcons((prev) => [...prev, ...icons.data]);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (debouncedSearchValue !== '' && !ImpossibleVariants) {
      setIcons([]);
      setPage(1);
      setHasMore(true);
      fetchIcons(1);
    }
  }, [debouncedSearchValue, styleColor, orderBy, styleShape]);

  return { icons, loading, fetchIcons, hasMore, setPage, page };
};

const IconsPopover: React.FC<IIconsPopover> = ({ addToCanvas }) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const debouncedSearchValue = useDebounce(searchValue, 1000);
  const [iconColorSort, setIconColorSort] = useState<IconColorSort>(ICON_COLOR_SORT_VALUES[0]);

  const [iconStyleSort, setIconStyleSort] = useState<IconStyleSort>(ICON_STYLE_SORT_VALUES[0]);

  const [iconPopularSort, setIconPopularSort] = useState<IconSort>(ICON_POPULAR_SORT_VALUES[0]);

  const ImpossibleVariants =
    (iconStyleSort.title === 'Контурные цветные' && iconColorSort.title === 'Черный') ||
    (iconStyleSort.title === 'Контурные цветные' && iconColorSort.title === 'Градиент') ||
    (iconStyleSort.title === 'Нарисованные' && iconColorSort.title === 'Градиент');

  const { icons, loading, fetchIcons, hasMore, setPage } = useIcons(
    debouncedSearchValue as string,
    iconColorSort.value,
    iconPopularSort.value as 'recent' | 'relevance',
    iconStyleSort.value,
    ImpossibleVariants,
  );

  const thumbnailRowIconRef = useRef(null);

  const preserveScrollPosition = (scrollContainer: HTMLElement, callback: () => void) => {
    const previousScroll = scrollContainer.scrollTop;
    callback();
    requestAnimationFrame(() => {
      scrollContainer.scrollTop = previousScroll;
    });
  };

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const targetDiv = e.target as HTMLDivElement;
    if (
      !loading &&
      hasMore &&
      targetDiv.scrollTop + targetDiv.clientHeight >= targetDiv.scrollHeight
    ) {
      setPage((prevPage) => {
        const nextPage = prevPage + 1;
        preserveScrollPosition(targetDiv, () => {
          fetchIcons(nextPage);
        });
        return nextPage;
      });
    }
  };

  const addIconToCanvas = (iconUrl: string) => {
    const imgObj = new Image();
    imgObj.crossOrigin = 'anonymous';
    imgObj.src = iconUrl;
    imgObj.onload = () => {
      const image = new fabric.Image(imgObj);
      image.setControlsVisibility({
        mt: false,
        mb: false,
        ml: false,
        mr: false,
      });
      addToCanvas && addToCanvas(image);
    };
  };

  return (
    <FlexCol gap={10} style={{ width: '332px' }}>
      <Search
        value={searchValue}
        setSearchValue={setSearchValue}
        placeholder="Поиск иконок"
        fail={icons.length === 0 && debouncedSearchValue !== '' && !loading}
      />

      <Flex gap={6} style={{ position: 'relative', zIndex: 1002 }}>
        <SortDropdown
          selection={ICON_COLOR_SORT_VALUES as IconSort[]}
          selectedOption={iconColorSort as string | IconSort}
          setSelectedOption={
            setIconColorSort as ({ title, value }: { title: string; value: string }) => void
          }
          width="146px"
          icons
        />

        <SortDropdown
          selection={ICON_STYLE_SORT_VALUES as IconSort[]}
          selectedOption={iconStyleSort as string | IconSort}
          setSelectedOption={
            setIconStyleSort as ({ title, value }: { title: string; value: string }) => void
          }
          width={'200px'}
          icons
        />
      </Flex>

      <SortDropdown
        selection={ICON_POPULAR_SORT_VALUES}
        selectedOption={iconPopularSort.title}
        setSelectedOption={setIconPopularSort}
      />

      <ThumbnailRowIcon
        onScroll={handleScroll}
        ref={thumbnailRowIconRef}
        style={{ display: 'flex', columnGap: '17.33px', rowGap: '20px', width: '336px' }}
      >
        {ImpossibleVariants ? (
          <FlexCenter>
            <ErrorText>Попробуйте поискать другую комбинацию фильтров.</ErrorText>
          </FlexCenter>
        ) : !loading && icons.length === 0 && debouncedSearchValue ? (
          <FlexCenter>
            <ErrorText>
              По вашему запросу ничего не найдено. Повторите попытку, набрав другие слова.
            </ErrorText>
          </FlexCenter>
        ) : (
          icons.map((icon) => (
            <div
              key={icon.id}
              style={{ marginTop: '20px', cursor: 'pointer' }}
              onClick={() => addIconToCanvas(icon.thumbnails[0].url)}
            >
              <img src={icon.thumbnails[0].url} width={70} height={70} />
            </div>
          ))
        )}

        {loading && debouncedSearchValue && (
          <FlexCenter>
            <CircleLoader2 />
          </FlexCenter>
        )}

        {debouncedSearchValue === '' && !ImpossibleVariants && (
          <FlexCenter>
            <ErrorText>Введите поисковый запрос для отображения иконок.</ErrorText>
          </FlexCenter>
        )}
      </ThumbnailRowIcon>

      {!(debouncedSearchValue === '' && !ImpossibleVariants) && (
        <FlexCenter>
          <ErrorText>Нажмите, чтобы добавить иконку на холст.</ErrorText>
        </FlexCenter>
      )}
    </FlexCol>
  );
};

export default IconsPopover;

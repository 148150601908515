import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { PagePaginationProps } from './pagination.types';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
`;

const Body = styled.div`
  display: flex;
  gap: 10px;
`;

const PageButtons = styled.div`
  display: flex;
  gap: 10px;
`;

const PageButton = styled.div<{ active?: boolean }>`
  background-color: #d9d9d9;
  // padding: 4px 10px;
  color: black;
  cursor: pointer;
  border-radius: 8px;
  box-shadow: 0px 1px 2px 0px #00000040;
  transition: all 0.3s ease-in-out;
  user-select: none;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;

  ${(props) =>
    props.active &&
    `
		background-color: #6620c7;
		color: white;
	`}

  &:hover {
    background-color: #6620c7;
    color: white;
  }
`;

const Button = styled.div<{ nonActive?: boolean }>`
  font-size: 16px;
  font-weight: 600;
  cursor: ${({ nonActive }) => (nonActive ? 'default' : 'pointer')};
  color: ${({ nonActive }) => (nonActive ? '#00000033' : '#000000')};
  display: flex;
  align-items: center;
  gap: 11px;
`;

const NextPrevText = styled.div<{ nonActive: boolean }>`
  &:hover span {
    color: ${({ nonActive }) => (nonActive ? 'rgba(0, 0, 0, 0.2)' : '#6620c7')};
  }
`;

const NUMBER_ITEMS = 5;

const ModalPagination: React.FC<PagePaginationProps> = ({ page, setPage, totalCount }) => {
  if (totalCount === 1) return null;

  const [currentState, setCurrentState] = useState<number>(NUMBER_ITEMS);
  const pagination: number[] = [];

  for (let i = 0; i < totalCount; i++) {
    pagination.push(i + 1);
  }

  const handleClickAhead = () => {
    if (page + 1 === currentState) setCurrentState((prev) => prev + NUMBER_ITEMS);
    page + 1 !== totalCount && setPage((prev) => prev + 1);
  };

  const handleClickBack = () => {
    if (page === currentState - NUMBER_ITEMS) setCurrentState((prev) => prev - NUMBER_ITEMS);
    page !== 0 && setPage((prev) => prev - 1);
  };

  useEffect(() => {
    setCurrentState(Math.floor(page / NUMBER_ITEMS) * NUMBER_ITEMS + NUMBER_ITEMS)
  }, [page]);


  return (
    <Wrapper>
      <NextPrevText nonActive={page === 0}>
        <Button nonActive={page === 0} onClick={() => !(page === 0) && handleClickBack()}>
          <span className="icon-arrow-left"></span>
          <span>Назад</span>
        </Button>
      </NextPrevText>
      <Body>
        {currentState != 5 && (
          <PageButton
            onClick={() => {
              setCurrentState((prev) => prev - NUMBER_ITEMS);
            }}
          >
            <span className="icon-more" />
          </PageButton>
        )}
        <PageButtons>
          {pagination.slice(currentState - NUMBER_ITEMS, currentState).map((number) => (
            <PageButton
              active={page === number - 1}
              key={number}
              onClick={() => {
                setPage(number - 1);
              }}
            >
              {number}
            </PageButton>
          ))}
        </PageButtons>
        {currentState < totalCount && (
          <PageButton
            onClick={() => {
              setCurrentState((prev) => prev + NUMBER_ITEMS);
            }}
          >
            <span className="icon-more" />
          </PageButton>
        )}
      </Body>
      <NextPrevText nonActive={page + 1 === totalCount}>
        <Button
          nonActive={page + 1 === totalCount}
          onClick={() => !(page + 1 === totalCount) && handleClickAhead()}
        >
          <span>Дальше</span>
          <span className="icon-arrow-right"></span>
        </Button>
      </NextPrevText>
    </Wrapper>
  );
};

export default ModalPagination;

import { useState } from 'react';
export type ASCSort = 'ASC' | 'DESC';
export type SortOption = {
  title: string;
  value: string;
};

export default function useSortSearch(defaultedSortOption: SortOption, defaultSortAsc?: ASCSort) {
  const [sortAsc, setSortAsc] = useState<ASCSort>(defaultSortAsc || 'ASC');
  const [selectedOption, setSelectedOption] = useState<SortOption>(defaultedSortOption);
  const [search, setSearch] = useState<string>('');
  const [page, setPage] = useState<number>(0);
  const [hasMore, setHasMore] = useState<boolean>(true);

  return {
    sortAsc,
    setSortAsc,
    selectedOption,
    setSelectedOption,
    search,
    setSearch,
    page,
    setPage,
    hasMore,
    setHasMore,
  };
}

import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  changeUserDetails,
  changeUserPassword,
  confirmEmailChange,
} from '../../requests/user.requests';
import Input from '../../components/input.component';
import { IChangePasswordDto, IUserDetails } from '../../interfaces/auth.interface';
import './profile.styles.scss';
import { Text } from '../../components/text.component';
import { Button } from '../../components/button.component';
import { PurchaseRateTypes, RateNames } from '../../constants/rates.constant';
import { getHistory, purchaseRate } from '../../requests/tariff.request';
import { useNavigate } from 'react-router-dom';
import { useUserContext } from '../../contexts/user.context';

const initialUserDetails: IUserDetails = {
  name: undefined,
  phoneNumber: undefined,
  email: '',
  tariff: null,
  rate: null,
};

interface IUserPasswords extends IChangePasswordDto {
  newPasswordCopy: string;
}

const initialPasswords: IUserPasswords = {
  oldPassword: '',
  newPassword: '',
  newPasswordCopy: '',
};

export const formatDate = (date: string) =>
  date && new Date(date).toLocaleDateString().replaceAll('/', '.');

const ProfilePage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [changePasswordOpen, setChangePasswordOpen] = useState<boolean>(false);
  const [userDetails, setUserDetails] = useState<IUserDetails>(initialUserDetails);
  const [userDetailsErrors, setUserDetailsErrors] = useState<IUserDetails>(initialUserDetails);
  const [passwords, setPasswords] = useState<IUserPasswords>(initialPasswords);
  const [verifyEmailOpen, setVerifyEmailOpen] = useState<boolean>(false);
  const [verificationCode, setVerificationCode] = useState<string>('');
  const [verificationCodeResponse, setVerificationCodeResponse] = useState({
    success: '',
    error: '',
  });
  const [passwordsError, setPasswordsError] = useState({ ...initialPasswords, success: '' });
  const [currentRate, setCurrentRate] = useState<any>();
  const [history, setHistory] = useState([]);
  const { userData, updateUserDetails } = useUserContext();

  useEffect(() => {
    getHistory().then((res) => {
      setHistory(res.list.filter((item: any) => item.name !== RateNames.TRIAL));
    });
  }, []);

  useEffect(() => {
    if (userData) {
      setUserDetails(userData);
      setCurrentRate(userData.rate);
    }
  }, [userData]);

  const changeUserInfo = (user: IUserDetails) => {
    changeUserDetails(user)
      .then(() => {
        updateUserDetails();
        return;
      })
      .catch((res) => console.log(res));
  };

  const handleToggleChangePasswordOpen = () => {
    setChangePasswordOpen((prev) => !prev);
  };

  const verifyEmail = (email: string) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const format = emailRegex.test(email);
    if (!format) {
      setUserDetailsErrors((prev) => ({ ...prev, email: 'Неверный формат' }));
      return format;
    }
    if (email.toLowerCase() === userDetails.email.toLowerCase()) {
      setUserDetailsErrors((prev) => ({ ...prev, email: 'Почта повторяется' }));
      return false;
    }
    return format;
  };

  const verifyFullName = (name: string) => {
    const fullNameRegex = /^\S+(\s+\S+)*$/;
    return fullNameRegex.test(name);
  };

  const verifyPhoneNumber = (num: string) => {
    if (num.trim() === '') {
      return true;
    }
    return /^\+7\d{10}$/.test(num);
  };

  const verifyPassword = (password: string) => {
    if (password.length < 6) {
      const error = 'Длина пароля должна быть не менее 6 символов';
      setPasswordsError((prev) => ({ ...prev, newPassword: error, newPasswordCopy: error }));
      return false;
    } else if (!/^[a-zA-Z0-9!@#$%^&*()_+={}:;"'<>,.?/-]{6,}$/.test(password)) {
      const error = 'Пароли должны содержать латинские буквы';
      setPasswordsError((prev) => ({ ...prev, newPassword: error, newPasswordCopy: error }));
      return false;
    }
    return true;
  };

  const handleUserDetailsChange =
    (key: keyof IUserDetails) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const updateUser = () => {
        setUserDetails((prev) => ({ ...prev, [key]: e.target.value }));
        changeUserInfo({ ...userDetails, [key]: e.target.value });
      };
      switch (key) {
        case 'email':
          if (verifyEmail(e.target.value)) {
            setUserDetailsErrors((prev) => ({ ...prev, email: '' }));
            updateUser();
            setVerifyEmailOpen(true);
          } else {
            setUserDetailsErrors((prev) => ({ ...prev, email: 'Неверный формат' }));
          }
          break;
        case 'name':
          if (verifyFullName(e.target.value)) {
            setUserDetailsErrors((prev) => ({ ...prev, name: '' }));
            updateUser();
          } else {
            setUserDetailsErrors((prev) => ({ ...prev, name: 'Неверный формат' }));
          }
          break;
        case 'phoneNumber':
          if (verifyPhoneNumber(e.target.value)) {
            setUserDetailsErrors((prev) => ({ ...prev, phoneNumber: '' }));
            updateUser();
          } else {
            setUserDetailsErrors((prev) => ({ ...prev, phoneNumber: 'Номер введен неправильно' }));
          }
          break;
        default:
          updateUser();
      }
    };

  const handleCancelChangePasswordClick = () => {
    setChangePasswordOpen(false);
  };

  const handleSaveChangePasswordClick = (e: React.MouseEvent<HTMLSpanElement>) => {
    if (isChangePasswordDisabled()) {
      e.preventDefault();
      return;
    }
    setPasswordsError({ ...initialPasswords, success: '' });
    if (passwords.newPasswordCopy !== passwords.newPassword) {
      const error = 'Пароли не совпадают. Попробуйте еще раз.';
      setPasswordsError((prev) => ({ ...prev, newPassword: error, newPasswordCopy: error }));
      return;
    } else if (!verifyPassword(passwords.newPassword)) {
      return;
    } else {
      setPasswordsError({ ...initialPasswords, success: '' });
    }
    const newPasswords = { oldPassword: passwords.oldPassword, newPassword: passwords.newPassword };
    changeUserPassword(newPasswords)
      .then((res) => {
        if (res.success) {
          setChangePasswordOpen(false);
          setPasswordsError({ ...initialPasswords, success: 'Вы сменили пароль' });
          setPasswords(initialPasswords);
          setTimeout(() => {
            setPasswordsError({ ...initialPasswords, success: '' });
          }, 6000);
        }
      })
      .catch((res) => {
        setPasswordsError((prev) => ({ ...prev, oldPassword: res.response.data.msg }));
      });
  };

  const handlePasswordChange =
    (key: keyof IUserPasswords) => (e: React.ChangeEvent<HTMLInputElement>) => {
      setPasswords((prev) => ({ ...prev, [key]: e.target.value }));
      switch (key) {
        case 'newPassword':
          setPasswordsError((prev) => ({
            ...prev,
            ...initialPasswords,
            oldPassword: prev.oldPassword,
          }));
          break;
        case 'newPasswordCopy':
          setPasswordsError((prev) => ({
            ...prev,
            ...initialPasswords,
            oldPassword: prev.oldPassword,
          }));
          break;
        case 'oldPassword':
          setPasswordsError((prev) => ({ ...prev, ...initialPasswords }));
          break;
      }
    };

  const handleVerificationCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setVerificationCode(e.target.value);
  };

  const handleSendVerificationCode = () => {
    confirmEmailChange(userDetails.email, verificationCode)
      .then(() => {
        setVerifyEmailOpen(false);
        setVerificationCode('');
        setVerificationCodeResponse({ error: '', success: 'Адрес подтвержден. Спасибо!' });
        setTimeout(() => {
          setVerificationCodeResponse({ error: '', success: '' });
        }, 6000);
      })
      .catch((res) => {
        setVerificationCodeResponse({ error: res.response.data.msg, success: '' });
      });
  };

  const isChangePasswordDisabled = useCallback(() => {
    return (
      Object.entries(passwordsError).some(([key, value]) => key !== 'success' && !!value) ||
      Object.values(passwords).some((value) => value.length < 6)
    );
  }, [passwordsError, passwords]);

  const isExpiredRate = () => {
    return new Date(currentRate?.expireDate) < new Date();
  };

  const getButtonText = () => {
    if (currentRate?.name === RateNames.TRIAL) {
      return t('rates.chooseRate');
    } else if (!isExpiredRate()) {
      return t('rates.changeRatePlan');
    } else {
      return t('rates.renewRate');
    }
  };

  const handleButtonClick = () => {
    if (currentRate?.name !== RateNames.TRIAL && isExpiredRate()) {
      purchaseRate(currentRate.id, PurchaseRateTypes.RENEW).then(() => {
        updateUserDetails();
      });
    } else {
      navigate('/rates');
    }
  };

  const hasRefunds = () => history.some((item: any) => !item.isActive)

  return (
    <div className={'profile'}>
      <div className="profile__info">
        <Text strong fontSize="1.4375rem">
          {t('profile.profile')}
        </Text>
        <div className="profile__container">
          <div className="profile__inputField">
            <Input
              changeInstantly={false}
              label={t('profile.name') || ''}
              value={userDetails?.name || ''}
              onChange={handleUserDetailsChange('name')}
              error={!!userDetailsErrors.name}
              errorText={userDetailsErrors.name}
            />
          </div>
          <div className="profile__verifyEmail">
            <Input
              changeInstantly={false}
              label={'Email'}
              value={userDetails?.email || ''}
              onChange={handleUserDetailsChange('email')}
              error={!!userDetailsErrors.email}
              errorText={userDetailsErrors.email}
            />
            {!!verificationCodeResponse.success && (
              <p className={'profile__prompt--success'}>{verificationCodeResponse.success}</p>
            )}
            {verifyEmailOpen && (
              <div className="profile__verifyEmail">
                <p className={'profile__verifyEmail__prompt'}>
                  {t('profile.address_not_verified')}
                </p>
                <div className="profile__verifyEmail__container">
                  <Input
                    value={verificationCode}
                    onChange={handleVerificationCodeChange}
                    changeInstantly
                    label={'Код подтверждения'}
                    onSave={handleSendVerificationCode}
                    error={!!verificationCodeResponse.error}
                    errorText={verificationCodeResponse.error}
                  />
                  {!verificationCode && (
                    <p className="profile__verifyEmail__description">
                      На адрес {userDetails.email} отправлено письмо с кодом подтверждения.
                      Пожалуйста, введите код, указанный в этом письме. Если вы не видите письма,
                      проверьте папку со спамом. Спасибо.
                    </p>
                  )}
                </div>
              </div>
            )}
          </div>
          <div className="profile__inputField">
            <Input
              label={t('profile.phoneNumber') || ''}
              value={userDetails?.phoneNumber || ''}
              onChange={handleUserDetailsChange('phoneNumber')}
              type={'tel'}
              changeInstantly={false}
              error={!!userDetailsErrors.phoneNumber}
              errorText={userDetailsErrors.phoneNumber}
            />
          </div>
          <div className="profile__changePassword__container">
            <div className="profile__changePassword__header">
              <button
                className={'profile__changePassword__button'}
                onClick={handleToggleChangePasswordOpen}
              >
                {t('profile.change_password')}
              </button>
              {changePasswordOpen && (
                <div className={'profile__changePassword__icons'}>
                  <span className="icon-close" onClick={handleCancelChangePasswordClick} />
                  <span
                    className={`icon-check${isChangePasswordDisabled() ? '--disabled' : ''}`}
                    onClick={handleSaveChangePasswordClick}
                  />
                </div>
              )}
            </div>
            {!!passwordsError.success && (
              <p className="profile__prompt--success">{passwordsError.success}</p>
            )}
            {changePasswordOpen && (
              <div className={'profile__changePassword'}>
                <Input
                  onChange={handlePasswordChange('oldPassword')}
                  value={passwords.oldPassword}
                  type={'password'}
                  label={t('profile.oldPassword') || ''}
                  error={!!passwordsError.oldPassword}
                  errorText={passwordsError.oldPassword}
                />
                <Input
                  onChange={handlePasswordChange('newPassword')}
                  value={passwords.newPassword}
                  type={'password'}
                  label={t('profile.newPassword') || ''}
                  error={!!passwordsError.newPassword}
                />
                <Input
                  onChange={handlePasswordChange('newPasswordCopy')}
                  value={passwords.newPasswordCopy}
                  type={'password'}
                  label={t('profile.newPasswordCopy') || ''}
                  error={!!passwordsError.newPasswordCopy}
                  errorText={passwordsError.newPasswordCopy}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="profile__info">
        <h2 className="profile__title">{t('profile.rate_plan')}</h2>
        <div className="profile__container rate__container">
          <div className="column">
            <Text>
              {t(`rates.${currentRate?.name}`)}{' '}
              {currentRate?.name !== RateNames.TRIAL &&
                `за ${currentRate?.totalPrice} руб. ${t(`rates.${currentRate?.paymentPeriod}`)
                  .split(' ')
                  .slice(1)
                  .join(' ')}`}{' '}
            </Text>
            <Text
              color={!isExpiredRate() ? 'infoGray' : 'lightRed'}
              fontSize="0.875rem"
              fontWeight={600}
            >
              {isExpiredRate()
                ? t('rates.expired', { date: formatDate(currentRate?.expireDate) })
                : t('rates.expires', { date: formatDate(currentRate?.expireDate) })}
            </Text>
          </div>

          <div className="buttons-container">
            <Button type="button" onClick={handleButtonClick}>
              {getButtonText()}
            </Button>
            {isExpiredRate() && (
              <Button onClick={() => navigate('/rates')} btnStyle="gray">
                {t('rates.changeRatePlan')}
              </Button>
            )}
          </div>
        </div>
      </div>

      {history.length > 0 && (
        <div className="profile__info">
          <h2 className="profile__title">{t('rates.history')}</h2>
          <div className="profile__container rate__container">
            <div className={`rate-history-grid ${hasRefunds() ? 'col-5' : 'col-4'}`}>
              {['date', 'rate', 'period', 'sum'].map((item) => (
                <Text strong key={item}>
                  {t(`rates.headings.${item}`)}
                </Text>
              ))}
              {hasRefunds() && <Text></Text>}
              {history.map(({ purchaseDate, name, paymentPeriod, totalPrice, isActive }) => (
                <>
                  <Text>{formatDate(purchaseDate)}</Text>
                  <Text>{t(`rates.${name}` as string)}</Text>
                  <Text>{t(`rates.grid.${paymentPeriod}` as string)}</Text>
                  <Text>{totalPrice} руб.</Text>
                  {hasRefunds() && <Text> {isActive ? '' : 'Оформлен возврат'} </Text>}
                </>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProfilePage;

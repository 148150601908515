import React, {
  useState,
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useMemo,
} from 'react';
import styled, { css, keyframes } from 'styled-components';
import { useTranslation } from 'react-i18next';

const fade = keyframes`
  0% { opacity: 0 }
  100% { opacity: 1 }
`;

const DropdownWrapper = styled.div<{
  design?: string;
  optionsParams?: DOMRect;
}>`
  position: relative;
  ${({ design, theme, optionsParams }) =>
    design === 'corner-radius' &&
    css`
      & ${DropdownBtn} {
        padding: 0.625rem 1rem;
        &.isOpen {
          color: ${theme.primary};
        }
      }
      & ${OptionsWrapper} {
        top: calc(100% - 0.25rem);
        left: ${optionsParams?.width ? `calc(50% - ${optionsParams.width / 2}px)` : '0'};
        gap: 0;
        box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
        border-radius: 1rem;
        animation: ${fade} 0.125s ease-in-out;
      }
      & ${Option} {
        position: relative;
        padding: 0.625rem 1rem;
        border-radius: 0;
        width: 100%;
        color: ${theme.black};
        font-weight: 600;
        font-size: 0.875rem;
        line-height: 1.1875rem;
        background: ${theme.white};
        &:first-child {
          border-top-left-radius: 1rem;
          border-top-right-radius: 1rem;
        }
        &:last-child {
          border-bottom-left-radius: 1rem;
          border-bottom-right-radius: 1rem;
        }
        &:not(:first-child) {
          &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            height: 1px;
            width: 100%;
            background: ${theme.lightGray2};
          }
        }
        &.active,
        &:hover {
          &::after {
            opacity: 0;
          }
          & + * {
            &::after {
              opacity: 0;
            }
          }
        }
      }
    `};
`;

const DropdownBtn = styled.button.attrs({ type: 'button' })<{ isOpen?: boolean }>`
  display: flex;
  border: 0;
  padding: 0;
  background: transparent;
  color: ${(p) => p.theme.black};
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.1875rem;
  & > span[class^='icon-'] {
    font-size: 1.25rem;
    margin-left: 0.25rem;
  }
  &:not(:disabled) {
    cursor: pointer;
  }
  &:disabled {
    opacity: 0.15;
  }
`;

const OptionsWrapper = styled.div`
  position: absolute;
  top: calc(100% + 0.375rem);
  left: 0;
  background: transparent;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.375rem;
`;

const Option = styled.div`
  display: flex;
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.375em;
  padding: 0.71875em 1em;
  border-radius: 16px;
  color: ${(p) => p.theme.black};
  text-decoration: none;
  transition: all 250ms ease-in-out;
  background-color: ${(p) => p.theme.lightGray2};
  white-space: nowrap;
  cursor: pointer;
  &.active,
  &:hover {
    color: ${(p) => p.theme.primary};
    background: linear-gradient(0deg, rgba(102, 32, 199, 0.4), rgba(102, 32, 199, 0.4)),
      ${(p) => p.theme.white};
  }
`;

type ObjectOptionType = { value: any; name: string };

type OptionType = ObjectOptionType | string | number;

interface IProps {
  value: any;
  handleChange: (value: any) => void;
  options: OptionType[];
  disabled?: boolean;
  translateKey?: string;
  hideSelected?: boolean;
  design?: string;
}

const Dropdown = forwardRef(function Dropdown(
  { value, handleChange, options, disabled, translateKey, hideSelected, design }: IProps,
  ref,
) {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [isModified, setIsModified] = useState(false);
  const [localOptions, setLocalOptions] = useState<ObjectOptionType[]>([]);
  const [dropdownValue, setDropdownValue] = useState(value);
  const [minWidth, setMinWidth] = useState<string>();
  const [optionsWrapperParams, setOptionsWrapperParams] = useState<DOMRect>();
  const wrapperRef = useRef<any>(null);
  const optionsWrapperRef = useRef<any>(null);

  useEffect(() => {
    if (!isModified && options?.length) {
      if ([...options].every((i: OptionType) => typeof i === 'string' || typeof i === 'number')) {
        setLocalOptions([...options].map((item) => ({ value: item, name: `${item}` })));
        return;
      }

      setLocalOptions(options as ObjectOptionType[]);
      setIsModified(true);
    }
  }, [isModified]);

  useImperativeHandle(
    ref,
    () => {
      return {
        close() {
          setIsOpen(false);
        },
      };
    },
    [],
  );

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  const handleClick = (newValue: any) => {
    setDropdownValue(newValue);
    handleChange(newValue);
    setIsOpen(false);
  };

  const getName = useMemo(() => {
    if (!dropdownValue || !options?.length) {
      return t('action.chooseValue');
    }

    const option = localOptions.find((o) => o.value === dropdownValue);
    if (option?.name && translateKey) {
      return t(`${translateKey}.${option?.name}`);
    }

    return option?.name ?? t('action.chooseValue');
  }, [dropdownValue, localOptions]);

  useEffect(() => {
    if (isOpen) {
      setOptionsWrapperParams(optionsWrapperRef?.current?.getBoundingClientRect());
    }
  }, [isOpen]);

  useEffect(() => {
    if (localOptions.length > 0) {
      const sortedOptions = [...localOptions]
        .map((option) => ({
          ...option,
          name: translateKey ? t(`${translateKey}.${option.name}`) : option.name,
        }))
        .sort((a, b) => b.name?.length - a.name?.length);
      const currentName = getName;
      const maxLengthOptionName = sortedOptions[0].name;
      if (maxLengthOptionName?.length > currentName?.length) {
        setMinWidth(`${maxLengthOptionName.length * 10}px`);
      }
    }
  }, [localOptions, getName]);

  return (
    <DropdownWrapper ref={wrapperRef} design={design} optionsParams={optionsWrapperParams}>
      <DropdownBtn
        className={isOpen ? 'isOpen' : ''}
        onClick={() => setIsOpen(!isOpen)}
        disabled={disabled}
        style={{ minWidth }}
      >
        {getName}
        <span className={`icon-chevron-${isOpen ? 'up' : 'down'}`} />
      </DropdownBtn>
      {isOpen && (
        <OptionsWrapper ref={optionsWrapperRef}>
          {localOptions
            .filter((option) => !(hideSelected && option.value === value))
            .map((option, index) => (
              <Option
                key={`${index}_${option.value}`}
                onClick={() => handleClick(option.value)}
                className={option.value === dropdownValue ? 'active' : ''}
              >
                {translateKey ? t(`${translateKey}.${option?.name}`) : option.name}
              </Option>
            ))}
        </OptionsWrapper>
      )}
    </DropdownWrapper>
  );
});

export default Dropdown;

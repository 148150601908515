import styled, { css } from 'styled-components';
import { Flex } from '../../flexbox.component';

export const SettingsWrapper = styled.div`
  position: relative;
  z-index: 100;
`;

export const SettingsDivider = styled.div<{ nonActive: boolean }>`
  ${({ nonActive }) =>
    !nonActive &&
    css`
      border-bottom: 1px solid #0000001a;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
    `}
`;

export const Option = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  position: absolute;
  top: 40px;
  right: 0;
  width: fit-content;
  height: fit-content;
  background-color: #ffffff;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0px 6px 20px 0px #00000026;

  &:hover {
    ${SettingsDivider} {
      border-bottom: none;
    }
  }
`;

export const SettingOptionName = styled.span<{ width?: string }>`
  position: relative;
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  color: #000000;
  padding: 10.5px 15px;

  &:hover {
    background-color: #6620c766;
    color: #6620c7;
  }

  ${({ width }) =>
    width &&
    css`
      width: ${width};
    `}
`;

export const SettingIcon = styled.div<{ dropDownOpen: boolean }>`
  font-size: 1.25rem;
  cursor: pointer;
  padding: 5px;
  border-radius: 8px;
  position: relative;
  box-shadow: 0px 1px 2px 0px #00000040;
  background-color: ${({ dropDownOpen }) => (dropDownOpen ? '#6620C7' : '#e6e6e6')};
  color: ${({ dropDownOpen }) => (dropDownOpen ? '#fff' : '#000')};
  transition: all 0.1s ease-in-out;
  &:hover {
    background-color: #6620c7;
    color: #fff;
  }
`;

export const TabsWrapper = styled.div<{ row: boolean }>`
  ${({ row }) =>
    row ? 'display: flex; justify-content: space-between; flex-direction: row-reverse;' : ''}
`;
export const TabsTitle = styled.div<{ active?: boolean }>`
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  cursor: pointer;
  margin-top: 10px;
  color: ${(p) => (p.active ? '#000000' : '#00000066')};
`;

export const TabsTitleList = styled.div`
  display: flex;
  gap: 15px;
  margin: 10px 0;
`;

export const ContentWrapper = styled.div`
  width: 100%;
  height: fit-content;
`;

export const ModalTitle = styled.span`
  font-size: 23px;
  font-weight: 700;
  line-height: 31px;
`;

export const ModalDescription = styled.span`
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
`;

export const FooterModal = styled(Flex)`
  width: 100%;
  justify-content: flex-end;
`;

import React, { useEffect, useState, useRef } from 'react';
import Components, {
  WhiteHeader,
  FoldersWrapper,
  CustomButton,
  CustomProjectColumn,
  SmallImgWrap,
  ChooseFile,
} from '../trash/trash.style';
import { useTranslation } from 'react-i18next';
import { IconWithText, Text } from '../../components/text.component';
import { Button } from '../../components/button.component';
import { MenuList } from '../menu';
import { useNavigate, useLocation } from 'react-router-dom';
import { Row } from '../../components/grid.component';
import {
  NewCardButton,
  ButtonsWrap,
  CustomImgCard,
  ProjectColumn,
  AddToFolderModalContent,
  FolderInput,
  CustomCard,
} from './folders.style';
import { Card } from '../../components/card.component';
import { Modal } from '../../components/modal.component';
import { Flex, FlexCol } from '../../components/flexbox.component';
import { deleteProject, getProjects } from '../../requests/project.requests';
import {
  addLibraryImage,
  addProject,
  getLibraryImages,
  getFolderProjects,
  removeFromFolder,
  renameFolder,
} from '../../requests/folder.requests';
import { ICreateFolderRequest, IImages, IProject } from '../../interfaces/projects.interface';
import plus from '../../assets/icons/plus.svg';
import { deleteFile, getImages } from '../../requests/files.requests';
import { recoverItem } from '../../requests/trash.requests';
import { LeftColumn, ProjectNameInput, RightColumn } from '../my-projects/my-projects.style';
import styled from 'styled-components';
import ProjectListLoader from '../../components/loaders/project-list.component';
import ImageListLoader from '../../components/loaders/image-list.component';
import SkeletonLoader from '../../components/skeleton-loader.component';
import { FILE_SORT, FOLDER_SORT, fallbackImage } from '../../constants/shared.constants';
import SortDropdown from '../../components/sort-dropdown.component';
import { ASCSort } from '../../interfaces/editor.interface';
import { useNotification } from '../../contexts/notification.context';
import PagePagination from '../../components/pagination/page-pagination';
import ModalPagination from '../../components/pagination/modal.pagination';
import { calculateImageSize, calculateNewDimensions } from '../../utils/calculateImageSize';
import { FileName } from '../files/files.style';
import CircleLoader2 from '../../components/circle-loader2';
import ModalHeader from '../../components/modal/modal.header';
import useSortSearch from '../../hooks/use-sort-search.hooks';

const PageHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 75%;
  @media (min-width: 1280px) {
    width: 80%;
  }
`;

const StyledRow = styled(Row)`
  max-height: 265px;
  overflow-y: auto;
  padding: 1px;
  gap: 10px;
  ::-webkit-scrollbar {
    width: 8px;
    margin-left: 20px;
  }

  @media (max-height: 628px) {
    max-height: 175px;
  }
`;

const ModalImageListRow = styled(Row)`
  max-height: 45vh;
  overflow-y: scroll;
  gap: 10px;
  padding: 12px;
  margin-bottom: 0px;

  @media (max-height: 663px) {
    max-height: 30vh;
  }
`;

const tabs = ['projects', 'files'];
const COUNT_OF_LOADER_PROJECTS = 8;
const COUNT_OF_LOADER_IMAGES = 12;
const COUNT_OF_LOADER_ALL_PROJECTS = 6;

export const OpenFolderPage = () => {
  const { t } = useTranslation();
  const { setNotification, closeNotification } = useNotification();

  const [activeTab, setActiveTab] = useState<string>('projects');
  const [recoverType, setRecoverType] = useState<string>('PROJECT');
  const [chosenItemId, setChosenItemId] = useState<number>();
  const [showFilesModal, setShowFilesModal] = useState<boolean>(false);
  const [showProjectsModal, setShowProjectsModal] = useState<boolean>(false);
  const [showRemovedNotification, setShowRemovedNotification] = useState<boolean>(false);
  const [folderImageList, setFolderImageList] = useState<IImages[]>([]);
  const [folderProjectList, setFolderProjectList] = useState<any[]>([]);
  const [allImages, setAllImages] = useState<IImages[]>([]);
  const [loadingAllImages, setLoadingAllImages] = useState<boolean>(false);
  const [allProjects, setAllProjects] = useState<IProject[]>([]);
  const [loadingAllProjects, setLoadingAllProjects] = useState<boolean>(false);

  const [chosenImages, setChosenImages] = useState<number[]>([]);
  const [chosenProjects, setChosenProjects] = useState<number[]>([]);
  const [folderName, setFolderName] = useState<string>('');
  const [isRenaming, setIsRenaming] = useState<boolean>(false);
  const [loadingFolderProjects, setLoadingFolderProjects] = useState<boolean>(false);
  const [sortAscFile, setSortAscFile] = useState<ASCSort>('DESC');
  const [sortAscFolder, setSortAscFolder] = useState<ASCSort>('DESC');

  const [selectedOptionFile, setSelectedOptionFile] = useState(FILE_SORT[0]);
  const [selectedOptionFolder, setSelectedOptionFolder] = useState(FOLDER_SORT[0]);

  const [imageListPage, setImageListPage] = useState<number>(0);
  const [projectListPage, setProjectListPage] = useState<number>(0);

  const navigate = useNavigate();
  const location = useLocation();
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const [folderInfo, setFolderInfo] = useState(location.state?.folderInfo || {});
  const [loadingImageList, setLoadingImageList] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [allProjectPage, setAllProjectPage] = useState<number>(0);
  const [allImagePage, setAllImagePage] = useState<number>(0);
  const [allProjectTotalPages, setAllProjectTotalPages] = useState<number>(0);
  const [allImageTotalPages, setAllImageTotalPages] = useState<number>(0);
  const [projectTotalPages, setProjectTotalPages] = useState<number>(0);
  const [imageTotalPages, setImageTotalPages] = useState<number>(0);
  const [customCardOption, setCustomCardOption] = useState<boolean>(false);
  const [loadingImageId, setLoadingImageId] = useState<number | null>(null);
  const [showZoomModal, setShowZoomModal] = useState<boolean>(false);
  const [chosenImage, setChosenImage] = useState<IImages | undefined>(undefined);
  const [imageSizeWithName, setImageSizeWithName] = useState({ width: 0, height: 0 });
  const [imageSize, setImageSize] = useState({ width: 0, height: 0 });
  const projectSort = useSortSearch(FOLDER_SORT[1]);
  const fileSort = useSortSearch(FILE_SORT[0]);

  if (Object.keys(folderInfo).length === 0) navigate('/folders');

  const fileOptions = (id: number, type: string) => {
    return (
      <FlexCol gap={6} alignItems={'flex-start'} style={{ width: 'max-content' }}>
        <CustomButton onClick={() => handleRemoveFromFolder(id, type)}>
          {t(`action.removeFromFolder`)}
        </CustomButton>
        <CustomButton onClick={() => handleDeleteFile(id, type)}>
          {t(`action.toTrash`)}
        </CustomButton>
      </FlexCol>
    );
  };

  const fetchData = () => {
    getFolderImagesList(sortAscFile, selectedOptionFile.value);
    getFolderProjectsList(sortAscFolder, selectedOptionFolder.value);
    getAllProjectsList();
  };

  const handleDeleteFile = (id: number, type: string) => {
    setChosenItemId(id);
    if (type === 'LIBRARY_IMAGE') {
      setRecoverType('IMAGE');
      deleteFile(id)
        .then(() => {
          setNotification({
            text: `Вы перенесли ${recoverType === 'IMAGE' ? 'файл' : 'проект'} в корзину`,
            handleFunction: () => handleRecoverItem(id, 'IMAGE'),
            buttonText: 'Отменить',
          });
          getAllImagesList();
        })
        .finally(fetchData);
    } else {
      setRecoverType('PROJECT');
      deleteProject(id)
        .then(() => {
          setNotification({
            text: `Вы перенесли ${recoverType === 'IMAGE' ? 'файл' : 'проект'} в корзину`,
            handleFunction: () => handleRecoverItem(id, 'PROJECT'),
            buttonText: 'Отменить',
          });
          getAllProjectsList();
        })
        .finally(fetchData);
    }
  };

  const handleRecoverItem = (id: number, recoverType: string) => {
    recoverItem(id, recoverType)
      .then(() => {
        getFolderImagesList(sortAscFile, selectedOptionFile.value);
        getFolderProjectsList(sortAscFolder, selectedOptionFolder.value);
        closeNotification();
      })
      .finally(() => setChosenItemId(0));
  };

  const handleRemoveFromFolder = (id: number, type: string) => {
    setShowRemovedNotification(false);
    if (type === 'LIBRARY_IMAGE') {
      setRecoverType('IMAGE');
      setChosenImages([id]);
    } else {
      setRecoverType('PROJECT');
      setChosenProjects([id]);
    }
    removeFromFolder({
      folderId: folderInfo.id,
      folderItemId: id,
      folderItemType: type,
    })
      .then(() => {
        setNotification({
          text: `Вы убрали ${recoverType === 'IMAGE' ? 'файл' : 'проект'} из папки «${
            folderInfo.folderName
          }»`,
          handleFunction:
            type === 'LIBRARY_IMAGE' ? () => handleAddFiles([id]) : () => handleAddProjects([id]),
          buttonText: 'Отменить',
        });
        getFolderImagesList(sortAscFile, selectedOptionFile.value);
        getFolderProjectsList(sortAscFolder, selectedOptionFolder.value);
      })
      .catch(() =>
        setNotification({
          text: 'Произошла ошибка, повторите позже',
          errorNotification: true,
        }),
      );
  };

  const handleAddProjects = (chosenProjects: number[]) => {
    setLoading(true);
    addProject(folderInfo.id, chosenProjects)
      .then(() => {
        getFolderProjectsList(sortAscFolder, selectedOptionFolder.value);
        setShowRemovedNotification(false);
        closeNotification();
      })
      .catch((error) => {
        const text = error?.response?.data?.msg ?? 'Произошла ошибка, повторите позже';
        setNotification({
          text: text,
          errorNotification: true,
        });
      })
      .finally(() => {
        setShowProjectsModal(false);
        setChosenProjects([]);
        setLoading(false);
      });
  };

  const handleAddFiles = (chosenImages: number[]) => {
    setLoading(true);
    addLibraryImage(folderInfo.id, chosenImages)
      .then(() => {
        getFolderImagesList(sortAscFile, selectedOptionFile.value);
        setShowRemovedNotification(false);
      })
      .catch((error) => {
        const text = error?.response?.data?.msg ?? 'Произошла ошибка, повторите позже';
        setNotification({
          text: text,
          errorNotification: true,
        });
      })
      .finally(() => {
        setShowFilesModal(false);
        setChosenImages([]);
        setLoading(false);
      });
  };

  const getFolderImagesList = (
    orderType: ASCSort,
    sortingType: string,
    name?: string,
    page?: number,
  ) => {
    setLoadingImageList(true);
    getLibraryImages(folderInfo.id, name, sortingType, orderType, page)
      .then((res: any) => {
        setImageTotalPages(res.totalPages);
        setFolderImageList(res.list);
        setLoadingImageList(false);
      })
      .catch(() => {
        setLoadingImageList(false);
      });
  };

  const getFolderProjectsList = (orderType?: ASCSort, sortingType?: string, page?: number) => {
    setLoadingFolderProjects(true);
    getFolderProjects(folderInfo.id, orderType, sortingType, page)
      .then((res: any) => {
        setProjectTotalPages(res.totalPages);
        setFolderProjectList(res.list);
        setLoadingFolderProjects(false);
      })
      .catch(() => {
        setLoadingFolderProjects(false);
      });
  };

  const getAllImagesList = () => {
    setLoadingAllImages(true);
    getImages(undefined, fileSort.selectedOption.value, fileSort.sortAsc, fileSort.page, 18).then(
      (res: any) => {
        setAllImageTotalPages(res.totalPages);
        setAllImages(res.list);
        setLoadingAllImages(false);
      },
    );
  };

  const getAllProjectsList = () => {
    setLoadingAllProjects(true);
    getProjects(
      undefined,
      projectSort.sortAsc,
      projectSort.page,
      projectSort.selectedOption.value,
      9,
    )
      .then((res: any) => {
        setAllProjectTotalPages(res.totalPages);
        setAllProjects(res.list);
        setLoadingAllProjects(false);
      })
      .catch(() => null);
  };

  const handleChooseImage = (item?: any) => {
    if (chosenImages.includes(item.id)) {
      setChosenImages(chosenImages.filter((id) => id !== item.id));
    } else {
      setChosenImages((prevarr) => [...prevarr, item.id]);
    }
  };

  const handleChooseProjects = (item?: any) => {
    if (chosenProjects.includes(item.id)) {
      setChosenProjects(chosenProjects.filter((id) => id !== item.id));
    } else {
      setChosenProjects((prevarr) => [...prevarr, item.id]);
    }
  };

  const handleProjectClick = (id: number) => {
    navigate(`/project/${id}`);
  };

  useEffect(() => {
    if (isRenaming && inputRef.current) {
      inputRef.current.focus();
      const length = inputRef.current.value.length;
      inputRef.current.selectionStart = length;
      inputRef.current.selectionEnd = length;
    }
  }, [isRenaming]);

  const handleFolderNameChange = (folder: ICreateFolderRequest, folderName: string) => {
    setIsRenaming(false);
    if (folderName && folderName !== folder.folderName) {
      renameFolder({ id: folder.id, newName: folderName });
      setFolderInfo((prevFolderInfo: any) => ({
        ...prevFolderInfo,
        folderName: folderName,
      }));
    }
    folderName && setFolderName(folderName);
  };

  const handleImageClick = (imageId: number) => {
    setLoadingImageId(imageId);
    const img = new Image();
    img.src = `/api/project/load/image/png?imageId=${imageId}&imageSizeType=NORMAL`;
    img.onload = () => {
      const value = calculateImageSize(img);
      setImageSize(value);
      setShowZoomModal(true);
      setLoadingImageId(null);
    };
  };

  useEffect(() => {
    navigate('/folders/item', { state: { folderInfo: folderInfo } });
  }, [folderInfo, navigate]);

  useEffect(() => {
    getAllImagesList();
  }, [fileSort.page, fileSort.sortAsc, fileSort.selectedOption]);

  useEffect(() => {
    getAllProjectsList();
  }, [projectSort.sortAsc, projectSort.selectedOption, projectSort.page]);

  useEffect(() => {
    getFolderImagesList(sortAscFile, selectedOptionFile.value, undefined, imageListPage);
  }, [sortAscFile, selectedOptionFile.value, imageListPage]);

  useEffect(() => {
    getFolderProjectsList(sortAscFolder, selectedOptionFolder.value, projectListPage);
  }, [sortAscFolder, selectedOptionFolder.value, projectListPage]);

  useEffect(() => {
    const result = calculateNewDimensions(imageSize.width, imageSize.height);
    setImageSizeWithName(result);
  }, [imageSize.width, imageSize.height]);

  return (
    <Components.Wrapper>
      <LeftColumn>
        <MenuList />
      </LeftColumn>
      <RightColumn
        style={{
          maxWidth: '100%',
        }}
      >
        <PageHeader>
          <WhiteHeader>
            <Button
              icon="undo"
              btnStyle={'cancel'}
              onClick={() => navigate('/folders')}
              style={{ justifyContent: 'flex-start', gap: '5px', padding: '0px' }}
              iconPosition="left"
            >
              {t(`projects.folders`)}
            </Button>

            <IconWithText
              style={{ gap: '10px', alignItems: 'center' }}
              hover
              iconLast
              onClick={() => {
                setIsRenaming(true);
              }}
            >
              {isRenaming ? (
                <FolderInput
                  ref={inputRef}
                  style={{ width: '100%' }}
                  key={folderInfo.id}
                  defaultValue={folderName || folderInfo.folderName || t('myProjects.withoutName')}
                  rows={1}
                  onBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
                    folderInfo.id && handleFolderNameChange(folderInfo, e.target.value)
                  }
                  maxLength={100}
                />
              ) : (
                <Text
                  ellipsis
                  fontSize="23px"
                  strong
                  style={{ padding: '2px', lineHeight: '31px' }}
                >
                  {folderName || folderInfo?.folderName}
                </Text>
              )}

              {!isRenaming && (
                <Text fontSize="1.25rem" lineHeight="1em" className="icon-edit"></Text>
              )}
            </IconWithText>

            <FoldersWrapper>
              <div>
                {tabs.map((item, idx) => (
                  <CustomButton
                    key={idx}
                    active={activeTab === item}
                    onClick={() => setActiveTab(item)}
                  >
                    {t(`projects.${item}`)}
                    {item === 'files' &&
                      folderImageList.length !== undefined &&
                      !loadingImageList &&
                      ` (${folderImageList.length})`}
                    {item === 'projects' &&
                      folderProjectList.length !== undefined &&
                      !loadingFolderProjects &&
                      ` (${folderProjectList.length})`}
                  </CustomButton>
                ))}
              </div>
              {activeTab === 'files' ? (
                <SortDropdown
                  selection={FILE_SORT}
                  setSelectedOption={setSelectedOptionFile}
                  selectedOption={selectedOptionFile.title}
                  sortOrder={sortAscFile}
                  setSortOrder={setSortAscFile}
                  width={'170px'}
                />
              ) : (
                <SortDropdown
                  selection={FOLDER_SORT}
                  setSelectedOption={setSelectedOptionFolder}
                  selectedOption={selectedOptionFolder.title}
                  sortOrder={sortAscFolder}
                  setSortOrder={setSortAscFolder}
                  width={'170px'}
                />
              )}
            </FoldersWrapper>
          </WhiteHeader>
          {activeTab === 'files' && (
            <Row adaptive>
              {loadingImageList ? (
                <ImageListLoader count={COUNT_OF_LOADER_IMAGES} />
              ) : (
                <>
                  <NewCardButton onClick={() => setShowFilesModal(true)} isScaled>
                    <Text fontSize="23px" color="gray" strong>
                      Добавить файл
                    </Text>
                    <img src={plus} alt="plus" />
                  </NewCardButton>
                  {folderImageList.map((item) => (
                    <CustomImgCard
                      onPopoverActiveChange={setCustomCardOption}
                      key={item.id}
                      customOptions={fileOptions(item.id, 'LIBRARY_IMAGE')}
                      cursor={customCardOption ? 'cursor' : 'zoom-in'}
                      onClick={() => {
                        handleImageClick(item.imageId);
                        setChosenImage(item);
                      }}
                      {...(!customCardOption && {
                        'data-tooltip': item.imageName + '.' + item.imageType?.toLowerCase(),
                      })}
                      {...(!customCardOption && { 'data-tooltip-position': 'bottom' })}
                    >
                      <img
                        src={`/api/project/load/image/png?imageId=${item.imageId}&imageSizeType=ICON`}
                        alt="img"
                        style={{
                          filter: loadingImageId === item.imageId ? 'blur(5px)' : 'none',
                        }}
                      />
                      {loadingImageId === item.imageId && (
                        <div
                          style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                          }}
                        >
                          <CircleLoader2 />
                        </div>
                      )}
                    </CustomImgCard>
                  ))}
                </>
              )}
            </Row>
          )}
          {activeTab === 'projects' && (
            <Row adaptive>
              {loadingFolderProjects ? (
                <ProjectListLoader count={COUNT_OF_LOADER_PROJECTS} />
              ) : (
                <>
                  <ProjectColumn onClick={() => setShowProjectsModal(true)}>
                    <Card color={'gray'} onClick={() => setShowProjectsModal(true)}>
                      <Flex gap={4} mb={10}>
                        <Components.Thumbnail squared clickable scalableImage>
                          <img src={plus} alt="plus" />
                        </Components.Thumbnail>
                      </Flex>
                      <Text fontSize="16px" color="infoGray">
                        Добавить проект
                      </Text>
                    </Card>
                  </ProjectColumn>
                  {folderProjectList.map((item) => (
                    <ProjectColumn key={item.id} onClick={() => handleProjectClick(item.id)}>
                      <Card customOptions={fileOptions(item.id, 'PROJECT')}>
                        <Flex gap={4}>
                          <Components.Thumbnail
                            squared
                            url={
                              item.projectItems[0]
                                ? `/api/project/load/image/png?imageId=${item.projectItems[0].modifiedImageId}&imageSizeType=ICON`
                                : ''
                            }
                          />
                        </Flex>
                        <div style={{ margin: '20px 0' }}>
                          <Text fontSize="14px" color="infoGray" ellipsis>
                            {folderInfo?.folderName}
                          </Text>
                          <ProjectNameInput
                            key={item.id}
                            defaultValue={item.projectName || t('myProjects.withoutName')}
                            rows={2}
                            disabled={true}
                            maxLength={100}
                          />
                        </div>
                      </Card>
                    </ProjectColumn>
                  ))}
                </>
              )}
            </Row>
          )}
          <PagePagination
            page={activeTab === 'projects' ? projectListPage : imageListPage}
            setPage={activeTab === 'projects' ? setProjectListPage : setImageListPage}
            totalCount={activeTab === 'projects' ? projectTotalPages : imageTotalPages}
          />
        </PageHeader>
      </RightColumn>
      {showFilesModal && (
        <Modal width="49.25rem" onClose={() => setShowFilesModal(false)}>
          <AddToFolderModalContent type="file">
            <ModalHeader
              sort={fileSort}
              type="file"
              action="add"
              sortType="file"
              isLoading={loadingAllImages}
            />
            <ModalImageListRow gutter={28}>
              {loadingAllImages
                ? Array.from({ length: 12 }).map((_, index) => (
                    <SkeletonLoader key={index} width="115px" height="115px" borderRadius="16px" />
                  ))
                : allImages.map((item: IImages) => (
                    <ChooseFile
                      key={item.id}
                      onClick={() => folderInfo.id !== item.folderId && handleChooseImage(item)}
                      active={chosenImages.includes(item.id) || folderInfo.id === item.folderId}
                    >
                      <img
                        src={`/api/project/load/image/png?imageId=${item.imageId}&imageSizeType=ICON`}
                        alt="img"
                      />
                    </ChooseFile>
                  ))}
            </ModalImageListRow>
            {loadingAllImages ? (
              <Flex gap={20} alignItems="center" justifyContent="end">
                <SkeletonLoader width="78px" height="22px" />
                <SkeletonLoader
                  width="172px"
                  height="50px"
                  borderRadius="16px"
                  baseColor="#00000033"
                />
              </Flex>
            ) : (
              <>
                <ModalPagination
                  page={fileSort.page}
                  setPage={fileSort.setPage}
                  totalCount={allImageTotalPages}
                />
                <ButtonsWrap>
                  <Button
                    btnStyle={'cancel'}
                    onClick={() => {
                      setShowFilesModal(false);
                      setChosenImages([]);
                    }}
                  >
                    Отменить
                  </Button>
                  <Button
                    onClick={() => handleAddFiles(chosenImages)}
                    icon={'plus'}
                    disabled={chosenImages.length === 0}
                    isLoading={loading}
                  >
                    Добавить
                  </Button>
                </ButtonsWrap>
              </>
            )}
          </AddToFolderModalContent>
        </Modal>
      )}
      {showZoomModal && chosenImage && (
        <Modal
          contentPadding="0"
          onClose={() => {
            setShowZoomModal(false);
            setChosenImage(undefined);
          }}
          width={`calc(${imageSizeWithName.width}px + 40px)`}
          height={`${imageSize.height}px`}
        >
          <FlexCol justifyContent="center" alignItems="center" gap={20}>
            <FileName>
              {chosenImage && chosenImage.imageName + '.' + chosenImage.imageType?.toLowerCase()}
            </FileName>
            {chosenImage ? (
              <img
                style={{
                  height: imageSizeWithName.height,
                  width: imageSizeWithName.width,
                }}
                src={`/api/project/load/image/png?imageId=${chosenImage.imageId}&imageSizeType=NORMAL`}
              />
            ) : (
              <CircleLoader2 />
            )}
          </FlexCol>
        </Modal>
      )}
      {showProjectsModal && (
        <Modal onClose={() => setShowProjectsModal(false)} width={'782px'}>
          <AddToFolderModalContent type="project" style={{ padding: 0 }}>
            <ModalHeader
              sort={projectSort}
              type="project"
              action="add"
              sortType="folder"
              isLoading={loadingAllProjects}
            />
            <StyledRow>
              {loadingAllProjects ? (
                <>
                  {Array.from({ length: COUNT_OF_LOADER_ALL_PROJECTS }).map(() => (
                    <>
                      <CustomCard
                        style={{
                          height: '100%',
                          background:
                            'linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), rgb(255, 255, 255)',
                        }}
                      >
                        <Flex gap={10} alignItems="center">
                          <SkeletonLoader width="34px" height="51px" />
                          <FlexCol gap={4}>
                            <SkeletonLoader width="166px" height="20px" />
                            <SkeletonLoader width="140px" height="20px" />
                          </FlexCol>
                        </Flex>
                      </CustomCard>
                    </>
                  ))}
                </>
              ) : (
                allProjects.map((item: IProject) => (
                  <CustomProjectColumn
                    style={{ cursor: 'pointer' }}
                    key={item.id}
                    onClick={() => {
                      folderProjectList.some((project) => project.id === item.id)
                        ? null
                        : handleChooseProjects(item);
                    }}
                    active={
                      chosenProjects.includes(item.id) ||
                      folderProjectList.some((project) => project.id === item.id)
                    }
                  >
                    <SmallImgWrap>
                      <img
                        src={`/api/project/load/image/png?imageId=${item.projectItems[0].modifiedImageId}&imageSizeType=ICON`}
                        alt={item.projectName}
                        width={'100%'}
                        height={'100%'}
                        onError={(e) => {
                          const target = e.target as HTMLImageElement;
                          target.onerror = null;
                          target.src = fallbackImage;
                        }}
                      />
                    </SmallImgWrap>
                    <Text fontSize="16px">{item.projectName || t('myProjects.withoutName')}</Text>
                  </CustomProjectColumn>
                ))
              )}
            </StyledRow>
            {loadingAllProjects ? (
              <Flex gap={20} alignItems="center" justifyContent="end">
                <SkeletonLoader width="78px" height="22px" />
                <SkeletonLoader
                  width="172px"
                  height="50px"
                  borderRadius="16px"
                  baseColor="#00000033"
                />
              </Flex>
            ) : (
              <>
                <ModalPagination
                  page={projectSort.page}
                  setPage={projectSort.setPage}
                  totalCount={allProjectTotalPages}
                />
                <ButtonsWrap>
                  <Button
                    btnStyle={'cancel'}
                    onClick={() => {
                      setShowProjectsModal(false);
                      setChosenProjects([]);
                    }}
                  >
                    Отменить
                  </Button>
                  <Button
                    icon={'plus'}
                    onClick={() => handleAddProjects(chosenProjects)}
                    disabled={chosenProjects.length === 0}
                    isLoading={loading}
                  >
                    Добавить
                  </Button>
                </ButtonsWrap>
              </>
            )}
          </AddToFolderModalContent>
        </Modal>
      )}
    </Components.Wrapper>
  );
};

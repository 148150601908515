import React, { FC, useEffect, useState } from 'react'
import { PurchaseRateTypes, RateNames, RatePeriods } from '../../constants/rates.constant';
import { useTranslation } from 'react-i18next';
import { Text } from '../text.component';
import { Button } from '../button.component';
import { RateItem, RatesContainer } from './rates.style';
import { getRateList, purchaseRate } from '../../requests/tariff.request';
import CircleLoader2 from '../circle-loader2';
import ChangeRateModal from './change-rate-modal.component';
import { useUserContext } from '../../contexts/user.context';

interface IProps {
  type: string;
  isFiltered?: boolean;
  variant?: string;
}
const RateList: FC<IProps> = ({ type, variant = 'normal', isFiltered = false }) => {
  const { t } = useTranslation();
  const { userData, updateUserDetails } = useUserContext();

  const [data, setData] = useState<any[]>([]);
  const [currentRate, setCurrentRate] = useState<any>(null);
  const [chosenRate, setChosenRate] = useState<any>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isLimitExceeded, setIsLimitExceeded] = useState<boolean>(false);
  const [isShorterPeriod, setIsShorterPeriod] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getList = () => {
    getRateList(type as RatePeriods).then((res: any) => {
      if (isFiltered) {
        if (userData) {
          const { rate } = userData
          setCurrentRate(rate)
          const list = res.list.slice(res.list.indexOf(res.list.find((item: any) => item.name === rate?.name)) + 1, res.list.length)
          setData(list.length === 0 ? res.list : list);
        }
      } else {
        setData(res.list);
      }
    })
  }

  const subscribe = (rate: any) => {
    if (isLoading) return;

    if (currentRate?.name === RateNames.TRIAL) {
      purchaseRate(rate.id, PurchaseRateTypes.NOW).then(() => {
        updateUserDetails();
        setIsLoading(false);
      })
    } else {
      if (rate.projectLimit !== null) {
        setIsLimitExceeded(currentRate && rate.projectLimit < currentRate.existingProjectCount);
      } else {
        setIsLimitExceeded(false);
      }

      const list = [RatePeriods.MONTHLY, RatePeriods.QUARTERLY, RatePeriods.BIANNUALLY, RatePeriods.ANNUALLY];

      if (list.indexOf(rate.paymentPeriod) < list.indexOf(currentRate?.paymentPeriod)) {
        setIsShorterPeriod(true);
      }

      setChosenRate(rate);
      setIsOpen(true);
      setIsLoading(false);
    }

  };

  useEffect(() => {
    getList();
  }, [type, currentRate?.id, userData])

  useEffect(() => {
    if (userData) {
      setCurrentRate(userData.rate);
    }
  }, [userData])
  return (
    <>
      {data && currentRate &&
        <RatesContainer>
          {
            data && data?.map((rate) =>
              <RateItem key={rate.id} type={rate.name} variant={variant}>
                <div className="rate-header">
                  <div className="sb">
                    <Text fontSize='1.4375rem' strong>{t(`rates.${rate.name}`)}</Text>
                    {rate.name === RateNames.ADVANCED && <div className="tag"> <span className="icon-star" /> <Text color='primary' fontWeight={800} fontSize='0.875rem'>{t('rates.popular')}</Text> </div>}
                  </div>
                  <Text>{rate.name !== RateNames.UNLIMITED ? rate.projectLimit + ' проектов' : t('rates.no-limit')}</Text>
                </div>
                <div className="footer">
                  <div>
                    <div className="price">
                      <div className="current">
                        <Text fontSize='3rem' fontWeight={600}>{rate.currentPrice}</Text>
                        <Text fontSize='1.4375rem' strong>руб.</Text>
                      </div>

                      {
                        rate.discount > 0 ?
                          <div className="original">
                            <s>
                              <Text strong >{rate.originalPrice} руб.</Text>
                            </s>
                            <Text color='lightRed' strong>-{rate.discount}%</Text>
                          </div>
                          : <></>
                      }

                    </div>
                    <Text fontSize='0.875rem' color='infoGray' fontWeight={600}>{t(`rates.periodText.${type}`, { price: rate.totalPrice })}</Text>
                  </div>
                  {
                    rate.id === currentRate?.id
                      ? <Button className='current-rate'>{t('rates.current-rate')}</Button>
                      : <Button onClick={() => chosenRate === null && subscribe(rate)}> {chosenRate?.id === rate.id ? <CircleLoader2 /> : t('rates.subscribe')}</Button>
                  }
                </div>
              </RateItem>
            )
          }
        </RatesContainer>}

      <ChangeRateModal
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false)
          setChosenRate(null)
        }}
        isLimitExceeded={isLimitExceeded}
        rate={chosenRate}
        currentRate={currentRate}
        isShorterPeriod={isShorterPeriod}
      />
    </>

  )
}

export default RateList
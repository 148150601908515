import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Components, { LeftColumn } from '././my-projects/my-projects.style';
import { FlexCol } from '../components/flexbox.component';
import { Headline } from '../components/headline.component';
import ProjectsCounter from '../components/rates/projects-counter.component';
import ContactSupport from '../components/support/support.component';

export const MenuList: React.FC<{ rate?: any }> = ({ rate }) => {
  const { t } = useTranslation();

  const menu = useMemo(() => {
    const menuItems = ['my-projects', 'files', 'folders', 'trash'];
    return (
      <LeftColumn>
        <Components.Menu>
          <Headline color={'white'} mb={16} small>
            {t('myProjects.title')}
          </Headline>
          <FlexCol gap={6} alignItems={'flex-start'}>
            {menuItems.map((item) => (
              <Components.MenuItem
                key={item}
                to={`/${item}`}
                // className={index === 0 ? 'active' : ''}
              >
                {t(`myProjects.menu.${item}`)}
              </Components.MenuItem>
            ))}
          </FlexCol>
        </Components.Menu>
        <ProjectsCounter />
        <ContactSupport />
      </LeftColumn>
    );
  }, [t, rate?.existingProjectCount]);

  return <>{menu}</>;
};

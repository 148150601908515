import React, { useState } from 'react';
import { IModalOptions } from '../../../interfaces/modal.interface';
import { Flex } from '../../flexbox.component';
import { Button } from '../../button.component';
import { StyledBtnLink } from './template-popover.style';
import { Text } from '../../text.component';

const TemplateConfirm: React.FC<
  {
    id: number;
    addTemplate: (id: number, type: 'new' | 'exist', callback?: () => void) => Promise<void>;
  } & IModalOptions
> = ({ id, addTemplate, handleCancel }) => {
  const [loading, setLoading] = useState<{ new: boolean; exist: boolean }>({
    new: false,
    exist: false,
  });

  return (
    <>
      <Text fontSize="1.070rem">Как вы хотите добавить шаблон?</Text>
      <Flex justifyContent="space-between" alignItems="center" style={{ marginTop: '20px' }}>
        <StyledBtnLink onClick={handleCancel}>Отменить</StyledBtnLink>
        <Flex justifyContent="space-between" gap={10}>
          <Button
            isLoading={loading.new}
            onClick={() => {
              setLoading({
                new: true,
                exist: false,
              });
              addTemplate(id, 'new', handleCancel).finally(() =>
                setLoading({ new: false, exist: false }),
              );
            }}
          >
            Как новый холст
          </Button>
          <Button
            isLoading={loading.exist}
            onClick={() => {
              setLoading({
                new: false,
                exist: true,
              });
              addTemplate(id, 'exist', handleCancel).finally(() =>
                setLoading({ new: false, exist: false }),
              );
            }}
          >
            Вместо текущего
          </Button>
        </Flex>
      </Flex>
    </>
  );
};

export default TemplateConfirm;

import React, { FC, useEffect, useState } from 'react'
import Components from '../../pages/my-projects/my-projects.style'
import { Headline } from '../headline.component'
import { Text } from '../text.component'
import { ReactComponent as SparkleLeft } from '../../assets/images/sparkle-left.svg';
import { ReactComponent as SparkleRight } from '../../assets/images/sparkle-right.svg';
import { Button } from '../button.component';
import NewProjectModal from '../new-project-modal.component.';
import { useTranslation } from 'react-i18next';
import { OverlayWrapper } from './rates.style';
import { PurchaseRateTypes, RateNames, overlayData } from '../../constants/rates.constant';
import { useNavigate } from 'react-router-dom';
import RateModal from './rate-modal.component';
import { purchaseRate } from '../../requests/tariff.request';
import { useUserContext } from '../../contexts/user.context';

interface IProps {
  rate: any;
}

const RateOverlay: FC<IProps> = ({ rate }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { updateUserDetails } = useUserContext();

  const [isOpen, setIsOpen] = useState(false);
  const [isRateModalOpen, setIsRateModalOpen] = useState(false);
  const [days, setDays] = useState(0);
  const [type, setType] = useState<'trialEnded' | 'TRIAL' | 'subscriptionUnpaid' | 'projectDeleted'>('TRIAL');

  const handleButtonClick = () => {
    switch (overlayData[type].buttonText) {
      case 'myProjects.createNewCard':
        setIsOpen(true);
        break;
      case 'rates.chooseRate':
        navigate('/rates');
        break;
      case 'rates.renewSubscription':
        purchaseRate(rate.id, PurchaseRateTypes.RENEW).then(() => {
          updateUserDetails();
        })
    }
  }

  useEffect(() => {
    const today = new Date().getTime();
    const endDate = new Date(rate.expireDate).getTime();
    const difference = Math.floor((endDate - today) / (1000 * 60 * 60 * 24)) + 1
    setDays(rate.name === RateNames.TRIAL ? Math.min(+difference, 3) : +difference);

    if (rate.name === RateNames.TRIAL && difference <= 0) {
      setType('trialEnded');
    } else if (rate.name === RateNames.TRIAL) {
      if (rate && rate.existingProjectCount === 0 && rate.availableProjectCount === 0 && rate.inTrashProjectCount === 0) {
        setType('projectDeleted')
      } else {
        setType('TRIAL');
      }
    } else {
      setType('subscriptionUnpaid');
    }
  }, [rate.id])

  return (
    <>
      {rate && overlayData[type] && (
        <OverlayWrapper>
          <div className="header">
            <Headline>{t(overlayData[type].headline)}</Headline>
            <div>
              {overlayData[type].description.map(item => (
                <Text key={item}>{t(item, { rateName: t(`rates.${rate?.name}`) })}</Text>
              ))}
            </div>
          </div>
          {rate.name === RateNames.TRIAL && days > 0 && rate?.existingProjectCount === 0 && rate?.availableProjectCount !== 0 && (
            <Text strong color='lightPrimary'>{days !== 1 ? t('rates.daysLeft', { days }) : t('rates.dayLeft', { days })} </Text>
          )}

          <Components.SparkleWrapper>
            <SparkleLeft />
            <Button
              icon={overlayData[type].buttonIcon ? overlayData[type].buttonIcon as string : ''}
              btnStyle={'inverse'}
              onClick={handleButtonClick}
            >
              {t(overlayData[type].buttonText)}
            </Button>
            <SparkleRight />
          </Components.SparkleWrapper>

          {overlayData[type].menuItem && <Components.MenuItem to={'/rates'}>{t(overlayData[type].menuItem as string)}</Components.MenuItem>}

          <NewProjectModal
            onClose={() => setIsOpen(false)}
            isOpen={isOpen}
            trackKey="ecom_webapp_create_project_from_main_page"
            setIsOpen={setIsOpen}
            setIsAdditionalModalOpen={setIsRateModalOpen}
          />

          <RateModal
            isOpen={isRateModalOpen}
            onClose={() => setIsRateModalOpen(false)}
          />
        </OverlayWrapper>
      )}
    </>
  )
}

export default RateOverlay
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../button.component';
import { Flex, FlexCol } from '../../flexbox.component';
import { IModalOptions } from '../../../interfaces/modal.interface';
import { StyledBtnLink } from './template-popover.style';

const TemplateRemoveModal: React.FC<IModalOptions & { isRemoving?: boolean }> = ({
  handleCancel,
  handleOk,
  isRemoving,
}) => {
  const { t } = useTranslation();

  return (
    <FlexCol gap={20}>
      <div>Вы действительно ходите удалить шаблон?</div>
      <Flex justifyContent="space-between" alignItems="center">
        <StyledBtnLink
          onClick={() => {
            if (handleCancel) {
              handleCancel();
            }
          }}
        >
          {t('action.cancel')}
        </StyledBtnLink>
        <Button
          icon="trash"
          isLoading={isRemoving}
          disabled={isRemoving}
          onClick={() => {
            if (handleOk) {
              handleOk();
            }
          }}
        >
          {t('action.delete')}
        </Button>
      </Flex>
    </FlexCol>
  );
};

export default TemplateRemoveModal;

import React, { ButtonHTMLAttributes, useRef, useEffect } from 'react';
import styled, { css } from 'styled-components';

interface IModalProps extends ButtonHTMLAttributes<any> {
  children?: any;
  onClose: () => void;
  width?: string;
  height?: string;
  flexCenter?: boolean;
  contentPadding?: string;
  maxHeight?: string;
  currentRate?: string;
  contentType?: string;
}

const ModalWrapper = styled.div<any>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1100;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContent = styled.div<any>`
  position: relative;
  background-color: white;
  padding: ${({ contentPadding }) => contentPadding};
  border-radius: 16px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  width: ${({ width }) => (width ? width : 'max-content')};
  height: ${({ height }) => (height ? height : 'max-content')};
  max-height: ${({ maxHeight }) => (maxHeight ? maxHeight : '90%')};
  max-width: 90%;
  overflow: auto;

  ${({ flexCenter }) =>
    flexCenter &&
    `
    display: flex;
    justify-content: center;
    align-items: center;
  `}

  ${({ currentRate }) =>
    currentRate === 'ADVANCED' &&
    css`
      max-width: 37.5rem;
      min-width: 37.5rem;
    `}
  
  ${({ currentRate }) =>
    currentRate === 'BASIC' &&
    css`
      max-width: 50rem;
      min-width: 50rem;
    `}

  ${({ currentRate }) =>
    (currentRate === 'UNLIMITED' || currentRate === 'TRIAL') &&
    css`
      max-width: 70.625rem;
      min-width: 70.625rem;
    `}

  ${({ contentType }) =>
    contentType === 'delete-some' &&
    css`
      max-width: 33.75rem;
      min-width: 33.75rem;
    `}
`;

const CloseIcon = styled.div.attrs({
  className: 'icon-close',
})`
  position: absolute;
  top: 0.625rem;
  right: 0.625rem;
  font-size: 1.25rem;
  color: ${(p) => p.theme.black};
  padding: 0;
  background: transparent;
  border: 0;
  cursor: pointer;
  &:hover {
    color: ${(p) => p.theme.primary};
  }
`;

export const Modal = ({
  children,
  width,
  height,
  onClose,
  flexCenter,
  contentPadding = '20px',
  maxHeight,
  currentRate,
  contentType,
}: IModalProps) => {
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  return (
    <ModalWrapper>
      <ModalContent
        ref={modalRef}
        width={width}
        height={height}
        flexCenter={flexCenter}
        contentPadding={contentPadding}
        maxHeight={maxHeight}
        currentRate={currentRate}
        contentType={contentType}
      >
        {children}
        <CloseIcon onClick={onClose} />
      </ModalContent>
    </ModalWrapper>
  );
};

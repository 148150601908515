import { Role } from '../requests/user.requests';

export const ACCESS_TOKEN = 'ACCESS_TOKEN';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';

export interface IUser {
  email: string;
  password: string;
  name?: string;
  rememberMe?: boolean;
  role?: Role;
}

export interface ITokenResponse {
  accessToken: string;
  msg: string;
  refreshToken: string;
  success: boolean;
  type: string;
  role: Role;
}

export interface IPasswords {
  password: string;
  repeatPassword: string;
}

export type RegisterStep = 'register' | 'confirm' | 'done';

export interface IDataSuccessResponse<T> {
  data: T;
  success: boolean;
}

export interface IUserDetails {
  name?: string;
  email: string;
  phoneNumber?: string;
  tariff: any;
  rate?: any;
}

export interface IChangePasswordDto {
  oldPassword: string;
  newPassword: string;
}

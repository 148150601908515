import styled from 'styled-components';

const ShadowToolRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ShadowToolCol = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  border-bottom: 1px solid ${(p) => p.theme.lightGray2};
  width: 129px;
  padding: 11px 10px;

  label {
    justify-content: space-between;
  }
`;

const ShadowBtn = styled.button`
  padding: 0 !important;
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;
  cursor: pointer;
`;

const IconMinus = styled(ShadowBtn)`
  font-size: 1rem;
  font-family: 'icomoon', serif !important;
  padding: 0 3.17px;
  &:before {
    content: '\\e948';
  }
`;

const IconPlus = styled(ShadowBtn)`
  font-size: 1rem;
  font-family: 'icomoon', serif !important;

  &:before {
    content: '\\e95e';
  }
`;

export { ShadowToolRow, ShadowToolCol, ShadowBtn, IconMinus, IconPlus };

import React from 'react'
import { Wrapper } from './rates.style';
import ToggleButtons from '../../components/toggle-buttons.component';
import RateList from '../../components/rates/rate-list.component';
import { useTranslation } from 'react-i18next';
import { rates } from '../../constants/rates.constant';
import { Text } from '../../components/text.component';
import ContactSupport from "../../components/support/support.component";

const RatesPage = () => {
  const { t } = useTranslation();

  const tabs = Object.keys(rates).map((rate: string) => ({
    title: t(`rates.${rate}`),
    content: <RateList type={rate} />,
  }))

  return (
    <Wrapper>
      <ToggleButtons tabs={tabs} />
      <div className="footer-text">
        <Text color="infoGray" small>
          Оплачивая пакет услуг, вы принимаете <a target='_blank' href="/public-offer">соглашение оферты.</a>
        </Text>
      </div>
      <ContactSupport />
    </Wrapper>
  )
}

export default RatesPage
import React, { useEffect, useState } from 'react';
import { FabricJSCanvas, FabricJSEditor, useFabricJSEditor } from 'fabricjs-react';
import { fabric } from 'fabric';
import FabricTypes, { IRectOptions } from 'fabric/fabric-impl';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { loadFullImage } from '../requests/project.requests';
import { Flex } from './flexbox.component';
import { Button } from './button.component';
import { useModal } from '../contexts/modal.context';
import { IModalOptions } from '../interfaces/modal.interface';
import { CanvasRatio } from '../interfaces/editor.interface';
import { handleSelectionRectMoving, handleSelectionRectScaling } from '../utils/editor.utils';
import CircleLoader2 from './circle-loader2';

const Wrapper = styled.div`
  & .crop-bg-image-canvas {
    overflow: auto;
  }
`;

const ModalFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1.59375rem;
  & button:first-child {
    padding: 0;
  }
`;

interface IProps extends IModalOptions {
  imageId: number;
  canvasRatio?: CanvasRatio;
  isBackground?: boolean;
  savedCrop?: any;
  onCrop?: (crop: any) => void;
  setDefaultBg?: (_: any) => void;
  setSquareBg?: (_: any) => void;
  setSelectedRect?: (_: any) => void;
  selectedRect?: FabricTypes.Rect | undefined;
}

export const CropImage: React.FC<IProps> = ({
  imageId,
  canvasRatio = 'square',
  handleOk,
  handleCancel,
  isBackground = false,
  savedCrop,
  onCrop,
  setDefaultBg,
  setSquareBg,
  setSelectedRect,
  selectedRect,
}: IProps) => {
  const { editor, onReady } = useFabricJSEditor();
  const { t } = useTranslation();
  const { close } = useModal();
  const [editorLoaded, setEditorLoaded] = useState(false);
  const [selectionModified, setSelectionModified] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    if (editor && !editorLoaded) {
      setEditorLoaded(true);
      const { canvas } = editor as FabricJSEditor;
      // const { width, height } = CANVAS_SIZE[canvasRatio];
      canvas.setWidth(932);
      canvas.setHeight(500);
      canvas.preserveObjectStacking = true;
      canvas.on('mouse:down', () => {
        const rect = canvas._objects.find((obj) => obj.name === 'selectionRect');
        if (rect) {
          canvas.setActiveObject(rect);
        }
      });
      if (imageId) {
        handleLoadFullImage(imageId);
      }
    }
  }, [editor, editorLoaded, imageId]);

  const getImageSize = (): { width: number; height: number } => {
    const { canvas } = editor as FabricJSEditor;
    const { width, height, scaleX, scaleY } = canvas.overlayImage as FabricTypes.Image;
    return {
      width: (width && scaleX ? width * scaleX : width) as number,
      height: (height && scaleY ? height * scaleY : height) as number,
    };
  };

  const addOverlay = () => {
    const { canvas } = editor as FabricJSEditor;

    const { left, top } = canvas.overlayImage as FabricTypes.Image;
    const { width, height } = getImageSize();
    const overlay = new fabric.Rect({
      name: 'overlay',
      left,
      top,
      width,
      height,
      fill: 'rgba(0,0,0,0.5)',
      selectable: false,
      globalCompositeOperation: 'source-over',
    });
    canvas.add(overlay);
    canvas.renderAll();
  };

  const addSelectionRect = () => {
    const { canvas } = editor as FabricJSEditor;
    const imageSize = getImageSize();
    let width = imageSize.width ?? 500,
      height = imageSize.height ?? 500;
    if (isBackground) {
      const canvasWidth = canvasRatio === 'square' ? 500 : 375;
      const canvasHeight = 500;
      const minValue = imageSize.width < imageSize.height ? imageSize.width : imageSize.height;
      width = minValue < canvasWidth ? minValue : canvasWidth;
      height = minValue < canvasHeight ? minValue : canvasHeight;
    }

    const defaultRect: IRectOptions = {
      name: 'selectionRect',
      originX: 'left',
      originY: 'top',
      stroke: 'black',
      strokeWidth: 0,
      opacity: 1,
      width,
      height,
      hasRotatingPoint: false,
      transparentCorners: true,
      cornerColor: 'white',
      cornerStrokeColor: 'white',
      borderColor: 'black',
      cornerSize: 12,
      padding: 0,
      cornerStyle: 'rect',
      fill: '#451245',
      globalCompositeOperation: 'destination-out',
      lockScalingFlip: true,
    };

    const selectionRect = savedCrop
      ? new fabric.Rect({ ...defaultRect, ...savedCrop })
      : new fabric.Rect({ ...defaultRect });

    selectionRect.setControlsVisibility({
      mt: !isBackground,
      mr: !isBackground,
      mb: !isBackground,
      ml: !isBackground,
      mtr: false,
    });

    canvas.on('object:moving', handleSelectionRectMoving(canvas));
    canvas.on('object:scaling', handleSelectionRectScaling(canvas));
    canvas.on('object:modified', (event) => {
      if (event.target) {
        event.target.lockScalingX = false;
        event.target.lockScalingY = false;
        event.target.lockMovementX = false;
        event.target.lockMovementY = false;
      }
    });
    canvas.on('object:modified', () => {
      setSelectionModified(true);
    });

    // const { tl } = selectionRect.controls;
    // selectionRect.controls.tl = new fabric.Control({
    //   x: -0.5,
    //   y: -0.5,
    //   actionName: 'scale',
    //   cursorStyle: 'pointer',
    //   render: renderIcon(),
    //   actionHandler: tl.actionHandler,
    // });
    !savedCrop && canvas.centerObject(selectionRect);
    canvas.add(selectionRect);
    canvas.setActiveObject(selectionRect);
    canvas.renderAll();
  };

  const resetSelectionRect = () => {
    const { canvas } = editor as FabricJSEditor;
    const selectionRect = canvas.getActiveObject();
    if (selectionRect) {
      setSelectionModified(false);
      canvas.remove(selectionRect);
      canvas.discardActiveObject();
      addSelectionRect();
      canvas.centerObject(selectionRect);
    }
  };

  const handleAddImage = (imageBlob: Blob, selectedRect?: fabric.Rect) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const url = event.target?.result as string;
      const { canvas } = editor as FabricJSEditor;
      canvas.setOverlayImage(
        url,
        () => {
          if (canvas.overlayImage) {
            canvas.overlayImage.scaleToHeight(canvas.height as number);
            canvas.centerObject(canvas.overlayImage);
            canvas.renderAll();
            addOverlay();

            if (selectedRect) {
              const croppingRect = new fabric.Rect({
                originX: selectedRect.originX,
                originY: selectedRect.originY,
                left: selectedRect.left,
                top: selectedRect.top,
                width: selectedRect.getScaledWidth(),
                height: selectedRect.getScaledHeight(),
                absolutePositioned: true,
                name: 'selectionRect',
                stroke: 'black',
                strokeWidth: 0,
                opacity: 1,
                hasRotatingPoint: false,
                transparentCorners: true,
                cornerColor: 'white',
                cornerStrokeColor: 'white',
                borderColor: 'black',
                cornerSize: 12,
                padding: 0,
                cornerStyle: 'rect',
                fill: '#451245',
                globalCompositeOperation: 'destination-out',
                lockScalingFlip: true,
                lockRotation: true,
              });

              croppingRect.setControlsVisibility({
                mtr: false,
              });

              canvas.add(croppingRect);
              canvas.setActiveObject(croppingRect);
            } else {
              addSelectionRect();
            }
          }
        },
        {
          lockMovementX: true,
          lockMovementY: true,
          lockRotation: true,
          selectable: false,
          evented: false,
          globalCompositeOperation: 'destination-atop',
        },
      );
    };
    reader.readAsDataURL(imageBlob);
  };

  const handleLoadFullImage = (id: number) => {
    setImageLoaded(true);
    loadFullImage(id)
      .then((image) => {
        handleAddImage(image, selectedRect);
        setImageLoaded(false);
      })
      .catch(() => null);
  };

  const handleCrop = () => {
    const { canvas } = editor as FabricJSEditor;
    const selectionRect = canvas.getActiveObject();
    const overlay = canvas._objects.find((obj) => obj.name === 'overlay') as FabricTypes.Object;
    const currentImage = canvas.overlayImage;

    if (selectionRect && currentImage) {
      const rect = new fabric.Rect({
        originX: selectionRect.originX,
        originY: selectionRect.originY,
        left: selectionRect.left,
        top: selectionRect.top,
        width: selectionRect.getScaledWidth(),
        height: selectionRect.getScaledHeight(),
        absolutePositioned: true,
      });

      setSelectedRect && setSelectedRect(rect);

      currentImage.clipPath = rect;
      canvasRatio === 'square' &&
        saveBackgroundImages(canvas, selectionRect, currentImage, overlay);

      canvas.remove(selectionRect);
      canvas.remove(overlay);
      onCrop && onCrop(rect);
      const cropped = new Image();

      const finalMultiplier =
        currentImage.scaleX &&
        currentImage.scaleY &&
        (1 / currentImage.scaleX + 1 / currentImage.scaleY) / 2;
      currentImage.clipPath = undefined;

      cropped.src = canvas.toDataURL({
        left: rect.left && rect.left + 1,
        top: rect.top && rect.top + 1,
        width: rect.width && rect.width - 1,
        height: rect.height && rect.height - 1,
        multiplier: finalMultiplier,
      });

      cropped.onload = () => {
        canvas.clear();
        const image = new fabric.Image(cropped);
        image.left = rect.left;
        image.top = rect.top;
        image.setCoords();
        canvas.add(image);
        canvas.renderAll();

        image.clone((cloned: any) => {
          cloned.set({
            name: `backgroundImage_${imageId}`,
            left: 0,
            top: 0,
          });
          if (setSquareBg && canvasRatio === 'square') {
            setSquareBg(cloned);
          }
          if (setDefaultBg && canvasRatio === 'default') {
            setDefaultBg(cloned);
          }

          if (handleOk) {
            handleOk(cloned);
            close();
          }
        });
      };
    }
  };

  const handleClose = () => {
    if (handleCancel) {
      handleCancel();
    }
    close();
  };

  const saveBackgroundImages = (
    canvas: any,
    selectionRect: any,
    currentImage: any,
    overlay: any,
  ) => {
    const distanceToBottom = canvas.height! - selectionRect.top! - selectionRect.getScaledHeight();
    const toScale = ((selectionRect.getScaledWidth() * 4) / 3 - selectionRect.getScaledWidth()) / 2;
    let newTop = 0;

    if (canvasRatio === 'square') {
      newTop = selectionRect.top! - toScale;

      if (distanceToBottom < toScale) {
        newTop = newTop - toScale + distanceToBottom;
      }

      if (selectionRect.top! <= toScale) {
        newTop = 0;
      }

      if (newTop < 0) {
        newTop = 0;
      }
    } else if (canvasRatio === 'default') {
      newTop = selectionRect.top! + toScale;
    }

    let newHeight =
      canvasRatio === 'square'
        ? (selectionRect.getScaledWidth() * 4) / 3
        : selectionRect.getScaledWidth();
    let newWidth = selectionRect.getScaledWidth();
    let newLeft = selectionRect.left!;

    if (canvas.height <= newHeight + toScale * 2) {
      newHeight = selectionRect.getScaledWidth();
      newWidth = (newHeight * 3) / 4;
      newLeft = selectionRect.left! - (newWidth - selectionRect.getScaledWidth()) / 2;
    }

    const newRect = new fabric.Rect({
      originX: selectionRect.originX,
      originY: selectionRect.originY,
      left: newLeft,
      top: newTop,
      width: newWidth,
      height: newHeight,
      absolutePositioned: true,
    });
    canvas.remove(overlay);

    if (canvas.height > newHeight + toScale * 2) {
      currentImage.clipPath = newRect;
    }

    const newCropped = new Image();

    newCropped.src = canvas.toDataURL({
      left: newRect.left! + 1,
      top: newRect.top! + 1,
      width: newRect.width! - 1,
      height: newRect.height! - 1,
      multiplier: isBackground ? 2 : 1,
    });

    newCropped.onload = () => {
      const image = new fabric.Image(newCropped);
      image.left = newRect.left;
      image.top = newRect.top;
      image.setCoords();
      image.clone((cloned: FabricTypes.Image) => {
        cloned.set({
          name: `backgroundImage_${imageId}`,
          left: 0,
          top: 0,
        });
        canvasRatio === 'square' && setDefaultBg && setDefaultBg(cloned);
        canvasRatio === 'default' && setSquareBg && setSquareBg(cloned);
      });
    };
  };

  return (
    <Wrapper>
      {imageLoaded && (
        <div
          style={{
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <CircleLoader2 shineColor={'#6620C7'} />
        </div>
      )}
      <FabricJSCanvas className="crop-bg-image-canvas" onReady={onReady} />
      <ModalFooter>
        <Button
          btnStyle={'cancel'}
          icon={'reset'}
          onClick={resetSelectionRect}
          disabled={!selectionModified && !savedCrop}
        >
          {t('action.reset')}
        </Button>
        <Flex alignItems="center" gap={20}>
          <Button btnStyle={'cancel'} onClick={handleClose}>
            {t('action.cancel')}
          </Button>
          <Button icon={'check'} onClick={handleCrop}>
            {t('action.done')}
          </Button>
        </Flex>
      </ModalFooter>
    </Wrapper>
  );
};

import styled, { css } from 'styled-components';
import { Flex } from './flexbox.component';

export const Row = styled(Flex)<{ gutter?: number; adaptive?: boolean }>`
  position: relative;
  flex-flow: row wrap;
  ${(p) =>
    p.gutter &&
    css`
      margin: -${p.gutter / 2}px;
      & > * {
        padding: ${p.gutter / 2}px;
      }
    `}

  ${(p) =>
    p.adaptive &&
    css`
      gap: 10px;
      @media (max-width: 1280px) {
        gap: 8px;
      }
    `}
`;

export const Col = styled.div<{
  span?: number;
}>`
  position: relative;
  min-height: 1px;
  display: block;
  flex: 0 0 ${(p) => (p.span && p.span >= 1 && p.span <= 24 ? (p.span * 100) / 24 : 100)}%;
  max-width: ${(p) => (p.span && p.span >= 1 && p.span <= 24 ? (p.span * 100) / 24 : 100)}%;
`;

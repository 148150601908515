import api from './api';
import {
  IDataSuccessResponse,
  ISuccessResponse,
  IId,
  ICreateFolderRequest,
  ILibraryImages,
  IProject,
} from '../interfaces/projects.interface';
import { SortOption } from '../interfaces/editor.interface';

export const addLibraryImage = (
  folderId?: number,
  itemIds?: number[],
): Promise<ISuccessResponse> => {
  return api.post('folder/addLibraryImage', { folderId, itemIds });
};

export const addProject = (folderId?: number, itemIds?: number[]): Promise<ISuccessResponse> => {
  return api.post('folder/addProject', { folderId, itemIds });
};

export const createFolder = (
  createFolderRequest: ICreateFolderRequest,
): Promise<ISuccessResponse<ICreateFolderRequest>> => {
  return api.post('folder/create', createFolderRequest);
};

export const deleteFolder = (deleteRequest: IId): Promise<ISuccessResponse> => {
  return api.delete('folder/delete', { data: deleteRequest });
};

export const getLibraryImages = (
  folderId: number,
  name?: string,
  sortingType?: string,
  orderType?: string,
  page?: number,
): Promise<IDataSuccessResponse<ILibraryImages>> => {
  return api.get(`folder/libraryImageList?folderId=${folderId}`, {
    params: { orderType, page, sortingType, name },
  });
};

export const getFolderProjects = (
  folderId: number,
  orderType?: 'ASC' | 'DESC',
  sortingType?: string,
  page?: number,
): Promise<IDataSuccessResponse<IProject>> => {
  return api.get(`folder/projectList?folderId=${folderId}`, {
    params: { orderType, sortingType, page },
  });
};

export const getFolders = (
  name?: string,
  sortingType?: string | SortOption,
  orderType?: string,
  page?: number,
  pageSize?: number,
): Promise<IDataSuccessResponse<ICreateFolderRequest>> => {
  return api.get('folder/list', { params: { name, sortingType, orderType, page, pageSize } });
};

export const renameFolder = (renameRequest: {
  id?: number;
  newName?: string;
}): Promise<ISuccessResponse> => {
  return api.post('folder/rename', renameRequest);
};

export const removeFromFolder = (removeFolderItemRequest: {
  folderId?: number;
  folderItemId?: number;
  folderItemType?: string;
}): Promise<ISuccessResponse> => {
  return api.post('folder/folderItem/remove', removeFolderItemRequest);
};

export const deleteSelectedFolders = (folderIds: number[]) => {
  return api.delete('folder/delete/selected', { data: { ids: folderIds } });
};

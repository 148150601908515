import { PurchaseRateTypes, RatePeriods } from "../constants/rates.constant";
import api from "./api";

const baseUrl = 'tariff';

const getRateList = (type: RatePeriods) => {
  return api.get(`${baseUrl}/list?paymentPeriodType=${type}`,).then((res) => res);
}

const purchaseRate = (tariffId: number, type: PurchaseRateTypes) => {
  return api.post(`${baseUrl}/purchase?tariffId=${tariffId}&tariffPurchaseType=${type}`)
    .then((res: any) => {
      if (res.data) {
        const link = document.createElement('a');
        link.href = res.data;
        link.click();
        link.remove();
      }
    })
}

const getHistory = () => {
  return api.get(`${baseUrl}/history`).then((res: any) => res)
}

// FOR TEST ONLY, DELETE ON PROD

const changeDate = () => {
  const date = new Date('February 13, 2024 03:24:00').toDateString();
  return api.post(`${baseUrl}/changeDate?date=${date}`).then((res: any) => res)
}
export {
  getRateList,
  purchaseRate,
  getHistory,
  changeDate
}
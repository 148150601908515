import api from './api';
import { IChangePasswordDto, IUserDetails } from '../interfaces/auth.interface';

export type Role = 'ADMIN' | 'PARTNER' | 'USER' | null;

const baseURL = '/user';

export const getUserDetails = (): Promise<IUserDetails> => {
  return api.get(`${baseURL}/getUserDetails`).then((res) => {
    return res.data;
  });
};

export const changeUserDetails = (userDetails: IUserDetails): Promise<{ success: boolean }> => {
  return api.post(`${baseURL}/changeUserDetails`, userDetails);
};

export const changeUserPassword = (passwords: IChangePasswordDto): Promise<any> => {
  return api.post(`${baseURL}/changePassword`, passwords);
};

export const confirmEmailChange = (newEmail: string, code: string) => {
  return api.post(`${baseURL}/confirmChangeEmail`, { newEmail, code });
};

export const sendSupportRequest = (email: string, request: string) => {
  return api.post(`${baseURL}/sendSupportRequest`, { email, request });
};

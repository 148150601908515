import { useCallback } from 'react';

function useInfiniteScroll(
  loading: boolean,
  hasMore: boolean,
  setPage: React.Dispatch<React.SetStateAction<number>>,
) {
  const handleScroll = useCallback(
    (event: React.UIEvent<HTMLElement>) => {
      const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
      if (Math.ceil(clientHeight + scrollTop) === scrollHeight && hasMore && !loading) {
        setPage((prevPage) => prevPage + 1);
      }
    },
    [loading, hasMore],
  );

  return { handleScroll };
}

export default useInfiniteScroll;

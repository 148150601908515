import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import MobilePage from '../pages/mobile/mobile.page';
import { Route, Routes } from 'react-router-dom';
import { RegisterPage } from '../pages/register/register.page';

type Props = {
  children: React.ReactNode;
};

type MobileCheckContextType = {
  isMobile: boolean;
  setIsMobile: (value: boolean) => void;
};

const MobileCheckContext = createContext<MobileCheckContextType>({
  isMobile: false,
  setIsMobile: () => null,
});

export const MobileCheckProvider: React.FC<Props> = ({ children }) => {
  const [isMobile, setIsMobile] = useState<boolean>(false);

  const value = useMemo(
    () => ({
      isMobile,
      setIsMobile,
    }),
    [isMobile],
  );

  const isMobileDevice = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent,
    );
  };

  useEffect(() => {
    setIsMobile(isMobileDevice());
  }, []);

  if (isMobile) {
    return (
      <MobileCheckContext.Provider value={{ ...value }}>
        <Routes>
          <Route path="*" element={<MobilePage />} />
          <Route path="/register" element={<RegisterPage />} />
        </Routes>
      </MobileCheckContext.Provider>
    );
  }

  return <MobileCheckContext.Provider value={{ ...value }}>{children}</MobileCheckContext.Provider>;
};

export const useMobileCheck = (): MobileCheckContextType => {
  return {
    ...useContext(MobileCheckContext),
  };
};

import { Dispatch, SetStateAction } from 'react';
import FabricTypes, { IEvent, Canvas, Textbox, Point } from 'fabric/fabric-impl';
import { fabric } from 'fabric';
import { FabricJSEditor } from 'fabricjs-react';

import {
  ColorType,
  IGradient,
  IObjectWithId,
  LinearGradientType,
  RadialGradientType,
} from '../interfaces/editor.interface';
import { IGradientRangeValue } from '../components/gradient-range-slider.component';
import { ALIGN_OPTIONS, CANVAS_SIZE, MOVE_OPTIONS, SHAPE_NAMES } from '../constants';
import { SHAPE_PATH } from '../constants/shapes.constants';
import { FONTS_URL, LINE_STROKE_WIDTH } from '../constants/editor.constants';
import { FontWeights } from '../components/object-toolbar/fonts-list.component';

let scalingProperties: any = null;
const textBoxMargin = 15;
const multiplier = 4;

export let savedObjects: IObjectWithId[] | undefined = undefined;

export const enableGridMoving = (gridSize: number) => (e: IEvent<MouseEvent>) => {
  if (e.target) {
    e.target?.set({
      left: e.target?.left && Math.round(e.target.left / gridSize) * gridSize,
      top: e.target?.top && Math.round(e.target.top / gridSize) * gridSize,
    });
  }
};

const handleSnap = (value = 0, gridSize: number) => Math.round(value / gridSize) * gridSize;

export const enableGridResizing =
  (gridSize: number) =>
  ({ target }: IEvent<MouseEvent>) => {
    if (
      target?.width &&
      target.height &&
      target.scaleX &&
      target.scaleY &&
      target.top &&
      target.left
    ) {
      const w = target.width * target.scaleX,
        h = target.height * target.scaleY,
        snap = {
          // Closest snapping points
          top: handleSnap(target.top, gridSize),
          left: handleSnap(target.left, gridSize),
          bottom: handleSnap(target.top + h, gridSize),
          right: handleSnap(target.left + w, gridSize),
        },
        threshold = gridSize,
        dist = {
          // Distance from snapping points
          top: Math.abs(snap.top - target.top),
          left: Math.abs(snap.left - target.left),
          bottom: Math.abs(snap.bottom - target.top - h),
          right: Math.abs(snap.right - target.left - w),
        },
        attrs = {
          scaleX: target.scaleX,
          scaleY: target.scaleY,
          top: target.top,
          left: target.left,
        };
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      switch (target.__corner) {
        case 'tl':
          if (dist.left < dist.top && dist.left < threshold) {
            attrs.scaleX = (w - (snap.left - target.left)) / target.width;
            attrs.scaleY = (attrs.scaleX / target.scaleX) * target.scaleY;
            attrs.top = target.top + (h - target.height * attrs.scaleY);
            attrs.left = snap.left;
          } else if (dist.top < threshold) {
            attrs.scaleY = (h - (snap.top - target.top)) / target.height;
            attrs.scaleX = (attrs.scaleY / target.scaleY) * target.scaleX;
            attrs.left = attrs.left + (w - target.width * attrs.scaleX);
            attrs.top = snap.top;
          }
          break;
        case 'mt':
          if (dist.top < threshold) {
            attrs.scaleY = (h - (snap.top - target.top)) / target.height;
            attrs.top = snap.top;
          }
          break;
        case 'tr':
          if (dist.right < dist.top && dist.right < threshold) {
            attrs.scaleX = (snap.right - target.left) / target.width;
            attrs.scaleY = (attrs.scaleX / target.scaleX) * target.scaleY;
            attrs.top = target.top + (h - target.height * attrs.scaleY);
          } else if (dist.top < threshold) {
            attrs.scaleY = (h - (snap.top - target.top)) / target.height;
            attrs.scaleX = (attrs.scaleY / target.scaleY) * target.scaleX;
            attrs.top = snap.top;
          }
          break;
        case 'ml':
          if (dist.left < threshold) {
            attrs.scaleX = (w - (snap.left - target.left)) / target.width;
            attrs.left = snap.left;
          }
          break;
        case 'mr':
          if (dist.right < threshold) attrs.scaleX = (snap.right - target.left) / target.width;
          break;
        case 'bl':
          if (dist.left < dist.bottom && dist.left < threshold) {
            attrs.scaleX = (w - (snap.left - target.left)) / target.width;
            attrs.scaleY = (attrs.scaleX / target.scaleX) * target.scaleY;
            attrs.left = snap.left;
          } else if (dist.bottom < threshold) {
            attrs.scaleY = (snap.bottom - target.top) / target.height;
            attrs.scaleX = (attrs.scaleY / target.scaleY) * target.scaleX;
            attrs.left = attrs.left + (w - target.width * attrs.scaleX);
          }
          break;
        case 'mb':
          if (dist.bottom < threshold) attrs.scaleY = (snap.bottom - target.top) / target.height;
          break;
        case 'br':
          if (dist.right < dist.bottom && dist.right < threshold) {
            attrs.scaleX = (snap.right - target.left) / target.width;
            attrs.scaleY = (attrs.scaleX / target.scaleX) * target.scaleY;
          } else if (dist.bottom < threshold) {
            attrs.scaleY = (snap.bottom - target.top) / target.height;
            attrs.scaleX = (attrs.scaleY / target.scaleY) * target.scaleX;
          }
          break;
      }
      target.set(attrs);
    }
  };

export const enableGridResizingTextBox =
  (gridSize: number) =>
  ({ target }: IEvent<MouseEvent>) => {
    if (target?.type === 'textbox' && target.width && target.scaleX && target.left) {
      const w = target.width * target.scaleX,
        snap = {
          left: handleSnap(target.left, gridSize),
          right: handleSnap(target.left + w, gridSize),
        },
        threshold = gridSize,
        dist = {
          left: Math.abs(snap.left - target.left),
          right: Math.abs(snap.right - target.left - w),
        },
        attrs = {
          width: target.width,
          left: target.left,
        };
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      switch (target.__corner) {
        case 'ml':
          if (dist.left < threshold) {
            attrs.width = (w - (snap.left - target.left)) / multiplier;
            attrs.left = snap.left;
          }
          break;
        case 'mr':
          if (dist.right < threshold) attrs.width = (snap.right - target.left) / multiplier;
          break;
      }
      target.set(attrs);
    }
  };

export const enableBackgroundImageMoving = (canvas: Canvas) => (e: IEvent<MouseEvent>) => {
  const obj: FabricTypes.Object | undefined = e.target;
  if (obj?.name?.includes('backgroundImage') && obj.oCoords) {
    obj.setCoords();
    // top-left  corner
    if (obj.oCoords.tl.y > 0) {
      obj.top = 0;
    }
    if (obj.oCoords.tl.x > 0) {
      obj.left = 0;
    }
    if (obj.height && obj.scaleY && canvas.height && obj.oCoords.br.y < canvas.height) {
      obj.top = canvas.height - obj.height * obj.scaleY;
    }
    if (obj.width && obj.scaleX && canvas.width && obj.oCoords.br.x < canvas.width) {
      obj.left = canvas.width - obj.width * obj.scaleX;
    }
  }
};

export const handleSelectionRectMoving = (canvas: Canvas) => (e: IEvent<MouseEvent>) => {
  const obj: FabricTypes.Object | undefined = e.target;
  const { left, top } = canvas.overlayImage as FabricTypes.Image;
  const bottom = (top !== undefined &&
    canvas.overlayImage &&
    top + canvas.overlayImage.getScaledHeight()) as number;
  const right = (left &&
    canvas.overlayImage &&
    left + canvas.overlayImage.getScaledWidth()) as number;

  if (obj?.oCoords) {
    obj.setCoords();
    if (top !== undefined && obj.oCoords.tl.y < top) {
      obj.top = top;
    }
    if (bottom && obj.oCoords.bl.y > bottom) {
      obj.top = bottom - obj.getScaledHeight();
    }
    if (left !== undefined && obj.oCoords.tl.x < left) {
      obj.left = left;
    }
    if (right && obj.oCoords.tr.x > right) {
      obj.left = right - obj.getScaledWidth();
    }
  }
};

const _setScalingProperties = (left: number, top: number, scale: number) => {
  if (scalingProperties == null || scalingProperties['scale'] > scale) {
    scalingProperties = {
      left: left,
      top: top,
      scale: scale,
    };
  }
};

export const handleSelectionRectScaling = (canvas: Canvas) => (event: IEvent<MouseEvent>) => {
  const obj: FabricTypes.Object | undefined = event.target;
  const { left: imgLeft, top: imgTop } = canvas.overlayImage as FabricTypes.Image;
  const imgBottom = (imgTop !== undefined &&
    canvas.overlayImage &&
    imgTop + canvas.overlayImage.getScaledHeight()) as number;
  const imgRight = (imgLeft &&
    canvas.overlayImage &&
    imgLeft + canvas.overlayImage.getScaledWidth()) as number;

  if (obj?.oCoords) {
    const oldPos = obj.getBoundingRect();
    obj.setCoords();
    const newPos = obj.getBoundingRect();

    if (imgLeft !== undefined && newPos.left < imgLeft && obj.width && oldPos.left >= imgLeft) {
      obj.scaleX = (obj.getScaledWidth() - (imgLeft - newPos.left)) / obj.width;
      obj.left = imgLeft;
    }
    if (imgTop !== undefined && newPos.top < imgTop && obj.height && oldPos.top >= imgTop) {
      obj.scaleY = (obj.getScaledHeight() - (imgTop - newPos.top)) / obj.height;
      obj.top = imgTop;
    }
    if (imgBottom && obj.height && obj.oCoords.bl.y > imgBottom) {
      obj.scaleY = (obj.getScaledHeight() - (obj.oCoords.bl.y - imgBottom)) / obj.height;
    }
    if (obj.width && imgRight && obj.oCoords.tr.x > imgRight) {
      obj.scaleX = (obj.getScaledWidth() - (obj.oCoords.tr.x - imgRight)) / obj.width;
    }
    obj.setCoords();
  }
};

export const clearBackground = (canvas: Canvas) => {
  const objects = canvas._objects?.filter(
    (obj) => obj.name?.includes('backgroundImage') || obj.name?.includes('backgroundGradient'),
  );
  if (objects.length > 0) {
    objects.forEach((obj) => canvas.remove(obj));
  }
};

export const handleScaleImageToRatio = (canvas: Canvas, image?: FabricTypes.Object) => {
  if (image && canvas.height && canvas.width) {
    const angle = image.get('angle');
    image.set('angle', 0);
    image.scaleToHeight(canvas.height as number);
    const width = image.getScaledWidth() as number;
    const height = image.getScaledHeight() as number;

    if (
      (height > width && height >= canvas.height && width < canvas.width) ||
      (width < canvas.width && width < height)
    ) {
      image.scaleToWidth(canvas.width as number);
    }

    image.set({ angle });
    handleChangeObjectAlign(canvas, image, 'center-vertical');
    handleChangeObjectAlign(canvas, image, 'center-horizontal');
  }
};

const _getSize = (canvas: Canvas, colorType: ColorType) => {
  const activeObject = canvas.getActiveObject();
  let width = 0;
  let height = 0;

  if (colorType === 'background') {
    width = canvas.width as number;
    height = canvas.height as number;
  } else if (activeObject) {
    // if (
    //   activeObject.type === 'group' &&
    //   activeObject.name &&
    //   ['rect', 'circle', 'triangle', 'path', 'line'].includes(activeObject.name)
    // ) {
    //   const group = activeObject as FabricTypes.Group;
    //   width = group.item(0).width as number;
    //   height = group.item(0).height as number;
    // } else {
    width = activeObject.width as number;
    height = activeObject.height as number;
    // }
  }

  return { width, height };
};

const _getGradientCoords = (
  canvas: Canvas,
  gradientType: LinearGradientType | RadialGradientType,
  colorType: ColorType,
) => {
  const { width, height } = _getSize(canvas, colorType);
  if (width && height) {
    const coords: { [key: string]: object } = {
      horizontal: {
        x1: 0,
        y1: height / 2,
        x2: width,
        y2: height / 2,
      },
      vertical: {
        x1: 0,
        y1: 0,
        x2: 0,
        y2: height,
      },
      leftDiagonal: {
        x1: 0,
        y1: 0,
        x2: width,
        y2: height,
      },
      rightDiagonal: {
        x1: width,
        y1: 0,
        x2: 0,
        y2: height,
      },
      radialCenter: {
        r1: 0,
        r2: height / 2,
        x1: width / 2,
        y1: height / 2,
        x2: width / 2,
        y2: height / 2,
      },
      radialLeftTop: {
        r1: 0,
        r2: width,
        x1: 0,
        y1: 0,
        x2: 0,
        y2: 0,
      },
      radialTop: {
        r1: 0,
        r2: width,
        x1: width / 2,
        y1: 0,
        x2: width / 2,
        y2: 0,
      },
      radialRightCenter: {
        r1: 0,
        r2: width,
        x1: width,
        y1: height / 2,
        x2: width,
        y2: height / 2,
      },
    };

    return coords[gradientType];
  }

  return {};
};

const _getGradientRect = (
  canvas: Canvas,
  gradientType: LinearGradientType | RadialGradientType,
) => {
  return new fabric.Rect({
    left: -1,
    top: -1,
    width: canvas.width ? canvas.width + 1 : canvas.width,
    height: canvas.height ? canvas.height + 1 : canvas.height,
    name: `backgroundGradient_${gradientType}`,
    selectable: false,
    evented: false,
    lockMovementX: true,
    lockMovementY: true,
    lockScalingX: true,
    lockScalingY: true,
    lockRotation: true,
    hasControls: false,
  });
};

export const handleSetGradientBackground = (
  canvas: Canvas,
  type: 'linear' | 'radial',
  colors: string[],
  gradientRange: IGradientRangeValue,
  gradientType: LinearGradientType | RadialGradientType,
  colorType: ColorType,
): IGradient => {
  const coords = _getGradientCoords(canvas, gradientType, colorType);
  const grad = new fabric.Gradient({
    type,
    coords,
    colorStops: [
      { color: colors[0], offset: gradientRange.start },
      { color: colors[1], offset: gradientRange.end },
    ],
  });

  const activeObject = canvas.getActiveObject();
  switch (colorType) {
    case 'background': {
      clearBackground(canvas);
      const rect = _getGradientRect(canvas, gradientType);
      rect.set('fill', grad);
      canvas.add(rect);
      rect.sendToBack();
      canvas.setBackgroundColor('', canvas.renderAll.bind(canvas));
      canvas.renderAll();
      break;
    }
    case 'object': {
      if (activeObject) {
        // if (
        //   activeObject?.type === 'group' &&
        //   activeObject?.name &&
        //   ['rect', 'circle', 'triangle', 'path', 'line'].includes(activeObject.name)
        // ) {
        //   const group = activeObject as FabricTypes.Group;
        //   group.set('paintFirst', `backgroundGradient_${gradientType}`);
        //   group.item(0).set('fill', grad);
        // } else {
        activeObject.set('paintFirst', `backgroundGradient_${gradientType}`);
        activeObject.set('fill', grad);
        // }
      }
      canvas.renderAll();
      break;
    }
    default:
      break;
  }

  return { type: gradientType, options: grad.toObject() };
};

export const getPixelColor = (canvas: Canvas, point: FabricTypes.Point) => {
  const x = point.x * window.devicePixelRatio;
  const y = point.y * window.devicePixelRatio;
  const { data } = canvas.getContext().getImageData(x, y, 1, 1);
  return `rgba(${data.slice(0, 3).join(',')},1)`;
};

export const disableEyeDrop = (canvas: Canvas) => {
  if (canvas) {
    const canvasWrapper = document.getElementById('canvasWrapper');
    canvas.defaultCursor = 'default';
    canvasWrapper?.classList.remove('pipetteCursor');
    canvas.off('mouse:move');
  }
};

export const handleEyeDrop = (
  canvas: Canvas,
  setColor: Dispatch<SetStateAction<string | null>>,
  onFinish: (close?: boolean) => void,
) => {
  canvas.defaultCursor = 'none';
  const canvasWrapper = document.getElementById('canvasWrapper');
  canvasWrapper?.classList.add('pipetteCursor');

  const handleMouseClick = (event: MouseEvent) => {
    const element = event.target as HTMLElement;
    if (element.tagName.toLowerCase() === 'canvas') {
      onFinish();
    } else {
      setColor(null);
      onFinish(true);
    }
    disableEyeDrop(canvas);
    document.removeEventListener('mousedown', handleMouseClick);
  };

  document.addEventListener('mousedown', handleMouseClick);
  canvas.on('mouse:move', (event) => {
    const pointer = event.pointer as FabricTypes.Point;
    const rgbColor = getPixelColor(canvas, pointer);
    setColor(rgbColor);
  });
};

export const handleEyeDropGradient = (
  canvas: Canvas,
  setColor: Dispatch<SetStateAction<string[]>>,
  onFinish: (close?: boolean) => void,
  index: number,
) => {
  canvas.defaultCursor = 'none';
  const canvasWrapper = document.getElementById('canvasWrapper');
  canvasWrapper?.classList.add('pipetteCursor');

  const handleMouseClick = (event: MouseEvent) => {
    const element = event.target as HTMLElement;
    if (element.tagName.toLowerCase() === 'canvas') {
      onFinish();
    } else {
      setColor([]);
      onFinish(true);
    }
    disableEyeDrop(canvas);
    document.removeEventListener('mousedown', handleMouseClick);
  };

  document.addEventListener('mousedown', handleMouseClick);
  canvas.on('mouse:move', (event) => {
    const pointer = event.pointer as FabricTypes.Point;
    const rgbColor = getPixelColor(canvas, pointer);
    setColor((prev) => {
      const colors = [...prev];
      colors[index] = rgbColor;
      return colors;
    });
  });
};

export const handleChangeObjectAlign = async (
  canvas: Canvas,
  activeObject: FabricTypes.Object,
  key: (typeof ALIGN_OPTIONS)[number],
) => {
  if (activeObject.type === 'activeSelection') {
    handleChangeActiveSelectionAlign(activeObject, key);
  } else {
    activeObject.set({ centeredRotation: true });
    const bound = activeObject.getBoundingRect();
    const angle = activeObject.angle as number;
    const aCoords = activeObject.aCoords as { bl: Point; br: Point; tl: Point; tr: Point };

    switch (key) {
      case 'start-vertical': {
        activeObject.set({ left: (activeObject.left as number) - bound.left });
        break;
      }
      case 'center-vertical': {
        activeObject.viewportCenterH();
        break;
      }
      case 'end-vertical': {
        let left;
        if (angle <= 90) {
          left = canvas.width && aCoords.tl.x + (canvas.width - aCoords.tr.x);
        }
        if (angle > 90 && angle <= 180) {
          left = canvas.width;
        }
        if (angle > 180 && angle <= 270) {
          left = canvas.width && aCoords.tl.x + (canvas.width - aCoords.bl.x);
        }
        if (angle > 270) {
          left = canvas.width && aCoords.tl.x + (canvas.width - aCoords.br.x);
        }
        activeObject.set({ left });
        break;
      }
      case 'start-horizontal': {
        activeObject.set({ top: (activeObject.top as number) - bound.top });
        break;
      }
      case 'center-horizontal': {
        activeObject.viewportCenterV();
        break;
      }
      case 'end-horizontal': {
        let top;
        if (angle <= 90) {
          top = canvas.height && aCoords.tl.y + (canvas.height - aCoords.br.y);
        }
        if (angle > 90 && angle <= 180) {
          top = canvas.height && aCoords.tl.y + (canvas.height - aCoords.tr.y);
        }
        if (angle > 180 && angle <= 270) {
          top = canvas.height;
        }
        if (angle > 270) {
          top = canvas.height && aCoords.tl.y + (canvas.height - aCoords.bl.y);
        }
        activeObject.set({ top });
        break;
      }
      default:
        break;
    }
  }

  activeObject.setCoords();
  await canvas.renderAll();
};

export const handleChangeImageInShapeAlign = async (
  canvas: Canvas,
  activeObject: FabricTypes.Object,
  key: (typeof ALIGN_OPTIONS)[number],
) => {
  const image = getHiddenShapeImage(canvas, activeObject);
  if (image) {
    image.clone((cloned: FabricTypes.Image) => {
      const shape = activeObject;
      let imageWidth = cloned.width as number;
      let imageHeight = cloned.height as number;
      const shapeWidth = shape?.getScaledWidth() as number;
      const shapeHeight = shape?.getScaledHeight() as number;
      const pattern = activeObject.get('fill') as FabricTypes.Pattern;
      let offsetX = pattern.offsetX,
        offsetY = pattern.offsetY;

      if (
        imageWidth / imageHeight < shapeWidth / shapeHeight ||
        (imageHeight > imageWidth && imageHeight >= shapeHeight)
      ) {
        cloned.scaleToWidth(shapeWidth);
      } else {
        cloned.scaleToHeight(shapeHeight);
      }

      const source = new Image();
      source.src = cloned.toDataURL({ format: 'png' });

      source.onload = () => {
        imageWidth = source.width as number;
        imageHeight = source.height as number;

        switch (key) {
          case 'start-vertical': {
            offsetX = 0;
            break;
          }
          case 'center-vertical': {
            offsetX = (shapeWidth - imageWidth) / 2;
            break;
          }
          case 'end-vertical': {
            offsetX = shapeWidth - imageWidth;
            break;
          }
          case 'start-horizontal': {
            offsetY = 0;
            break;
          }
          case 'center-horizontal': {
            offsetY = (shapeHeight - imageHeight) / 2;
            break;
          }
          case 'end-horizontal': {
            offsetY = shapeHeight - imageHeight;
            break;
          }
          default:
            offsetX = 0;
            offsetY = 0;
            break;
        }
        pattern.offsetX = offsetX;
        pattern.offsetY = offsetY;
        activeObject.set('fill', pattern);

        canvas.renderAll();
      };
    });
  }
};

const getInclinedProperties = (
  angle: number,
  originalWidth: number,
  originalHeight: number
) => {
  const radians = (angle * Math.PI) / 180;
  const newWidth =
    Math.abs(Math.cos(radians)) * originalWidth +
    Math.abs(Math.sin(radians)) * originalHeight;

  const newHeight =
    Math.abs(Math.sin(radians)) * originalWidth +
    Math.abs(Math.cos(radians)) * originalHeight;

  return {
    width: Math.cos(radians) === 0 ? originalWidth : newWidth,
    height: Math.sin(radians) === 0 ? originalHeight : newHeight,
  };
};


const handleChangeActiveSelectionAlign = async (
  activeObject: FabricTypes.Object,
  key: (typeof ALIGN_OPTIONS)[number],
) => {
  const objectList = (activeObject as FabricTypes.ActiveSelection).getObjects();

  const maxWidth = Math.max(
    ...objectList.map((obj) =>
      getInclinedProperties(obj.angle as number, obj.width as number, obj.height as number)
        .width || 0
    )
  );
  const maxHeight = Math.max(
    ...objectList.map((obj) =>
      getInclinedProperties(obj.angle as number, obj.width as number, obj.height as number)
        .height || 0
    )
  );
  const initialDimensions = activeObject.getBoundingRect();

  switch (key) {
    case 'start-vertical':
      activeObject.set({ width: maxWidth, left: initialDimensions.left, originX: 'left' });
      objectList.forEach((item) => {
        const { width: itemWidth } = getInclinedProperties(
          item.angle as number,
          item.width as number,
          item.height as number
        );
        const center = item.getCenterPoint();
        item.set({
          originX: 'center',
          originY: 'center',
          left: -maxWidth / 2 + itemWidth / 2,
          top: center.y,
        });
      });
      break;

    case 'center-vertical':
      activeObject.set({ width: maxWidth });
      objectList.forEach((item) => {
        const center = item.getCenterPoint();
        item.set({
          top: center.y,
          left: 0,
          originY: 'center',
          originX: 'center',
        });
      });
      break;

    case 'end-vertical':
      activeObject.set({
        width: maxWidth,
        left: initialDimensions.left + initialDimensions.width - maxWidth,
        originX: 'left',
      });
      objectList.forEach((item) => {
        const { width: itemWidth } = getInclinedProperties(
          item.angle as number,
          item.width as number,
          item.height as number
        );
        const center = item.getCenterPoint();
        item.set({
          originX: 'center',
          originY: 'center',
          left: maxWidth / 2 - itemWidth / 2,
          top: center.y,
        });
      });
      break;

    case 'start-horizontal':
      activeObject.set({ height: maxHeight, top: initialDimensions.top, originY: 'top' });
      objectList.forEach((item) => {
        const { height: itemHeight } = getInclinedProperties(
          item.angle as number,
          item.width as number,
          item.height as number
        );
        const center = item.getCenterPoint();
        item.set({
          originX: 'center',
          originY: 'center',
          left: center.x,
          top: -maxHeight / 2 + itemHeight / 2,
        });
      });
      break;

    case 'center-horizontal':
      activeObject.set({ height: maxHeight });
      objectList.forEach((item) => {
        const center = item.getCenterPoint();
        item.set({
          top: 0,
          left: center.x,
          originY: 'center',
          originX: 'center',
        });
      });
      break;

    case 'end-horizontal':
      activeObject.set({
        height: maxHeight,
        top: initialDimensions.top + initialDimensions.height - maxHeight,
        originY: 'top',
      });
      objectList.forEach((item) => {
        const { height: itemHeight } = getInclinedProperties(
          item.angle as number,
          item.width as number,
          item.height as number
        );
        const center = item.getCenterPoint();
        item.set({
          originX: 'center',
          originY: 'center',
          left: center.x,
          top: maxHeight / 2 - itemHeight / 2,
        });
      });
      break;

    default:
      break;
  }

  activeObject.canvas?.requestRenderAll();
};


export const renderIcon = () => {
  const rotateIcon =
    "data:image/svg+xml,%3Csvg height='32px' width='32px' fill='%23000000' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' x='0px' y='0px' viewBox='0 0 33.317 28' enable-background='new 0 0 33.317 28' xml:space='preserve'%3E%3Cpath d='M16.659,24c-5.078,0-9.213-3.987-9.475-9h2.975l-4.5-5l-4.5,5h3.025c0.264,6.671,5.74,12,12.475,12c3.197,0,6.104-1.21,8.315-3.185l-2.122-2.122C21.188,23.127,19.027,24,16.659,24z'%3E%3C/path%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M29.133,14c-0.265-6.669-5.74-12-12.475-12c-3.197,0-6.104,1.21-8.315,3.185l2.122,2.122C12.129,5.873,14.29,5,16.659,5c5.077,0,9.213,3.987,9.475,9h-2.975l4.5,5l4.5-5H29.133z'%3E%3C/path%3E%3C/svg%3E";
  const rotateImg = document.createElement('img');
  rotateImg.src = rotateIcon;
  return function renderIcon(
    ctx: CanvasRenderingContext2D,
    left: number,
    top: number,
    styleOverride: any,
    fabricObject: FabricTypes.Object,
  ) {
    const size = 16;

    ctx.save();
    ctx.beginPath();
    ctx.arc(left, top, size / 2 + 2, 0, 2 * Math.PI, false);
    ctx.shadowColor = '#00000055';
    ctx.shadowBlur = 4;
    ctx.fillStyle = '#ffffff';
    ctx.fill();
    ctx.lineWidth = 2;
    ctx.restore();

    ctx.save();
    ctx.translate(left, top);
    ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle as number));
    ctx.drawImage(rotateImg, -size / 2, -size / 2, size, size);
    ctx.restore();
  };
};

export const getImageId = (object: FabricTypes.Object) => {
  if (!object.name) {
    return -1;
  }

  const name = object.name as string;
  const index = name.indexOf('_') + 1;
  return +name.substring(index, name.length);
};

export const changeTextLineHeight = async (
  canvas: Canvas,
  activeObject: FabricTypes.Object,
  value: number,
) => {
  if (activeObject && activeObject.type === 'textbox') {
    const textBox = activeObject as Textbox;
    textBox.set('lineHeight', value);
    await canvas.renderAll();
  }
};

export const alignText = async (
  activeObject: FabricTypes.Object,
  canvas: Canvas,
  value: string,
) => {
  if (activeObject && activeObject.type === 'textbox') {
    const textBox = activeObject as Textbox;
    textBox.set('textAlign', value);
    fabric.util.clearFabricFontCache();
    await canvas.renderAll();
  }
};

export const handleBulletList = (object: FabricTypes.Textbox) => {
  const bullet = '\u25CF';
  const text = object.text;
  const textArray: string[] = text?.split('\n') ?? [];
  const tempStr: string[] = [];
  object.set(
    'globalCompositeOperation',
    JSON.stringify({ ...JSON.parse(object.globalCompositeOperation || '{}'), listType: 'bullet' }),
  );
  textArray.forEach((text, i) => {
    if (text[0] === bullet) {
      tempStr.push(text);
    } else {
      tempStr.push(bullet + ' ' + text);
      object.selectionStart = object.selectionStart ? object.selectionStart + 2 : 2;
      object.selectionEnd = object.selectionEnd ? object.selectionEnd + 2 : 2;
    }
  });
  object.set('text', tempStr.join('\n'));
};

export const handleNumeratedList = (object: FabricTypes.Textbox) => {
  const text = object.text;
  const textArray: string[] = text?.split('\n') ?? [];
  const tempStr: string[] = [];
  object.set(
    'globalCompositeOperation',
    JSON.stringify({
      ...JSON.parse(object.globalCompositeOperation || '{}'),
      listType: 'numerated',
    }),
  );
  textArray.forEach((text, i) => {
    if (text.startsWith(`${i + 1}. `)) {
      tempStr.push(text);
    } else {
      tempStr.push(`${i + 1}. ` + text);
      const indexLength = `${i + 1}. `.length;
      object.selectionStart = object.selectionStart
        ? object.selectionStart + indexLength
        : indexLength;
      object.selectionEnd = object.selectionEnd ? object.selectionEnd + indexLength : indexLength;
    }
  });
  object.set('text', tempStr.join('\n'));
};

export const handleClearList = async (activeObject: any, prevType: string, canvas: Canvas) => {
  if (activeObject && activeObject.type === 'textbox') {
    const bullet = '\u25CF';
    const text = activeObject.text;
    const textArray: any[] = text.split('\n');
    const tempStr: any[] = [];
    activeObject.off('changed');

    activeObject.set(
      'globalCompositeOperation',
      JSON.stringify({
        ...JSON.parse(activeObject.get('globalCompositeOperation') || '{}'),
        listType: undefined,
      }),
    );

    switch (prevType) {
      case 'bullet':
        textArray.forEach((elem) => {
          if (elem[0] === bullet) {
            tempStr.push(elem.replace(`${bullet} `, ''));
          } else {
            tempStr.push(elem);
          }
        });
        activeObject.set('text', tempStr.join('\n'));
        break;
      case 'numerated':
        textArray.forEach((elem, i) => {
          if (elem.startsWith(`${i + 1}. `)) {
            tempStr.push(elem.replace(`${i + 1}. `, ''));
          } else {
            tempStr.push(elem);
          }
        });
        activeObject.set('text', tempStr.join('\n'));
        break;
      default:
        break;
    }
    await canvas.renderAll();
  }
};

export const setTextBoxStyle = (
  activeObject: FabricTypes.Object,
  key: keyof Textbox,
  value: number | string | boolean,
) => {
  const text = activeObject as Textbox;
  const { selectionStart, selectionEnd } = text;
  if (selectionStart !== undefined && selectionStart !== selectionEnd) {
    text.setSelectionStyles({ [key]: value }, selectionStart, selectionEnd);
  } else {
    text.removeStyle(key);
    text.set(key, value);
  }
};

export const changeFontWeight = async (
  activeObject: FabricTypes.Object,
  canvas: Canvas,
  value: number,
) => {
  if (activeObject && activeObject.type === 'textbox') {
    setTextBoxStyle(activeObject, 'fontWeight', value);
    await canvas.renderAll();
  }
};

export const changeFontStyle = async (
  activeObject: FabricTypes.Object,
  canvas: Canvas,
  value: 'italic' | 'normal',
) => {
  if (activeObject && activeObject.type === 'textbox') {
    setTextBoxStyle(activeObject, 'fontStyle', value as string);
    await canvas.renderAll();
  }
};

export const changeTextDecoration = async (
  activeObject: FabricTypes.Object,
  canvas: Canvas,
  key: 'linethrough' | 'underline',
  value: boolean,
) => {
  if (activeObject && activeObject.type === 'textbox') {
    setTextBoxStyle(activeObject, key, value);
    await canvas.renderAll();
  }
};

export const handleLists = async (
  object: Textbox,
  canvas: Canvas,
  callback?: VoidFunction,
  isActive?: boolean,
) => {
  const listType = JSON.parse(object.globalCompositeOperation || '{}')?.listType;

  if (isActive) {
    handleClearList(object, listType, canvas);
    await canvas.renderAll();
  } else {
    switch (listType) {
      case 'bullet':
        handleClearList(object, 'numerated', canvas).then(() => {
          handleBulletList(object);
        });
        object.on('changed', () => {
          handleBulletList(object);
          if (callback) {
            callback();
          }
          canvas.renderAll();
        });
        await canvas.renderAll();
        break;
      case 'numerated':
        handleClearList(object, 'bullet', canvas).then(() => {
          handleNumeratedList(object);
        });
        object.on('changed', () => {
          handleNumeratedList(object);
          if (callback) {
            callback();
          }
          canvas.renderAll();
        });
        await canvas.renderAll();
        break;
      default:
        break;
    }
  }
};

const handleUpperCase = (textbox: Textbox, canvas: Canvas) => async () => {
  const listType = JSON.parse(textbox.globalCompositeOperation || '{}')?.listType;
  if (listType) {
    await handleLists(textbox, canvas, () => {
      textbox.set('text', textbox.text?.toUpperCase());
    });
  } else {
    textbox.set('text', textbox.text?.toUpperCase());
    await canvas.renderAll();
  }
};

const handleLowerCase = (textbox: Textbox, canvas: Canvas) => async () => {
  const listType = JSON.parse(textbox.globalCompositeOperation || '{}')?.listType;
  if (listType) {
    await handleLists(textbox, canvas, () => {
      textbox.set('text', textbox.text?.toLowerCase());
    });
  } else {
    textbox.set('text', textbox.text?.toLowerCase());
    await canvas.renderAll();
  }
};

export const transformText = async (
  activeObject: FabricTypes.Object,
  canvas: Canvas,
  value: 'uppercase' | 'lowercase' | undefined,
) => {
  if (activeObject && activeObject.type === 'textbox') {
    const textbox = activeObject as Textbox;

    fabric.util.clearFabricFontCache();

    textbox.off('changed');

    const text = textbox.text;
    if (value === 'uppercase') {
      textbox.set('text', text?.toUpperCase());
      textbox.set(
        'globalCompositeOperation',
        JSON.stringify({
          ...JSON.parse(textbox.globalCompositeOperation || '{}'),
          uppercase: true,
          lowercase: false,
        }),
      );
      textbox.on('changed', handleUpperCase(textbox, canvas));
    } else if (value === 'lowercase') {
      textbox.set('text', text?.toLowerCase());
      textbox.set(
        'globalCompositeOperation',
        JSON.stringify({
          ...JSON.parse(textbox.globalCompositeOperation || '{}'),
          lowercase: true,
          uppercase: false,
        }),
      );
      textbox.on('changed', handleLowerCase(textbox, canvas));
    } else {
      textbox.set(
        'globalCompositeOperation',
        JSON.stringify({
          ...JSON.parse(textbox.globalCompositeOperation || '{}'),
          uppercase: false,
          lowercase: false,
        }),
      );
    }
    await canvas.renderAll();
  }
};

export const changeFontSize = async (
  activeObject: FabricTypes.Object,
  canvas: Canvas,
  value: number,
) => {
  if (activeObject && activeObject.type === 'textbox') {
    const textbox = activeObject as Textbox;
    textbox.set('fontSize', value);
    await canvas.renderAll();
  }
};

export const handleGetShape = (
  canvas: Canvas,
  key: (typeof SHAPE_NAMES)[number],
): FabricTypes.Object | null => {
  const id = Math.random().toString(16).slice(2);
  const basicOptions = {
    id: `${key}_${id}`,
    name: key,
    fill: '#D9D9D9',
    stroke: 'transparent',
    strokeWidth: 0,
    left: 30,
    top: 60,
    noScaleCache: false,
    objectCaching: false,
    // originX: 'center',
    // originY: 'center',
  };
  switch (key) {
    case 'ellipse': {
      return new fabric.Ellipse({
        ...basicOptions,
        rx: 180,
        ry: 180,
      });
    }
    case 'square': {
      return new fabric.Rect({
        ...basicOptions,
        width: 360,
        height: 360,
      });
    }
    case 'parallelogram': {
      return new fabric.Rect({
        ...basicOptions,
        width: 280,
        height: 360,
        skewX: -15,
      });
    }
    case 'triangle': {
      return new fabric.Triangle({
        ...basicOptions,
        width: 416,
        height: 360,
      });
    }
    case 'rhombus':
    case 'octagon':
    case 'hexagon':
    case 'star':
    case 'star1':
    case 'star2':
    case 'star3':
    case 'star4':
    case 'star5':
    case 'star6':
    case 'heart': {
      const path = new fabric.Path(SHAPE_PATH[key]);
      const pathWidth = path?.width as number;
      const scale = path && 360 / pathWidth;
      path.set({ ...basicOptions, scaleX: scale, scaleY: scale });
      handlePathScaling(canvas, path)();
      return path;
    }
    case 'line': {
      return new fabric.Line([0, 0, 360, 0], {
        stroke: '#000000',
        padding: 10,
        left: canvas.width && canvas.width / 2 - 180,
        top: canvas.height && canvas.height / 2 - 0.5,
        strokeWidth: LINE_STROKE_WIDTH,
      });
    }
    default:
      return null;
  }
};

export const changeFontFamily = (
  activeObject: FabricTypes.Object,
  canvas: Canvas,
  fontFamily: string,
  fontWeight = 400,
) => {
  if (activeObject && activeObject.type === 'textbox') {
    const textbox = activeObject as Textbox;
    textbox.set('fontFamily', fontFamily);
    changeFontWeight(activeObject, canvas, fontWeight);
    canvas.renderAll();
  }
};

export const handleShapeTextEdit = (canvas: Canvas, group: FabricTypes.Group) => () => {
  const textBox = group._objects[1] as FabricTypes.Textbox;
  const scale = (group.scaleX as number) * (textBox.scaleX as number);
  const width = Math.round(group.getScaledWidth() / scale);
  const textForEditing = new fabric.Textbox(textBox.text ?? '', {
    angle: group.angle,
    originX: 'center',
    originY: 'center',
    left: (group.left as number) + group.getScaledWidth() / 2,
    top: (group.top as number) + group.getScaledHeight() / 2,
    fill: textBox.fill,
    textBackgroundColor: textBox.textBackgroundColor,
    styles: textBox.styles,
    fontSize: textBox.fontSize,
    fontWeight: textBox.fontWeight,
    fontFamily: textBox.fontFamily,
    textAlign: textBox.textAlign,
    scaleX: scale,
    scaleY: scale,
    width: width - textBoxMargin * 2,
    globalCompositeOperation: JSON.stringify({}),
  });

  textBox.visible = false;
  group.addWithUpdate();
  textForEditing.visible = true;
  textForEditing.hasControls = false;
  canvas.add(textForEditing);
  canvas.setActiveObject(textForEditing);
  textForEditing.enterEditing();
  textForEditing.selectAll();
  textForEditing.on('editing:exited', () => {
    textBox.set({
      text: textForEditing.text,
      fill: textForEditing.fill,
      textBackgroundColor: textForEditing.textBackgroundColor,
      styles: textForEditing.styles,
      fontSize: textForEditing.fontSize,
      fontWeight: textForEditing.fontWeight,
      fontFamily: textForEditing.fontFamily,
      textAlign: textForEditing.textAlign,
      visible: true,
    });
    group.addWithUpdate();
    textForEditing.visible = false;
    canvas.remove(textForEditing);
    canvas.setActiveObject(group);
  });
};
export const handlePathScaling =
  (canvas: Canvas, shape: FabricTypes.Path) => (e?: IEvent<Event>) => {
    const scaleX = shape.scaleX ?? 1;
    const scaleY = shape.scaleY ?? 1;

    const scaledPath =
      shape.path &&
      shape.path.map((points: any) => {
        return points.map((point: string | number, index: number) => {
          if (typeof point === 'string') {
            return point;
          }
          return index % 2 === 0 ? point * scaleX : point * scaleY;
        });
      });
    if (shape.width && shape.height) {
      const width = shape.width * scaleX;
      const height = shape.height * scaleY;

      shape.set({
        scaleX: 1,
        scaleY: 1,
        width,
        height,
        path: scaledPath,
      });

      shape.set({
        pathOffset: {
          x: shape.pathOffset.x * scaleX,
          y: shape.pathOffset.y * scaleY,
        } as FabricTypes.Point,
      });

      if (e) {
        handleScaleImageInShape(e, canvas, {
          width,
          height,
        });
      }
    }
    canvas.renderAll();
  };

export const handleShapeScaling =
  (canvas: Canvas, shape: FabricTypes.Object & FabricTypes.IEllipseOptions) =>
  (e: IEvent<Event>) => {
    const { action, target } = e;
    if (action && ['scale', 'scaleX', 'scaleY'].includes(action) && target) {
      if (shape.type === 'path') {
        handlePathScaling(canvas, shape as FabricTypes.Path)(e);
        return;
      }

      const strokeWidth = shape.strokeWidth ?? 0;
      const newWidth = shape.getScaledWidth() - strokeWidth;
      const newHeight = shape.getScaledHeight() - strokeWidth;

      const shapeType = shape.name as (typeof SHAPE_NAMES)[number];
      switch (shapeType) {
        case 'ellipse': {
          shape.rx &&
            shape.ry &&
            shape.scaleX &&
            shape.scaleY &&
            shape.set({
              rx: shape.rx * shape.scaleX,
              ry: shape.ry * shape.scaleY,
            });
          break;
        }
        case 'square':
        case 'triangle': {
          shape.set({
            width: newWidth,
            height: newHeight,
          });
          break;
        }

        // TODO: parallelogram еще баг есть с ним, было тут до меня
        case 'parallelogram': {
          shape.set({
            width: newWidth * 0.7777777778,
            height: newHeight,
          });
          break;
        }
        default: {
          // const scaleX = newWidth / (shape.width as number);
          // const scaleY = newHeight / (shape.height as number);
          // shape.set({ scaleX, scaleY });
          break;
        }
      }

      shape.set({
        scaleX: 1,
        scaleY: 1,
      });

      handleScaleImageInShape(e, canvas, { width: newWidth, height: newHeight });
    }
  };

export const setCanvasControlStyle = (canvas: Canvas, activeObject: FabricTypes.Object) => {
  activeObject.transparentCorners = false;
  activeObject.cornerStyle = 'circle';
  activeObject.cornerSize = 48;
  activeObject.cornerColor = 'rgba(102, 32, 199, 1)';
  activeObject.cornerStrokeColor = 'rgba(255, 255, 255, 1)';
  activeObject.borderColor = 'rgba(102, 32, 199, 1)';
  activeObject.borderScaleFactor = 4;

  if (activeObject.type === 'activeSelection') {
    const objectList = (activeObject as FabricTypes.ActiveSelection).getObjects();
    objectList.forEach((obj) => {
      obj.set({ borderColor: 'transparent' });
    });
  }
  if (activeObject.type === 'group' || activeObject.name?.includes('image_')) {
    activeObject.setControlsVisibility({
      mt: false,
      mr: false,
      mb: false,
      ml: false,
    });
  }
  if (
    activeObject.name === 'line' ||
    activeObject.type === 'line' ||
    activeObject.type === 'textbox'
  ) {
    activeObject.setControlsVisibility({
      bl: false,
      br: false,
      tl: false,
      tr: false,
      mt: false,
      mb: false,
    });
  }
  //
  canvas.renderAll();
};

export const handleDeleteItemFromSavedObjects = (object?: IObjectWithId) => {
  return new Promise<void>((resolve) => {
    const index = savedObjects?.findIndex((obj: IObjectWithId) => obj.id === object?.id);
    if (index !== undefined && index > -1) {
      savedObjects?.splice(index, 1);
      resolve();
    } else {
      resolve();
    }
  });
};

export const handleSelectGroupItem =
  (canvas: Canvas) =>
  ({ target, subTargets }: IEvent<MouseEvent>) => {
    savedObjects = (target as FabricTypes.Group)?.getObjects();
    (target as FabricTypes.Group)?.toActiveSelection();
    const groupItem = subTargets && subTargets[subTargets.length - 1];
    if (groupItem) {
      setTimeout(() => {
        canvas.off('selection:cleared');
        canvas.on('selection:cleared', () => {
          if (savedObjects?.length && savedObjects?.length > 1) {
            const group = new fabric.Group(savedObjects, {
              subTargetCheck: true,
              centeredRotation: true,
            });
            savedObjects?.forEach((obj) => canvas.remove(obj));
            group.off('mousedblclick');
            group.on('mousedblclick', handleSelectGroupItem(canvas));
            canvas.add(group);
            canvas.renderAll();
          }
          savedObjects = undefined;
          canvas.off('selection:cleared');
        });
        canvas.setActiveObject(groupItem);
        canvas.renderAll();
      }, 0);
    }
  };

const _handleMove = (canvas: Canvas, index: number) => {
  const activeObject = canvas.getActiveObject();
  if (activeObject) {
    if (activeObject.type === 'activeSelection') {
      (activeObject as FabricTypes.ActiveSelection)
        .getObjects()
        .forEach((obj) => obj.moveTo(index));
    } else {
      activeObject.moveTo(index);
    }
    canvas.renderAll();
  }
};

export const handleMoveObject =
  (editor: FabricJSEditor, key: (typeof MOVE_OPTIONS)[number]) => () => {
    const { canvas } = editor as FabricJSEditor;
    const objects = canvas.getObjects();
    const activeObject = canvas.getActiveObject();
    if (activeObject) {
      const index = (activeObject && objects.indexOf(activeObject)) as number;
      const lastIndex = objects.length - 1;
      const isHaveLayoutGrid = objects.some((obj: any) => obj.name === 'grid');
      const isHaveBgRect = objects.some(
        (obj) => obj.name?.includes('backgroundImage') || obj.name?.includes('backgroundGradient'),
      );

      switch (key) {
        case 'back':
          if (isHaveBgRect ? index > 1 : index > 0) {
            _handleMove(canvas, index - 1);
          }
          break;
        case 'backward':
          if (isHaveBgRect) {
            _handleMove(canvas, 1);
          } else {
            canvas.sendToBack(activeObject);
          }
          canvas.renderAll();
          break;
        case 'front':
          if (isHaveLayoutGrid ? index < lastIndex - 1 : index < lastIndex) {
            _handleMove(canvas, index + 1);
            canvas.renderAll();
          }
          break;
        case 'forward':
          if (isHaveLayoutGrid) {
            _handleMove(canvas, lastIndex - 1);
          } else {
            canvas.bringToFront(activeObject);
          }
          canvas.renderAll();
          break;
        default:
          break;
      }
    }
  };

export const handleSetDoubleClick = (canvas: Canvas, object: FabricTypes.Object) => {
  if (object.type === 'group') {
    object.off('mousedblclick');
    // if (object.name && ['rect', 'circle', 'triangle', 'path'].includes(object.name)) {
    //   object.on('mousedblclick', handleShapeTextEdit(canvas, object as FabricTypes.Group));
    //   object.off('scaling');
    //   object.on('scaling', handleShapeScaling(canvas, object as FabricTypes.Group));
    // } else {
    object.on('mousedblclick', (e) => handleSelectGroupItem(canvas)(e));
    // }
  }
};

export const handleReloadTextBoxes = (object: FabricTypes.Object) => {
  const defaultParams = {
    selectable: true,
    hasControls: true,
    lockMovementX: false,
    lockMovementY: false,
  };

  // if (object.type === 'group') {
  // const group = object as FabricTypes.Group;
  // const textBoxes = group.getObjects('textbox');
  // if (textBoxes?.length) {
  //   textBoxes.forEach((textBox) =>
  //     textBox.set(defaultParams),
  //   );
  // }
  // }
  if (object.type === 'textbox') {
    object.set(defaultParams);
  }
};

export const handleAddImageInShape = (
  canvas: Canvas,
  image: FabricTypes.Image,
  size?: { width: number; height: number },
) => {
  return new Promise<void>((resolve) => {
    const shape = canvas.getActiveObject() as FabricTypes.Object;
    let imageWidth = image.width as number;
    let imageHeight = image.height as number;
    const width = size?.width ?? (shape?.getScaledWidth() as number);
    const height = size?.height ?? (shape?.getScaledHeight() as number);

    if (
      imageWidth / imageHeight < width / height ||
      (imageHeight > imageWidth && imageHeight >= height)
    ) {
      image.scaleToWidth(width);
    } else {
      image.scaleToHeight(height);
    }

    const source = new Image();
    source.src = image.toDataURL({ format: 'png' });

    source.onload = () => {
      imageWidth = source.width as number;
      imageHeight = source.height as number;
      shape.set(
        'fill',
        new fabric.Pattern({
          source,
          repeat: 'no-repeat',
          offsetX: width / 2 - imageWidth / 2,
          offsetY: height / 2 - imageHeight / 2,
        }),
      );
      canvas.renderAll();
      resolve();
    };
  });
};

export const handleEditImageInShape = (
  shape: FabricTypes.Object,
  image: FabricTypes.Image,
  canvas: Canvas,
) => {
  return new Promise<void>((resolve) => {
    let imageWidth = image.width as number;
    let imageHeight = image.height as number;
    const width = shape?.getScaledWidth() as number;
    const height = shape?.getScaledHeight() as number;

    image.set({
      visible: true,
      scaleX: 1,
      scaleY: 1,
    });

    if (imageHeight > imageWidth && imageHeight >= height) {
      image.scaleToWidth(width);
    } else {
      image.scaleToHeight(height);
    }

    const source = new Image();
    source.src = image.toDataURL({ format: 'png' });

    source.onload = () => {
      imageWidth = source.width as number;
      imageHeight = source.height as number;
      shape.set(
        'fill',
        new fabric.Pattern({
          source,
          repeat: 'repeat',
          offsetX: width / 2 - imageWidth / 2,
          offsetY: height / 2 - imageHeight / 2,
        }),
      );
      shape.set({
        visible: true,
      });
      canvas.renderAll();

      resolve();
    };
    image.set({
      visible: false,
      scaleX: 1,
      scaleY: 1,
    });
  });
};

export const getHiddenShapeImage = (canvas: Canvas, target?: FabricTypes.Object) => {
  const params = target?.globalCompositeOperation as string;
  if (!target || !params || !params.includes('_')) {
    return;
  }

  const [, imageId, customId] = params.split('_');
  return canvas
    .getObjects()
    .find((obj: FabricTypes.Object) => obj.name === `${target.name}_${imageId}_${customId}`) as
    | FabricTypes.Image
    | undefined;
};

export const handleScaleImageInShape = (
  e: IEvent<Event>,
  canvas: Canvas,
  size?: { width: number; height: number },
) => {
  const { action, target } = e;
  if (action && ['scale', 'scaleX', 'scaleY'].includes(action) && target) {
    const imageObject = getHiddenShapeImage(canvas, target);
    if (imageObject) {
      const url = imageObject.getSrc();
      fetch(url)
        .then((res) => {
          res.blob().then((blob) => {
            handleParseImage(blob, (image) => {
              if (imageObject.filters) {
                image.applyFilters(imageObject.filters);
              }
              handleAddImageInShape(canvas, image, size);
            });
          });
        })
        .catch(() => null);
    }
  }
};

export const handleDeleteImageInShape = (canvas: Canvas, object?: FabricTypes.Object) => {
  return new Promise<void>((resolve) => {
    if (object?.name && SHAPE_NAMES.includes(object.name as (typeof SHAPE_NAMES)[number])) {
      const params = object.globalCompositeOperation as string;
      if (!params || !params.includes('_')) {
        resolve();
        return;
      }
      const [, customId, imageId] = params.split('_');
      const imageObject = canvas
        .getObjects()
        .find((obj: FabricTypes.Object) => obj.name === `${object.name}_${customId}_${imageId}`);
      if (imageObject) {
        canvas.remove(imageObject);
      }
      resolve();
    } else {
      resolve();
    }
  });
};

export const handleReloadShapes = (canvas: Canvas, object: FabricTypes.Object) => {
  if (object.name && SHAPE_NAMES.includes(object.name as (typeof SHAPE_NAMES)[number])) {
    object.off('modified');
    object.on('modified', handleShapeScaling(canvas, object));
  }
};

export const focusOnTextBox = (textbox: Textbox) => {
  textbox.enterEditing();
  textbox.hiddenTextarea?.focus();
  fabric.util.clearFabricFontCache();
};

export const blurTextBox = (textbox: Textbox) => {
  textbox.exitEditing();
  textbox.hiddenTextarea?.blur();
};

export const handleParseImage = (imageBlob: Blob, callback: (image: FabricTypes.Image) => void) => {
  const reader = new FileReader();
  reader.onload = (event) => {
    const dataUrl = event.target?.result as string;
    fabric.Image.fromURL(dataUrl, callback);
  };
  reader.readAsDataURL(imageBlob);
};

export const handleScaleImageToCanvas = (
  image: FabricTypes.Object,
  imageId: number,
  canvasRatio = 'default',
) => {
  const { height, width } = CANVAS_SIZE[canvasRatio];
  if (image.height && image?.width) {
    if (image.width > width) {
      image.scaleToWidth(width);
      image.set({ top: height / 2 - image.getScaledHeight() / 2 });
    } else {
      image.set({
        top: height / 2 - image.height / 2,
        left: width / 2 - image.width / 2,
      });
    }
  }
  image.name = `image_${imageId}`;
};

export async function loadFontAndProceed(
  fontWeight: string | number,
  fontSize: number,
  fontFamily: string,
  callback?: () => void,
) {
  document.fonts
    .load(`${fontWeight} ${fontSize}em ${fontFamily}`)
    .then(() => {
      callback && callback();
    })
    .catch((error) => {
      console.error(error);
    });
}

const parseFontURL = (fontURL: string): FontWeights => {
  const params = fontURL.split('?')[1].split('|');
  const fontWeights: FontWeights = {};

  params.forEach((param) => {
    const parts = param.split(':');
    const fontFamily = parts[0].replace('+', ' ');
    if (parts.length === 2) {
      const weightsString = parts[1].replace('wght@', '');
      fontWeights[fontFamily] = weightsString.split(';').map((weight) => parseInt(weight));
    }
  });

  return fontWeights;
};

export const loadAllFonts = () => {
  const fonts = parseFontURL(FONTS_URL);

  Object.entries(fonts).forEach(([fontStyle, fontWeights]) => {
    fontWeights.forEach((fontWeight) => {
      loadFontAndProceed(fontWeight, 1, fontStyle);
    });
  });
};

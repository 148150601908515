import styled, { css } from 'styled-components';
import { Headline } from '../../components/headline.component';
import { Text } from '../../components/text.component';
import { RoundButton } from '../../components/round-btn.component';
import { NavigationLink } from '../../components/navigation.component';
import { CardWrapper } from '../../components/card.component';
import { InputOverlay as InputCheckbox } from '../../components/card.checkbox.overlay';

export const Wrapper = styled.div`
  height: 72vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${(p) =>
    `linear-gradient(0deg, ${p.theme.primary}, ${p.theme.primary}), ${p.theme.white}`};
  border-radius: 16px;
  & > ${Headline}, ${Text} {
    color: ${(p) => p.theme.white};
  }
  & > ${Headline} {
    margin-bottom: 0.625rem;
  }
`;

export const SparkleWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  margin-top: 1.875rem;
`;

export const ContactUs = styled(RoundButton).attrs({
  icon: 'question',
  btnStyle: 'primary',
  position: 'right',
  title: 'Задать вопрос',
})`
  z-index: 99;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
  & > span[class^='icon-'] {
    font-size: 1.5rem !important;
  }
`;

const ColumnTemplate = styled.div`
  position: relative;
  min-height: 1px;
  display: block;
`;

export const LeftColumn = styled(ColumnTemplate)`
  max-width: 25%;
  @media (min-width: 1280px) {
    max-width: 20%;
  }

  display: flex;
  flex-direction: column;
  gap: 0.625rem;
  position: fixed;
  z-index: 1002;
  top: 100px;
`;

export const RightColumn = styled(ColumnTemplate)`
  flex: 1;
  max-width: calc(100% - 16.5rem);
  left: 15.625rem;

  .templates {
    width: 100%;

    > div {
      margin-bottom: 0.625rem;
    }
  }

  .swiper-track {
    display: flex;
    gap: 0.625rem;
    overflow: hidden;
    max-width: 100%;
    width: 100%;
  }

  .swiper-wrapper {
    max-width: 100%;
  }

  .swiper {
    position: relative;
  }

  .swiper-button {
    width: 3.125rem;
    height: 3.125rem;
    border-radius: 50%;
    background: #fff;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 99;
    font-size: 1.25rem;

    &:hover {
      background: ${(p) => p.theme.primary};
      color: #fff;
    }
  }

  .swiper-button-prev {
    left: 0.625rem;
  }

  .swiper-button-next {
    right: 0.625rem;
  }

  .swiper-button-disabled {
    display: none;
  }
`;

export const Column = styled(ColumnTemplate)<{
  active?: boolean;
  highlight?: boolean;
  withOutline?: boolean;
}>`
  position: relative;
  border-radius: 16px;
  max-width: 33.33333%;
  @media (min-width: 1280px) {
    max-width: 25%;
  }

  border: 2px solid transparent;
  ${({ highlight, active }) =>
    highlight &&
    css`
      border: 2px solid ${active ? '#6620C7' : '#cccccc'};
      user-select: none;
    `}

  ${({ highlight }) =>
    highlight &&
    css`
      &:hover {
        border: 2px solid #6620c7 !important;
        & > ${InputCheckbox} {
          border: 2px solid #6620c7 !important;
        }
      }
    `}

   ${({ withOutline }) =>
    withOutline &&
    css`
      border: none;
    `} 

   ${({ withOutline, highlight, active }) =>
    highlight &&
    withOutline &&
    css`
      border: none;
      outline: 2px solid ${active ? '#6620C7' : '#cccccc'};
    `} 

   ${({ highlight, withOutline }) =>
    withOutline &&
    highlight &&
    css`
      &:hover {
        outline: 2px solid #6620c7 !important;
        border: none !important;
        & > ${InputCheckbox} {
          border: none;
        }
      }
    `}
`;

export const Menu = styled(CardWrapper)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: ${(p) =>
    `linear-gradient(0deg, ${p.theme.primary}, ${p.theme.primary}), ${p.theme.white}`};
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
`;

export const MenuItem = styled(NavigationLink)`
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)),
    ${(p) => p.theme.primary};
  color: ${(p) => p.theme.white};
`;

export const Thumbnail = styled.div<{
  full?: boolean;
  clickable?: boolean;
  url?: string;
  squared?: boolean;
  scalableImage?: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 34px;
  min-height: 51px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background: ${(p) => (p.url ? `url(${p.url}) no-repeat 0 0` : 'transparent')};
  background-size: cover;
  padding: 0.5rem;
  & > span[class^='icon-'] {
    font-size: 3rem;
    color: ${(p) => p.theme.primary};
    transition: all 250ms ease-in-out;
  }
  &:hover {
    & > span[class^='icon-'] {
      transform: scale(1.5);
      color: ${(p) => p.theme.darkPrimary};
    }
  }
  ${(p) =>
    p.full &&
    css`
      flex: 1 1;
      min-height: 13.625rem;
    `}
  ${(p) =>
    p.clickable &&
    css`
      cursor: pointer;
    `}

  ${(p) =>
    p.squared &&
    css`
      width: 200px;
      height: 200px;
      background-size: 'cover';
      background-position: 'top';
    `}

  ${(p) =>
    p.scalableImage &&
    css`
      .scalable {
        width: 35px;
        height: 35px;
      }

      &:hover {
        .scalable {
          transform: scale(1.143);
          transition: all 250ms;
        }
      }
    `}
`;

export const InputOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99;
`;

export const ProjectNameInput = styled.textarea<any>`
  width: 100%;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21.86px;
  border: none;
  background: #fff;
  color: #000000;
  overflow: hidden;
  display: -webkit-box;
  word-break: break-all;
  white-space: 'pre-line';

  resize: none;
  &:focus,
  :active {
    outline: 1px dashed #6620c7;
    border-radius: 8px;
  }

  & + ${InputOverlay} {
    display: none;
  }

  &:focus {
    z-index: 100;
    position: relative;
    & + ${InputOverlay} {
      display: block;
    }
  }
`;

export const NewProjectWithTemplateModalBody = styled.div`
  padding: 0.25rem;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;

  .body {
    display: flex;
    gap: 1.875rem;
  }

  .df-cl {
    display: flex;
    flex-direction: column;
    gap: 0.625rem;

    button {
      width: 4.59375rem;
    }
  }

  .df-rw {
    display: flex;
    gap: 0.375rem;
  }

  .template {
    .image:hover img {
      border: none;
    }
  }

  .details {
    display: flex;
    flex-direction: column;
    gap: 1.875rem;
    flex: 1;
  }

  .df-sb {
    display: flex;
    gap: 6.0625rem;
    justify-content: space-between;
  }

  .mt-10 {
    margin-top: 0.625rem;
  }

  .new-card-container {
    border: 1px solid #dfdfdf;
    background: #f5f5f5;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: center;
    justify-content: center;
    text-align: center;

    &.selected {
      border-color: ${(p) => p.theme.primary};
    }
    img {
      width: 30px;
      height: 30px;
    }
  }
`;

const components = {
  Wrapper,
  SparkleWrapper,
  ContactUs,
  LeftColumn,
  RightColumn,
  Column,
  Menu,
  MenuItem,
  Thumbnail,
  InputOverlay,
};

export default components;

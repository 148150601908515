import React, { useEffect } from 'react'
import PublicOfferDocument from '../../assets/files/public-offer.pdf'
const PublicOfferPage = () => {
  useEffect(() => {
    window.location.href = PublicOfferDocument;
  }, [])

  return (
    <></>
  )
}

export default PublicOfferPage
import styled from 'styled-components';

export const Card = styled.div<{ color: string; success?: boolean }>`
  background-color: ${({ color }) => color};
  border-radius: 16px;
  min-width: 300px;
  min-height: 300px;
  width: calc(50vw - 15px);
  height: calc(100vh - 15px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media (max-width: 740px) {
    width: calc(100vw - 15px);
    height: calc(50vh - 15px);
  }
  ${({ success }) =>
    success &&
    `
  width: calc(100vw - 15px) !important;
  height: calc(100vh - 15px) !important;
  `}
`;

export const MobilePageWrapper = styled.div`
  background-color: #f0f0f0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 10px;
  @media (max-width: 740px) {
    flex-wrap: wrap;
  }
`;

export const CardWrapper = styled.div`
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  gap: 10px;
`;

import React, { useEffect, useState } from 'react';
import { IModalOptions } from '../interfaces/modal.interface';
import { Button } from './button.component';
import { useTranslation } from 'react-i18next';
import { Flex } from './flexbox.component';
import styled from 'styled-components';
import { ImageFilters } from './image-filters.component';
import { handleEditImageInShape } from '../utils/editor.utils';
import FabricTypes from 'fabric/fabric-impl';
import { useCanvasHistory } from '../contexts/canvas-history.context';
import { fabric } from 'fabric';
import { FabricJSCanvas, FabricJSEditor, useFabricJSEditor } from 'fabricjs-react';
import { INITIAL_FILTER } from '../constants';
import { Modal } from './modal.component';
import { ModalHeader } from './modal-template.component';
import { PropertiesModalType } from '../interfaces/editor.interface';
import { useNotification } from '../contexts/notification.context';

const ModalFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1.59375rem;
  & button:first-child {
    padding: 0;
  }
`;

const PropertyImageContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

interface IProps extends IModalOptions {
  title?: string;
  editor: any;
  imageId: number;
  activeObject: any;
  type?: 'bg' | 'shape' | 'image';
  handleSetBackgroundImage: (image: FabricTypes.Image, id: number) => void;
  setBackgroundImage?: any;
  setShowImagePropertiesModal: React.Dispatch<React.SetStateAction<PropertiesModalType>>;
}

const ImagePropertiesModal: React.FC<IProps> = ({
  title,
  editor,
  activeObject,
  type,
  imageId,
  handleSetBackgroundImage,
  setBackgroundImage,
  setShowImagePropertiesModal,
}) => {
  const { t } = useTranslation();
  const { setNotification } = useNotification();
  const { historySaveAction } = useCanvasHistory();
  const { editor: fabricEditor, onReady } = useFabricJSEditor();
  const [filter, setFilter] = useState({ ...INITIAL_FILTER });
  const [initialFilter, setInitialFilter] = useState<FabricTypes.IBaseFilter[]>();
  const [addedImage, setAddedImage] = useState<FabricTypes.Image | null>(null);
  const [objectAngle, setObjectAngle] = useState(0);

  const ImageName =
    activeObject?.id &&
    activeObject.globalCompositeOperation &&
    activeObject.id.split('_')[0] +
      '_' +
      activeObject.globalCompositeOperation.split('_')[1] +
      '_' +
      activeObject.globalCompositeOperation.split('_')[2];

  const object =
    type === 'bg' || type === 'image'
      ? activeObject
      : editor.canvas.getObjects().find((img: FabricTypes.Image) => img.name === ImageName);

  useEffect(() => {
    if (!object) {
      setNotification({
        text: 'Что-то пошло не так',
      });
    }
  }, []);

  if (!object) {
    return null;
  }

  useEffect(() => {
    type !== 'shape' && setObjectAngle(object.angle);
  }, []);

  useEffect(() => {
    type !== 'shape' && setInitialFilter([...object.filters]);
    if (type === 'bg' && setBackgroundImage && object) {
      setBackgroundImage(object);
    }
  }, [object]);

  const handleClose = () => {
    object.angle = objectAngle;
    setShowImagePropertiesModal({ show: false, id: 0 });
    if (type === 'bg') {
      object.set({
        filters: [
          new fabric.Image.filters.Brightness({
            brightness: 0,
          }),
          new fabric.Image.filters.Contrast({
            contrast: 0,
          }),
          new fabric.Image.filters.Saturation({
            saturation: 0,
          }),
          new fabric.Image.filters.Blur({
            blur: 0,
          }),
        ],
      });
      object.set('opacity', 1);
      object.set('gamma', [1, 1, 1]);
      object.applyFilters();
      handleSetBackgroundImage(object, imageId);
    }
    editor.canvas.renderAll();

    if (type !== 'bg') {
      setFilter(object.filters);
    }

    object.filters = initialFilter;
    object.applyFilters();
    fabricEditor?.canvas.renderAll();
    if (addedImage && fabricEditor) {
      fabricEditor.canvas.remove(addedImage);
      fabricEditor.canvas.renderAll();
      setAddedImage(null);
    }
    editor.canvas.renderAll();
  };

  const handleOk = () => {
    object.angle = objectAngle;
    if (type === 'shape') {
      if (activeObject) {
        object.applyFilters();
        handleEditImageInShape(activeObject, object, editor.canvas).then(() => {
          historySaveAction(editor);
        });
      }
    } else if (type === 'bg') {
      handleSetBackgroundImage(object, imageId);
      if (addedImage && fabricEditor) {
        fabricEditor.canvas.remove(addedImage);
        fabricEditor.canvas.renderAll();
        setAddedImage(null);
      }
    }
    editor.canvas.renderAll();
    if (type !== 'shape') {
      historySaveAction(editor);
    }
    setShowImagePropertiesModal({ show: false, id: 0 });
  };

  const handleResetFilters = () => {
    setFilter({ ...INITIAL_FILTER });
  };

  const nullFilter = JSON.stringify(INITIAL_FILTER) === JSON.stringify(filter);

  useEffect(() => {
    if (
      fabricEditor &&
      object &&
      object.type === 'image' &&
      fabricEditor.canvas._objects.length === 0
    ) {
      const fabricCanvas = fabricEditor.canvas;
      fabricCanvas.setWidth(752);
      fabricCanvas.setHeight(500);
      fabricCanvas.setBackgroundColor('#0000000F', fabricCanvas.renderAll.bind(fabricCanvas));
      if (type === 'shape') {
        fabric.Image.fromURL(object.src, (img) => {
          const center = fabricCanvas.getCenter();
          const checkWidth = img.width && img.width > 752;
          const checkHeight = img.height && img.height > 500;

          if (img.width && img.height) {
            const scale = checkWidth ? 752 / img.width : checkHeight ? 500 / img.height : 1;
            const scaledWidth = img.width * scale;
            const scaledHeight = img.height * scale;

            img.set({
              left: center.left - scaledWidth / 2,
              top: center.top - scaledHeight / 2,
              scaleX: scale,
              scaleY: scale,
              filters: object.filters,
              selectable: false,
              angle: 0,
            });
            img.applyFilters();
            fabricCanvas.add(img);
            fabricCanvas.renderAll();
            setAddedImage(img);
          }
        });
      } else if (type === 'bg') {
        const center = fabricCanvas.getCenter();
        const maxHeight = 500;
        const aspectRatio = object.width / object.height;
        const newHeight = object.height > maxHeight ? maxHeight : object.height;
        const newWidth = newHeight * aspectRatio;

        object.set({
          left: center.left - newWidth / 2,
          top: center.top - newHeight / 2,
          scaleX: newWidth / object.width,
          scaleY: newHeight / object.height,
          filters: object.filters,
          selectable: false,
          angle: 0,
        });

        object.applyFilters();
        fabricCanvas.add(object);
        fabricCanvas.renderAll();
        setAddedImage(object);
      } else {
        const center = fabricCanvas.getCenter();
        const maxHeight = 500;
        const aspectRatio = object.width / object.height;
        const newHeight = object.height > maxHeight ? maxHeight : object.height;
        const newWidth = newHeight * aspectRatio;
        const clone = fabric.util.object.clone(editor.canvas.getActiveObject());

        clone.set({
          left: center.left - newWidth / 2,
          top: center.top - newHeight / 2,
          scaleX: newWidth / object.width,
          scaleY: newHeight / object.height,
          filters: object.filters,
          selectable: false,
          angle: 0,
        });

        object.applyFilters();
        fabricCanvas.add(clone);
        fabricCanvas.renderAll();
        setAddedImage(object);
      }
    }
  }, [fabricEditor, object]);

  return (
    <Modal width="980px" height="fit-content" style={{ minHeight: '419px' }} onClose={handleClose}>
      <ModalHeader>{title ?? 'Настройка фонового изображения'}</ModalHeader>
      <PropertyImageContainer>
        <FabricJSCanvas onReady={onReady} />
        <ImageFilters
          filter={filter}
          setFilter={setFilter}
          editor={fabricEditor as FabricJSEditor}
          activeObject={object}
          modalFilter
        />
      </PropertyImageContainer>
      <ModalFooter>
        <Button
          btnStyle={'cancel'}
          icon={'reset'}
          onClick={handleResetFilters}
          disabled={nullFilter}
        >
          {t('action.reset')}
        </Button>
        <Flex alignItems="center" gap={20}>
          <Button btnStyle={'cancel'} onClick={handleClose}>
            {t('action.cancel')}
          </Button>
          <Button icon={'check'} onClick={handleOk}>
            {t('action.done')}
          </Button>
        </Flex>
      </ModalFooter>
    </Modal>
  );
};

export default ImagePropertiesModal;

import React from 'react';
import { AfterTabLine, PreviewImageTab } from '../preview-image.style';
import { ItemType, OzonType } from '../../../interfaces/preview-image.interface';
import { Flex, FlexCol } from '../../../components/flexbox.component';

type THeaderItem = {
  headerItem: {
    items: Array<{ title: ItemType }>;
    activeItem: ItemType;
    onItemSelect: (value: ItemType) => void;
  };
  idx: number;
};

const HeaderItem: React.FC<THeaderItem> = ({ headerItem, idx }) => {
  return (
    <FlexCol>
      <Flex gap={6}>
        {headerItem.items.map((item) => (
          <PreviewImageTab
            key={item.title}
            active={headerItem.activeItem === item.title}
            onClick={() => headerItem.onItemSelect(item.title)}
          >
            {item.title}
          </PreviewImageTab>
        ))}
        {/* Что бы последний исключить с last-child линию :D */}
        {idx !== headerItem.items.length && (
          <AfterTabLine marginFromLeft={15} marginFromRight={15} />
        )}
      </Flex>
    </FlexCol>
  );
};

export default HeaderItem;

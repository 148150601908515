import React, { useEffect } from 'react';
import { RgbaStringColorPicker } from 'react-colorful';
import { useTranslation } from 'react-i18next';
import { BG_COLORS } from '../../constants';
import { Flex } from '../flexbox.component';
import { Color, EyeDropBtn, Hr, TransparentBtn } from './color-popover.style';

interface IFillBackGround {
  enableColorEyeDrop?: boolean;
  _handleEyeDrop: (gradient?: boolean) => void;
  eyeDropColor?: string | null;
  color?: string;
  handleChange?: (rgbaColor: string) => void;
  hexColor?: string;
  hexToRgba: (hexColorStr: string) => void;
  showTransparentBtn?: boolean;
  setColor: (color: string) => void;
  setHexColor?: (hexColor: string) => void;
  handleChangeColor: (color: string) => void;
}

const FillBackGround: React.FC<IFillBackGround> = ({
  enableColorEyeDrop,
  _handleEyeDrop,
  eyeDropColor,
  color,
  handleChange,
  hexColor,
  hexToRgba,
  showTransparentBtn,
  setColor,
  setHexColor,
  handleChangeColor,
}) => {
  const { t } = useTranslation();

  const handleColorChange = (rgbaColor: string) => {
    setColor(rgbaColor);
    handleChangeColor(rgbaColor);
  };

  useEffect(() => {
    if (eyeDropColor) {
      handleColorChange(eyeDropColor);
    }
  }, [eyeDropColor]);

  return (
    <>
      <EyeDropBtn active={enableColorEyeDrop} onClick={() => _handleEyeDrop()} />
      <RgbaStringColorPicker
        color={enableColorEyeDrop && eyeDropColor ? eyeDropColor : color}
        onChange={handleChange ? handleChange : handleColorChange}
      />
      <Hr />
      <Flex gap={4} wrapItems>
        {BG_COLORS.map((c) => (
          <Color
            key={c}
            active={color !== 'transparent' && hexColor === c}
            color={c}
            onClick={() => hexToRgba(c)}
          />
        ))}
        {showTransparentBtn && (
          <TransparentBtn
            active={color === 'transparent'}
            onClick={() => {
              setColor('transparent');
              setHexColor && setHexColor('transparent');
              handleChangeColor('transparent');
            }}
          >
            {t('editor.transparent')}
            <span className="icon-circle-slashed" />
          </TransparentBtn>
        )}
      </Flex>
    </>
  );
};

export default FillBackGround;

import React from 'react';
import { MarketPlaceType, TImageDetail } from '../../../interfaces/preview-image.interface';
import SkeletonLoader from '../../../components/skeleton-loader.component';
import { Flex, FlexCol } from '../../../components/flexbox.component';
import { LabelFromOzon, LabelsFromOzon, StyledIconHeart } from '../preview-image.style';
import styled from 'styled-components';
import WildberriesLoader from './wildberries-loader';
import YandexLoader from './yandex-loader';
import OzonLoader from './ozon-loader';
import NoPreview from '../components/no-preview';

const ImageListWrapper = styled.div<{ marketPlace: MarketPlaceType }>`
  margin: ${(p) =>
    (p.marketPlace === 'Wildberries' && '0 40px') ||
    (p.marketPlace === 'YandexMarket' && '0 182px') ||
    (p.marketPlace === 'Ozon' && '0 163px')};
  display: flex;
  flex-wrap: wrap;
  row-gap: ${(p) =>
    (p.marketPlace === 'Wildberries' && '38px') ||
    (p.marketPlace === 'YandexMarket' && '55px') ||
    (p.marketPlace === 'Ozon' && '38px') ||
    '20px'};
  column-gap: ${(p) =>
    (p.marketPlace === 'Wildberries' && '20px') ||
    (p.marketPlace === 'YandexMarket' && '16px') ||
    (p.marketPlace === 'Ozon' && '33px') ||
    '38px'};
  margin-top: 14px;
  align-items: center;
  justify-content: center;
  padding-bottom: 30px;
`;

const ImageListPage: React.FC<{
  imageDetails: TImageDetail[];
  imageSrc: { [key: number]: string };
  marketPlace: MarketPlaceType;
  loadingImages: boolean;
  canvasRatio: 'default' | 'square';
}> = ({ imageSrc, loadingImages, marketPlace, imageDetails, canvasRatio }) => {
  const renderSkeletons = (amount: number, noneAnimation?: boolean) =>
    Array.from({ length: amount }).map((_, index) => (
      <FlexCol key={index} gap={15}>
        {marketPlace === 'Wildberries' && <WildberriesLoader noneAnimation={noneAnimation} isSquared={isSquaredWB()} />}
        {marketPlace === 'YandexMarket' && <YandexLoader noneAnimation={noneAnimation} canvasRatio={canvasRatio} />}
        {marketPlace === 'Ozon' && <OzonLoader noneAnimation={noneAnimation} canvasRatio={canvasRatio} />}
      </FlexCol>
    ));

  const isSquaredWB = () => canvasRatio === 'square' && marketPlace === 'Wildberries';

  return !loadingImages ? (
    <ImageListWrapper marketPlace={marketPlace}>
      {Array.from({ length: 7 }).map((_, index) => (
        <>
          <FlexCol gap={15} key={index}>
            <Flex
              style={{
                width:
                  (marketPlace === 'Wildberries' && '225px') ||
                  (marketPlace === 'YandexMarket' && '217px') ||
                  (marketPlace === 'Ozon' && '213px') ||
                  '225px',
                height:
                  (marketPlace === 'Wildberries' && '300px') ||
                  (marketPlace === 'YandexMarket' && '217px') ||
                  (marketPlace === 'Ozon' && '213px') ||
                  '300px',
                borderRadius: '10px',
                position: 'relative',
                background: isSquaredWB() ? 'none' : '#F5F5F5',
              }}
              alignItems="center"
              justifyContent="center"
            >

              {
                isSquaredWB()
                  ? <NoPreview style={{ borderRadius: '10px' }} />
                  : <>
                    <img
                      src={imageSrc[imageDetails[0].modifiedImageId]}
                      style={{
                        width:
                          (marketPlace === 'Wildberries' && '100%') ||
                          (marketPlace === 'YandexMarket' &&
                            (canvasRatio === 'square' ? '200px' : '150px')) ||
                          (marketPlace === 'Ozon' &&
                            (canvasRatio === 'square' ? '213px' : '160px')) ||
                          '100%',
                        height:
                          (marketPlace === 'Wildberries' && '100%') ||
                          (marketPlace === 'YandexMarket' && '200px') ||
                          (marketPlace === 'Ozon' && '100%') ||
                          '100%',
                        borderRadius:
                          marketPlace === 'Wildberries' ||
                            (marketPlace === 'Ozon' && canvasRatio === 'square')
                            ? '10px'
                            : '0px',
                      }}
                    />
                    <LabelsFromOzon left={9.5} bottom={9.5} start gap={4}>
                      {marketPlace === 'YandexMarket' && (
                        <LabelFromOzon fontSize={12} paddingX={3} paddingY={2.5}>
                          Скидка
                        </LabelFromOzon>
                      )}
                    </LabelsFromOzon>

                    <LabelsFromOzon left={8} bottom={8} start gap={4}>
                      {marketPlace !== 'YandexMarket' && (
                        <>
                          <LabelFromOzon fontSize={12} paddingX={5} paddingY={2.5}>
                            ТУТ МОГУТ
                          </LabelFromOzon>
                          <LabelFromOzon fontSize={12} paddingX={5} paddingY={2.5}>
                            БЫТЬ ЛЕЙБЛЫ
                          </LabelFromOzon>
                        </>
                      )}
                    </LabelsFromOzon>

                    <StyledIconHeart top="8px" right="8px" />
                    {/* <StyledIconHeart bottom="8px" right="8px" /> */}
                  </>
              }

            </Flex>

            {marketPlace === 'Wildberries' && (
              <FlexCol gap={5}>
                <SkeletonLoader width="96px" height="20px" noneAnimation />
                <SkeletonLoader width="200px" height="20px" noneAnimation />
                <SkeletonLoader width="130px" height="20px" noneAnimation />
                <SkeletonLoader width="96px" height="20px" noneAnimation />
              </FlexCol>
            )}

            {marketPlace === 'YandexMarket' && (
              <FlexCol>
                <FlexCol gap={5} style={{ marginBottom: '13px' }}>
                  <SkeletonLoader noneAnimation={true} width="200px" height="20px" />
                  <SkeletonLoader noneAnimation={true} width="187px" height="20px" />
                  <SkeletonLoader noneAnimation={true} width="130px" height="20px" />
                </FlexCol>
                <FlexCol gap={5} style={{ marginBottom: '13px' }}>
                  <SkeletonLoader noneAnimation={true} width="96px" height="10px" />
                  <SkeletonLoader noneAnimation={true} width="200px" height="20px" />
                  <SkeletonLoader noneAnimation={true} width="138px" height="20px" />
                  <SkeletonLoader noneAnimation={true} width="96px" height="20px" />
                </FlexCol>
                <SkeletonLoader
                  shineColor="#00000033"
                  baseColor="#00000033"
                  width="213px"
                  height="40px"
                />
              </FlexCol>
            )}

            {marketPlace === 'Ozon' && (
              <>
                <FlexCol gap={5} style={{ marginBottom: '2px' }}>
                  <SkeletonLoader noneAnimation={true} width="200px" height="20px" />
                  <SkeletonLoader noneAnimation={true} width="138px" height="20px" />
                  <SkeletonLoader noneAnimation={true} width="200px" height="20px" />
                  <SkeletonLoader noneAnimation={true} width="96px" height="20px" />
                </FlexCol>
                <SkeletonLoader
                  baseColor="#00000033"
                  shineColor="#00000033"
                  width="213px"
                  height="40px"
                />
              </>
            )}

            {loadingImages && marketPlace === 'Wildberries' && <WildberriesLoader />}
            {loadingImages && marketPlace === 'YandexMarket' && <YandexLoader />}
            {loadingImages && marketPlace === 'Ozon' && <OzonLoader />}
          </FlexCol>
          {renderSkeletons(index % 2 === 0 ? 2 : 1, true)}
        </>
      ))}
    </ImageListWrapper>
  ) : (
    <ImageListWrapper marketPlace={marketPlace}>{renderSkeletons(14)}</ImageListWrapper>
  );
};

export default ImageListPage;

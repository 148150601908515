import React, { useEffect, useState } from 'react';
import { Thumbnail, ThumbnailRow } from '../toolbar.style';
import { Text } from '../../text.component';
import SkeletonLoader from '../../skeleton-loader.component';
import { ICreateFolderRequest, IImages } from '../../../interfaces/projects.interface';
import { TGetImageList } from '../../../interfaces/gallery.interface';
import useDebounce from '../../../hooks/use-debounce.hooks';
import useInfiniteScroll from '../../../hooks/use-infinite-scroll';
import CircleLoader2 from '../../circle-loader2';
import { useTranslation } from 'react-i18next';
import { getTooltipPosition } from '../../../utils/shared.utils';
import FabricTypes from 'fabric/fabric-impl';
import { useCanvasHistory } from '../../../contexts/canvas-history.context';
import { useShared } from '../../../contexts/shared.context';
import { SortType } from '../../../interfaces/editor.interface';
import { FabricJSEditor } from 'fabricjs-react';
import useMouseDown from '../../../hooks/use-mouse-down.hooks';
import CardCheckboxOverlay from '../../card.checkbox.overlay';
import ImageHighlight from '../image-highlight.component';

type TImageFilesTab = {
  editor: FabricJSEditor;
  bgImage: boolean | undefined;
  shapeImage: boolean | undefined;
  errorMsg: string;
  activeIndex: number;
  selectedImageId: number | undefined;
  setActiveIndex: (index: number) => void;
  setBgObject: (obj: FabricTypes.Object | undefined) => void;
  setImageId: (id: number) => void;
  setSelectedImageId: (id: number | undefined) => void;
  activeObject: FabricTypes.Object | undefined | null;
  handleLoadFullImageSelect: (imageId: number, index: number) => void;
  handleLoadFullImage: (image: IImages) => void;
  getImageList: ({ name, selectedOption, sortAsc, page }: TGetImageList) => void;
  loadingImage: boolean;
  imageList: IImages[];
  setImageList: (images: IImages[]) => void;
  imageSort: SortType;
  setErrorMsg: (msg: string) => void;
  navigateFolderTab: (folder: ICreateFolderRequest) => void;
};

const ImageFilesTab: React.FC<TImageFilesTab> = ({
  editor,
  bgImage,
  shapeImage,
  errorMsg,
  activeIndex,
  selectedImageId,
  setActiveIndex,
  setBgObject,
  setImageId,
  setSelectedImageId,
  activeObject,
  handleLoadFullImageSelect,
  handleLoadFullImage,
  getImageList,
  loadingImage,
  imageList,
  setImageList,
  imageSort,
  setErrorMsg,
  navigateFolderTab,
}) => {
  const { t } = useTranslation();
  const { historySaveAction } = useCanvasHistory();
  const { fileDropped } = useShared();
  const debouncedSearchValueFile = useDebounce(imageSort.search, 500);
  const imageInfiniteScroll = useInfiniteScroll(loadingImage, imageSort.hasMore, imageSort.setPage);

  const [highlight, setHighlight] = useState<{ show: boolean; imageId: number }>({
    show: false,
    imageId: 0,
  });
  const [highlightedFiles, setHighlightedFiles] = useState<number[]>([]);
  const [chosenImage, setChosenImage] = useState<IImages>();
  const [isHighlighting, setIsHighlighting] = useState<boolean>(false);
  const isRightMouseDown = useMouseDown();

  // Image in shape functions
  const handleClearImage = () => {
    if (activeObject) {
      const { canvas } = editor;
      setActiveIndex(-1);
      activeObject.set('fill', '#D9D9D9');
      canvas.renderAll();
      historySaveAction(editor);
      setSelectedImageId(undefined);
    }
  };

  // bg image functions
  const handleClearBgImage = () => {
    const { canvas } = editor;
    const bgImage = canvas._objects?.find((obj: FabricTypes.Object) =>
      obj.name?.includes('backgroundImage'),
    );

    if (bgImage) {
      canvas.remove(bgImage);
      setActiveIndex(-1);
      setBgObject(undefined);
      setImageId(0);
    }
  };

  useEffect(() => {
    if (imageSort.page !== 0)
      getImageList({
        name: imageSort.search,
        page: imageSort.page,
        selectedOption: imageSort.selectedOption.value,
        sortAsc: imageSort.sortAsc,
      });
  }, [imageSort.page]);

  useEffect(() => {
    imageSort.setPage(0);
    setImageList([]);
    getImageList({
      name: imageSort.search,
      page: 0,
      selectedOption: imageSort.selectedOption.value,
      sortAsc: imageSort.sortAsc,
    });
  }, [imageSort.selectedOption, imageSort.sortAsc, debouncedSearchValueFile]);

  useEffect(() => {
    if (fileDropped.message) {
      setErrorMsg(fileDropped.message);
    }
  }, [fileDropped.message]);

  // bg image
  useEffect(() => {
    if (editor && editor.canvas) {
      const backgroundImage = editor.canvas._objects?.find((obj: FabricTypes.Object) => {
        const image = bgImage && obj.name?.includes('backgroundImage_');

        if (image) {
          return obj;
        }
      });

      if (backgroundImage) {
        setBgObject(backgroundImage);
      }

      const backgroundImageId = bgImage
        ? backgroundImage?.name?.match(/\d+/g)?.join('')
        : activeObject?.globalCompositeOperation?.split('_')[2];

      if (backgroundImageId) {
        setImageId(Number(backgroundImageId));
        setSelectedImageId(Number(backgroundImageId));
      }
    }
  }, [selectedImageId, editor]);

  useEffect(() => {
    if (highlightedFiles.length > 0) setIsHighlighting(true);
  }, [highlightedFiles]);

  const NoImageThumbnail = (
    <Thumbnail
      active={!selectedImageId && activeIndex === -1}
      onClick={() => {
        if (bgImage) {
          handleClearBgImage();
          setSelectedImageId(undefined);
        } else if (shapeImage) {
          handleClearImage();
        }
      }}
      data-tooltip-position={'right'}
      data-tooltip={t('action.withoutImage')}
    >
      <span className="icon-image-off" />
    </Thumbnail>
  );

  return (
    <>
      <ThumbnailRow
        style={{ marginTop: '10px' }}
        onScroll={!loadingImage ? imageInfiniteScroll.handleScroll : () => null}
        isHighlighting={isHighlighting}
      >
        {errorMsg && (
          <Thumbnail type="error">
            <span className="icon-alert-circle" style={{ color: '#C72052' }} />
          </Thumbnail>
        )}
        {(bgImage || shapeImage) && NoImageThumbnail}
        {!imageList.length && debouncedSearchValueFile === '' && !loadingImage ? (
          <>
            <Text>{t('editor.imagesNotFound')}</Text>
            <Text small color={'lightBlack'}>
              {t('editor.availableSizeImage')}
              <br />
              {t('editor.availableTypeImage')}
            </Text>
          </>
        ) : (
          imageList.map((image, index) => (
            <Thumbnail
              key={image.imageId}
              active={
                ((activeIndex === index || image.imageId === selectedImageId) &&
                  (shapeImage || bgImage)) ||
                highlightedFiles.includes(image.id)
              }
              src={`/api/project/load/image/png?imageId=${image.imageId}&imageSizeType=ICON`}
              style={{ opacity: image.pending && activeIndex === index ? '0.4' : '' }}
              data-tooltip-wrapped={`${image.imageName}.${image.imageType}`}
              data-tooltip-position={getTooltipPosition(
                imageList.length,
                index + (bgImage || shapeImage ? 2 : 1),
              )}
              onMouseEnter={() => setHighlight({ show: true, imageId: image.id })}
              onMouseLeave={() => highlight.show && setHighlight({ show: true, imageId: 0 })}
            >
              {(highlight.imageId === image.id || highlightedFiles.includes(image.id)) && (
                <CardCheckboxOverlay
                  chosenImage={chosenImage}
                  image
                  imageData={image.id === highlightedFiles[0] ? image : undefined}
                  setChosenImage={setChosenImage}
                  cardId={image.id}
                  highlight={highlight.show}
                  highlightedCards={highlightedFiles}
                  setHighlightedCard={setHighlightedFiles}
                  isRightMouseDown={isRightMouseDown}
                  imageLoadFunction={() => {
                    if (!image.pending) {
                      shapeImage || bgImage
                        ? handleLoadFullImageSelect(image.imageId, index)
                        : !image.pending && handleLoadFullImage(image);
                    }
                  }}
                />
              )}
              {image.pending && <CircleLoader2 shineColor="#6620C7" />}
            </Thumbnail>
          ))
        )}
        {loadingImage &&
          Array.from({ length: 20 }).map((_, index) => (
            <SkeletonLoader width="70px" height="70px" key={index} />
          ))}
        {imageList.length === 0 && !loadingImage && debouncedSearchValueFile !== '' && (
          <Text style={{ color: '#00000066' }}>
            По вашему запросу ничего не найдено. Повторите попытку, набрав другие слова.
          </Text>
        )}
      </ThumbnailRow>
      {isHighlighting && (
        <ImageHighlight
          folderId={highlightedFiles.length === 1 ? chosenImage?.folderId : undefined}
          navigateFolderTab={navigateFolderTab}
          setIsHighlighting={setIsHighlighting}
          highlightedFiles={highlightedFiles}
          setHighlightedFiles={setHighlightedFiles}
          choiceList={imageList}
          refreshRequest={() => {
            imageSort.setPage(0);
            setImageList([]);
            getImageList({
              name: imageSort.search,
              page: 0,
              selectedOption: imageSort.selectedOption.value,
              sortAsc: imageSort.sortAsc,
            });
          }}
        />
      )}
    </>
  );
};

export default ImageFilesTab;

import React, { useState } from 'react';
import { Modal } from '../modal.component';
import { Text } from '../text.component';
import { Button } from '../button.component';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { purchaseRate } from '../../requests/tariff.request';
import { useUserContext } from '../../contexts/user.context';
import { RateModalContent, RateModalHeader } from './rates.style';
import { PurchaseRateTypes } from '../../constants/rates.constant';
import { useShared } from '../../contexts/shared.context';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  isLimitExceeded: boolean;
  rate: any;
  currentRate: any;
  isShorterPeriod: boolean;
}

export const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  .df {
    display: flex;
    gap: 0.625rem;
  }
`;

const ChangeRateModal: React.FC<IProps> = ({
  isOpen,
  onClose,
  isLimitExceeded,
  rate,
  currentRate,
  isShorterPeriod,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { updateUserDetails } = useUserContext();
  const { setIsSupportOpen } = useShared();
  const [subscribeState, setSubscribeState] = useState<{ loading: boolean; type: string }>({
    loading: false,
    type: '',
  });

  const subscribe = (type: string) => {
    if (subscribeState.loading) return;
    setSubscribeState({ loading: true, type });

    const rateType = PurchaseRateTypes[type.toUpperCase() as keyof typeof PurchaseRateTypes];
    purchaseRate(rate.id, rateType).then(() => {
      setSubscribeState({ loading: false, type: '' });
      updateUserDetails();
    });
  };

  const handleOpenSupport = () => {
    onClose();
    setIsSupportOpen(true);
  };

  const getType = () => {
    if (isShorterPeriod) {
      return 'shorter';
    } else if (isLimitExceeded) {
      return 'limit-exceeded';
    } else {
      return 'default';
    }
  };

  const modalContent = {
    'limit-exceeded': {
      header: t('rates.delete-projects'),
      body: (
        <>
          <Text>{t('rates.message-1', { rate: t(`rates.${rate?.name}`) })}</Text>
          <Text>
            {t('rates.message-2', {
              current: currentRate?.existingProjectCount,
              targett: rate?.projectLimit,
            })}
          </Text>
        </>
      ),
      buttons: (
        <ButtonsWrapper>
          <Button
            type="button"
            btnStyle="cancel"
            onClick={onClose}
            style={{ paddingLeft: 0, paddingRight: 0 }}
          >
            {t('rates.decide-later')}
          </Button>

          <Button type="button" onClick={() => navigate('/my-projects')}>
            {t('rates.to-project')}
          </Button>
        </ButtonsWrapper>
      ),
    },
    default: {
      header: t('rates.you-want'),
      body: (
        <ul className="list">
          <li>
            <Text>{t('rates.option-1')}</Text>
          </li>
          <li>
            <Text>{t('rates.option-2')}</Text>
          </li>
        </ul>
      ),
      buttons: (
        <ButtonsWrapper>
          <Button
            type="button"
            btnStyle="cancel"
            onClick={onClose}
            style={{ paddingLeft: 0, paddingRight: 0 }}
          >
            {t('action.cancel')}
          </Button>
          <div className="df">
            <Button
              type="button"
              onClick={() => subscribe('now')}
              disabled={subscribeState.loading}
              isLoading={subscribeState.loading && subscribeState.type === 'now'}
            >
              {t('rates.start-now')}
            </Button>
            <Button
              type="button"
              onClick={() => subscribe('later')}
              disabled={subscribeState.loading}
              isLoading={subscribeState.loading && subscribeState.type === 'later'}
            >
              {t('rates.after-current')}
            </Button>
          </div>
        </ButtonsWrapper>
      ),
    },
    shorter: {
      header: t('rates.want-shorter'),
      body: <Text>{t('rates.contact-us')}</Text>,
      buttons: (
        <ButtonsWrapper>
          <Button
            type="button"
            btnStyle="cancel"
            onClick={onClose}
            style={{ paddingLeft: 0, paddingRight: 0 }}
          >
            {t('action.cancel')}
          </Button>
          <Button type="button" onClick={handleOpenSupport}>
            {t('rates.contact-support')}
          </Button>
        </ButtonsWrapper>
      ),
    },
  };

  return (
    <>
      {isOpen && (
        <Modal maxHeight="unset" onClose={onClose} width="33.75rem">
          <RateModalContent style={{ minWidth: 'unset' }}>
            <RateModalHeader>
              <Text fontSize="1.4375rem" strong>
                {modalContent[getType()].header}
              </Text>
            </RateModalHeader>

            {modalContent[getType()].body}

            {modalContent[getType()].buttons}
          </RateModalContent>
        </Modal>
      )}
    </>
  );
};

export default ChangeRateModal;

import { ACCESS_TOKEN, REFRESH_TOKEN } from "../interfaces/auth.interface";

const setCookie = (name: string, value: string, days: number) => {
  const date = new Date();
  date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
  const expires = `expires=${date.toUTCString()}`;
  document.cookie = `${name}=${value};${expires};path=/`;
}

export const setTokens = (accessToken: string, refreshToken: string) => {
  accessToken && localStorage.setItem(ACCESS_TOKEN, accessToken);
  refreshToken && localStorage.setItem(REFRESH_TOKEN, refreshToken);
  setCookie('_auth_token', accessToken, 1);
};

export const removeTokens = () => {
  localStorage.removeItem(ACCESS_TOKEN);
  localStorage.removeItem(REFRESH_TOKEN);
};

export const getAccessToken = (): string => {
  return localStorage.getItem(ACCESS_TOKEN) || '';
};

export const getRefreshToken = (): string => {
  return localStorage.getItem(REFRESH_TOKEN) || '';
};

import React, { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { useAuth } from '../contexts/auth.context';
import { AuthGuard } from '../pages/auth-guard';
import { Navigation, NavigationLink } from '../components/navigation.component';
import { RoundButton } from '../components/round-btn.component';
import { Button } from '../components/button.component';
import logo from '../assets/images/logo.svg';
import Search from '../components/search.component';
import Popover from '../components/popover.component';
import { FlexCol } from '../components/flexbox.component';
import NewProjectModal from '../components/new-project-modal.component.';
import ExpireNotification from '../components/expire-notification.component';
import { RateNames } from '../constants/rates.constant';
import { useUserContext } from '../contexts/user.context';
import RateModal from '../components/rates/rate-modal.component';

const Header = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1.125rem 1.25rem 0.75rem;
  gap: 20px;
  position: sticky;
  top: 0;
  z-index: 1002;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.06), rgba(0, 0, 0, 0.06)),
    rgba(255, 255, 255, 0.95);
`;

const HeaderItem = styled.div`
  display: flex;
  position: relative;
  & > img {
    position: absolute;
    top: -0.4375rem;
    left: 0;
    height: 3.75rem;
    filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.25));
  }
`;

const OutletWrapper = styled.div`
  padding: 1.25rem;
`;

const AdaptiveDropdownMenuList = styled(Navigation)`
  padding: 0;
  margin-right: 20px;
`;

const COUNT_TABS = 2;

export const BasicLayout = () => {
  const { t } = useTranslation();
  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { userData } = useUserContext();

  // const routes = ['my-projects', 'templates', 'rates', 'dictionary'];
  const routes = ['my-projects', 'rates'];

  const [searchQuery, setSearchQuery] = useState<string>('');
  const [prevPathname, setPrevPathname] = useState<string>(location.pathname);
  const [didMount, setDidMount] = useState(false);
  const [, setSearchParams] = useSearchParams();
  const [initials, setInitials] = useState<string>('');
  const [popoverActive, setPopoverActive] = useState<boolean>(false);
  const [visibleTabs, setVisibleTabs] = useState<number>(COUNT_TABS);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isRateOpen, setIsRateOpen] = useState<boolean>(false);
  const [isExpireOpen, setIsExpireOpen] = useState(true);
  const [rate, setRate] = useState<any>();
  const [days, setDays] = useState<number>(365);
  const [showReminder, setShowReminder] = useState<boolean>(false);

  const width = window.innerWidth;

  useEffect(() => {
    // if (width < 1270 && width > 1200) {
    //   setVisibleTabs(3);
    // } else if (width < 1200 && width > 1100) {
    //   setVisibleTabs(2);
    // } else

    if (width < 1100 && width > 1000) {
      setVisibleTabs(1);
    } else if (width < 1000) {
      setVisibleTabs(0);
    } else {
      setVisibleTabs(2);
    }
  }, [width]);

  useEffect(() => {
    setPrevPathname(prevPathname);
    if (didMount && prevPathname !== '/') {
      setSearchParams({});
      setSearchQuery('');
    }
  }, [didMount]);

  useEffect(() => {
    if (searchQuery) {
      setSearchParams({ q: searchQuery as string });
    } else if (didMount) {
      setSearchParams({});
    }
  }, [searchQuery, setSearchParams]);

  useEffect(() => setDidMount(true), []);

  useEffect(() => {
    if (userData) {
      getShowReminder();
      const { name, rate } = userData;
      const firstName = name?.split(' ')[1];
      const surname = name?.split(' ')[0];
      const initials = `${surname ? surname?.charAt(0) : ''}${
        firstName ? firstName?.charAt(0) : ''
      }`.toUpperCase();
      setInitials(initials);

      setRate(rate);
      const today = new Date().getTime();
      const endDate = new Date(rate.expireDate).getTime();
      const difference = Math.floor((endDate - today) / (1000 * 60 * 60 * 24)) + 1;
      setDays(rate.name === RateNames.TRIAL ? Math.min(+difference, 3) : +difference);
    }
  }, [userData]);

  useEffect(() => {
    getShowReminder();
  }, [rate]);

  const handleLogOut = () => {
    // auth.user.userName
    auth.signOut(() => navigate('/'));
  };

  const handleNavigateToProfile = () => {
    navigate('/profile');
  };

  const getShowReminder = () => {
    if (rate) {
      const initial =
        (rate?.name === RateNames.TRIAL && rate?.availableProjectCount === 0 && days >= 1) ||
        (rate?.name !== RateNames.TRIAL && days <= 7 && days >= 1);

      if (rate?.name === RateNames.TRIAL && rate?.existingProjectCount === 0 && days >= 1) {
        setShowReminder(false);
      } else {
        setShowReminder(initial);
      }
    }
  };

  const getBtnStyle = () => {
    if (
      rate &&
      ((rate.name === RateNames.TRIAL && days <= 1) || (rate.name !== RateNames.TRIAL && days <= 3))
    ) {
      return 'strong-red';
    }

    return 'red';
  };

  const handleAddProject = () => {
    if (userData) {
      const { rate } = userData;
      if (
        (rate.availableProjectCount !== null && rate.availableProjectCount <= 0) ||
        (rate.projectLimit !== null && rate.existingProjectCount >= rate.projectLimit)
      ) {
        setIsRateOpen(true);
      } else {
        setIsOpen(true);
      }
    }
  };

  const headerOptions = (from: number, to: number) => {
    return (
      <FlexCol gap={6} alignItems={'flex-start'} style={{ width: 'max-content' }}>
        {routes.slice(from, to).map((route) => (
          <NavigationLink
            headerNav
            key={route}
            to={route}
            className={location.pathname.includes(route) ? 'active' : ''}
          >
            {t(`routes.${route}`)}
          </NavigationLink>
        ))}
      </FlexCol>
    );
  };

  return (
    <AuthGuard>
      <>
        <Header>
          <HeaderItem>
            <img src={logo} alt={'mark it'} />
            <Navigation>
              {routes.slice(0, visibleTabs).map((route) => (
                <NavigationLink
                  key={route}
                  to={route}
                  className={location.pathname.includes(route) ? 'active' : ''}
                  style={{ whiteSpace: 'nowrap' }}
                >
                  {t(`routes.${route}`)}
                </NavigationLink>
              ))}
              {visibleTabs !== COUNT_TABS && (
                <AdaptiveDropdownMenuList>
                  <Popover
                    content={headerOptions(visibleTabs, COUNT_TABS)}
                    setIsActive={setPopoverActive}
                  >
                    <RoundButton
                      icon={'more'}
                      title={'Действие'}
                      className={popoverActive ? 'active' : ''}
                    />
                  </Popover>
                </AdaptiveDropdownMenuList>
              )}
            </Navigation>
          </HeaderItem>

          <HeaderItem
            style={{ gap: '10px', display: 'flex', justifyContent: 'end', width: '100%' }}
          >
            {!location.pathname.includes('profile') && (
              <div
                style={{ height: '50px', marginRight: '10px', width: '100%', minWidth: '204px' }}
              >
                <Search value={searchQuery} setSearchValue={setSearchQuery} />
              </div>
            )}

            {showReminder && (
              <div style={{ position: 'relative' }}>
                <RoundButton
                  icon={'light'}
                  onClick={() => setIsExpireOpen(!isExpireOpen)}
                  btnStyle={getBtnStyle()}
                />

                {rate && isExpireOpen && (
                  <ExpireNotification
                    rate={rate}
                    days={days}
                    onClose={() => {
                      setIsExpireOpen(false);
                    }}
                  />
                )}
              </div>
            )}

            <RoundButton
              isActive={location.pathname.includes('profile')}
              title={t('main.profile') as string}
              onClick={handleNavigateToProfile}
            >
              {initials}
            </RoundButton>

            <RoundButton
              icon={'log-out'}
              title={t('main.logOut') as string}
              onClick={handleLogOut}
            />
            <Button icon={'plus'} onClick={handleAddProject} style={{ whiteSpace: 'nowrap' }}>
              {t('action.createProject')}
            </Button>
          </HeaderItem>
        </Header>

        <NewProjectModal
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          trackKey="ecom_webapp_create_project_from_layout"
          setIsOpen={setIsOpen}
        />

        <RateModal isOpen={isRateOpen} onClose={() => setIsRateOpen(false)} />
        <OutletWrapper>
          <Outlet />
        </OutletWrapper>
      </>
    </AuthGuard>
  );
};

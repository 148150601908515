import React, {
  useState,
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useCallback,
} from 'react';
import styled, { css, keyframes } from 'styled-components';
import checkeredBackground from '../assets/images/checkered-background.svg';
import { useShared } from '../contexts/shared.context';

type IPopoverPosition =
  | 'top'
  | 'bottom'
  | 'right'
  | 'left'
  | 'left-bottom'
  | 'right-bottom'
  | 'bottom-right';
type IPopoverType = 'default' | 'cloud';

export const PopoverWrapper = styled.div<{ relativeToParent?: boolean }>`
  ${(p) =>
    !p.relativeToParent &&
    css`
      position: relative;
    `}
`;

const fade = keyframes`
  0% {
    opacity: 0;
    display: none;
  }
  100% {
    opacity: 1;
    display: block;
  }
`;

const margin = 10;

export const PopoverContent = styled.div<{
  isOpen?: boolean;
  position: IPopoverPosition;
  type: IPopoverType;
  paddingLess: boolean;
}>`
  display: ${(p) => (p.isOpen ? 'block' : 'none')};
  position: absolute;
  top: calc(100% + ${margin}px);
  left: 0;
  background: transparent;
  z-index: 1;
  animation: ${fade} 0.25s linear;

  ${(p) =>
    p.type === 'cloud' &&
    css`
      background: ${p.theme.white};
      border-radius: 1rem;
      padding: ${p.paddingLess ? 0 : '1.5rem'};
      box-shadow: rgba(0, 0, 0, 0.15) 0 10px 36px 0;
    `}

  ${(p) =>
    p.position === 'top' &&
    css`
      top: auto;
      bottom: calc(100% + ${margin}px);
      left: 0;
    `}

  ${(p) =>
    p.position === 'right' &&
    css`
      top: 0;
      left: calc(100% + ${margin}px);
    `}

  ${(p) =>
    p.position === 'left' &&
    css`
      top: 0;
      left: auto;
      right: calc(100% + ${margin}px);
    `}

  ${(p) =>
    p.position === 'left-bottom' &&
    css`
      top: auto;
      bottom: 0;
      left: auto;
      right: calc(100% + ${margin}px);
    `}

  ${(p) =>
    p.position === 'right-bottom' &&
    css`
      top: auto;
      bottom: 0;
      right: auto;
      left: calc(100% + ${margin}px);
    `}

  ${(p) =>
    p.position === 'bottom-right' &&
    css`
      right: 0;
      left: auto;
    `}

    .react-colorful__alpha {
    background-image: url(${checkeredBackground});
  }
`;

interface IProps {
  children: React.ReactNode;
  content?: React.ReactNode;
  contentClassName?: string;
  setIsActive?: (value: boolean) => void;
  position?: IPopoverPosition;
  type?: IPopoverType;
  closeOnClick?: boolean;
  className?: string;
  onOpen?: () => void;
  preloadContent?: boolean;
  paddingLess?: boolean;
  disableOutsideClick?: boolean;
  relativeToParent?: boolean;
}

const Popover = forwardRef(function Popover(
  {
    children,
    content,
    contentClassName,
    setIsActive,
    position = 'bottom',
    type = 'default',
    closeOnClick = true,
    className,
    onOpen,
    preloadContent = false,
    paddingLess = false,
    disableOutsideClick = false,
    relativeToParent = false,
  }: IProps,
  ref,
) {
  const [isOpen, setIsOpen] = useState<boolean>();
  const [popoverPosition, setPopoverPosition] = useState<IPopoverPosition>(position);
  const wrapperRef = useRef<any>(null);
  const { recentTemplateIds, clearRecentTemplateIds, fileDropped } = useShared();

  const callBackRef = useCallback(
    (domNode: HTMLDivElement) => {
      if (domNode && content && isOpen) {
        setTimeout(() => {
          const dimensions = domNode.getBoundingClientRect();
          const wrapper = wrapperRef.current?.getBoundingClientRect();
          if (dimensions && wrapper && Object.values(wrapper).some((val: any) => +val !== 0)) {
            const { width } = dimensions;
            if (['right', 'right-bottom'].includes(position)) {
              if (wrapper.right + margin + width > window.innerWidth) {
                const newPosition = position === 'right' ? 'left' : 'left-bottom';
                setPopoverPosition(newPosition);
              }
              return;
            }
            if (['left', 'left-bottom'].includes(position)) {
              if (wrapper.left - margin - width < 0) {
                const newPosition = position === 'left' ? 'right' : 'right-bottom';
                setPopoverPosition(newPosition);
              }
              return;
            }

            setPopoverPosition(position);
          }
        }, 10);
      }
    },
    [content, isOpen, wrapperRef],
  );

  useImperativeHandle(
    ref,
    () => {
      return {
        close() {
          setIsOpen(false);
          clearRecentTemplateIds();
        },
      };
    },
    [],
  );

  const modalOverlayCheck = (element: HTMLElement | null, id: string): boolean => {
    if (!element) return false;

    if (element.id === id) return true;

    if (element.tagName.toLowerCase() === 'button' && element.id !== 'modal-close') return false;

    return modalOverlayCheck(element.parentElement, id);
  };

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      const isNotification =
        (event.target as HTMLElement)?.parentElement?.id === 'notification' ||
        (event.target as HTMLElement)?.id === 'notification';

      const isModalOverlay = modalOverlayCheck(event.target as HTMLElement, 'modal-overlay');

      if (
        !disableOutsideClick &&
        isOpen &&
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node) &&
        !isNotification &&
        !isModalOverlay
      ) {
        setIsOpen(false);
        clearRecentTemplateIds();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [disableOutsideClick, isOpen, wrapperRef]);

  useEffect(() => {
    if (isOpen !== undefined) {
      setIsActive && setIsActive(isOpen);
      if (isOpen && onOpen) {
        onOpen();
      }
    }
  }, [isOpen]);

  const handleClick = (event: any) => {
    event.stopPropagation();
    if (closeOnClick) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (
      (recentTemplateIds.ids.length &&
        (content as { key: string })?.key === recentTemplateIds.type) ||
      ((content as { key: string })?.key === 'image' && fileDropped.message !== '')
    ) {
      setIsOpen(true);
    }
  }, [recentTemplateIds, fileDropped]);

  return (
    <PopoverWrapper
      ref={wrapperRef}
      className={className}
      relativeToParent={isOpen && relativeToParent}
    >
      <div
        onClick={(event) => {
          setIsOpen(!isOpen);
          event.stopPropagation();
        }}
      >
        {children}
      </div>
      {content && (preloadContent || (!preloadContent && isOpen)) && (
        <PopoverContent
          ref={callBackRef}
          isOpen={isOpen}
          position={popoverPosition}
          type={type}
          onClick={(event) => handleClick(event)}
          paddingLess={paddingLess}
          className={contentClassName}
        >
          {content}
        </PopoverContent>
      )}
    </PopoverWrapper>
  );
});

export default Popover;

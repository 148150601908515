import React, { useEffect, useState, useRef } from 'react';
import SwiperCore, { Swiper as SwiperType } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, A11y } from 'swiper/modules';
import { Swiper as SwiperClass } from 'swiper/types';

import { TProductPageMobile } from '../../../interfaces/preview-image.interface';
import ImageIcons from '../components/image-icons';
import { SimilarButton, WildBerriesMobileWrapper } from '../preview-image.style';
import 'swiper/css';
import styled from 'styled-components';
import { Flex } from '../../../components/flexbox.component';
import { useTranslation } from 'react-i18next';
import WBView from '../../../assets/images/preview/WB-mobile.png';
import NoPreview from '../components/no-preview';

SwiperCore.use([Navigation, Pagination, A11y]);

const ImageMobileSlider = styled.img`
  height: 402px;
  width: 302px;
`;

const WildberriesMobile: React.FC<TProductPageMobile> = ({
  imagesSrc,
  marketPlace,
  imageDetails,
  canvasRatio
}) => {
  const [activeSlide, setActiveSlide] = useState(0);
  const totalSlides = Object.values(imagesSrc).length;
  const swiperRef = useRef<{ swiper: SwiperType } | null>(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (totalSlides === 1) {
      setActiveSlide(0);
    }
  }, [totalSlides]);

  const handleSlideChange = (swiper: SwiperClass) => {
    setActiveSlide(swiper.activeIndex);
  };

  const isSquaredWB = () => canvasRatio === 'square' && marketPlace === 'Wildberries';

  return (
    <Flex style={{ marginTop: 165 }} gap={85} justifyContent="center" alignItems="center">
      {!isSquaredWB() && totalSlides > 1 && (
        <div
          className="icon-arrow-left"
          style={{
            fontSize: '20px',
            cursor: 'pointer',
            opacity: activeSlide === 0 ? 0.2 : 1,
          }}
          onClick={() => swiperRef.current?.swiper?.slidePrev()}
        ></div>
      )}
      <WildBerriesMobileWrapper style={{ background: 'none' }}>
        {
          isSquaredWB()
            ? <NoPreview style={{
              width: '320px',
              height: '402px',
            }} />
            : <>
              <Swiper
                ref={swiperRef}
                className={'swiper'}
                slidesPerView={1}
                spaceBetween={0}
                onSlideChange={handleSlideChange}
              >
                {imageDetails.map((image, index) => (
                  <SwiperSlide className={'swiper-slide'} key={index}>
                    <ImageMobileSlider src={imagesSrc[image.modifiedImageId]} alt={`image_${index}`} />
                  </SwiperSlide>
                ))}
              </Swiper>
              <ImageIcons />
              <SimilarButton
                mobile
                marketPlace={marketPlace}
                data-tooltip={t(`preview-image.similar`) as string}
                data-tooltip-position="left"
              >
                <img src={WBView} width={78} height={30} />
              </SimilarButton>
            </>
        }

      </WildBerriesMobileWrapper>
      {!isSquaredWB() && totalSlides > 1 && (
        <div
          className="icon-arrow-right"
          style={{
            fontSize: '20px',
            opacity: activeSlide === totalSlides - 1 ? 0.2 : 1,
            cursor: 'pointer',
          }}
          onClick={() => swiperRef.current?.swiper?.slideNext()}
        ></div>
      )}
    </Flex>
  );
};

export default WildberriesMobile;

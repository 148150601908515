import React from 'react';
import styled from 'styled-components';
import FabricTypes from 'fabric/fabric-impl';

import { useCanvasHistory } from '../../contexts/canvas-history.context';
import { Row, Col } from '../grid.component';
import { SHAPES, SHAPE_NAMES } from '../../constants';
import {
  handleAddImageInShape,
  handleGetShape,
  handleShapeScaling,
} from '../../utils/editor.utils';
import { IBasicEditorProps } from '../../interfaces/editor.interface';

const ShapesPopoverWrapper = styled.div`
  width: 20.75rem;
  & svg {
    cursor: pointer;
    &:hover {
      & * {
        fill: ${(p) => p.theme.lightPrimary};
        fill-opacity: 1;
      }
    }
  }
`;

interface IProps extends IBasicEditorProps {
  addToCanvas?: (shape: any) => void;
}

export const ShapesPopover = ({ addToCanvas, editor, activeObject }: IProps) => {
  const { historySaveAction } = useCanvasHistory();

  const getShapeParams = ({
    left,
    top,
    width,
    height,
    scaleY,
    scaleX,
    fill,
    stroke,
    strokeWidth,
    angle,
  }: FabricTypes.Object) => {
    return { left, top, width, height, scaleY, scaleX, fill, stroke, strokeWidth, angle };
  };

  const handleReplaceImageInShape = (shape: FabricTypes.Object) => {
    if (activeObject?.globalCompositeOperation) {
      const { canvas } = editor;
      const [, customId, imageId] = activeObject.globalCompositeOperation.split('_');
      const imageObject = canvas
        .getObjects()
        .find(
          (obj: FabricTypes.Object) => obj.name === `${activeObject.name}_${customId}_${imageId}`,
        );
      if (imageObject) {
        imageObject.clone((clonedImageObject: FabricTypes.Image) => {
          clonedImageObject.set({ name: `${shape.name}_${customId}_${imageId}` });
          canvas.add(clonedImageObject);
          shape.off('modified');
          shape.on('modified', handleShapeScaling(editor.canvas, shape));
          imageObject.clone((visibleClonedImageObject: FabricTypes.Image) => {
            visibleClonedImageObject.set({ visible: true });
            handleAddImageInShape(editor.canvas, visibleClonedImageObject).then(() => {
              historySaveAction(editor);
            });
          });
        });
      }
    }
  };

  const handleAddShape = (key: (typeof SHAPE_NAMES)[number]) => () => {
    const { canvas } = editor;
    const shape = handleGetShape(editor.canvas, key);
    if (shape) {
      // const textBox = new fabric.Textbox('', {
      //   originX: 'center',
      //   originY: 'center',
      //   left: shape.left,
      //   top: shape.top,
      //   fill: '#000000',
      //   fontSize: 16,
      //   fontWeight: 500,
      //   fontFamily: 'Inter',
      //   textAlign: 'center',
      //   strokeWidth: 0,
      //   globalCompositeOperation: JSON.stringify({}),
      // });
      // const shapeGroup = new fabric.Group([shape, textBox], {
      //   name: shape.type,
      //   subTargetCheck: true,
      //   originX: 'left',
      //   originY: 'top',
      //   left: 30,
      //   top: 60,
      // });
      if (shape.type !== 'line') {
        // shapeGroup.off('mousedblclick');
        // shapeGroup.on('mousedblclick', handleShapeTextEdit(canvas, shapeGroup));
        shape.off('modified');
        shape.on('modified', handleShapeScaling(canvas, shape));
      }

      if (addToCanvas) {
        shape.set({ stroke: 'rgba(0, 0, 0, 1)' });
        addToCanvas(shape);
      } else if (activeObject) {
        // const activeGroup = activeObject as FabricTypes.Group;
        const globalCompositeOperation = activeObject.get('globalCompositeOperation');
        const shapeParams = getShapeParams(activeObject);
        const previousWasLine = activeObject.type === 'line';
        const defaultParams = {
          ...shapeParams,
          fill: previousWasLine ? shapeParams.stroke : shapeParams.fill,
          width: shape.width,
          height: shape.height,
          scaleY: shape.scaleY,
          scaleX: shape.scaleX,
        };
        switch (shape.type) {
          case 'line': {
            shape.set({ stroke: shapeParams.fill as string });
            break;
          }
          case 'ellipse': {
            (shape as FabricTypes.Ellipse).set({
              ...defaultParams,
              rx: (defaultParams.width as number) / 2,
              ry: (defaultParams.height as number) / 2,
            });
            break;
          }
          default: {
            shape.set(defaultParams);
            break;
          }
        }
        // shapeGroup._objects[0] = shape;
        // shapeGroup._objects[1] = activeGroup.item(1);
        // shapeGroup.item(1).set({
        //   left: (shape.left as number) + (shape.width as number) / 2,
        //   top: (shape.top as number) + (shape.height as number) / 2,
        // });
        // shapeGroup.addWithUpdate();
        shape.set({
          paintFirst: activeObject.paintFirst,
          left: activeObject.left,
          top: activeObject.top,
          originX: activeObject.originX,
          originY: activeObject.originY,
          angle: activeObject.angle,
        });

        handleReplaceImageInShape(shape);
        shape.set('globalCompositeOperation', globalCompositeOperation);
        canvas.remove(activeObject);
        canvas.discardActiveObject();
        canvas.add(shape);
        canvas.setActiveObject(shape);

        if (!activeObject?.globalCompositeOperation) {
          historySaveAction(editor);
        }
      }
    }
  };

  return (
    <ShapesPopoverWrapper>
      <Row gutter={16} alignItems="center">
        {SHAPES.map((shape) => (
          <Col key={shape.key} span={6} onClick={handleAddShape(shape.key)}>
            {shape.icon}
          </Col>
        ))}
      </Row>
    </ShapesPopoverWrapper>
  );
};

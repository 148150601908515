import React, { InputHTMLAttributes, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useCanvasFocus } from '../contexts/canvas-focus.context';
import { useShared } from '../contexts/shared.context';

const Line = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  max-width: 100%;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  color: transparent;
  height: 2px;
  background-color: ${(p) => p.theme.lightPrimary};
  border-radius: 1px;
  opacity: 0;
  transition: opacity ease-in-out 150ms;
  overflow: hidden;
`;

const InputWrapper = styled.div`
  position: relative;
`;

const Input = styled.input<{ fontSize?: string }>`
  position: relative;
  padding: 0;
  border: 0;
  background: transparent;
  color: ${(p) => p.theme.black};
  font-weight: 500;
  font-size: ${(p) => (p.fontSize ? p.fontSize : '1rem')};
  line-height: 1.375em;
  -moz-appearance: textfield;
  width: 100%;
  &:hover,
  &:focus {
    outline: none;
    & + ${Line} {
      opacity: 1;
    }
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  text-align: center;
`;

interface IProps extends InputHTMLAttributes<any> {
  size?: number;
  fontSize?: string;
}

export const NumberInput = ({ size = 3, fontSize = '1rem', ...rest }: IProps) => {
  const [width, setWidth] = useState('auto');
  const measureCanvasRef = useRef<HTMLCanvasElement | null>(null);
  const { setIsFocused } = useCanvasFocus();
  const { popoverActive } = useShared();

  useEffect(() => {
    if (popoverActive.text || popoverActive.color) {
      setIsFocused(false);
    }
  }, [popoverActive.text, popoverActive.color]);

  const getStringWidth = (text: string) => {
    const canvas = measureCanvasRef.current;
    const context = canvas?.getContext('2d');
    if (context) {
      context.font = `${fontSize} "Manrope", sans-serif`;
      setWidth(`${context.measureText(text).width}px`);
    }
  };

  useEffect(() => {
    if (rest.value || rest.value === 0) {
      getStringWidth(rest.value as string);
    }
  }, [rest.value]);

  return (
    <InputWrapper>
      <Input {...rest} size={size} fontSize={fontSize} style={{ width }} />
      <Line>{rest.value}</Line>
      <canvas ref={measureCanvasRef} style={{ display: 'none' }}></canvas>
    </InputWrapper>
  );
};

import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

interface LinkProps {
  link: string;
  text: string;
  color?: string;
}

const LinkWrapper = styled(Link)`
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  text-decoration: none;
  color: ${(p) => p.color || p.theme.primary};
  &:hover {
    color: ${(p) => p.theme.primary};
  }
`;

export const StyledLink = ({ link, text, color }: LinkProps) => {
  return (
    <LinkWrapper to={link} color={color}>
      {text}
    </LinkWrapper>
  );
};

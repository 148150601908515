import React from 'react';

const ImageIcons: React.FC = () => {
  return (
    <div
      style={{
        position: 'absolute',
        top: '0',
        left: '0',
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        zIndex: 100,
        padding: '15px 12px',
        alignItems: 'center',
      }}
    >
      <div style={{ transform: 'rotate(270deg)', cursor: 'pointer' }}>
        <span
          className="icon-chevron-up"
          style={{ color: 'white', fontSize: '25px', fontWeight: '300' }}
        ></span>
      </div>
      <div style={{ display: 'flex', gap: '20px', cursor: 'pointer' }}>
        <span className="icon-heart" style={{ color: 'white', fontSize: '20px' }}></span>
        <span className="icon-upload" style={{ color: 'white', fontSize: '20px' }}></span>
      </div>
    </div>
  );
};

export default ImageIcons;

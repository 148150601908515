import React, { useState, useRef, useEffect, forwardRef, useImperativeHandle } from 'react';
import styled, { css, keyframes } from 'styled-components';

type IActionPopoverPosition = 'right' | 'left';
interface IActionPopover {
  icon: string;
  callback: () => void;
  text?: string;
  active?: boolean;
  hide?: boolean;
}

const PopoverWrapper = styled.div`
  position: relative;
`;

const fade = keyframes`
  0% { opacity: 0 }
  100% { opacity: 1 }
`;

interface IPopoverContentProps {
  position: IActionPopoverPosition;
  trigger: 'click' | 'hover';
  self: DOMRect;
  parent: DOMRect;
}

const margin = 0.625;

export const PopoverContent = styled.div<IPopoverContentProps>`
  display: flex;
  position: absolute;
  top: 0;
  left: calc(100% + ${margin}rem);
  background: transparent;
  z-index: 1;
  ${(p) =>
    p.position === 'left' &&
    css`
      top: 0;
      left: auto;
      right: calc(100% + ${margin}rem);
    `};
  ${({ trigger, position, self, parent: { top, left, height, width } }) =>
    trigger === 'hover' &&
    position === 'right' &&
    css`
      position: fixed;
      top: ${top + height / 2 - self?.height / 2}px;
      left: ${left + width}px;
      right: auto;
      padding-left: 0.625rem;
    `};
  ${({ trigger, position, self, parent: { top, left, height } }) =>
    trigger === 'hover' &&
    position === 'left' &&
    css`
      position: fixed;
      top: ${top + height / 2 - self?.height / 2}px;
      left: ${left - self?.width}px;
      right: auto;
      padding-right: 0.625rem;
    `};
`;

const PopoverBtnText = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.25em;
  color: ${(p) => p.theme.black};
  margin-left: 0.1875em;
`;

const PopoverBtn = styled.button.attrs({ type: 'button' })<{ active?: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  background: ${(p) => (p.active ? p.theme.primary : p.theme.white)};
  color: ${(p) => (p.active ? p.theme.white : p.theme.black)};
  border: 0;
  padding: 0.9375rem;
  animation: ${fade} 0.125s linear;
  border-radius: 0 !important;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.05) 6px 0 16px 0;
  & > span[class^='icon-'] {
    font-size: 1.125rem;
  }
  &:not(:first-child) {
    &::after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 9px;
      width: 1px;
      height: calc(100% - 18px);
      background: ${(p) => p.theme.lightGray2};
    }
  }
  &:first-child {
    border-top-left-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
    padding-left: 1.0625rem;
  }
  &:last-child {
    border-top-right-radius: 1rem !important;
    border-bottom-right-radius: 1rem !important;
    padding-right: 1.0625rem;
  }
  &:hover {
    background: ${(p) => p.theme.primary};
    color: ${(p) => p.theme.white};
    & > ${PopoverBtnText} {
      color: ${(p) => p.theme.white};
    }
    &::after {
      opacity: 0;
    }
    & + * {
      &::after {
        opacity: 0;
      }
    }
  }
  ${(p) =>
    p.active &&
    css`
      & > ${PopoverBtnText} {
        color: ${(p) => p.theme.white};
      }
      &::after {
        opacity: 0;
      }
      & + * {
        &::after {
          opacity: 0;
        }
      }
    `}
`;

interface IProps {
  children: React.ReactNode;
  content: IActionPopover[];
  setIsActive?: (value: boolean) => void;
  position?: IActionPopoverPosition;
  closeOnClick?: boolean;
  className?: string;
  onOpen?: () => void;
  trigger?: 'click' | 'hover';
}

const ActionPopover = forwardRef(function ActionPopover(
  {
    children,
    content,
    setIsActive,
    position = 'right',
    closeOnClick = true,
    className,
    onOpen,
    trigger = 'click',
  }: IProps,
  ref,
) {
  const [isOpen, setIsOpen] = useState(false);
  const [selfParams, setSelfParams] = useState<DOMRect>();
  const [parentParams, setParentParams] = useState<DOMRect>();
  const [popoverPosition, setPopoverPosition] = useState<IActionPopoverPosition>(position);
  const wrapperRef = useRef<any>(null);
  const contentRef = useRef<any>(null);

  useImperativeHandle(
    ref,
    () => {
      return {
        close() {
          setIsOpen(false);
        },
      };
    },
    [],
  );

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  const checkPosition = () => {
    if (contentRef?.current) {
      const wrapper = wrapperRef.current.getBoundingClientRect();
      const { width } = contentRef.current.getBoundingClientRect();
      if (position === 'right') {
        if (wrapper.right + margin + width > window.innerWidth) {
          setPopoverPosition('left');
        }
        return;
      }
      if (position === 'left') {
        if (wrapper.left - margin - width < 0) {
          setPopoverPosition('right');
        }
        return;
      }

      setPopoverPosition(position);
    }
  };

  useEffect(() => {
    setSelfParams(contentRef?.current?.getBoundingClientRect());
    checkPosition();
    if (setIsActive) {
      setIsActive(isOpen);
    }

    if (isOpen && onOpen) {
      onOpen();
    }
  }, [isOpen]);

  const handleClick = () => {
    if (closeOnClick) {
      setIsOpen(false);
    }
  };

  const handleChildClick = () => {
    if (trigger === 'click') {
      setIsOpen(!isOpen);
    }
  };

  const handleMouseEnter = (event: React.MouseEvent) => {
    setParentParams(event.currentTarget.getBoundingClientRect());
    if (trigger === 'hover') {
      setIsOpen(true);
    }
  };

  const handleMouseLeave = () => {
    if (trigger === 'hover') {
      setIsOpen(false);
    }
  };

  return (
    <PopoverWrapper
      ref={wrapperRef}
      className={className}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div style={{ display: 'flex' }} onClick={handleChildClick}>
        {children}
      </div>
      {isOpen && (
        <PopoverContent
          ref={contentRef}
          position={popoverPosition}
          trigger={trigger}
          self={selfParams as DOMRect}
          parent={parentParams as DOMRect}
          onClick={handleClick}
        >
          {content?.map(
            (item, index) =>
              !item.hide && (
                <PopoverBtn
                  key={`${item.icon}_${index}`}
                  onClick={item.callback}
                  active={item.active}
                >
                  <span className={`icon-${item.icon}`} />
                  {item.text && <PopoverBtnText>{item.text}</PopoverBtnText>}
                </PopoverBtn>
              ),
          )}
        </PopoverContent>
      )}
    </PopoverWrapper>
  );
});

export default ActionPopover;

export type MarketPlaceType = 'Wildberries' | 'YandexMarket' | 'Ozon';
export type DeviceType = 'ПК' | 'Мобильный';
export type ViewType = 'Страница товаров' | 'Плитка';
export type OzonType = 'Тип 1' | 'Тип 2';

export type ItemType = MarketPlaceType | DeviceType | ViewType;

export type TImageDetail = {
  details: any;
  id: number;
  modifiedImageId: number;
  originalImageId: number;
  position: number;
};

export const allMarketplaceType: Array<{ title: MarketPlaceType }> = [
  { title: 'Wildberries' },
  { title: 'YandexMarket' },
  { title: 'Ozon' },
];

export const allDeviceType: Array<{ title: DeviceType }> = [
  { title: 'ПК' },
  { title: 'Мобильный' },
];

export const allViewType: Array<{ title: ViewType }> = [
  { title: 'Страница товаров' },
  { title: 'Плитка' },
];

export const ozonTypes: Array<{ title: OzonType }> = [{ title: 'Тип 1' }, { title: 'Тип 2' }];

export type TProductPageMobile = {
  imageDetails: TImageDetail[];
  marketPlace: MarketPlaceType;
  imagesSrc: { [key: number]: string };
  loadingImages: boolean;
  ozonType?: OzonType;
  yandexType?: OzonType;
  canvasRatio?: 'default' | 'square';
};

import React, { useEffect, useState } from 'react';
import { Modal } from './modal.component';
import Input from './input.component';
import { ButtonsWrap } from '../pages/files/files.style';
import { Button } from './button.component';
import { Text } from './text.component';
import { useTranslation } from 'react-i18next';
import { createProjectWithTemplate } from '../requests/project.requests';
import * as amplitude from '@amplitude/analytics-browser';
import { useNavigate } from 'react-router-dom';
import RateModal from './rates/rate-modal.component';
import { useUserContext } from '../contexts/user.context';
import { RateNames } from '../constants/rates.constant';
import { NewProjectWithTemplateModalBody } from '../pages/my-projects/my-projects.style';
import { canvasRatios } from './new-project-with-template-modal.component';
import { InfoGraphicRequest } from '../requests/infographic.request';
import { TGetListResponse } from '../interfaces/infographics.interface';
import { GlobalSort } from '../constants/infographic.constants';
import useSortSearch from '../hooks/use-sort-search.hooks';
import SortDropdown from './sort-dropdown.component';
import {
  ErrorText,
  ListContainer,
  ListItem,
} from './toolbar/template-popover/template-popover.style';
import useInfiniteScroll from '../hooks/use-infinite-scroll';
import { ImageWrapper } from './toolbar/template-popover/template-popover.component';
import SkeletonLoader from './skeleton-loader.component';
import plus from '../assets/icons/plus.svg';
import { INITIAL_PROJECT } from '../constants/editor.constants';
import { TemplateCard } from './template-card.component';

interface IProps {
  onClose: () => void;
  isOpen: boolean;
  trackKey: string;
  setIsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  setIsAdditionalModalOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  setType?: React.Dispatch<React.SetStateAction<string>>;
  templateList?: any[];
}
const NewProjectModal: React.FC<IProps> = ({
  onClose,
  trackKey,
  isOpen,
  setIsOpen,
  setIsAdditionalModalOpen,
  setType,
  templateList = [],
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userData, updateUserDetails } = useUserContext();
  const publicSort = useSortSearch(GlobalSort[0], 'DESC');

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [initialProjectName, setInitialProjectName] = useState<string>('');
  const [limit, setLimit] = useState<number>(1);
  const [canvasRatio, setCanvasRatio] = useState<any>(canvasRatios[0]);
  const [selectedTemplate, setSelectedTemplate] = useState<any>(null);
  const [templates, setTemplates] = useState<TGetListResponse[]>([]);
  const [isTemplatesLoading, setIsTemplatesLoading] = useState<boolean>(false);

  const globalInfiniteScroll = useInfiniteScroll(
    isTemplatesLoading,
    publicSort.hasMore,
    publicSort.setPage,
  );

  const sort = {
    selection: GlobalSort,
    selectedOption: publicSort.selectedOption,
    setSelectedOption: publicSort.setSelectedOption,
    sortOrder: publicSort.sortAsc,
    setSortOrder: publicSort.setSortAsc,
    search: publicSort.search,
    setSearch: publicSort.setSearch,
    page: publicSort.page,
    setPage: publicSort.setPage,
  };

  const handleAddProject = () => {
    if (isLoading) return;

    // setIsLoading(true);
    if (userData) {
      const { rate } = userData;
      if (rate?.name === RateNames.UNLIMITED || limit > 0) {
        amplitude.track(trackKey);
        setIsLoading(true);
        const initialProjectBody = {
          ...INITIAL_PROJECT,
          projectName: initialProjectName,
          details: {
            ...INITIAL_PROJECT.details,
            canvasRatio: canvasRatio.value,
            withTemplate: !!selectedTemplate,
          },
        };

        if (selectedTemplate) {
          InfoGraphicRequest.getInfoGraphic(selectedTemplate).then((res: any) => {
            const items = {
              // modifiedImageId: res.imageId,
              details: res.details[0],
            };

            const body = {
              ...INITIAL_PROJECT,
              projectName: initialProjectName,
              details: {
                ...INITIAL_PROJECT.details,
                canvasRatio: canvasRatio.value,
                withTemplate: true,
              },
              projectItems: [items],
            };

            createProjectWithTemplate(body)
              .then((res) => {
                if (res.success) {
                  updateUserDetails();
                  navigate(`/project/${res.data?.id}`);
                  setInitialProjectName('');
                  setType && setType('default');
                } else {
                  setIsOpen && setIsOpen(false);
                  setIsAdditionalModalOpen && setIsAdditionalModalOpen(true);
                }
              })
              .catch(() => null)
              .finally(() => setIsLoading(false));
          });
        } else {
          createProjectWithTemplate(initialProjectBody)
            .then((res) => {
              if (res.success) {
                updateUserDetails();
                navigate(`/project/${res.data?.id}`);
                setInitialProjectName('');
                setType && setType('default');
              } else {
                setIsOpen && setIsOpen(false);
                setIsAdditionalModalOpen && setIsAdditionalModalOpen(true);
              }
            })
            .catch(() => null)
            .finally(() => setIsLoading(false));
        }
      }
    }
  };

  const ListLoader = Array.from({ length: 6 }).map((_, index) => (
    <SkeletonLoader key={index} width="115px" height={'160px'} />
  ));

  const renderList = (list: TGetListResponse[]) => (
    <ListContainer onScroll={globalInfiniteScroll.handleScroll} style={{ gap: '10px' }}>
      <ListItem style={{ width: '115px' }} height={'160px'}>
        <ImageWrapper
          onClick={() => setSelectedTemplate(null)}
          height={'160px'}
          isLoading={false}
          className={`new-card-container ${selectedTemplate === null ? 'selected' : ''}`}
        >
          <img src={plus} className="icon" alt="plus" />
          <Text fontWeight={600} fontSize="0.875rem" color="infoGray">
            Начать с чистого листа
          </Text>
        </ImageWrapper>
      </ListItem>

      {list.length > 0 ? (
        list.map((item) => (
          <TemplateCard
            key={item.id}
            src={`/api/project/load/image/png?imageId=${item.imageId}&imageSizeType=NORMAL`}
            active={selectedTemplate === item?.id}
            onClick={() => setSelectedTemplate(item.id)}
          />
        ))
      ) : (
        <>
          {list === templates && !isTemplatesLoading && (
            <ErrorText>
              По вашему запросу ничего не найдено. Повторите попытку, набрав другие слова.
            </ErrorText>
          )}
        </>
      )}
      {list === templates && isTemplatesLoading && <>{ListLoader}</>}
    </ListContainer>
  );

  const loadTemplates = (page: number) => {
    page === 0 && setIsTemplatesLoading(true);
    if (page === 0 || publicSort.hasMore) {
      InfoGraphicRequest.getGlobalList({
        orderType: publicSort.sortAsc,
        page,
        sortingType: publicSort.selectedOption.value as any,
        templateType: 'CANVAS',
      })
        .then((res) => {
          setIsTemplatesLoading(true);
          if (res.list.length > 0) {
            const list = page > 0 ? [...templates, ...res.list] : res.list;
            setTemplates(list);
          }
        })
        .finally(() => setIsTemplatesLoading(false));
    }
  };

  const handleSortAndUpdate = () => {
    loadTemplates(0);
    publicSort.setPage(0);
  };

  const handlePageUpdate = () => {
    loadTemplates(publicSort.page);
  };

  useEffect(() => {
    if (userData) {
      const { rate } = userData;
      setLimit(rate.availableProjectCount);
    }
  }, [userData]);

  useEffect(() => {
    if (templateList.length > 0) {
      setTemplates(templateList);
    } else {
      handleSortAndUpdate();
    }
  }, [templateList?.length]);

  useEffect(() => {
    handleSortAndUpdate();
  }, [publicSort.sortAsc, publicSort.selectedOption.value]);

  useEffect(() => {
    publicSort.page > 0 && handlePageUpdate();
  }, [publicSort.page]);

  if (userData && userData.rate?.name !== RateNames.UNLIMITED && limit && limit <= 0) {
    return <RateModal isOpen={isOpen} onClose={onClose} />;
  }

  return (
    <>
      {isOpen && (
        <Modal onClose={onClose} width="50.375rem">
          <NewProjectWithTemplateModalBody>
            <Text fontSize="1.875rem" strong style={{ color: '#000000' }}>
              {t('myProjects.new')}
            </Text>

            <div className="df-sb">
              <div className="df-cl">
                <Text fontWeight={500}>1. Выберите формат</Text>
                <div className="df-rw">
                  {canvasRatios.map((item: any) => (
                    <Button
                      btnStyle="gray"
                      key={item.value}
                      active={canvasRatio.value === item.value}
                      onClick={() => {
                        setCanvasRatio(item);
                        item.value === 'square' && setSelectedTemplate(null);
                      }}
                    >
                      {item.label}
                    </Button>
                  ))}
                </div>
              </div>
              <div className="df-cl" style={{ flex: 1 }}>
                <Text fontWeight={500}>2. Назовите проект</Text>
                <Input
                  label="Название проекта"
                  value={initialProjectName}
                  onChange={(e) => setInitialProjectName(e.target.value)}
                  showButtons={false}
                  maxlength={100}
                />
              </div>
            </div>

            {canvasRatio.value === 'default' ? <div className="df-cl mt-10">
              <div className="df-sb">
                <Text fontWeight={500}>3. Выберите шаблон</Text>
                <SortDropdown
                  selection={sort.selection}
                  setSelectedOption={sort.setSelectedOption}
                  selectedOption={sort.selectedOption.title}
                  sortOrder={sort.sortOrder}
                  setSortOrder={sort.setSortOrder}
                  width={'147.11px'}
                  disabled={isTemplatesLoading}
                />
              </div>
              {renderList(templates)}
            </div>
              : <Text>Шаблоны для формата 1:1 еще в разработке, но скоро появятся.</Text>
            }

            <ButtonsWrap>
              <div style={{ display: 'flex', justifyContent: 'flex-end', gap: 20, width: '100%' }}>
                <Button
                  btnStyle={'cancel'}
                  style={{ padding: '0.8125rem 0' }}
                  onClick={() => {
                    setInitialProjectName('');
                    onClose();
                  }}
                >
                  Отменить
                </Button>
                <Button
                  icon={'plus'}
                  onClick={() => handleAddProject()}
                  disabled={
                    !initialProjectName || initialProjectName.trim().length === 0 || isLoading
                  }
                  isLoading={isLoading}
                >
                  Создать
                </Button>
              </div>
            </ButtonsWrap>
          </NewProjectWithTemplateModalBody>
        </Modal>
      )}
    </>
  );
};

export default NewProjectModal;

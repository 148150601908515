import React, { ButtonHTMLAttributes } from 'react';
import styled from 'styled-components';
import CircleLoader2 from './circle-loader2';

interface IconBtnProps extends ButtonHTMLAttributes<any> {
  icon: string;
  btnStyle?: string;
  isLoading?: boolean;
}

export const IconBtn = styled.button<{ btnStyle?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(p) => p.theme.black};
  background-color: ${(p) => p.theme.lightGray2};
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  font-weight: 600;
  font-size: 1.25rem;
  border: 0;
  padding: 0.9375rem;
  cursor: pointer;
  transition: background-color 0.2s linear, color 0.2s linear;
  &.active,
  &:hover {
    color: ${(p) => p.theme.white};
    background-color: ${(p) => p.theme.primary};
  }
  &:disabled {
    background-color: ${(p) => p.theme.lightGray2};
    color: rgba(0, 0, 0, 0.2);
  }
`;

export const IconButton = ({ icon, isLoading, ...rest }: IconBtnProps) => {
  return (
    <IconBtn
      type="button"
      {...rest}
      data-tooltip={rest.title as string}
      data-tooltip-position="bottom"
    >
      {isLoading ? (
        <CircleLoader2 shineColor="rgb(102, 32, 199)" size={20} />
      ) : (
        <span className={`icon-${icon}`} />
      )}
    </IconBtn>
  );
};

import React, { HTMLAttributes, useEffect, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';

import { RoundButton } from './round-btn.component';
import Popover from './popover.component';
import { InputOverlay } from './card.checkbox.overlay';

interface ICardProps extends HTMLAttributes<any> {
  children: React.ReactNode;
  color?: string;
  height?: string;
  width?: string;
  customOptions?: React.ReactNode;
  onDownload?: () => void;
  forceDisablePopover?: boolean;
  enableHover?: boolean;
  cursor?: string;
  active?: boolean;
  highlight?: boolean;
  projectDownloading?: boolean;
  onPopoverActiveChange?: (active: boolean) => void;
}

const fade = keyframes`
  0% { opacity: 0 }
  100% { opacity: 1 }
`;

const Overlay = styled.div<{ active: boolean }>`
  display: ${(p) => (p.active ? 'flex' : 'none')};
  align-items: flex-start;
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(2px);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 16px;
  animation: ${fade} 0.25s linear;
  padding: 1.25rem;
  z-index: 100;
  & > * {
    &:not(:last-child) {
      margin-right: 0.625rem;
    }
  }
`;
export const CardWrapper = styled.div<ICardProps & { active?: boolean; highlight?: boolean }>`
  position: relative;
  width: ${(p) => p.width || '15rem'};
  height: ${(p) => p.height || '20.625rem'};
  background: ${(p) => p.theme.white};
  border-radius: 16px;
  padding: 1.25rem;
  cursor: ${(p) => p.cursor};
  ${(p) =>
    p.color === 'gray' &&
    css`
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), ${p.theme.white};
    `}
  ${(p) =>
    p.customOptions &&
    css`
      &:hover {
        & > ${Overlay} {
          display: flex;
          align-items: flex-start;
        }
      }
    `}

    // border: 2px solid transparent;
  ${({ highlight, active }) =>
    highlight &&
    css`
      border: 2px solid ${active ? '#6620C7' : '#cccccc'};
      user-select: none;
    `}
  ${({ highlight }) =>
    highlight &&
    css`
      &:hover {
        border: 2px solid #6620c7 !important;
        & > ${InputOverlay} {
          border: 2px solid #6620c7 !important;
        }
      }
    `}
`;

export const Card: React.FC<ICardProps> = ({
  projectDownloading,
  children,
  customOptions,
  onDownload,
  forceDisablePopover = false,
  enableHover = true,
  cursor = 'pointer',
  active,
  highlight,
  onPopoverActiveChange,
  ...rest
}: ICardProps) => {
  const [popoverActive, setPopoverActive] = useState<boolean>(false);

  useEffect(() => {
    if (forceDisablePopover) {
      setPopoverActive(false);
    }
  }, [forceDisablePopover]);

  useEffect(() => {
    if (onPopoverActiveChange) {
      onPopoverActiveChange(popoverActive);
    }
  }, [popoverActive, onPopoverActiveChange]);

  return (
    <CardWrapper customOptions cursor={cursor} highlight={highlight} active={active} {...rest}>
      {children}
      {customOptions && enableHover && (
        <Overlay active={popoverActive}>
          <Popover setIsActive={setPopoverActive} content={customOptions}>
            <RoundButton
              icon={'more'}
              className={popoverActive ? 'active' : ''}
              title={'Действие'}
            />
          </Popover>

          {onDownload && (
            <RoundButton
              isLoading={projectDownloading}
              icon={'download'}
              onClick={(event) => {
                event.stopPropagation();
                onDownload();
              }}
              title={'Выгрузить'}
            />
          )}
        </Overlay>
      )}
    </CardWrapper>
  );
};

import React, { useEffect, useState } from 'react';
import SortDropdown from '../sort-dropdown.component';
import Search from '../search.component';
import {
  ContentWrapper,
  TabsTitle,
  TabsTitleList,
  TabsWrapper,
} from '../object-toolbar/infographic-tool/infographic.style';
import { TabType } from '../../interfaces/infographics.interface';
import { SortOption } from '../../interfaces/editor.interface';
import { IMAGE_OR_FOLDER } from '../../constants/editor.constants';
import { ErrorMsg } from './image-popover/image-popover.style';
import { useShared } from '../../contexts/shared.context';

const PopoverTab: React.FC<{
  width?: string;
  firstLoader?: boolean;
  folderImageListIsOpen?: boolean;
  tabs: TabType[];
  optionalContent?: React.ReactNode;
  imagePopover?: boolean;
  ImageNotFound?: boolean;
  imageLoading?: boolean;
  folderImageListNotFound?: boolean;
  searchResult?: boolean;
  errorMessage?: string;
  folderLoading?: boolean;
  folderImageLoading?: boolean;
  firstLoaderFolder?: boolean;
  firstLoaderImageFolder?: boolean;
  searchPlaceholder?: string;
  selectedOptionFolderOrImage?: SortOption;
  setSelectedOptionFolderOrImage?: (option: SortOption) => void;
}> = ({
  firstLoader,
  errorMessage,
  tabs,
  optionalContent,
  imagePopover = false,
  ImageNotFound,
  folderImageListNotFound,
  searchResult,
  folderImageListIsOpen,
  imageLoading,
  folderLoading,
  folderImageLoading,
  firstLoaderFolder,
  firstLoaderImageFolder,
  searchPlaceholder,
  width,
  selectedOptionFolderOrImage,
  setSelectedOptionFolderOrImage,
}) => {
  const [activeTab, setActiveTab] = useState(tabs[0].title);
  const { recentTemplateIds } = useShared();

  useEffect(() => {
    if (imagePopover) setActiveTab(selectedOptionFolderOrImage?.title as 'Файлы' | 'Папки');
  }, [selectedOptionFolderOrImage?.title]);

  useEffect(() => {
    if (recentTemplateIds.ids.length > 0) {
      if (recentTemplateIds.type === 'infographics') {
        setActiveTab('Моя инфографика');
        return;
      }
      setActiveTab('Мои шаблоны');
    }
  }, [recentTemplateIds]);

  const defaultChecker = !folderImageListIsOpen && !ImageNotFound && !searchResult;

  const allLoading = imageLoading || folderLoading || folderImageLoading;

  return (
    <>
      {errorMessage && <ErrorMsg>{errorMessage}</ErrorMsg>}
      {(optionalContent || !folderImageListNotFound) && (
        <div style={{ maxHeight: '15px' }}>{optionalContent}</div>
      )}
      {/* divider */}
      {optionalContent && !firstLoaderImageFolder && (
        <div style={{ height: folderImageListNotFound ? '17.5px' : '37.5px' }}></div>
      )}
      {tabs.map(
        (tab) =>
          activeTab === tab.title &&
          !firstLoader &&
          (!folderImageListIsOpen
            ? !ImageNotFound
            : !folderImageListNotFound && !firstLoaderImageFolder) && (
            <Search
              key={tab.title}
              value={tab.sort.search}
              setSearchValue={tab.sort.setSearch}
              placeholder={searchPlaceholder ? searchPlaceholder : 'Поиск'}
              fail={searchResult}
              disabled={allLoading}
            />
          ),
      )}
      {/* divider */}
      {!firstLoader && imagePopover && !ImageNotFound && !searchResult && (
        <div style={{ width: '100%', height: '10px' }}></div>
      )}

      <TabsWrapper
        row={
          !folderImageListNotFound &&
          !firstLoader &&
          defaultChecker &&
          imagePopover &&
          !firstLoaderFolder
        }
      >
        {!folderImageListNotFound && !firstLoader && defaultChecker && (
          <>
            {imagePopover &&
            selectedOptionFolderOrImage &&
            setSelectedOptionFolderOrImage &&
            !firstLoaderFolder ? (
              <div style={{ position: 'relative', zIndex: 1050 }}>
                <SortDropdown
                  selection={IMAGE_OR_FOLDER}
                  setSelectedOption={setSelectedOptionFolderOrImage}
                  selectedOption={selectedOptionFolderOrImage.title}
                />
              </div>
            ) : (
              !firstLoaderFolder && (
                <TabsTitleList>
                  {tabs.map((tab) => (
                    <TabsTitle
                      key={tab.title}
                      onClick={() => {
                        setActiveTab(tab.title);
                      }}
                      active={tab.title === activeTab}
                    >
                      {tab.title}
                    </TabsTitle>
                  ))}
                </TabsTitleList>
              )
            )}
          </>
        )}
        {tabs.map(
          (tab) =>
            !firstLoader &&
            activeTab === tab.title &&
            !searchResult &&
            (!folderImageListIsOpen
              ? !ImageNotFound
              : !folderImageListNotFound && !firstLoaderImageFolder) && (
              <SortDropdown
                key={tab.title}
                selection={tab.sort.selection}
                setSelectedOption={tab.sort.setSelectedOption}
                selectedOption={tab.sort.selectedOption.title}
                sortOrder={tab.sort.sortOrder}
                setSortOrder={tab.sort.setSortOrder}
                width={width ? width : tab.title === 'Папки' ? '162.2px' : '147.11px'}
                disabled={allLoading}
              />
            ),
        )}
      </TabsWrapper>
      {tabs.map(
        (tab) =>
          activeTab === tab.title && <ContentWrapper key={tab.title}>{tab.content}</ContentWrapper>,
      )}
    </>
  );
};

export default PopoverTab;

import React, { InputHTMLAttributes, LabelHTMLAttributes } from 'react';
import styled, { css } from 'styled-components';

interface IInputProps extends InputHTMLAttributes<any> {
  icon?: string;
  label?: string;
  pt?: number;
}

const Wrapper = styled.div<IInputProps>`
  ${({ pt }) =>
    pt &&
    css`
      padding-top: ${pt}px;
    `}
`;

const Label = styled.label<LabelHTMLAttributes<any>>`
  display: flex;
  align-items: center;
  text-align: center;
  font-style: normal;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.1875rem;
  color: ${(p) => p.theme.black};
  cursor: pointer;
  & > span[class^='icon-'] {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.5rem;
    height: 1.5rem;
    color: transparent;
    text-align: center;
    border-radius: 8px;
    background: #f0f0f0;
    border: 2px solid #cccccc;
    margin-right: 0.625rem;
    transition: background-color 0.3s ease-in-out, border 0.3s ease-in-out;
  }
  &:hover {
    & > span[class^='icon-'] {
      border-color: ${(p) => p.theme.primary};
    }
  }
`;

const InputWrapper = styled.input.attrs({ type: 'checkbox' })<IInputProps>`
  display: none;
  &:checked {
    & + ${Label} {
      & > span[class^='icon-'] {
        color: ${(p) => p.theme.white};
        background-color: ${(p) => p.theme.primary};
        border-color: ${(p) => p.theme.primary};
      }
    }
  }
`;

export const Checkbox = ({ id = 'myCheckbox', icon, label, pt, ...rest }: IInputProps) => {
  return (
    <Wrapper pt={pt}>
      <InputWrapper {...rest} type="checkbox" id={id} />
      <Label htmlFor={id}>
        <span className="icon-check" style={{ fontSize: '18px' }} />
        {label}
      </Label>
    </Wrapper>
  );
};

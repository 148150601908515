export const CANVAS_SIZE: { [key: string]: { width: number; height: number } } = {
  default: { width: 1800, height: 2400 },
  square: { width: 1800, height: 1800 },
};

export const FOLDER_SORT = [
  {
    title: 'По дате изменения',
    value: 'BY_UPDATE_DATE',
  },
  {
    title: 'По дате создания',
    value: 'BY_CREATION_DATE',
  },
  {
    title: 'По названию',
    value: 'BY_NAME',
  },
];

export const FILE_SORT = [
  {
    title: 'По дате загрузки',
    value: 'BY_CREATION_DATE',
  },
  {
    title: 'По названию',
    value: 'BY_NAME',
  },
];

export const fallbackImage =
  'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';

export const DEBOUNCE_SAVE = 10;

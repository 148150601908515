import React, { InputHTMLAttributes } from 'react';
import styled from 'styled-components';
import FabricTypes from 'fabric/fabric-impl';
import { FabricJSEditor } from 'fabricjs-react';
import { useTranslation } from 'react-i18next';
import { NumberInput } from '../number-input.component';
import { useCanvasFocus } from '../../contexts/canvas-focus.context';

const Wrapper = styled.div`
  display: flex;
  position: relative;
  padding: 0.9375rem;
  align-items: center;
  justify-content: space-between;
  gap: 3px;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 9px;
    display: block;
    height: 1px;
    width: calc(100% - 18px);
    background: ${(p) => p.theme.lightGray2};
  }
`;

const FontChangeButton = styled.div`
  display: block;
  cursor: pointer;
  > span [class^='icon-'] {
    font-size: 1rem;
  }
  &:hover {
    & > span[class^='icon-'] {
      color: #6620c7;
    }
  }
  &:active {
    & > span[class^='icon-'] {
      color: #6620c7;
    }
  }
`;

interface IProps extends InputHTMLAttributes<any> {
  editor: FabricJSEditor;
  activeObject?: FabricTypes.Object;
}

const FontSizeTool: React.FC<IProps> = ({ activeObject, editor, ...rest }) => {
  const { t } = useTranslation();
  const { setIsInputFocused } = useCanvasFocus();

  const handleChange = (value: string) => {
    rest.onChange &&
      rest.onChange({ target: { value: value } } as React.ChangeEvent<HTMLInputElement>);
  };

  const handlePlusButtonClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!rest.value) {
      handleChange('1');
    } else {
      const value = parseInt(rest.value?.toString(), 10);
      if (isNaN(value)) {
        handleChange('1');
      } else if (value >= 60) {
        e.preventDefault();
      } else {
        handleChange(`${value + 1}`);
      }
    }
  };

  const handleMinusButtonClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!rest.value) {
      handleChange('12');
    } else {
      const value = parseInt(rest.value?.toString(), 10);
      if (isNaN(value)) {
        handleChange('12');
      } else if (value <= 12) {
        e.preventDefault();
      } else {
        handleChange(`${value - 1}`);
      }
    }
  };

  return (
    <Wrapper data-tooltip={t(`tooltip.textFontSize`)} data-tooltip-position="left">
      <FontChangeButton onClick={handleMinusButtonClick}>
        <span className={'icon-minus'} />
      </FontChangeButton>
      <NumberInput
        onFocus={() => setIsInputFocused(true)}
        onBlur={() => setIsInputFocused(false)}
        {...rest}
      />
      <FontChangeButton onClick={handlePlusButtonClick}>
        <span className={'icon-plus'} />
      </FontChangeButton>
    </Wrapper>
  );
};

export default FontSizeTool;

import React, { useEffect, useState } from 'react';
import FontsListItem from './fonts-list-item.component';
import Accordion from '../accordion.component';
import FontWeightOptions from './font-weight-options.component';
import { IBasicEditorProps } from '../../interfaces/editor.interface';
import styled from 'styled-components';
import { GOOGLE_FONTS, FONTS_URL } from '../../constants/editor.constants';
import { changeFontFamily } from '../../utils/editor.utils';

type FontMap = {
  [key in (typeof GOOGLE_FONTS)[number]]: boolean;
};

const Wrapper = styled.div`
  max-height: 80vh;
  border-radius: 1rem;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  cursor: pointer;
`;

type Props = {
  active: { style: string; weight: string | number };
  onChoose?: (font: string, value: number) => void;
} & IBasicEditorProps;

export interface FontWeights {
  [fontFamily: string]: number[];
}

const FontsList = ({ activeObject, editor, active, onChoose }: Props) => {
  const [openFontAccordion, setOpenFontAccordion] = useState<FontMap>({});
  const [fontWeights, setFontWeights] = useState<FontWeights>({});

  useEffect(() => {
    parseFontURL(FONTS_URL);
  }, []);

  const handleOpenAccordion = (value: keyof FontMap) => () => {
    setOpenFontAccordion({ [value]: !openFontAccordion[value] });
  };

  const parseFontURL = (fontURL: string) => {
    const params = fontURL.split('?')[1].split('|');
    const fontWeights: FontWeights = {};

    params.forEach((param) => {
      const parts = param.split(':');
      const fontFamily = parts[0].replace('+', ' ');
      if (parts.length === 2) {
        const weightsString = parts[1].replace('wght@', '');
        fontWeights[fontFamily] = weightsString.split(';').map((weight) => parseInt(weight));
      }
    });

    setFontWeights(fontWeights);
  };

  const handleItemClick = (value: number, font: any) => () => {
    activeObject && changeFontFamily(activeObject, editor.canvas, font, value);
    onChoose && onChoose(font, value);
  };

  return (
    <Wrapper>
      {Object.keys(fontWeights).map((elem, index) => (
        <Accordion
          key={`${elem}--${index}`}
          title={
            <FontsListItem
              active={active.style === elem}
              font={elem}
              isCanExpand={fontWeights[elem].length === 1}
            />
          }
          isOpen={openFontAccordion[elem]}
          handleOpen={
            fontWeights[elem].length === 1
              ? handleItemClick(fontWeights[elem][0], elem)
              : handleOpenAccordion(elem)
          }
        >
          <FontWeightOptions
            activeFontStyle={active.style === elem}
            activeFontWeight={active.weight}
            font={elem}
            editor={editor}
            options={fontWeights[elem]}
            handleItemClick={handleItemClick}
          />
        </Accordion>
      ))}
    </Wrapper>
  );
};

export default FontsList;

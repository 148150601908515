import React, { useEffect, useRef, useState } from 'react';
import Components from '../../editor/editor.style';
import {
  DeviceType,
  ItemType,
  MarketPlaceType,
  OzonType,
  ViewType,
} from '../../../interfaces/preview-image.interface';
import { IconButton } from '../../../components/icon-btn.component';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../components/button.component';
import HeaderItem from './header-item';
import { PreviewImageTab } from '../preview-image.style';
import DownloadProject from '../../../components/download-project.component';
import { downloadProject } from '../../../utils/canvaspng';
import { FlexCol } from '../../../components/flexbox.component';

type THeader = {
  headerItems: {
    items: Array<{ title: ItemType }>;
    activeItem: ItemType;
    onItemSelect: (value: ItemType) => void;
  }[];
  projectId: number;
  ozonHeaderItem: {
    items: Array<{ title: OzonType }>;
    activeItem: OzonType;
    onItemSelect: (value: OzonType) => void;
  };
  yandexHeaderItem: {
    items: Array<{ title: OzonType }>;
    activeItem: OzonType;
    onItemSelect: (value: OzonType) => void;
  };
  marketPlace: MarketPlaceType;
  view: ViewType;
  device: DeviceType;
  projectInfo: any;
  projectInfoImage: any;
};

const Header: React.FC<THeader> = ({
  headerItems,
  projectId,
  ozonHeaderItem,
  yandexHeaderItem,
  marketPlace,
  view,
  device,
  projectInfo,
  projectInfoImage,
}) => {
  const navigate = useNavigate();

  const exportAsImage = async () => {
    const { projectName } = projectInfo;
    const a = document.createElement('a');
    a.href = projectInfoImage;
    a.download = projectName !== undefined ? `${projectName}.png` : 'canvas.png';
    a.click();
  };

  const downloadProjectPreview = () => {
    if (projectInfo.projectItems.length > 1) {
      downloadProject(
        projectInfo?.projectItems.map((item: any) => item.details),
        projectInfo.projectName,
      );
    } else {
      exportAsImage();
    }
  };

  return (
    <>
      <Components.Header>
        <Components.HeaderItem>
          {headerItems.map((headerItem, idx) => (
            <HeaderItem headerItem={headerItem} key={idx} idx={idx} />
          ))}
        </Components.HeaderItem>
        <Components.HeaderItem columnGap={20}>
          <IconButton
            title="Редактировать проект"
            icon={'edit'}
            onClick={() => {
              projectId ? navigate(`/project/${projectId}`) : navigate('/my-project');
            }}
          />
          <Button
            icon={'download'}
            onClick={() => {
              downloadProjectPreview();
            }}
          >
            Выгрузить
          </Button>
        </Components.HeaderItem>
      </Components.Header>
      {marketPlace === 'Ozon' && device === 'Мобильный' && (
        <Components.Header style={{ justifyContent: 'normal', padding: '0 1.25rem', gap: '6px' }}>
          {ozonHeaderItem &&
            ozonHeaderItem.items.map((item) => (
              <PreviewImageTab
                key={item.title}
                active={ozonHeaderItem.activeItem === item.title}
                onClick={() => ozonHeaderItem.onItemSelect(item.title)}
              >
                {item.title}
              </PreviewImageTab>
            ))}
        </Components.Header>
      )}

      {marketPlace === 'YandexMarket' && device === 'Мобильный' && (
        <Components.Header style={{ justifyContent: 'normal', padding: '0 1.25rem', gap: '6px' }}>
          {yandexHeaderItem &&
            yandexHeaderItem.items.map((item) => (
              <PreviewImageTab
                key={item.title}
                active={yandexHeaderItem.activeItem === item.title}
                onClick={() => yandexHeaderItem.onItemSelect(item.title)}
              >
                {item.title}
              </PreviewImageTab>
            ))}
        </Components.Header>
      )}
    </>
  );
};

export default Header;

import React, { useEffect } from 'react'
import PrivacyPolicy from '../../assets/files/policy.pdf'

const PolicyPage = () => {

  useEffect(() => {
    window.location.href = PrivacyPolicy;
  }, [])

  return (
    <></>
  )
}

export default PolicyPage
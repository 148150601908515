import React, { useCallback, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { fabric } from 'fabric';
import { FabricJSEditor } from 'fabricjs-react';
import FabricTypes from 'fabric/fabric-impl';
import { useTranslation } from 'react-i18next';

import { useCanvasHistory } from '../contexts/canvas-history.context';
import { useClipboard } from '../contexts/clipboard.context';
import { ICanvasObject, IObjectWithId } from '../interfaces/editor.interface';
import {
  handleDeleteItemFromSavedObjects,
  handleMoveObject,
  handleSelectGroupItem,
  handleSetDoubleClick,
} from '../utils/editor.utils';
import Popover from './popover.component';
import { MOVE_OPTIONS } from '../constants';
import { ToolbarRow, ToolbarBtn } from './toolbar/toolbar.style';
import { EDITOR_PROPERTIES } from '../constants/editor.constants';
import { getActionKey } from '../utils/shared.utils';
import { useCanvasFocus } from '../contexts/canvas-focus.context';
import { useShared } from '../contexts/shared.context';

const Wrapper = styled(ToolbarRow)<{ x?: number; y?: number }>`
  position: absolute;
  ${({ x, y }) =>
    x !== undefined &&
    y !== undefined &&
    css`
      left: ${x * 0.25}px;
      top: ${y * 0.25}px;
    `};
`;

interface IProps {
  show: boolean;
  position: null | FabricTypes.Point;
  editor: FabricJSEditor;
  activeObject: ICanvasObject;
  refreshProjectCard: VoidFunction;
  close: VoidFunction;
}

export const CanvasContextMenu = ({
  show,
  position,
  editor,
  activeObject,
  refreshProjectCard,
  close,
}: IProps) => {
  const { historySaveAction } = useCanvasHistory();
  const { t } = useTranslation();
  const clipboard = useClipboard();
  const { pageIndexes, handleIndexChange } = useShared();
  const { isFocused, isInputFocused } = useCanvasFocus();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [popoverActive, setPopoverActive] = useState<{ [key: string]: boolean }>({});

  const handleKeyboardEvents = useCallback(
    (e: KeyboardEvent) => {
      const actionKey = getActionKey();
      switch (e.code) {
        case 'Backspace':
        case 'Delete': {
          if (
            !isInputFocused &&
            activeObject &&
            !(activeObject.type === 'textbox' && (activeObject as FabricTypes.Textbox).isEditing)
          ) {
            e.preventDefault();
            handleDelete();
          }
          break;
        }
        case 'KeyC': {
          if (e[actionKey] && activeObject && !isInputFocused) {
            e.preventDefault();
            handleCopy();
          }
          break;
        }
        case 'KeyX': {
          if (e[actionKey] && activeObject && !isInputFocused) {
            e.preventDefault();
            handleCopy(true);
          }
          break;
        }
        case 'KeyV': {
          if (e[actionKey] && !isInputFocused) {
            e.preventDefault();
            handlePaste();
          }
          break;
        }
        case 'KeyA': {
          if (e[actionKey] && editor?.canvas && !isInputFocused) {
            e.preventDefault();
            const { canvas } = editor;
            const objects = canvas.getObjects();
            if (objects?.length) {
              canvas.discardActiveObject();
              const sel = new fabric.ActiveSelection(objects, {
                canvas,
              });
              canvas.setActiveObject(sel);
              canvas.requestRenderAll();
            }
          }
          break;
        }
        case 'KeyG': {
          if (e[actionKey] && !e.shiftKey && canGroup) {
            e.preventDefault();
            handleGroup();
          } else if (e[actionKey] && e.shiftKey && canUngroup) {
            e.preventDefault();
            handleUngroup();
          }
          break;
        }
      }
    },
    [activeObject, clipboard, position, isFocused, isInputFocused],
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyboardEvents, false);
    return () => {
      document.removeEventListener('keydown', handleKeyboardEvents, false);
    };
  }, [handleKeyboardEvents]);

  useEffect(() => {
    setIsOpen(show);
    if (!show) {
      setPopoverActive({});
    }
  }, [show]);

  const handleSetPopoverActive = (key: string) => (value: boolean) => {
    setPopoverActive((prevState) => ({ ...prevState, [key]: value }));
  };

  useEffect(() => {
    if (!isOpen) {
      setPopoverActive({});
    }
  }, [isOpen]);

  const canGroup = React.useMemo(() => {
    const selected = activeObject as FabricTypes.ActiveSelection;
    return selected?.type === 'activeSelection' && selected._objects?.length > 1;
  }, [activeObject]);

  const canUngroup = React.useMemo(() => {
    return (
      activeObject?.type === 'group' &&
      !(activeObject?.name && activeObject.name.includes('image_'))
    );
    // return (
    //   activeObject?.type === 'group' &&
    //   !(
    //     activeObject?.name &&
    //     ['rect', 'circle', 'triangle', 'path', 'line'].includes(activeObject.name)
    //   )
    // );
  }, [activeObject]);

  const handleUngroup = () => {
    const { canvas } = editor as FabricJSEditor;
    const group = activeObject as FabricTypes.Group;
    group.forEachObject((obj) => {
      obj.hasControls = true;
    });
    group?.toActiveSelection();
    canvas.discardActiveObject();
    canvas.requestRenderAll();
    historySaveAction(editor);
    refreshProjectCard();
    close();
  };

  const handleGroup = () => {
    const { canvas } = editor as FabricJSEditor;
    const selected = activeObject as FabricTypes.ActiveSelection;
    const group = selected.toGroup();
    group.set({
      subTargetCheck: true,
      centeredRotation: true,
    });
    group.off('mousedblclick');
    group.on('mousedblclick', handleSelectGroupItem(canvas));
    canvas.requestRenderAll();
    historySaveAction(editor);
    refreshProjectCard();
    close();
  };

  const handleCopy = (remove?: boolean) => {
    handleIndexChange('copied', pageIndexes.current);
    activeObject.clone((cloned: FabricTypes.Object) => {
      clipboard.setValue(cloned);
      if (remove) {
        editor.canvas.remove(activeObject);
      }
      close();
    }, EDITOR_PROPERTIES);
  };

  const handleAddCloneToCanvas = (object: ICanvasObject) => {
    const { canvas } = editor as FabricJSEditor;
    canvas.discardActiveObject();
    if (object.type === 'activeSelection') {
      object.canvas = canvas;
      object._objects?.forEach((obj) => {
        if (activeObject?.type === 'group') {
          (activeObject as FabricTypes.Group).addWithUpdate(obj);
          canvas.renderAll();
        } else {
          canvas.add(obj);
        }
      });
      object.setCoords();
    } else {
      handleSetDoubleClick(canvas, object);
      if (activeObject?.type === 'group') {
        // (activeObject as FabricTypes.Group).addWithUpdate(object);
        canvas.add(object);
        canvas.renderAll();
      } else {
        canvas.add(object);
      }
    }
    canvas.setActiveObject(object);
    canvas.requestRenderAll();
    historySaveAction(editor);
    refreshProjectCard();
    close();
  };

  const handlePaste = () => {
    const diff = pageIndexes.copied === pageIndexes.current ? 10 : 0;
    clipboard.value?.clone((clonedObj: ICanvasObject) => {
      clonedObj.set({
        left: position?.x ?? clonedObj.left + diff,
        top: position?.y ?? clonedObj.top + diff,
      });
      handleAddCloneToCanvas(clonedObj);
    }, EDITOR_PROPERTIES);
  };

  const handleClone = () => {
    activeObject.clone((clonedObj: ICanvasObject) => {
      clonedObj.set({
        left: clonedObj.left + 10,
        top: clonedObj.top + 10,
      });
      handleAddCloneToCanvas(clonedObj);
    }, EDITOR_PROPERTIES);
  };

  const handleDelete = () => {
    handleDeleteItemFromSavedObjects(activeObject as IObjectWithId).then(() => {
      const { canvas } = editor as FabricJSEditor;
      const objects = canvas.getActiveObjects();
      if (objects?.length) {
        objects.forEach((obj) => {
          canvas.remove(obj);
        });
      } else {
        canvas.remove(activeObject);
      }
      canvas.discardActiveObject();
      canvas.requestRenderAll();
      historySaveAction(editor);
      refreshProjectCard();
      close();
    });
  };

  return isOpen ? (
    <Wrapper light {...position}>
      {/* Копировать */}
      {activeObject && (
        <ToolbarBtn
          light
          onClick={() => handleCopy()}
          data-tooltip={t('action.copy') as string}
          data-tooltip-position="bottom"
        >
          <span className="icon-copy" />
        </ToolbarBtn>
      )}
      {/* Вставить */}
      <ToolbarBtn
        light
        onClick={handlePaste}
        data-tooltip={t('action.paste') as string}
        data-tooltip-position="bottom"
      >
        <span className="icon-past" />
      </ToolbarBtn>
      {activeObject && (
        <>
          {/* Дублировать */}
          <ToolbarBtn
            light
            onClick={handleClone}
            data-tooltip={t('action.double') as string}
            data-tooltip-position="bottom"
          >
            <span className="icon-double" />
          </ToolbarBtn>
          {/* Переместить */}
          <Popover
            position={'bottom'}
            className={popoverActive['layers'] ? 'active' : ''}
            setIsActive={handleSetPopoverActive('layers')}
            content={
              <ToolbarRow light>
                {MOVE_OPTIONS.map((key) => (
                  <ToolbarBtn
                    light
                    key={key}
                    data-tooltip={t(`move.${key}`) as string}
                    data-tooltip-position="bottom"
                    onClick={handleMoveObject(editor, key)}
                  >
                    <span className={`icon-layers-${key}`} />
                  </ToolbarBtn>
                ))}
              </ToolbarRow>
            }
          >
            <ToolbarBtn
              light
              active={popoverActive['layers']}
              data-tooltip={t('action.move') as string}
              data-tooltip-position="bottom"
            >
              <span className="icon-layers-forward" />
            </ToolbarBtn>
          </Popover>
          {/* Удалить */}
          <ToolbarBtn
            light
            onClick={handleDelete}
            data-tooltip={t('action.removeFromCanvas') as string}
            data-tooltip-position="bottom"
          >
            <span className="icon-trash" />
          </ToolbarBtn>
        </>
      )}
    </Wrapper>
  ) : null;
};

import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Components, {
  CustomCard,
  CustomButton,
  ChooseFolder,
  FoldersRow,
  PlusIcon,
  ButtonsWrap,
  FileName,
  CardContainer,
} from './files.style';
import { MenuList } from '../menu';
import { RightColumn } from '../my-projects/my-projects.style';
import { Row } from '../../components/grid.component';
import { Text } from '../../components/text.component';
import plus from '../../assets/icons/plus.svg';
import { useTranslation } from 'react-i18next';
import { Flex, FlexCol } from '../../components/flexbox.component';
import { Button } from '../../components/button.component';
import { Modal } from '../../components/modal.component';
import { FolderModalContent, MoveToFolderModalContent } from '../folders/folders.style';
import {
  getImages,
  saveImage,
  deleteFile,
  moveToFolder,
  moveToFolderFiles,
} from '../../requests/files.requests';
import { ICreateFolderRequest, IImages } from '../../interfaces/projects.interface';
import { createFolder, getFolders } from '../../requests/folder.requests';
import { recoverItem } from '../../requests/trash.requests';
import ImageListLoader from '../../components/loaders/image-list.component';
import Input from '../../components/input.component';
import FolderListLoader from '../../components/loaders/folder-list.component';
import SkeletonLoader from '../../components/skeleton-loader.component';
import CircleLoader2 from '../../components/circle-loader2';
import { resizeImage } from '../../utils/imageResize';
import PageHeader from '../../components/page-header.component';
import useDebounce from '../../hooks/use-debounce.hooks';
import { ASCSort, SortOption } from '../../interfaces/editor.interface';
import { FILE_SORT, FOLDER_SORT } from '../../constants/shared.constants';
import CardCheckboxOverlay from '../../components/card.checkbox.overlay';
import MultipleChoice from '../../components/multiple-choice.component';
import useMouseDown from '../../hooks/use-mouse-down.hooks';
import { useNotification } from '../../contexts/notification.context';
import PagePagination from '../../components/pagination/page-pagination';
import ModalPagination from '../../components/pagination/modal.pagination';
import useSortSearch from '../../hooks/use-sort-search.hooks';
import { calculateImageSize, calculateNewDimensions } from '../../utils/calculateImageSize';
import ModalHeader from '../../components/modal/modal.header';

export const FilesPage = () => {
  const { t } = useTranslation();
  const { setNotification, closeNotification } = useNotification();

  const [imageList, setImageList] = useState<IImages[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [chosenFolder, setChosenFolder] = useState<ICreateFolderRequest>();
  const [chosenImageId, setChosenImageId] = useState<number>();
  const [folderList, setFolderList] = useState<ICreateFolderRequest[]>([]);
  const [loadingImageList, setLoadingImageList] = useState<boolean>(true);
  const [folderModal, setFolderModal] = useState<boolean>(false);
  const [newFolderName, setNewFolderName] = useState<string>('');
  const [inputValue, setInputValue] = useState('');
  const [loadingFolderList, setLoadingFolderList] = useState<boolean>(false);
  const [showZoomModal, setShowZoomModal] = useState<boolean>(false);
  const [chosenImage, setChosenImage] = useState<IImages | undefined>(undefined);
  const [imageSize, setImageSize] = useState({ width: 0, height: 0 });
  const [imageSizeWithName, setImageSizeWithName] = useState({ width: 0, height: 0 });
  const [loadingImageId, setLoadingImageId] = useState<number | null>(null);
  const [loadingUploadedFiles, setLoadingUploadedFiles] = useState<number | undefined>(undefined);
  const [errMessage, setErrMessage] = useState<string>('');
  const [selectedOption, setSelectedOption] = useState<SortOption>(FILE_SORT[0]);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [totalElements, setTotalElements] = useState<number>(0);
  const [sortAsc, setSortAsc] = useState<ASCSort>('DESC');
  const [page, setPage] = useState<number>(0);
  const [errorCard, setErrorCard] = useState<string>();
  const [didMount, setDidMount] = useState(false);
  const [highlight, setHighlight] = useState<boolean>(false);
  const [highlightedFiles, setHighlightedFiles] = useState<number[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [folderTotalPages, setFolderTotalPages] = useState<number>(0);
  const [customCardOption, setCustomCardOption] = useState<boolean>(false);

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const searchValue = params.get('q');
  const debouncedSearchValue = useDebounce(searchValue as string, 500);
  const isRightMouseDown = useMouseDown();
  const navigate = useNavigate();

  const folderSort = useSortSearch(FOLDER_SORT[1]);

  const fileOptions = (file: IImages) => {
    return (
      <FlexCol
        gap={6}
        alignItems={'flex-start'}
        style={{ width: 'max-content', cursor: 'pointer' }}
      >
        <CustomButton
          onClick={() => {
            setShowModal(true);
            setChosenImageId(file.id);
            setChosenImage(file);
          }}
        >
          {t(`action.moveToFolder`)}
        </CustomButton>
        <CustomButton onClick={() => handleDeleteFile(file.id)}>{t(`action.toTrash`)}</CustomButton>
      </FlexCol>
    );
  };

  const handleUploadFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event?.target?.files?.length && event?.target?.files?.length > 0) {
      const files = Array.from(event.target.files);
      const resizedImagesPromises = [];

      for (const file of files) {
        const fileType = file.type;
        if (fileType === 'image/jpeg' || fileType === 'image/jpg' || fileType === 'image/png') {
          if (file.size <= 5 * 1024 * 1024) {
            resizedImagesPromises.push(resizeImage(file, 1680, 900));
          } else {
            setErrorCard(
              `Файл слишком большой (${(file.size / 1048576).toFixed(
                0,
              )} Мб). Максимальный размер: 5 Мб.`,
            );
            return;
          }
        } else {
          setErrorCard('Допустимые форматы: jpeg, png. Загрузите изображения с верным форматом.');
          return;
        }
      }

      Promise.all(resizedImagesPromises)
        .then((resizedFiles) => {
          return saveImage(resizedFiles, (progress) => {
            setLoadingUploadedFiles(progress);
          });
        })
        .then((data) => {
          setImageList([...data.list, ...imageList]);
          setLoadingUploadedFiles(undefined);
          setTotalElements(totalElements + files.length);
        })
        .catch((err) => {
          setNotification({
            text:
              `Произошла ошибка ${err.response.data.msg}` ||
              'Произошла ошибка, выберите другой файл',
            handleFunction: () => {
              closeNotification();
            },
            buttonText: 'Закрыть',
          });
        });
    }
  };

  const handleDeleteFile = (id: number) => {
    setChosenImageId(id);
    deleteFile(id).then(() => {
      setNotification({
        text: 'Вы перенесли файл в корзину',
        buttonText: 'Отменить',
        handleFunction: () => handleRecoverItem(id),
        handleClose: handleCloseNotification,
      });
      getImageList(debouncedSearchValue as string, page, sortAsc, selectedOption.value);
    });
  };

  const getImageList = (name?: string, page?: number, sortingType?: string, orderType?: string) => {
    setLoadingImageList(true);
    getImages(name, orderType, sortingType, page).then((res: any) => {
      setImageList(res.list);
      setTotalPages(res.totalPages);
      setTotalElements(res.size);
      setLoadingImageList(false);
    });
  };

  const handleChooseFolder = (folder?: ICreateFolderRequest) => {
    setChosenFolder(folder);
  };

  const handleMoveToFolder = (folder: ICreateFolderRequest) => {
    setIsLoading(true);
    setErrMessage('');
    if (highlight && folder.id) {
      moveToFolderFiles(highlightedFiles, folder.id)
        .then(() => {
          setHighlightedFiles([]);
          const count = highlightedFiles.length;
          setNotification({
            handleClose: handleCloseNotification,
            text: `Файл${count === 1 ? '' : 'ы'} помещены в папку ${
              newFolderName && `«${newFolderName}»`
            }`,
            handleFunction: () => {
              navigate('/folders/item', {
                state: { folderInfo: folder },
              });
              closeNotification();
            },
            buttonText: 'Перейти в папку',
            icon: 'folder',
          });
          getFoldersList();
          getImageList(debouncedSearchValue as string, page, sortAsc, selectedOption.value);
        })
        .catch((err) => {
          setErrMessage(err.response.data.msg);
        })
        .finally(() => {
          handleCloseModal();
          setIsLoading(false);
        });
    } else {
      moveToFolder(folder.id, chosenImageId)
        .then(() => {
          setHighlight(false);
          const text = newFolderName
            ? `Файл помещен в папку «${newFolderName}»`
            : 'Файл помещен в папку';

          setNotification({
            handleClose: handleCloseNotification,
            text,
            handleFunction: () => {
              navigate('/folders/item', {
                state: { folderInfo: folder },
              });
              closeNotification();
            },
            buttonText: 'Перейти в папку',
            icon: 'folder',
          });
          getFoldersList();
          getImageList(debouncedSearchValue as string, page, sortAsc, selectedOption.value);
        })
        .catch((err) => {
          setErrMessage(err.response.data.msg);
        })
        .finally(() => {
          handleCloseModal();
          setIsLoading(false);
        });
    }
  };

  const handleRecoverItem = (id?: number) => {
    recoverItem(id, 'IMAGE')
      .then(() => {
        closeNotification();
        getImageList(debouncedSearchValue as string, page, sortAsc, selectedOption.value);
      })
      .finally(() => setChosenImageId(0));
  };

  const handleCloseNotification = () => {
    closeNotification();
    setChosenImageId(0);
    setNewFolderName('');
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setFolderModal(false);
    setChosenImageId(0);
    setInputValue('');
    setChosenFolder(undefined);
  };

  useEffect(() => {
    if (sortAsc === 'ASC') setSortAsc('DESC');
    else
      didMount && getImageList(debouncedSearchValue as string, page, sortAsc, selectedOption.value);
  }, [selectedOption.value]);

  useEffect(() => {
    if (didMount && searchValue === null) {
      getImageList(debouncedSearchValue as string, page, sortAsc, selectedOption.value);
    }
  }, [didMount, debouncedSearchValue, page, sortAsc]);

  useEffect(() => {
    if (didMount && debouncedSearchValue !== null) {
      getImageList(debouncedSearchValue as string, page, sortAsc, selectedOption.value);
    }
  }, [debouncedSearchValue, sortAsc, page]);

  useEffect(() => setDidMount(true), []);

  const getFoldersList = () => {
    setLoadingFolderList(true);
    getFolders(
      undefined,
      folderSort.selectedOption.value,
      folderSort.sortAsc,
      folderSort.page,
      5,
    ).then((res) => {
      setFolderTotalPages(res.totalPages);
      folderSort.setHasMore(!(res.list.length < 24));
      setFolderList(res.list);
      setLoadingFolderList(false);
    });
  };

  useEffect(() => {
    getFoldersList();
  }, [folderSort.page, folderSort.sortAsc, folderSort.selectedOption.value]);

  useEffect(() => {
    if (errorCard) {
      setTimeout(() => {
        setErrorCard('');
      }, 5000);
    }
  }, [errorCard]);

  const handleCreateFolder = () => {
    setIsLoading(true);
    handleCloseModal();
    setNewFolderName(inputValue);
    createFolder({ folderName: inputValue })
      .then((result) => {
        if ((result as any).data) {
          handleMoveToFolder((result as any).data);
          setChosenFolder((result as any).data);
        }
      })
      .finally(() => {
        setIsLoading(false);
        getFoldersList();
      });
  };

  const handleImageClick = (imageId: number) => {
    setLoadingImageId(imageId);
    const img = new Image();
    img.src = `/api/project/load/image/png?imageId=${imageId}&imageSizeType=NORMAL`;
    img.onload = () => {
      const value = calculateImageSize(img);
      setImageSize(value);
      setShowZoomModal(true);
      setLoadingImageId(null);
    };
  };

  useEffect(() => {
    const result = calculateNewDimensions(imageSize.width, imageSize.height);
    setImageSizeWithName(result);
  }, [imageSize.width, imageSize.height]);

  return (
    <Components.Wrapper>
      <MenuList />
      <RightColumn>
        <PageHeader
          pageTitle={'Все файлы'}
          sortType="fileSort"
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
          setSortOrder={setSortAsc}
          sortOrder={sortAsc}
          highlight={highlight}
          setHighlight={setHighlight}
          count={totalElements}
          isLoading={loadingImageList}
        />
        <Row adaptive>
          {loadingImageList ? (
            <ImageListLoader />
          ) : (
            <>
              <Components.NewCardButton isScaled>
                <input
                  id="bgImageInput"
                  style={{ display: 'none' }}
                  type="file"
                  multiple={true}
                  accept={'.jpeg, .jpg, .png'}
                  onChange={handleUploadFile}
                />
                <label htmlFor="bgImageInput" style={{ cursor: 'pointer' }}>
                  <Text fontSize="23px" color="gray" strong>
                    Загрузить файл
                  </Text>
                  <img src={plus} alt="plus" />
                </label>
              </Components.NewCardButton>
              {imageList.length === 0 && debouncedSearchValue && (
                <>
                  Тут по вашему запросу ничего не найдено. Посмотрите в других вкладках или
                  повторите попытку, набрав другие слова.
                </>
              )}
              {errorCard && (
                <>
                  <CardContainer bgColor="#C7205233" style={{ padding: '20px' }}>
                    <FlexCol justifyContent="space-between" style={{ height: '100%' }}>
                      <div
                        className="icon-alert-circle"
                        style={{ fontSize: '20px', color: '#C72052' }}
                      ></div>

                      <div style={{ width: '100%', color: '#C72052' }}>{errorCard}</div>
                    </FlexCol>
                  </CardContainer>
                </>
              )}
              {loadingUploadedFiles && (
                <CardContainer>
                  <ImageListLoader count={1} width={loadingUploadedFiles} borderRadius="0px" />
                </CardContainer>
              )}
              {imageList.map((item: IImages) => (
                <CustomCard
                  onPopoverActiveChange={setCustomCardOption}
                  active={highlightedFiles.includes(item.id)}
                  highlight={highlight}
                  cursor={customCardOption ? 'cursor' : 'zoom-in'}
                  key={item.id}
                  customOptions={!highlight && fileOptions(item)}
                  onClick={() => {
                    if (!highlight && !customCardOption) {
                      handleImageClick(item.imageId);
                      setChosenImage(item);
                    }
                  }}
                  {...(!customCardOption && {
                    'data-tooltip': item.imageName + '.' + item.imageType?.toLowerCase(),
                  })}
                  {...(!customCardOption && { 'data-tooltip-position': 'bottom' })}
                >
                  <CardCheckboxOverlay
                    cardId={item.id}
                    highlight={highlight}
                    highlightedCards={highlightedFiles}
                    setHighlightedCard={setHighlightedFiles}
                    isRightMouseDown={isRightMouseDown}
                  />
                  <div className="photo-container" style={{ position: 'relative' }}>
                    <img
                      src={`/api/project/load/image/png?imageId=${item.imageId}&imageSizeType=NORMAL`}
                      alt="img"
                      style={{
                        filter: loadingImageId === item.imageId ? 'blur(5px)' : 'none',
                      }}
                    />
                    {loadingImageId === item.imageId && (
                      <div
                        style={{
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          transform: 'translate(-50%, -50%)',
                        }}
                      >
                        <CircleLoader2 />
                      </div>
                    )}
                  </div>
                </CustomCard>
              ))}
            </>
          )}
        </Row>
        <PagePagination totalCount={totalPages} page={page} setPage={setPage} />
      </RightColumn>

      {showZoomModal && chosenImage && (
        <Modal
          contentPadding="0"
          onClose={() => {
            setShowZoomModal(false);
            setChosenImage(undefined);
          }}
          width={`calc(${imageSizeWithName.width}px + 40px)`}
          height={`${imageSize.height}px`}
        >
          <FlexCol justifyContent="center" alignItems="center" gap={20}>
            <FileName>
              {chosenImage && chosenImage.imageName + '.' + chosenImage.imageType?.toLowerCase()}
            </FileName>
            {chosenImage ? (
              <img
                style={{
                  height: imageSizeWithName.height,
                  width: imageSizeWithName.width,
                }}
                src={`/api/project/load/image/png?imageId=${chosenImage.imageId}&imageSizeType=NORMAL`}
              />
            ) : (
              <CircleLoader2 />
            )}
          </FlexCol>
        </Modal>
      )}

      {showModal && (
        <Modal onClose={handleCloseModal} width={folderModal ? '380px' : '786px'}>
          {folderModal ? (
            <FolderModalContent>
              <Text fontSize="23px" strong>
                Новая папка
              </Text>
              <Input
                label="Название папки"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                showButtons={false}
                maxlength={100}
              />
              <ButtonsWrap>
                <Button
                  btnStyle={'cancel'}
                  style={{ padding: '0' }}
                  onClick={() => {
                    handleCloseModal();
                  }}
                >
                  Отмена
                </Button>
                <Button
                  disabled={!inputValue}
                  icon={'folder-plus'}
                  onClick={handleCreateFolder}
                  isLoading={isLoading}
                >
                  Создать
                </Button>
              </ButtonsWrap>
            </FolderModalContent>
          ) : (
            <MoveToFolderModalContent>
              <ModalHeader sort={folderSort} type={'file'} isLoading={loadingFolderList} />
              <FoldersRow gutter={0}>
                {loadingFolderList ? (
                  <FolderListLoader withoutFolder count={2} />
                ) : folderList.length !== 0 ? (
                  <>
                    <Components.NewCardButton onClick={handleCloseModal}>
                      <Text fontSize="23px" color="gray" strong>
                        Без папки
                      </Text>
                      <Text fontSize="14px" color="gray" strong>
                        Будет доступен на странице «Все файлы»
                      </Text>
                    </Components.NewCardButton>
                    {folderList.map((folder: ICreateFolderRequest) => (
                      <ChooseFolder
                        key={folder.id}
                        onClick={() => handleChooseFolder(folder)}
                        active={
                          folder.id === chosenFolder?.id ||
                          (chosenImage && !chosenFolder?.id && folder.id === chosenImage.folderId)
                        }
                      >
                        <Text breakByWord fontSize="23px" strong truncate>
                          {folder.folderName}
                        </Text>
                        <Text fontSize="14px" color="gray">
                          {`${folder.itemsCount} ${
                            folder.itemsCount === 0 ? 'элементов' : 'элемента'
                          }`}
                        </Text>
                      </ChooseFolder>
                    ))}
                  </>
                ) : (
                  <>
                    <Components.NewCardButton
                      onClick={handleCloseModal}
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Text fontSize="1.4375rem" color="gray" strong>
                        Без папки
                      </Text>
                      <Text fontSize="0.875rem" color="gray" strong>
                        Будет доступен на странице «Все файлы»
                      </Text>
                    </Components.NewCardButton>
                    {folderList.length === 0 && (
                      <Components.NewCardButton onClick={() => setFolderModal(true)}>
                        <Text fontSize="1.4375rem" color="gray" strong>
                          Создать папку
                        </Text>
                        <PlusIcon>
                          <img src={plus} alt="plus" />
                        </PlusIcon>
                      </Components.NewCardButton>
                    )}
                    {folderList.map((folder: ICreateFolderRequest) => (
                      <ChooseFolder
                        key={folder.id}
                        onClick={() => handleChooseFolder(folder)}
                        active={folder.id === chosenFolder?.id}
                      >
                        <Text fontSize="1.4375rem" strong truncate breakByWord>
                          {folder.folderName}
                        </Text>
                        <Text fontSize="0.875rem" color="gray">
                          {`${folder.itemsCount} ${
                            folder.itemsCount === 0 ? 'элементов' : 'элемента'
                          }`}
                        </Text>
                      </ChooseFolder>
                    ))}
                  </>
                )}
              </FoldersRow>
              {loadingFolderList ? (
                <Flex justifyContent="space-between" alignItems="center">
                  <SkeletonLoader width="123px" height="22px" />
                  <Flex gap={20} alignItems="center">
                    <SkeletonLoader width="78px" height="22px" />
                    <SkeletonLoader
                      width="172px"
                      height="50px"
                      borderRadius="16px"
                      baseColor="#00000033"
                    />
                  </Flex>
                </Flex>
              ) : (
                folderList.length !== 0 && (
                  <div>
                    <ModalPagination
                      page={folderSort.page}
                      setPage={folderSort.setPage}
                      totalCount={folderTotalPages}
                    />
                    <Components.ButtonsWrap>
                      <Button
                        btnStyle={'cancel'}
                        icon={'folder-plus'}
                        onClick={() => {
                          setFolderModal(true);
                        }}
                        style={{ padding: 0 }}
                      >
                        Новая папка
                      </Button>
                      <div style={{ display: 'flex' }}>
                        <Button btnStyle={'cancel'} onClick={handleCloseModal}>
                          Отменить
                        </Button>
                        <Button
                          icon={'folder'}
                          onClick={() => chosenFolder && handleMoveToFolder(chosenFolder)}
                          disabled={!chosenFolder}
                          isLoading={isLoading}
                        >
                          Поместить
                        </Button>
                      </div>
                    </Components.ButtonsWrap>
                  </div>
                )
              )}
            </MoveToFolderModalContent>
          )}
        </Modal>
      )}
      {highlight && (
        <MultipleChoice
          refreshRequest={() =>
            getImageList(debouncedSearchValue as string, page, sortAsc, selectedOption.value)
          }
          type="file"
          choiceList={imageList}
          setHighlightedIds={setHighlightedFiles}
          highlightedList={highlightedFiles}
          setShowModal={setShowModal}
          folderId={chosenFolder?.id}
          setHighlight={setHighlight}
          itemType="IMAGE"
        />
      )}
    </Components.Wrapper>
  );
};

import React, { useState, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import { ASCSort } from '../interfaces/editor.interface';

const Dropdown = styled.div<{ disabled: boolean }>`
  position: relative;
  display: flex;
  gap: 8px;
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  z-index: 1001;
`;

const DropdownContent = styled.div<{
  isOpen: boolean;
  icons?: boolean;
  width?: string;
  position?: string;
}>`
  display: ${(props) => (props.isOpen ? 'block' : 'none')};
  position: absolute;
  background-color: #f9f9f9;
  box-shadow: 0px 6px 20px 0px #00000026;
  z-index: 1;
  border-radius: 16px;
  overflow: hidden;
  width: ${(p) => (p.width ? p.width : 'fit-content')};
  top: ${(p) => (p.icons ? '42px' : '29px')};
  right: ${(p) => (p.position === 'right' ? '0' : 'auto')};
  left: ${(p) => (p.position === 'left' ? '0' : 'auto')};
`;

const DropdownItem = styled.div<{ active: boolean; icons?: boolean; fontSize?: string }>`
  padding: 10.5px 15px;
  text-decoration: none;
  display: block;
  cursor: pointer;
  &:hover {
    background-color: #6620c766;
    color: #6620c7;
  }
  background-color: ${(props) => (props.active ? '#6620c766' : 'none')};
  color: ${(props) => (props.active ? '#6620c7' : '#000')};
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '14px')};
  font-weight: 600;

  ${(p) =>
    p.icons &&
    css`
      border-bottom: 1px solid #e5e5e5;
      &:last-child {
        border-bottom: none;
      }
    `}
`;

const SelectedOption = styled.div<{ disabled: boolean; selectionFontSize?: string }>`
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  font-size: ${({ selectionFontSize }) => (selectionFontSize ? selectionFontSize : '14px')};
  font-weight: 600;
  line-height: 19px;
  color: ${({ disabled }) => (disabled ? 'gray' : '#000')};
`;

const SelectOptionWrapper = styled.div<{
  gap: number;
  icons?: boolean;
  active?: boolean;
  disabled?: boolean;
}>`
  display: flex;
  gap: ${(props) => props.gap}px;
  align-items: center;
  cursor: pointer;

  ${(props) =>
    props.disabled &&
    css`
      cursor: not-allowed !important;
    `}

  ${(props) =>
    props.icons &&
    css`
      padding: 6px 10px;
      border-radius: 8px;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%), #fff;
    `}

  ${(props) =>
    props.active &&
    props.icons &&
    css`
      color: #6620c7;
      background: linear-gradient(0deg, rgba(102, 32, 199, 0.4) 0%, rgba(102, 32, 199, 0.4) 100%),
        #fff;
    `}
`;

const PlusIcon = styled.span`
  font-size: 20px;
  transform: rotate(45deg);
  color: rgba(102, 32, 199, 0.4);
`;

const ChevronDownIcon = styled.span<{ disabled: boolean; iconFontSize?: string }>`
  font-size: ${({ iconFontSize }) => (iconFontSize ? iconFontSize : '20px')};
  color: ${({ disabled }) => (disabled ? 'gray' : '#000')};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;

type SortDropdownOptions = {
  title: string;
  value: string;
  icon?: string;
};

const SortDropdown: React.FC<{
  selection: SortDropdownOptions[];
  selectedOption: string | SortDropdownOptions;
  setSelectedOption: ({ title, value }: { title: string; value: string }) => void;
  sortOrder?: string;
  setSortOrder?: (sortAsc: ASCSort) => void;
  icons?: boolean;
  width?: string;
  disabled?: boolean;
  fontSize?: string;
  selectionFontSize?: string;
  iconFontSize?: string;
  position?: string;
}> = ({
  selection,
  selectedOption,
  setSelectedOption,
  sortOrder,
  setSortOrder,
  icons,
  width,
  disabled = false,
  fontSize,
  selectionFontSize,
  iconFontSize,
  position,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (title: string, value: string) => {
    setSelectedOption({ title, value });
    setIsOpen(false);
  };

  return (
    <Dropdown ref={dropdownRef} disabled={disabled}>
      <SelectOptionWrapper
        gap={4}
        icons={icons}
        active={(selectedOption as SortDropdownOptions).value !== ''}
        disabled={disabled}
      >
        <SelectedOption
          onClick={() => !disabled && toggleDropdown()}
          disabled={disabled}
          selectionFontSize={selectionFontSize}
        >
          {!icons ? (selectedOption as string) : (selectedOption as SortDropdownOptions).title}
        </SelectedOption>
        {(selectedOption as SortDropdownOptions).value !== '' && icons ? (
          <PlusIcon
            className="icon-plus"
            onClick={() => {
              !disabled && setSelectedOption({ title: selection[0].title, value: '' });
            }}
          />
        ) : (
          <ChevronDownIcon
            disabled={disabled}
            onClick={() => !disabled && toggleDropdown()}
            className="icon-chevron-down"
            iconFontSize={iconFontSize}
          />
        )}
      </SelectOptionWrapper>

      <DropdownContent position={position} isOpen={isOpen} icons={icons} width={width}>
        {selection.map(
          (option) =>
            option.value && (
              <DropdownItem
                fontSize={fontSize}
                active={
                  !icons
                    ? option.title === selectedOption
                    : option.title === (selectedOption as SortDropdownOptions).title
                }
                key={option.value}
                onClick={() => !disabled && handleOptionClick(option.title, option.value)}
                icons={icons}
              >
                <div style={{ display: 'flex', alignItems: 'center', gap: '7px' }}>
                  {option.icon && <img src={option.icon} alt={option.icon} />}
                  {option.title}
                </div>
              </DropdownItem>
            ),
        )}
      </DropdownContent>
      {sortOrder && (
        <div data-tooltip-wrapped={'Сменить порядок'} data-tooltip-position="left-bottom-nowrap">
          <div
            style={{
              cursor: !disabled ? 'pointer' : 'not-allowed',
              color: !disabled ? '#000' : 'gray',
              fontSize: iconFontSize ? iconFontSize : '16px',
            }}
            onClick={() => {
              !disabled && setSortOrder && setSortOrder(sortOrder === 'ASC' ? 'DESC' : 'ASC');
            }}
            className={`icon-arrow-${sortOrder === 'ASC' ? 'down' : 'up'}`}
          ></div>
        </div>
      )}
    </Dropdown>
  );
};

export default SortDropdown;

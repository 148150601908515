import React, { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { fabric } from 'fabric';
import FabricTypes from 'fabric/fabric-impl';

import TagInput from '../../tag-input.component';
import { Flex, FlexCol } from '../../flexbox.component';
import ToggleSwitch from '../../toggle.component';
import { Button } from '../../button.component';
import Tags from '../../tags.component';
import { IBasicEditorProps } from '../../../interfaces/editor.interface';
import { InfoGraphicRequest } from '../../../requests/infographic.request';
import { TCreateInfoGraphic } from '../../../interfaces/infographics.interface';
import { IModalOptions } from '../../../interfaces/modal.interface';
import { infographic_notification } from '../../../constants/infographic.constants';
import { ModalTitle, FooterModal, ModalDescription } from './infographic.style';
import { useNotification } from '../../../contexts/notification.context';
import { useShared } from '../../../contexts/shared.context';
import { useUserContext } from '../../../contexts/user.context';

const InfographicTool: React.FC<IBasicEditorProps & IModalOptions> = ({
  activeObject,
  handleCancel,
}) => {
  const { t } = useTranslation();
  const { setNotification } = useNotification();
  const { setRecentTemplateIds } = useShared();
  const { userData } = useUserContext();

  const isDesigner = userData?.role === 'EDITOR';

  const [enableLayout, setEnableLayout] = useState(false);
  const [infographic, setInfoGraphic] = useState<TCreateInfoGraphic>({
    accessStatus: 'PRIVATE',
    details: [],
    image: {} as File,
    keyWords: [],
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const createImageFile = (canvas: fabric.Canvas, activeObjects: fabric.Object[]) => {
    const imageDataUrl = canvas.toDataURL({
      format: 'png',
    });

    const infographic_id = Math.random().toString(16).slice(2);
    fetch(imageDataUrl)
      .then((res) => res.blob())
      .then((blob) => {
        const file = new File([blob], `infographic_${infographic_id}.jpeg`, {
          type: 'image/jpeg',
        });

        const groupObjects = activeObjects.map((obj) => obj.toObject());
        setInfoGraphic({ ...infographic, image: file, details: groupObjects });
      });
  };

  const handleObject = (object: FabricTypes.Object) => {
    const width = (object.width ?? 0) * (object.scaleX ?? 1);
    const height = (object.height ?? 0) * (object.scaleY ?? 1);

    const tempCanvas = new fabric.Canvas(null, { width, height });
    const clonedObject = fabric.util.object.clone(object);
    clonedObject.set({
      left: width / 2,
      top: height / 2,
      originX: 'center',
      originY: 'center',
    });
    tempCanvas.add(clonedObject);
    tempCanvas.renderAll();

    createImageFile(tempCanvas, [object]);
  };

  const handleCreateInfographic = (object: FabricTypes.Object) => {
    if (object instanceof fabric.Group || object instanceof fabric.ActiveSelection) {
      const groupWidth = (object.width ?? 0) * (object.scaleX ?? 1);
      const groupHeight = (object.height ?? 0) * (object.scaleY ?? 1);

      const tempCanvas = new fabric.Canvas(null, {
        width: groupWidth,
        height: groupHeight,
      });
      object.getObjects().forEach((obj) => {
        obj.set({
          left: obj.left ?? 0,
          top: obj.top ?? 0,
        });
        tempCanvas.add(obj);
      });
      tempCanvas.renderAll();

      createImageFile(tempCanvas, object.getObjects());
    } else {
      handleObject(object);
    }
  };

  useEffect(() => {
    if (!activeObject) return;
    const isGroup =
      activeObject instanceof fabric.Group || activeObject instanceof fabric.ActiveSelection;

    if (isGroup) {
      const groupLeft = activeObject.left ?? 0;
      const groupTop = activeObject.top ?? 0;
      const groupWidth = activeObject.width ?? 0;
      const groupHeight = activeObject.height ?? 0;
      const groupScaleX = activeObject.scaleX ?? 1;
      const groupScaleY = activeObject.scaleY ?? 1;

      const groupCenterX = groupLeft + groupWidth / 2;
      const groupCenterY = groupTop + groupHeight / 2;

      const tempCanvas = new fabric.Canvas(null, { width: 1800, height: 2400 });

      activeObject.getObjects().forEach((obj) => tempCanvas.add(obj));

      const imageDataUrl = tempCanvas.toDataURL({
        format: 'png',
        left: groupLeft,
        top: groupTop,
        width: groupWidth,
        height: groupHeight,
      });

      const infographic_id = Math.random().toString(16).slice(2);
      fetch(imageDataUrl)
        .then((res) => res.blob())
        .then((blob) => {
          const file = new File([blob], `infographic_${infographic_id}.jpeg`, {
            type: 'image/jpeg',
          });

          const groupObjects = activeObject.getObjects().map((obj) => {
            const objLeft = obj.left ?? 0;
            const objTop = obj.top ?? 0;

            const absoluteLeft = groupCenterX + objLeft * groupScaleX;
            const absoluteTop = groupCenterY + objTop * groupScaleY;

            const absoluteCoords = fabric.util.object.clone(obj);
            absoluteCoords.set({
              left: absoluteLeft,
              top: absoluteTop,
            });

            return absoluteCoords.toObject();
          });

          setInfoGraphic({ ...infographic, image: file, details: groupObjects });
        });
    } else
      activeObject.clone((cloned: FabricTypes.Object) => {
        handleCreateInfographic(cloned);
      });
  }, [activeObject]);

  const handleChangeToggle = (e: ChangeEvent<HTMLInputElement>) => {
    setEnableLayout(e.target.checked);
    setInfoGraphic((prev) => ({
      ...prev,
      accessStatus: e.target.checked ? 'PUBLIC' : 'PRIVATE',
    }));
  };

  const handleRemove = (index: number) => {
    setInfoGraphic((prev) => ({
      ...prev,
      keyWords: prev.keyWords.filter((_, i) => i !== index),
    }));
  };

  const handleAddInfoGraphic = async () => {
    setIsLoading(true);
    await InfoGraphicRequest.createInfoGraphic(infographic)
      .then((item) => {
        setRecentTemplateIds({ ids: [item.data.id], type: 'infographics' });
        infographic.accessStatus === 'PUBLIC'
          ? setNotification({ text: infographic_notification.public })
          : setNotification({ text: infographic_notification.private });
      })
      .catch(() =>
        setNotification({
          text: infographic_notification.error,
          errorNotification: true,
        }),
      )
      .finally(() => {
        setIsLoading(false);
        handleCancel && handleCancel();
      });
  };

  return (
    <FlexCol gap={20}>
      <FlexCol gap={20}>
        <ModalTitle>{t('editor.createInfographic')}</ModalTitle>
        <ModalDescription>
          Добавьте ключевые слова. Правильные ключевые слова позволят вам и другим пользователям
          легко найти инфографику с помощью функции поиска. Слова должно буквально соответствовать
          содержанию.
        </ModalDescription>
      </FlexCol>
      <TagInput setInfoGraphic={setInfoGraphic} />
      <Flex wrapItems gap={5} style={{ maxHeight: '120px', overflowY: 'auto' }}>
        {infographic.keyWords.map((tag, index) => (
          <Tags tag={tag} key={index} handleRemove={() => handleRemove(index)} />
        ))}
      </Flex>
      {isDesigner && (
        <ToggleSwitch
          checked={enableLayout}
          onChange={handleChangeToggle}
          label={'editor.infographic.publicAccess'}
        />
      )}
      <FooterModal>
        <Flex>
          <Button
            btnStyle={'cancel'}
            onClick={() => {
              if (handleCancel) {
                handleCancel();
              }
            }}
          >
            {t('action.cancel')}
          </Button>
          <Button
            icon="plus"
            iconPosition={undefined}
            disabled={infographic.keyWords.length === 0}
            onClick={handleAddInfoGraphic}
            isLoading={isLoading}
          >
            Добавить
          </Button>
        </Flex>
      </FooterModal>
    </FlexCol>
  );
};

export default InfographicTool;

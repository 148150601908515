import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from './components/header';
import {
  DeviceType,
  ItemType,
  MarketPlaceType,
  OzonType,
  ViewType,
  allDeviceType,
  allMarketplaceType,
  allViewType,
  ozonTypes,
} from '../../interfaces/preview-image.interface';
import ProductPagePC from './product-page-PC';
import ImageListPage from './image-list-loaders';
import api from '../../requests/api';
import ProductPageMobile from './product-images-mobile';
import ImageListMobile from './image-list-mobile';

const PreviewImagePage: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const projectInfoFromState = location.state?.projectInfo || null;
  const projectInfoImage = location.state?.image || null;

  useEffect(() => {
    if (!projectInfoFromState) {
      navigate(location.pathname.replace('/item', ''));
    }
  }, [projectInfoFromState, location.pathname]);

  if (!projectInfoFromState) return null;

  const [projectInfo] = useState(projectInfoFromState);
  const [marketPlace, setMarketPlace] = useState<MarketPlaceType>('Wildberries');
  const [device, setDevice] = useState<DeviceType>('ПК');
  const [view, setView] = useState<ViewType>('Страница товаров');
  const [ozonType, setOzonType] = useState<OzonType>('Тип 1');
  const [Images, setImages] = useState<{ [key: number]: string }>({});
  const [loadingImages, setLoadingImages] = useState<boolean>(false);
  const [yandexType, setYandexType] = useState<OzonType>('Тип 1');
  const [canvasRatio, setCanvasRatio] = useState<'default' | 'square'>('default');

  const handleMarketPlaceChange = (value: ItemType) => setMarketPlace(value as MarketPlaceType);
  const handleDeviceChange = (value: ItemType) => setDevice(value as DeviceType);
  const handleViewChange = (value: ItemType) => setView(value as ViewType);
  const handleOzonTypeChange = (value: OzonType) => setOzonType(value as OzonType);
  const handleYandexTypeChange = (value: OzonType) => setYandexType(value as OzonType);

  const headerItems = [
    {
      items: allMarketplaceType,
      activeItem: marketPlace,
      onItemSelect: handleMarketPlaceChange,
    },
    {
      items: allDeviceType,
      activeItem: device,
      onItemSelect: handleDeviceChange,
    },
    {
      items: allViewType,
      activeItem: view,
      onItemSelect: handleViewChange,
    },
  ];

  const ozonHeaderItem = {
    items: ozonTypes,
    activeItem: ozonType,
    onItemSelect: handleOzonTypeChange,
  };

  const yandexHeaderItem = {
    items: ozonTypes,
    activeItem: yandexType,
    onItemSelect: handleYandexTypeChange,
  };

  useEffect(() => {
    const fetchAllImages = async () => {
      const images: { [key: number]: string } = {};
      setLoadingImages(true);
      for (const imageDetail of projectInfo.projectItems) {
        if (imageDetail.modifiedImageId !== 0 && imageDetail.modifiedImageId) {
          const res = await api.get(
            `/project/load/image/png?imageId=${imageDetail.modifiedImageId}&imageSizeType=NORMAL`,
            {
              responseType: 'arraybuffer',
            },
          );

          const base64 = btoa(
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            new Uint8Array(res).reduce((data, byte) => data + String.fromCharCode(byte), ''),
          );
          images[imageDetail.modifiedImageId] = `data:image/png;base64,${base64}`;
        }
      }
      setImages(images);
      setLoadingImages(false);
    };
    fetchAllImages();
  }, [projectInfo.projectItems]);

  useEffect(() => {
    if (location.state.projectInfo.details.canvasRatio) {
      setCanvasRatio(location.state.projectInfo.details.canvasRatio);
    }
  }, [location]);

  return (
    <div
      style={{
        background: '#fff',
        height: view === 'Плитка' && device === 'ПК' ? '100%' : '100vh',
      }}
    >
      <Header
        headerItems={headerItems}
        projectId={projectInfo.id}
        ozonHeaderItem={ozonHeaderItem}
        yandexHeaderItem={yandexHeaderItem}
        marketPlace={marketPlace}
        view={view}
        device={device}
        projectInfo={projectInfo}
        projectInfoImage={projectInfoImage}
      />
      {view === 'Страница товаров' ? (
        device === 'ПК' ? (
          <ProductPagePC
            imagesSrc={Images}
            imageDetails={projectInfo.projectItems}
            marketPlace={marketPlace}
            loadingImages={loadingImages}
            canvasRatio={canvasRatio}
          />
        ) : (
          <ProductPageMobile
            imagesSrc={Images}
            imageDetails={projectInfo.projectItems}
            marketPlace={marketPlace}
            loadingImages={loadingImages}
            ozonType={ozonType}
            yandexType={yandexType}
            canvasRatio={canvasRatio}
          />
        )
      ) : device === 'ПК' ? (
        <ImageListPage
          imageSrc={Images}
          marketPlace={marketPlace}
          loadingImages={loadingImages}
          imageDetails={projectInfo.projectItems}
          canvasRatio={canvasRatio}
        />
      ) : (
        <ImageListMobile
          imagesSrc={Images}
          imageDetails={projectInfo.projectItems}
          marketPlace={marketPlace}
          loadingImages={loadingImages}
          ozonType={ozonType}
          yandexType={yandexType}
          canvasRatio={canvasRatio}
        />
      )}
    </div>
  );
};

export default PreviewImagePage;

import styled, { css, keyframes } from 'styled-components';
import { ToolbarBtn } from '../toolbar/toolbar.style';

const fade = keyframes`
  0% { opacity: 0 }
  100% { opacity: 1 }
`;

export const ToolWrapper = styled.div`
  position: relative;
  padding: 0.625rem 0.9375rem;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
  border-radius: 1rem !important;
  background: ${(p) => p.theme.white};
`;

export const StrokeToolWrapper = styled(ToolWrapper)`
  padding: 0;
  & ${ToolbarBtn} {
    border-top-right-radius: 1rem;
  }
  & > div {
    &:not(:last-child) {
      border-bottom: 1px solid ${(p) => p.theme.lightGray2};
    }
    &:last-child {
      & > div {
        &:first-child {
          border-bottom-left-radius: 1rem !important;
        }
        &:last-child {
          border-bottom-right-radius: 1rem !important;
        }
      }
    }
  }
`;

export const ChildPropertiesWrapper = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  padding-top: 0.625rem;
`;

export const Property = styled.div<{ active?: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(p) => (p.active ? p.theme.lightPrimary : p.theme.white)};
  color: ${(p) => (p.active ? p.theme.primary : p.theme.black)};

  ${(p) =>
    p.active &&
    css`
      &::after {
        opacity: 0;
      }
      & + * {
        &::after {
          opacity: 0;
        }
      }
    `}

  border: 0;
  padding: 0.9375rem;
  animation: ${fade} 0.125s linear;
  border-radius: 0 !important;
  cursor: pointer;
  & > span[class^='icon-'] {
    font-size: 1.25rem;
  }
  &:not(:first-child) {
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 9px;
      display: block;
      height: 1px;
      width: calc(100% - 18px);
      background: ${(p) => p.theme.lightGray2};
    }
  }
  &:first-child {
    border-top-left-radius: 1rem !important;
    border-top-right-radius: 1rem !important;
    padding-left: 0.9375rem;
  }
  &:last-child {
    border-bottom-left-radius: 1rem !important;
    border-bottom-right-radius: 1rem !important;
    padding-right: 0.9375rem;
  }

  &:hover {
    background: ${(p) => p.theme.lightPrimary};
    color: ${(p) => p.theme.primary};
    &::after {
      opacity: 0;
    }
    & + * {
      &::after {
        opacity: 0;
      }
    }
  }
`;

export const _IncDecBtn = styled.button.attrs({ type: 'button' })`
  border: 0;
  background: transparent;
  font-size: 1.0625rem;
  padding: 1em 1em 1rem;
  cursor: pointer;
  color: ${(p) => p.theme.black};
  &:hover {
    color: ${(p) => p.theme.primary};
  }
  &:first-child {
    padding-left: 0.5rem;
  }
  &:last-child {
    padding-right: 0.5rem;
  }
`;

export const DecrementBtn = styled(_IncDecBtn).attrs({ className: 'icon-minus' })``;
export const IncrementBtn = styled(_IncDecBtn).attrs({ className: 'icon-plus' })``;

export const StyleButton = styled.div<{ active?: boolean; disableHover?: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(p) => (p.active ? p.theme.lightPrimary : p.theme.white)};
  color: ${(p) => (p.active ? p.theme.primary : p.theme.black)};
  border: 0;
  padding: 0.9375rem;
  animation: ${fade} 0.125s linear;
  border-radius: 0;
  max-width: 100px;

  & > span[class^='icon-'] {
    font-size: 1.25rem;
  }
  ${(p) =>
    !p.disableHover &&
    css`
      &:hover {
        cursor: pointer;
        background: ${(p) => p.theme.lightPrimary};
        color: ${(p) => p.theme.primary};
        &::after {
          opacity: 0;
        }
        & + * {
          &::after {
            opacity: 0;
          }
        }
      }
    `}
`;

export const Properties = styled(ToolWrapper)`
  display: flex;
  flex-direction: column;
  padding: 0;
  & > * {
    &:first-child {
      border-top-left-radius: 1rem;
      border-top-right-radius: 1rem;
      & ${StyleButton} {
        &:first-child {
          border-top-left-radius: 1rem;
        }
        &:last-child {
          border-top-right-radius: 1rem;
        }
      }
    }
    &:last-child {
      border-bottom-left-radius: 1rem;
      border-bottom-right-radius: 1rem;
      & ${StyleButton} {
        &:first-child {
          border-bottom-left-radius: 1rem;
        }
        &:last-child {
          border-bottom-right-radius: 1rem;
        }
      }
    }
  }
`;

export const TextBgIcon = styled.span.attrs({ className: 'icon-pencil' })<{ color?: string }>`
  position: relative;
  font-size: 0.9375rem;
  line-height: normal;
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 3px;
    border: 1px solid ${(p) => (p.color !== 'transparent' ? p.color : 'currentColor')};
    background: ${(p) => p.color ?? 'transparent'};
  }
`;

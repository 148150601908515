import React, { createContext, useContext } from 'react';
import FabricTypes from 'fabric/fabric-impl';

interface IClipboardContext {
  value: FabricTypes.Object | null;
  setValue: (object: FabricTypes.Object) => void;
}

interface IProps {
  children: React.ReactNode;
}

const ClipboardContext = createContext<IClipboardContext>({
  value: null,
  setValue: () => null,
});
export const useClipboard = (): IClipboardContext => useContext(ClipboardContext);

export const ClipboardProvider: React.FC<IProps> = ({ children }: IProps) => {
  const [value, setValue] = React.useState<FabricTypes.Object | null>(null);

  return (
    <ClipboardContext.Provider value={{ value, setValue }}>{children}</ClipboardContext.Provider>
  );
};

import api from './api';
import { FabricJSEditor } from 'fabricjs-react';
import {
  IDataSuccessResponse,
  ISuccessResponse,
  IProject,
  IProjectItem,
} from '../interfaces/projects.interface';
import { INITIAL_PROJECT, INITIAL_PROJECT_ITEM } from '../constants/editor.constants';
import { SortOption } from '../hooks/use-sort-search.hooks';

export const loadImage = (
  imageId: number,
  imageSizeType: 'NORMAL' | 'ICON' = 'ICON',
): Promise<string> => {
  const params = { imageId, imageSizeType };
  return api
    .get<any, Blob>(`project/load/image/png`, { params, responseType: 'blob' })
    .then((res) => URL.createObjectURL(res));
};

export const loadFullImage = (imageId: number): Promise<Blob> => {
  const params = { imageId };
  return api.get<any, Blob>(`project/load/image/file`, { params, responseType: 'blob' });
};

export const getProjects = (
  searchValue?: string,
  orderType?: 'ASC' | 'DESC',
  page?: number,
  sortingType?: string,
  pageSize?: number,
): Promise<IDataSuccessResponse<IProject>> => {
  return api.get('project/list', {
    params: { name: searchValue, orderType, sortingType, page, pageSize },
  });
};

export const getProject = (id: number): Promise<ISuccessResponse<IProject>> => {
  return api.get(`project/${id}`);
};

export const createProject = (projectName: string): Promise<ISuccessResponse<IProject>> => {
  return api.post('project/create', { ...INITIAL_PROJECT, projectName: projectName });
};

export const createProjectWithTemplate = (body: any): Promise<ISuccessResponse<IProject>> => {
  return api.post('project/create', body);
};

export const addProjectItem = (
  projectId: number,
  projectItem: IProjectItem = INITIAL_PROJECT_ITEM,
): Promise<ISuccessResponse<IProjectItem>> => {
  const data = { projectId, projectItem };
  return api.post('project/projectItem/add', data);
};

export const deleteProjectItem = (id: number): Promise<ISuccessResponse<IProjectItem>> => {
  const data = { id };
  return api.delete('project/projectItem/delete', { data });
};

export const saveProjectItem = (
  projectItem: IProjectItem,
  modifiedImage: File,
): Promise<ISuccessResponse<number>> => {
  const formData = new FormData();
  const projectItemDto = new Blob([JSON.stringify(projectItem)], { type: 'application/json' });
  formData.append('projectItemDto', projectItemDto);
  formData.append('modifiedImage', modifiedImage);
  return api.post('project/projectItem/save', formData);
};

export const handleSaveNewPage = (editor: FabricJSEditor, projectItem: IProjectItem) => {
  const image = editor.canvas.toDataURL({ format: 'jpeg', quality: 0.5 });
  fetch(image)
    .then((response) => response.blob())
    .then((blob) => {
      const imageFile = new File([blob], `canvas_${projectItem.id}.jpeg`, {
        type: blob.type,
      });
      return imageFile;
    })
    .then((imageFile) => {
      saveProjectItem(projectItem, imageFile);
    });
};

export const renameProject = (id: number, newName: string): Promise<ISuccessResponse<IProject>> => {
  const data = { id, newName };
  return api.post('project/rename', data);
};

export const cloneProject = (projectId: number) => {
  const data = { projectId };
  return api.post<any, { success: boolean; exceptionType: string; data: IProject }>(
    'project/clone',
    data,
  );
};

export const moveToFolder = (
  itemId?: number,
  folderId?: number,
): Promise<ISuccessResponse<IProject>> => {
  const data = { itemId, folderId };
  return api.post('project/moveToFolder', data);
};

export const deleteProject = (id: number): Promise<ISuccessResponse<IProject>> => {
  return api.delete('project/delete', { data: { id } });
};

export const downloadProject = (projectId: number): Promise<string> => {
  return api
    .get(`project/download?projectId=${projectId}&downloadFormat=JPEG`, { responseType: 'blob' })
    .then((res) => {
      const urlCreator = window.URL;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return urlCreator.createObjectURL(res);
    });
};

export const cloneProjects = (projects: number[]) => {
  return api.post('project/clone/selected', { projectIds: projects });
};

export const deleteProjects = (projects: number[]) => {
  return api.delete('project/delete/selected', { data: { ids: projects } });
};

export const moveToFolderProjects = (projects: number[], folderId: number) => {
  return api.post('project/moveToFolder/selected', { folderId, itemIds: projects });
};

export const projectDetailsUpdate = async (projectId: number, details: any) => {
  const response = await api.post('project/update/details', { id: projectId, details });
  return response.data;
};

export const getProjectDetails = async (projectId: number) => {
  const response = await api.get('project/details', { params: { projectId } });
  return response.data;
};

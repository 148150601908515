import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { FabricJSEditor } from 'fabricjs-react';
import { RgbaStringColorPicker } from 'react-colorful';
import { AnyColor, colord } from 'colord';
import { useTranslation } from 'react-i18next';

import { ColorType, IGradient, RadialGradientType } from '../../interfaces/editor.interface';
import { Text } from '../text.component';
import { RADIAL_GRADIENT_TYPES, BG_GRADIENT_TEMPLATES } from '../../constants';
import { Flex } from '../flexbox.component';
import GradientRangeSlider, { IGradientRangeValue } from '../gradient-range-slider.component';
import { EyeDropBtn, Gradient, Hr } from './color-popover.style';
import {
  disableEyeDrop,
  handleEyeDrop,
  handleSetGradientBackground,
} from '../../utils/editor.utils';

interface IProps {
  editor: FabricJSEditor;
  initialGradient?: IGradient;
  setGradient: Dispatch<SetStateAction<IGradient | undefined>>;
  setDisablePopoverClose: Dispatch<SetStateAction<boolean>>;
  colorType: ColorType;
  gradientColors: string[];
  setGradientColors: Dispatch<SetStateAction<string[]>>;
  activeColorIndex: number;
  setActiveColorIndex: Dispatch<SetStateAction<number>>;
  _handleEyeDrop: (gradient?: boolean) => void;
}

export const RadialGradient = ({
  editor,
  initialGradient,
  setGradient,
  setDisablePopoverClose,
  colorType,
  gradientColors,
  setGradientColors,
  activeColorIndex,
  setActiveColorIndex,
  _handleEyeDrop,
}: IProps) => {
  const { t } = useTranslation();

  const start = initialGradient?.options?.colorStops
    ? initialGradient?.options?.colorStops[0]?.offset
    : 0;
  const end = initialGradient?.options?.colorStops
    ? initialGradient?.options?.colorStops[1]?.offset
    : 1;
  const [gradientRange, setGradientRange] = useState<IGradientRangeValue>({ start, end });

  const radialGradientType: RadialGradientType = RADIAL_GRADIENT_TYPES.includes(
    initialGradient?.type as RadialGradientType,
  )
    ? (initialGradient?.type as RadialGradientType)
    : 'radialCenter';
  const [gradientType, setGradientType] = useState<RadialGradientType>(radialGradientType);
  const [alpha, setAlpha] = useState<number>(1);
  const [enableColorEyeDrop, setEnableColorEyeDrop] = useState<boolean>(false);
  const [eyeDropColor, setEyeDropColor] = useState<string | null>(null);

  useEffect(() => {
    if (editor && gradientType && gradientColors[0] && gradientColors[1]) {
      const gradient = handleSetGradientBackground(
        editor.canvas,
        'radial',
        gradientColors,
        gradientRange,
        gradientType,
        colorType,
      );
      setGradient(gradient);
    }
  }, [gradientType, gradientColors[0], gradientColors[1], gradientRange.start, gradientRange.end]);

  const handleChangeColor = (rgbaColor: string) => {
    const a = parseFloat(rgbaColor.substring(5, rgbaColor.length - 1).split(',')[3]);
    setAlpha(a);
    if (!enableColorEyeDrop) {
      const colorsCopy = [...gradientColors];
      colorsCopy[activeColorIndex] = rgbaColor;
      setGradientColors(colorsCopy);
    }
  };

  const setAlphaText = () => {
    const hue = document.querySelector('div.react-colorful__alpha');
    const div = document.createElement('div');
    div.className = 'react-colorful__alpha__text';
    div.innerText = `${parseInt(String(alpha * 100))}%`;
    if (hue?.childNodes?.length === 2) {
      hue?.appendChild(div);
    } else {
      hue?.replaceChild(div, hue?.childNodes[2]);
    }
  };

  useEffect(setAlphaText, [alpha]);

  const hexToRgba = (hexColorStr: string) => {
    const { r, g, b, a } = colord(hexColorStr as AnyColor).toRgb();
    setAlpha(1);
    return `rgba(${r}, ${g}, ${b}, ${a})`;
  };

  const handleChooseGradient = (gradient: { start: string; end: string }) => {
    const start = hexToRgba(gradient.start);
    const end = hexToRgba(gradient.end);
    setGradientColors([start, end]);
  };

  useEffect(() => {
    setDisablePopoverClose(enableColorEyeDrop);
  }, [enableColorEyeDrop]);

  return (
    <>
      <Hr style={{ marginTop: '0.75rem', marginBottom: '0.5rem' }} />
      <Flex gap={36.75} alignItems="center">
        <Text small>{t('editor.style')}</Text>
        {RADIAL_GRADIENT_TYPES.map((type) => (
          <Gradient
            key={type}
            active={type === gradientType}
            type={type}
            colors={gradientColors}
            onClick={() => setGradientType(type)}
          />
        ))}
      </Flex>
      <GradientRangeSlider
        colors={gradientColors}
        activeColorIndex={activeColorIndex}
        setActiveColorIndex={setActiveColorIndex}
        gradientRange={gradientRange}
        setGradientRange={setGradientRange}
      />
      <EyeDropBtn
        isGradient={true}
        active={enableColorEyeDrop}
        onClick={() => _handleEyeDrop(true)}
      />
      <RgbaStringColorPicker
        color={enableColorEyeDrop && eyeDropColor ? eyeDropColor : gradientColors[activeColorIndex]}
        onChange={handleChangeColor}
      />
      <Hr />
      <Flex gap={4} wrapItems>
        {BG_GRADIENT_TEMPLATES.map((g, index) => (
          <Gradient
            key={`radial-gradient-color_${index}`}
            active={gradientColors[0] === g.start && gradientColors[1] === g.end}
            type={gradientType}
            colors={[g.start, g.end]}
            onClick={() => handleChooseGradient(g)}
          />
        ))}
      </Flex>
    </>
  );
};

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { TabTypeWithoutSort } from '../interfaces/infographics.interface';

const TabContainer = styled.div<{ tabDisplay: boolean | undefined }>`
  display: ${(p) => (p.tabDisplay ? 'none' : 'flex')};
  gap: 6px;
`;

const Tab = styled.div<{ isActive: boolean }>`
  padding: 13px 16px 13px 16px;
  border-radius: 16px;
  background-color: ${(p) => (p.isActive ? 'rgba(102,32,199, 0.4)' : '#00000026')};
  margin-bottom: 1.25em;
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;
  color: ${(p) => (p.isActive ? 'rgba(102,32,199, 1)' : '#000')};
`;

interface ToggleButtonsProps {
  tabs: TabTypeWithoutSort[];
  tabDisplay?: boolean;
  content?: JSX.Element | false;
  setParentActiveTab?: (index: number) => void;
  parentTab?: number;
  hasParentTab?: boolean;
}

const ToggleButtons: React.FC<ToggleButtonsProps> = ({
  tabs,
  tabDisplay,
  content,
  setParentActiveTab,
  parentTab,
  hasParentTab,
}) => {
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    hasParentTab && parentTab && setActiveTab(parentTab);
  }, [parentTab]);

  return (
    <>
      <TabContainer tabDisplay={tabDisplay}>
        {tabs.map((tab, index) => (
          <Tab
            key={index}
            isActive={activeTab === index}
            onClick={() => {
              setActiveTab(index);
              setParentActiveTab && setParentActiveTab(index);
            }}
          >
            {tab.title}
          </Tab>
        ))}
      </TabContainer>
      {content && content}
      {tabs[activeTab]?.content}
    </>
  );
};

export default ToggleButtons;

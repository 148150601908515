import React, { SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { RoundBtn } from '../round-btn.component';
import { Flex } from '../flexbox.component';
import { Modal } from '../modal.component';
import {
  ButtonsWrap,
  FolderModalContent,
  MoveToFolderModalContent,
  NewCardButton,
} from '../../pages/folders/folders.style';
import { Text } from '../text.component';
import Input from '../input.component';
import { Button } from '../button.component';
import { ChooseFolder, FoldersRow, PlusIcon } from '../../pages/files/files.style';
import FolderListLoader from '../loaders/folder-list.component';
import SkeletonLoader from '../skeleton-loader.component';
import { createFolder, getFolders } from '../../requests/folder.requests';
import { ICreateFolderRequest } from '../../interfaces/projects.interface';
import plus from '../../assets/icons/plus.svg';
import { deleteSelectedFiles, moveToFolderFiles } from '../../requests/files.requests';
import { recoverySelected } from '../../requests/trash.requests';
import { useNotification } from '../../contexts/notification.context';
import useSortSearch from '../../hooks/use-sort-search.hooks';
import { FOLDER_SORT } from '../../constants/shared.constants';
import ModalPagination from '../pagination/modal.pagination';
import ModalHeader from '../modal/modal.header';

const Divider = styled.div`
  width: calc(100% + 48px);
  height: 1px;
  background: #00000026;
  transform: translate(-24px, 0);
  margin-bottom: 20px;
`;

const CustomRoundBtn = styled(RoundBtn)<{ br?: number }>`
  border-radius: ${({ br }) => br}px;
`;

const ImageHighlight: React.FC<{
  setHighlightedFiles: React.Dispatch<SetStateAction<number[]>>;
  highlightedFiles: number[];
  choiceList: any;
  refreshRequest: () => void;
  setIsHighlighting: (value: boolean) => void;
  navigateFolderTab?: (folder: ICreateFolderRequest) => void;
  folderId?: number | undefined;
}> = ({
  setHighlightedFiles,
  choiceList,
  highlightedFiles,
  refreshRequest,
  setIsHighlighting,
  navigateFolderTab,
  folderId,
}) => {
  const { setNotification, closeNotification } = useNotification();
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [folderModal, setFolderModal] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>('');
  const [loadingFolderList, setLoadingFolderList] = useState<boolean>(false);
  const [folderList, setFolderList] = useState<ICreateFolderRequest[]>([]);
  const [chosenFolder, setChosenFolder] = useState<ICreateFolderRequest>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [folderTotalPages, setFolderTotalPages] = useState<number>(0);

  const choiceListIds = choiceList.map((item: any) => item.id);
  const emptySelect = highlightedFiles.length === 0;

  const folderSort = useSortSearch(FOLDER_SORT[1]);

  useEffect(() => {
    setLoadingFolderList(true);
    getFolders(
      undefined,
      folderSort.selectedOption.value,
      folderSort.sortAsc,
      folderSort.page,
      5,
    ).then((res) => {
      setFolderTotalPages(res.totalPages);
      folderSort.setHasMore(!(res.list.length < 24));
      setFolderList(res.list);
      setLoadingFolderList(false);
    });
  }, [folderSort.page, folderSort.selectedOption, folderSort.sortAsc]);

  const handleCloseModal = () => {
    setShowModal(false);
    setFolderModal(false);
    setInputValue('');
    setChosenFolder(undefined);
  };

  const handleCreateFolder = () => {
    handleCloseModal();
    setIsLoading(true);
    createFolder({ folderName: inputValue })
      .then((result) => {
        handleMoveToFolder((result as any).data);
      })
      .finally(() => setIsLoading(false));
  };

  const handleHighlightAll = () => {
    if (choiceList.length === highlightedFiles.length) {
      setHighlightedFiles([]);
      return;
    }
    setHighlightedFiles(choiceListIds);
  };

  const resetHighlight = () => {
    setHighlightedFiles([]);
    setIsHighlighting(false);
  };

  const handleChooseFolder = (folder?: ICreateFolderRequest) => {
    setChosenFolder(folder);
  };

  const handleDelete = () => {
    if (highlightedFiles.length === 0) return;

    deleteSelectedFiles(highlightedFiles).then(() => {
      const count = highlightedFiles.length;
      setNotification({
        text: `Вы перенесли файл${count === 1 ? '' : 'ы'} в корзину`,
        buttonText: 'Отменить',
        handleFunction: () => handleRecovery(),
        handleClose: resetHighlight,
      });
      refreshRequest();
      setHighlightedFiles([]);
    });
  };

  const handleMoveToFolder = (folder: ICreateFolderRequest) => {
    setIsLoading(true);
    moveToFolderFiles(highlightedFiles, folder.id || (chosenFolder?.id as number))
      .then(() => {
        const count = highlightedFiles.length;
        setNotification({
          text: `Файл${count === 1 ? '' : 'ы'} помещен${count === 1 ? '' : 'ы'} в папку «${
            folder?.folderName || chosenFolder?.folderName
          }»`,
          handleFunction: () => {
            folder && navigateFolderTab && navigateFolderTab(folder);
            closeNotification();
          },
          buttonText: 'Перейти в папку',
          icon: 'folder',
        });
        refreshRequest();
      })
      .finally(() => {
        handleCloseModal();
        resetHighlight();
        setIsLoading(false);
      });
  };

  const handleRecovery = () => {
    recoverySelected('IMAGE', highlightedFiles).then(() => {
      refreshRequest && refreshRequest();
      closeNotification();
      resetHighlight();
    });
  };

  return (
    <>
      <Divider />
      <Flex justifyContent={'space-between'} style={{ width: '100%' }}>
        <Flex gap={6}>
          <CustomRoundBtn
            data-tooltip-wrapped={t('multiple-choice.highlight-all') as string}
            data-tooltip-position="right-top"
            br={16}
            onClick={handleHighlightAll}
            style={{ boxShadow: '0 1px 2px rgba(0, 0, 0, 0.25)' }}
          >
            <span className="icon-select"></span>
          </CustomRoundBtn>
          <CustomRoundBtn
            data-tooltip={t('multiple-choice.move-highlighted') as string}
            data-tooltip-position="top"
            br={16}
            onClick={() => {
              setShowModal(true);
            }}
            style={{ boxShadow: '0 1px 2px rgba(0, 0, 0, 0.25)' }}
            disabled={emptySelect}
          >
            <span className="icon-folder"></span>
          </CustomRoundBtn>
          <CustomRoundBtn
            data-tooltip={t('multiple-choice.delete-highlighted') as string}
            data-tooltip-position="top"
            br={16}
            onClick={handleDelete}
            style={{ boxShadow: '0 1px 2px rgba(0, 0, 0, 0.25)' }}
            disabled={emptySelect}
          >
            <span className="icon-trash"></span>
          </CustomRoundBtn>
        </Flex>
        <CustomRoundBtn
          data-tooltip-wrapped={t('action.cancel') as string}
          data-tooltip-position="top"
          br={16}
          onClick={resetHighlight}
          style={{ boxShadow: '0 1px 2px rgba(0, 0, 0, 0.25)' }}
        >
          <span className="icon-close"></span>
        </CustomRoundBtn>
      </Flex>

      {showModal && (
        <Modal onClose={handleCloseModal} width={folderModal ? '380px' : '786px'}>
          {folderModal ? (
            <FolderModalContent>
              <Text fontSize="23px" strong>
                Новая папка
              </Text>
              <Input
                label="Название папки"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                showButtons={false}
                maxlength={100}
              />
              <ButtonsWrap spaceBetween>
                <Button btnStyle={'cancel'} style={{ padding: '0' }} onClick={handleCloseModal}>
                  Отмена
                </Button>
                <Button
                  disabled={!inputValue}
                  icon={'folder-plus'}
                  onClick={handleCreateFolder}
                  isLoading={isLoading}
                >
                  Создать
                </Button>
              </ButtonsWrap>
            </FolderModalContent>
          ) : (
            <MoveToFolderModalContent>
              <ModalHeader sort={folderSort} type={'file'} isLoading={loadingFolderList} />
              <FoldersRow gutter={0} style={{ minHeight: '162px' }}>
                {loadingFolderList ? (
                  <FolderListLoader withoutFolder count={2} />
                ) : (
                  <>
                    <NewCardButton
                      onClick={handleCloseModal}
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Text fontSize="1.4375rem" color="gray" strong>
                        Без папки
                      </Text>
                      <Text fontSize="0.875rem" color="gray" strong>
                        Будет доступен на странице «Все файлы»
                      </Text>
                    </NewCardButton>
                    {folderList.length === 0 && (
                      <NewCardButton onClick={() => setFolderModal(true)}>
                        <Text fontSize="1.4375rem" color="gray" strong>
                          Создать папку
                        </Text>
                        <PlusIcon>
                          <img src={plus} alt="plus" />
                        </PlusIcon>
                      </NewCardButton>
                    )}
                    {folderList.map((folder: ICreateFolderRequest) => (
                      <ChooseFolder
                        key={folder.id}
                        onClick={() => handleChooseFolder(folder)}
                        active={
                          folder.id === chosenFolder?.id ||
                          (!chosenFolder?.id && folder.id === folderId)
                        }
                      >
                        <Text fontSize="1.4375rem" strong truncate breakByWord>
                          {folder.folderName}
                        </Text>
                        <Text fontSize="0.875rem" color="gray">
                          {`${folder.itemsCount} ${
                            folder.itemsCount === 0 ? 'элементов' : 'элемента'
                          }`}
                        </Text>
                      </ChooseFolder>
                    ))}
                  </>
                )}
              </FoldersRow>
              {loadingFolderList ? (
                <Flex justifyContent="space-between" alignItems="center">
                  <SkeletonLoader width="123px" height="22px" />
                  <Flex gap={20} alignItems="center">
                    <SkeletonLoader width="78px" height="22px" />
                    <SkeletonLoader
                      width="172px"
                      height="50px"
                      borderRadius="16px"
                      baseColor="#00000033"
                    />
                  </Flex>
                </Flex>
              ) : (
                folderList.length !== 0 && (
                  <div>
                    <ModalPagination
                      page={folderSort.page}
                      setPage={folderSort.setPage}
                      totalCount={folderTotalPages}
                    />
                    <ButtonsWrap style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Button
                        btnStyle={'cancel'}
                        onClick={() => {
                          setFolderModal(true);
                        }}
                        style={{ padding: 0 }}
                        icon={'folder-plus'}
                      >
                        Новая папка
                      </Button>
                      <div style={{ display: 'flex' }}>
                        <Button btnStyle={'cancel'} onClick={handleCloseModal}>
                          Отменить
                        </Button>
                        <Button
                          icon={'folder'}
                          onClick={() => {
                            handleMoveToFolder(chosenFolder as ICreateFolderRequest);
                          }}
                          disabled={
                            (chosenFolder && (!chosenFolder.id || chosenFolder.id < 0)) ||
                            folderId === chosenFolder?.id ||
                            !chosenFolder?.id
                          }
                          isLoading={isLoading}
                        >
                          Поместить
                        </Button>
                      </div>
                    </ButtonsWrap>
                  </div>
                )
              )}
            </MoveToFolderModalContent>
          )}
        </Modal>
      )}
    </>
  );
};

export default ImageHighlight;

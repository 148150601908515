import React from 'react';
import { Card, CardWrapper, MobilePageWrapper } from './mobile.style';
import { Text } from '../../components/text.component';
import { Button } from '../../components/button.component';
import { useTranslation } from 'react-i18next';
import * as amplitude from '@amplitude/analytics-browser';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as SparkleLeft } from '../../assets/images/sparkle-left.svg';
import { ReactComponent as SparkleRight } from '../../assets/images/sparkle-right.svg';
import { SparkleWrapper } from '../register/register.style';
import MobileSuccess from './components/mobile.success.comoponent';

const MobilePage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleRegisterClick = () => {
    amplitude.track('ecom_webapp_register_click');
    navigate('/register');
  };

  return (
    <MobilePageWrapper>
      <MobileSuccess />
      <Card color="#6620C7">
        <CardWrapper>
          <Text color="#FFFFFF" fontWeight={600} fontSize="23px" lineHeight="40.98px">
            Вы еще не с нами?
          </Text>
          <Text color="#FFFFFF" fontWeight={500} fontSize="16px" lineHeight="21.86px">
            Регистрируйтесь и создавайте впечатляющие оформления карточек для ваших товаров на
            маркетплейсах.
          </Text>
          <SparkleWrapper style={{ marginTop: '20px' }}>
            <SparkleLeft />
            <Button btnStyle={'inverse'} onClick={handleRegisterClick}>
              {t('register.action')}
            </Button>
            <SparkleRight />
          </SparkleWrapper>
        </CardWrapper>
      </Card>
    </MobilePageWrapper>
  );
};

export default MobilePage;

import React from 'react';
import { FlexCol } from '../../../components/flexbox.component';
import SkeletonLoader from '../../../components/skeleton-loader.component';

const OzonLoader: React.FC<{ noneAnimation?: boolean, canvasRatio?: 'default' | 'square' }> = ({ noneAnimation, canvasRatio }) => {
  return (
    <>
      <div
        style={{
          width: '213px',
          height: '213px',
          borderRadius: '10px',
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          background: '#F5F5F5',
        }}
      >
        <SkeletonLoader
          width={canvasRatio === 'square' ? "213px" : "160px"}
          height="213px"
          borderRadius={canvasRatio === 'square' ? "10px" : "0px"}
          noneAnimation={noneAnimation}
        />
      </div>
      <FlexCol gap={5} style={{ marginBottom: '2px' }}>
        <SkeletonLoader width="200px" height="20px" noneAnimation={noneAnimation} />
        <SkeletonLoader width="138px" height="20px" noneAnimation={noneAnimation} />
        <SkeletonLoader width="200px" height="20px" noneAnimation={noneAnimation} />
        <SkeletonLoader width="96px" height="20px" noneAnimation={noneAnimation} />
      </FlexCol>
      <SkeletonLoader
        baseColor="#00000033"
        shineColor={noneAnimation ? '#00000033' : '#0000001A'}
        width="213px"
        height="40px"
      />
    </>
  );
};

export default OzonLoader;

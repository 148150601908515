import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useUserContext } from '../contexts/user.context';
import { RateNames } from '../constants/rates.constant';
import * as amplitude from '@amplitude/analytics-browser';
import { createProjectWithTemplate } from '../requests/project.requests';
import RateModal from './rates/rate-modal.component';
import { Modal } from './modal.component';
import Input from './input.component';
import { ButtonsWrap } from '../pages/files/files.style';
import { Button } from './button.component';
import { Text } from './text.component';
import { NewProjectWithTemplateModalBody } from '../pages/my-projects/my-projects.style';
import { INITIAL_PROJECT } from '../constants/editor.constants';
import { InfoGraphicRequest } from '../requests/infographic.request';
import { TemplateCard } from './template-card.component';

interface IProps {
  onClose: () => void;
  isOpen: boolean;
  trackKey: string;
  setIsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  setIsAdditionalModalOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  setType?: React.Dispatch<React.SetStateAction<string>>;
  selectedTemplate: any;
}

export const canvasRatios = [
  {
    label: '3:4',
    value: 'default',
  },
  {
    label: '1:1',
    value: 'square',
  },
];

const NewProjectWithTemplateModal: React.FC<IProps> = ({
  onClose,
  trackKey,
  isOpen,
  setIsOpen,
  setIsAdditionalModalOpen,
  setType,
  selectedTemplate,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userData, updateUserDetails } = useUserContext();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [canvasRatio, setCanvasRatio] = useState<any>(canvasRatios[0]);

  const [initialProjectName, setInitialProjectName] = useState<string>('');
  const [limit, setLimit] = useState<number>(1);

  const handleAddProject = () => {
    if (isLoading) return;

    // setIsLoading(true);
    if (userData) {
      const { rate } = userData;
      if (rate?.name === RateNames.UNLIMITED || limit > 0) {
        amplitude.track(trackKey);
        setIsLoading(true);

        InfoGraphicRequest.getInfoGraphic(selectedTemplate.id).then((res: any) => {
          const items = {
            // modifiedImageId: res.imageId,
            details: res.details[0],
          };

          const body = {
            ...INITIAL_PROJECT,
            projectName: initialProjectName,
            details: {
              ...INITIAL_PROJECT.details,
              canvasRatio: canvasRatio.value,
              withTemplate: true,
            },
            projectItems: [items],
          };

          createProjectWithTemplate(body)
            .then((res) => {
              if (res.success) {
                updateUserDetails();
                navigate(`/project/${res.data?.id}`);
                setInitialProjectName('');
                setType && setType('default');
              } else {
                setIsOpen && setIsOpen(false);
                setIsAdditionalModalOpen && setIsAdditionalModalOpen(true);
              }
            })
            .catch(() => null)
            .finally(() => setIsLoading(false));
        });
      }
    }
  };

  if (userData && userData.rate?.name !== RateNames.UNLIMITED && limit && limit <= 0) {
    return <RateModal isOpen={isOpen} onClose={onClose} />;
  }

  useEffect(() => {
    if (userData) {
      const { rate } = userData;
      setLimit(rate.availableProjectCount);
    }
  }, [userData]);

  return (
    <>
      {isOpen && (
        <Modal onClose={onClose} width="50.375rem">
          <NewProjectWithTemplateModalBody>
            <Text fontSize="1.875rem" strong style={{ color: '#000000' }}>
              {t('myProjects.new')}
            </Text>

            <div className="body">
              <div className="template df-cl">
                <Text fontWeight={500}>Выбранный шаблон</Text>
                <TemplateCard src={selectedTemplate.thumbnail} />
              </div>
              <div className="details">
                <div className="df-cl">
                  <Text fontWeight={500}>1. Выберите формат</Text>
                  <div className="df-rw">
                    {canvasRatios.map((item: any) => (
                      <Button
                        btnStyle="gray"
                        key={item.value}
                        active={canvasRatio.value === item.value}
                        onClick={() => setCanvasRatio(item)}
                      >
                        {item.label}
                      </Button>
                    ))}
                  </div>
                </div>
                <div className="df-cl">
                  <Text fontWeight={500}>2. Назовите проект</Text>
                  <Input
                    label="Название проекта"
                    value={initialProjectName}
                    onChange={(e) => setInitialProjectName(e.target.value)}
                    showButtons={false}
                    maxlength={100}
                  />
                </div>
              </div>
            </div>

            <ButtonsWrap>
              <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <Button
                  btnStyle={'cancel'}
                  style={{ padding: '0.8125rem 0' }}
                  onClick={() => {
                    setInitialProjectName('');
                    onClose();
                  }}
                >
                  Отменить
                </Button>
                <Button
                  icon={'plus'}
                  onClick={() => handleAddProject()}
                  disabled={
                    !initialProjectName || initialProjectName.trim().length === 0 || isLoading
                  }
                  isLoading={isLoading}
                >
                  Создать
                </Button>
              </div>
            </ButtonsWrap>
          </NewProjectWithTemplateModalBody>
        </Modal>
      )}
    </>
  );
};

export default NewProjectWithTemplateModal;

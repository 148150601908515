import styled, { css } from 'styled-components';

interface IHeadlineProps {
  small?: boolean,
  color?: string,
  mb?: number,
}

export const Headline = styled.h1<IHeadlineProps>`
  font-style: normal;
  font-weight: 600;
  font-size: 1.875rem;
  line-height: 2.5625rem;
  color: ${p => p.theme.black};
  margin: 0;
  padding: 0;

  ${p => p.small && css`
    font-weight: 700;
    font-size: 1.4375rem;
    line-height: 1.9375rem;
  `}

  ${({ color }) => color && css`
    color: ${p => p.theme[color]};
  `}

  ${({ mb }) => mb && css`
    margin-bottom: ${mb}px;
  `}
`;

import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FabricTypes from 'fabric/fabric-impl';

import { useCanvasHistory } from '../../contexts/canvas-history.context';
import useDebounce from '../../hooks/use-debounce.hooks';
import { StrokeToolWrapper, DecrementBtn, IncrementBtn, StyleButton } from './object-toolbar.style';
import { IBasicEditorProps } from '../../interfaces/editor.interface';
import { Flex } from '../flexbox.component';
import { ColorPopover } from '../color-popover/color-popover.component';
import { NumberInput } from '../number-input.component';
import { LINE_STROKE_WIDTH } from '../../constants/editor.constants';
import { useCanvasFocus } from '../../contexts/canvas-focus.context';

interface IProps extends IBasicEditorProps {
  setDisablePopoverClose: Dispatch<SetStateAction<boolean>>;
}

export const StrokeTool = ({ editor, activeObject, setDisablePopoverClose }: IProps) => {
  const { t } = useTranslation();
  const { historySaveAction } = useCanvasHistory();
  const { setIsInputFocused } = useCanvasFocus();
  // const activeGroup = activeObject as FabricTypes.Group;
  const [didMount, setDidMount] = useState(false);
  const [params, setParams] = useState<{ [key: string]: number }>({
    strokeWidth: 0,
    borderRadius: activeObject?.name === 'rect' ? (activeObject as FabricTypes.Rect)?.rx || 0 : 0,
  });
  const [strokeStyle, setStrokeStyle] = useState<'solid' | 'dashed' | 'dotted'>('solid');
  const [isEdited, setIsEdited] = useState<{ [key: string]: boolean }>({
    strokeWidth: false,
    borderRadius: false,
  });
  const debouncedStrokeWidth = useDebounce(params.strokeWidth, 1000);
  const debouncedBorderRadius = useDebounce(params.borderRadius, 1000);

  useEffect(() => {
    return () => {
      setIsEdited({
        strokeWidth: false,
        borderRadius: false,
      });
    };
  }, []);

  useEffect(() => {
    if (editor && activeObject) {
      setParams((prev) => ({
        ...prev,
        strokeWidth: activeObject?.strokeWidth ?? 0,
        borderRadius:
          activeObject?.type === 'rect' ? (activeObject as FabricTypes.Rect)?.rx || 0 : 0,
      }));
    }
  }, [activeObject]);

  useEffect(() => {
    if (isEdited.strokeWidth) {
      historySaveAction(editor);
    }
  }, [debouncedStrokeWidth]);

  useEffect(() => {
    if (isEdited.borderRadius) {
      historySaveAction(editor);
    }
  }, [debouncedBorderRadius]);

  useEffect(() => {
    if (didMount && editor && activeObject) {
      activeObject.set('strokeWidth', params.strokeWidth);
      if (strokeStyle !== 'solid' && params.strokeWidth) {
        const value = strokeStyle === 'dashed' ? 5 : 1;
        activeObject.set('strokeDashArray', [
          value * params.strokeWidth,
          value * params.strokeWidth,
        ]);
      }
      // activeGroup.addWithUpdate();
      editor.canvas.renderAll();
    }
  }, [params.strokeWidth, strokeStyle, activeObject]);

  useEffect(() => {
    if (didMount && editor && activeObject?.type === 'rect') {
      const rect = activeObject as FabricTypes.Rect;
      rect.set({
        rx: params.borderRadius,
        ry: params.borderRadius,
      });
      // activeGroup.addWithUpdate();
      editor.canvas.renderAll();
    }
  }, [params.borderRadius, activeObject]);

  const handleDecrement = (key: string) => () => {
    setParams((prev) => ({ ...prev, [key]: prev[key] > 0 ? prev[key] - LINE_STROKE_WIDTH : 0 }));
    setIsEdited((prev) => ({ ...prev, [key]: true }));
  };

  const handleIncrement = (key: string) => () => {
    setParams((prev) => ({ ...prev, [key]: prev[key] + LINE_STROKE_WIDTH }));
    setIsEdited((prev) => ({ ...prev, [key]: true }));
  };

  const handleChange = (key: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setParams((prev) => ({ ...prev, [key]: +event.target.value * LINE_STROKE_WIDTH }));
    setIsEdited((prev) => ({ ...prev, [key]: true }));
  };

  const handleChangeStrokeColor = (color: string) => {
    if (color && activeObject) {
      activeObject.set('stroke', color);
      // activeGroup.addWithUpdate();
      editor.canvas.renderAll();
    }
  };

  const getStrokeStyle = (dashArray?: number[]) => {
    const strokeDashArray = activeObject?.strokeDashArray;
    const value = dashArray
      ? dashArray[0]
      : strokeDashArray && strokeDashArray[0] / params.strokeWidth;

    if (value === 5) {
      return 'dashed';
    }
    if (value === 1) {
      return 'dotted';
    }
    return 'solid';
  };

  const handleStyleStroke = (dashArray?: number[]) => {
    if (activeObject) {
      const value = dashArray ? dashArray[0] : 0;
      activeObject.set('strokeDashArray', [value * params.strokeWidth, value * params.strokeWidth]);
      const style = getStrokeStyle(dashArray);
      setStrokeStyle(style);
      // activeGroup.addWithUpdate();
      editor.canvas.requestRenderAll();
      historySaveAction(editor);
    }
  };

  useEffect(() => {
    if (didMount && activeObject) {
      const style = getStrokeStyle();
      setStrokeStyle(style);
    }
  }, [activeObject, params.strokeWidth]);

  useEffect(() => setDidMount(true), []);

  return (
    <StrokeToolWrapper>
      <Flex justifyContent={'space-between'}>
        <Flex
          alignItems={'center'}
          data-tooltip={t('tooltip.stroke')}
          data-tooltip-position={'left'}
        >
          <DecrementBtn onClick={handleDecrement('strokeWidth')} />
          <NumberInput
            value={params.strokeWidth / LINE_STROKE_WIDTH}
            onChange={handleChange('strokeWidth')}
            onFocus={() => setIsInputFocused(true)}
            onBlur={() => setIsInputFocused(false)}
          />
          <IncrementBtn onClick={handleIncrement('strokeWidth')} />
        </Flex>
        <ColorPopover
          colorType={'stroke'}
          initialFill={activeObject?.stroke}
          editor={editor}
          handleChangeColor={handleChangeStrokeColor}
          tooltipPosition="top"
          tooltipText={'tooltip.strokeColor'}
          lightTheme={true}
          setDisableParentPopoverClose={setDisablePopoverClose}
          typeKey={'stroke-color'}
        />
      </Flex>
      {activeObject?.type === 'rect' && (
        <Flex
          justifyContent={'space-between'}
          data-tooltip={t('tooltip.borderRadius')}
          data-tooltip-position={'left'}
        >
          <Flex alignItems={'center'}>
            <DecrementBtn onClick={handleDecrement('borderRadius')} />
            <NumberInput
              value={params.borderRadius / LINE_STROKE_WIDTH}
              onChange={handleChange('borderRadius')}
              onFocus={() => setIsInputFocused(true)}
              onBlur={() => setIsInputFocused(false)}
            />
            <IncrementBtn onClick={handleIncrement('borderRadius')} />
          </Flex>
          <StyleButton disableHover>
            <span className={'icon-corners'} />
          </StyleButton>
        </Flex>
      )}
      <Flex
        justifyContent={'space-between'}
        data-tooltip={t('tooltip.strokeStyle')}
        data-tooltip-position={'left'}
      >
        <StyleButton active={strokeStyle === 'solid'} onClick={() => handleStyleStroke()}>
          <span className={'icon-circle'} />
        </StyleButton>
        <StyleButton active={strokeStyle === 'dashed'} onClick={() => handleStyleStroke([5, 5])}>
          <span className={'icon-dashed'} />
        </StyleButton>
        <StyleButton active={strokeStyle === 'dotted'} onClick={() => handleStyleStroke([1, 1])}>
          <span className={'icon-dotted'} />
        </StyleButton>
      </Flex>
    </StrokeToolWrapper>
  );
};

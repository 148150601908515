import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/auth.context';

export const AuthGuard = ({ children }: { children: JSX.Element }) => {
  const auth = useAuth();
  const location = useLocation();

  if (!auth.user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (auth.userRole === 'ADMIN') {
    return <Navigate to="/admin" replace />;
  }

  return children;
};

import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { BasicLayout } from '../layout/basic.layout';
import { AuthGuard } from './auth-guard';
import { AdminGuard } from './admin-guard';

import { RegisterPage } from './register/register.page';
import { LoginPage } from './login/login.page';
import { MyProjects } from './my-projects/my-projects.page';
import { EditorPage } from './editor/editor.page';
import ProfilePage from './profile/profile.page';
import { ResetPassword } from './reset-password/reset-password.page';
import { ChangePassword } from './change-password/change-password.page';
import { FilesPage } from './files/files.page';
import { FoldersPage } from './folders/folders.page';
import { TrashPage } from './trash/trash.page';
import { OpenFolderPage } from './folders/open-folder.page';
import PreviewImagePage from './preview-image/preview-image.page';
import RatesPage from './rates/rates.page';
import PaymentSuccess from './payment/payment-success.page';
import PolicyPage from './policy/policy.page';
import PublicOfferPage from './policy/public-offer.page';
import AdminPage from './admin/admin.page';

export default function RoutesIndex() {
  return (
    <Routes>
      <Route path="/register" element={<RegisterPage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/policy" element={<PolicyPage />} />
      <Route path="/public-offer" element={<PublicOfferPage />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/changePassword" element={<ChangePassword />} />
      <Route path="/" element={<BasicLayout />}>
        <Route index element={<Navigate to="/my-projects" replace />} />
        <Route path="/my-projects" element={<MyProjects />} />
        <Route path="/profile" element={<ProfilePage />} />
        <Route path="/folders" element={<FoldersPage />} />
        <Route path="/folders/item" element={<OpenFolderPage />} />
        <Route path="/files" element={<FilesPage />} />
        <Route path="/trash" element={<TrashPage />} />
        <Route path="/rates" element={<RatesPage />} />
        <Route path="/payment/success" element={<PaymentSuccess />} />
      </Route>
      <Route
        path="/project/:projectId"
        element={
          <AuthGuard>
            <EditorPage />
          </AuthGuard>
        }
      />
      <Route
        path="/project/:projectId/item"
        element={
          <AuthGuard>
            <PreviewImagePage />
          </AuthGuard>
        }
      />
      <Route
        path="/admin"
        element={
          <AdminGuard>
            <AdminPage />
          </AdminGuard>
        }
      />
    </Routes>
  );
}

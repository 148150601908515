import styled, { css } from 'styled-components';
import { Card } from '../../components/card.component';
import { Row } from '../../components/grid.component';
import CheckeredBackground from '../../assets/images/checkered-background.svg';
import { InputOverlay } from '../../components/card.checkbox.overlay';

export const Wrapper = styled.div`
  display: flex;
  gap: 10px;
`;

export const NewCardButton = styled.div<{ noIcon?: boolean; isScaled?: boolean }>`
  &:hover {
    .plus-icon {
      transition: all 0.25s ease-in-out;
      transform: scale(1.143);
    }
  }

  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 20px;
  width: 240px;
  height: 160px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), #ffffff;
  border-radius: 16px;
  cursor: pointer;
  min-width: 200px;
  > label {
    display: flex;
    height: 120px;
  }

  img {
    height: 35px;
    width: 35px;
    align-self: end;
    justify-self: end;
  }

  ${(p) =>
    p.isScaled &&
    css`
      &:hover {
        > label > img {
          transform: scale(1.143);
          transition: all 0.3s;
        }
      }
    `}
`;

export const CardContainer = styled.div<{ bgColor?: string }>`
  width: 240px;
  height: 160px;
  border-radius: 16px;
  background: ${(p) =>
    p.bgColor
      ? p.bgColor
      : 'linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), rgb(255, 255, 255)'};
  overflow: hidden;
`;

export const CustomCard = styled(Card)<{ highlight?: boolean; active?: boolean }>`
  position: relative;
  height: 160px;
  width: 240px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  .photo-container {
    height: 140px;
    width: 220px;
    background: url(${CheckeredBackground});
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      max-height: 140px;
      max-width: 220px;
    }
  }

  ${({ highlight }) =>
    highlight &&
    css`
      &:hover {
        border: 2px solid #6620c7 !important;
        & > ${InputOverlay} {
          border: 2px solid #6620c7 !important;
        }
      }
    `}

  ${({ highlight, active }) =>
    highlight &&
    css`
      border: 2px solid ${active ? '#6620C7' : '#cccccc'};
      user-select: none;
    `}
`;

export const ChooseFolder = styled(CustomCard)<any>`
  height: 160px;
  width: 240px;
  justify-content: space-between;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  border: 1px solid ${(p) => (p.active ? p.theme.primary : p.theme.lightBlack2)};
  outline: 1px solid ${(p) => (p.active ? p.theme.primary : 'transparent')};
  border-radius: 8px;
  min-width: 200px;
  padding: 20px;
  overflow: hidden;
  word-break: break-all;
  &:hover {
    border-color: ${(p) => p.theme.primary};
    outline-color: ${(p) => p.theme.primary};
  }
`;

export const CustomButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.375em;
  padding: 0.71875em 1em;
  border-radius: 16px;
  color: ${(p) => p.theme.black};
  text-decoration: none;
  transition: all 250ms ease-in-out;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), #ffffff;
  &.active,
  &:hover {
    color: ${(p) => p.theme.primary};
    background: linear-gradient(0deg, rgba(102, 32, 199, 0.4), rgba(102, 32, 199, 0.4)),
      ${(p) => p.theme.white};
  }
`;

export const ButtonsWrap = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const FoldersRow = styled(Row)`
  gap: 10px;
  overflow-y: auto;
  padding: 1px;
  margin: -1px;
`;

export const PlusIcon = styled.div.attrs({
  className: 'plus-icon',
})`
  display: block;
  align-self: end;
`;

export const FileName = styled.span`
  color: #00000066;
  font-size: 14px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 20px 20px 0px 20px;
`;

const components = {
  Wrapper,
  NewCardButton,
  ButtonsWrap,
  ChooseFolder,
  Notification,
  FoldersRow,
  PlusIcon,
  FileName,
  CardContainer,
};

export default components;

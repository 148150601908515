import api from './api';
import {
  IDataSuccessResponse,
  IFolders,
  ILibraryImages,
  IProject,
  ISuccessResponse,
} from '../interfaces/projects.interface';

export const deleteForever = (itemId?: number, itemType?: string): Promise<ISuccessResponse> => {
  return api.delete('trash/delete', { data: { itemId, itemType } });
};

export const deleteAll = (itemType: string): Promise<ISuccessResponse> => {
  return api.delete(`trash/deleteAll?itemType=${itemType}`);
};

export const getFolders = (
  name?: string,
  sortingType?: string,
  orderType?: string,
): Promise<IDataSuccessResponse<IFolders>> => {
  return api.get('trash/folders', { params: { name, sortingType, orderType } });
};

export const getLibraryImages = (
  name?: string,
  sortingType?: string,
  orderType?: string,
  page?: number,
): Promise<IDataSuccessResponse<ILibraryImages>> => {
  return api.get('trash/libraryImages', { params: { name, sortingType, orderType, page } });
};

export const getProjects = (
  name?: string,
  sortingType?: string,
  orderType?: string,
  page?: number,
): Promise<IDataSuccessResponse<IProject>> => {
  return api.get('trash/projects', { params: { name, sortingType, orderType, page } });
};

export const recoverItem = (itemId?: number, itemType?: string): Promise<ISuccessResponse> => {
  return api.post('trash/recovery', { itemId, itemType });
};

export const deleteSelected = (itemType: 'PROJECT' | 'FOLDER' | 'IMAGE', itemIds: number[]) => {
  return api.delete(`trash/delete/selected`, { data: { itemIds, itemType } });
};

export const recoverySelected = (itemType: 'PROJECT' | 'FOLDER' | 'IMAGE', itemIds: number[]) => {
  return api.post(`trash/recovery/selected`, { itemIds, itemType });
};

import { AnyColor, colord } from 'colord';
import { fabric } from 'fabric';
import FabricTypes, { IShadowOptions } from 'fabric/fabric-impl';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { IBasicEditorProps } from '../../../interfaces/editor.interface';
import { handleEyeDrop } from '../../../utils/editor.utils';
import { ColorIcon } from '../../color-popover/color-popover.style';
import FillBackGround from '../../color-popover/fill.component';
import { Flex } from '../../flexbox.component';
import Popover from '../../popover.component';
import { Text } from '../../text.component';
import ToggleSwitch from '../../toggle.component';
import { ToolWrapper } from '../object-toolbar.style';
import ShadowNumeration from './shadow-numeration.component';
import { ShadowToolCol, ShadowToolRow } from './shadow-tool.style';
import { NumberInput as ColorPickerInput } from '../../number-input.component';
import { useCanvasFocus } from '../../../contexts/canvas-focus.context';

const ShadowTool: React.FC<IBasicEditorProps> = ({ editor, activeObject }) => {
  const [blur, setBlur] = useState<number>(0);
  const [offsetX, setOffsetX] = useState<number>(0);
  const [offsetY, setOffsetY] = useState<number>(0);
  const [allFilter, setAllFilter] = useState(false);
  const [color, setColor] = useState('transparent');
  const [hexColor, setHexColor] = useState<string>('#FFFFFF');
  const [enableColorEyeDrop, setEnableColorEyeDrop] = useState<boolean>(false);
  const [eyeDropColor, setEyeDropColor] = useState<string | null>(null);
  const popoverRef = useRef<{ close: () => void }>();
  const [alpha, setAlpha] = useState<number>(1);
  const [inputColor, setInputColor] = useState<string>('');
  const [isTypingColor, setIsTypingColor] = useState<boolean>(false);
  const { setIsInputFocused } = useCanvasFocus();


  const [enable, setEnable] = useState(() => {
    return Boolean(activeObject?.shadow);
  });

  const handleChangeToggle = (e: ChangeEvent<HTMLInputElement>) => {
    setAllFilter(e.target.checked);
  };

  useEffect(() => {
    if (!activeObject?.shadow) return;
    if (enable) {
      setAllFilter((editor.canvas as any).name.includes('_allFilter_true'));
    }
  }, []);

  useEffect(() => {
    if (!activeObject?.shadow) {
      const objects = editor.canvas._objects;
      const shadowProperties = objects.filter((object) => object.shadow !== null);

      if (
        shadowProperties[0]?.shadow &&
        activeObject &&
        (editor.canvas as any).name.includes('_allFilter_true')
      ) {
        activeObject.set({
          shadow: new fabric.Shadow({
            offsetX: (shadowProperties[0].shadow as any).offsetX,
            offsetY: (shadowProperties[0].shadow as any).offsetY,
            blur: (shadowProperties[0].shadow as any).blur,
            color: (shadowProperties[0].shadow as any).color,
          }),
        });
        setEnable(true);
        setAllFilter(true);
      } else {
        setEnable(true);
        activeObject &&
          activeObject.set({
            shadow: new fabric.Shadow({
              offsetX: 12,
              offsetY: 16,
              blur: 13,
              color: '#000000',
            }),
          });
      }
    }
  }, []);

  useEffect(() => {
    applyAllFilter();
    editor.canvas._objects
      .filter((object) => object.shadow !== null)
      .map((obj) => {
        if (obj.shadow instanceof fabric.Shadow) {
          const updatedShadow = new fabric.Shadow({ ...obj.shadow });
          obj.set('shadow', updatedShadow);
        }
      });
  }, [allFilter]);

  useEffect(() => {
    !isTypingColor && setInputColor(hexColor);
  }, [hexColor]);

  const handleChangeEnable = (e: ChangeEvent<HTMLInputElement>) => {
    setEnable(e.target.checked);
  };

  useEffect(() => {
    const getShadowFilter = async () => {
      if (!activeObject) return;

      const shadow = await activeObject.shadow;
      if (typeof shadow !== 'string') {
        setBlur(shadow?.blur ?? blur);
        setOffsetX(shadow?.offsetX ?? offsetX);
        setOffsetY(shadow?.offsetY ?? offsetY);
        setColor(shadow?.color ?? color);
      }
    };
    getShadowFilter();
  }, [activeObject]);

  useEffect(() => {
    if (!activeObject) return;

    const existingShadow = activeObject.shadow;
    if (
      enable &&
      (!existingShadow ||
        (typeof existingShadow !== 'string' &&
          (existingShadow.blur !== blur ||
            existingShadow.offsetX !== offsetX ||
            existingShadow.offsetY !== offsetY ||
            existingShadow.color !== color)))
    ) {
      activeObject.set({
        shadow: new fabric.Shadow({ offsetX, offsetY, blur, color }),
      });
      editor.canvas.renderAll();
    } else if (!enable && existingShadow) {
      activeObject.set({ shadow: undefined });
      editor.canvas.renderAll();
    }
  }, [blur, offsetX, offsetY, color, enable, editor, activeObject]);

  const handleChangeColor = (newColor: string) => {
    setColor(newColor);
  };

  const hexToRgba = (hexColorStr: string) => {
    const { r, g, b, a } = colord(hexColorStr as AnyColor).toRgb();
    const rgbaColor = `rgba(${r}, ${g}, ${b}, ${a})`;
    setColor(rgbaColor);
    handleChangeColor(rgbaColor);
  };

  useEffect(() => {
    if (!color) {
      return;
    }
    if (color.includes('rgba')) {
      const [r, g, b, a] = color
        .substring(5, color.length - 1)
        .split(',')
        .map((item) => parseFloat(item));
      setAlpha(a);
      setHexColor(colord({ r, g, b }).toHex().toUpperCase());
    } else {
      setHexColor(color);
    }
  }, [color]);

  const setAlphaText = () => {
    const hue = document.querySelector('div.react-colorful__alpha');
    const div = document.createElement('div');
    div.className = 'react-colorful__alpha__text';
    div.innerText = `${parseInt(String(alpha * 100))}%`;
    if (hue?.childNodes?.length === 2) {
      hue?.appendChild(div);
    } else {
      hue?.replaceChild(div, hue?.childNodes[2]);
    }
  };

  useEffect(setAlphaText, [alpha]);

  const _handleEyeDrop = () => {
    const { canvas } = editor;
    setEnableColorEyeDrop(!enableColorEyeDrop);
    const activeObject = canvas.getActiveObject();
    const objects = canvas.getObjects();
    objects.forEach((obj) => obj.set({ selectable: false }));
    handleEyeDrop(canvas, setEyeDropColor, (isClosePopover) => {
      setEnableColorEyeDrop(false);
      objects
        .filter(
          (obj) =>
            obj.name !== 'grid' &&
            obj.name !== 'watermark' &&
            !obj.name?.includes('backgroundImage') &&
            !obj.name?.includes('backgroundGradient'),
        )
        .forEach((obj) => obj.set({ selectable: true }));
      canvas.setActiveObject(activeObject as FabricTypes.Object);
      if (isClosePopover) {
        popoverRef?.current?.close();
      }
    });
  };

  const applyAllFilter = () => {
    const canvasName = (editor.canvas as { name?: string }).name;
    let name;
    if (canvasName?.includes('_allFilter_true')) {
      name = canvasName?.replace('_allFilter_true', `_allFilter_${allFilter}`);
    } else if (canvasName?.includes('_allFilter_false')) {
      name = canvasName?.replace('_allFilter_false', `_allFilter_${allFilter}`);
    } else {
      name = canvasName + `_allFilter_${allFilter}`;
    }
    (editor.canvas as { name?: string }).name = name;
  };

  const applyShadowToAll = (objects: FabricTypes.Object[], shadowProperties: IShadowOptions) => {
    if (!objects) return;
    objects.forEach((obj) => {
      if (obj.shadow) {
        obj.set({ shadow: new fabric.Shadow(shadowProperties) });
      }
    });
  };

  useEffect(() => {
    if (activeObject?.shadow === null || activeObject?.shadow === undefined) return;
    if (allFilter) {
      const objects = editor.canvas._objects;
      applyShadowToAll(objects, { offsetX, offsetY, blur, color });
    }
    requestAnimationFrame(() => {
      editor.canvas.renderAll();
    });
  }, [allFilter, offsetX, offsetY, blur, color, editor.canvas._objects, activeObject]);

  return (
    <>
      <ToolWrapper style={{ padding: 0, position: 'relative' }}>
        <ShadowToolCol>
          <ToggleSwitch checked={enable} onChange={handleChangeEnable} label="Вкл." />
        </ShadowToolCol>
        <ShadowToolCol>
          <ShadowToolRow>
            <Text>Цвет</Text>
            <Popover
              onOpen={setAlphaText}
              ref={popoverRef}
              type={'cloud'}
              relativeToParent={true}
              contentClassName={'colorPopover'}
              position="left"
              closeOnClick={false}
              content={
                <>
                  <Flex justifyContent="space-between" mb={10.5}>
                    <Text small>Цвет тени</Text>
                    <ColorPickerInput
                      fontSize="0.875rem"
                      size={7}
                      maxLength={7}
                      value={inputColor}
                      onChange={(e) => {
                        hexToRgba(e.target.value);
                        setInputColor(e.target.value);
                      }}
                      onFocus={() => {
                        setIsTypingColor(true);
                        setIsInputFocused(true);
                      }}
                      onBlur={() => {
                        setIsTypingColor(false);
                        setIsInputFocused(false);
                      }}
                    />
                  </Flex>
                  <FillBackGround
                    enableColorEyeDrop={enableColorEyeDrop}
                    eyeDropColor={eyeDropColor}
                    _handleEyeDrop={_handleEyeDrop}
                    handleChangeColor={handleChangeColor}
                    setColor={setColor}
                    showTransparentBtn={true}
                    color={color}
                    hexToRgba={hexToRgba}
                  />
                </>
              }
            >
              <ColorIcon bgType={'fill'} color={color} style={{ cursor: 'pointer' }} />
            </Popover>
          </ShadowToolRow>
        </ShadowToolCol>

        <ShadowNumeration label="Размытие" value={blur} setValue={setBlur} min={0} max={100} onFocus={() => setIsInputFocused(true)} onBlur={() => setIsInputFocused(false)} />
        <ShadowNumeration label="Смещение X" value={offsetX} setValue={setOffsetX} onFocus={() => setIsInputFocused(true)} onBlur={() => setIsInputFocused(false)} />
        <ShadowNumeration label="Смещение Y" value={offsetY} setValue={setOffsetY} onFocus={() => setIsInputFocused(true)} onBlur={() => setIsInputFocused(false)} />
        <ShadowToolCol>
          <ToggleSwitch
            checked={enable && allFilter}
            onChange={handleChangeToggle}
            label="Для всех"
          />
        </ShadowToolCol>
      </ToolWrapper>
    </>
  );
};

export default ShadowTool;

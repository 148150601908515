import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { Wrapper } from '../rates/rates.style';
import { useTranslation } from 'react-i18next';
import { Text } from '../../components/text.component';
import NewProjectModal from '../../components/new-project-modal.component.';
import { Button } from '../../components/button.component';
import { PurchaseRateTypes } from '../../constants/rates.constant';
import { useUserContext } from '../../contexts/user.context';

const Container = styled.div`
  width: 100%;
  background: #ffffff;
  padding: 1.25rem;
  display: flex;
  flex-direction: column;
  padding: 1.25rem;
  justify-content: center;
  align-items: center;
  border-radius: 1rem;
  flex: 1;
  margin-bottom: 1.25rem;
  white-space: pre-line;
  text-align: center;
  gap: 1.875rem;

  .text-container {
    display: flex;
    flex-direction: column;
    gap: 0.625rem;
  }

  .buttons {
    display: flex;
    gap: 0.625rem;
  }
`;

type StateType = {
  name: string;
  end: string;
  start: string | null;
  type: PurchaseRateTypes;
} | null;

const PaymentSuccess = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { updateUserDetails } = useUserContext();

  const [params] = useSearchParams();
  const [state, setState] = useState<StateType>(null);
  const [isOpen, setIsOpen] = useState(false);

  const modalContent: { [key: string]: any } = {
    [PurchaseRateTypes.NOW]: {
      heading: t('payment.success.paid-activated.heading', { rate: t(`rates.${state?.name}`) }),
      description: t('payment.success.paid-activated.description', { endDate: state?.end }),
    },
    [PurchaseRateTypes.LATER]: {
      heading: t('payment.success.paid.heading', { rate: t(`rates.${state?.name}`) }),
      description: t('payment.success.paid.description', {
        endDate: state?.end,
        startDate: state?.start,
      }),
    },
    [PurchaseRateTypes.RENEW]: {
      heading: t('payment.success.renewed.heading', { rate: t(`rates.${state?.name}`) }),
      description: t('payment.success.renewed.description', { endDate: state?.end }),
    },
  };

  useEffect(() => {
    if (params) {
      const type = params.get('type');
      const name = params.get('name');
      const end = params.get('end');
      const start = params.get('start');

      if (type && name && (end || start)) {
        setState((prev: any) => ({
          ...prev,
          type,
          name,
          end: end ? new Date(+end).toLocaleDateString().replaceAll('/', '.') : null,
          start: start ? new Date(+start).toLocaleDateString().replaceAll('/', '.') : null,
        }));
      }
    }
  }, [params]);

  useEffect(() => {
    updateUserDetails();
  }, []);

  return (
    <>
      {state && (
        <Wrapper>
          <Container>
            <div className="text-container">
              <Text fontSize="1.875rem" fontWeight={600}>
                {modalContent[state.type].heading}
              </Text>
              <Text>{modalContent[state.type].description}</Text>
            </div>

            <div className="buttons">
              <Button type="button" onClick={() => setIsOpen(true)} icon="file-plus">
                {' '}
                {t('payment.success.new-project')}{' '}
              </Button>
              <Button type="button" btnStyle="gray" onClick={() => navigate('/my-projects')}>
                {' '}
                {t('rates.to-project')}{' '}
              </Button>
            </div>
          </Container>

          <NewProjectModal
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
            trackKey="ecom_webapp_create_project_from_layout"
          />
        </Wrapper>
      )}
    </>
  );
};

export default PaymentSuccess;

import React, { createContext, Dispatch, SetStateAction, useContext, useState } from 'react';
import FabricTypes from 'fabric/fabric-impl';
import { FabricJSEditor } from 'fabricjs-react';
import isEqual from 'lodash.isequal';
import {
  handleDeleteImageInShape,
  handleReloadShapes,
  handleReloadTextBoxes,
  handleSetDoubleClick,
} from '../utils/editor.utils';
import { useCanvasFocus } from './canvas-focus.context';
import { EDITOR_PROPERTIES } from '../constants/editor.constants';

interface ICanvasHistoryContext {
  currentState: number;
  canvasStates: string[];
  setCanvasStates: Dispatch<SetStateAction<string[]>>;
  offCanvasEvents: (editor: FabricJSEditor) => void;
  onCanvasEvents: (editor: FabricJSEditor) => void;
  historySaveAction: (
    editor: FabricJSEditor,
    e?: FabricTypes.IEvent<MouseEvent>,
    skipCheck?: boolean,
  ) => void;
  handleUndoRedo: (editor: FabricJSEditor, action: string) => void;
  handleClearHistory: VoidFunction;
}

interface IProps {
  children: React.ReactNode;
}

const CanvasHistoryContext = createContext<ICanvasHistoryContext>({
  currentState: 0,
  canvasStates: [],
  setCanvasStates: () => null,
  offCanvasEvents: () => null,
  onCanvasEvents: () => null,
  historySaveAction: () => null,
  handleUndoRedo: () => null,
  handleClearHistory: () => null,
});
export const useCanvasHistory = (): ICanvasHistoryContext => useContext(CanvasHistoryContext);

const preventOutOfBounds = (obj: FabricTypes.Object) => {
  const PIECE = 10;

  obj.on('moving', function () {
    const canvas = obj.canvas as FabricTypes.Canvas;
    if (
      obj.top &&
      obj.left &&
      canvas.height &&
      canvas.width &&
      obj.height &&
      obj.width &&
      obj.scaleX &&
      obj.scaleY
    ) {
      const minLeft = PIECE - obj.width * obj.scaleX;
      const minTop = PIECE - obj.height * obj.scaleY;
      const maxLeft = canvas.width - PIECE;
      const maxTop = canvas.height - PIECE;

      if (obj.left < minLeft) {
        obj.set('left', minLeft);
      }
      if (obj.top < minTop) {
        obj.set('top', minTop);
      }
      if (obj.left > maxLeft) {
        obj.set('left', maxLeft);
      }
      if (obj.top > maxTop) {
        obj.set('top', maxTop);
      }

      obj.setCoords();
    }
  });
};

export const CanvasHistoryProvider: React.FC<IProps> = ({ children }: IProps) => {
  const { setIsFocused } = useCanvasFocus();
  const [canvasStates, setCanvasStates] = useState<string[]>([]);
  const [currentState, setCurrentState] = useState(0);

  const offCanvasEvents = (editor: FabricJSEditor) => {
    const { canvas } = editor;
    // canvas.off('object:added');
    canvas.off('object:removed');
  };

  const onCanvasEvents = (editor: FabricJSEditor) => {
    const { canvas } = editor;
    // canvas.on('object:added', (e) => historySaveAction(editor, e, true));
    canvas.on('object:added', (e) => {
      setIsFocused(true);
      if (e.target) {
        preventOutOfBounds(e.target);
      }
    });

    canvas.on('object:removed', async (e) => {
      await handleDeleteImageInShape(canvas, e.target);
      setIsFocused(false);
    });

    canvas._objects.forEach((obj) => {
      preventOutOfBounds(obj);
    });
  };

  const historySaveAction = (
    editor: FabricJSEditor,
    e?: FabricTypes.IEvent<MouseEvent>,
    skipCheck?: boolean,
  ) => {
    if (e?.target?.name === 'grid') {
      return;
    }

    const { canvas } = editor;
    const lastStateIndex = canvasStates.length - 1;
    const lastState = canvasStates[lastStateIndex];
    const state = JSON.stringify(canvas.toJSON(EDITOR_PROPERTIES));
    if (skipCheck || (lastState && !isEqual(lastState, state))) {
      setCurrentState((prevState) => prevState + 1);
      if (currentState < lastStateIndex) {
        const statesCopy = canvasStates.slice(0, currentState + 1);
        setCanvasStates([...statesCopy, state]);
      } else {
        setCanvasStates((prevState) => [...prevState, state]);
      }
    }
  };

  const reloadObjectEvents = (canvas: FabricTypes.Canvas) => {
    canvas._objects.forEach((obj) => {
      handleSetDoubleClick(canvas, obj);
      handleReloadTextBoxes(obj);
      handleReloadShapes(canvas, obj);
    });
  };

  const handleUndoRedo = (editor: FabricJSEditor, action: string) => {
    const { canvas } = editor;
    canvas.discardActiveObject();

    switch (action) {
      case 'undo': {
        if (currentState > 0) {
          const json = JSON.parse(canvasStates[currentState - 1]);
          offCanvasEvents(editor);
          canvas.loadFromJSON(json, () => {
            onCanvasEvents(editor);
            reloadObjectEvents(canvas);
            setCurrentState(currentState - 1);
            canvas.renderAll();
          });
        }
        break;
      }
      case 'redo': {
        const json = JSON.parse(canvasStates[currentState + 1]);
        offCanvasEvents(editor);
        canvas.loadFromJSON(json, () => {
          onCanvasEvents(editor);
          reloadObjectEvents(canvas);
          setCurrentState(currentState + 1);
          canvas.renderAll();
        });
        break;
      }
    }
  };

  const handleClearHistory = () => {
    setCanvasStates([]);
    setCurrentState(0);
  };

  return (
    <CanvasHistoryContext.Provider
      value={{
        currentState,
        canvasStates,
        setCanvasStates,
        offCanvasEvents,
        onCanvasEvents,
        historySaveAction,
        handleUndoRedo,
        handleClearHistory,
      }}
    >
      {children}
    </CanvasHistoryContext.Provider>
  );
};

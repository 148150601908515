import React, { useEffect, useState } from 'react';
import Components, {
  WhiteHeader,
  FoldersWrapper,
  CustomButton,
  ContentWrap,
  ProjectColumn,
} from './trash.style';
import { MenuList } from '../menu';
import {
  deleteForever,
  recoverItem,
  getLibraryImages,
  getProjects,
  deleteAll,
} from '../../requests/trash.requests';
import { useTranslation } from 'react-i18next';
import { Text } from '../../components/text.component';
import { ITrashList } from '../../interfaces/projects.interface';
import { Row } from '../../components/grid.component';
import { Card } from '../../components/card.component';
import styled, { css } from 'styled-components';
import { Flex, FlexCol } from '../../components/flexbox.component';
import { calculateTillDays } from '../../utils/dateCalculate';
import { Button } from '../../components/button.component';
import { useLocation, useNavigate } from 'react-router-dom';
import ProjectListLoader from '../../components/loaders/project-list.component';
import ImageListLoader from '../../components/loaders/image-list.component';
import useDebounce from '../../hooks/use-debounce.hooks';
import PageHeader from '../../components/page-header.component';
import { ASCSort, SortOption } from '../../interfaces/editor.interface';
import { FILE_SORT, FOLDER_SORT } from '../../constants/shared.constants';
import useMouseDown from '../../hooks/use-mouse-down.hooks';
import CardCheckboxOverlay, { InputOverlay } from '../../components/card.checkbox.overlay';
import MultipleChoice from '../../components/multiple-choice.component';
import RateModal from '../../components/rates/rate-modal.component';
import { useNotification } from '../../contexts/notification.context';
import DeleteFolderForever from '../../components/delete-folder-forever.component';
import { useModal } from '../../contexts/modal.context';
import { Modal } from '../../components/modal.component';
import { RateModalContent } from '../../components/rates/rates.style';
import { useUserContext } from '../../contexts/user.context';
import PagePagination from '../../components/pagination/page-pagination';
import { RightColumn } from '../my-projects/my-projects.style';
import { RateNames } from '../../constants/rates.constant';

const tabs: TabKey[] = ['PROJECT', 'IMAGE'];
const COUNT_OF_LOADER_PROJECTS = 8;

const CustomCard = styled(Card) <{ activeTab: TabKey; active?: boolean; highlight?: boolean }>`
  height: 160px;
  width: 240px;
  justify-content: center;
  display: flex;
  & > ${Text} {
    margin-bottom: 0;
  }

  img {
    max-height: 140px;
    max-width: 220px;
  }

  ${({ highlight }) =>
    highlight &&
    css`
      &:hover {
        border: 2px solid #6620c7 !important;
        & > ${InputOverlay} {
          border: 2px solid #6620c7 !important;
        }
      }
    `}

  border: 2px solid transparent;
  ${({ highlight, active }) =>
    highlight &&
    css`
      border: 2px solid ${active ? '#6620C7' : '#cccccc'};
      user-select: none;
    `}
`;

export const CustomFolderCard = styled(Card)`
  height: 160px;
  width: 240px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  word-wrap: break-word;
  & > ${Text} {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`;

const EmptyTrash = styled(Text)`
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  color: #00000066;
`;

type TabKey = 'PROJECT' | 'IMAGE';

export const TrashPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setNotification, closeNotification } = useNotification();
  const { userData, updateUserDetails } = useUserContext();
  const modal = useModal();

  const [activeTab, setActiveTab] = useState<TabKey>('PROJECT');
  const [listItems, setListItems] = useState<ITrashList>({});
  const [recoveredItem, setRecoveredItem] = useState<any>();
  const [timeoutId, setTimeoutId] = useState<string | number | NodeJS.Timeout | undefined>();
  const [loadingImageList, setLoadingImageList] = useState<boolean>(false);
  const [loadingProjectList, setLoadingProjectList] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<SortOption>(FOLDER_SORT[0]);
  const [selectedOptionFile, setSelectedOptionFile] = useState<SortOption>(FILE_SORT[0]);
  const [sortAsc, setSortAsc] = useState<ASCSort>('DESC');
  const [didMount, setDidMount] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isRecoverModalOpen, setIsRecoverModalOpen] = useState(false);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [totalProjectElements, setTotalProjectElements] = useState<number>(0);
  const [totalImageElements, setTotalImageElements] = useState<number>(0);
  const [totalImagePage, setTotalImagePage] = useState<number>(0);

  // highlight
  const [highlight, setHighlight] = useState<boolean>(false);
  const [highlightedProjects, setHighlightedProjects] = useState<number[]>([]);
  const [highlightedFiles, setHighlightedFiles] = useState<number[]>([]);
  const [projectPage, setProjectPage] = useState<number>(0);
  const [imagePage, setImagePage] = useState<number>(0);
  const [customCardOption, setCustomCardOption] = useState<boolean>(false);

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const searchValue = params.get('q');
  const debouncedSearchValue = useDebounce(searchValue as string, 500);
  const isRightMouseDown = useMouseDown();

  const cardOptions = (item?: any) => {
    return (
      <FlexCol gap={6} alignItems={'flex-start'} style={{ width: 'max-content' }}>
        <CustomButton onClick={() => handleRecoverOpen(item)}>{t(`action.recover`)}</CustomButton>
        <CustomButton
          onClick={() => {
            handleDeleteItem(item.id);
          }}
        >
          {t(`action.delete`)}
        </CustomButton>
      </FlexCol>
    );
  };

  const isTrashEmpty = (tab: string) =>
    listItems?.[tab as keyof ITrashList]?.length == 0 ||
    listItems?.[tab as keyof ITrashList] == null;

  const getAllList = () => {
    getLibraryImagesList(
      debouncedSearchValue as string,
      selectedOptionFile.value,
      sortAsc,
      imagePage,
    );
    getProjectsList(debouncedSearchValue as string, selectedOption.value, sortAsc, projectPage);
  };

  const getLibraryImagesList = (
    name?: string,
    sortingType?: string,
    orderType?: string,
    page?: number,
  ) => {
    setLoadingImageList(true);
    getLibraryImages(name, sortingType, orderType, page).then((res) =>
      setListItems((prevState) => {
        setLoadingImageList(false);
        setTotalImagePage(res.totalPages);
        setTotalImageElements(res.size);
        return { ...prevState, IMAGE: res.list };
      }),
    );
  };

  const getProjectsList = (
    name?: string,
    sortingType?: string,
    orderType?: string,
    page?: number,
  ) => {
    setLoadingProjectList(true);
    getProjects(name, sortingType, orderType, page).then((res) => {
      setListItems((prevState) => {
        setLoadingProjectList(false);
        setTotalPages(res.totalPages);
        setTotalProjectElements(res.size);
        return { ...prevState, PROJECT: res.list };
      });
    });
  };

  const handleDeleteAll = () => {
    const content = <DeleteFolderForever message={t('deleteMessages.ALL')} />;

    modal.open(content, {
      header: `Хотите очистить корзину?`,
      headerAlign: 'left',
      width: 540,
      handleOk: () => {
        deleteAll(activeTab).then(() => {
          setNotification({
            text: 'Вы очистили корзину',
          });
          setListItems((prevState) => ({ ...prevState, [activeTab]: [] }));
          if (activeTab === 'PROJECT') {
            setTotalProjectElements(0);
          } else {
            setTotalImageElements(0);
          }
          updateUserDetails();
        });
        modal.close();
      },
      handleCancel: () => {
        modal.close();
      },
    });
  };

  const handleDeleteItem = (id?: number) => {
    const content = <DeleteFolderForever message={t(`deleteMessages.${activeTab}`)} />;

    modal.open(content, {
      header: `Хотите удалить ${activeTab === 'IMAGE' ? 'файл' : 'проект'}?`,
      headerAlign: 'left',
      width: 540,
      handleOk: () => {
        deleteForever(id, activeTab).then(() => {
          setNotification({
            text: `Вы удалили ${t(`trash.${activeTab}`)}`,
          });
          const filteredImages = (listItems[activeTab] as any[])?.filter(
            (item: any) => item.id !== id,
          );
          setListItems((prevState) => ({
            ...prevState,
            [activeTab]: filteredImages,
          }));
          updateUserDetails();
        });
        modal.close();
      },
      handleCancel: () => {
        modal.close();
      },
    });
  };

  const handleRecoverOpen = (item?: any) => {
    setRecoveredItem(item);
    if (activeTab === 'PROJECT') {
      if (userData && userData.rate.availableProjectCount === 0) {
        setIsOpen(true);
      } else {
        setIsRecoverModalOpen(true);
      }
    } else {
      handleRecoverItem(item);
    }
  };

  const handleRecoverItem = (item?: any) => {
    setRecoveredItem(item);
    recoverItem(item.id, activeTab)
      .then((res: any) => {
        if (res.success) {
          setNotification({
            text: `Вы восстановили ${t(`trash.${activeTab}`)}`,
            handleFunction: () => {
              handleGoToButtonClick();
              closeNotification();
            },
            buttonText: t(`trash.${activeTab}_b`)!,
            icon: 'arrow-right',
          });
          getAllList();
          updateUserDetails();
        } else if (res.exceptionType === 'TARIFF_LIMITATION') {
          closeNotification();
          setIsOpen(true);
        } else if (res.exceptionType === 'LIMIT_EXCEEDED') {
          setIsRecoverModalOpen(true);
          closeNotification();
        }
      })
      .finally(() => setIsRecoverModalOpen(false));
  };

  const handleGoToButtonClick = () => {
    if (activeTab === 'PROJECT') {
      navigate('/my-projects');
    } else if (activeTab === 'IMAGE') {
      navigate('/files');
    } else if (activeTab === 'FOLDER') {
      navigate('/folders/item', {
        state: { folderInfo: recoveredItem },
      });
    }
  };

  const checkLimits = () =>
    userData &&
    (userData.rate.name === RateNames.UNLIMITED ||
      (userData.rate.projectLimit > userData.rate.existingProjectCount &&
        userData.rate.availableProjectCount > 0));

  useEffect(() => {
    if (sortAsc === 'ASC') setSortAsc('DESC');
    else didMount && getAllList();
  }, [selectedOption.value, selectedOptionFile.value]);

  useEffect(() => {
    if (didMount && debouncedSearchValue !== null) {
      getAllList();
    }
  }, [sortAsc, debouncedSearchValue, projectPage, imagePage]);

  useEffect(() => {
    if (didMount && searchValue === null) {
      getAllList();
    }
  }, [didMount, sortAsc, debouncedSearchValue, projectPage, imagePage]);

  useEffect(() => setDidMount(true), []);

  useEffect(() => {
    setHighlightedProjects([]);
    setHighlightedFiles([]);
  }, [activeTab]);

  const allEmpty =
    activeTab === 'PROJECT'
      ? listItems.PROJECT?.length === 0
      : activeTab === 'IMAGE' && listItems.IMAGE?.length === 0;

  return (
    <Components.Wrapper>
      <MenuList />
      <RightColumn>
        <Components.PageWrap>
          <WhiteHeader>
            {activeTab !== 'IMAGE' ? (
              <PageHeader
                pageTitle={'Корзина'}
                sortType={'folderSort'}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
                setSortOrder={setSortAsc}
                sortOrder={sortAsc}
                highlight={highlight}
                setHighlight={setHighlight}
                isLoading={loadingProjectList}
                noneBackground
              />
            ) : (
              <PageHeader
                pageTitle={'Корзина'}
                sortType={'fileSort'}
                selectedOption={selectedOptionFile}
                setSelectedOption={setSelectedOptionFile}
                setSortOrder={setSortAsc}
                sortOrder={sortAsc}
                highlight={highlight}
                setHighlight={setHighlight}
                isLoading={loadingImageList}
                noneBackground
              />
            )}

            <FoldersWrapper>
              <div>
                {tabs.map((item, idx) => (
                  <CustomButton
                    key={idx}
                    active={activeTab === item && !isTrashEmpty(item)}
                    onClick={() => setActiveTab(item)}
                    disabled={isTrashEmpty(item)}
                  >
                    {t(`projects.${item}`)}
                    {((listItems.PROJECT && !loadingProjectList) ||
                      (listItems.IMAGE && !loadingImageList)) &&
                      listItems?.[item]?.length !== undefined &&
                      ` (${item === 'PROJECT' ? totalProjectElements : totalImageElements})`}
                  </CustomButton>
                ))}
              </div>
              <Button
                icon="trash"
                btnStyle={'cancel'}
                onClick={handleDeleteAll}
                disabled={listItems?.[activeTab as keyof ITrashList]?.length === 0}
                active={listItems?.[activeTab as keyof ITrashList]?.length !== 0}
              >
                {t(`projects.clearTrash`)}
              </Button>
            </FoldersWrapper>
          </WhiteHeader>

          <ContentWrap>
            <Row adaptive>
              {activeTab === 'PROJECT' &&
                (loadingProjectList ? (
                  <ProjectListLoader count={COUNT_OF_LOADER_PROJECTS} />
                ) : !isTrashEmpty('PROJECT') ? (
                  listItems.PROJECT?.map((item) => (
                    <ProjectColumn key={item.id}>
                      <Card
                        customOptions={!highlight && cardOptions(item)}
                        active={highlightedProjects.includes(item.id)}
                        highlight={highlight}
                      >
                        <CardCheckboxOverlay
                          cardId={item.id}
                          highlight={highlight}
                          highlightedCards={highlightedProjects}
                          setHighlightedCard={setHighlightedProjects}
                          isRightMouseDown={isRightMouseDown}
                        />
                        <Flex gap={4} mb={10}>
                          {/* <FlexCol gap={4} style={{ maxHeight: '13.625rem', overflow: 'hidden' }}>
                        {item.projectItems?.map((projectItem) => (
                          <Components.Thumbnail key={projectItem.id} />
                        ))}
                      </FlexCol> */}
                          <Components.Thumbnail
                            squared
                            url={
                              item.projectItems[0]
                                ? `/api/project/load/image/png?imageId=${item.projectItems[0].modifiedImageId}&imageSizeType=ICON`
                                : ''
                            }
                          />
                        </Flex>
                        <Text truncate rows={item.folderName ? 2 : 3} fontSize="16px">
                          {item.projectName || t('myProjects.withoutName')}
                        </Text>
                        <Components.TimeWrap>
                          <span className="icon-timer"></span>
                          <Text>{calculateTillDays(item.completeDeletionDate)} д</Text>
                        </Components.TimeWrap>
                      </Card>
                    </ProjectColumn>
                  ))
                ) : !debouncedSearchValue && allEmpty ? (
                  <EmptyTrash>{t('projects.emptyTrash')}</EmptyTrash>
                ) : (
                  debouncedSearchValue &&
                  allEmpty && (
                    <>
                      Тут по вашему запросу ничего не найдено. Посмотрите в других вкладках или
                      повторите попытку, набрав другие слова.
                    </>
                  )
                ))}
              {activeTab === 'IMAGE' &&
                (loadingImageList ? (
                  <ImageListLoader />
                ) : !isTrashEmpty('IMAGE') ? (
                  listItems.IMAGE?.map((item) => (
                    <Components.Column key={item.id}>
                      <CustomCard
                        onPopoverActiveChange={setCustomCardOption}
                        active={highlightedFiles.includes(item.id)}
                        highlight={highlight}
                        activeTab={activeTab}
                        customOptions={!highlight && cardOptions(item)}
                        {...(!customCardOption && {
                          'data-tooltip': item.imageName + '.' + item.imageType?.toLowerCase(),
                        })}
                        {...(!customCardOption && { 'data-tooltip-position': 'bottom' })}
                      >
                        <CardCheckboxOverlay
                          cardId={item.id}
                          highlight={highlight}
                          highlightedCards={highlightedFiles}
                          setHighlightedCard={setHighlightedFiles}
                          isRightMouseDown={isRightMouseDown}
                        />
                        <img
                          src={`/api/project/load/image/png?imageId=${item.imageId}&imageSizeType=ICON`}
                          alt="img"
                        />
                        <Components.TimeWrap>
                          <span className="icon-timer"></span>
                          <Text>{calculateTillDays(item.completeDeletionDate)} д</Text>
                        </Components.TimeWrap>
                      </CustomCard>
                    </Components.Column>
                  ))
                ) : (
                  <EmptyTrash>{t('projects.emptyTrash')}</EmptyTrash>
                ))}
            </Row>
            <PagePagination
              totalCount={activeTab === 'PROJECT' ? totalPages : totalImagePage}
              page={activeTab === 'PROJECT' ? projectPage : imagePage}
              setPage={activeTab === 'PROJECT' ? setProjectPage : setImagePage}
            />
          </ContentWrap>
          <RateModal isOpen={isOpen} onClose={() => setIsOpen(false)} isMore />

          {isRecoverModalOpen && (
            <>
              {
                <Modal width="33.75rem" onClose={() => setIsRecoverModalOpen(false)}>
                  <RateModalContent minWidth="30.75rem">
                    {checkLimits() ? (
                      <>
                        <Text strong fontSize="1.4375rem">
                          {t('want-to-recover')}
                        </Text>
                        <Text>{t('recover-reminder')}</Text>
                        <div className="buttons">
                          <Button
                            btnStyle="cancel"
                            style={{ padding: '0.8125rem 0' }}
                            onClick={() => setIsRecoverModalOpen(false)}
                          >
                            {t('rates.decide-later')}
                          </Button>
                          <Button
                            btnStyle="primary"
                            onClick={() => handleRecoverItem(recoveredItem)}
                          >
                            {t('still-recover')}
                          </Button>
                        </div>
                      </>
                    ) : (
                      <>
                        <Text strong fontSize="1.4375rem">
                          Сократите количество проектов
                        </Text>
                        <Text>
                          Вы не можете восстановить проект(ты), так как количество текущих проектов
                          равно лимиту по выбранному тарифу «{t(`rates.${userData?.rate.name}`)}».
                        </Text>
                        <Text>
                          У вас {userData?.rate.projectLimit} текущих проектов. Перенесите
                          неактуальные проекты в корзину и попробуйте восстановить нужные проект(ы)
                          из корзины еще раз.
                        </Text>

                        <div className="buttons">
                          <Button
                            btnStyle="cancel"
                            style={{ padding: '0.8125rem 0' }}
                            onClick={() => setIsRecoverModalOpen(false)}
                          >
                            {t('rates.decide-later')}
                          </Button>
                          <div className="df">
                            <Button btnStyle="gray" onClick={() => navigate('/my-projects')}>
                              {t('rates.to-project')}
                            </Button>
                            <Button
                              btnStyle="primary"
                              icon="plus"
                              onClick={() => {
                                setIsRecoverModalOpen(false);
                                setIsOpen(true);
                              }}
                            >
                              {t('rates.moreProjects')}
                            </Button>
                          </div>
                        </div>
                      </>
                    )}
                  </RateModalContent>
                </Modal>
              }
            </>
          )}
        </Components.PageWrap>
      </RightColumn>
      {highlight && (
        <MultipleChoice
          refreshRequest={
            activeTab === 'PROJECT'
              ? () =>
                getProjectsList(
                  debouncedSearchValue as string,
                  selectedOption.value,
                  sortAsc,
                  projectPage,
                )
              : () =>
                getLibraryImagesList(
                  debouncedSearchValue as string,
                  selectedOption.value,
                  sortAsc,
                  imagePage,
                )
          }
          type="trash"
          itemType={activeTab}
          choiceList={
            activeTab === 'PROJECT' ? listItems.PROJECT : activeTab === 'IMAGE' && listItems.IMAGE
          }
          setHighlightedIds={activeTab === 'PROJECT' ? setHighlightedProjects : setHighlightedFiles}
          highlightedList={activeTab === 'PROJECT' ? highlightedProjects : highlightedFiles}
          setHighlight={setHighlight}
        />
      )}
    </Components.Wrapper>
  );
};

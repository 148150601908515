import React from 'react';
import { FlexCol } from '../../../components/flexbox.component';
import SkeletonLoader from '../../../components/skeleton-loader.component';
import NoPreview from '../components/no-preview';

const WildberriesLoader: React.FC<{ noneAnimation?: boolean | undefined, isSquared?: boolean | undefined }> = ({
  noneAnimation,
  isSquared
}) => {
  return (
    <>
      {!isSquared
        ? <SkeletonLoader width="225px" height="300px" noneAnimation={noneAnimation} />
        : <NoPreview style={{ borderRadius: '10px', width: '225px', height: '300px' }} />}
      <FlexCol gap={5}>
        <SkeletonLoader width="96px" height="20px" noneAnimation={noneAnimation} />
        <SkeletonLoader width="200px" height="20px" noneAnimation={noneAnimation} />
        <SkeletonLoader width="130px" height="20px" noneAnimation={noneAnimation} />
        <SkeletonLoader width="96px" height="20px" noneAnimation={noneAnimation} />
      </FlexCol>
    </>
  );
};

export default WildberriesLoader;

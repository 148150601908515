import React from 'react';
import { useTranslation } from 'react-i18next';
import { FooterModal, ModalDescription } from './object-toolbar/infographic-tool/infographic.style';
import { Flex } from './flexbox.component';
import { Button } from './button.component';
import { IModalOptions } from '../interfaces/modal.interface';

const DeleteFolderForever: React.FC<IModalOptions & { message: string }> = ({
  handleCancel,
  handleOk,
  message,
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <ModalDescription>{message}</ModalDescription>
      <FooterModal style={{ marginTop: '20px' }}>
        <Flex justifyContent="space-between" style={{ width: '100%' }}>
          <Button
            style={{ padding: '0.8125rem 0' }}
            btnStyle={'cancel'}
            onClick={() => {
              if (handleCancel) {
                handleCancel();
              }
            }}
          >
            {t('action.cancel')}
          </Button>
          <Button icon="trash" iconPosition={undefined} onClick={handleOk}>
            Удалить
          </Button>
        </Flex>
      </FooterModal>
    </div>
  );
};

export default DeleteFolderForever;

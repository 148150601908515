import React, { FC } from 'react'
import { NoSquareWBPreview } from '../preview-image.style'
import { Text } from '../../../components/text.component'

const NoPreview: FC<{ style?: any, text?: string }> = ({ style, text = 'Wildberries' }) => {
  const getText = () => text === 'Ozon' ? "У Ozon для этого типа нет квадратного формата" : `У ${text} нет квадратного формата`

  return (
    <NoSquareWBPreview
      style={{ ...style, padding: 5 }}
    >
      <Text fontSize='0.875rem' color='infoGray' fontWeight={600}>{getText()}</Text>
    </NoSquareWBPreview>
  )
}

export default NoPreview
import { FabricJSEditor } from 'fabricjs-react';
import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCanvasHistory } from '../../contexts/canvas-history.context';
import {
  CanvasRatio,
  IObjectWithId,
  IPopoverActive,
  IToolbarAction,
} from '../../interfaces/editor.interface';
import Popover from '../popover.component';
import TextPopover from '../text-popover.component';
import IconsPopover from './icons-popover.component';
import InfographicPopover from './infographic-popover/infographic-popover.component';
import AllImagePopover from './image-popover/all-image-popover.component';
import { ShapesPopover } from './shapes-popover.component';
import { ToolbarBtn, ToolbarWrapper } from './toolbar.style';
import TemplatePopover from './template-popover/template-popover.component';
import { IProject } from '../../interfaces/projects.interface';
import { useShared } from '../../contexts/shared.context';

const actionToTitle: { [key: string]: string } = {
  text: 'Добавить текст',
  shapes: 'Добавить фигуру',
  icons: 'Добавить иконку',
  infographics: 'Готовая инфографика',
  templates: 'Готовые шаблоны',
};

export const LeftToolbar: React.FC<{
  editor: FabricJSEditor;
  canvasRatio: CanvasRatio;
  handleSavePage: () => Promise<boolean>;
  project: IProject;
  setProject: Dispatch<SetStateAction<IProject>>;
  setActiveIndex: Dispatch<SetStateAction<number | undefined>>;
  refreshProjectCard: () => void;
}> = ({
  editor,
  canvasRatio,
  handleSavePage,
  project,
  setProject,
  setActiveIndex,
  refreshProjectCard,
}) => {
  const { t } = useTranslation();
  const { historySaveAction } = useCanvasHistory();
  const { fileDropped, projectLoading } = useShared();

  const [popoverActive, setPopoverActive] = useState<IPopoverActive>({});

  const imagePopoverRef = useRef<{ close: () => void }>();

  const addToCanvas = (object: IObjectWithId) => {
    const { canvas } = editor as FabricJSEditor;
    const id = Math.random().toString(16).slice(2);
    object.set({ id: object.id ?? `${object.type}_${id}`, centeredRotation: true });
    canvas.add(object);
    canvas.setActiveObject(object);
    canvas.renderAll();
    imagePopoverRef.current?.close();
    historySaveAction(editor);
    refreshProjectCard();
  };

  const actions: IToolbarAction[] = [
    {
      key: 'text',
      icon: 'text-cursor',
      popover: <TextPopover addToCanvas={addToCanvas} editor={editor} />,
    },
    {
      key: 'shapes',
      icon: 'triangle',
      popover: <ShapesPopover editor={editor} addToCanvas={addToCanvas} />,
    },
    {
      key: 'icons',
      icon: 'star',
      popover: <IconsPopover canvasRatio={canvasRatio} editor={editor} addToCanvas={addToCanvas} />,
    },
    {
      key: 'infographics',
      icon: 'components',
      popover: (
        <InfographicPopover
          editor={editor}
          key="infographics"
          refreshProjectCard={refreshProjectCard}
        />
      ),
    },
    {
      key: 'templates',
      icon: 'templates',
      popover: (
        <TemplatePopover
          canvasRatio={canvasRatio}
          editor={editor}
          project={project}
          setProject={setProject}
          setActiveIndex={setActiveIndex}
          handleSavePage={handleSavePage}
          key="templates"
        />
      ),
    },
  ];

  const handleSetPopoverActive = (key: string) => (value: boolean) => {
    setPopoverActive((prevState) => ({ ...prevState, [key]: value }));
  };

  useEffect(() => {
    if (fileDropped.message !== '') {
      setPopoverActive((prevState) => ({ ...prevState, image: true }));
    }
  }, [fileDropped]);

  if (projectLoading) return null;

  return (
    <ToolbarWrapper align="left">
      <Popover
        ref={imagePopoverRef}
        type={'cloud'}
        position={'right'}
        setIsActive={handleSetPopoverActive('image')}
        className={popoverActive['image'] ? 'active' : ''}
        closeOnClick={false}
        relativeToParent={true}
        content={
          <AllImagePopover
            addToCanvas={addToCanvas}
            canvasRatio={canvasRatio}
            editor={editor}
            key="image"
          />
        }
      >
        <ToolbarBtn
          active={popoverActive?.image}
          data-tooltip={popoverActive?.image ? null : t('action.uploadImage')}
        >
          <span className="icon-image-plus" />
        </ToolbarBtn>
      </Popover>
      {actions.map((action, index) =>
        action.popover ? (
          <Popover
            closeOnClick={!['icons', 'infographics', 'templates'].includes(action.key)}
            key={`${action.key}_${index}`}
            ref={imagePopoverRef}
            type={'cloud'}
            position={'right'}
            setIsActive={handleSetPopoverActive(action.key)}
            content={action.popover}
            className={popoverActive[action.key as keyof IPopoverActive] ? 'active' : ''}
            paddingLess={action.key === 'text'}
            relativeToParent={true}
          >
            <ToolbarBtn
              active={popoverActive[action.key as keyof IPopoverActive]}
              data-tooltip={actionToTitle[action.key]}
            >
              <span className={`icon-${action.icon}`} />
            </ToolbarBtn>
          </Popover>
        ) : (
          <ToolbarBtn
            key={`${action.key}_${index}`}
            onClick={action.handler}
            data-tooltip={actionToTitle[action.key]}
          >
            <span className={`icon-${action.icon}`} />
          </ToolbarBtn>
        ),
      )}
    </ToolbarWrapper>
  );
};

import React, { useEffect, useState } from 'react';
import { ProgressBar, ProjectsCounterWrapper } from './rates.style';
import { Text } from '../text.component';
import { Button } from '../button.component';
import { useTranslation } from 'react-i18next';
import RateModal from './rate-modal.component';
import { RateNames } from '../../constants/rates.constant';
import { useUserContext } from '../../contexts/user.context';

const ProjectsCounter = () => {
  const { t } = useTranslation();
  const { userData } = useUserContext();
  const [rate, setRate] = useState<any>(null);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (userData) {
      const { rate } = userData;
      setRate(rate);
    }
  }, [
    userData,
    userData?.rate,
    userData?.rate?.existingProjectCount,
    userData?.rate?.projectLimit,
    userData?.rate?.availableProjectCount,
  ]);

  return (
    <>
      {' '}
      {rate && rate.name !== RateNames.UNLIMITED && (
        <ProjectsCounterWrapper>
          <div className="top">
            <div className="head">
              <Text fontSize="0.875rem" color="infoGray" strong>
                Текущий лимит:
              </Text>
              <Text>
                {rate?.projectLimit - rate?.availableProjectCount}/{rate?.projectLimit} создано
                проектов
              </Text>
            </div>
            <ProgressBar
              percentage={
                ((rate?.projectLimit - rate?.availableProjectCount) / rate?.projectLimit) * 100
              }
            />
          </div>
          <div className="bot">
            <Button icon="plus" btnStyle="gray" onClick={() => setIsOpen(true)}>
              {t('rates.moreProjects')}{' '}
            </Button>
          </div>

          <RateModal isOpen={isOpen} onClose={() => setIsOpen(false)} isMore />
        </ProjectsCounterWrapper>
      )}
    </>
  );
};

export default ProjectsCounter;

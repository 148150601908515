import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as amplitude from '@amplitude/analytics-browser';
import { Button } from '../../components/button.component';
import { Headline } from '../../components/headline.component';
import { Text } from '../../components/text.component';
import logo from '../../assets/images/logo.svg';
import Components, { WhiteBox, ButtonsWrapper } from './reset-password.style';
import Input from '../../components/input.component';
import { StyledLink } from '../../components/link.component';
import { forgotPassword } from '../../requests/auth.request';

export const ResetPassword = () => {
  const { t } = useTranslation();
  const [showForm, setShowForm] = useState<boolean>(true);
  const [error, setError] = useState<string>('');
  const [email, setEmail] = useState<string>('');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setError('');
    setEmail(event.target.value);
  };

  const handleSubmit = () => {
    const isValidEmail = /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/.test(email);
    if (!email || !isValidEmail) {
      setError('Введите валидный email');
      return;
    }
    amplitude.track('ecom_webapp_login_click');
    forgotPassword({ email: email.toLocaleLowerCase() })
      .then(() => setShowForm(false))
      .catch((error) => setError(error.response.data.msg || 'Произошла ошибка'));
  };

  return (
    <Components.Wrapper>
      <img
        src={logo}
        alt={'mark it'}
        height={'62.04px'}
        width={'98.18px'}
        style={{ position: 'absolute', left: '10px' }}
      />
      <WhiteBox>
        <Headline>{t('recover.title')}</Headline>
        {showForm ? (
          <>
            <Text color="#000000">{t('recover.info')}</Text>
            <Input
              label="Email"
              type="email"
              error={!!error}
              errorText={error}
              value={email}
              onChange={handleChange}
              showButtons={false}
            />
            <Button type="submit" onClick={handleSubmit}>
              {t('recover.action')}
            </Button>
          </>
        ) : (
          <Text color="#000000" fontSize={'16px'}>
            {t('recover.send', { email: email })}
          </Text>
        )}
        <ButtonsWrapper>
          <StyledLink link={'/login'} text={t('login.action')} color="black" />
          <StyledLink link={'/register'} text={t('register.action')} color="black" />
        </ButtonsWrapper>
      </WhiteBox>
    </Components.Wrapper>
  );
};

import React, { InputHTMLAttributes, useCallback, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { Text } from './text.component';
import { useCanvasFocus } from '../contexts/canvas-focus.context';

type ValueType = string | number | undefined | readonly string[];

interface Props extends InputHTMLAttributes<any> {
  label: string;
  changeInstantly?: boolean;
  onSave?: () => void;
  error?: boolean;
  errorText?: string;
  showButtons?: boolean;
  minlength?: number;
  maxlength?: number;
  isTextArea?: boolean;
  mt?: boolean;
}

const Wrapper = styled.div<any>`
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
  width: 100%;
  height: ${(p) => (p.isTextArea ? 'unset' : '50px')};

  justify-content: flex-end;
  border-bottom: ${(props) =>
    props.error ? '2px solid rgba(223, 44, 55, 1)' : '2px solid rgba(0, 0, 0, 0.15)'};
  background-color: transparent;
  position: relative;

  &:focus-within,
  &:hover {
    border-bottom: ${(props) =>
      props.error ? '2px solid rgba(223, 44, 55, 1)' : '2px solid rgba(102, 32, 199, 0.4)'};
  }

  & > span[class^='icon-'] {
    font-size: 1.125rem;
    position: absolute;
    bottom: 0.5rem;
    left: 94%;
    cursor: pointer;
  }

  ${(p) =>
    p.isTextArea &&
    css`
      min-height: ${(p: any) => p.height};
    `};
`;

const StyledInput = styled.input<any>`
  appearance: none;
  width: 100%;
  margin-top: ${({ isActive, mt }) => (mt ? '18px' : isActive ? '18px' : '0')};
  border: none;
  font-size: 16px;
  line-height: 21.86px;
  background-color: transparent;

  &:hover {
    cursor: pointer;
  }

  &:focus {
    appearance: none;
    border: none;
    outline: none;
    cursor: default;
  }

  &[type='password'] {
    letter-spacing: 0.125em;

    &::placeholder {
      letter-spacing: normal;
    }
  }
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 100px white inset !important;
  }
`;

const StyledTextarea = styled.textarea<any>`
  appearance: none;
  width: 100%;
  margin-top: ${({ isActive }) => (isActive ? '18px' : '0')};
  border: none;
  font-size: 16px;
  line-height: 21.86px;
  background-color: transparent;
  resize: none;
  white-space: pre-line;
  resize: none;
  overflow: auto;

  &:hover {
    cursor: pointer;
  }

  &:focus {
    appearance: none;
    border: none;
    outline: none;
    cursor: default;
  }
`;

const InputLabel = styled.label<any>`
  position: absolute;
  top: ${({ isActive }) => (isActive ? '0' : '20px')};
  left: 2px;
  color: rgba(0, 0, 0, 0.4);
  font-size: ${({ isActive }) => (isActive ? '12px' : '16px')};
  line-height: ${({ isActive }) => (isActive ? '16.39px' : ' 12px')};
  font-weight: ${({ isActive }) => (isActive ? '600' : '500')};
  transition: all 0.3s ease-in-out;
  pointer-events: none;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 0.375rem;
  align-items: center;
  position: absolute;
  right: 0;
  bottom: 0;
  transform: translate(0, -50%);
  margin-right: 2px;

  & > span[class^='icon-'] {
    font-size: 1.25rem;
    cursor: pointer;
  }

  span[class='icon-check'] {
    color: #6620c7;
  }
`;

const moveLabelOnClickTypes = ['text', 'name', 'email', 'password', 'code'];

const Input: React.FC<Props> = ({
  type = 'text',
  value,
  onChange,
  label,
  minlength,
  maxlength,
  changeInstantly = true,
  onSave,
  error = false,
  errorText,
  showButtons = true,
  isTextArea = false,
  mt,
  ...rest
}) => {
  const { setIsInputFocused } = useCanvasFocus();
  const [inputValue, setInputValue] = useState<ValueType>(value);
  const [hasChanged, setHasChanged] = useState<boolean>(false);
  const [isActive, setIsActive] = useState<boolean>(false);
  const [eyeIcon, setEyeIcon] = useState<string>('eye-on');
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    type !== 'password' && setInputValue(value);
    type === 'password' && setEyeIcon(type === 'text' ? 'eye-off' : 'eye-on');
    // setIsActive(!!value);
    type === 'tel' && setIsActive(!!value);
  }, [type, value]);

  const handleFocus = () => {
    type === 'tel' && !value && setInputValue('+7');
    setIsActive(true);
    setIsInputFocused(true);
  };

  const handleBlur = () => {
    setIsInputFocused(false);
    if (type === 'tel' && !value) {
      setInputValue('');
      setHasChanged(false);
    }
    if (inputValue === '+7' || moveLabelOnClickTypes.includes(type)) {
      setIsActive(false);
    }
  };

  const validatePhoneNumber = (tel: string) => {
    return /^\+7\d{10}$/.test(tel);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { target } = e;

    if (type === 'tel') {
      const inputValue = target.value;
      const isValidInput = /^\+?7?\d{0,10}$/.test(inputValue);
      if (!isValidInput) {
        e.preventDefault();
        return;
      }
    }

    // setIsActive(!!target.value);
    setInputValue(target.value);
    setHasChanged(target.value !== value);
    (type === 'password' || changeInstantly) && onChange && onChange(e);
  };

  const getTargetObject = (targetValue: ValueType = '') => {
    return {
      target: { name: rest.name, value: targetValue },
    } as React.ChangeEvent<HTMLInputElement>;
  };

  const handleCancelClick = () => {
    setInputValue(value);
    setHasChanged(false);
    if (changeInstantly && onChange) {
      const target = getTargetObject();
      onChange(target);
    }
    setIsActive(value !== '');
  };

  const handleSaveClick = (e: React.MouseEvent<HTMLSpanElement>) => {
    if (!validateInput()) {
      e.preventDefault();
      return;
    }
    if (!changeInstantly && onChange) {
      const target = getTargetObject(inputValue);
      onChange(target);
    }
    setHasChanged(false);
    onSave && onSave();
    setIsActive(value !== '');
  };

  const handleEyeIconClick = () => {
    if (inputRef.current) {
      inputRef.current.type = inputRef.current.type === 'text' ? 'password' : 'text';
      setEyeIcon(inputRef.current.type === 'text' ? 'eye-off' : 'eye-on');
    }
  };

  const validateInput = useCallback(() => {
    switch (type) {
      case 'tel':
        return (inputValue && validatePhoneNumber(inputValue.toString())) || inputValue === '';
      default:
        return true;
    }
  }, [type, inputValue]);

  const getAdjustedHeight = () => {
    if (inputRef.current && isTextArea) {
      const initial = +inputRef.current.style.height.slice(0, -2);
      return initial + 24 + 'px';
    }
  };

  useEffect(() => {
    if (inputRef.current && isTextArea) {
      inputRef.current.style.height = '0px';
      const scrollHeight = inputRef.current.scrollHeight;
      inputRef.current.style.height = Math.min(Math.max(scrollHeight, 75), 135) + 'px';
    }
  }, [inputRef, inputValue]);

  return (
    <>
      <Wrapper
        error={error}
        isActive={isActive && !!inputValue}
        isTextArea={isTextArea}
        height={getAdjustedHeight()}
      >
        <InputLabel
          isActive={
            moveLabelOnClickTypes.includes(type)
              ? isActive || !!inputValue
              : isActive && !!inputValue
          }
        >
          {label}
        </InputLabel>

        {isTextArea ? (
          <StyledTextarea
            onFocus={handleFocus}
            onBlur={handleBlur}
            value={inputValue}
            onChange={handleInputChange}
            ref={inputRef}
            minLength={minlength}
            maxLength={maxlength}
            isActive={isActive}
            cols={30}
            rows={3}
            {...rest}
          />
        ) : (
          <StyledInput
            onFocus={handleFocus}
            onBlur={handleBlur}
            value={inputValue}
            onChange={handleInputChange}
            ref={inputRef}
            type={type}
            minLength={minlength}
            maxLength={maxlength}
            isActive={isActive}
            mt={mt}
          />
        )}

        {showButtons && hasChanged && type !== 'password' && (
          <ButtonsWrapper>
            <span className="icon-close" onClick={handleCancelClick} />
            <span
              className={`icon-check${!validateInput() ? '--disabled' : ''}`}
              onMouseDown={handleSaveClick}
            />
          </ButtonsWrapper>
        )}
        {type === 'password' && <span className={`icon-${eyeIcon}`} onClick={handleEyeIconClick} />}
      </Wrapper>
      {error && errorText && (
        <Text color="lightRed" small style={{ marginTop: '-10px' }}>
          {errorText}
        </Text>
      )}
    </>
  );
};

export default Input;

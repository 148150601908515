import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { changeFontFamily } from '../../utils/editor.utils';
import { IBasicEditorProps } from '../../interfaces/editor.interface';

const List = styled.ul`
  list-style: none;
  display: block;
  padding: 0;
  margin: 0;
  background-color: #fff;

  > :not(:last-child) {
    border-bottom: 1px solid #d9d9d9;
  }
`;

const ListItem = styled.li<{ activeFontWeight?: boolean; font: string; fontWeight: number }>`
  display: flex;
  padding: 10px 10px 10px 15px;
  background-color: ${(p) =>
    p.activeFontWeight ? 'rgba(102, 32, 199, 0.4)' : 'rgba(0, 0, 0, 0.06)'};
  gap: 10px;
  font-size: 14px;
  line-height: 17px;
  color: ${(p) => (p.activeFontWeight ? '#6620C7' : '#000')};
  align-items: center;
  justify-content: space-between;
  width: 10rem;
  font-family: ${(p) => (p.font ? p.font : 'Manrope')};
  font-weight: ${(p) => (p.fontWeight ? p.fontWeight : 400)};

  &:hover {
    background-color: rgba(102, 32, 199, 0.4);
    color: #6620c7;
    & > span[class^='icon-'] {
      color: #6620c7;
    }
  }
`;

interface Props extends IBasicEditorProps {
  font: string;
  options: number[];
  handleItemClick: (value: number, font: any) => () => void;
  activeFontWeight: number | string;
  activeFontStyle?: boolean;
}

const FontWeightOptions: React.FC<Props> = ({
  font,
  options,
  handleItemClick,
  activeFontWeight,
  activeFontStyle,
}) => {
  const { t } = useTranslation();

  return (
    <List>
      {options.map((elem, index) => (
        <ListItem
          activeFontWeight={activeFontWeight === elem && activeFontStyle}
          key={`${font}--${elem}--${index}`}
          font={font}
          onClick={handleItemClick(elem, font)}
          fontWeight={elem}
        >
          {t(`fontWeight.${elem}`)}
        </ListItem>
      ))}
    </List>
  );
};

export default FontWeightOptions;

interface _Object {
  [key: string]: string | number | boolean | null | undefined;
}

export const getPlatform = (): 'Windows' | 'macOS' | 'Unknown' => {
  const userAgent = window.navigator.userAgent.toLowerCase();

  if (userAgent.indexOf('win') !== -1) {
    return 'Windows';
  }
  if (userAgent.indexOf('mac') !== -1) {
    return 'macOS';
  }
  return 'Unknown';
};

export const getActionKey = (): 'metaKey' | 'ctrlKey' => {
  const platform = getPlatform();

  if (platform === 'macOS') {
    return 'metaKey'; // command
  }

  return 'ctrlKey';
};

export const areObjectsEqual = (obj1: _Object, obj2: _Object) => {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    if (obj1[key] !== obj2[key]) {
      return false;
    }
  }

  return true;
};

export const areAllObjectsEqual = (arr: _Object[]) => {
  if (arr.length < 2) {
    return true; // If there are fewer than 2 objects, they are equal by definition.
  }

  return arr.every((item) => areObjectsEqual(arr[0], item));
};

export const getTooltipPosition = (total: number, index: number) => {
  if (total - 4 < index) {
    if (index % 4 === 1) return 'right-top';
    else if (index % 4 === 2 || index % 4 === 3) return 'top';
    else if (index % 4 === 0) return 'left-top';
  } else {
    if (index % 4 === 1) return 'right-bottom';
    else if (index % 4 === 2 || index % 4 === 3) return 'bottom';
    else if (index % 4 === 0) return 'left-bottom';
  }
};

export const getElementText = (itemsCount: number) => {
  let text;
  if (itemsCount % 10 === 1 && itemsCount % 100 !== 11) {
    text = 'элемент';
  } else if (
    itemsCount % 10 >= 2 &&
    itemsCount % 10 <= 4 &&
    !(itemsCount % 100 >= 12 && itemsCount % 100 <= 14)
  ) {
    text = 'элемента';
  } else {
    text = 'элементов';
  }
  return `${itemsCount} ${text}`;
};

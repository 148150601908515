export const FavOrPrivateSort = [
  {
    title: 'Недавно использованные',
    value: 'BY_LAST_USAGE',
  },
  {
    title: 'Недавно добавленные',
    value: 'BY_LAST_ADDING',
  },
];

export const GlobalSort = [
  {
    title: 'По популярности',
    value: 'BY_POPULARITY',
  },
  {
    title: 'По новизне',
    value: 'BY_LAST_ADDING',
  },
];

export const infographic_notification = {
  public: 'Инфографика добавлена в вашу коллекцию, а также доступна всем пользователям Mark It',
  private: 'Инфографика добавлена в вашу коллекцию.',
  error: 'Что-пошло не так, попробуй позже',
  keyWords: 'Добавьте ключевые слова',
};

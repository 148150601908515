import { LinearGradientType, RadialGradientType } from '../interfaces/editor.interface';

export const BG_TYPES: string[] = ['fill', 'linear', 'radial'];

export const LINEAR_GRADIENT_TYPES: LinearGradientType[] = [
  'horizontal',
  'vertical',
  'leftDiagonal',
  'rightDiagonal',
];

export const RADIAL_GRADIENT_TYPES: RadialGradientType[] = [
  'radialCenter',
  'radialLeftTop',
  'radialTop',
  'radialRightCenter',
];

export const BG_COLORS: string[] = [
  '#000000',
  '#424242',
  '#757575',
  '#BDBDBD',
  '#EEEEEE',
  '#FFFFFF',
  '#795548',
  '#FFF3E0',
  '#D50000',
  '#D81B60',
  '#7B1FA2',
  '#0D47A1',
  '#00B0FF',
  '#00C853',
  '#FF6D00',
  '#FFD600',
  '#FF5252',
  '#FF4081',
  '#D500F9',
  '#3D5AFE',
  '#18FFFF',
  '#76FF03',
  '#FFA726',
  '#FFFF00',
  '#FFCCBC',
  '#F8BBD0',
  '#E1BEE7',
  '#BBDEFB',
  '#B2EBF2',
  '#B9F6CA',
  '#FFE0B2',
  '#FFF9C4',
];

export const BG_GRADIENT_TEMPLATES: { start: string; end: string }[] = [
  { start: '#BDBDBD', end: '#000000' },
  { start: '#000000', end: '#4A148C' },
  { start: '#000000', end: '#3D5AFE' },
  { start: '#000000', end: '#795548' },
  { start: '#FF4081', end: '#FF6D00' },
  { start: '#FF6D00', end: '#FFD600' },
  { start: '#76FF03', end: '#FFFF00' },
  { start: '#18FFFF', end: '#FFFF00' },
  { start: '#FFFFFF', end: '#BDBDBD' },
  { start: '#F8BBD0', end: '#D500F9' },
  { start: '#D500F9', end: '#3D5AFE' },
  { start: '#FF4081', end: '#D500F9' },
  { start: '#E1BEE7', end: '#B9F6CA' },
  { start: '#B2EBF2', end: '#3D5AFE' },
  { start: '#FFFF00', end: '#00B0FF' },
  { start: '#B9F6CA', end: '#00C853' },
];

import React from 'react';
import { FlexCol } from '../../../components/flexbox.component';
import SkeletonLoader from '../../../components/skeleton-loader.component';

const OzonLoaderMobile: React.FC<{ noneAnimation?: boolean, ozonType?: 'Тип 1' | 'Тип 2' }> = ({ noneAnimation, ozonType = 'Тип 1' }) => {
  return (
    <FlexCol gap={10}>
      <FlexCol gap={7} style={{ marginTop: '10px', marginLeft: '5px' }}>
        <SkeletonLoader noneAnimation={noneAnimation} width="40px" height="15px" />
        <SkeletonLoader noneAnimation={noneAnimation} width="96px" height="10px" />
        <SkeletonLoader noneAnimation={noneAnimation} width="109px" height="10px" />
        {ozonType === 'Тип 1' && <SkeletonLoader noneAnimation={noneAnimation} width="63px" height="10px" />}
      </FlexCol>
      {ozonType === 'Тип 1' && <SkeletonLoader width="150px" height="25px" noneAnimation={noneAnimation} />}
    </FlexCol>
  );
};

export default OzonLoaderMobile;

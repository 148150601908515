import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Text } from './text.component';
import { Button } from './button.component';
import { useTranslation } from 'react-i18next';
import { PurchaseRateTypes, RateNames } from '../constants/rates.constant';
import { useNavigate } from 'react-router-dom';
import { useUserContext } from '../contexts/user.context';
import { purchaseRate } from '../requests/tariff.request';
import RateModal from './rates/rate-modal.component';

interface IProps {
  rate: any;
  days: number;
  onClose: () => void;
}

const Container = styled.div`
  position: absolute;
  top: calc(100% + 0.625rem);
  left: 0;
  width: 20rem;
  border-radius: 1rem;
  background: #f4d2dc;
  box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.15);
  z-index: 100000;
  padding: 1.25rem;
  color: ${(p) => p.theme.red};

  .body {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    justify-content: space-between;
    height: 100%;
  }

  span[class^='icon-'] {
    font-size: 1.25rem;
  }

  .df {
    display: flex;
    gap: 0.625rem;
    width: 100%;
    align-items: start;
  }

  .icon-close {
    position: absolute;
    top: 0.625rem;
    right: 0.625rem;
    сursor: pointer;
    z-index: 100;
    color: black;
  }

  .buttons {
    display: flex;
    width: 100%;
    gap: 0.625rem;

    > button {
      width: 8.4375rem;
      padding: 0.8125rem 0;
    }
  }
`;

const ExpireNotification: FC<IProps> = ({ rate, days, onClose }) => {
  const { t } = useTranslation();
  const { updateUserDetails } = useUserContext();
  const navigate = useNavigate();

  const [type, setType] = useState<'default' | 'trial'>('default');
  const [isOpen, setIsOpen] = useState(false);

  const content: { [key: string]: any } = {
    trial: {
      text:
        days === 1
          ? t('rates.expire-rate-trial', { days }).replace('осталось', 'остался')
          : t('rates.expire-rate-trial', { days }),
      buttons: (
        <Button
          type="button"
          onClick={() => {
            setIsOpen(true);
          }}
        >
          {t('rates.chooseRate-now')}
        </Button>
      ),
    },
    default: {
      text: t('rates.expire-rate-default', { days }),
      buttons: (
        <div className="buttons">
          <Button
            type="button"
            onClick={() => {
              purchaseRate(rate.id, PurchaseRateTypes.RENEW).then(() => updateUserDetails());
              onClose();
            }}
          >
            {t('rates.renew')}
          </Button>

          <Button
            type="button"
            btnStyle="white"
            onClick={() => {
              navigate('/rates');
              onClose();
            }}
          >
            {t('rates.anotherRate')}
          </Button>
        </div>
      ),
    },
  };

  const getDaysText = () => {
    if (days <= 1) {
      return 'день';
    } else if (days <= 4) {
      return 'дня';
    } else {
      return 'дней';
    }
  };

  useEffect(() => {
    setType(rate.name === RateNames.TRIAL ? 'trial' : 'default');
  }, []);

  return (
    <>
      <Container className="container">
        <div className="body">
          <div className="df">
            <span className="icon-alert-circle" />
            <Text color="red" fontWeight={800}>
              {content[type].text.replace('дней', getDaysText())}
            </Text>
          </div>

          {content[type].buttons}
        </div>
        <span className="icon-close" onClick={onClose} />
      </Container>

      <RateModal isOpen={isOpen} onClose={() => setIsOpen(false)} />
    </>
  );
};

export default ExpireNotification;

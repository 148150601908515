import { fabric } from 'fabric';
import JSZip from 'jszip';

export const downloadProject = (detailsList: any[], projectName: string): Promise<void> => {
  return new Promise((resolve) => {
    const zip = new JSZip();
    const promises: Promise<void>[] = [];

    detailsList.forEach((details, index) => {
      const promise = new Promise<void>((resolveDetails) => {
        const canvas = document.createElement('canvas');
        const fabricCanvas = new fabric.Canvas(canvas);

        fabricCanvas.loadFromJSON(details, () => {
          const gridObj = fabricCanvas._objects.find((obj) => obj.name === 'grid');
          if (gridObj) {
            fabricCanvas.remove(gridObj);
          }

          const watermark = fabricCanvas._objects.find((obj) => obj.name === 'watermark');
          if (watermark) {
            fabricCanvas.bringToFront(watermark);
          }

          fabricCanvas.setBackgroundColor(
            details.background || '#ffffff',
            fabricCanvas.renderAll.bind(fabricCanvas),
          );

          fabricCanvas.renderAll();

          const base64 = fabricCanvas.toDataURL({ format: 'png' });

          const byteCharacters = atob(base64.split(',')[1]);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], { type: 'image/png' });

          zip.file(`image_${index + 1}.png`, blob);

          resolveDetails();
        });
      });
      promises.push(promise);
    });

    Promise.all(promises).then(() => {
      zip.generateAsync({ type: 'blob' }).then((content) => {
        const url = URL.createObjectURL(content);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${projectName || 'images'}.zip`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
        resolve();
      });
    });
  });
};

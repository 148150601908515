export enum RateNames {
  BASIC = 'BASIC',
  ADVANCED = 'ADVANCED',
  UNLIMITED = 'UNLIMITED',
  TRIAL = 'TRIAL'
}

export enum RatePeriods {
  ANNUALLY = 'ANNUALLY',
  BIANNUALLY = 'BIANNUALLY',
  MONTHLY = 'MONTHLY',
  QUARTERLY = 'QUARTERLY'
}


export const rates = {
  [RatePeriods.ANNUALLY]: {
    discount: 40,
    rates: [
      {
        name: RateNames.BASIC,
        price: 600,
      },
      {
        name: RateNames.ADVANCED,
        price: 1500,
      },
      {
        name: RateNames.UNLIMITED,
        price: 4500,
      }
    ]
  },
  [RatePeriods.BIANNUALLY]: {
    discount: 30,
    rates: [
      {
        name: RateNames.BASIC,
        price: 700,
      },
      {
        name: RateNames.ADVANCED,
        price: 1750,
      },
      {
        name: RateNames.UNLIMITED,
        price: 4500,
      }
    ]
  },
  [RatePeriods.QUARTERLY]: {
    discount: 20,
    rates: [
      {
        name: RateNames.BASIC,
        price: 800,
      },
      {
        name: RateNames.ADVANCED,
        price: 2000,
      },
      {
        name: RateNames.UNLIMITED,
        price: 6000,
      }
    ]
  },
  [RatePeriods.MONTHLY]: {
    discount: 0,
    rates: [
      {
        name: RateNames.BASIC,
        price: 1000,
      },
      {
        name: RateNames.ADVANCED,
        price: 2500,
      },
      {
        name: RateNames.UNLIMITED,
        price: 7500,
      }
    ]
  }
}

export type OverlayContent = {
  headline: string,
  description: string[],
  buttonText: string,
  buttonIcon: string | null,
  menuItem: string | null
}

export enum PurchaseRateTypes {
  NOW = 'NOW',
  LATER = 'LATER',
  RENEW = 'RENEW'
}

export const overlayData: { [key: string]: OverlayContent } = {
  TRIAL: {
    headline: "myProjects.welcome",
    description: ["myProjects.description"],
    buttonText: "myProjects.createNewCard",
    buttonIcon: "file-plus",
    menuItem: "rates.chooseRate"
  },
  trialEnded: {
    headline: "rates.increaseCapabilities",
    description: ["rates.trialEnded", "rates.trialEnded2"],
    buttonText: "rates.chooseRate",
    buttonIcon: null,
    menuItem: null
  },
  subscriptionUnpaid: {
    headline: "rates.paySubscription",
    description: ["rates.subscriptionExpired", "rates.subscriptionExpired2"],
    buttonText: "rates.renewSubscription",
    buttonIcon: null,
    menuItem: "rates.changeRate"
  },
  projectDeleted: {
    headline: "rates.increaseCapabilities",
    description: ["rates.deletedProject-1", "rates.deletedProject-2"],
    buttonText: "rates.chooseRate",
    buttonIcon: null,
    menuItem: null
  }
}

import styled, { keyframes, css } from 'styled-components';
import { Text } from '../../components/text.component';
import pipetteCursor from '../../assets/icons/pipette-cursor.svg';
import { RoundButton } from '../../components/round-btn.component';

const fade = keyframes`
  0% { opacity: 0 }
  100% { opacity: 1 }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.6875rem 1.25rem 1rem;
`;

const HeaderItem = styled.div<{ columnGap?: number; rowGap?: number }>`
  display: flex;
  align-items: center;
  column-gap: ${(p) => p.columnGap}px;
  row-gap: ${(p) => p.rowGap}px;
`;

const HeaderItemDropDown = styled(HeaderItem)`
  position: relative;
`;

const OutletWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2.5rem 0 3.625rem;
  padding: 0 1.25rem;
`;

const NameInput = styled.input.attrs({ type: 'text' })`
  color: ${(p) => p.theme.black};
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.375em;
  padding: 0;
  background: transparent;
  border: 0;
  flex: 1;
  &::placeholder {
    color: ${(p) => p.theme.gray};
    opacity: 1;
  }
  &:focus {
    outline: none;
  }
`;

const CanvasWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), ${(p) => p.theme.white};
  border-radius: 8px;
  width: 450px;
  height: 600px;
  & .main-canvas {
    overflow: hidden;
    border-radius: 32px;
    outline: 4px solid ${(p) => p.theme.lightGray};
    background: #ffffff;
    //background: repeating-conic-gradient(#ccc 0% 25%, transparent 0% 50%) 50% / 20px 20px;
  }
  &.pipetteCursor {
    & .upper-canvas {
      cursor: url(${pipetteCursor}) 0 21, auto !important;
    }
  }
  & > ${Text} {
    position: absolute;
    top: calc(100% + 0.875rem);
    width: 100%;
    text-align: center;
    animation: ${fade} 1s linear;
  }
`;

const DragAndDropZone = styled.div<{ dragOver?: boolean }>`
  position: relative;
  transform: scale(0.25);
  ${(p) =>
    p.dragOver &&
    css`
      & > .main-canvas {
        outline: 8px dashed ${(p) => p.theme.lightPrimary};
      }
    `}
`;

const DragAndDropDescription = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.75);
  z-index: 999;
  pointer-events: none;
  border-radius: 8px;
  animation: ${fade} 0.25s linear;
  & > h2 {
    font-size: 6rem;
    margin: 0 0 0.25rem;
    color: ${(p) => p.theme.black};
  }
  & > h5 {
    font-size: 3.5rem;
    margin: 0;
    padding: 0 3rem;
    text-align: center;
    color: ${(p) => p.theme.lightBlack};
  }
`;

const Footer = styled.div`
  position: fixed;
  bottom: 1.25rem;
  left: 1.25rem;
  display: flex;
  align-items: center;
  width: calc(100% - 2.5rem);
  z-index: 1;
  & > ${Text} {
    margin-left: -3.125rem;
    width: 100%;
    text-align: center;
    animation: ${fade} 1s linear;
    pointer-events: none;
  }
`;

const ContactUs = styled(RoundButton).attrs({
  icon: 'question',
  btnStyle: 'primary',
  position: 'right',
  title: 'Задать вопрос',
})`
  z-index: 99;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
  pointer-events: auto;
  & > span[class^='icon-'] {
    font-size: 1.5rem !important;
  }
`;

const EditorCard = styled.div`
  background-color: ${(p) => p.theme.white};
  border-radius: 8px;
  min-width: 450px;
  min-height: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
`;

const components = {
  Header,
  HeaderItem,
  HeaderItemDropDown,
  OutletWrapper,
  NameInput,
  CanvasWrapper,
  DragAndDropZone,
  DragAndDropDescription,
  Footer,
  ContactUs,
  EditorCard,
};

export default components;

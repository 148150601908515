import React from 'react';
import { CustomCard } from '../../pages/folders/folders.style';
import { FlexCol } from '../flexbox.component';
import SkeletonLoader from '../skeleton-loader.component';

const FolderListLoader: React.FC<{ count?: number; withoutFolder?: boolean }> = ({
  count = 20,
  withoutFolder = false,
}) => {
  return (
    <>
      {withoutFolder && (
        <CustomCard
          style={{
            background:
              'linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), rgb(255, 255, 255)',
          }}
        >
          <SkeletonLoader width="138px" height="30px" />
          <FlexCol gap={3}>
            <SkeletonLoader width="200px" height="16px" />
            <SkeletonLoader width="100px" height="16px" />
          </FlexCol>
        </CustomCard>
      )}
      {Array.from({ length: count }).map((_, index) => (
        <CustomCard
          key={index}
          style={{
            background:
              'linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), rgb(255, 255, 255)',
          }}
        >
          <FlexCol gap={3}>
            <SkeletonLoader width="200px" height="30px" />
            <SkeletonLoader width="180px" height="30px" />
            <SkeletonLoader width="200px" height="30px" />
          </FlexCol>
          <SkeletonLoader width="100px" height="16px" />
        </CustomCard>
      ))}
    </>
  );
};

export default FolderListLoader;

import React, { useState, useRef, useEffect } from 'react';
import { MyInfoGraphicSettings } from '../../../interfaces/infographics.interface';
import {
  Option,
  SettingIcon,
  SettingOptionName,
  SettingsDivider,
  SettingsWrapper,
} from '../../object-toolbar/infographic-tool/infographic.style';

export const InfographicSettings: React.FC<{
  settingOptions: MyInfoGraphicSettings[];
  id: number;
}> = ({ settingOptions, id }) => {
  const [dropDownOpen, setDropDownOpen] = useState<boolean>(false);
  const optionRef = useRef<HTMLDivElement | null>(null);

  const handleClickOutside = (event: MouseEvent) => {
    const target = event.target as Node;
    if (optionRef.current && !optionRef.current.contains(target)) {
      setDropDownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <SettingsWrapper>
      <SettingIcon
        ref={optionRef}
        dropDownOpen={dropDownOpen}
        className="icon-more"
        onClick={() => setDropDownOpen(!dropDownOpen)}
      >
        <Option>
          {dropDownOpen &&
            settingOptions.map((option, index) => (
              <SettingOptionName key={index} onClick={() => option.function(id)}>
                {option.name}
                <SettingsDivider nonActive={settingOptions.length - 1 === index} />
              </SettingOptionName>
            ))}
        </Option>
      </SettingIcon>
    </SettingsWrapper>
  );
};

import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';
import { IImages } from '../interfaces/projects.interface';

export const InputOverlay = styled.div<{ active?: boolean; image?: boolean }>`
  width: 24px;
  height: 24px;
  position: absolute;
  top: ${({ image }) => (image ? '5px' : '10px')};
  right: ${({ image }) => (image ? '5px' : '10px')};
  z-index: ${({ image }) => (image ? '1021' : '1000')};
  border-radius: 8px;
  cursor: pointer;
  &:hover {
    border: 2px solid #6620c7;
  }
  ${({ active }) =>
    active
      ? css`
          background-color: #6620c7;
        `
      : css`
          border: 2px solid #cccccc;
          background-color: #f0f0f0;
        `};
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1020;
  cursor: pointer;
`;

type CardOverlayType = {
  highlight: boolean;
  highlightedCards: number[];
  setHighlightedCard: React.Dispatch<React.SetStateAction<number[]>>;
  cardId: number;
  isRightMouseDown: boolean;
  image?: boolean;
  imageLoadFunction?: () => void;
  imageData?: IImages;
  setChosenImage?: React.Dispatch<React.SetStateAction<IImages | undefined>>;
  chosenImage?: IImages | undefined;
};

const CardCheckboxOverlay: React.FC<CardOverlayType> = ({
  highlight,
  highlightedCards,
  setHighlightedCard,
  cardId,
  isRightMouseDown,
  image,
  imageLoadFunction,
  imageData,
  setChosenImage,
  chosenImage,
}) => {
  useEffect(() => {
    if (!highlight) {
      setHighlightedCard([]);
    }
  }, [highlight]);

  const highlightCard = () => {
    if (highlightedCards.includes(cardId)) {
      setHighlightedCard((prev) => prev.filter((id) => id !== cardId));
      return;
    } else setHighlightedCard((prev) => [...prev, cardId]);
  };

  useEffect(() => {
    if (highlightedCards.length === 1) {
      !chosenImage && setChosenImage && imageData && setChosenImage(imageData);
    } else {
      setChosenImage && setChosenImage(undefined);
    }
  }, [highlightedCards]);

  return (
    <>
      {highlight && (
        <>
          <InputOverlay
            image={image}
            active={highlightedCards.includes(cardId)}
            onClick={() => {
              image && highlightCard();
            }}
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          >
            {highlightedCards.includes(cardId) && (
              <span
                className="icon-check"
                style={{ fontSize: '16px', fontWeight: '700', color: 'white' }}
              ></span>
            )}
          </InputOverlay>
          <Overlay
            onClick={() => {
              !image ? highlightCard() : imageLoadFunction && imageLoadFunction();
            }}
            onMouseEnter={() => {
              isRightMouseDown && setHighlightedCard((prev) => [...prev, cardId]);
            }}
          ></Overlay>
        </>
      )}
    </>
  );
};

export default CardCheckboxOverlay;

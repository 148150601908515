import styled from 'styled-components';
import { MarketPlaceType, OzonType } from '../../interfaces/preview-image.interface';
import { Flex } from '../../components/flexbox.component';

export const PreviewImageTab = styled.button<{ active?: boolean }>`
  background: ${(p) => (p.active ? p.theme.lightPrimaryGradient : p.theme.grayGradient)};
  border-radius: 16px;
  width: max-content;
  height: 45px;
  border: none;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: ${(p) => (p.active ? p.theme.primary : p.theme.black)};
  &.active,
  &:hover {
    color: ${(p) => p.theme.primary};
    background: linear-gradient(0deg, rgba(102, 32, 199, 0.4), rgba(102, 32, 199, 0.4)),
      ${(p) => p.theme.white};
  }
  padding: 13px 16px;
  cursor: pointer;
`;

export const AfterTabLine = styled.div<{
  marginFromLeft?: number;
  marginFromRight?: number;
}>`
  height: 34px;
  width: 1px;
  background: ${(p) => p.theme.lightGray};
  margin-left: ${(p) => p.marginFromLeft}px;
  margin-right: ${(p) => p.marginFromRight}px;
`;

export const PreviewImageBlock = styled.div<{
  marketPlace: MarketPlaceType;
  isIconFormat: boolean;
  canvasRatio: string;
}>`
  height: ${(p) =>
    (p.marketPlace === 'Wildberries' && '656px') ||
    (p.marketPlace === 'YandexMarket' && (!p.isIconFormat ? '600px' : '544px')) ||
    (p.marketPlace === 'Ozon' && '500px')};
  width: ${(p) =>
    (p.marketPlace === 'Wildberries' && '490px') ||
    (p.marketPlace === 'YandexMarket' && (!p.isIconFormat ? '450px' : '544px')) ||
    (p.marketPlace === 'Ozon'
      ? !p.isIconFormat && p.canvasRatio !== 'square'
        ? '374px'
        : '500px'
      : !p.isIconFormat && p.canvasRatio !== 'square'
        ? '533px'
        : '710px')};
  background: #f8f8fa;
  border-radius: 8px;
  position: relative;
`;

export const PreviewImageSelect = styled.div<{
  marketPlace: MarketPlaceType;
  iconFormat: boolean;
  canvasRatio?: string;
}>`
  width: ${(p) =>
    (p.marketPlace == 'Wildberries' && '72px') ||
    (p.marketPlace == 'YandexMarket' && '96px') ||
    (p.marketPlace == 'Ozon' && '54px')};
  min-height: ${(p) =>
    (p.marketPlace == 'Wildberries' && '96px') ||
    (p.marketPlace == 'YandexMarket' && (p.canvasRatio === 'square' ? '96px' : '125px')) ||
    (p.marketPlace == 'Ozon' && '54px')};
  border-radius: ${(p) =>
    (p.marketPlace == 'Wildberries' && '2px') ||
    (p.marketPlace == 'YandexMarket' && '10px') ||
    (p.marketPlace == 'Ozon' && '0px')};
  background: ${(p) =>
    p.marketPlace === 'Wildberries'
      ? '#F8F8FA'
      : p.marketPlace === 'Ozon'
        ? 'transparent'
        : '#fff'};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const LabelsFromOzon = styled.div<{
  top?: number;
  left?: number;
  right?: number;
  bottom?: number;
  start?: boolean;
  gap?: number;
}>`
  position: absolute;
  top: ${(p) => p.top}px;
  right: ${(p) => p.right}px;
  left: ${(p) => p.left}px;
  bottom: ${(p) => p.bottom}px;
  display: flex;
  flex-direction: column;
  gap: ${(p) => (p.gap ? p.gap : 8)}px;
  align-items: ${(p) => (p.start ? 'flex-start' : 'flex-end')};
`;
export const LabelFromOzon = styled.span<{
  fontSize?: number;
  paddingY?: number;
  paddingX?: number;
  lineHeightNone?: boolean;
  br?: number;
}>`
  color: #fff;
  background-color: #6620c7;
  line-height: ${(p) => (p.lineHeightNone ? '' : '18px')};
  padding: ${(p) =>
    p.paddingY && p.paddingX ? `${p.paddingY}px ` + `${p.paddingX}px` : '3px 7px'};
  border-radius: ${(p) => (p.br ? `${p.br}px` : '8px')};
  font-size: ${(p) => (p.fontSize ? p.fontSize : 13)}px;
`;

interface IconHeartProps {
  top?: string;
  right?: string;
  bottom?: string;
  left?: string;
}

export const StyledIconHeart = styled.span.attrs<IconHeartProps>({
  className: 'icon-heart',
}) <IconHeartProps>`
  position: absolute;
  top: ${(p) => p.top};
  right: ${(p) => p.right};
  bottom: ${(p) => p.bottom};
  left: ${(p) => p.left};
  color: #00000033;
  cursor: pointer;
`;

export const SimilarButton = styled.div<{ marketPlace: MarketPlaceType; mobile?: boolean }>`
  position: absolute !important;

  bottom: ${(p) =>
    (p.marketPlace === 'Wildberries' && (p.mobile ? '15px' : '12px')) ||
    (p.marketPlace === 'YandexMarket' && (p.mobile ? '10px' : '')) ||
    (p.marketPlace === 'Ozon' && (p.mobile ? '7px' : '10px'))};

  top: ${(p) => p.marketPlace == 'YandexMarket' && (p.mobile ? '' : '10px')};

  right: ${(p) =>
    (p.marketPlace === 'Wildberries' && (p.mobile ? '15px' : '12px')) ||
    (p.marketPlace === 'YandexMarket' && '10px') ||
    (p.marketPlace === 'Ozon' && (p.mobile ? '7px' : '-10px'))};

  display: flex;
  gap: 2px;
  align-items: center;
  z-index: 100;
  max-width: fit-content;
  cursor: default !important;

  & img {
    border-radius: ${(p) =>
    (p.marketPlace === 'Wildberries' && '8px') ||
    (p.marketPlace === 'YandexMarket' && '12px') ||
    (p.marketPlace === 'Ozon' && '8px')};
  }
`;

export const WildBerriesMobileWrapper = styled(Flex)`
  box-shadow: 0px 6px 20px 0px #00000026;
  width: 320px;
  overflow: hidden;
  position: relative;
  background: #0000001a;
  & .swiper-wrapper {
    width: 302px;
    height: 402px;
  }

  & .swiper {
    margin: 0;
    overflow: visible;
  }

  & .swiper-slide {
    margin-right: 0 !important;
    width: 302px !important;
    position: relative;
    background-color: #e6e6e6;
  }

  &:not(:first-child) {
    margin-left: -18px;
  }
`;

export const YandexMobileWrapper = styled(Flex) <{ yandexType?: OzonType }>`
  width: 320px;
  height: ${(p) => (p.yandexType === 'Тип 2' ? '195px' : '320px')};
  position: relative;
  background: #f8f7f5;
  box-shadow: 0px 6px 20px 0px #00000026;

  & .swiper-slide {
    display: flex !important;
    justify-content: center !important;
  }
`;

export const OzonMobileWrapper = styled(Flex) <{ ozonType?: OzonType }>`
  width: ${(p) => (p.ozonType === 'Тип 1' ? '320px' : '320px')};
  height: ${(p) => (p.ozonType === 'Тип 1' ? '426px' : '320px')};
  overflow: hidden;
  position: relative;
  box-shadow: 0px 6px 20px 0px #00000026;
  display: flex;
  align-items: center;

  & .swiper-wrapper {
    width: ${(p) => (p.ozonType === 'Тип 1' ? '320px' : '320px')};
    height: ${(p) => (p.ozonType === 'Тип 1' ? '364px' : '320px')};
  }

  & .swiper {
    margin: 0;
    overflow: visible;
  }

  & .swiper-slide {
    margin-right: 0 !important;
    width: ${(p) => (p.ozonType === 'Тип 1' ? '275px' : '320px')};
    position: relative;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const NoSquareWBPreview = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: rgba(0, 0, 0, 0.1);
`
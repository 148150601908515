import React, { FC, ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { getUserDetails } from '../requests/user.requests';
import { useAuth } from './auth.context';
import { useNavigate } from 'react-router-dom';

export interface RateDetails {
  availableProjectCount: number;
  currentPrice: number;
  discount: number;
  existingProjectCount: number;
  expireDate: string;
  id: number;
  name: string;
  originalPrice: number;
  paymentPeriod: string;
  projectLimit: number;
  purchaseDate: string;
  totalPrice: number;
  inTrashProjectCount: number;
}

export interface UserDetails {
  email: string;
  name: string;
  phoneNumber: string;
  rate: RateDetails;
  tariff: RateDetails;
  role: string;
}

export interface UserContextProps {
  userData: UserDetails | null;
  updateUserDetails: () => void;
}

const UserContext = createContext<UserContextProps>({
  userData: null,
  updateUserDetails: () => null,
});

export const useUserContext = () => useContext(UserContext);

interface UserProviderProps {
  children: ReactNode;
}

const UserProvider: FC<UserProviderProps> = ({ children }) => {
  const [userData, setUserData] = useState<UserDetails | null>(null);
  const { user } = useAuth();
  const updateUserDetails = () => {
    getUserDetails()
      .then((res: any) => {
        setUserData({ ...res, rate: res.tariff });
      })
      .catch((res: any) => {
        res.response.status === 401 && localStorage.clear(); // prevent infinite requests
      });
  };

  useEffect(() => {
    user && updateUserDetails();
  }, [user]);

  return (
    <UserContext.Provider value={{ userData, updateUserDetails }}>{children}</UserContext.Provider>
  );
};

export default UserProvider;

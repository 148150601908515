import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  background: #fff;
`;

const ContentBlock = styled.div<{ active?: boolean }>`
  display: flex;
  padding: 10px 10px 10px 15px;
  background-color: ${(p) => (p.active ? 'rgba(102, 32, 199, 0.4)' : '#fff')};
  gap: 10px;
  font-size: 14px;
  line-height: 17px;
  color: ${(p) => (p.active ? '#6620C7' : '#000')};
  align-items: center;
  justify-content: space-between;
  width: 10rem;

  &:hover {
    background-color: rgba(102, 32, 199, 0.4);
    color: #6620c7;
    & > span[class^='icon-'] {
      color: #6620c7;
    }
  }
`;

const Title = styled.p<{ font: string }>`
  font-weight: 400;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: ${(p) => (p.font ? p.font : 'Manrope')};
`;

type Props = {
  font: string;
  active?: boolean;
  isCanExpand?: boolean;
};

const FontsListItem: React.FC<Props> = ({ font, active, isCanExpand }) => {
  return (
    <Wrapper>
      <ContentBlock active={!!active}>
        <Title font={font}>{font}</Title>
        {!isCanExpand && <span className={'icon-chevron-down'} />}
      </ContentBlock>
    </Wrapper>
  );
};

export default FontsListItem;

export const calculateImageSize = (img: HTMLImageElement) => {
  const originalWidth = img.width;
  const originalHeight = img.height;

  const maxModalWidth = window.innerWidth * 0.9 - 40;
  const maxModalHeight = window.innerHeight * 0.7 - 40;

  const aspectRatio = originalWidth / originalHeight;

  let newWidth = originalWidth;
  let newHeight = originalHeight;

  if (newWidth > maxModalWidth) {
    newWidth = maxModalWidth;
    newHeight = newWidth / aspectRatio;
  }

  if (newHeight > maxModalHeight) {
    newHeight = maxModalHeight;
    newWidth = newHeight * aspectRatio;
  }

  const minWidth = 300;
  const minHeight = 300;
  if (newWidth < minWidth) {
    newWidth = minWidth;
    newHeight = newWidth / aspectRatio;
  }
  if (newHeight < minHeight) {
    newHeight = minHeight;
    newWidth = newHeight * aspectRatio;
  }

  return { width: newWidth - 40, height: newHeight - 40 };
};

export const calculateNewDimensions = (originalWidth: number, originalHeight: number) => {
  const aspectRatio = originalWidth / originalHeight;
  const height = originalHeight - 80;
  const width = height * aspectRatio;

  return { width, height };
};

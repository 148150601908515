import React from 'react';
import { FlexCol } from '../../../components/flexbox.component';
import SkeletonLoader from '../../../components/skeleton-loader.component';

const YandexLoader: React.FC<{ noneAnimation?: boolean | undefined, canvasRatio?: 'default' | 'square' }> = ({ noneAnimation, canvasRatio = 'square' }) => {
  return (
    <>
      <div
        style={{
          width: '217px',
          height: '217px',
          borderRadius: '10px',
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          background: '#F5F5F5',
        }}
      >
        <SkeletonLoader
          width={canvasRatio === 'square' ? '200px' : "150px"}
          height="200px"
          borderRadius="0px"
          noneAnimation={noneAnimation}
        />
      </div>
      <FlexCol>
        <FlexCol gap={5} style={{ marginBottom: '13px' }}>
          <SkeletonLoader width="200px" height="20px" noneAnimation={noneAnimation} />
          <SkeletonLoader width="187px" height="20px" noneAnimation={noneAnimation} />
          <SkeletonLoader width="130px" height="20px" noneAnimation={noneAnimation} />
        </FlexCol>
        <FlexCol gap={5} style={{ marginBottom: '13px' }}>
          <SkeletonLoader width="96px" height="10px" noneAnimation={noneAnimation} />
          <SkeletonLoader width="200px" height="20px" noneAnimation={noneAnimation} />
          <SkeletonLoader width="138px" height="20px" noneAnimation={noneAnimation} />
          <SkeletonLoader width="96px" height="20px" noneAnimation={noneAnimation} />
        </FlexCol>
        <SkeletonLoader
          baseColor="#00000033"
          shineColor={noneAnimation ? '#00000033' : '#0000001A'}
          width="213px"
          height="40px"
        />
      </FlexCol>
    </>
  );
};

export default YandexLoader;

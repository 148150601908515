import styled, { css } from 'styled-components';

export const SupportWrapper = styled.div`
  position: fixed;
  bottom: 1.25rem;
  left: 1.25rem;
  z-index: 10010;

  button.round {
    z-index: 10011;
    position: relative;
    background: ${(p) => p.theme.lightPrimary};
    span {
      font-size: 1.75rem !important;
      color: ${(p) => p.theme.primary};
    }

    &.gray {
      background: ${(p) => p.theme.lightGray2};
      span {
        font-size: 1.25rem !important;
        color: ${(p) => p.theme.black};
      }
    }

    &.active,
    &:hover {
      background: ${(p) => p.theme.primary};
      span {
        color: ${(p) => p.theme.white};
      }
    }
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.3);
    z-index: 1010;
  }
`;

export const ModalWrapper = styled.div<{ width: number; type: string }>`
  position: absolute;
  left: 0;
  bottom: calc(100% + 0.4375rem);
  border-radius: 1rem;
  padding: 1.25rem;
  width: ${(p) => p.width}rem;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  box-shadow: 0 6px 20px 0 #00000026;
  z-index: 10010;
  background: ${(p) => p.theme.white};

  ${(p) =>
    p.type === 'support' &&
    css`
      .err {
        position: absolute;
      }
    `}
  ${(p) =>
    p.type === 'FAQ' &&
    css`
      max-height: 85vh;
      overflow-y: auto;
      min-height: 60vh;

      .items {
        display: flex;
        flex-direction: column;
        border-top: 1px solid rgba(0, 0, 0, 0.15);
        width: 100%;
      }
    `}
`;

import React from 'react';
import styled, { css } from 'styled-components';
import { CanvasRatio } from '../interfaces/editor.interface';
import { CANVAS_SIZE } from '../constants';
import { useTranslation } from 'react-i18next';

const LayoutBlock = styled.div`
  background: rgba(0, 0, 0, 0.1);
  color: #999999;
  border: 1px solid ${(p) => p.theme.lightGray};
  border-radius: 8px;
  font-size: 0.875rem;
  font-weight: 600;
  padding: 1.25rem 2.25rem;
`;

const CanvasLayoutWrapper = styled.div<{ canvasRatio: CanvasRatio }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 0.625rem;
  pointer-events: none;
  ${(p) =>
    p.canvasRatio === 'square' &&
    css`
      max-height: calc(${CANVAS_SIZE['square'].height}px / 4);
      top: 50%;
      transform: translateY(-50%);
      & > ${LayoutBlock} {
        padding: 0.625rem 2rem;
      }
    `}
`;

interface IProps {
  show: boolean;
  canvasRatio: CanvasRatio;
}

export const CanvasLayout = ({ show, canvasRatio }: IProps) => {
  const { t } = useTranslation();

  return show ? (
    <CanvasLayoutWrapper canvasRatio={canvasRatio}>
      <LayoutBlock>{t('editor.bannerLayout')}</LayoutBlock>
    </CanvasLayoutWrapper>
  ) : null;
};

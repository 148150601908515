import React, { useState } from 'react';
import { Properties, Property } from './object-toolbar.style';
import { IBasicEditorProps } from '../../interfaces/editor.interface';

const CharSpace: React.FC<IBasicEditorProps> = ({ editor, activeObject }) => {
  const charSpaces: number[] = [0, 50, 100, 150, 200, 250, 300];
  const [activeCharSpace, setActiveCharSpace] = useState<number>(
    (activeObject as fabric.IText)?.charSpacing ?? 0,
  );

  const changeCharSpace = (charSpace: number) => {
    setActiveCharSpace(charSpace);
    (activeObject as fabric.IText)?.set('charSpacing', charSpace);
    editor.canvas.renderAll();
  };

  return (
    <Properties>
      {charSpaces.map((charSpace, index) => (
        <Property
          active={activeCharSpace === charSpace}
          key={index}
          onClick={() => changeCharSpace(charSpace)}
        >
          {charSpace / 100}
        </Property>
      ))}
    </Properties>
  );
};

export default CharSpace;

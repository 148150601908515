import React, { useState, useRef, useEffect } from 'react';
import { fabric } from 'fabric';
import { FabricJSEditor } from 'fabricjs-react';
import FabricTypes from 'fabric/fabric-impl';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { useCanvasHistory } from '../../contexts/canvas-history.context';
import { UploadButton } from '../upload-button.component';
import Popover from '../popover.component';
import { WatermarkType } from '../../interfaces/editor.interface';
import { Button } from '../button.component';
import { Text } from '../text.component';
import { Range } from '../range.component';
import { Flex, FlexCol } from '../flexbox.component';
import { ALIGN_OPTIONS, CANVAS_SIZE } from '../../constants';
import { ToolbarBtn, ToolbarRow } from './toolbar.style';

const ButtonRow = styled(Flex).attrs({ gap: 10, mb: 20 })`
  width: 20.75rem;
  & > ${ToolbarBtn} {
    border-radius: 1rem;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.25);
    &:hover:not(:disabled) {
      background-color: ${({ theme }) => theme.lightPrimary};
      color: ${({ theme }) => theme.primary};
    }
  }
`;

const AlignRow = styled(ToolbarRow)`
  & > ${ToolbarBtn} {
    flex: 1 1;
    justify-content: center;
  }
`;

const Preview = styled.div<{ src: string }>`
  width: 100%;
  height: 4.375rem;
  border-radius: 0.5rem;
  margin-bottom: 1.25rem;
  border: 1px solid ${(p) => p.theme.lightGray};
  background: url(${(p) => p.src}) center center / contain no-repeat,
    repeating-conic-gradient(#ccc 0% 25%, transparent 0% 50%) 50% / 10px 10px;
`;

interface IProps {
  editor: FabricJSEditor;
  handleSavePage: VoidFunction;
}

export const WatermarkPopover = ({ editor, handleSavePage }: IProps) => {
  const { t } = useTranslation();
  const { historySaveAction } = useCanvasHistory();
  const popoverRef = useRef<{ close: () => void }>();
  const [didMount, setDidMount] = useState(false);
  const [popoverActive, setPopoverActive] = useState<boolean>();
  const [watermark, setWatermark] = useState<FabricTypes.Object>();
  const [img, setImg] = useState<FabricTypes.Image>();
  const [src, setSrc] = useState<string>();
  const [watermarkType, setWatermarkType] = useState<WatermarkType>('x1');
  const [opacity, setOpacity] = useState<number>(0.5);
  const [size, setSize] = useState<number>(50);
  const [isEdited, setIsEdited] = useState<boolean>(false);

  useEffect(() => {
    if (popoverActive) {
      setDidMount(true);
    } else {
      setIsEdited(false);
    }
  }, [popoverActive]);

  const resetVariables = () => {
    setWatermark(undefined);
    setImg(undefined);
    setSrc(undefined);
    setOpacity(0.5);
    setSize(50);
  };

  useEffect(() => {
    // init state
    if (didMount && popoverActive) {
      const { canvas } = editor;
      const objects = canvas.getObjects();
      const obj = objects.find((obj: any) => obj.name === 'watermark');
      if (obj) {
        setWatermark(obj);
        setWatermarkType(obj?.type === 'image' ? 'x1' : 'grid');
        setOpacity(obj.opacity as number);
        if (obj.type === 'rect') {
          const watermarkImg = objects.find((obj: any) => obj.name === 'watermarkImg');
          if (watermarkImg) {
            watermarkImg.clone((cloned: FabricTypes.Image) => {
              const clonedImage = cloned.set({ visible: true }) as FabricTypes.Image;
              handleSetSize(clonedImage);
            });
          }
        } else {
          handleSetSize(obj);
        }
      } else {
        resetVariables();
      }
    }
  }, [didMount, popoverActive]);

  useEffect(() => {
    if (watermark) {
      if (watermark.type === 'rect') {
        const fill = watermark.get('fill') as FabricTypes.Pattern;
        const source = fill.source as HTMLImageElement;
        setSrc(source.src);
      } else {
        setSrc((watermark as FabricTypes.Image).getSrc());
      }
    }
  }, [watermark]);

  const handleSetSize = (obj?: FabricTypes.Object) => {
    const padding = obj?.type === 'image' ? 30 : 10;
    if (obj && obj.width && obj.scaleX) {
      const { canvas } = editor;
      const objSize = (((obj.width + padding * 2) * obj.scaleX) / canvas.width!) * 100;
      setSize(Math.round(objSize));
      setImg(obj as FabricTypes.Image);
    }
  };

  const getScale = (image: FabricTypes.Object) => {
    const { canvas } = editor;
    const canvasWidth = canvas.width as number;
    const imageWidth = image.width as number;
    return +(Math.round(((canvasWidth * (size / 100)) / imageWidth) * 100) / 100).toFixed(2);
  };

  const setPosition = (image: FabricTypes.Object) => {
    const { canvas } = editor;
    const objects = canvas.getObjects();
    const isHaveLayoutGrid = objects.some((obj: any) => obj.name === 'grid');
    if (isHaveLayoutGrid) {
      const lastIndex = objects.length - 1;
      image.moveTo(lastIndex);
    } else {
      canvas.bringToFront(image);
    }
  };

  const handleSetPattern = (image: FabricTypes.Image, rect: FabricTypes.Rect, refresh = false) => {
    const { canvas } = editor as FabricJSEditor;
    const source = new Image();
    source.src = image.toDataURL({
      top: -10,
      left: -10,
      width: image.getScaledWidth() + 20,
      height: image.getScaledHeight() + 20,
    });
    source.onload = () => {
      rect.fill = new fabric.Pattern({
        source,
        repeat: 'repeat',
      });
      if (!refresh) {
        canvas.add(rect);
        setPosition(rect);
        image.clone((cloned: FabricTypes.Image) => {
          const hiddenImage = cloned.set({ visible: false, name: 'watermarkImg' });
          canvas.add(hiddenImage);
        });
      }
      canvas.renderAll();
      historySaveAction(editor);
      setWatermark(rect);
    };
  };

  const handleAddWatermarkGrid = (image: FabricTypes.Image) => {
    const rect = new fabric.Rect({
      name: 'watermark',
      width: CANVAS_SIZE['default'].width,
      height: CANVAS_SIZE['default'].height,
      left: 0,
      top: 0,
      evented: false,
      selectable: false,
      objectCaching: false,
      opacity,
    });
    handleSetPattern(image, rect);
  };

  const handleRemoveOldWatermark = () => {
    const { canvas } = editor;
    const objects = canvas.getObjects();
    objects.forEach((obj: any) => {
      if (obj.name === 'watermark' || obj.name === 'watermarkImg') {
        canvas.remove(obj);
      }
    });
  };

  const handleSetWatermark = (image: FabricTypes.Image) => {
    const { canvas } = editor;
    handleRemoveOldWatermark();
    const scale = getScale(image);
    image.set({
      scaleX: scale,
      scaleY: scale,
      evented: false,
      selectable: false,
    });
    if (canvas.width && canvas.height && image.width) {
      if (watermarkType === 'x1') {
        image.set({
          name: 'watermark',
          originX: 'center',
          originY: 'center',
          left: canvas.width / 2,
          top: canvas.height / 2,
          padding: 30,
          opacity,
        });
        canvas.add(image);
        setPosition(image);
        setWatermark(image);
        setImg(image);
        historySaveAction(editor);
      } else {
        image.set({
          left: 10,
          top: 10,
          padding: 10,
          opacity: 1,
        });
        setImg(image);
        handleAddWatermarkGrid(image);
      }
    }
    canvas.renderAll();
  };

  const handleParseImage = (imageBlob: Blob) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const imgObj = new Image();
      imgObj.src = event.target?.result as string;
      imgObj.onload = () => {
        const image = new fabric.Image(imgObj);
        handleSetWatermark(image);
      };
    };
    reader.readAsDataURL(imageBlob);
  };

  const handleUploadImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files as FileList;
    handleParseImage(files[0]);
  };

  useEffect(() => {
    if (popoverActive === false) {
      handleSavePage();
    }
  }, [popoverActive]);

  useEffect(() => {
    if (didMount && img) {
      handleSetWatermark(img);
    }
  }, [watermarkType]);

  const handleChangeType = (type: WatermarkType) => () => {
    setWatermarkType(type);
  };

  const handleSetAlign = (key: (typeof ALIGN_OPTIONS)[number]) => async () => {
    if (watermark) {
      const { canvas } = editor;
      const top = watermark.top as number;
      const left = watermark.left as number;
      const bound = watermark.getBoundingRect();

      switch (key) {
        case 'start-vertical':
          watermark.set({ left: left - bound.left });
          break;
        case 'center-vertical': {
          canvas.width && watermark.set({ left: canvas.width / 2 });
          break;
        }
        case 'end-vertical': {
          canvas.width && watermark.set({ left: canvas.width - bound.width / 2 });
          break;
        }
        case 'start-horizontal': {
          watermark.set({ top: top - bound.top });
          break;
        }
        case 'center-horizontal': {
          canvas.height && watermark.set({ top: canvas.height / 2 });
          break;
        }
        case 'end-horizontal': {
          canvas.height && watermark.set({ top: canvas.height - bound.height / 2 });
          break;
        }
        default:
          break;
      }

      watermark.setCoords();
      await canvas.renderAll();
      historySaveAction(editor);
    }
  };

  useEffect(() => {
    if (didMount && editor && watermark) {
      watermark.set('opacity', opacity);
      editor.canvas.renderAll();
    }
  }, [opacity]);

  useEffect(() => {
    if (didMount && editor?.canvas.width && watermark && isEdited) {
      if (watermarkType === 'x1') {
        const scale = getScale(watermark);
        watermark.set({ scaleX: scale, scaleY: scale });
        editor.canvas.renderAll();
      } else if (img) {
        const scale = getScale(img as FabricTypes.Object);
        if (img) {
          img.set({ scaleX: scale, scaleY: scale });
          handleSetPattern(img, watermark, true);
          const { canvas } = editor as FabricJSEditor;
          const objects = canvas.getObjects();
          const watermarkImg = objects.find((obj: any) => obj.name === 'watermarkImg');
          if (watermarkImg) {
            watermarkImg.set({ scaleX: scale, scaleY: scale });
          }
        }
      }
    }
  }, [size]);

  const handleDeleteWatermark = () => {
    handleRemoveOldWatermark();
    resetVariables();
    historySaveAction(editor);
  };

  return (
    <Popover
      ref={popoverRef}
      type={'cloud'}
      position={'right-bottom'}
      setIsActive={setPopoverActive}
      className={popoverActive ? 'active' : ''}
      closeOnClick={false}
      relativeToParent={true}
      content={
        <>
          {src && <Preview src={src} />}
          <ButtonRow>
            <UploadButton
              btnText="action.uploadPngLogo"
              accept="image/png"
              handleUpload={handleUploadImage}
              withoutMargin
              flexible
            />
            {src && (
              <ToolbarBtn
                data-tooltip={t('action.delete') as string}
                data-tooltip-position="bottom"
                onClick={handleDeleteWatermark}
              >
                <span className={'icon-trash'} />
              </ToolbarBtn>
            )}
          </ButtonRow>
          <FlexCol gap={20}>
            <Flex gap={6}>
              {(['x1', 'grid'] as WatermarkType[]).map((type) => (
                <Button
                  key={type}
                  btnStyle="gray"
                  active={watermarkType === type}
                  onClick={handleChangeType(type)}
                >
                  {t(`watermark.${type}`)}
                </Button>
              ))}
            </Flex>
            <FlexCol gap={6}>
              <Text small>{t('watermark.opacity')}</Text>
              <Range
                id="watermarkOpacity"
                min="0"
                max="1"
                step="0.01"
                value={opacity}
                onChange={(e) => setOpacity(+e.target.value)}
              />
            </FlexCol>
            <FlexCol gap={6}>
              <Text small>{t('watermark.size')}</Text>
              <Range
                id="watermarkSize"
                min="0"
                max="100"
                step="1"
                value={size}
                onChange={(e) => {
                  setSize(+e.target.value);
                  setIsEdited(true);
                }}
              />
            </FlexCol>
            <AlignRow>
              {ALIGN_OPTIONS.map((key) => (
                <ToolbarBtn
                  key={key}
                  data-tooltip={watermarkType === 'grid' ? null : (t(`align.${key}`) as string)}
                  data-tooltip-position="bottom"
                  onClick={handleSetAlign(key)}
                  disabled={watermarkType === 'grid'}
                >
                  <span className={`icon-align-${key}`} />
                </ToolbarBtn>
              ))}
            </AlignRow>
          </FlexCol>
        </>
      }
    >
      <ToolbarBtn
        active={popoverActive}
        data-tooltip-position={'right'}
        data-tooltip={popoverActive ? null : (t('watermark.title') as string)}
      >
        <span className="icon-watermark" />
      </ToolbarBtn>
    </Popover>
  );
};

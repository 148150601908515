import React, { useEffect, useState } from 'react'
import { Modal } from '../modal.component'
import { Text } from '../text.component';
import { RateNames, rates } from '../../constants/rates.constant';
import { useTranslation } from 'react-i18next';
import ToggleButtons from '../toggle-buttons.component';
import RateList from './rate-list.component';
import { Button } from '../button.component';
import { useUserContext } from '../../contexts/user.context';
import { RateModalContent, RateModalFooter, RateModalHeader, RateModalRatesWrapper } from './rates.style';
import { useNavigate } from 'react-router-dom';

interface IProps {
  isOpen: boolean,
  onClose: () => void,
  isMore?: boolean
}

const RateModal: React.FC<IProps> = ({ isOpen, onClose, isMore = false }) => {
  const { t } = useTranslation();
  const { userData } = useUserContext();
  const navigate = useNavigate();

  const [tabs, setTabs] = useState<any[]>([]);
  const [currentLimit, setCurrentLimit] = useState(0);
  const [type, setType] = useState('default');

  const modalData: { [key: string]: any } = {
    default: {
      header: 'Увеличьте свои возможности — выберите тариф!',
      description: <div>
        <Text strong>С подпиской вы сможете:</Text>
        <ul>
          <li>создавать более одного проекта</li>
          <li>выгружать готовые изображения для ваших карточек товаров на маркетплейсах</li>
          <li>восстанавливать удаленные проекты из корзины</li>
        </ul>
      </div>
    },
    ['project-limit']: {
      header: 'Увеличьте свои возможности!',
      description: <Text>{t('rates.project-limit-exceeded', { limit: currentLimit })}</Text>
    },
    ['basic']: {
      header: 'Увеличьте свои возможности!',
      description: <Text>Выберите тариф с еще большим количеством проектов или безлимит.</Text>
    },
    ['advanced']: {
      header: 'Увеличьте свои возможности!',
      description: <Text>Выберите безлимит и ни в чем себе не отказывайте.</Text>
    },
    ['delete-some']: {
      header: 'Сократите количество проектов!',
      description: <>
        <Text>Вы не можете cоздать проект(ты), так как количество текущих проектов равно лимиту по выбранному тарифу «{t(`rates.${userData?.rate.name}`)}».</Text>
        <Text>У вас {currentLimit} текущих проектов. Перенесите неактуальные проекты в корзину и попробуйте восстановить нужные проект(ы) из корзины еще раз.</Text>
      </>
    }
  }

  const getModalContentType = () => {
    if (userData) {
      const { rate } = userData;
      setCurrentLimit(rate?.projectLimit);
      if (rate?.name === RateNames.TRIAL) {
        setType('default')
      } else if (rate.availableProjectCount === 0) {
        setType('project-limit')
      } else if (!isMore && rate.availableProjectCount > 0 && rate.existingProjectCount >= rate.projectLimit) {
        setType('delete-some')
      } else if (rate.availableProjectCount > 0 && rate.name === RateNames.BASIC) {
        setType('basic')
      } else if (rate.availableProjectCount > 0 && rate.name === RateNames.ADVANCED) {
        setType('advanced')
      }
    }
  }

  const closeModal = () => {
    onClose();
    getModalContentType();
  }

  useEffect(() => {
    const tablist = Object.keys(rates).map((rate) => ({
      title: t(`rates.${rate}`),
      content: <RateList type={rate} variant="small" isFiltered={true} />,
    }))

    setTabs(tablist)
  }, [])

  useEffect(() => {
    getModalContentType();
  }, [userData])

  return (
    <>
      {
        userData && isOpen && <Modal currentRate={userData?.rate?.name} maxHeight='unset' onClose={closeModal} contentType={type}>
          <RateModalContent>
            <RateModalHeader>
              <Text fontSize='1.4375rem' strong>{modalData[type].header}</Text>
              {modalData[type].description}
            </RateModalHeader>

            {type !== 'delete-some' && <RateModalRatesWrapper>
              <ToggleButtons tabs={tabs} />
            </RateModalRatesWrapper>}

            {
              type === 'delete-some'
                ? <div className='buttons'>
                  <Button style={{ padding: '0.8125rem 0rem' }} btnStyle='cancel' onClick={closeModal}>{t('rates.decide-later')}</Button>
                  <div className='df'>
                    <Button btnStyle='gray' onClick={() => {
                      closeModal();
                      navigate('/my-projects');
                    }}>{t('rates.to-project')}</Button>
                    <Button btnStyle='primary' icon='plus' onClick={() => setType(userData?.rate.name.toLowerCase())}>{t('rates.moreProjects')}</Button>
                  </div>
                </div>
                : <RateModalFooter>
                  <Button btnStyle='cancel' type='button' onClick={closeModal}>Выберу позже</Button>
                </RateModalFooter>
            }

          </RateModalContent>
        </Modal>
      }
    </>
  )
}

export default RateModal
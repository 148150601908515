import React, { useState } from 'react';
import logo from '../../assets/images/logo.svg';
import { useTranslation } from 'react-i18next';
import { RoundButton } from '../../components/round-btn.component';
import { useAuth } from '../../contexts/auth.context';
import { useNavigate } from 'react-router-dom';
import { ErrorWrapper, Form, WhiteBox } from '../login/login.style';
import { Headline } from '../../components/headline.component';
import Input from '../../components/input.component';
import { Button } from '../../components/button.component';
import { adminCancel } from '../../requests/admin';
import { Text } from '../../components/text.component';
import { useNotification } from '../../contexts/notification.context';
import { AdminBody, AdminWrapper, Header, HeaderItem } from './admin.styles';

const AdminPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setNotification } = useNotification();
  const auth = useAuth();
  const [form, setForm] = useState<{
    email: string;
  }>({
    email: '',
  });
  const [error, setError] = useState<string>('');
  const [cancelLoading, setCancelLoading] = useState<boolean>(false);

  const handleLogOut = () => {
    auth.signOut(() => navigate('/'));
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setCancelLoading(true);
    adminCancel(form.email)
      .then((res) => {
        if (res?.data?.status === 'REFUNDED') {
          setNotification({ text: 'Успешно!' });
          setForm({
            email: '',
          });
          setError('');
        } else {
          setError(res.msg || 'Что-то пошло не так, попробуйте позже');
        }
      })
      .finally(() => {
        setCancelLoading(false);
      });
  };

  return (
    <AdminWrapper>
      <Header>
        <HeaderItem>
          <img src={logo} alt={'mark it'} />
        </HeaderItem>
        <HeaderItem>
          <RoundButton icon={'log-out'} title={t('main.logOut') as string} onClick={handleLogOut} />
        </HeaderItem>
      </Header>

      <AdminBody>
        <WhiteBox>
          <Headline>{t('admin.refund')}</Headline>
          {error && (
            <ErrorWrapper>
              <Text
                small
                style={{
                  color: 'red',
                }}
              >
                {error}
              </Text>
            </ErrorWrapper>
          )}
          <Form onSubmit={handleSubmit}>
            <Input
              label="Email"
              type="email"
              error={error ? true : false}
              value={form.email}
              onChange={(e) =>
                setForm({
                  email: e.target.value,
                })
              }
              showButtons={false}
            />
            <Button isLoading={cancelLoading} type="submit">
              {t('admin.refund')}
            </Button>
          </Form>
        </WhiteBox>
      </AdminBody>
    </AdminWrapper>
  );
};

export default AdminPage;

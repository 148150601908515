import React, { useEffect } from 'react';
import { Text } from './text.component';
import { Button } from './button.component';
import styled from 'styled-components';

interface INotification {
  handleClose?: () => void;
  notificationText: string;
  handleFunction?: () => void;
  show: boolean | string;
  buttonText?: string;
  errorNotification?: boolean;
  closeNotification: () => void;
  icon?: string;
  time?: number;
}

export const Wrapper = styled.div`
  background: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.15) 0 6px 20px;
  border-radius: 16px;
  display: flex;
  justify-content: space-between;
  gap: 1.25rem;
  max-width: 690px;
  align-items: center;
  padding: 0.9375rem 1.25rem;
  position: fixed;
  left: 50%;
  bottom: 5%;
  z-index: 2000;
  transform: translateX(-50%);
`;

const Notification: React.FC<INotification> = ({
  show,
  handleClose,
  notificationText,
  handleFunction,
  buttonText,
  errorNotification,
  closeNotification,
  icon = 'undo',
  time = 20000,
}) => {
  let timer: NodeJS.Timeout;

  const buttonFunction = () => {
    closeNotification();
    handleFunction && handleFunction();
  };

  handleClose &&
    useEffect(() => {
      clearTimeout(timer);

      timer = setTimeout(() => {
        handleClose();
      }, time);

      return () => {
        clearTimeout(timer);
      };
    }, [show]);

  return show ? (
    <Wrapper>
      <Text
        fontSize="16px"
        style={{ width: buttonText ? '60%' : '', color: errorNotification ? 'red' : '' }}
      >
        {notificationText}
      </Text>
      {buttonText && buttonFunction && (
        <Button
          style={{ whiteSpace: 'nowrap' }}
          icon={icon}
          onClick={buttonFunction}
          id="notification"
        >
          {buttonText}
        </Button>
      )}
    </Wrapper>
  ) : null;
};

export default Notification;

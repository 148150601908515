import React, { useEffect, useState } from 'react';
import { SupportWrapper, ModalWrapper } from './support.style';
import { Text } from '../text.component';
import Input from '../input.component';
import { useUserContext } from '../../contexts/user.context';
import { Button } from '../button.component';
import { useShared } from '../../contexts/shared.context';
import { RoundButton } from '../round-btn.component';
import FAQItem from '../faq-item.component';
import { sendSupportRequest } from '../../requests/user.requests';
import { useCanvasFocus } from '../../contexts/canvas-focus.context';

const FAQItems = Array.from({ length: 20 }).map((_, i) => ({
  question: `Как создать проект? ${i}`,
  answer: `Таким образом новая модель организационной деятельности влечет за собой процесс внедрения и модернизации существенных финансовых и административных условий. 
  Товарищи! постоянный количественный рост и сфера нашей активности представляет собой интересный эксперимент проверки дальнейших направлений развития.`,
}));

const ContactSupport = () => {
  const { userData } = useUserContext();
  const { setIsFocused } = useCanvasFocus();
  const { isSupportOpen, setIsSupportOpen } = useShared();
  const [isSent, setIsSent] = useState<boolean>(false);
  const [isHelpMenuOpen, setIsHelpMenuOpen] = useState<boolean>(false);
  const [isFAQOpen, setIsFAQOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [sentError, setSentError] = useState<boolean>(false);
  const { setIsInputFocused } = useCanvasFocus();

  const [form, setForm] = useState<{ email: string; msg: string }>({
    email: '',
    msg: '',
  });
  const [error, setError] = useState<any>({
    email: false,
    msg: false,
  });

  const handleChange =
    (key: string) =>
      (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
        const { value } = event.target;
        setForm((prevState) => ({ ...prevState, [key]: value }));
      };

  const handleButtonClick = () => {
    setIsSupportOpen(!isSupportOpen);
  };

  useEffect(() => {
    if (isSupportOpen) {
      setIsFocused(false);
      setIsInputFocused(true);
    } else {
      setIsFocused(true);
      setIsInputFocused(false);
    }
  }, [isSupportOpen]);

  const handleSendQuestion = () => {
    if (userData) {
      if (error.email || error.msg) return;

      const isValidEmail = /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/.test(form.email);

      if (!isValidEmail) {
        setError((prev: any) => ({ ...prev, email: true }));
        return;
      }

      if (form.msg === '') {
        setError((prev: any) => ({ ...prev, msg: true }));
        return;
      }

      setError(false);
      setLoading(true);
      sendSupportRequest(form.email, form.msg)
        .then(() => {
          setIsSent(true);
          setForm({
            email: form.email || userData.email,
            msg: '',
          });
        })
        .catch(() => {
          setSentError(true);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    if (userData) {
      setForm({
        email: userData.email,
        msg: '',
      });
    }
  }, [userData]);

  return (
    <SupportWrapper>
      <RoundButton
        title={isSupportOpen || isFAQOpen ? undefined : 'Помощь'}
        position="right"
        icon={isSupportOpen || isFAQOpen ? 'arrow-left' : 'question'}
        onClick={handleButtonClick}
        isActive={isHelpMenuOpen}
        className={`round ${isHelpMenuOpen ? 'active' : ''} ${(isSupportOpen || isFAQOpen) && 'gray'
          } `}
      />

      {isSupportOpen && (
        <ModalWrapper width={23.75} type="support">
          {isSent ? (
            sentError ? (
              <Text strong fontSize="1.4375rem">
                Что-то пошло не так, повторите попытку позже
              </Text>
            ) : (
              <>
                <Text strong fontSize="1.4375rem">
                  Сообщение отправлено
                </Text>
                <Text>Спасибо за обращение! Мы свяжемся с вами по указанному email.</Text>
              </>
            )
          ) : (
            <>
              <Text strong fontSize="1.4375rem">
                Напишите нам
              </Text>

              <div style={{ position: 'relative' }}>
                <Input
                  label="Ваш email"
                  type="email"
                  error={error.email}
                  value={form.email}
                  onChange={handleChange('email')}
                  showButtons={false}
                />
                {error.email && (
                  <div className="err">
                    {form.email === ''
                      ? 'Введите email, чтобы мы могли вам ответить'
                      : 'Некорректный email'}
                  </div>
                )}
              </div>

              <div style={{ position: 'relative' }}>
                <Input
                  label="Ваш вопрос"
                  type="email"
                  value={form.msg}
                  onChange={handleChange('msg')}
                  showButtons={false}
                  isTextArea={true}
                  error={error.msg}
                />
                {error.msg && <div className="err">Введите ваш вопрос</div>}
              </div>

              <Button
                isLoading={loading}
                onClick={handleSendQuestion}
                disabled={!form.email || !form.msg}
              >
                Отправить
              </Button>
            </>
          )}
        </ModalWrapper>
      )}

      {isSupportOpen && (
        <div
          className="overlay"
          onClick={() => {
            setIsSupportOpen(false);
            setIsSent(false);
          }}
        />
      )}

      {isFAQOpen && (
        <ModalWrapper width={37.5} type="FAQ">
          <Text fontSize="1.4375rem" strong>
            Частые вопросы
          </Text>

          <div className="items">
            {FAQItems.map((item: any, index: number) => (
              <FAQItem key={index} item={item} />
            ))}
          </div>
        </ModalWrapper>
      )}
    </SupportWrapper>
  );
};

export default ContactSupport;

import React from 'react';
import { ModalHeaderWrapper } from './modal.style';
import { Text } from '../text.component';
import SortDropdown from '../sort-dropdown.component';
import { FILE_SORT, FOLDER_SORT } from '../../constants/shared.constants';

const ModalHeader: React.FC<{
  sort: any;
  isLoading: boolean;
  type: 'project' | 'file';
  action?: 'add' | 'replace';
  sortType?: 'folder' | 'file';
}> = ({ sort, type, action = 'replace', sortType = 'folder', isLoading }) => {
  return (
    <ModalHeaderWrapper>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
        }}
      >
        <Text fontSize="1.4375rem" strong>
          {action === 'replace' && `Поместить ${type === 'file' ? 'файл' : 'проект'} в папку`}
          {action === 'add' && `Добавить  ${type === 'file' ? 'файл' : 'проект'} в папку`}
        </Text>
        <Text fontSize="1rem">
          {sortType === 'folder' ? 'Выберите одну папку' : 'Выберите один или сразу несколько'}
        </Text>
      </div>
      <div>
        <SortDropdown
          selection={sortType === 'folder' ? FOLDER_SORT : FILE_SORT}
          setSelectedOption={sort.setSelectedOption}
          selectedOption={sort.selectedOption.title}
          sortOrder={sort.sortAsc}
          setSortOrder={sort.setSortAsc}
          fontSize="16px"
          width="181.09px"
          selectionFontSize="16px"
          iconFontSize="20px"
          position="right"
          disabled={isLoading}
        />
      </div>
    </ModalHeaderWrapper>
  );
};

export default ModalHeader;

import styled from "styled-components";
import { Text } from "../../components/text.component";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 10.75rem);
  flex: 1;

  .footer-text {
    position: fixed;
    bottom: 2rem;
    left: 1.25rem;
    display: flex;
    align-items: center;
    width: calc(100% - 2.5rem);
    z-index: 1;
    & > ${Text} {
      margin-left: -3.125rem;
      width: 100%;
      text-align: center;
      // pointer-events: none;
      cursor: default;
    }

    a {
      color: ${(p) => p.theme.infoGray}; 
      cursor: pointer;
    }
  }
`;

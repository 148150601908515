import React, { useCallback, useEffect, useState } from 'react';
import { Textbox } from 'fabric/fabric-impl';
import { t } from 'i18next';

import { useCanvasHistory } from '../../contexts/canvas-history.context';
import { Properties, Property } from './object-toolbar.style';
import { IBasicEditorProps } from '../../interfaces/editor.interface';
import { alignText, focusOnTextBox, handleLists } from '../../utils/editor.utils';
import { fabric } from 'fabric';
import { getActionKey } from '../../utils/shared.utils';

const textAlignOptions = [
  {
    key: 'left',
    value: 'left',
    icon: 'align-left',
    tooltipText: `align.start-vertical`,
  },
  {
    key: 'center',
    value: 'center',
    icon: 'align-center',
    tooltipText: `align.center`,
  },
  {
    key: 'right',
    value: 'right',
    icon: 'align-right',
    tooltipText: `align.end-vertical`,
  },
  {
    key: 'justify',
    value: 'justify',
    icon: 'align-justify',
    tooltipText: `align.justify`,
  },
];

const listOptions = [
  {
    key: 'bullet-list',
    value: 'bullet',
    icon: 'list',
    tooltipText: `textPopover.marked_list`,
  },
  {
    key: 'numbered-list',
    value: 'numerated',
    icon: 'list-numbers',
    tooltipText: `textPopover.numbered_list`,
  },
];

type AlignProperties = {
  left: boolean;
  right: boolean;
  center: boolean;
  justify: boolean;
  bullet: boolean;
  numerated: boolean;
};

const initialAlignProperties = {
  left: false,
  right: false,
  center: false,
  justify: false,
};

const initialListProperties = {
  bullet: false,
  numerated: false,
};

const TextAlignTool = ({ activeObject, editor }: IBasicEditorProps) => {
  const { historySaveAction } = useCanvasHistory();
  const { canvas } = editor;
  const [activeProperty, setActiveProperty] = useState<AlignProperties>({
    ...initialAlignProperties,
    ...initialListProperties,
  });

  useEffect(() => {
    const textBox = activeObject as Textbox;
    if (textBox.textAlign && (textBox.textAlign as keyof AlignProperties)) {
      setActiveProperty((prev) => ({
        ...prev,
        [textBox.textAlign as keyof AlignProperties]: true,
      }));
    }
    const listType = JSON.parse(textBox.globalCompositeOperation || '{}')?.listType;
    if (listType) {
      setActiveProperty((prev) => ({ ...prev, [listType]: true }));
    }
  }, []);

  const handleTextAlignItemClick = (value: string) => () => {
    if (activeObject && editor.canvas) {
      // const oldTextStyle = activeProperty[value as keyof AlignProperties]; На случаи если Вика, попросит вернуть
      const newActiveProperty = {
        ...activeProperty,
        ...initialAlignProperties,
        [value]: true,
      };

      setActiveProperty(newActiveProperty);

      if (
        Object.entries(newActiveProperty).every(([key, val]) => {
          if (key === 'bullet' || key === 'numerated') return true;
          return val === false;
        })
      ) {
        value = 'left';
      }

      alignText(activeObject, editor.canvas, value).then(() => {
        historySaveAction(editor);
      });

      focusOnTextBox(activeObject as Textbox);
    }
  };

  const handleListTypeClick = (listType: string) => () => {
    if (activeObject && activeObject.type === 'textbox') {
      setActiveProperty((prev) => ({
        ...prev,
        ...initialListProperties,
        [listType]: !activeProperty[listType as keyof AlignProperties],
      }));
      activeObject.set(
        'globalCompositeOperation',
        JSON.stringify({
          ...JSON.parse(activeObject.globalCompositeOperation || '{}'),
          listType,
        }),
      );
      canvas.renderAll();
      canvas.discardActiveObject();
      canvas.setActiveObject(activeObject);

      handleLists(
        activeObject as Textbox,
        canvas,
        undefined,
        activeProperty[listType as keyof AlignProperties],
      ).then(() => {
        canvas.renderAll();
        historySaveAction(editor);
        fabric.util.clearFabricFontCache();
      });

      focusOnTextBox(activeObject as Textbox);
    }
  };

  const handleKeyboardEvents = useCallback(
    (e: KeyboardEvent) => {
      const actionKey = getActionKey();
      switch (e.code) {
        case 'KeyL': {
          if (e[actionKey]) {
            e.preventDefault();
            handleTextAlignItemClick('left')();
          }
          break;
        }
        case 'KeyR': {
          if (e[actionKey]) {
            e.preventDefault();
            handleTextAlignItemClick('right')();
          }
          break;
        }
        case 'KeyE': {
          if (e[actionKey]) {
            e.preventDefault();
            handleTextAlignItemClick('center')();
          }
          break;
        }
      }
    },
    [activeObject, editor],
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyboardEvents, false);
    return () => {
      document.removeEventListener('keydown', handleKeyboardEvents, false);
    };
  }, [handleKeyboardEvents]);

  return (
    <Properties>
      {textAlignOptions.map((option) => (
        <Property
          key={option.key}
          data-tooltip={t(option.tooltipText)}
          data-tooltip-position="left"
          active={activeProperty[option.key as keyof AlignProperties]}
          onClick={handleTextAlignItemClick(option.value)}
        >
          <span className={`icon-${option.icon}`} />
        </Property>
      ))}
      {listOptions.map((option) => (
        <Property
          key={option.key}
          data-tooltip={t(option.tooltipText)}
          data-tooltip-position="left"
          active={activeProperty[option.value as keyof AlignProperties]}
          onClick={handleListTypeClick(option.value)}
        >
          <span className={`icon-${option.icon}`} />
        </Property>
      ))}
    </Properties>
  );
};

export default TextAlignTool;

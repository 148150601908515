import React, { useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import { Swiper as SwiperType } from 'swiper';
import { useTranslation } from 'react-i18next';

import { TemplateItem } from '../../../components/toolbar/template-popover/template-popover.style';
import { Text } from '../../../components/text.component';
import SkeletonLoader from '../../../components/skeleton-loader.component';
import { TGetListResponse } from '../../../interfaces/infographics.interface';
import { RateDetails } from '../../../contexts/user.context';
import { RateNames } from '../../../constants/rates.constant';
import { InfoGraphicRequest } from '../../../requests/infographic.request';
import { loadImage } from '../../../requests/project.requests';
import { TemplateCard } from '../../../components/template-card.component';
import { fallbackImage } from '../../../constants/shared.constants';

const TemplateHeader: React.FC<{
  templateList: TGetListResponse[];
  rate: RateDetails;
  setTemplateList: React.Dispatch<React.SetStateAction<TGetListResponse[]>>;
  setSelectedTemplate: React.Dispatch<React.SetStateAction<TGetListResponse | null>>;
  setIsWithTemplateModal: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ templateList, rate, setTemplateList, setSelectedTemplate, setIsWithTemplateModal }) => {
  const { t } = useTranslation();
  const swiperRef = useRef<{ swiper: SwiperType } | null>(null);
  const [thumbnails, setThumbnails] = useState<string[]>([]);
  const [isTemplateLoading, setIsTemplateLoading] = useState<boolean>(false);

  const handleSelectTemplate = (template: TGetListResponse) => {
    setIsWithTemplateModal(true);
    setSelectedTemplate({ ...template, thumbnail: template.thumbnail });
  };

  useEffect(() => {
    if (rate) {
      const today = new Date().getTime();
      const endDate = new Date(rate?.expireDate).getTime();
      const difference = Math.floor((endDate - today) / (1000 * 60 * 60 * 24)) + 1;
      setIsTemplateLoading(true);
      if (rate.name === RateNames.TRIAL) {
        if (difference >= 1 && rate.existingProjectCount !== 0) {
          InfoGraphicRequest.getGlobalList({
            orderType: 'DESC',
            page: 0,
            sortingType: 'BY_POPULARITY',
            templateType: 'CANVAS',
          })
            .then(async (res) => {
              setTemplateList(res.list);
              const thumbnailsArr = await Promise.all(
                [...res.list].map(async (item) => {
                  return item.imageId ? await loadImage(item.imageId, 'NORMAL') : '';
                }),
              );
              setThumbnails(thumbnailsArr);
            })
            .finally(() => setIsTemplateLoading(false));
        }
      } else {
        if (difference >= 1) {
          InfoGraphicRequest.getGlobalList({
            orderType: 'DESC',
            page: 0,
            sortingType: 'BY_POPULARITY',
            templateType: 'CANVAS',
          })
            .then(async (res) => {
              setTemplateList(res.list);
              const thumbnailsArr = await Promise.all(
                [...res.list].map(async (item) => {
                  return item.imageId ? await loadImage(item.imageId, 'NORMAL') : '';
                }),
              );
              setThumbnails(thumbnailsArr);
            })
            .finally(() => setIsTemplateLoading(false));
        }
      }
    }
  }, [rate]);

  return (
    <>
      <Text fontWeight={700} fontSize="1.4375rem">
        Популярные шаблоны
      </Text>
      {isTemplateLoading ? (
        <div className="swiper-track">
          {Array.from({ length: 18 }).map((_, index) => (
            <TemplateItem key={index} height={'160px'} style={{ width: '115px' }}>
              <SkeletonLoader width="115px" height="100%" />
            </TemplateItem>
          ))}
        </div>
      ) : (
        <Swiper
          ref={swiperRef}
          className={'swiper'}
          slidesPerView={'auto'}
          spaceBetween={10}
          navigation={
            templateList.length > 7
              ? {
                  nextEl: '.swiper-button-next',
                  prevEl: '.swiper-button-prev',
                }
              : false
          }
          modules={[Navigation]}
        >
          {templateList.map((template: TGetListResponse, index: number) => (
            <SwiperSlide key={template.id} style={{ width: 'unset' }}>
              <TemplateCard
                src={thumbnails[index]}
                overlay={t('action.choose') as string}
                onClick={() =>
                  handleSelectTemplate({
                    ...template,
                    thumbnail: thumbnails[index] || fallbackImage,
                  })
                }
              />
            </SwiperSlide>
          ))}
          <div className="swiper-button swiper-button-prev">
            <span className="icon-arrow-left" />
          </div>
          <div className="swiper-button swiper-button-next">
            <span className="icon-arrow-right" />
          </div>
        </Swiper>
      )}
    </>
  );
};

export default TemplateHeader;

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

export type IGradientRangeValue = { start: number; end: number };

interface IGradientRangeSlider {
  colors: string[];
  gradientRange: IGradientRangeValue;
  setGradientRange: (value: IGradientRangeValue) => void;
  activeColorIndex: number;
  setActiveColorIndex: (value: number) => void;
}

const Wrapper = styled.div<{ value: IGradientRangeValue; colors: string[] }>`
  position: relative;
  height: 1rem;
  width: 100%;
  background: ${({ colors, value }) =>
    value.end < value.start
      ? `linear-gradient(90deg, ${colors[1]} ${value.end * 100}%, ${colors[0]} ${value.start * 100}%)`
      : `linear-gradient(90deg, ${colors[0]} ${value.start * 100}%, ${colors[1]} ${value.end * 100}%)`};
  border: 1px solid ${(p) => p.theme.lightGray};
  border-radius: 8px;
  margin: 0.75rem 0;
  & > input[type='range'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 100%;
    outline: none;
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    background-color: transparent;
    pointer-events: none;
    &::-webkit-slider-runnable-track {
      -webkit-appearance: none;
      height: 5px;
    }
    &::-moz-range-track {
      -moz-appearance: none;
      height: 5px;
    }
    &::-ms-track {
      appearance: none;
      height: 5px;
    }
    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      min-height: 1.0625rem;
      min-width: 1.0625rem;
      height: 1.0625rem;
      width: 1.0625rem;
      cursor: pointer;
      margin-top: -6px;
      pointer-events: auto;
      border: 2px solid #fff;
      border-radius: 50%;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
      transition: all ease-in-out 0.2s;
    }
    &::-moz-range-thumb {
      -webkit-appearance: none;
      min-height: 0.75rem;
      min-width: 0.75rem;
      height: 0.75rem;
      width: 0.75rem;
      cursor: pointer;
      pointer-events: auto;
      border: 2px solid #fff;
      border-radius: 50%;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
      transition: all ease-in-out 0.2s;
    }
    &::-ms-thumb {
      appearance: none;
      min-height: 0.75rem;
      min-width: 0.75rem;
      height: 0.75rem;
      width: 0.75rem;
      cursor: pointer;
      pointer-events: auto;
      border: 2px solid #fff;
      border-radius: 50%;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
      transition: all ease-in-out 0.2s;
    }
    &:active::-webkit-slider-thumb {
      background-color: #ffffff;
      border: 3px solid #3264fe;
    }
    &:first-of-type {
      &::-webkit-slider-thumb {
        background: ${(p) => p.colors[0]};
      }
      &::-moz-range-thumb {
        background: ${(p) => p.colors[0]};
      }
      &::-ms-thumb {
        background: ${(p) => p.colors[0]};
      }
    }
    &:last-of-type {
      &::-webkit-slider-thumb {
        background: ${(p) => p.colors[1]};
      }
      &::-moz-range-thumb {
        background: ${(p) => p.colors[1]};
      }
      &::-ms-thumb {
        background: ${(p) => p.colors[1]};
      }
    }
    &.activeRange {
      &::-webkit-slider-thumb {
        border: 4px solid #fff;
      }
      &::-moz-range-thumb {
        border: 4px solid #fff;
      }
      &::-ms-thumb {
        border: 4px solid #fff;
      }
    }
  }
`;

const GradientRangeSlider = ({
  colors,
  gradientRange,
  setGradientRange,
  activeColorIndex,
  setActiveColorIndex,
}: IGradientRangeSlider) => {
  const [value, setValue] = useState<IGradientRangeValue>(gradientRange);

  useEffect(() => {
    setGradientRange(value);
  }, [value]);

  const handleChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    setActiveColorIndex(target.name === 'start' ? 0 : 1);
    setValue((prev) => ({ ...prev, [target.name]: parseFloat(target.value) }));
  };

  return (
    <Wrapper value={value} colors={colors}>
      <input
        name="start"
        type="range"
        className={activeColorIndex === 0 ? 'activeRange' : ''}
        min={0}
        max={1}
        step={0.01}
        value={value.start}
        onChange={handleChange}
        onClick={() => setActiveColorIndex(0)}
      />
      <input
        name="end"
        type="range"
        className={activeColorIndex === 1 ? 'activeRange' : ''}
        min={0}
        max={1}
        step={0.01}
        value={value.end}
        onChange={handleChange}
        onClick={() => setActiveColorIndex(1)}
      />
    </Wrapper>
  );
};

export default GradientRangeSlider;

import styled, { css } from 'styled-components';
import { Card } from '../../components/card.component';
import { Text } from '../../components/text.component';
import { FoldersRow } from '../files/files.style';
import { Row } from '../../components/grid.component';
import { InputOverlay } from '../my-projects/my-projects.style';
import { InputOverlay as InputCheckbox } from '../../components/card.checkbox.overlay';

export const Wrapper = styled.div`
  display: flex;
  gap: 10px;
`;

export const NewCardButton = styled.div<{ isScaled?: boolean }>`
  display: flex;
  padding: 20px;
  width: 240px;
  height: 160px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), #ffffff;
  border-radius: 16px;
  cursor: pointer;
  > img {
    height: 35px;
    width: 35px;
    align-self: end;
  }

  ${(p) =>
    p.isScaled &&
    css`
      &:hover {
        > label > img,
        img {
          transform: scale(1.143);
          transition: all 0.3s;
        }
      }
    `}
`;

export const ButtonsWrap = styled.div<{ spaceBetween?: boolean }>`
  display: flex;
  justify-content: ${(p) => (p.spaceBetween ? 'space-between' : 'flex-end')};
`;

export const ModalContent = styled.div`
  display: grid;
  gap: 12px;
`;

export const FolderModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const MoveToFolderModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;

  ${FoldersRow} {
    max-height: 350px;
    @media (max-height: 752px) {
      max-height: 150px;
    }
  }

  & > ${Text}:first-child {
    margin-bottom: -0.625rem;
  }
`;

export const AddToFolderModalContent = styled.div<{ type?: 'file' | 'project' }>`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  padding: 0.25rem;
  ${Text} {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  & > ${Text}:first-child {
    margin-bottom: -0.625rem;
  }

  ${Row} {
    display: grid;
    grid-template-columns: ${(p) => (p.type === 'project' ? 'repeat(3, 1fr)' : 'repeat(6, 1fr)')};
    gap: 10px;
  }
`;

export const CustomCard = styled(Card)<{ active?: boolean; highlight?: boolean }>`
  position: relative;
  height: 163px;
  width: 240px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: space-between;
  word-wrap: break-word;
  & > ${Text} {
    font-weight: 700;
  }
  border: 2px solid transparent;

  ${({ highlight }) =>
    highlight &&
    css`
      &:hover {
        border: 2px solid #6620c7 !important;
        & > ${InputOverlay} {
          border: 2px solid #6620c7 !important;
        }
      }
    `}

  ${({ highlight, active }) =>
    highlight &&
    css`
      border: 2px solid ${active ? '#6620C7' : '#cccccc'};
      user-select: none;
    `}
`;

export const CustomImgCard = styled(Card)`
  height: 160px;
  width: 240px;
  justify-content: center;
  display: flex;
`;

const ColumnTemplate = styled.div`
  position: relative;
  min-height: 1px;
  display: block;
`;

export const ProjectColumn = styled(ColumnTemplate)`
  overflow: hidden;
  white-space: 'pre-line';
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  resize: none;
  display: -webkit-box;
  max-width: 100%;
`;

export const FolderInput = styled.textarea<any>`
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-size: 23px;
  line-height: 31px;
  border: none;
  background: #fff;
  overflow: hidden;
  display: -webkit-box;
  white-space: 'pre-line';
  -webkit-box-orient: vertical;
  resize: none;
  z-index: 100;
  color: #000;

  &:focus,
  &:active {
    outline: 1px dashed #6620c7;
    border-radius: 8px;
    position: relative;
  }
`;

const components = {
  Wrapper,
  NewCardButton,
  ButtonsWrap,
  CustomCard,
  CustomImgCard,
  FolderInput,
};

export default components;

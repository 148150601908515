import React, { useEffect, useState, useRef } from 'react';
import SwiperCore, { Swiper as SwiperType } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, A11y } from 'swiper/modules';
import { Swiper as SwiperClass } from 'swiper/types';

import { OzonType, TProductPageMobile } from '../../../interfaces/preview-image.interface';
import { OzonMobileWrapper, SimilarButton } from '../preview-image.style';
import 'swiper/css';
import styled from 'styled-components';
import { Flex } from '../../../components/flexbox.component';
import { useTranslation } from 'react-i18next';
import OzonMobileView from '../../../assets/images/preview/Ozon-mobile.png';

SwiperCore.use([Navigation, Pagination, A11y]);

const ImageMobileSlider = styled.img<{ ozonType?: OzonType, canvasRatio?: 'default' | 'square' }>`
  height: ${(p) => (p.ozonType === 'Тип 1' ? p.canvasRatio === 'square' ? '320px' : '426px' : '320px')};
  width: ${(p) => (p.ozonType === 'Тип 1' ? '320px' : p.canvasRatio !== 'square' ? 'auto' : '320px')};
  padding: ${(p) => p.ozonType === 'Тип 2' && '20px'};
  display: flex;
  align-items: center;
  object-fit: cover;
  object-position: 0 0;
`;

const OzonMobile: React.FC<TProductPageMobile> = ({
  imagesSrc,
  marketPlace,
  ozonType,
  imageDetails,
  canvasRatio = 'default',
}) => {
  const [activeSlide, setActiveSlide] = useState(0);
  const totalSlides = Object.values(imagesSrc).length;
  const swiperRef = useRef<{ swiper: SwiperType } | null>(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (totalSlides === 1) {
      setActiveSlide(0);
    }
  }, [totalSlides]);

  const handleSlideChange = (swiper: SwiperClass) => {
    setActiveSlide(swiper.activeIndex);

    if (swiper.activeIndex >= totalSlides) {
      swiper.slideTo(totalSlides - 1);
    }
  };

  return (
    <Flex style={{ marginTop: 165 }} gap={85} justifyContent="center" alignItems="center">
      {totalSlides > 1 && (
        <div
          className="icon-arrow-left"
          style={{
            fontSize: '20px',
            cursor: 'pointer',
            opacity: activeSlide === 0 ? 0.2 : 1,
          }}
          onClick={() => swiperRef.current?.swiper?.slidePrev()}
        ></div>
      )}
      <OzonMobileWrapper ozonType={ozonType}>
        <Swiper
          ref={swiperRef}
          className={'swiper'}
          slidesPerView={1}
          spaceBetween={0}
          onSlideChange={handleSlideChange}
        >
          {imageDetails.map((image, index) => (
            <SwiperSlide className={'swiper-slide'} key={index}>
              <ImageMobileSlider
                src={imagesSrc[image.modifiedImageId]}
                alt={`image_${index}`}
                ozonType={ozonType}
                canvasRatio={canvasRatio}
              />
            </SwiperSlide>
          ))}
        </Swiper>
        <SimilarButton
          mobile
          marketPlace={marketPlace}
          data-tooltip={t(`preview-image.similar`) as string}
          data-tooltip-position="left"
        >
          <img src={OzonMobileView} width={28} height={28} style={{
            boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.1)'
          }} />
        </SimilarButton>
      </OzonMobileWrapper>
      {totalSlides > 1 && (
        <div
          className="icon-arrow-right"
          style={{
            fontSize: '20px',
            opacity: activeSlide === totalSlides - 1 ? 0.2 : 1,
            cursor: 'pointer',
          }}
          onClick={() => swiperRef.current?.swiper?.slideNext()}
        ></div>
      )}
    </Flex>
  );
};

export default OzonMobile;

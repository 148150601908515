import { fabric } from 'fabric';
import { SetStateAction } from 'react';
import { CanvasRatio } from '../interfaces/editor.interface';

export const refreshThumbnails = async (
  detailsList: any[],
  setThumbnails?: React.Dispatch<SetStateAction<string[]>>,
  canvasRatio?: CanvasRatio,
) => {
  const width = 1800;
  const height = canvasRatio === 'square' ? 1800 : 2400;
  const thumbnail: string[] = [];

  const promises = detailsList.map((details, index) => {
    return new Promise<void>((resolve) => {
      const canvas = document.createElement('canvas');
      const fabricCanvas = new fabric.Canvas(canvas);

      fabricCanvas.loadFromJSON(details, () => {
        const gridObj = fabricCanvas._objects.find((obj) => obj.name === 'grid');
        if (gridObj) {
          fabricCanvas.remove(gridObj);
        }

        const watermark = fabricCanvas._objects.find((obj) => obj.name === 'watermark');
        if (watermark) {
          fabricCanvas.bringToFront(watermark);
        }

        fabricCanvas.setBackgroundColor(
          details.background || '#ffffff',
          fabricCanvas.renderAll.bind(fabricCanvas),
        );

        fabricCanvas.setWidth(width);
        fabricCanvas.setHeight(height);
        fabricCanvas.renderAll();
        const image = fabricCanvas.toDataURL({ format: 'jpeg', quality: 0.5 });
        thumbnail[index] = image;
        resolve();
      });
    });
  });

  await Promise.all(promises);
  setThumbnails && setThumbnails(thumbnail);
  return thumbnail;
};

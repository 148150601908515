import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

export const Navigation = styled.nav`
  display: flex;
  align-items: center;
  gap: 5px;
  padding-left: 7.8125rem;
`;

export const NavigationLink = styled(NavLink)<{ headerNav?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.375em;
  padding: 0.71875em 1em;
  border-radius: 16px;
  color: ${(p) => p.theme.black};
  text-decoration: none;
  transition: all 250ms ease-in-out;

  ${(p) =>
    p.headerNav &&
    css`
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), rgb(255, 255, 255);
    `}

  &.active,
  &:hover {
    color: ${(p) => p.theme.primary};
    background: linear-gradient(0deg, rgba(102, 32, 199, 0.4), rgba(102, 32, 199, 0.4)),
      ${(p) => p.theme.white};
  }
`;

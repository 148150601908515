import React, { useState, useRef, useEffect } from 'react';
import { FabricJSEditor } from 'fabricjs-react';
import { useTranslation } from 'react-i18next';

import { ToolbarBtn } from './toolbar.style';
import Popover from '../popover.component';
import { CanvasRatio } from '../../interfaces/editor.interface';
import AllImagePopover from './image-popover/all-image-popover.component';

interface IProps {
  editor: FabricJSEditor;
  refreshProjectCard: VoidFunction;
  canvasRatio: CanvasRatio;
}

export const BgImagePopover = ({ editor, refreshProjectCard, canvasRatio }: IProps) => {
  const { t } = useTranslation();
  const popoverRef = useRef<{ close: () => void }>();
  const [popoverActive, setPopoverActive] = useState<boolean>();

  useEffect(() => {
    if (popoverActive === false) {
      refreshProjectCard();
    }
  }, [popoverActive]);

  const handleCheckActive = (isOpen: boolean) => {
    setPopoverActive(isOpen);
  };

  return (
    <Popover
      ref={popoverRef}
      type={'cloud'}
      position={'right-bottom'}
      setIsActive={handleCheckActive}
      className={popoverActive ? 'active' : ''}
      closeOnClick={false}
      relativeToParent={true}
      content={
        <AllImagePopover
          editor={editor}
          bgImage
          canvasRatio={canvasRatio}
          refreshProjectCard={refreshProjectCard}
        />
      }
    >
      <ToolbarBtn
        active={popoverActive}
        data-tooltip-position={'right'}
        data-tooltip={popoverActive ? null : (t('action.backgroundImage') as string)}
      >
        <span className="icon-image" />
      </ToolbarBtn>
    </Popover>
  );
};

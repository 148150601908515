import React, { useRef } from 'react';
import { IconMinus, IconPlus, ShadowToolCol, ShadowToolRow } from './shadow-tool.style';
import { Text } from '../../text.component';
import { NumberInput } from '../../number-input.component';

interface IShadowNumeration {
  value: number;
  setValue: (value: number | ((prevValue: number) => number)) => void;
  label: string;
  max?: number;
  min?: number;
  onFocus?: () => void;
  onBlur?: () => void;
}

const ShadowNumeration: React.FC<IShadowNumeration> = ({
  label,
  setValue,
  value,
  min = -100,
  max = 100,
  onFocus = () => null,
  onBlur = () => null,
}) => {
  const intervalId = useRef<NodeJS.Timeout | null>(null);
  const timeoutId = useRef<NodeJS.Timeout | null>(null);

  const handleMouseDown = (amount: number) => {
    timeoutId.current = setTimeout(() => {
      intervalId.current = setInterval(() => {
        setValue((prevValue) => {
          const newValue = prevValue + amount;
          if (newValue <= max && newValue >= min) {
            return newValue;
          }
          return prevValue;
        });
      }, 50);
    }, 400);
  };

  const handleMouseUpAndLeave = () => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
      timeoutId.current = null;
    }
    if (intervalId.current) {
      clearInterval(intervalId.current);
      intervalId.current = null;
    }
  };

  const handleSingleClick = (amount: number) => {
    setValue((prevValue) => {
      const newValue = prevValue + amount;
      if (newValue <= max && newValue >= min) {
        return newValue;
      }
      return prevValue;
    });
  };

  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    let newValue = parseInt(e.target.value, 10);
    if (isNaN(newValue)) {
      newValue = 0;
    }
    if (newValue >= min && newValue <= max) {
      setValue(newValue);
    }
  };

  return (
    <ShadowToolCol>
      <Text fontWeight={700} fontSize="14px">
        {label}
      </Text>
      <ShadowToolRow>
        <IconMinus
          onMouseDown={() => handleMouseDown(-1)}
          onMouseUp={handleMouseUpAndLeave}
          onMouseLeave={handleMouseUpAndLeave}
          onClick={() => handleSingleClick(-1)}
        />
        <NumberInput value={value} onChange={handleChangeInput} onFocus={onFocus} onBlur={onBlur} />
        <IconPlus
          onMouseDown={() => handleMouseDown(1)}
          onMouseUp={handleMouseUpAndLeave}
          onMouseLeave={handleMouseUpAndLeave}
          onClick={() => handleSingleClick(1)}
        />
      </ShadowToolRow>
    </ShadowToolCol>
  );
};

export default ShadowNumeration;

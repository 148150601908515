import styled, { css } from "styled-components";
import { Headline } from "../headline.component";
import { Text } from "../text.component";
import { SparkleWrapper } from "../../pages/my-projects/my-projects.style";
import { ButtonWrapper } from "../button.component";
import { RateNames } from "../../constants/rates.constant";

const bgColors = {
  [RateNames.BASIC]: '#fff',
  [RateNames.ADVANCED]: 'linear-gradient(0deg, rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0.15) 100%), #FFF;',
  [RateNames.UNLIMITED]: 'linear-gradient(0deg, rgba(102, 32, 199, 0.40) 0%, rgba(102, 32, 199, 0.40) 100%), #FFF;'
}

const RatesContainer = styled.div`
  display: flex;
  gap: 0.625rem;
  flex: 1;
  width: 100%;
`;

const RateItem = styled.div<{ type: string, variant?: string }>`
  flex: 1;
  display: flex;
  padding: 1.25rem;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 1rem;
  background: ${(p) => bgColors[p.type as keyof typeof bgColors]};
  margin-bottom: 1.25rem;
  .rate-header {
    display: flex;
    flex-direction: column;
    gap: 0.3125rem;

    .sb {
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;
    }

    .tag {
      height: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      background: ${(p) => p.theme.lightPrimary};
      padding: 0.375rem 0.625rem;
      color: ${(p) => p.theme.primary};
      border-radius: 8px;
      gap: 0.5rem;

      span {
        font-size: 1.25rem;
      }
    }
  };

  ${({ type, variant }) => type === 'BASIC' && variant === 'small' && `
    border: 1px solid rgba(0, 0, 0, 0.20);
  `}}

  ${({ variant }) => variant === 'small' && `
    min-width: 21rem;
    height: 18.75rem;
    margin: 0;
    `
  }

  .price {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 4.125rem; 
  };

  .current { 
    display: flex;
    gap: 0.3125rem;
    align-items: baseline;
  };

  .footer { 
    display: flex;
    flex-direction: column;
    gap: 1.875rem;
  };

  .current-rate {
    border: 1px solid rgba(0, 0, 0, 0.20);
    background: none;
    box-shadow: none;
    color: ${(p) => p.theme.infoGray};
    cursor: not-allowed;
    height: 3.125rem;
  }
`;

const OverlayWrapper = styled.div`
  height: calc(100vh - 11.75rem);
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1.875rem;
  align-items: center;
  background: ${(p) =>
    `linear-gradient(0deg, ${p.theme.primary}, ${p.theme.primary}), ${p.theme.white}`};
  border-radius: 16px;

  .header {
      ${Headline}, ${Text} {
      color: ${(p) => p.theme.white};
      text-align: center;
    }
  }

  ${SparkleWrapper} {
    margin-top: 0;
  }

  .header {
    display: flex;
    flex-direction: column;
    gap: 0.625rem;
  }
`;

const ProjectsCounterWrapper = styled.div`
  display: flex;
  padding: 1.25rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.25rem;
  border-radius: 1rem;
  background: #FFF;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
  max-width: 15rem;

  .head {
    display: flex;
    flex-direction: column;
    gap: 0.1875rem;
    > div {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  .top{
    display: flex;
    flex-direction: column;
    gap: 0.625rem;
    width: 100%;
  }
`;

const ProgressBar = styled.div<{ percentage: number }>`
  width: 100%;
  max-width: 100%;
  height: 2px;
  background: ${(p) => p.theme.lightPrimary};
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: ${(p) => p.percentage}%;
    background: ${(p) => p.theme.primary};
    height: 2px;
  }
`

export const RateModalContent = styled.div<{ minWidth?: string }>`
  display: flex;
  padding: 0.25rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.25rem;
  min-width: ${(p) => p.minWidth ? p.minWidth : 'unset'};
  max-width: 70.625rem;

  ul {
    margin: 0;
    padding: 0 0 0 1.5rem;
  }

  li {
    font-weight: 500;

    &:marker {
      font-size: 12px;
    }
  }

  .buttons {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .df {
    display: flex;
    gap: 0.625rem;
  }
`;

const RateModalHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
`;

const RateModalFooter = styled.div`
  display: flex;
  align-self: flex-end;
  
  button {
    padding: 0;
  }
`;

const RateModalRatesWrapper = styled.div`
  width: 100%;
  > div:first-child {
    > div {
      margin-bottom: 0.625rem;
    }
  }
`;

export {
  RatesContainer,
  RateItem,
  OverlayWrapper,
  ProjectsCounterWrapper,
  ProgressBar,
  RateModalHeader,
  RateModalFooter,
  RateModalRatesWrapper
}


import styled, { css } from 'styled-components';

interface IFlexBoxProps {
  justifyContent?: string,
  alignItems?: string,
  gap?: number,
  mt?: number, // margin-top
  mb?: number, // margin-bottom
  wrapItems?: boolean,
}

export const Flex = styled.div<IFlexBoxProps>`
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent ?? ''};
  align-items: ${({ alignItems }) => alignItems ?? ''};
  gap: ${({ gap }) => gap ? `${gap}px` : '0'};

  ${({ mt }) => mt && css`
    margin-top: ${mt}px;
  `}

  ${({ mb }) => mb && css`
    margin-bottom: ${mb}px;
  `}

  ${({ wrapItems }) => wrapItems && css`
    flex-wrap: wrap;
  `}
`;

export const FlexCol = styled(Flex)<IFlexBoxProps>`
  flex-direction: column;
`;

import React, { useEffect, useState } from 'react';
import { FlexCol } from '../../flexbox.component';
import { Text } from '../../text.component';
import SkeletonLoader from '../../skeleton-loader.component';
import useInfiniteScroll from '../../../hooks/use-infinite-scroll';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { TGetImageList } from '../../../interfaces/gallery.interface';
import { Col } from '../../grid.component';
import { Thumbnail, ThumbnailRow } from '../toolbar.style';
import CircleLoader2 from '../../circle-loader2';
import { SortType } from '../../../interfaces/editor.interface';
import { ICreateFolderRequest, IImages } from '../../../interfaces/projects.interface';
import useDebounce from '../../../hooks/use-debounce.hooks';
import useMouseDown from '../../../hooks/use-mouse-down.hooks';
import CardCheckboxOverlay from '../../card.checkbox.overlay';
import ImageHighlight from '../image-highlight.component';

type TImageFoldersTab = {
  loadingFolder: boolean;
  folderList: ICreateFolderRequest[];
  setFolderImageListOpened: React.Dispatch<
    React.SetStateAction<{ isOpen: boolean; name: string; id: number }>
  >;
  getFolderList: ({ name, selectedOption, sortAsc, page }: TGetImageList) => void;
  getFolderImagesList: ({
    id,
    name,
    selectedOption,
    sortAsc,
    page,
  }: TGetImageList & { id: number }) => void;
  folderImageListOpened: { isOpen: boolean; name: string; id: number };
  loadingImageFolder: boolean;
  shapeImage: boolean | undefined;
  bgImage: boolean | undefined;
  handleLoadFullImageSelect: (imageId: number, index: number) => void;
  handleLoadFullImage: (image: IImages) => void;
  folderImagesList: IImages[];
  setFolderImagesList: React.Dispatch<React.SetStateAction<IImages[]>>;
  folderSort: SortType;
  folderImageSort: SortType;
};

const FoldersList = styled.div`
  max-width: 100%;
  height: fit-content;
  padding: 15px 20px;
  border: 2px solid #6620c7d9;
  word-break: break-word;
  white-space: normal;
  cursor: pointer;
  border-radius: 8px;
  margin-right: 6px;
`;

const ImageFoldersTab: React.FC<TImageFoldersTab> = ({
  loadingFolder,
  folderList,
  setFolderImageListOpened,
  getFolderList,
  folderImageListOpened,
  loadingImageFolder,
  shapeImage,
  bgImage,
  handleLoadFullImageSelect,
  handleLoadFullImage,
  folderImagesList,
  getFolderImagesList,
  setFolderImagesList,
  folderImageSort,
  folderSort,
}) => {
  const { t } = useTranslation();
  const debouncedSearchValueFolderImage = useDebounce(folderImageSort.search, 500);
  const debouncedSearchValueFolder = useDebounce(folderSort.search, 500);
  const folderImageInfiniteScroll = useInfiniteScroll(
    loadingImageFolder,
    folderImageSort.hasMore,
    folderImageSort.setPage,
  );

  const folderInfiniteScroll = useInfiniteScroll(
    loadingFolder,
    folderSort.hasMore,
    folderSort.setPage,
  );

  const [highlight, setHighlight] = useState<{ show: boolean; imageId: number }>({
    show: false,
    imageId: 0,
  });
  const [highlightedFiles, setHighlightedFiles] = useState<number[]>([]);
  const [isHighlighting, setIsHighlighting] = useState<boolean>(false);
  const isRightMouseDown = useMouseDown();

  useEffect(() => {
    if (highlightedFiles.length > 0) {
      setIsHighlighting(true);
    }
  }, [highlightedFiles]);

  useEffect(() => {
    if (folderSort.page !== 0)
      getFolderList({
        name: folderSort.search,
        page: folderSort.page,
        selectedOption: folderSort.selectedOption.value,
        sortAsc: folderSort.sortAsc,
      });
  }, [folderSort.page]);

  useEffect(() => {
    if (folderImageSort.page !== 0 && folderImageListOpened.isOpen)
      getFolderImagesList({
        id: folderImageListOpened.id,
        name: folderImageSort.search,
        page: folderImageSort.page,
        selectedOption: folderImageSort.selectedOption.value,
        sortAsc: folderImageSort.sortAsc,
      });
  }, [folderImageSort.page, folderImageListOpened.isOpen]);

  useEffect(() => {
    if (folderImageListOpened.isOpen) {
      folderImageSort.setPage(0);
      setFolderImagesList([]);
      getFolderImagesList({
        id: folderImageListOpened.id,
        name: folderImageSort.search,
        page: 0,
        selectedOption: folderImageSort.selectedOption.value,
        sortAsc: folderImageSort.sortAsc,
      });
    }
  }, [
    folderImageSort.selectedOption,
    folderImageSort.sortAsc,
    debouncedSearchValueFolderImage,
    folderImageListOpened.isOpen,
  ]);

  const folderImagesContent = (
    <Col style={{ marginTop: '20px' }}>
      <ThumbnailRow
        onScroll={folderImageInfiniteScroll.handleScroll}
        isHighlighting={isHighlighting}
      >
        {folderImagesList.length > 0 ? (
          folderImagesList.map((image, index) => (
            <Thumbnail
              key={image.imageId}
              src={`/api/project/load/image/png?imageId=${image.imageId}&imageSizeType=ICON`}
              data-tooltip={image.imageName}
              data-tooltip-position="bottom"
              onMouseEnter={() => setHighlight({ show: true, imageId: image.id })}
              onMouseLeave={() => highlight.show && setHighlight({ show: true, imageId: 0 })}
            >
              {(highlight.imageId === image.id || highlightedFiles.includes(image.id)) && (
                <CardCheckboxOverlay
                  image
                  cardId={image.id}
                  highlight={highlight.show}
                  highlightedCards={highlightedFiles}
                  setHighlightedCard={setHighlightedFiles}
                  isRightMouseDown={isRightMouseDown}
                  imageLoadFunction={() => {
                    if (!image.pending) {
                      shapeImage || bgImage
                        ? handleLoadFullImageSelect(image.imageId, index)
                        : !image.pending && handleLoadFullImage(image);
                    }
                  }}
                />
              )}
              {image.pending && <CircleLoader2 shineColor="#6620C7" />}
            </Thumbnail>
          ))
        ) : (
          <>
            {!loadingImageFolder &&
              folderImagesList.length === 0 &&
              debouncedSearchValueFolderImage === '' && (
                <Text>{t('editor.imagesNotFoundInFolder')}</Text>
              )}
          </>
        )}
      </ThumbnailRow>
      {loadingImageFolder && folderImagesList.length === 0 && (
        <ThumbnailRow style={{ marginTop: '20px' }} heightPX={0}>
          {Array.from({ length: 20 }).map((_, index) => (
            <SkeletonLoader width="70px" height="70px" key={index} />
          ))}
        </ThumbnailRow>
      )}
      {folderImagesList.length === 0 &&
        !loadingImageFolder &&
        debouncedSearchValueFolderImage !== '' && (
          <Text style={{ color: '#00000066' }}>
            По вашему запросу ничего не найдено. Повторите попытку, набрав другие слова.
          </Text>
        )}
      {isHighlighting && (
        <ImageHighlight
          setIsHighlighting={setIsHighlighting}
          highlightedFiles={highlightedFiles}
          setHighlightedFiles={setHighlightedFiles}
          choiceList={folderImagesList}
          refreshRequest={() => {
            folderImageSort.setPage(0);
            setFolderImagesList([]);
            getFolderImagesList({
              id: folderImageListOpened.id,
              name: folderImageSort.search,
              page: 0,
              selectedOption: folderImageSort.selectedOption.value,
              sortAsc: folderImageSort.sortAsc,
            });
          }}
        />
      )}
    </Col>
  );

  const FolderContent = (
    <FlexCol
      onScroll={folderInfiniteScroll.handleScroll}
      gap={10}
      style={{
        maxHeight: '472px',
        overflow: 'auto',
        height: 'fit-content',
        marginTop: '20px',
      }}
    >
      {!folderList.length && debouncedSearchValueFolder === '' && !loadingFolder ? (
        <Text>{t('editor.foldersNotFound')}</Text>
      ) : (
        folderList.map((folder) => (
          <FoldersList
            onClick={() => {
              folder.id &&
                setFolderImageListOpened({ isOpen: true, name: folder.folderName, id: folder.id });
            }}
            key={folder.id}
          >
            {folder.folderName}
          </FoldersList>
        ))
      )}
      {loadingFolder && (
        <FlexCol gap={10} style={{ marginTop: '-20px' }}>
          {Array.from({ length: 8 }).map((_, index) => (
            <SkeletonLoader key={index} width="322px" height="52px" />
          ))}
        </FlexCol>
      )}
    </FlexCol>
  );

  return folderImageListOpened.isOpen ? folderImagesContent : FolderContent;
};

export default ImageFoldersTab;

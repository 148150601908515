import React, { ReactElement } from 'react';
import { ReactComponent as Circle } from '../assets/images/shapes/circle.svg';
import { ReactComponent as Square } from '../assets/images/shapes/square.svg';
import { ReactComponent as Triangle } from '../assets/images/shapes/triangle.svg';
import { ReactComponent as Hexagon } from '../assets/images/shapes/hexagon.svg';
import { ReactComponent as Octagon } from '../assets/images/shapes/octagon.svg';
import { ReactComponent as Rhombus } from '../assets/images/shapes/rhombus.svg';
import { ReactComponent as Parallelogram } from '../assets/images/shapes/parallelogram.svg';
import { ReactComponent as Star1 } from '../assets/images/shapes/star-1.svg';
import { ReactComponent as Star2 } from '../assets/images/shapes/star-2.svg';
import { ReactComponent as Star3 } from '../assets/images/shapes/star-3.svg';
import { ReactComponent as Star4 } from '../assets/images/shapes/star-4.svg';
import { ReactComponent as Star } from '../assets/images/shapes/star.svg';
import { ReactComponent as Star5 } from '../assets/images/shapes/star-5.svg';
import { ReactComponent as Star6 } from '../assets/images/shapes/star-6.svg';
import { ReactComponent as Heart } from '../assets/images/shapes/heart.svg';
import { ReactComponent as Line } from '../assets/images/shapes/line.svg';

export const SHAPE_NAMES = [
  'ellipse',
  'square',
  'triangle',
  'hexagon',
  'octagon',
  'rhombus',
  'parallelogram',
  'star1',
  'star2',
  'star3',
  'star4',
  'star',
  'star5',
  'star6',
  'heart',
  'line',
] as const;

export const SHAPES: { key: (typeof SHAPE_NAMES)[number]; icon: ReactElement }[] = [
  { key: 'ellipse', icon: <Circle /> },
  { key: 'square', icon: <Square /> },
  { key: 'triangle', icon: <Triangle /> },
  { key: 'hexagon', icon: <Hexagon /> },
  { key: 'octagon', icon: <Octagon /> },
  { key: 'rhombus', icon: <Rhombus /> },
  { key: 'parallelogram', icon: <Parallelogram /> },
  { key: 'star1', icon: <Star1 /> },
  { key: 'star2', icon: <Star2 /> },
  { key: 'star3', icon: <Star3 /> },
  { key: 'star4', icon: <Star4 /> },
  { key: 'star', icon: <Star /> },
  { key: 'star5', icon: <Star5 /> },
  { key: 'star6', icon: <Star6 /> },
  { key: 'heart', icon: <Heart /> },
  { key: 'line', icon: <Line /> },
];

export const SHAPE_PATH: { [key: string]: string } = {
  hexagon: 'M38.9711 0L77.9423 22.5V67.5L38.9711 90L0 67.5V22.5L38.9711 0Z',
  octagon:
    'M45.9414 0L77.7612 13.1802L90.9414 45L77.7612 76.8198L45.9414 90L14.1216 76.8198L0.941406 45L14.1216 13.1802L45.9414 0Z',
  rhombus: 'M30.9414 0L60.9414 45L30.9414 90L0.941406 45L30.9414 0Z',
  triangle:
    'M28.8123 5.73333C31.5624 0.755556 38.4376 0.755556 41.1877 5.73333L69.0321 56.1333C71.7822 61.1111 68.3446 67.3333 62.8444 67.3333H7.15555C1.65541 67.3333 -1.78218 61.1111 0.967893 56.1333L28.8123 5.73333Z',
  star: 'M45 0.202637L58.9058 28.3788L90 32.897L67.5 54.8291L72.8115 85.7977L45 71.1763L17.1885 85.7977L22.5 54.8291L0 32.897L31.0942 28.3788L45 0.202637Z',
  star1:
    'M39 0L54.75 17.7202L77.9711 22.5L70.5 45L77.9711 67.5L54.75 72.2798L39 90L23.25 72.2798L0.0288582 67.5L7.5 45L0.0288582 22.5L23.25 17.7202L39 0Z',
  star2:
    'M45 0L57.0545 15.8978L76.8198 13.1802L74.1022 32.9455L90 45L74.1022 57.0545L76.8198 76.8198L57.0545 74.1022L45 90L32.9455 74.1022L13.1802 76.8198L15.8978 57.0545L0 45L15.8978 32.9455L13.1802 13.1802L32.9455 15.8978L45 0Z',
  star3:
    'M45 0L54.3175 10.2267L67.5 6.02886L70.4558 19.5442L83.9711 22.5L79.7733 35.6825L90 45L79.7733 54.3175L83.9711 67.5L70.4558 70.4558L67.5 83.9711L54.3175 79.7733L45 90L35.6825 79.7733L22.5 83.9711L19.5442 70.4558L6.02886 67.5L10.2267 54.3175L0 45L10.2267 35.6825L6.02886 22.5L19.5442 19.5442L22.5 6.02886L35.6825 10.2267L45 0Z',
  star4:
    'M45 0L50.2863 4.84649L56.6469 1.53334L60.4987 7.58288L67.5 6.02886L69.6548 12.8692L76.8198 13.1802L77.1308 20.3452L83.9711 22.5L82.4171 29.5013L88.4667 33.3531L85.1535 39.7137L90 45L85.1535 50.2863L88.4667 56.6469L82.4171 60.4987L83.9711 67.5L77.1308 69.6548L76.8198 76.8198L69.6548 77.1308L67.5 83.9711L60.4987 82.4171L56.6469 88.4667L50.2863 85.1535L45 90L39.7137 85.1535L33.3531 88.4667L29.5013 82.4171L22.5 83.9711L20.3452 77.1308L13.1802 76.8198L12.8692 69.6548L6.02886 67.5L7.58288 60.4987L1.53334 56.6469L4.84649 50.2863L0 45L4.84649 39.7137L1.53334 33.3531L7.58288 29.5013L6.02886 22.5L12.8692 20.3452L13.1802 13.1802L20.3452 12.8692L22.5 6.02886L29.5013 7.58288L33.3531 1.53334L39.7137 4.84649L45 0Z',
  star5:
    'M39 0L48 29.4115L77.9711 22.5L57 45L77.9711 67.5L48 60.5885L39 90L30 60.5885L0.0288582 67.5L21 45L0.0288582 22.5L30 29.4115L39 0Z',
  star6:
    'M45 0L57.7279 32.2721L90 45L57.7279 57.7279L45 90L32.2721 57.7279L0 45L32.2721 32.2721L45 0Z',
  heart:
    'M82.8601 7.6547C80.6043 5.39109 77.9239 3.59503 74.9725 2.36953C72.0211 1.14402 68.8569 0.513184 65.6612 0.513184C62.4655 0.513184 59.3012 1.14402 56.3498 2.36953C53.3985 3.59503 50.718 5.39109 48.4623 7.6547L45 11.1619L41.5377 7.6547C39.282 5.39109 36.6015 3.59503 33.6502 2.36953C30.6988 1.14402 27.5345 0.513184 24.3388 0.513184C21.1431 0.513184 17.9789 1.14402 15.0275 2.36953C12.0761 3.59503 9.39568 5.39109 7.1399 7.6547C-2.39257 17.1872 -2.9771 33.2845 9.02841 45.5148L45 81.4864L80.9716 45.5148C92.9771 33.2845 92.3926 17.1872 82.8601 7.6547Z',
};

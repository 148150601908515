import React from 'react';
import { useTranslation } from 'react-i18next';

import { useCanvasHistory } from '../../contexts/canvas-history.context';
import { handleChangeObjectAlign, focusOnTextBox, blurTextBox } from '../../utils/editor.utils';
import { ALIGN_OPTIONS } from '../../constants';
import { Properties, Property } from './object-toolbar.style';
import { IBasicEditorProps } from '../../interfaces/editor.interface';
import { Textbox } from 'fabric/fabric-impl';

export const AlignTool = ({ editor, activeObject }: IBasicEditorProps) => {
  const { t } = useTranslation();
  const { historySaveAction } = useCanvasHistory();

  const handleSetAlign = (key: (typeof ALIGN_OPTIONS)[number]) => () => {
    const { canvas } = editor;
    if (activeObject) {
      if (activeObject.type === 'textbox') {
        blurTextBox(activeObject as Textbox);
      }
      handleChangeObjectAlign(canvas, activeObject, key).then(() => {
        historySaveAction(editor);
      });
    }
  };

  return (
    <Properties>
      {ALIGN_OPTIONS.map((key) => (
        <Property
          key={key}
          onClick={handleSetAlign(key)}
          data-tooltip={t(`align.${key}`) as string}
          data-tooltip-position={'left'}
        >
          <span className={`icon-align-${key}`} />
        </Property>
      ))}
    </Properties>
  );
};

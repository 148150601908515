import React from 'react';
import SkeletonLoader from '../skeleton-loader.component';

const ImageListLoader: React.FC<{ count?: number; width?: number; borderRadius?: string }> = ({
  count = 20,
  width,
  borderRadius,
}) => {
  return (
    <>
      {Array.from({ length: count }).map((_, index) => (
        <SkeletonLoader
          borderRadius={borderRadius && borderRadius}
          key={index}
          width={width ? `${width}%` : '240px'}
          height={'160px'}
        />
      ))}
    </>
  );
};

export default ImageListLoader;

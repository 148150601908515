import React, { useEffect, useRef, useState } from 'react';
import { Flex, FlexCol } from '../../components/flexbox.component';
import {
  LabelFromOzon,
  LabelsFromOzon,
  PreviewImageBlock,
  PreviewImageSelect,
  SimilarButton,
} from './preview-image.style';
import { IArrowBtnProps } from '../../interfaces/editor.interface';
import styled, { css } from 'styled-components';
import { MarketPlaceType, OzonType, TImageDetail } from '../../interfaces/preview-image.interface';
import SkeletonLoader from '../../components/skeleton-loader.component';
import CircleLoader2 from '../../components/circle-loader2';
import { useTranslation } from 'react-i18next';
import WBPreviewImage from '../../assets/images/preview/WB-PC.png';
import YMPreviewImage from '../../assets/images/preview/YM-PC.png';
import OzonPreviewImage from '../../assets/images/preview/Ozon-PC.png';
import NoPreview from './components/no-preview';

const PreviewImageSelectContainer = styled(FlexCol) <{ marketPlace: MarketPlaceType }>`
  ${(p) =>
    p.marketPlace !== 'YandexMarket' &&
    css`
      height: 656px;
      overflow-y: auto;
      &::-webkit-scrollbar {
        display: none;
      }
    `}
`;

const PreviewImageSelectWrapper = styled(FlexCol) <{
  marketPlace: MarketPlaceType;
}>`
  max-height: ${(p) =>
    (p.marketPlace === 'Wildberries' && '656px') ||
    (p.marketPlace === 'YandexMarket' && '600px') ||
    (p.marketPlace === 'Ozon' ? '500px' : '710px')};
`;

const More = styled.div`
  color: rgb(217, 217, 217);
`;

const ArrowBtn = styled.button.attrs((p: IArrowBtnProps) => ({
  type: 'button',
  children:
    p.marketPlace === 'Ozon' ? (
      <span className={`icon-arrow-${p.direction}`} />
    ) : (
      <span className={`icon-arrow-${p.direction}`} />
    ),
})) <IArrowBtnProps>`
  background: transparent;
  border: 0;
  font-size: 1.25rem;
  color: ${(p) => p.theme.black};
  text-align: center;
  margin-bottom: ${(p) => (p.direction === 'up' ? '0.75rem' : '0.625rem')};
  margin-top: ${(p) => (p.direction === 'down' ? '0.75rem' : '')};
  cursor: pointer;

  &:disabled {
    opacity: 0.2;

    &:hover {
      color: ${(p) => p.theme.black};
      cursor: default;
      background: rgba(255, 255, 255, 0.2);
    }
  }

  &:hover {
    color: ${(p) => p.theme.primary};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      &:hover {
        opacity: 0.2;
        color: ${(p) => p.theme.black};
        cursor: default;
      }
    `}
`;

const Border = styled.div<{ active: boolean; marketPlace: MarketPlaceType }>`
  cursor: pointer;
  position: absolute;
  width: 100%;
  height: 100%;
  border: ${(p) =>
    p.active
      ? (p.marketPlace === 'Wildberries' && '2px solid #BA2BA6') ||
      (p.marketPlace === 'YandexMarket' && '2px solid #F7CE46') ||
      (p.marketPlace === 'Ozon' && '2px solid #255AF6')
      : p.marketPlace === 'Ozon'
        ? 'none'
        : '2px solid #fff'};
  border-radius: ${(p) =>
    p.active
      ? (p.marketPlace === 'Wildberries' && '2px') ||
      (p.marketPlace === 'YandexMarket' && '10px') ||
      (p.marketPlace === 'Ozon' ? '3px' : '')
      : 'transparent'};
  border-bottom: ${(p) =>
    p.active ? p.marketPlace === 'Ozon' && '2px solid #255AF6' : '2px solid #fff'};
`;

const ProductPagePC: React.FC<{
  imageDetails: TImageDetail[];
  marketPlace: MarketPlaceType;
  imagesSrc: { [key: number]: string };
  loadingImages: boolean;
  canvasRatio: 'default' | 'square';
}> = ({ imageDetails, marketPlace, imagesSrc, loadingImages, canvasRatio }) => {
  const [isImageLoaded, setImageLoaded] = useState(false);
  const [selectedImage, setSelectedImage] = useState<number>(imageDetails[0].modifiedImageId);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [isScrollable, setIsScrollable] = useState(false);
  const [disabledBtn, setDisabledBtn] = useState({ up: true, down: true });
  const [isIconFormat, setIsIconFormat] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (wrapperRef?.current) {
      handleScroll();
    }
  }, [wrapperRef]);

  const handleScroll = () => {
    if (wrapperRef?.current) {
      setDisabledBtn({
        up: wrapperRef.current.scrollTop === 0,
        down:
          wrapperRef.current.scrollTop ===
          wrapperRef.current.scrollHeight - wrapperRef.current.offsetHeight,
      });
    }
  };

  useEffect(() => {
    if (wrapperRef.current) {
      const isContentScrollable = wrapperRef.current.scrollHeight > wrapperRef.current.clientHeight;
      setIsScrollable(isContentScrollable);
    }
  }, []);

  useEffect(() => {
    setIsIconFormat(canvasRatio === 'square');
  }, [canvasRatio]);

  const getImageStyle = (marketPlace: MarketPlaceType, height: number) => {
    const styles: Record<MarketPlaceType, { height: string; borderRadius: string }> = {
      Wildberries: {
        height: height == 450 ? '69px' : '92px',
        borderRadius: height == 450 ? '0px' : '2px',
      },
      YandexMarket: {
        height: canvasRatio === 'square' ? '88px' : '117px',
        borderRadius: '0',
      },
      Ozon: {
        height: '184px',
        borderRadius: '0',
      },
    };

    return styles[marketPlace] || {};
  };

  const imagesToDisplay =
    marketPlace === 'YandexMarket' && imageDetails.length > 5
      ? imageDetails.slice(0, 4)
      : imageDetails;

  const handleDecrement = () => {
    if (wrapperRef.current && wrapperRef.current.scrollTop > 0) {
      wrapperRef.current.scrollTop -= 100;
    }
  };

  const handleIncrement = () => {
    if (wrapperRef.current && wrapperRef.current.scrollTop < wrapperRef.current.scrollHeight) {
      wrapperRef.current.scrollTop += 100;
    }
  };

  const isSquaredWB = () => canvasRatio === 'square' && marketPlace === 'Wildberries';

  return (
    <Flex
      justifyContent="center"
      gap={marketPlace === 'Ozon' ? 28 : 20}
      style={{ margin: '48px 0' }}
      alignItems="flex-start"
    >
      <PreviewImageSelectWrapper gap={8} marketPlace={marketPlace} >
        {isScrollable && marketPlace !== 'YandexMarket' && !loadingImages && (
          <ArrowBtn
            direction="up"
            disabled={disabledBtn.up}
            onClick={handleDecrement}
            marketPlace={marketPlace}
          />
        )}
        <PreviewImageSelectContainer
          gap={
            (marketPlace === 'Ozon' && 12) ||
            (marketPlace === 'YandexMarket' && 20) ||
            (marketPlace === 'Wildberries' && 12) ||
            20
          }
          alignItems="center"
          ref={wrapperRef}
          onScroll={handleScroll}
          marketPlace={marketPlace}
        >
          {loadingImages ? (
            <FlexCol gap={12}>
              {Array.from({ length: 3 }).map((_, idx) => (
                <SkeletonLoader
                  key={idx}
                  width={
                    (marketPlace === 'Wildberries' && '68px') ||
                    (marketPlace === 'YandexMarket' && '88px') ||
                    (marketPlace === 'Ozon' && '138px') ||
                    '68px'
                  }
                  height={
                    (marketPlace === 'Wildberries' && '92px') ||
                    (marketPlace === 'YandexMarket' && '117px') ||
                    (marketPlace === 'Ozon' && '206px') ||
                    '92px'
                  }
                  borderRadius={
                    (marketPlace === 'YandexMarket' && '0') ||
                    (marketPlace === 'Ozon' && '0') ||
                    '8px'
                  }
                />
              ))}
            </FlexCol>
          ) : (
            <>{isSquaredWB()
              ? <>
                <PreviewImageSelect
                  marketPlace={marketPlace}
                  iconFormat={true}
                >
                  <div
                    style={{
                      opacity: 1,
                      width: '68px',
                      height: '93px',
                      borderRadius: '2px',
                      background: 'rgba(0, 0, 0, 0.1)',
                    }}
                  />
                </PreviewImageSelect>
              </>
              : <>{imagesToDisplay.map((image: TImageDetail) => (
                <PreviewImageSelect
                  key={image.modifiedImageId}
                  marketPlace={marketPlace}
                  iconFormat={canvasRatio === 'square'}
                  canvasRatio={canvasRatio}
                >
                  <img
                    style={{
                      opacity: isImageLoaded ? 1 : 0,
                      width:
                        marketPlace === 'Ozon'
                          ? canvasRatio === 'square'
                            ? '48px'
                            : '36px'
                          : marketPlace === 'Wildberries'
                            ? '68px'
                            : marketPlace === 'YandexMarket'
                              ? '88px'
                              : '100%',
                      ...getImageStyle(marketPlace, image.details.height),
                      height:
                        marketPlace === 'Ozon' && canvasRatio === 'square'
                          ? '48px'
                          : marketPlace === 'Wildberries' && image.details.height !== 450
                            ? '93px'
                            : marketPlace === 'YandexMarket'
                              ? canvasRatio === 'square'
                                ? '88px'
                                : '117px'
                              : 'auto',
                      overflow: 'hidden',
                      objectFit: 'cover',
                      objectPosition: '0 0'
                    }}
                    src={imagesSrc[image.modifiedImageId]}
                  />
                  <Border
                    marketPlace={marketPlace}
                    active={selectedImage === image.modifiedImageId}
                    onClick={() => {
                      setSelectedImage(image.modifiedImageId);
                      setIsIconFormat(canvasRatio === 'square');
                    }}
                  />
                </PreviewImageSelect>
              ))}</>}</>


          )}

          {marketPlace === 'YandexMarket' && imageDetails.length > 5 && !loadingImages && (
            <More>Ещё {imageDetails.length - 4}</More>
          )}
        </PreviewImageSelectContainer>
        {isScrollable && marketPlace !== 'YandexMarket' && !loadingImages && (
          <ArrowBtn
            disabled={disabledBtn.down}
            direction="down"
            onClick={handleIncrement}
            marketPlace={marketPlace}
          />
        )}
      </PreviewImageSelectWrapper>
      <Flex justifyContent="center" alignItems="center">
        <PreviewImageBlock
          marketPlace={marketPlace}
          isIconFormat={isIconFormat}
          canvasRatio={canvasRatio}
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          {loadingImages ? (
            <div
              style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <CircleLoader2 shineColor="#6620C7" />
            </div>
          ) : (
            <>
              {!isSquaredWB()
                ? <>
                  <img
                    style={{
                      borderRadius: marketPlace === 'YandexMarket' ? 0 : '8px',
                      width: canvasRatio === 'square' ? 'auto' : '100%',
                      height: '100%',
                      opacity: isImageLoaded ? 1 : 0,
                      objectFit: canvasRatio !== 'square' ? 'contain' : 'cover',
                      objectPosition: canvasRatio !== 'square' ? 'center' : '0 0',
                      aspectRatio: canvasRatio === 'square' ? '1 / 1' : 'auto',
                    }}
                    src={imagesSrc[selectedImage]}
                    onLoad={() => setImageLoaded(true)}
                  />
                  <SimilarButton
                    marketPlace={marketPlace}
                    data-tooltip={t(`preview-image.similar`) as string}
                    data-tooltip-position="left"
                  >
                    {marketPlace === 'Wildberries' && (
                      <img src={WBPreviewImage} width={111} height={32} />
                    )}
                    {marketPlace === 'YandexMarket' && (
                      <img src={YMPreviewImage} width={126} height={46} />
                    )}
                    {marketPlace === 'Ozon' && <img src={OzonPreviewImage} width={40} height={40} />}
                  </SimilarButton>
                  {marketPlace === 'Ozon' && (
                    <LabelsFromOzon top={16} right={-10}>
                      <LabelFromOzon>Тут</LabelFromOzon>
                      <LabelFromOzon>Могут быть лейблы</LabelFromOzon>
                      <LabelFromOzon>от Ozon</LabelFromOzon>
                    </LabelsFromOzon>
                  )}
                </>
                : <NoPreview />
              }
            </>
          )}
        </PreviewImageBlock>
      </Flex>
    </Flex>
  );
};

export default ProductPagePC;

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IPasswords } from '../../interfaces/auth.interface';
import { Button } from '../../components/button.component';
import { Headline } from '../../components/headline.component';
import { Text } from '../../components/text.component';
import logo from '../../assets/images/logo.svg';
import Components, { WhiteBox } from './change-password.style';
import Input from '../../components/input.component';
import { resetPassword } from '../../requests/auth.request';
import { useLocation, useNavigate } from 'react-router-dom';

export const ChangePassword = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [error, setError] = useState<boolean>(false);
  const [form, setForm] = useState<IPasswords>({
    password: '',
    repeatPassword: '',
  });
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get('token') || '';
  const email = searchParams.get('email') || '';

  const handleChange = (key: keyof IPasswords) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setForm((prevState) => ({ ...prevState, [key]: value }));
  };

  const handleSubmit = () => {
    if (form.repeatPassword !== form.password) {
      setError(true);
      return;
    }
    resetPassword({ email: email, token: token, password: form.password }).then(() =>
      navigate('/login'),
    );
  };

  return (
    <Components.Wrapper>
      <img
        src={logo}
        alt={'mark it'}
        height={'62.04px'}
        width={'98.18px'}
        style={{ position: 'absolute', left: '10px' }}
      />
      <WhiteBox>
        <Headline>{t('recover.newPassword')}</Headline>
        <Input
          label="Новый пароль"
          type="password"
          value={form.password}
          onChange={handleChange('password')}
        />
        <Input
          label="Повторите пароль"
          type="password"
          value={form.repeatPassword}
          onChange={handleChange('repeatPassword')}
        />
        {error && (
          <Text color="gray" small>
            {t('recover.incorrectPassword')}
          </Text>
        )}
        <Button type="submit" onClick={handleSubmit}>
          {t('recover.confirm')}
        </Button>
      </WhiteBox>
    </Components.Wrapper>
  );
};

import React, { ChangeEvent, Dispatch, useState } from 'react';
import { Button } from './button.component';
import styled from 'styled-components';
import { Flex, FlexCol } from './flexbox.component';
import { TCreateInfoGraphic, TUpdateInfoGraphic } from '../interfaces/infographics.interface';
import Input from './input.component';

const InputWithButton = styled(Flex)`
  width: 100%;
  gap: 10px;
  input {
    flex: 1;
  }
`;

const TagInput: React.FC<{
  setInfoGraphic?: Dispatch<React.SetStateAction<TCreateInfoGraphic>>;
  setInfoUpdateGraphic?: Dispatch<React.SetStateAction<TUpdateInfoGraphic>>;
}> = ({ setInfoGraphic, setInfoUpdateGraphic }) => {
  const [inputValue, setInputValue] = useState('');

  const handleAddTag = () => {
    setInfoGraphic
      ? setInfoGraphic((prev) => ({ ...prev, keyWords: [...prev.keyWords, inputValue] }))
      : setInfoUpdateGraphic &&
        setInfoUpdateGraphic((prev) => ({
          ...prev,
          keyWords: [...(prev.keyWords || []), inputValue],
        }));
    setInputValue('');
  };

  return (
    <FlexCol>
      <InputWithButton>
        <Input
          label="Ключевое слово"
          value={inputValue}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setInputValue(e.target.value);
          }}
          maxlength={25}
          showButtons={false}
          mt
        />
        <Button
          icon="plus"
          style={{ width: '50px', height: '50px' }}
          onClick={handleAddTag}
          disabled={!inputValue}
          iconPosition="center"
        ></Button>
      </InputWithButton>
    </FlexCol>
  );
};

export default TagInput;

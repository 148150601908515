import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 1.125rem 1.25rem 0.75rem;
  gap: 20px;
  position: sticky;
  top: 0;
  z-index: 1002;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.06), rgba(0, 0, 0, 0.06)),
    rgba(255, 255, 255, 0.95);
`;

export const HeaderItem = styled.div`
  display: flex;
  position: relative;
  & > img {
    position: absolute;
    top: -0.4375rem;
    left: 0;
    height: 3.75rem;
    filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.25));
  }
`;

export const AdminBody = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const AdminWrapper = styled.div`
  width: 100%;
  height: 80vh;
`;

import React from 'react';
import FabricTypes from 'fabric/fabric-impl';
import { useCanvasHistory } from '../../contexts/canvas-history.context';
import { Properties, Property } from './object-toolbar.style';
import { IBasicEditorProps } from '../../interfaces/editor.interface';
import { changeTextLineHeight } from '../../utils/editor.utils';

const lineHeightOptions = [
  { key: 'line-space-1.0', value: 1, label: '1' },
  { key: 'line-space-1.15', value: 1.15, label: '1,15' },
  { key: 'line-space-1.5', value: 1.5, label: '1,5' },
  { key: 'line-space-2.0', value: 2, label: '2' },
];

const LineHeightTool = ({ editor, activeObject }: IBasicEditorProps) => {
  const { historySaveAction } = useCanvasHistory();

  const handlePropertyClick = (value: number) => () => {
    if (activeObject && editor.canvas) {
      changeTextLineHeight(editor.canvas, activeObject, value).then(() => {
        historySaveAction(editor);
      });
    }
  };

  return (
    <Properties>
      {lineHeightOptions.map((option) => (
        <Property
          key={option.key}
          active={(activeObject as FabricTypes.Textbox)?.lineHeight === option.value}
          onClick={handlePropertyClick(option.value)}
        >
          {option.label}
        </Property>
      ))}
    </Properties>
  );
};

export default LineHeightTool;

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FavOrPrivateSort, GlobalSort } from '../../../constants/infographic.constants';
import useDebounce from '../../../hooks/use-debounce.hooks';
import useSortSearch from '../../../hooks/use-sort-search.hooks';
import { IBasicEditorProps } from '../../../interfaces/editor.interface';
import {
  MyInfoGraphicSettings,
  TAllInfoGraphicLists,
  TGetListInfoGraphic,
  TGetListResponse,
  sortingTypeFavOrPrivate,
  sortingTypeGlobal,
} from '../../../interfaces/infographics.interface';
import { InfoGraphicRequest } from '../../../requests/infographic.request';
import { Modal } from '../../modal.component';
import SkeletonLoader from '../../skeleton-loader.component';
import InfoGraphicUpdateModal from './info-graphic-update-modal';
import { InfographicSettings } from './infographic-settings';
import { fabric } from 'fabric';
import { useModal } from '../../../contexts/modal.context';
import { useTranslation } from 'react-i18next';
import addInfographic from '../../../assets/images/add-info-graphic-instruction.png';
import useInfiniteScroll from '../../../hooks/use-infinite-scroll';
import InfoGraphicRemoveModal from './info-graphic-remove-modal';
import PopoverTab from '../popover-tab';
import FabricTypes from 'fabric/fabric-impl';

const Border = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 10px;
  z-index: 1;
  // border: 1px solid ${(p) => p.theme.lightGray};
`;

const ListContainer = styled.div`
  height: fit-content;
  max-height: 500px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
  gap: 17px;
  margin-top: 20px;
`;

const HiddenSelect = styled.div`
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  position: absolute;
  top: 10px;
  right: 10px;
  transition: opacity 0.5s ease;
`;

const ListItem = styled.div`
  width: 160px;
  height: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
  &:hover ${HiddenSelect} {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
  }

  &:hover ${Border} {
    border: 2px solid ${(p) => p.theme.primary};
  }
`;

const StyledImage = styled.img`
  max-height: 100%;
  max-width: 100%;
`;

const InfographicPopoverWrapper = styled.div`
  width: 352px;
  height: fit-content;
`;

const Favourite = styled.div`
  font-size: 20px;
  font-weight: 400;
  background-color: #e6e6e6;
  padding: 5px;
  border-radius: 8px;
  cursor: pointer;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
`;

const ErrorText = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: #000;
`;

const StyledOl = styled.ol`
  font-size: 16px;
  font-weight: 500;
  color: #000;
  margin: 0;
  padding-left: 20px;
`;

const StyledLi = styled.li`
  font-size: 16px;
  font-weight: 500;
  color: #000;
  margin: 0;
  padding: 0;
`;

const templateType = 'INFOGRAPHICS';

const InfographicPopover: React.FC<IBasicEditorProps> = ({ editor, refreshProjectCard }) => {
  const modal = useModal();
  const { t } = useTranslation();
  const [tuneModalOpen, setTuneModalOpen] = useState<{ open: boolean; id: number }>({
    open: false,
    id: 0,
  });
  const [infographicLists, setInfographicLists] = useState<TAllInfoGraphicLists>({
    privateList: [],
    favoriteList: [],
    globalList: [],
  });
  const privateSort = useSortSearch(FavOrPrivateSort[0], 'DESC');
  const favoriteSort = useSortSearch(FavOrPrivateSort[0]);
  const publicSort = useSortSearch(GlobalSort[0]);
  const debouncedSearchPublic = useDebounce(publicSort.search, 500);
  const debouncedSearchFavourite = useDebounce(favoriteSort.search, 500);
  const debouncedSearchPrivate = useDebounce(privateSort.search, 500);
  const [privateListLoading, setPrivateListLoading] = useState<boolean>(false);
  const [globalListLoading, setGlobalListLoading] = useState<boolean>(false);
  const [favoriteListLoading, setFavoriteListLoading] = useState<boolean>(false);
  const [isRemoving, setIsRemoving] = useState<boolean>(false);
  const [isFavouriteHover, setIsFavoriteHover] = useState<boolean>(false);
  const [didMount, setDidMount] = useState<boolean>(false);

  const { privateList, favoriteList, globalList } = infographicLists;

  const fetchPrivateList = ({ keyWord, orderType, page, sortingType }: TGetListInfoGraphic) => {
    setPrivateListLoading(true);
    InfoGraphicRequest.getPrivateList({ keyWord, orderType, page, sortingType, templateType })
      .then((res) => {
        privateSort.setHasMore(res.list.length > 0);
        setInfographicLists((prev) => ({
          ...prev,
          privateList: [...prev.privateList, ...res.list],
        }));
      })
      .finally(() => setPrivateListLoading(false));
  };

  const fetchGlobalList = ({ page, keyWord, orderType, sortingType }: TGetListInfoGraphic) => {
    setGlobalListLoading(true);
    InfoGraphicRequest.getGlobalList({ keyWord, orderType, page, sortingType, templateType })
      .then((res) => {
        publicSort.setHasMore(!(res.list.length < 24));
        setInfographicLists((prev) => ({
          ...prev,
          globalList: [...prev.globalList, ...res.list],
        }));
      })
      .finally(() => setGlobalListLoading(false));
  };

  const fetchFavoriteList = ({ keyWord, orderType, page, sortingType }: TGetListInfoGraphic) => {
    setFavoriteListLoading(true);
    InfoGraphicRequest.getFavoriteInfoGraphicList({
      keyWord,
      orderType,
      page,
      sortingType,
      templateType,
    })
      .then((res) => {
        favoriteSort.setHasMore(res.list.length > 0);
        setInfographicLists((prev) => ({
          ...prev,
          favoriteList: [...prev.favoriteList, ...res.list],
        }));
      })
      .finally(() => setFavoriteListLoading(false));
  };

  const getPrivateList = () => {
    fetchPrivateList({
      keyWord: privateSort.search,
      orderType: privateSort.sortAsc,
      page: privateSort.page,
      sortingType: privateSort.selectedOption.value as sortingTypeFavOrPrivate,
      templateType,
    });
  };

  const getFavouriteList = () => {
    fetchFavoriteList({
      keyWord: favoriteSort.search,
      orderType: favoriteSort.sortAsc,
      page: favoriteSort.page,
      sortingType: favoriteSort.selectedOption.value as sortingTypeFavOrPrivate,
      templateType,
    });
  };

  const getGlobalList = () => {
    fetchGlobalList({
      keyWord: publicSort.search,
      orderType: publicSort.sortAsc,
      page: publicSort.page,
      sortingType: publicSort.selectedOption.value as sortingTypeGlobal,
      templateType,
    });
  };

  useEffect(() => {
    if (favoriteSort.page !== 0) getFavouriteList();
  }, [favoriteSort.page]);

  useEffect(() => {
    if (publicSort.page !== 0) getGlobalList();
  }, [publicSort.page]);

  useEffect(() => {
    if (privateSort.page !== 0) getPrivateList();
  }, [privateSort.page]);

  const refetchPublicData = () => {
    publicSort.setPage(0);
    setInfographicLists((prev) => ({
      ...prev,
      globalList: [],
    }));
    fetchGlobalList({
      keyWord: publicSort.search,
      orderType: publicSort.sortAsc,
      page: 0,
      sortingType: publicSort.selectedOption.value as sortingTypeGlobal,
      templateType,
    });
  };

  const refetchPrivateData = () => {
    privateSort.setPage(0);
    setInfographicLists((prev) => ({
      ...prev,
      privateList: [],
    }));
    fetchPrivateList({
      keyWord: privateSort.search,
      orderType: privateSort.sortAsc,
      page: 0,
      sortingType: privateSort.selectedOption.value as sortingTypeFavOrPrivate,
      templateType,
    });
  };

  const refetchFavoriteData = () => {
    favoriteSort.setPage(0);
    setInfographicLists((prev) => ({
      ...prev,
      favoriteList: [],
    }));
    fetchFavoriteList({
      keyWord: favoriteSort.search,
      orderType: favoriteSort.sortAsc,
      page: 0,
      sortingType: favoriteSort.selectedOption.value as sortingTypeFavOrPrivate,
      templateType,
    });
  };

  const refetchAllData = () => {
    refetchPublicData();
    refetchPrivateData();
    refetchFavoriteData();
  };

  useEffect(() => setDidMount(true), []);

  useEffect(() => {
    didMount &&
      publicSort.setSortAsc(publicSort.selectedOption.title === 'По новизне' ? 'DESC' : 'ASC');
  }, [publicSort.selectedOption]);

  useEffect(() => {
    refetchPublicData();
  }, [publicSort.sortAsc, debouncedSearchPublic]);

  useEffect(() => {
    refetchPrivateData();
  }, [privateSort.selectedOption, privateSort.sortAsc, debouncedSearchPrivate]);

  useEffect(() => {
    refetchFavoriteData();
  }, [favoriteSort.selectedOption, favoriteSort.sortAsc, debouncedSearchFavourite]);

  const privateInfiniteScroll = useInfiniteScroll(
    privateListLoading,
    privateSort.hasMore,
    privateSort.setPage,
  );

  const globalInfiniteScroll = useInfiniteScroll(
    globalListLoading,
    publicSort.hasMore,
    publicSort.setPage,
  );

  const favouriteInfiniteScroll = useInfiniteScroll(
    favoriteListLoading,
    favoriteSort.hasMore,
    favoriteSort.setPage,
  );

  const infographicTune = (id: number) => {
    setTuneModalOpen({ open: true, id });
  };

  const removeInfoGraphic = (id: number) => {
    const content = <InfoGraphicRemoveModal isRemoving={isRemoving} />;
    modal.open(content, {
      width: 460,
      handleOk: () => {
        setIsRemoving(true);
        InfoGraphicRequest.deleteInfoGraphic(id)
          .then(() => {
            fetchPrivateList({
              keyWord: privateSort.search,
              orderType: privateSort.sortAsc,
              page: privateSort.page,
              sortingType: privateSort.selectedOption.value as sortingTypeFavOrPrivate,
              templateType,
            });
          })
          .finally(() => {
            modal.close();
            setIsRemoving(false);
          });
      },
      handleCancel: () => modal.close(),
    });
  };

  const addInfoGraphicToCanvas = (id: number) => {
    InfoGraphicRequest.getInfoGraphic(id).then((res) => {
      fabric.util.enlivenObjects(
        res.details,
        (enlivenedObjects: FabricTypes.Object[]) => {
          const group =
            enlivenedObjects.length > 1 ? new fabric.Group(enlivenedObjects) : enlivenedObjects[0];

          const infographicId = Math.random().toString(16).slice(2);
          group.set({
            name: `infographic_${infographicId}`,
          });
          editor.canvas.add(group);
          editor.canvas.renderAll();
        },
        '',
      );
      refreshProjectCard && refreshProjectCard();
    });
  };

  const refetchFavouriteAddingData = () => {
    refetchPublicData();
    refetchFavoriteData();
  };

  const addToFavourite = (id: number) =>
    InfoGraphicRequest.addFavoriteInfoGraphic(id).then(() => refetchFavouriteAddingData());

  const removeFavourite = (id: number) =>
    InfoGraphicRequest.removeFavoriteInfoGraphic(id).then(() => refetchFavouriteAddingData());

  const ListLoader = Array.from({ length: 6 }).map((_, index) => (
    <SkeletonLoader key={index} width="160px" height="160px" />
  ));

  const myInfoGraphicSettingsOptions: MyInfoGraphicSettings[] = [
    {
      name: 'Настроить',
      function: infographicTune,
    },
    {
      name: 'Удалить',
      function: removeInfoGraphic,
    },
  ];

  const renderList = (list: TGetListResponse[]) => (
    <ListContainer
      onScroll={
        list === privateList
          ? privateInfiniteScroll.handleScroll
          : list === globalList
          ? globalInfiniteScroll.handleScroll
          : list === favoriteList
          ? favouriteInfiniteScroll.handleScroll
          : undefined
      }
    >
      {list.length > 0 ? (
        list.map((item) => (
          <ListItem key={item.id}>
            {list === privateList && (
              <HiddenSelect>
                <InfographicSettings settingOptions={myInfoGraphicSettingsOptions} id={item.id} />
              </HiddenSelect>
            )}
            {list === globalList && (
              <HiddenSelect>
                <Favourite
                  onClick={() =>
                    !item.isFavourite ? addToFavourite(item.id) : removeFavourite(item.id)
                  }
                  onMouseEnter={() => setIsFavoriteHover(true)}
                  onMouseLeave={() => setIsFavoriteHover(false)}
                  className={
                    isFavouriteHover
                      ? 'icon-heart-filled'
                      : item.isFavourite
                      ? 'icon-heart-filled'
                      : 'icon-heart'
                  }
                ></Favourite>
              </HiddenSelect>
            )}
            {list === favoriteList && (
              <HiddenSelect>
                <Favourite
                  onClick={() => removeFavourite(item.id)}
                  onMouseEnter={() => setIsFavoriteHover(true)}
                  onMouseLeave={() => setIsFavoriteHover(false)}
                  className={
                    isFavouriteHover
                      ? 'icon-heart-filled'
                      : item.isFavourite
                      ? 'icon-heart-filled'
                      : 'icon-heart'
                  }
                ></Favourite>
              </HiddenSelect>
            )}
            <div
              onClick={() => addInfoGraphicToCanvas(item.id)}
              style={{
                width: '160px',
                height: '160px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
              }}
            >
              <StyledImage
                src={`/api/project/load/image/png?imageId=${item.imageId}&imageSizeType=NORMAL`}
              />
            </div>
            <Border style={{ pointerEvents: 'none' }} />
          </ListItem>
        ))
      ) : (
        <>
          {list === privateList && !privateListLoading && (
            <>
              <div>
                <ErrorText>{t('editor.infographic.privateListError.text')}</ErrorText>
                <StyledOl>
                  <StyledLi>{t('editor.infographic.privateListError.instruction.1')}</StyledLi>
                  <StyledLi>{t('editor.infographic.privateListError.instruction.2')}</StyledLi>
                  <StyledLi>{t('editor.infographic.privateListError.instruction.3')}</StyledLi>
                </StyledOl>
              </div>
              <img src={addInfographic} width={'100%'} height={'100%'} />
            </>
          )}
          {list === globalList && !globalListLoading && (
            <ErrorText>
              По вашему запросу ничего не найдено. Повторите попытку, набрав другие слова.
            </ErrorText>
          )}
          {list === favoriteList && !favoriteListLoading && (
            <ErrorText>{t('editor.infographic.favListError')}</ErrorText>
          )}
        </>
      )}
      {list === privateList && privateListLoading && <>{ListLoader}</>}
      {list === globalList && globalListLoading && <>{ListLoader}</>}
      {list === favoriteList && favoriteListLoading && <>{ListLoader}</>}
    </ListContainer>
  );

  const PrivateInfographics = renderList(privateList);
  const FavoriteInfographics = renderList(favoriteList);
  const GlobalInfographics = renderList(globalList);

  return (
    <InfographicPopoverWrapper>
      {tuneModalOpen.open && (
        <Modal
          width="540px"
          height="fit-content"
          style={{ minHeight: '419px' }}
          onClose={() => setTuneModalOpen({ open: false, id: 0 })}
        >
          <InfoGraphicUpdateModal
            id={tuneModalOpen.id}
            onClose={() => setTuneModalOpen({ open: false, id: 0 })}
            refetchAllData={refetchAllData}
          />
        </Modal>
      )}
      <PopoverTab
        width="fit-content"
        searchPlaceholder="Поиск инфографики"
        tabs={[
          {
            title: 'Все шаблоны',
            sort: {
              selection: GlobalSort,
              selectedOption: publicSort.selectedOption,
              setSelectedOption: publicSort.setSelectedOption,
              sortOrder: publicSort.sortAsc,
              setSortOrder: publicSort.setSortAsc,
              search: publicSort.search,
              setSearch: publicSort.setSearch,
            },
            content: GlobalInfographics,
            loading: globalListLoading,
          },
          {
            title: 'Избранное',
            sort: {
              selection: FavOrPrivateSort,
              selectedOption: favoriteSort.selectedOption,
              setSelectedOption: favoriteSort.setSelectedOption,
              sortOrder: favoriteSort.sortAsc,
              setSortOrder: favoriteSort.setSortAsc,
              search: favoriteSort.search,
              setSearch: favoriteSort.setSearch,
            },
            content: FavoriteInfographics,
            loading: favoriteListLoading,
          },
          {
            title: 'Моя инфографика',
            sort: {
              selection: FavOrPrivateSort,
              selectedOption: privateSort.selectedOption,
              setSelectedOption: privateSort.setSelectedOption,
              sortOrder: privateSort.sortAsc,
              setSortOrder: privateSort.setSortAsc,
              search: privateSort.search,
              setSearch: privateSort.setSearch,
            },
            content: PrivateInfographics,
            loading: privateListLoading,
          },
        ]}
      />
    </InfographicPopoverWrapper>
  );
};

export default InfographicPopover;

import React, { useEffect, useState } from 'react';
import { Modal } from '../../../components/modal.component';
import { FolderModalContent, MoveToFolderModalContent } from '../../folders/folders.style';
import { Text } from '../../../components/text.component';
import Input from '../../../components/input.component';
import {
  ButtonsWrap,
  ChooseFolder,
  FoldersRow,
  NewCardButton,
  PlusIcon,
} from '../../files/files.style';
import { Button } from '../../../components/button.component';
import { ICreateFolderRequest, IProject } from '../../../interfaces/projects.interface';
import FolderListLoader from '../../../components/loaders/folder-list.component';
import { Flex } from '../../../components/flexbox.component';
import SkeletonLoader from '../../../components/skeleton-loader.component';
import { createFolder, getFolders, removeFromFolder } from '../../../requests/folder.requests';
import useSortSearch from '../../../hooks/use-sort-search.hooks';
import { FOLDER_SORT } from '../../../constants/shared.constants';
import plus from '../../../assets/icons/plus.svg';
import { useNotification } from '../../../contexts/notification.context';
import { useNavigate } from 'react-router-dom';
import { moveToFolder, moveToFolderProjects } from '../../../requests/project.requests';
import ModalPagination from '../../../components/pagination/modal.pagination';
import ModalHeader from '../../../components/modal/modal.header';

type MoveToFolderModalProps = {
  isOpen: boolean;
  closeModal: () => void;
  projects: IProject[] | null;
  fetchProjects: () => void;
  chosenProjectId: number | undefined;
  chosenFolderId: number | undefined;
  setChosenFolderId: (id: number) => void;
  setHighlightedProjects: React.Dispatch<React.SetStateAction<number[]>>;
  highlight: boolean;
  highlightedProjects: number[];
  setHighlight: React.Dispatch<React.SetStateAction<boolean>>;
};

const MoveToFolderModal: React.FC<MoveToFolderModalProps> = ({
  isOpen,
  closeModal,
  projects,
  fetchProjects,
  chosenProjectId,
  chosenFolderId,
  setChosenFolderId,
  setHighlightedProjects,
  highlight,
  highlightedProjects,
  setHighlight,
}) => {
  const { setNotification, closeNotification } = useNotification();
  const navigate = useNavigate();

  const [folderModal, setFolderModal] = useState<boolean>(false);
  const [createFolderName, setCreateFolderName] = useState<string>('');
  const [folderInfo, setFolderInfo] = useState<ICreateFolderRequest>();
  const [folderList, setFolderList] = useState<ICreateFolderRequest[]>([]);
  const [loadingFolderList, setLoadingFolderList] = useState<boolean>(false);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [moveLoading, setMoveLoading] = useState<boolean>(false);
  const [loadingCreateFolder, setLoadingCreateFolder] = useState<boolean>(false);

  const folderSort = useSortSearch(FOLDER_SORT[1]);

  const handleClose = () => {
    closeModal();
    setFolderModal(false);
    setCreateFolderName('');
    folderSort.setPage(0);
  };

  const handleCreateFolder = () => {
    setLoadingCreateFolder(true);
    createFolder({ folderName: createFolderName })
      .then((result) => {
        if (result.data) {
          handleMoveToFolderSubmit(result.data);
          setFolderInfo(result.data);
        }
      })
      .finally(() => {
        setLoadingCreateFolder(false);
        handleClose();
      });
  };

  const handleChooseFolder = (folder: ICreateFolderRequest) => {
    setFolderInfo(folder);
    folder.id && setChosenFolderId(folder.id);
  };

  const fetchFolders = () => {
    setLoadingFolderList(true);
    getFolders(
      undefined,
      folderSort.selectedOption.value,
      folderSort.sortAsc,
      folderSort.page,
      5,
    ).then((res) => {
      setTotalPages(res.totalPages);
      folderSort.setHasMore(!(res.list.length < 24));
      setFolderList(res.list);
      setLoadingFolderList(false);
    });
  };

  const removeProjectFromFolder = () => {
    const project = (projects || []).find((project) => project.id === chosenProjectId);
    if (!project) {
      return;
    }

    const removeFolderItemRequest = {
      folderId: project.folderId,
      folderItemId: project.id,
      folderItemType: 'PROJECT',
    };

    project.folderId && removeFromFolder(removeFolderItemRequest).then(fetchProjects);
    handleClose();
  };

  const handleMoveToFolderSubmit = (folder: ICreateFolderRequest) => {
    setMoveLoading(true);
    const commonActions = (error?: { response: { data: { msg: string } } }) => {
      error && setNotification({ text: error.response.data.msg, errorNotification: true });
      setNotification({
        text: `Вы поместили проект${highlightedProjects.length > 1 ? 'ы' : ''}
         в папку`,
        handleFunction: () => {
          navigate('/folders/item', {
            state: { folderInfo: folder },
          });
          closeNotification();
        },
        buttonText: 'Перейти в папку',
        handleClose: () => setChosenFolderId(0),
        icon: 'folder',
      });
      closeModal();
      setHighlightedProjects([]);
      setHighlight(false);
    };

    const moveAction = highlight
      ? moveToFolderProjects(
          highlightedProjects,
          (folder.id as number) || (chosenFolderId as number),
        )
      : moveToFolder(chosenProjectId, folder.id || chosenFolderId);

    moveAction
      .then(() => {
        commonActions();
        fetchProjects();
        fetchFolders();
      })
      .catch((err: { response: { data: { msg: string } } }) => commonActions(err))
      .finally(() => {
        setMoveLoading(false);
      });
  };

  useEffect(() => {
    fetchFolders();
  }, [folderSort.page, folderSort.selectedOption, folderSort.sortAsc]);

  if (!isOpen) return null;

  return (
    <Modal onClose={handleClose} width={folderModal ? '26%' : '49.25rem'}>
      {folderModal ? (
        <FolderModalContent>
          <Text fontSize="23px" strong>
            Новая папка
          </Text>
          <Input
            label="Название папки"
            value={createFolderName}
            onChange={(e) => setCreateFolderName(e.target.value)}
            showButtons={false}
            maxlength={100}
          />
          <ButtonsWrap>
            <Button btnStyle={'cancel'} style={{ padding: '0' }} onClick={handleClose}>
              Отмена
            </Button>
            <Button
              isLoading={loadingCreateFolder}
              disabled={!createFolderName}
              icon={'folder-plus'}
              onClick={handleCreateFolder}
            >
              Создать
            </Button>
          </ButtonsWrap>
        </FolderModalContent>
      ) : (
        <MoveToFolderModalContent>
          <ModalHeader sort={folderSort} type={'project'} isLoading={loadingFolderList} />
          <FoldersRow gutter={0}>
            {folderList.length === 0 ? (
              <>
                <NewCardButton
                  onClick={handleClose}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                  }}
                >
                  <Text fontSize="1.4375rem" color="gray" strong>
                    Без папки
                  </Text>
                  <Text fontSize="0.875rem" color="gray" strong>
                    Будет доступен на странице «Все файлы»
                  </Text>
                </NewCardButton>
                {folderList.length === 0 && (
                  <NewCardButton onClick={() => setFolderModal(true)}>
                    <Text fontSize="1.4375rem" color="gray" strong>
                      Создать папку
                    </Text>
                    <PlusIcon>
                      <img src={plus} alt="plus" />
                    </PlusIcon>
                  </NewCardButton>
                )}
                {folderList.map((folder: ICreateFolderRequest) => (
                  <ChooseFolder
                    key={folder.id}
                    onClick={() => handleChooseFolder(folder)}
                    active={folder.id === chosenFolderId}
                  >
                    <Text fontSize="1.4375rem" strong truncate breakByWord>
                      {folder.folderName}
                    </Text>
                    <Text fontSize="0.875rem" color="gray">
                      {`${folder.itemsCount} ${folder.itemsCount === 0 ? 'элементов' : 'элемента'}`}
                    </Text>
                  </ChooseFolder>
                ))}
                {loadingFolderList && <FolderListLoader withoutFolder count={2} />}
              </>
            ) : loadingFolderList ? (
              <FolderListLoader withoutFolder count={2} />
            ) : (
              <>
                <NewCardButton onClick={removeProjectFromFolder}>
                  <Text fontSize="1.4375rem" color="gray" strong>
                    Без папки
                  </Text>
                  <Text fontSize="0.875rem" color="gray" strong>
                    Будет доступен на странице «Мои проекты»
                  </Text>
                </NewCardButton>
                {folderList.map((folder: ICreateFolderRequest) => (
                  <ChooseFolder
                    key={folder.id}
                    onClick={() => handleChooseFolder(folder)}
                    active={folder.id === chosenFolderId}
                  >
                    <Text fontSize="1.4375rem" strong truncate breakByWord>
                      {folder.folderName}
                    </Text>
                    <Text fontSize="0.875rem" color="gray">
                      {`${folder.itemsCount} ${folder.itemsCount === 0 ? 'элементов' : 'элемента'}`}
                    </Text>
                  </ChooseFolder>
                ))}
              </>
            )}
          </FoldersRow>
          {loadingFolderList ? (
            <Flex justifyContent="space-between" alignItems="center">
              <SkeletonLoader width="123px" height="22px" />
              <Flex gap={20} alignItems="center">
                <SkeletonLoader width="78px" height="22px" />
                <SkeletonLoader
                  width="172px"
                  height="50px"
                  borderRadius="16px"
                  baseColor="#00000033"
                />
              </Flex>
            </Flex>
          ) : (
            folderList.length !== 0 && (
              <div>
                <ModalPagination
                  page={folderSort.page}
                  setPage={folderSort.setPage}
                  totalCount={totalPages}
                />
                <ButtonsWrap>
                  <Button
                    btnStyle={'cancel'}
                    onClick={() => {
                      setFolderModal(true);
                    }}
                    style={{ padding: 0 }}
                    icon={'folder-plus'}
                  >
                    Новая папка
                  </Button>
                  <div style={{ display: 'flex' }}>
                    <Button btnStyle={'cancel'} onClick={handleClose}>
                      Отменить
                    </Button>
                    <Button
                      icon={'folder'}
                      onClick={() => {
                        folderInfo && handleMoveToFolderSubmit(folderInfo);
                      }}
                      disabled={!chosenFolderId || chosenFolderId < 0}
                      isLoading={moveLoading}
                    >
                      Поместить
                    </Button>
                  </div>
                </ButtonsWrap>
              </div>
            )
          )}
        </MoveToFolderModalContent>
      )}
    </Modal>
  );
};

export default MoveToFolderModal;

import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import FabricTypes from 'fabric/fabric-impl';
import { useTranslation } from 'react-i18next';

import {
  IBasicEditorProps,
  CanvasRatio,
  PropertiesModalType,
} from '../interfaces/editor.interface';
import { useModal } from '../contexts/modal.context';
import { Flex } from './flexbox.component';
import { ALIGN_OPTIONS } from '../constants';
import { ToolbarRow, ToolbarBtn } from './toolbar/toolbar.style';
import Popover from './popover.component';
import { handleChangeImageInShapeAlign, handleChangeObjectAlign } from '../utils/editor.utils';
import { CropImage } from './crop-image.component';
import { RotateTool } from './object-toolbar/rotate-tool.component';
import ImagePropertiesModal from './image-properties-modal.component';

const Wrapper = styled(Flex).attrs({ gap: 6 })`
  position: relative;
  & > div > div > ${ToolbarBtn}, & > ${ToolbarBtn} {
    border-radius: 1rem;
  }
`;

interface IProps extends IBasicEditorProps {
  imageId: number;
  canvasRatio?: CanvasRatio;
  handleSetBackgroundImage: (image: FabricTypes.Image, id: number) => void;
  setDisablePopoverClose?: Dispatch<SetStateAction<boolean>>;
  isBackground?: boolean;
  setDefaultBg?: (_: FabricTypes.Image) => void;
  setSquareBg?: (_: FabricTypes.Image) => void;
  hiddenImage?: string;
  type?: 'shape' | 'bg' | 'image';
}

export const ImageProperties = ({
  imageId,
  editor,
  activeObject,
  canvasRatio,
  handleSetBackgroundImage,
  setDisablePopoverClose,
  isBackground = false,
  setDefaultBg,
  setSquareBg,
  type,
}: IProps) => {
  const { t } = useTranslation();
  const modal = useModal();
  const wrapperRef = useRef<any>(null);
  const [popoverActive, setPopoverActive] = useState<{ [key: string]: boolean }>({});
  const [savedCrop, setSavedCrop] = useState<{ [key: string]: any } | null>(null);
  const [backgroundImage, setBackgroundImage] = useState<FabricTypes.Image | null>(null);
  const [showImagePropertiesModal, setShowImagePropertiesModal] = useState<PropertiesModalType>({
    show: false,
    id: 0,
  });

  const handleSetPopoverActive = (key: string) => (value: boolean) => {
    setPopoverActive((prevState) => ({ ...prevState, [key]: value }));
  };

  const handleSetAlign = (key: (typeof ALIGN_OPTIONS)[number]) => () => {
    if (activeObject) {
      const { canvas } = editor;
      if (type === 'shape') {
        void handleChangeImageInShapeAlign(canvas, activeObject, key);
      } else {
        void handleChangeObjectAlign(canvas, activeObject, key);
      }
    }
  };

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (wrapperRef?.current && !wrapperRef.current.contains(event.target)) {
        setPopoverActive({});
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  const getCroppedImage = (image: FabricTypes.Image) => {
    setDisablePopoverClose && setDisablePopoverClose(false);
    const filters = (activeObject as FabricTypes.Image)?.filters;
    image.set({ filters });
    image.applyFilters();
    handleSetBackgroundImage(image, imageId);
  };

  const handleOpenCropModal = () => {
    setDisablePopoverClose && setDisablePopoverClose(true);

    const content = (
      <CropImage
        savedCrop={savedCrop}
        onCrop={handleSaveCropProperties}
        imageId={imageId}
        canvasRatio={canvasRatio}
        isBackground={isBackground}
        setDefaultBg={setDefaultBg}
        setSquareBg={setSquareBg}
      />
    );

    modal.open(content, {
      header: t('editor.bgImageCrop') as string,
      width: 980,
      handleOk: getCroppedImage,
      handleCancel: () => setDisablePopoverClose && setDisablePopoverClose(false),
    });
  };

  const handleOpenImagePropertiesModal = () => {
    setDisablePopoverClose && setDisablePopoverClose(true);
    setShowImagePropertiesModal({ show: true, id: imageId });
  };

  const handleSaveCropProperties = (cropProperties: { [key: string]: any }) => {
    setSavedCrop(cropProperties);
  };

  return (
    <>
      <Wrapper>
        <Popover
          position={'bottom'}
          setIsActive={handleSetPopoverActive('align')}
          content={
            <ToolbarRow light>
              {ALIGN_OPTIONS.map((key) => (
                <ToolbarBtn
                  light
                  key={key}
                  onClick={handleSetAlign(key)}
                  data-tooltip={t(`align.${key}`) as string}
                  data-tooltip-position="bottom"
                >
                  <span className={`icon-align-${key}`} />
                </ToolbarBtn>
              ))}
            </ToolbarRow>
          }
          closeOnClick={false}
          relativeToParent={true}
        >
          <ToolbarBtn
            active={popoverActive['align']}
            shadow
            data-tooltip={t('action.align') as string}
            data-tooltip-position="top"
          >
            <span className="icon-align-start-vertical" />
          </ToolbarBtn>
        </Popover>
        <ToolbarBtn
          onClick={handleOpenImagePropertiesModal}
          data-tooltip={t(`action.adjustImage`) as string}
          data-tooltip-position="top"
          shadow
        >
          <span className="icon-wand" />
        </ToolbarBtn>

        <ToolbarBtn
          onClick={handleOpenCropModal}
          data-tooltip={t(`action.crop`) as string}
          data-tooltip-position="top"
          shadow
        >
          <span className="icon-crop" />
        </ToolbarBtn>
        <Popover
          position={'bottom-right'}
          setIsActive={handleSetPopoverActive('rotate')}
          closeOnClick={false}
          content={
            <RotateTool
              editor={editor}
              activeObject={activeObject}
              isImageInShape={type === 'shape'}
            />
          }
        >
          <ToolbarBtn
            active={popoverActive['rotate']}
            shadow
            data-tooltip={t('action.rotate') as string}
            data-tooltip-position="top"
          >
            <span className="icon-rotate" />
          </ToolbarBtn>
        </Popover>
      </Wrapper>
      {showImagePropertiesModal.show && (
        <ImagePropertiesModal
          setBackgroundImage={setBackgroundImage}
          imageId={showImagePropertiesModal.id}
          editor={editor}
          activeObject={activeObject}
          type={type}
          handleSetBackgroundImage={handleSetBackgroundImage}
          setShowImagePropertiesModal={setShowImagePropertiesModal}
        />
      )}
    </>
  );
};

import React from 'react';
import { FlexCol } from '../../../components/flexbox.component';
import SkeletonLoader from '../../../components/skeleton-loader.component';

const WildberriesLoaderMobile: React.FC<{ noneAnimation?: boolean }> = ({ noneAnimation }) => {
  return (
    <FlexCol gap={7} style={{ marginTop: '10px', marginLeft: 10 }}>
      <SkeletonLoader width="95px" height="10px" noneAnimation={noneAnimation} />
      <SkeletonLoader width="130px" height="20px" noneAnimation={noneAnimation} />
      <SkeletonLoader width="109px" height="10px" noneAnimation={noneAnimation} />
      <SkeletonLoader width="63px" height="10px" noneAnimation={noneAnimation} />
    </FlexCol>
  );
};

export default WildberriesLoaderMobile;

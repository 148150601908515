import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { MenuList } from '../menu';
import { Text } from '../../components/text.component';
import plus from '../../assets/icons/plus.svg';
import Components, { FolderModalContent, CustomCard, FolderInput } from './folders.style';
import { Modal } from '../../components/modal.component';
import { Button } from '../../components/button.component';
import { RightColumn } from '../my-projects/my-projects.style';
import { Row } from '../../components/grid.component';
import { t } from 'i18next';
import { FlexCol } from '../../components/flexbox.component';
import { CustomButton } from '../files/files.style';
import {
  createFolder,
  deleteFolder,
  getFolders,
  renameFolder,
} from '../../requests/folder.requests';
import { ICreateFolderRequest } from '../../interfaces/projects.interface';
import Input from '../../components/input.component';
import FolderListLoader from '../../components/loaders/folder-list.component';
import PageHeader from '../../components/page-header.component';
import useDebounce from '../../hooks/use-debounce.hooks';
import { ASCSort, SortOption } from '../../interfaces/editor.interface';
import { FOLDER_SORT } from '../../constants/shared.constants';
import CardCheckboxOverlay from '../../components/card.checkbox.overlay';
import MultipleChoice from '../../components/multiple-choice.component';
import useMouseDown from '../../hooks/use-mouse-down.hooks';
import { useNotification } from '../../contexts/notification.context';
import { useModal } from '../../contexts/modal.context';
import DeleteFolderForever from '../../components/delete-folder-forever.component';
import PagePagination from '../../components/pagination/page-pagination';
import { getElementText } from '../../utils/shared.utils';

export const FoldersPage = () => {
  const navigate = useNavigate();
  const { setNotification } = useNotification();
  const modal = useModal();

  const [folderList, setFolderList] = useState<ICreateFolderRequest[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [chosenFolderId, setChosenFolderId] = useState<number>();
  const [newFolderName, setNewFolderName] = useState<string>('');
  const [changeFolderName, setChangeFolderName] = useState<string>('');
  const [refList, setRefList] = useState<any>([]);
  const [forceDisablePopover, setForceDisablePopover] = useState<boolean>(false);
  const [enableHover, setEnableHover] = useState(true);
  const [folderName, setFolderName] = useState<string>('');
  const renamedFolderId = useRef<number | null | undefined>(null);
  const [loadingFolderList, setLoadingFolderList] = useState<boolean>(true);
  const [selectedOption, setSelectedOption] = useState<SortOption>(FOLDER_SORT[0]);
  const [sortAsc, setSortAsc] = useState<ASCSort>('DESC');
  const [didMount, setDidMount] = useState(false);
  const [highlight, setHighlight] = useState<boolean>(false);
  const [highlightedFolders, setHighlightedFolders] = useState<number[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [totalElements, setTotalElements] = useState<number>(0);

  const folderOptions = (folder: ICreateFolderRequest) => {
    return (
      <FlexCol gap={6} alignItems={'flex-start'} style={{ width: 'max-content', zIndex: 0 }}>
        <CustomButton onClick={() => handleRenameClick(folder?.id)}>
          {t(`action.rename`)}
        </CustomButton>
        <CustomButton onClick={() => handleDeleteFolder(folder)}>{t(`action.delete`)}</CustomButton>
      </FlexCol>
    );
  };

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const searchValue = params.get('q');
  const debouncedSearchValue = useDebounce(searchValue as string, 500);
  const isRightMouseDown = useMouseDown();

  const getFolderList = (
    searchValue?: string,
    sortType?: ASCSort,
    selectedOption?: string,
    page?: number,
  ) => {
    setLoadingFolderList(true);
    getFolders(searchValue, selectedOption, sortType, page).then((res: any) => {
      setTotalPages(res.totalPages);
      setFolderList(res.list);
      setTotalElements(res.size);
      const refs = res.list.reduce(
        (acc: any, item: any) => ({ ...acc, [item.id]: React.createRef() }),
        {},
      );
      setRefList(refs);
      setLoadingFolderList(false);
    });
  };

  useEffect(() => {
    if (sortAsc === 'ASC') setSortAsc('DESC');
    else
      didMount &&
        getFolderList(debouncedSearchValue as string, sortAsc, selectedOption.value, page);
  }, [selectedOption.value]);

  useEffect(() => {
    if (didMount && searchValue === null) {
      getFolderList(debouncedSearchValue as string, sortAsc, selectedOption.value, page);
    }
  }, [didMount, debouncedSearchValue, sortAsc, page]);

  useEffect(() => {
    if (didMount && debouncedSearchValue !== null) {
      getFolderList(debouncedSearchValue as string, sortAsc, selectedOption.value, page);
    }
  }, [debouncedSearchValue, sortAsc, page]);

  useEffect(() => setDidMount(true), []);

  const handleCreateFolder = () => {
    setIsLoading(true);
    createFolder({ folderName: newFolderName })
      .then((res: any) => navigate('/folders/item', { state: { folderInfo: res.data } }))
      .finally(() => setIsLoading(false));
  };

  const handleDeleteFolder = (folder?: ICreateFolderRequest) => {
    const id = folder?.id;
    const content = <DeleteFolderForever message={t('deleteMessages.FOLDER')} />;

    modal.open(content, {
      header: 'Хотите удалить папку?',
      headerAlign: 'left',
      width: 540,
      handleOk: () => {
        id &&
          deleteFolder({ id }).then(() => {
            setNotification({
              text: `Папка удалена. Все элементы доступны в общих разделах.`,
            });

            getFolderList(debouncedSearchValue as string, sortAsc, selectedOption.value, page);
          });
        modal.close();
      },
      handleCancel: () => {
        modal.close();
      },
    });
  };

  const handleRenameClick = (id?: number) => {
    setEnableHover(false);
    setChosenFolderId(id);
    setForceDisablePopover(true);
    setTimeout(() => {
      if (id) {
        const valueLength = refList[id].current.value.length;
        refList[id].current.focus();
        refList[id].current.setSelectionRange(valueLength, valueLength);
        setForceDisablePopover(false);
      }
    }, 0);
  };

  const handleFolderNameChange = (folder: ICreateFolderRequest, folderName: string) => {
    if (folderName && chosenFolderId && folderName !== folder.folderName) {
      renameFolder({ id: folder.id, newName: folderName }).finally(() => {
        setChosenFolderId(0);
        getFolderList(debouncedSearchValue as string, sortAsc, selectedOption.value, page);
        setEnableHover(true);
      });
    } else {
      setTimeout(() => {
        setChosenFolderId(0);
        setEnableHover(true);
      }, 500);
    }
    folderName && setFolderName(folderName);
  };

  const handleCardClick = (item: ICreateFolderRequest) => {
    if (chosenFolderId !== item.id) {
      navigate('/folders/item', {
        state: { folderInfo: item },
      });
    }
  };

  useEffect(() => {
    renamedFolderId.current = chosenFolderId;
  }, [chosenFolderId]);

  const handleClose = () => {
    setChosenFolderId(0);
  };

  return (
    <Components.Wrapper>
      <MenuList />
      <RightColumn>
        <PageHeader
          pageTitle={'Все папки'}
          sortType="folderSort"
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
          setSortOrder={setSortAsc}
          sortOrder={sortAsc}
          highlight={highlight}
          setHighlight={setHighlight}
          count={totalElements}
          isLoading={loadingFolderList}
        />
        <Row gutter={0} style={{ gap: '10px' }}>
          {loadingFolderList ? (
            <FolderListLoader />
          ) : (
            <>
              <Components.NewCardButton onClick={() => setShowModal(true)} isScaled>
                <Text fontSize="23px" color="gray" strong>
                  Создать папку
                </Text>
                <img src={plus} alt="plus" />
              </Components.NewCardButton>
              {folderList.length === 0 && debouncedSearchValue && (
                <>
                  Тут по вашему запросу ничего не найдено. Посмотрите в других вкладках или
                  повторите попытку, набрав другие слова.
                </>
              )}
              {folderList.map((item) => (
                <CustomCard
                  active={highlightedFolders.includes(item.id as number)}
                  highlight={highlight}
                  key={item.id}
                  onClick={() => !highlight && enableHover && handleCardClick(item)}
                  customOptions={
                    !highlight && (chosenFolderId === item.id ? false : folderOptions(item))
                  }
                  forceDisablePopover={forceDisablePopover}
                  enableHover={enableHover}
                >
                  <CardCheckboxOverlay
                    cardId={item.id as number}
                    highlight={highlight}
                    highlightedCards={highlightedFolders}
                    setHighlightedCard={setHighlightedFolders}
                    isRightMouseDown={isRightMouseDown}
                  />
                  {chosenFolderId !== item.id ? (
                    <Text
                      truncate
                      rows={3}
                      style={{
                        padding: '2px',
                        lineHeight: '31px',
                        fontSize: '23px',
                      }}
                    >
                      {renamedFolderId.current == item.id
                        ? folderName
                        : item.folderName || t('myProjects.withoutName')}
                    </Text>
                  ) : (
                    <FolderInput
                      key={item.id}
                      defaultValue={item.folderName || t('myProjects.withoutName')}
                      rows={3}
                      disabled={chosenFolderId !== item.id}
                      onBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
                        item.id === chosenFolderId && handleFolderNameChange(item, e.target.value)
                      }
                      maxLength={100}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setChangeFolderName(e.target.value)
                      }
                      ref={item.id && refList[item.id]}
                    />
                  )}
                  {changeFolderName.length >= 100 && chosenFolderId === item.id && (
                    <p color="red" style={{ padding: 0, color: 'red', fontSize: '10px' }}>
                      Максимум 100 символов
                    </p>
                  )}
                  <Text color="infoGray" fontSize="14px">
                    {getElementText(item.itemsCount as number)}
                  </Text>
                </CustomCard>
              ))}
            </>
          )}
        </Row>

        <PagePagination totalCount={totalPages} page={page} setPage={setPage} />
      </RightColumn>
      {showModal && (
        <Modal onClose={() => setShowModal(false)} width={'26%'}>
          <FolderModalContent>
            <Text fontSize="23px" strong>
              Новая папка
            </Text>
            <Input
              label="Название папки"
              value={newFolderName}
              onChange={(e) => setNewFolderName(e.target.value)}
              showButtons={false}
              maxlength={100}
            />
            <Components.ButtonsWrap spaceBetween>
              <Button
                btnStyle={'cancel'}
                style={{ padding: '0' }}
                onClick={() => setShowModal(false)}
              >
                Отмена
              </Button>
              <Button
                disabled={!newFolderName}
                icon={'folder-plus'}
                onClick={handleCreateFolder}
                isLoading={isLoading}
              >
                Создать
              </Button>
            </Components.ButtonsWrap>
          </FolderModalContent>
        </Modal>
      )}
      {highlight && (
        <MultipleChoice
          refreshRequest={() =>
            getFolderList(debouncedSearchValue as string, sortAsc, selectedOption.value, page)
          }
          type="folder"
          choiceList={folderList}
          setHighlightedIds={setHighlightedFolders}
          highlightedList={highlightedFolders}
          folderId={chosenFolderId}
          setShowModal={setShowModal}
          setHighlight={setHighlight}
          actionsWhenClose={handleClose}
          itemType="FOLDER"
        />
      )}
    </Components.Wrapper>
  );
};

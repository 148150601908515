import React, { useCallback, useEffect, useState } from 'react';
import { fabric } from 'fabric';
import FabricTypes from 'fabric/fabric-impl';
import { FabricJSEditor } from 'fabricjs-react';
import { useTranslation } from 'react-i18next';

import { useCanvasHistory } from '../../contexts/canvas-history.context';
import { useModal } from '../../contexts/modal.context';
import {
  IBasicEditorProps,
  IGradient,
  IObjectWithId,
  IPopoverActive,
  IToolbarAction,
  ObjectWithId,
  PropertiesModalType,
} from '../../interfaces/editor.interface';
import { focusOnTextBox, getImageId, handleSelectGroupItem } from '../../utils/editor.utils';
import { ColorPopover } from '../color-popover/color-popover.component';
import { CropImage } from '../crop-image.component';
import Popover from '../popover.component';
import { ShapesPopover } from '../toolbar/shapes-popover.component';
import { ToolbarBtn, ToolbarWrapper } from '../toolbar/toolbar.style';
import { AlignTool } from './align-tool.component';
import LineHeightTool from './line-height-tool.component';
import { TextBgIcon } from './object-toolbar.style';
import { RotateTool } from './rotate-tool.component';
import { StrokeTool } from './stroke-tool.component';
import TextAlignTool from './text-align-tool.component';
import TextStylingTool from './text-styling-tool.component';
import { EDITOR_TOOLTIPS } from '../../constants';
import ShadowTool from './shadow-tool/shadow-tool.component';
import CharSpace from './char-space.component';
import InfographicTool from './infographic-tool/infographic-tool.component';
import AllImagePopover from '../toolbar/image-popover/all-image-popover.component';
import ImagePropertiesModal from '../image-properties-modal.component';
import { useShared } from '../../contexts/shared.context';

export const ObjectToolbar = (props: IBasicEditorProps) => {
  const { t } = useTranslation();
  const { popoverActive, setPopoverActive, projectLoading } = useShared();
  const { currentState, historySaveAction } = useCanvasHistory();
  const modal = useModal();
  const [disablePopoverClose, setDisablePopoverClose] = useState<boolean>(false);
  const [activeObject, setActiveObject] = useState<IObjectWithId>();
  const [selectionFill, setSelectionFill] = useState<string>();
  const [selectionStyles, setSelectionStyles] = useState<FabricTypes.TextOptions[]>();
  const [selectionTextBg, setSelectionTextBg] = useState<string>();
  const [currentStateOld, setCurrentStateOld] = useState<number>(currentState);
  const [selectedRect, setSelectedRect] = useState<FabricTypes.Rect | undefined>(undefined);
  const [showImagePropertiesModal, setShowImagePropertiesModal] = useState<PropertiesModalType>({
    show: false,
    id: 0,
  });

  useEffect(() => {
    setTimeout(() => setActiveObject(props.activeObject), 0);
  }, [props.activeObject]);

  const setTextBoxColor = () => {
    const textBox = activeObject as FabricTypes.Textbox;
    const changeFillColor = () => {
      const start = textBox.selectionStart === 0 ? 0 : (textBox.selectionStart as number) - 1;
      const end = textBox.selectionStart === 0 ? 1 : (textBox.selectionStart as number);
      let _selectionStyles =
        textBox.selectionStart !== textBox.selectionEnd
          ? textBox.getSelectionStyles(textBox.selectionStart, textBox.selectionEnd)
          : textBox.getSelectionStyles(start, end);

      if (_selectionStyles.length === 0) return;

      if (JSON.stringify(Object.values(_selectionStyles)[0]) === '{}') {
        _selectionStyles = textBox.getSelectionStyles(start + 1, end + 1);
      }

      setSelectionStyles(_selectionStyles);
      setSelectionFill(
        _selectionStyles.every((val, i, arr) => val?.fill === arr[0]?.fill)
          ? _selectionStyles[0].fill
          : undefined,
      );
      setSelectionTextBg(
        _selectionStyles.every(
          (val, i, arr) => val?.textBackgroundColor === arr[0]?.textBackgroundColor,
        )
          ? _selectionStyles[0].textBackgroundColor
          : undefined,
      );
    };

    // Что бы функция работала не только тогда когда он изменился, а также при первом открытии
    changeFillColor();
    textBox.off('selection:changed');
    textBox.on('selection:changed', () => {
      changeFillColor();
    });
  };

  useEffect(() => {
    if (activeObject?.type === 'textbox' && props.editor) {
      const textbox = activeObject as FabricTypes.Textbox;
      let currentText = textbox.text || '';

      const handleTextChange = () => {
        const newText = textbox.text || '';
        const addedText = newText.substring(currentText.length);
        const addedTextIndex = currentText.length;

        textbox.setSelectionStyles(
          { ['fill']: getInitialFill('fill') },
          addedTextIndex,
          addedTextIndex + addedText.length,
        );

        currentText = newText;
      };

      props.editor.canvas.on('text:changed', handleTextChange);

      return () => {
        props.editor.canvas.off('text:changed', handleTextChange);
      };
    }
  }, [activeObject, props.editor]);

  useEffect(() => {
    setPopoverActive({});
    setSelectionStyles(undefined);
    if (activeObject?.type === 'textbox') {
      setTextBoxColor();
    }
  }, [activeObject]);

  const handleSetPopoverActive = (key: string) => (value: boolean) => {
    setPopoverActive((prevState) => ({ ...prevState, [key]: value }));
    if (!value && props.refreshProjectCard && currentStateOld !== currentState) {
      props.refreshProjectCard();
      setCurrentStateOld(currentState);
    }
  };

  // const handleClose = (key: string) => () => {
  //   // use example - handleClose('align')
  //   handleSetPopoverActive(key)(false);
  // }

  const getCroppedImage = (image: ObjectWithId<FabricTypes.Image>) => {
    const { editor } = props;
    if (activeObject) {
      const { canvas } = editor as FabricJSEditor;
      const { top, left, scaleX, scaleY, name, id } = activeObject;
      image.set({
        centeredRotation: true,
        top,
        left,
        scaleX,
        scaleY,
        id,
        name,
      });
      canvas.remove(activeObject);
      canvas.add(image);
      canvas.requestRenderAll();
      historySaveAction(editor);
    }
  };

  const handleOpenCropModal = () => {
    const imageId = getImageId(activeObject as FabricTypes.Object);
    const content = (
      <CropImage imageId={imageId} setSelectedRect={setSelectedRect} selectedRect={selectedRect} />
    );
    modal.open(content, {
      header: t('editor.imageCrop') as string,
      width: 980,
      handleOk: getCroppedImage,
    });
  };

  const handleOpenInfographicModal = () => {
    // const infographicCheck =
    //   (activeObject as any)._objects.some((obj: FabricTypes.Object) =>
    //     obj.name?.includes('infographic'),
    //   ) ||
    //   (activeObject && activeObject.name?.includes('infographic'));

    // if (infographicCheck) {
    //   setNotification({
    //     text: 'Вы не можете создать инфографику, так как один или несколько выделенных элементов уже являются инфографикой. Выберите, пожалуйста, другие элементы.',
    //   });
    //   return;
    // }

    const content = <InfographicTool {...props} activeObject={activeObject} />;
    modal.open(content, {
      width: 540,
      handleCancel: modal.close,
    });
  };

  const handleOpenImagePropertiesModal = () => {
    const imageId = getImageId(activeObject as FabricTypes.Object);
    setShowImagePropertiesModal({ show: true, id: imageId });
    setDisablePopoverClose(true);
  };

  const canGroup = React.useMemo(() => {
    const selected = activeObject as FabricTypes.ActiveSelection;
    return selected?.type === 'activeSelection' && selected._objects?.length > 1;
  }, [activeObject]);

  const handleUnGroup = () => {
    // if (activeObject && activeObject.name?.includes('infographic')) {
    //   setNotification({ text: 'Инфографику нельзя разгруппировать.' });
    //   return;
    // }
    const { canvas } = props.editor as FabricJSEditor;
    const group = activeObject as FabricTypes.Group;
    group.forEachObject((obj) => {
      obj.hasControls = true;
    });
    group?.toActiveSelection();
    canvas.discardActiveObject();
    canvas.requestRenderAll();
    historySaveAction(props.editor);
    props.refreshProjectCard && props.refreshProjectCard();
  };

  const handleGroup = () => {
    const { canvas } = props.editor as FabricJSEditor;
    const selected = activeObject as FabricTypes.ActiveSelection;
    const group = selected.toGroup();
    group.set({
      subTargetCheck: true,
      centeredRotation: true,
    });
    group.off('mousedblclick');
    group.on('mousedblclick', handleSelectGroupItem(canvas));
    canvas.requestRenderAll();
    historySaveAction(props.editor);
    props.refreshProjectCard && props.refreshProjectCard();
  };

  const actions: IToolbarAction[] = [
    {
      key: 'text',
      icon: 'font',
      type: ['textbox'],
      popover: (
        <TextStylingTool {...props} activeObject={activeObject} selectionStyles={selectionStyles} />
      ),
    },
    {
      key: 'lineHeight',
      icon: 'line-height',
      type: ['textbox'],
      popover: <LineHeightTool {...props} activeObject={activeObject} />,
    },
    {
      key: 'charSpace',
      icon: 'letter-spacing',
      type: ['textbox'],
      popover: <CharSpace {...props} activeObject={activeObject} />,
    },
    {
      key: 'textAlign',
      icon: 'align-left',
      type: ['textbox'],
      popover: <TextAlignTool {...props} activeObject={activeObject} />,
    },

    {
      key: 'shapes',
      icon: 'triangle',
      type: ['rect', 'ellipse', 'triangle', 'path', 'line'],
      popover: <ShapesPopover {...props} activeObject={activeObject} />,
    },
    {
      key: 'color',
      icon: 'circle',
      type: ['textbox', 'rect', 'ellipse', 'triangle', 'path'],
    },
    {
      key: 'shapeStroke',
      icon: 'lines-style',
      type: ['rect', 'ellipse', 'triangle', 'path', 'line'],
      popover: (
        <StrokeTool
          {...props}
          activeObject={activeObject}
          setDisablePopoverClose={setDisablePopoverClose}
        />
      ),
    },
    { key: 'textBackgroundColor', icon: 'pencil', type: ['textbox'] },
    {
      key: 'align',
      icon: 'align-start-vertical',
      popover: <AlignTool {...props} activeObject={activeObject} />,
    },
    {
      key: 'rotate',
      icon: 'rotate',
      disableRelativeToParent: true,
      popover: <RotateTool {...props} activeObject={activeObject} />,
    },

    {
      key: 'crop',
      icon: 'crop',
      type: ['image'],
      handler: handleOpenCropModal,
    },
    {
      key: canGroup ? 'group' : 'unGroup',
      icon: 'group',
      type: ['activeSelection', 'group'],
      handler: canGroup ? handleGroup : handleUnGroup,
    },
    {
      key: 'filters',
      icon: 'wand',
      type: ['image'],
      handler: handleOpenImagePropertiesModal,
    },
    // {
    //   key: 'text',
    //   icon: 'text-cursor',
    //   type: ['rect', 'circle', 'triangle', 'path'],
    //   popover: <ShapeTextTool {...props} activeObject={activeObject} />,
    // },
    {
      key: 'shadow',
      icon: 'shadow',
      type: ['textbox', 'image', 'rect', 'ellipse', 'triangle', 'path'],
      popover: <ShadowTool {...props} activeObject={activeObject} />,
    },
    {
      key: 'imageInShape',
      icon: 'image',
      type: ['rect', 'ellipse', 'triangle', 'path'],
      popover: <AllImagePopover editor={props.editor} shapeImage />,
    },
    {
      key: 'infographic',
      icon: 'components-plus',
      type: ['textbox', 'image', 'rect', 'ellipse', 'triangle', 'path', 'group', 'activeSelection'],
      handler: handleOpenInfographicModal,
    },
  ];

  const filterActions = (action: IToolbarAction) => {
    return (
      !action.type ||
      (activeObject &&
        ((activeObject.type && action.type.includes(activeObject.type)) ||
          (activeObject.name && action.type.includes(activeObject.name))))
    );
  };

  const getInitialFill = useCallback(
    (key: string) => {
      // if (
      //   activeObject?.type === 'group' &&
      //   activeObject?.name &&
      //   ['rect', 'circle', 'triangle', 'path'].includes(activeObject.name)
      // ) {
      //   const group = activeObject as FabricTypes.Group;
      //   return group.item(0).get('fill');
      // }

      if (props.activeObject?.type === 'textbox') {
        const textBox = props.activeObject as FabricTypes.Textbox;
        if (key === 'textBackgroundColor') {
          return selectionTextBg ?? textBox.get('textBackgroundColor');
        }

        if (
          textBox.styles &&
          textBox.styles[0] &&
          textBox.styles[0][0] &&
          Object.values(textBox.styles).every((style: any) => {
            return Object.values(style).every((s: any) => s.fill === textBox.styles[0][0].fill);
          })
        ) {
          return textBox.styles[0][0].fill;
        }
        return selectionFill ?? textBox.get('fill');
      }
      return props.activeObject?.fill;
    },
    [props.activeObject, selectionFill, selectionTextBg],
  );

  const handleChangeColor =
    (colorType: 'fill' | 'stroke' | 'textBackgroundColor') =>
    (color: string, focusOnTextbox = true) => {
      const { editor } = props;
      if (editor && activeObject) {
        if (activeObject.type === 'textbox' || colorType === 'textBackgroundColor') {
          const textbox = activeObject as FabricTypes.Textbox;

          const { selectionStart, selectionEnd } = textbox;
          if (selectionStart !== undefined && selectionStart === selectionEnd) {
            if (selectionStart === 0) {
              textbox.set(colorType, color);
            } else if (textbox.text?.slice(-1) === ' ') {
              textbox.setSelectionStyles(
                { [colorType]: color },
                selectionStart - 1,
                selectionStart,
              );
            } else {
              const currentText = textbox.text || '';
              if (selectionStart === currentText.length) {
                const lastChar = currentText.charAt(currentText.length - 1);

                if (lastChar.match(/\p{L}/u)) {
                  const newText = currentText + ' ';
                  textbox._clearCache();

                  const newSelectionStart = newText.length - 1;

                  textbox.setSelectionStyles(
                    { [colorType]: color },
                    newSelectionStart,
                    newSelectionStart + 1,
                  );

                  textbox.set('text', newText);

                  textbox.setSelectionStart(newSelectionStart + 1);
                  textbox.setSelectionEnd(newSelectionStart + 1);
                }
              }
            }
          } else {
            textbox.setSelectionStyles({ [colorType]: color }, selectionStart, selectionEnd);
          }
          focusOnTextbox && focusOnTextBox(textbox);
          fabric.util.clearFabricFontCache();
          // } else if (
          //   activeObject?.type === 'group' &&
          //   activeObject.name &&
          //   ['rect', 'circle', 'triangle', 'path'].includes(activeObject.name)
          // ) {
          //   const group = activeObject as FabricTypes.Group;
          //   group.item(0).set(colorType, color);
        } else {
          activeObject.set(colorType, color);
        }
        editor.canvas.renderAll();
      }
    };

  const isPopoverActive = useCallback(
    (key: string) => {
      return popoverActive[key as keyof IPopoverActive];
    },
    [popoverActive],
  );

  const getTooltipText = (action: IToolbarAction) => {
    if (action.key === 'textBackgroundColor') {
      return 'action.textBackgroundColor';
    }

    if (
      // activeObject?.type === 'group' &&
      activeObject?.name &&
      ['rect', 'ellipse', 'triangle', 'path', 'line'].includes(activeObject.name)
    ) {
      return 'action.shapeColor';
    }

    return activeObject?.type && EDITOR_TOOLTIPS[activeObject.type];
  };

  if (projectLoading) return null;

  return activeObject ? (
    <>
      <ToolbarWrapper align="right">
        {actions.filter(filterActions).map((action, index) =>
          ['color', 'textBackgroundColor'].includes(action.key) ? (
            <ColorPopover
              colorPopoverName={
                action.key === 'textBackgroundColor'
                  ? (t('action.textBackgroundColor') as string)
                  : undefined
              }
              key={`color_${index}`}
              colorType={'object'}
              initialFill={getInitialFill(action.key)}
              editor={props.editor}
              handleChangeColor={
                action.key === 'textBackgroundColor'
                  ? handleChangeColor('textBackgroundColor')
                  : handleChangeColor('fill')
              }
              refreshProjectCard={props.refreshProjectCard}
              showTransparentBtn={action.key === 'textBackgroundColor' || action.key === 'color'}
              tooltipPosition="left"
              tooltipText={getTooltipText(action)}
              typeKey={`object-toolbar-${action.key}`}
            >
              {action.key === 'textBackgroundColor' ? (
                <TextBgIcon color={getInitialFill(action.key) as string} />
              ) : null}
            </ColorPopover>
          ) : action.popover ? (
            <Popover
              key={`${action.key}_${index}`}
              type={['shapes', 'imageInShape'].includes(action.key) ? 'cloud' : undefined}
              position={'left'}
              setIsActive={handleSetPopoverActive(action.key)}
              content={action.popover}
              preloadContent={
                ['text', 'textAlign'].includes(action.key) && activeObject?.type === 'textbox'
              }
              className={isPopoverActive(action.key) ? 'active' : ''}
              disableOutsideClick={disablePopoverClose}
              closeOnClick={false}
              relativeToParent={!action.disableRelativeToParent}
            >
              <ToolbarBtn
                active={isPopoverActive(action.key)}
                data-tooltip={
                  isPopoverActive(action.key)
                    ? null
                    : (t(`${EDITOR_TOOLTIPS[action.key]}`) as string)
                }
                data-tooltip-position="left"
              >
                <span className={`icon-${action.icon}`} />
              </ToolbarBtn>
            </Popover>
          ) : (
            <ToolbarBtn
              key={`${action.key}_${index}`}
              onClick={action.handler}
              data-tooltip={t(`${EDITOR_TOOLTIPS[action.key]}`) as string}
              data-tooltip-position="left"
            >
              <span className={`icon-${action.icon}`} />
            </ToolbarBtn>
          ),
        )}
      </ToolbarWrapper>
      {showImagePropertiesModal.show && (
        <ImagePropertiesModal
          title={'Настройка изображения'}
          imageId={showImagePropertiesModal.id}
          editor={props.editor}
          activeObject={activeObject}
          type={'image'}
          handleSetBackgroundImage={() => modal.close()}
          setShowImagePropertiesModal={setShowImagePropertiesModal}
        />
      )}
    </>
  ) : null;
};

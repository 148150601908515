import React from 'react';
import { Card, CardWrapper } from '../mobile.style';
import { Text } from '../../../components/text.component';
import mobile_logo from '../../../assets/images/mobile-logo.png';

const MobileSuccess: React.FC<{ success?: boolean }> = ({ success }) => {
  return (
    <Card color="#FFFFFF" success={success}>
      <CardWrapper>
        <img src={mobile_logo} style={{ marginBottom: '10px', height: 120 }} />
        <Text
          fontWeight={600}
          fontSize="23px"
          lineHeight="40.98px"
          style={{ whiteSpace: 'nowrap' }}
        >
          Добро пожаловать в Mark It!
        </Text>
        <Text fontWeight={500} fontSize="16px" lineHeight="21.86px">
          Наш сервис работает пока только в ПК версии. Чтобы работать в нем полноценно,
          воспользуйтесь персональным компьютером или ноутбуком.
        </Text>
      </CardWrapper>
    </Card>
  );
};

export default MobileSuccess;

import { detailsType } from '../components/toolbar/template-popover/template-types';
import {
  TCreateInfoGraphic,
  TGetListInfoGraphic,
  TGetListResponse,
  TUpdateInfoGraphic,
} from '../interfaces/infographics.interface';
import api from './api';

const baseUrl = 'template';

export const InfoGraphicRequest = {
  createInfoGraphic: async (data: TCreateInfoGraphic) => {
    const { image, ...infoDto } = data;
    const formData = new FormData();
    const InfographicsCreateDto = new Blob([JSON.stringify(infoDto)], { type: 'application/json' });
    formData.append('image', image);
    formData.append('createDto', InfographicsCreateDto);
    return api.post(`${baseUrl}/create/infographic`, formData);
  },

  createCanvas: async (data: { details: detailsType[]; image: string | ArrayBuffer }[]) => {
    const createDtoList = data.map((item) => ({
      details: item.details,
      image: item.image,
    }));

    return await api.post<any, { list: number[]; success: boolean }>(
      `${baseUrl}/create/canvas`,
      createDtoList,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
  },

  getInfoGraphic: async (id: number) => {
    const response = await api.get(`${baseUrl}/${id}`);
    return response.data;
  },

  deleteInfoGraphic: async (id: number) => {
    await api.delete(`${baseUrl}/delete`, { params: { id } });
  },

  addFavoriteInfoGraphic: async (id: number) => {
    await api.post(`${baseUrl}/favourite/add/${id}`);
  },

  getFavoriteInfoGraphicById: async (id: number) => {
    const response = await api.get<TGetListResponse>(`${baseUrl}/favorite/${id}`);
    return response.data;
  },

  getGlobalList: (params: TGetListInfoGraphic) => {
    return api.get<
      { list: TGetListResponse[]; success: boolean },
      { list: TGetListResponse[]; success: boolean }
    >(`${baseUrl}/global/list`, {
      params,
    });
  },

  getPrivateList: (params: TGetListInfoGraphic) => {
    return api.get<
      { list: TGetListResponse[]; success: boolean },
      { list: TGetListResponse[]; success: boolean }
    >(`${baseUrl}/private/list`, {
      params,
    });
  },

  getFavoriteInfoGraphicList: (params: TGetListInfoGraphic) => {
    return api.get<
      { list: TGetListResponse[]; success: boolean },
      { list: TGetListResponse[]; success: boolean }
    >(`${baseUrl}/favourite/list`, {
      params,
    });
  },

  removeFavoriteInfoGraphic: async (id: number) => {
    await api.post(`${baseUrl}/favourite/remove/${id}`);
  },

  updateInfographic: async (updateDto: TUpdateInfoGraphic) => {
    await api.post(`${baseUrl}/update`, updateDto);
  },

  updateImageTemplate: async (data: { id: number; image: Blob }) => {
    const formData = new FormData();
    const templateId = new Blob([JSON.stringify(data.id)], { type: 'application/json' });

    formData.append('id', templateId);
    formData.append('image', data.image);
    return api.post(`${baseUrl}/update/image`, formData);
  },
};

import React, { InputHTMLAttributes, useEffect, useRef } from 'react';
import styled from 'styled-components';

const RangeInput = styled.input<InputHTMLAttributes<any>>`
  -webkit-appearance: none;
  height: 0.5rem;
  background-color: ${(p) => p.theme.lightGray2};
  border-radius: 12px;
  background-image: ${({ theme }) =>
    `linear-gradient(${theme.lightPrimary}, ${theme.lightPrimary})`};
  background-size: 70% 100%;
  background-repeat: no-repeat;

  /* Input Thumb */
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 1rem;
    width: 1rem;
    border-radius: 50%;
    background: ${(p) => p.theme.black};
    cursor: pointer;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
    transition: width 0.3s ease-in-out, height 0.3s ease-in-out;
  }

  &::-moz-range-thumb {
    -webkit-appearance: none;
    height: 1rem;
    width: 1rem;
    border-radius: 50%;
    background: ${(p) => p.theme.black};
    cursor: pointer;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
    transition: width 0.3s ease-in-out, height 0.3s ease-in-out;
  }

  &::-ms-thumb {
    -webkit-appearance: none;
    height: 1rem;
    width: 1rem;
    border-radius: 50%;
    background: ${(p) => p.theme.black};
    cursor: pointer;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
    transition: width 0.3s ease-in-out, height 0.3s ease-in-out;
  }

  &::-webkit-slider-thumb:hover {
    height: 1.25rem;
    width: 1.25rem;
  }

  &::-moz-range-thumb:hover {
    height: 1.25rem;
    width: 1.25rem;
  }

  &::-ms-thumb:hover {
    height: 1.25rem;
    width: 1.25rem;
  }

  /* Input Track */
  &::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
  }

  &::-moz-range-track {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
  }

  &::-ms-track {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
  }
`;

export const Range = (props: InputHTMLAttributes<any>) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const setBackgroundStyle = (target: HTMLInputElement) => {
    const min: number = parseFloat(target.min);
    const max: number = parseFloat(target.max);
    const val: number = parseFloat(target.value);
    target.style.backgroundSize = ((val - min) * 100) / (max - min) + '% 100%';
  };

  useEffect(() => {
    if (
      inputRef?.current &&
      props.value !== undefined &&
      props.value !== null &&
      props.value !== ''
    ) {
      setBackgroundStyle(inputRef?.current);
    }
  }, [inputRef, props.value]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { target } = event;
    setBackgroundStyle(target);
    if (props.onChange) {
      props.onChange(event);
    }
  };

  return <RangeInput ref={inputRef} type="range" {...props} onChange={handleInputChange} />;
};

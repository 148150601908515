import React, { createContext, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { TNotification } from '../interfaces/infographics.interface';
import Notification from '../components/notification.component';
import { useLocation } from 'react-router-dom';

type Props = {
  children: React.ReactNode;
};

type NotificationContextType = {
  notification: TNotification;
  setNotification: (value: TNotification) => void;
  closeNotification: () => void;
};

const NotificationContext = createContext<NotificationContextType>({
  notification: {
    show: false,
    text: '',
    handleFunction: () => null,
    buttonText: '',
    errorNotification: false,
    icon: '',
  },
  setNotification: () => null,
  closeNotification: () => null,
});

export const NotificationProvider: React.FC<Props> = ({ children }) => {
  const notificationRef = useRef<any>(null);
  const [notification, setNotification] = useState<TNotification>({
    show: false,
    text: '',
    handleFunction: () => null,
    buttonText: '',
    errorNotification: false,
    icon: '',
  });

  const handleClose = () => {
    notification.handleClose && notification.handleClose();
    setNotification({
      text: '',
    });
  };

  const closeNotification = () => {
    setNotification({
      text: '',
    });
  };

  const value = useMemo(
    () => ({
      notification,
      setNotification,
      closeNotification,
    }),
    [notification],
  );

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (!notificationRef.current.contains(event.target as Node)) {
        closeNotification();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [notification.show]);

  return (
    <NotificationContext.Provider value={{ ...value }}>
      {children}
      <span ref={notificationRef}>
        <Notification
          show={notification.text}
          notificationText={notification.text}
          handleClose={handleClose}
          handleFunction={notification.handleFunction}
          buttonText={notification.buttonText}
          errorNotification={notification.errorNotification}
          icon={notification.icon}
          time={notification.time}
          closeNotification={closeNotification}
        />
      </span>
    </NotificationContext.Provider>
  );
};

export const useNotification = (): NotificationContextType => {
  return {
    ...useContext(NotificationContext),
  };
};

import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Components, { RightColumn, ProjectNameInput } from './my-projects.style';
import { Text } from '../../components/text.component';
import { Row } from '../../components/grid.component';
import { Flex, FlexCol } from '../../components/flexbox.component';
import { Card } from '../../components/card.component';
import { MenuList } from '../menu';
import {
  getProjects,
  deleteProject,
  renameProject,
  cloneProject,
  getProject,
} from '../../requests/project.requests';
import { IProject } from '../../interfaces/projects.interface';
import { CustomButton } from '../files/files.style';
import { recoverItem } from '../../requests/trash.requests';
import plus from '../../assets/icons/plus.svg';
import ProjectListLoader from '../../components/loaders/project-list.component';
import PageHeader from '../../components/page-header.component';
import useDebounce from '../../hooks/use-debounce.hooks';
import { ASCSort } from '../../interfaces/editor.interface';
import { FOLDER_SORT } from '../../constants/shared.constants';
import CardCheckboxOverlay from '../../components/card.checkbox.overlay';
import MultipleChoice from '../../components/multiple-choice.component';
import useMouseDown from '../../hooks/use-mouse-down.hooks';
import NewProjectModal from '../../components/new-project-modal.component.';
import RateOverlay from '../../components/rates/rate-overlay.component';
import { RateNames } from '../../constants/rates.constant';
import { RateDetails, useUserContext } from '../../contexts/user.context';
import { useNotification } from '../../contexts/notification.context';
import RateModal from '../../components/rates/rate-modal.component';

import NewProjectWithTemplateModal from '../../components/new-project-with-template-modal.component';
import useSortSearch, { SortOption } from '../../hooks/use-sort-search.hooks';
import { TGetListResponse } from '../../interfaces/infographics.interface';
import MoveToFolderModal from './components/MoveToFolderModal';
import TemplateHeader from './components/TemplateHeader';
import { downloadProject } from '../../utils/canvaspng';
import PagePagination from '../../components/pagination/page-pagination';

export const MyProjects = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setNotification, closeNotification } = useNotification();
  const { userData, updateUserDetails } = useUserContext();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const searchValue = params.get('q');
  const isRightMouseDown = useMouseDown();

  const [chosenProjectId, setChosenProjectId] = useState<number>();
  const [chosenFolderId, setChosenFolderId] = useState<number>();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [projects, setProjects] = useState<IProject[] | null>(null);
  const [refList, setRefList] = useState<any>();
  const [forceDisablePopover, setForceDisablePopover] = useState<boolean>(false);

  const [enableHover, setEnableHover] = useState<boolean>(true);
  const [loadingProjects, setLoadingProjects] = useState<boolean>(true);
  const [didMount, setDidMount] = useState<boolean>(false);
  const [templateList, setTemplateList] = useState<TGetListResponse[]>([]);
  const [isNameModalOpen, setIsNameModalOpen] = useState<boolean>(false);
  const [highlight, setHighlight] = useState<boolean>(false);
  const [highlightedProjects, setHighlightedProjects] = useState<number[]>([]);
  const [rate, setRate] = useState<RateDetails>();
  const [isRateOpen, setIsRateOpen] = useState<boolean>(false);
  const [totalPages, setPages] = useState<number>(0);
  const [totalElements, setTotalElements] = useState<number>(0);

  const [isWithTemplateModal, setIsWithTemplateModal] = useState<boolean>(false);
  const [selectedTemplate, setSelectedTemplate] = useState<TGetListResponse | null>(null);

  const [projectDownload, setProjectDownload] = useState<{
    projectId: number;
    downloading: boolean;
  }>({
    projectId: 0,
    downloading: false,
  });
  const projectsSort = useSortSearch(FOLDER_SORT[0]);
  const debouncedSearchValue = useDebounce(searchValue as string, 500);

  const handleGetProjects = (
    searchValue?: string,
    page?: number,
    sortType?: ASCSort,
    selectedOption?: string,
  ) => {
    setLoadingProjects(true);
    getProjects(searchValue, sortType, page, selectedOption)
      .then(({ list, totalPages, size }) => {
        setPages(totalPages);
        setTotalElements(size);
        projectsSort.setHasMore(!(list.length < 24));
        setProjects(list);
        setLoadingProjects(false);
        const refs = list.reduce(
          (acc, item: IProject) => ({ ...acc, [item.id]: React.createRef() }),
          {},
        );
        setRefList(refs);
      })
      .catch(() => setProjects([]))
      .finally(() => {
        setLoadingProjects(false);
      });
  };

  const handleOpenProjectNameModal = () => {
    if (rate) {
      if (
        rate.name !== RateNames.UNLIMITED &&
        (rate.availableProjectCount <= 0 || rate.existingProjectCount >= rate.projectLimit)
      ) {
        setIsRateOpen(true);
      } else {
        setIsNameModalOpen(true);
      }
    }
  };

  const handleRenameClick = (id: number) => {
    setEnableHover(false);
    setChosenProjectId(id);
    setForceDisablePopover(true);
    refList &&
      setTimeout(() => {
        const valueLength = refList[id].current.value.length;
        refList[id].current.focus();
        refList[id].current.setSelectionRange(valueLength, valueLength);
        setForceDisablePopover(false);
      }, 0);
  };

  const handleProjectNameChange = (project: IProject, projectName?: string) => {
    if (projectName && chosenProjectId && projectName !== project.projectName) {
      renameProject(chosenProjectId, projectName)
        .then(() =>
          handleGetProjects(
            debouncedSearchValue as string,
            projectsSort.page,
            projectsSort.sortAsc,
            projectsSort.selectedOption.value,
          ),
        )
        .finally(() => {
          setChosenProjectId(0);
          setEnableHover(true);
        });
    } else {
      setTimeout(() => {
        setChosenProjectId(0);
        setEnableHover(true);
      }, 500);
    }
  };

  const handleMoveToFolder = (id: number) => {
    setChosenFolderId(-1);
    setShowModal(true);
    setChosenProjectId(id);
    const folderId = (projects || []).find((item) => item.id === id)?.folderId;
    folderId && setChosenFolderId(folderId);
  };

  const handleDuplicate = (id: number) => {
    cloneProject(id).then((res) => {
      const { success, exceptionType } = res;
      if (success) {
        handleGetProjects(
          debouncedSearchValue as string,
          projectsSort.page,
          projectsSort.sortAsc,
          projectsSort.selectedOption.value,
        );
        updateUserDetails();
      } else exceptionType === 'LIMIT_EXCEEDED' && setIsRateOpen(true);
    });
  };

  const handleMoveToTrash = (id: number) => {
    setChosenProjectId(id);
    deleteProject(id).then(() => {
      updateUserDetails();
      setNotification({
        text: 'Вы перенесли проект в корзину',
        handleFunction: () => handleRecoverItem(id),
        buttonText: 'Отменить',
        handleClose: handleCloseNotification,
      });
      handleGetProjects(
        debouncedSearchValue as string,
        projectsSort.page,
        projectsSort.sortAsc,
        projectsSort.selectedOption.value,
      );
    });
  };

  const handleRecoverItem = (id?: number) => {
    recoverItem(id || chosenProjectId, 'PROJECT')
      .then(() => {
        handleGetProjects(
          debouncedSearchValue as string,
          projectsSort.page,
          projectsSort.sortAsc,
          projectsSort.selectedOption.value,
        );
        closeNotification();
        updateUserDetails();
      })
      .finally(() => setChosenProjectId(0));
  };

  const handleProjectClick = (project: IProject) => {
    if (chosenProjectId !== project.id) {
      navigate(`/project/${project.id}`);
    }
  };

  const handleDownloadProject = (id: number) => {
    if (rate?.name === RateNames.TRIAL) {
      setIsRateOpen(true);
      return;
    }
    setProjectDownload({
      projectId: id,
      downloading: true,
    });

    getProject(id).then((res) => {
      res.data &&
        downloadProject(
          res.data.projectItems.map((item) => item.details),
          res.data.projectName,
        ).finally(() =>
          setProjectDownload({
            projectId: id,
            downloading: false,
          }),
        );
    });
  };

  useEffect(() => {
    if (projectsSort.sortAsc === 'ASC') projectsSort.setSortAsc('DESC');
    else
      didMount &&
        handleGetProjects(
          debouncedSearchValue as string,
          projectsSort.page,
          projectsSort.sortAsc,
          projectsSort.selectedOption.value,
        );
  }, [projectsSort.selectedOption.value]);

  useEffect(() => {
    if (didMount && searchValue === null) {
      handleGetProjects(
        debouncedSearchValue as string,
        projectsSort.page,
        projectsSort.sortAsc,
        projectsSort.selectedOption.value,
      );
    }
  }, [debouncedSearchValue, projectsSort.page, projectsSort.sortAsc, didMount]);

  useEffect(() => {
    if (didMount && debouncedSearchValue !== null) {
      handleGetProjects(
        debouncedSearchValue as string,
        projectsSort.page,
        projectsSort.sortAsc,
        projectsSort.selectedOption.value,
      );
    }
  }, [debouncedSearchValue, projectsSort.page, projectsSort.sortAsc]);

  useEffect(() => {
    setDidMount(true);
  }, []);

  const createCard = (
    <Components.Column withOutline={true}>
      <Card color={'gray'} onClick={handleOpenProjectNameModal}>
        <Flex gap={4} mb={10}>
          <Components.Thumbnail squared clickable scalableImage>
            <img src={plus} alt="plus" className="scalable" />
          </Components.Thumbnail>
        </Flex>
        <Text color={'gray'}>{t('myProjects.new')}</Text>
      </Card>
    </Components.Column>
  );

  const handleCloseNotification = () => {
    setChosenFolderId(0);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setChosenProjectId(0);
    setChosenFolderId(0);
    setForceDisablePopover(true);
    setTimeout(() => {
      setForceDisablePopover(false);
    }, 0);
  };

  useEffect(() => {
    if (userData) {
      setRate(userData.rate);
    }
  }, [userData]);

  const projectOptions = (id: number) => {
    return (
      <FlexCol gap={6} alignItems={'flex-start'} style={{ width: 'max-content' }}>
        <CustomButton onClick={() => handleRenameClick(id)}>{t(`action.rename`)}</CustomButton>
        <CustomButton onClick={() => handleMoveToFolder(id)}>
          {t(`action.moveToFolder`)}
        </CustomButton>
        <CustomButton onClick={() => handleDuplicate(id)}>{t(`action.duplicate`)}</CustomButton>
        <CustomButton onClick={() => handleMoveToTrash(id)}>{t(`action.toTrash`)}</CustomButton>
      </FlexCol>
    );
  };

  if (rate && rate?.name !== RateNames.TRIAL) {
    const today = new Date().getTime();
    const endDate = new Date(rate?.expireDate).getTime();
    const difference = Math.floor((endDate - today) / (1000 * 60 * 60 * 24)) + 1;
    if (difference < 1) {
      return <RateOverlay rate={rate} />;
    }
  }

  if (rate && rate?.name === RateNames.TRIAL) {
    const today = new Date().getTime();
    const endDate = new Date(rate?.expireDate).getTime();
    const difference = Math.floor((endDate - today) / (1000 * 60 * 60 * 24)) + 1;
    if (
      difference < 1 ||
      (rate.existingProjectCount === 0 && rate.availableProjectCount !== 0) ||
      (rate.existingProjectCount === 0 &&
        rate.availableProjectCount === 0 &&
        rate.inTrashProjectCount === 0)
    ) {
      return <RateOverlay rate={rate} />;
    }
  }

  if (!rate) return null;

  return (
    <>
      <Row adaptive alignItems={'flex-start'}>
        <MenuList rate={rate} />
        <RightColumn>
          <div className="templates">
            <TemplateHeader
              rate={rate}
              setIsWithTemplateModal={setIsWithTemplateModal}
              setSelectedTemplate={setSelectedTemplate}
              setTemplateList={setTemplateList}
              templateList={templateList}
            />
          </div>
          <PageHeader
            pageTitle={'Все проекты'}
            sortType="folderSort"
            selectedOption={projectsSort.selectedOption}
            setSelectedOption={projectsSort.setSelectedOption}
            setSortOrder={projectsSort.setSortAsc}
            sortOrder={projectsSort.sortAsc}
            highlight={highlight}
            setHighlight={setHighlight}
            count={totalElements}
            isLoading={loadingProjects}
          />
          <Row adaptive>
            {loadingProjects ? (
              <ProjectListLoader />
            ) : (
              <>
                {createCard}
                {(projects || []).length === 0 && debouncedSearchValue && (
                  <>
                    Тут по вашему запросу ничего не найдено. Посмотрите в других вкладках или
                    повторите попытку, набрав другие слова.
                  </>
                )}
                {(projects || []).map((project) => (
                  <Components.Column
                    key={project.id}
                    active={highlightedProjects.includes(project.id)}
                    highlight={highlight}
                    onClick={() => {
                      !highlight && enableHover && handleProjectClick(project);
                    }}
                    withOutline={true}
                  >
                    <CardCheckboxOverlay
                      cardId={project.id}
                      highlight={highlight}
                      highlightedCards={highlightedProjects}
                      setHighlightedCard={setHighlightedProjects}
                      isRightMouseDown={isRightMouseDown}
                    />
                    <Card
                      onDownload={() =>
                        !projectDownload.downloading && handleDownloadProject(project.id)
                      }
                      customOptions={chosenProjectId !== project.id && projectOptions(project.id)}
                      forceDisablePopover={forceDisablePopover}
                      enableHover={enableHover}
                      projectDownloading={
                        projectDownload.projectId === project.id && projectDownload.downloading
                      }
                    >
                      <Flex gap={4}>
                        <Components.Thumbnail
                          squared
                          url={
                            project.projectItems[0].modifiedImageId
                              ? `/api/project/load/image/png?imageId=${project.projectItems[0].modifiedImageId}&imageSizeType=NORMAL`
                              : ''
                          }
                        />
                      </Flex>
                      <div style={{ margin: '20px 0' }}>
                        {project.folderName && project.folderName?.length > 0 && (
                          <Text fontSize="14px" color="infoGray" ellipsis>
                            {project.folderName}
                          </Text>
                        )}
                        {chosenProjectId !== project.id ? (
                          <Text
                            truncate
                            rows={project.folderName ? 2 : 3}
                            style={{
                              wordBreak: 'break-all',
                              padding: '2px',
                              lineHeight: '21.86px',
                            }}
                          >
                            {project.projectName || t('myProjects.withoutName')}
                          </Text>
                        ) : (
                          <ProjectNameInput
                            key={project.id}
                            defaultValue={project.projectName || t('myProjects.withoutName')}
                            rows={project.folderName ? 2 : 3}
                            disabled={chosenProjectId !== project.id}
                            maxLength={100}
                            ref={refList[project.id]}
                            onBlur={(e: React.FocusEvent<HTMLInputElement>) =>
                              project.id === chosenProjectId &&
                              handleProjectNameChange(project, e.target.value)
                            }
                            onClick={(e: React.MouseEvent) => e.stopPropagation()}
                          />
                        )}
                      </div>
                    </Card>
                  </Components.Column>
                ))}
              </>
            )}
          </Row>
          {totalPages > 0 && (
            <PagePagination
              totalCount={totalPages}
              page={projectsSort.page}
              setPage={projectsSort.setPage}
            />
          )}
        </RightColumn>
      </Row>

      {highlight && (
        <MultipleChoice
          refreshRequest={() =>
            handleGetProjects(
              debouncedSearchValue as string,
              projectsSort.page,
              projectsSort.sortAsc,
              projectsSort.selectedOption.value,
            )
          }
          type="project"
          choiceList={projects}
          setHighlightedIds={setHighlightedProjects}
          highlightedList={highlightedProjects}
          folderId={chosenFolderId}
          setShowModal={setShowModal}
          setHighlight={setHighlight}
          itemType={'PROJECT'}
          handleOpenModal={() => setIsRateOpen(true)}
        />
      )}
      <MoveToFolderModal
        setHighlight={setHighlight}
        setHighlightedProjects={setHighlightedProjects}
        highlight={highlight}
        highlightedProjects={highlightedProjects}
        closeModal={handleCloseModal}
        isOpen={showModal}
        projects={projects}
        fetchProjects={() => {
          handleGetProjects(
            debouncedSearchValue as string,
            projectsSort.page,
            projectsSort.sortAsc,
            projectsSort.selectedOption.value,
          );
        }}
        chosenProjectId={chosenProjectId}
        chosenFolderId={chosenFolderId}
        setChosenFolderId={setChosenFolderId}
      />
      {templateList.length > 0 && (
        <NewProjectModal
          onClose={() => setIsNameModalOpen(false)}
          isOpen={isNameModalOpen}
          trackKey="ecom_webapp_create_project_from_main_page"
          setIsOpen={setIsNameModalOpen}
          setIsAdditionalModalOpen={setIsRateOpen}
          templateList={templateList}
        />
      )}
      {selectedTemplate && (
        <NewProjectWithTemplateModal
          onClose={() => setIsWithTemplateModal(false)}
          isOpen={isWithTemplateModal}
          trackKey="ecom_webapp_create_project_from_main_page"
          setIsOpen={setIsWithTemplateModal}
          setIsAdditionalModalOpen={setIsRateOpen}
          selectedTemplate={selectedTemplate}
        />
      )}
      <RateModal isOpen={isRateOpen} onClose={() => setIsRateOpen(false)} />
    </>
  );
};

import styled, { keyframes } from 'styled-components';
import { Headline } from '../../components/headline.component';
import { Text } from '../../components/text.component';

export const Wrapper = styled.div`
  height: 100vh;
  display: flex;
  & > ${Headline}, ${Text} {
    color: ${(p) => p.theme.white};
  }
  & > ${Headline} {
    margin-bottom: 0.625rem;
  }
`;

export const SparkleWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  margin-top: 1.875rem;
`;

const ColumnTemplate = styled.div`
  position: relative;
  min-height: 1px;
  display: grid;
`;

const moveleft = keyframes`
  0%   {right: 40%; max-width: 60%; }
  50%  {right: 20%; max-width: 40%; }
  100% {right: 0%; max-width: 60%; }
`;

const moveright = keyframes`
  0%   {left: 60%; max-width: 40%; flex: 0 0 40%;}
  50%  {left: 20%; max-width: 60%; flex: 0 0 60%;}
  100% {left: 0%; max-width: 40%; flex: 0 0 40%;}
`;

const hide = keyframes`
  0% { opacity: 0 }
  100% { opacity: 1 }
`;

const buttonanimation = keyframes`
  0% { width: auto }
  50%  {width: 220px}
  100% { width: auto }
`;

export const ColumnContent = styled.div`
  display: flex;
  width: 360px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const LeftColumn = styled(ColumnTemplate)`
  flex: 0 0 40%;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: ${(p) =>
    `linear-gradient(0deg, ${p.theme.primary}, ${p.theme.primary}), ${p.theme.white}`};
  animation: ${moveright} 1s ease-in forwards;
  & > * {
    animation: ${hide} 2s ease-in forwards;
  }
  & > ${ColumnContent} > ${SparkleWrapper} > button {
    animation: ${buttonanimation} 1s ease-in-out forwards;
  }
`;

export const RightColumn = styled(ColumnTemplate)`
  flex: 0 0 60%;
  max-width: 60%;
  justify-content: center;
  align-content: center;
  animation: ${moveleft} 1s ease-in forwards;
  & > * {
    animation: ${hide} 2s ease-in-out forwards;
  }
  & > img {
    position: absolute;
    top: 13px;
    left: 20px;
    height: 3.75rem;
    filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.25));
  }
`;

export const WhiteBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #ffffff;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
  width: 380px;
  height: max-content;
  padding: 20px;
  gap: 20px;
`;
export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  gap: 20px;
  width: 100%;
`;

export const ErrorWrapper = styled.div`
  & > ${Text} {
    color: ${(p) => p.theme.black};
  }
  display: flex;
  flex-direction: column;
`;

const components = {
  Wrapper,
  SparkleWrapper,
  LeftColumn,
  RightColumn,
  Form,
  ErrorWrapper,
};

export default components;

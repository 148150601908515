import React, { InputHTMLAttributes, useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import { useCanvasFocus } from '../contexts/canvas-focus.context';

const Wrapper = styled.div<{ width: number }>`
  position: relative;
  width: ${(p) => `${p.width}px`};
  &::after {
    content: '°';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    color: ${(p) => p.theme.black};
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.375em;
  }
`;

const Line = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  display: inline-block;
  color: transparent;
  height: 2px;
  background-color: ${(p) => p.theme.lightPrimary};
  border-radius: 1px;
  opacity: 0;
  transition: opacity ease-in-out 150ms;
  overflow: hidden;
`;

const Input = styled.input`
  position: relative;
  padding: 0;
  border: 0;
  background: transparent;
  color: ${(p) => p.theme.black};
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.375em;
  -moz-appearance: textfield;
  &:hover,
  &:focus {
    outline: none;
    & + ${Line} {
      opacity: 1;
    }
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const AngleInput = (props: InputHTMLAttributes<any>) => {
  const { setIsFocused } = useCanvasFocus();

  const lineRef = useRef<HTMLDivElement | null>(null);
  const [width, setWidth] = useState(0);

  useEffect(() => {
    setIsFocused(false);
  }, []);

  useEffect(() => {
    if (lineRef?.current?.offsetWidth !== undefined) {
      setWidth(lineRef?.current?.offsetWidth);
    }
  }, [lineRef, props.value]);

  return (
    <Wrapper width={width}>
      <Input {...props} type="number" max={360} size={3} />
      <Line ref={lineRef}>{props.value}°</Line>
    </Wrapper>
  );
};

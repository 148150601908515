import React, {
  useState,
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
  Dispatch,
  SetStateAction,
} from 'react';
import { FabricJSEditor } from 'fabricjs-react';
import FabricTypes from 'fabric/fabric-impl';
import { AnyColor, colord } from 'colord';
import { Gradient, Pattern } from 'fabric/fabric-impl';
import { useTranslation } from 'react-i18next';

import {
  ColorType,
  BackgroundType,
  IGradient,
  LinearGradientType,
  RadialGradientType,
} from '../../interfaces/editor.interface';
import { useShared } from '../../contexts/shared.context';
import useDebounce from '../../hooks/use-debounce.hooks';
import { useCanvasHistory } from '../../contexts/canvas-history.context';
import Popover from '../popover.component';
import { ToolbarBtn } from '../toolbar/toolbar.style';
import { BG_TYPES } from '../../constants';
import Dropdown from '../dropdown.component';
import { LinearGradient } from './linear-gradient.component';
import { RadialGradient } from './radial-gradient.component';
import { ColorHeader, ColorIcon, HaveBGText } from './color-popover.style';
import {
  disableEyeDrop,
  handleEyeDrop,
  handleEyeDropGradient,
  handleSetGradientBackground,
} from '../../utils/editor.utils';
import FillBackGround from './fill.component';
import { NumberInput as ColorPickerInput } from '../number-input.component';
import { Text } from '../text.component';
import { useCanvasFocus } from '../../contexts/canvas-focus.context';

interface IProps {
  editor: FabricJSEditor;
  children?: React.ReactNode;
  refreshProjectCard?: VoidFunction;
  handleChangeColor: (color: string, focusOnTextbox?: boolean) => void;
  initialFill?: string | Gradient | Pattern;
  colorType: ColorType;
  tooltipPosition?: string;
  tooltipText?: string;
  showTransparentBtn?: boolean;
  lightTheme?: boolean;
  setDisableParentPopoverClose?: Dispatch<SetStateAction<boolean>>;
  colorPopoverName?: string;
  typeKey: string;
}

export const ColorPopover = forwardRef(function ColorPopover(
  {
    editor,
    children,
    refreshProjectCard,
    handleChangeColor,
    initialFill,
    colorType,
    tooltipPosition = 'right',
    tooltipText = 'action.backgroundColor',
    showTransparentBtn = false,
    lightTheme = false,
    setDisableParentPopoverClose,
    colorPopoverName,
    typeKey,
  }: IProps,
  ref,
) {
  const { historySaveAction } = useCanvasHistory();
  const popoverRef = useRef<{ close: () => void }>();
  const { popoverActive, setPopoverActive } = useShared();
  const [isEdited, setIsEdited] = useState<boolean>(false);
  const [bgType, setBgType] = useState<BackgroundType>('fill');
  const [color, setColor] = useState<string>('');
  const [hexColor, setHexColor] = useState<string>('#FFFFFF');
  const [alpha, setAlpha] = useState<number>(1);
  const [gradient, setGradient] = useState<IGradient | undefined>();
  const [gradientParams, setGradientParams] = useState<object>();
  const [enableColorEyeDrop, setEnableColorEyeDrop] = useState<boolean>(false);
  const [eyeDropColor, setEyeDropColor] = useState<string | null>(null);
  const [disablePopoverClose, setDisablePopoverClose] = useState<boolean>(false);
  const [inputColor, setInputColor] = useState<string>('');
  const [isTypingColor, setIsTypingColor] = useState<boolean>(false);
  // gradient colors
  const [gradientColors, setGradientColors] = useState<string[]>([]);
  const [activeColorIndex, setActiveColorIndex] = useState<number>(0);
  const { setIsInputFocused } = useCanvasFocus();

  const debouncedColor = useDebounce(color, 1000);
  const debouncedHexColor = useDebounce(hexColor, 1000);
  const [didMount, setDidMount] = useState(false);
  const { t } = useTranslation();
  const activeObject = editor?.canvas?.getActiveObject();

  useEffect(() => {
    if (didMount && editor && refreshProjectCard) {
      refreshProjectCard();
    }
  }, [debouncedHexColor]);

  useEffect(() => {
    if (bgType !== 'fill') {
      if (gradient && gradient.options?.colorStops)
        setGradientColors([
          gradient.options?.colorStops[0].color,
          gradient.options?.colorStops[1].color,
        ]);
      else {
        setGradientColors([color, 'rgba(0, 0, 0, 1)']);
      }
    }
  }, [bgType]);

  useEffect(() => {
    const hexColor = rgbaToHex(gradientColors[activeColorIndex]);
    hexColor && bgType !== 'fill' && setInputColor(hexColor);
  }, [activeColorIndex, bgType, gradientColors]);

  useEffect(() => {
    if (initialFill && initialFill !== 'transparent') {
      if (typeof initialFill === 'string' && bgType === 'fill') {
        setBgType('fill');
        if (initialFill.includes('rgba')) {
          setColor(initialFill);
        } else {
          hexToRgba(initialFill);
        }
      } else {
        const grad = initialFill as Gradient;
        const colorStops = grad.colorStops;
        const type = grad.type as 'linear' | 'radial';
        if (colorStops?.length) {
          const colors = colorStops.map((item) => item.color);
          const gradientRange = { start: colorStops[0].offset, end: colorStops[1].offset };
          const activeObject = editor.canvas.getActiveObject();
          const name: string = activeObject?.paintFirst as string;
          const index = name.indexOf('_') + 1;
          const gradientType = name.substring(index, name.length) as
            | LinearGradientType
            | RadialGradientType;

          const reloadedGradient = handleSetGradientBackground(
            editor.canvas,
            type,
            colors,
            gradientRange,
            gradientType,
            colorType,
          );
          setBgType(type);
          setGradient(reloadedGradient);
        }
      }
    } else if (initialFill === 'transparent') {
      setBgType('fill');
      setColor('transparent');
      setHexColor('transparent');
    }
  }, [initialFill, activeObject]);

  useImperativeHandle(
    ref,
    () => {
      return {
        setBgType: (type: BackgroundType) => {
          setBgType(type);
        },
        setColor: (colorStr: string) => {
          setColor(colorStr);
        },
        setGradient: (grad: IGradient) => {
          setGradient(grad);
        },
        reloadGradient: () => {
          if (bgType !== 'fill' && gradient?.options?.colorStops) {
            const colorStops = gradient?.options?.colorStops;
            const colors = colorStops.map((item) => item.color);
            const gradientRange = { start: colorStops[0].offset, end: colorStops[1].offset };

            const reloadedGradient = handleSetGradientBackground(
              editor.canvas,
              bgType,
              colors,
              gradientRange,
              gradient.type,
              colorType,
            );
            setGradient(reloadedGradient);
          }
        },
      };
    },
    [editor, bgType, gradient, colorType],
  );

  useEffect(() => {
    if (gradient?.options?.colorStops && gradient.options.colorStops.length > 0) {
      setGradientParams({
        type: gradient.type,
        colors: [gradient.options.colorStops[0]?.color, gradient.options.colorStops[1]?.color],
      });
    }
  }, [gradient]);

  useEffect(() => {
    if (debouncedColor && isEdited) {
      historySaveAction(editor);
    }
  }, [debouncedColor]);

  useEffect(() => {
    if (editor && color && colorType === 'background') {
      setGradient(undefined);
    }
  }, [color]);

  useEffect(() => {
    if (bgType === 'fill' && gradient?.options?.colorStops) {
      handleChange(gradient?.options?.colorStops[0]?.color);
    }
  }, [bgType]);

  const rgbaToHex = (rgba: string) => {
    if (rgba) {
      const [r, g, b] = rgba
        .substring(5, rgba.length - 1)
        .split(',')
        .map((item) => parseFloat(item));

      return colord({ r, g, b }).toHex().toUpperCase();
    }
    return rgba;
  };

  const removeGradient = () => {
    const { canvas } = editor;
    const bgGradient =
      colorType === 'background' &&
      canvas._objects?.find((obj) => obj.name?.includes('backgroundGradient'));
    if (bgGradient) {
      canvas.remove(bgGradient);
    }
  };
  // TODO: with not reset, need to do same for linear and radial gradient
  const handleChange = (rgbaColor: string) => {
    removeGradient();
    const [r, g, b, a] = rgbaColor
      .substring(5, rgbaColor.length - 1)
      .split(',')
      .map((item) => parseFloat(item));
    setAlpha(a);
    setHexColor(colord({ r, g, b }).toHex().toUpperCase());
    if (!enableColorEyeDrop) {
      setColor(rgbaColor);
      handleChangeColor(rgbaColor);
    }
    setIsEdited(true);
  };

  useEffect(() => {
    if (!color) {
      return;
    }
    if (color.includes('rgba')) {
      const [r, g, b, a] = color
        .substring(5, color.length - 1)
        .split(',')
        .map((item) => parseFloat(item));
      setAlpha(a);
      setHexColor(colord({ r, g, b }).toHex().toUpperCase());
    } else {
      setHexColor(color);
    }
  }, [color]);

  const setAlphaText = () => {
    const hue = document.querySelector('div.react-colorful__alpha');
    const div = document.createElement('div');
    div.className = 'react-colorful__alpha__text';
    div.innerText = `${parseInt(String(alpha * 100))}%`;
    if (hue?.childNodes?.length === 2) {
      hue?.appendChild(div);
    } else {
      hue?.replaceChild(div, hue?.childNodes[2]);
    }
  };

  useEffect(setAlphaText, [alpha]);

  const handleHexToRgba = (hexColorStr: string, focusOnTextbox = true): void => {
    const rgbaColor = hexToRgba(hexColorStr);
    if (rgbaColor) {
      handleChangeColor(rgbaColor, focusOnTextbox);
    }
  };

  const convertHexToRgba = (hexColorStr: string) => {
    const { r, g, b, a } = colord(hexColorStr as AnyColor).toRgb();
    return `rgba(${r}, ${g}, ${b}, ${a})`;
  };

  const handleGradient = (hexColorStr: string): void => {
    const rgbaColor = convertHexToRgba(hexColorStr);
    if (rgbaColor && hexColorStr.length === 7) {
      const colorsCopy = [...gradientColors];
      colorsCopy[activeColorIndex] = rgbaColor;
      setGradientColors(colorsCopy);
    }
  };

  const hexToRgba = (hexColorStr: string) => {
    removeGradient();
    const { r, g, b, a } = colord(hexColorStr as AnyColor).toRgb();
    const rgbaColor = `rgba(${r}, ${g}, ${b}, ${a})`;
    setColor(rgbaColor);
    setAlpha(1);
    setHexColor(hexColorStr);
    setIsEdited(true);
    return rgbaColor;
  };

  useEffect(() => {
    if (refreshProjectCard && popoverActive[typeKey] === false) {
      historySaveAction(editor);
      refreshProjectCard();
      setIsEdited(false);
    }
  }, [popoverActive[typeKey]]);

  const _handleEyeDrop = (gradient?: boolean) => {
    const { canvas } = editor;
    setEnableColorEyeDrop((prev) => !prev);
    const activeObject = canvas.getActiveObject();
    const objects = canvas.getObjects();
    objects.forEach((obj) => obj.set({ selectable: false }));

    const eyeDropFunction = (isClosePopover: boolean | undefined) => {
      setEnableColorEyeDrop(false);
      objects
        .filter(
          (obj) =>
            obj.name !== 'grid' &&
            obj.name !== 'watermark' &&
            !obj.name?.includes('backgroundImage') &&
            !obj.name?.includes('backgroundGradient'),
        )
        .forEach((obj) => obj.set({ selectable: true }));
      canvas.setActiveObject(activeObject as FabricTypes.Object);
      if (isClosePopover) {
        popoverRef?.current?.close();
      }
    };

    if (!gradient) {
      handleEyeDrop(canvas, setEyeDropColor, eyeDropFunction);
    } else {
      handleEyeDropGradient(canvas, setGradientColors, eyeDropFunction, activeColorIndex);
    }
  };

  useEffect(() => {
    setDisablePopoverClose(enableColorEyeDrop);
    if (setDisableParentPopoverClose) {
      setDisableParentPopoverClose(enableColorEyeDrop);
    }
  }, [enableColorEyeDrop]);

  useEffect(() => {
    if (!enableColorEyeDrop) {
      if (eyeDropColor) {
        setColor(eyeDropColor);
        handleChangeColor(eyeDropColor, true);
        setEyeDropColor(null);
        setIsEdited(true);
      } else {
        disableEyeDrop(editor?.canvas);
      }
    }
  }, [enableColorEyeDrop, eyeDropColor]);

  useEffect(() => {
    setTimeout(() => setDidMount(true), 1500);
  }, []);

  useEffect(() => {
    !isTypingColor && setInputColor(hexColor);
  }, [hexColor]);

  const handleSetPopoverActive = (value: boolean) => {
    setPopoverActive((prevState) => ({ ...prevState, [typeKey]: value }));
  };

  const haveBgImage = editor?.canvas
    ?.getObjects()
    ?.some((obj) => obj.name?.includes('backgroundImage'));

  return (
    <Popover
      ref={popoverRef}
      type={'cloud'}
      position={colorType === 'background' ? 'right-bottom' : 'left'}
      setIsActive={handleSetPopoverActive}
      closeOnClick={false}
      className={popoverActive[typeKey] ? 'active' : ''}
      onOpen={setAlphaText}
      disableOutsideClick={disablePopoverClose}
      relativeToParent={true}
      contentClassName={'colorPopover'}
      content={
        <>
          <ColorHeader>
            {colorType !== 'stroke' ? (
              !colorPopoverName ? (
                <Dropdown
                  value={bgType}
                  handleChange={(value) => setBgType(value)}
                  options={BG_TYPES}
                  translateKey="editor.bgTypes"
                  design="corner-radius"
                />
              ) : (
                <Text small style={{ paddingLeft: '15px' }}>
                  {colorPopoverName}
                </Text>
              )
            ) : (
              <Text small padding="0.71875em 1em">
                {t('tooltip.strokeColor')}
              </Text>
            )}
            <ColorPickerInput
              fontSize="0.875rem"
              size={inputColor === 'transparent' ? 9 : 8}
              maxLength={7}
              value={inputColor === 'transparent' ? 'Прозрачный' : inputColor}
              onChange={(e) => {
                if (bgType === 'fill') {
                  handleHexToRgba(e.target.value, false);
                } else {
                  handleGradient(e.target.value);
                }
                setInputColor(e.target.value.toUpperCase());
              }}
              onFocus={() => {
                setIsTypingColor(true);
                setIsInputFocused(true);
              }}
              onBlur={() => {
                setIsTypingColor(false);
                setIsInputFocused(false);
              }}
            />
          </ColorHeader>
          {bgType === 'fill' && (
            <FillBackGround
              _handleEyeDrop={_handleEyeDrop}
              color={color}
              enableColorEyeDrop={enableColorEyeDrop}
              eyeDropColor={eyeDropColor}
              handleChange={handleChange}
              handleChangeColor={handleChangeColor}
              hexColor={hexColor}
              hexToRgba={handleHexToRgba}
              setColor={setColor}
              setHexColor={setHexColor}
              showTransparentBtn={showTransparentBtn}
            />
          )}
          {bgType === 'linear' && (
            <LinearGradient
              activeColorIndex={activeColorIndex}
              setActiveColorIndex={setActiveColorIndex}
              gradientColors={gradientColors}
              setGradientColors={setGradientColors}
              editor={editor}
              initialGradient={gradient}
              setGradient={setGradient}
              setDisablePopoverClose={setDisablePopoverClose}
              colorType={colorType}
              _handleEyeDrop={_handleEyeDrop}
            />
          )}
          {bgType === 'radial' && (
            <RadialGradient
              activeColorIndex={activeColorIndex}
              setActiveColorIndex={setActiveColorIndex}
              gradientColors={gradientColors}
              setGradientColors={setGradientColors}
              editor={editor}
              initialGradient={gradient}
              setGradient={setGradient}
              setDisablePopoverClose={setDisablePopoverClose}
              colorType={colorType}
              _handleEyeDrop={_handleEyeDrop}
            />
          )}
          {bgType && haveBgImage && (
            <HaveBGText>
              У вас есть фоновое изображение. <br />
              Чтобы увидеть изменения цвета фона, удалите его.
            </HaveBGText>
          )}
        </>
      }
    >
      <ToolbarBtn
        active={popoverActive[typeKey]}
        data-tooltip={popoverActive[typeKey] ? null : t(tooltipText)}
        data-tooltip-position={tooltipPosition}
        light={lightTheme}
      >
        {children ?? (
          <ColorIcon bgType={bgType} color={bgType === 'fill' ? color : ''} {...gradientParams} />
        )}
      </ToolbarBtn>
    </Popover>
  );
});

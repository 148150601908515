import React from 'react';
import styled from 'styled-components';
import { fabric } from 'fabric';
import { Textbox } from 'fabric/fabric-impl';
import { useTranslation } from 'react-i18next';
import { FabricJSEditor } from 'fabricjs-react';
import { handleLists } from '../utils/editor.utils';
import { ObjectWithId } from '../interfaces/editor.interface';

const PopoverListItem = styled.li<{ fontSize?: string; lineHeight?: string; fontWeight?: string }>`
  width: 100%;
  display: block;
  padding: 0.625rem 0.9375rem;
  font-size: ${(p) => (p.fontSize ? p.fontSize : '1rem')};
  line-height: ${(p) => (p.lineHeight ? p.lineHeight : '1.375rem')};
  font-weight: ${(p) => (p.fontWeight ? p.fontWeight : '500')};
  cursor: pointer;

  &:hover {
    background: #6620c766;
    color: #6620c7;
  }
`;

const PopoverList = styled.ul`
  display: flex;
  position: relative;
  z-index: 1;
  flex-direction: column;
  align-items: flex-start;
  width: 24.5rem;
  list-style: none;
  margin: 0;
  padding: 0;
  > li:not(:last-child) {
    border-bottom: 1px solid #d9d9d9;
  }
  > li:first-child {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }
  > li:last-child {
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }
`;

interface Props {
  addToCanvas: (textBox: ObjectWithId<Textbox>) => void;
  editor: FabricJSEditor;
}

type TextboxProperties = {
  name: string;
  text: string;
  lineHeight: number;
  top: number;
};

type TextboxTypes = {
  header: TextboxProperties;
  subheader: TextboxProperties;
  main: TextboxProperties;
  list: TextboxProperties;
  small: TextboxProperties;
};

const TexboxRelation: Record<string, string> = {
  subheader: 'header',
  main: 'subheader',
  list: 'main',
  small: 'list',
};

const TextPopover: React.FC<Props> = ({ addToCanvas, editor }) => {
  const { t } = useTranslation();
  const { canvas } = editor as FabricJSEditor;

  const textProps: TextboxTypes = {
    header: { name: 'header', text: 'Введите свой заголовок', lineHeight: 1.15, top: 40 },
    subheader: { name: 'subheader', text: 'Введите свой подзаголовок', lineHeight: 1.15, top: 190 },
    main: { name: 'main', text: 'Введите свой текст', lineHeight: 1.5, top: 280 },
    list: { name: 'list', text: 'Пункт 1 \nПункт 2 \nПункт 3', lineHeight: 1.5, top: 320 },
    small: { name: 'small', text: 'Введите свой текст', lineHeight: 1.5, top: 410 },
  };

  const addText =
    (textType: string, fontSize?: number, fontWeight?: number, listType?: string) => () => {
      document.fonts.load('10pt "Inter"').then(() => {
        const existingObjects = canvas.getObjects();
        let top = textProps[textType as keyof TextboxTypes]?.top;

        if (textType !== 'header') {
          const higherObjs = existingObjects.filter((obj) => obj.name === TexboxRelation[textType]);
          const bottom =
            higherObjs.length && higherObjs.length === 1
              ? (higherObjs[0].top || 0) + higherObjs[0].getScaledHeight()
              : Number.MAX_SAFE_INTEGER;
          top = bottom <= 500 ? Math.min(bottom, top) : top;
        }

        const textBox = new fabric.Textbox(
          textProps[textType as keyof TextboxTypes]?.text || 'type here...',
          {
            fill: '#000000',
            fontSize: fontSize ? fontSize : 16,
            fontWeight: fontWeight ? fontWeight : 500,
            fontFamily: 'Inter', // Указываем шрифт Inter
            textAlign: 'left',
            width: 410,
            strokeWidth: 0,
            name: textProps[textType as keyof TextboxTypes]?.name,
            globalCompositeOperation: JSON.stringify({ listType }),
            top: top + 20,
            left: canvas.width && canvas.width / 2 - 780,
            lineHeight: textProps[textType as keyof TextboxTypes]?.lineHeight || 1,
            isWrapping: true,
            textBackgroundColor: 'transparent',
            scaleY: 4,
            scaleX: 4,
          },
        );

        // textBox.on('changed', () => {
        //   if (textBox.getScaledWidth() > 409) {
        //     textBox.set('splitByGrapheme', true);
        //   }
        //   canvas.renderAll();
        // });

        if (textBox.text) {
          textBox.setSelectionStyles({ fontWeight: 'normal' }, 0, textBox.text.length);
          textBox.setSelectionStyles({ fontStyle: 'normal' }, 0, textBox.text.length);
          textBox.setSelectionStyles({ underline: false }, 0, textBox.text.length);
          textBox.setSelectionStyles({ linethrough: false }, 0, textBox.text.length);
        }

        fabric.util.clearFabricFontCache();
        handleLists(textBox, canvas).then(() => {
          addToCanvas(textBox);
        });
      });
    };

  return (
    <div>
      <PopoverList>
        <PopoverListItem
          fontSize={'3.5rem'}
          lineHeight={'4.375rem'}
          onClick={addText('header', 56)}
        >
          {t('textPopover.header')} 1
        </PopoverListItem>
        <PopoverListItem fontSize={'3rem'} lineHeight={'4.125rem'} onClick={addText('header', 48)}>
          {t('textPopover.header')} 2
        </PopoverListItem>
        <PopoverListItem fontSize={'2rem'} lineHeight={'2.5rem'} onClick={addText('subheader', 32)}>
          {t('textPopover.subheader')} 1
        </PopoverListItem>
        <PopoverListItem fontSize={'1.5rem'} lineHeight={'2rem'} onClick={addText('subheader', 24)}>
          {t('textPopover.subheader')} 2
        </PopoverListItem>
        <PopoverListItem fontSize={'1rem'} lineHeight={'1.375rem'} onClick={addText('main', 16)}>
          {t('textPopover.text')}
        </PopoverListItem>
        <PopoverListItem
          fontSize={'1rem'}
          lineHeight={'1.375rem'}
          onClick={addText('list', 16, 500, 'bullet')}
        >
          • {t('textPopover.list')}
        </PopoverListItem>
        <PopoverListItem
          fontSize={'1rem'}
          lineHeight={'1.375rem'}
          onClick={addText('list', 16, 500, 'numerated')}
        >
          1. {t('textPopover.numbered_list')}
        </PopoverListItem>
        <PopoverListItem
          fontSize={'0.75rem'}
          lineHeight={'1rem'}
          fontWeight={'600'}
          onClick={addText('small', 12, 600)}
        >
          {t('textPopover.small_text')}
        </PopoverListItem>
      </PopoverList>
    </div>
  );
};

export default TextPopover;

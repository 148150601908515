import React from 'react';
import Components from '../../pages/my-projects/my-projects.style';
import { Card } from '../card.component';
import { Flex, FlexCol } from '../flexbox.component';
import SkeletonLoader from '../skeleton-loader.component';

const ProjectListLoader: React.FC<{ count?: number }> = ({ count = 12 }) => {
  return (
    <>
      {Array.from({ length: count }).map((_, index) => (
        <Components.Column key={index.toString()}>
          <Card
            style={{
              background:
                'linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), rgb(255, 255, 255)',
            }}
          >
            <Flex>
              <SkeletonLoader width={'200px'} height={'200px'} />
            </Flex>
            <FlexCol gap={3} style={{ margin: '20px 0' }}>
              <SkeletonLoader width={'200px'} height={'20px'} />
              <SkeletonLoader width={'180px'} height={'20px'} />
              <SkeletonLoader width={'200px'} height={'20px'} />
            </FlexCol>
          </Card>
        </Components.Column>
      ))}
    </>
  );
};

export default ProjectListLoader;

export const resizeImage = (file: File, maxWidth: number, maxHeight: number): Promise<File> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = URL.createObjectURL(file);
    img.onload = () => {
      const canvas = document.createElement('canvas');
      let width = img.width;
      let height = img.height;

      if (width > height) {
        if (width > maxWidth) {
          height *= maxWidth / width;
          width = maxWidth;
        }
      } else {
        if (height > maxHeight) {
          width *= maxHeight / height;
          height = maxHeight;
        }
      }

      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext('2d');

      if (!ctx) {
        return reject();
      }

      ctx.drawImage(img, 0, 0, width, height);

      canvas.toBlob((blob) => {
        if (!blob) {
          return reject();
        }
        resolve(new File([blob], file.name, { type: file.type }));
      }, file.type);
    };
    img.onerror = reject;
  });
};

// export const checkImageSize = (
//   file: File,
//   setErrorCard?: (error: string) => void,
// ): Promise<boolean> => {
//   return new Promise((resolve, reject) => {
//     const reader = new FileReader();
//     reader.onload = (e: ProgressEvent<FileReader>) => {
//       if (e.target && typeof e.target.result === 'string') {
//         const img = new Image();
//         img.onload = () => {
//           if (img.width > 1680 || img.height > 1680) {
//             setErrorCard &&
//               setErrorCard(
//                 'Изображение превышает 1680 px по самой длинной стороне. Необходимо его уменьшить.',
//               );
//             resolve(false);
//           } else {
//             resolve(true);
//           }
//         };
//         img.onerror = reject;
//         img.src = e.target.result;
//       } else {
//         reject(new Error('Ошибка при чтении файла'));
//       }
//     };
//     reader.onerror = reject;
//     reader.readAsDataURL(file);
//   });
// };

import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { PagePaginationProps } from './pagination.types';

const PagePaginationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ButtonPagination = styled.div<{ disabled?: boolean }>`
  margin: 30px 0;
  background-color: #d9d9d9;
  font-size: 16px;
  font-weight: 600;
  line-height: 21.86px;
  text-align: center;
  padding: 14px 20px;
  border-radius: 16px;
  cursor: pointer;
  user-select: none;
  box-shadow: 0px 1px 2px 0px #00000040;
  transition: all 0.3s ease-in-out;
  color: ${({ disabled }) => disabled && '#00000033'};
  cursor: ${({ disabled }) => disabled && 'default'};
  display: flex;
  align-items: center;
  gap: 11px;

  &:hover {
    background-color: ${({ disabled }) => !disabled && '#6620C7'};
    color: ${({ disabled }) => (!disabled ? 'white' : '#00000033')};
  }
`;

const PageButtons = styled.div`
  display: flex;
  gap: 10px;
`;

const PageButton = styled.div<{ active?: boolean; more?: boolean }>`
  background-color: #d9d9d9;
  padding: 14px 20px;
  color: black;
  cursor: pointer;
  border-radius: 16px;
  box-shadow: 0px 1px 2px 0px #00000040;
  transition: all 0.3s ease-in-out;
  user-select: none;
  justify-content: center;
  align-items: center;

  ${(props) =>
    props.active &&
    `
		background-color: #6620c7;
		color: white;
	`}

  &:hover {
    background-color: #6620c7;
    color: white;
  }

  ${(props) =>
    props.more &&
    css`
      font-size: 20px;
      padding: 0;
      width: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
    `}
`;

const NUMBER_ITEMS = 5;

const PagePagination: React.FC<PagePaginationProps> = ({ page, setPage, totalCount }) => {
  if (totalCount <= 1) return null;

  const [currentState, setCurrentState] = useState<number>(NUMBER_ITEMS);
  const pagination: number[] = [];

  for (let i = 0; i < totalCount; i++) {
    pagination.push(i + 1);
  }

  const handleClickAhead = () => {
    if (page + 1 === currentState) setCurrentState((prev) => prev + NUMBER_ITEMS);
    page + 1 !== totalCount && setPage((prev) => prev + 1);
  };

  const handleClickBack = () => {
    if (page === currentState - NUMBER_ITEMS) setCurrentState((prev) => prev - NUMBER_ITEMS);
    page !== 0 && setPage((prev) => prev - 1);
  };

  return (
    <PagePaginationWrapper>
      <ButtonPagination disabled={page === 0} onClick={() => page !== 0 && handleClickBack()}>
        <span
          className="icon-arrow-left"
          style={{
            fontSize: '20px',
          }}
        ></span>
        <span>Назад</span>
      </ButtonPagination>
      <div
        style={{
          display: 'flex',
          gap: '10px',
        }}
      >
        {currentState != 5 && (
          <PageButton
            more
            onClick={() => {
              setCurrentState((prev) => prev - NUMBER_ITEMS);
            }}
          >
            <span className="icon-more" />
          </PageButton>
        )}
        <PageButtons>
          {pagination.slice(currentState - NUMBER_ITEMS, currentState).map((number) => (
            <PageButton
              active={page === number - 1}
              key={number}
              onClick={() => {
                setPage(number - 1);
              }}
            >
              {number}
            </PageButton>
          ))}
        </PageButtons>

        {currentState < totalCount && (
          <PageButton
            more
            onClick={() => {
              setCurrentState((prev) => prev + NUMBER_ITEMS);
            }}
          >
            <span className="icon-more" />
          </PageButton>
        )}
      </div>
      <ButtonPagination
        disabled={page + 1 === totalCount}
        onClick={() => !(page + 1 === totalCount) && handleClickAhead()}
      >
        <span>Дальше</span>
        <span
          style={{
            fontSize: '20px',
          }}
          className="icon-arrow-right"
        ></span>
      </ButtonPagination>
    </PagePaginationWrapper>
  );
};

export default PagePagination;

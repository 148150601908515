import api from './api';
import { fabric } from 'fabric';
import {
  ISuccessResponse,
  IDataSuccessResponse,
  ILibraryImages,
} from '../interfaces/projects.interface';
import { AxiosProgressEvent, AxiosRequestConfig } from 'axios';
type ProgressCallback = (percentage: number) => void;

export const deleteFile = (id: number): Promise<ISuccessResponse> => {
  return api.delete('library/delete', { data: { id } });
};

export const getImages = (
  name?: string,
  sortingType?: string,
  orderType?: string,
  page?: number,
  pageSize?: number,
): Promise<IDataSuccessResponse<ILibraryImages>> => {
  return api.get('library/list', { params: { name, sortingType, orderType, page, pageSize } });
};

export const moveToFolder = (folderId?: number, itemId?: number): Promise<ISuccessResponse> => {
  return api.post('library/moveToFolder', { folderId, itemId });
};

export const saveImage = (
  images: FileList | File[] | null,
  onUploadProgress?: ProgressCallback,
): Promise<ISuccessResponse> => {
  const formData = new FormData();

  images && Object.values(images).map((item: File) => formData.append('images', item));

  const config: AxiosRequestConfig = {
    onUploadProgress: (progressEvent: AxiosProgressEvent) => {
      const { loaded, total } = progressEvent;
      if (total) {
        const percent = Math.floor((loaded * 100) / total);
        onUploadProgress && onUploadProgress(percent);
      }
    },
  };
  return api.post('library/upload', formData, config);
};

export const checkImage = (file: File, image?: fabric.Image) => {
  const errorList: string[] = [];
  if (!(file.type.match('jpg') || file.type.match('jpeg') || file.type.match('png'))) {
    errorList.push('acceptedFormats');
  }
  if (file.size / 1000000 > 5) {
    errorList.push('maxImageSize');
  }
  if (image?.width && image.height && (image.width > 1680 || image.height > 1680)) {
    errorList.push('maxImageHeightWidth');
  }

  return errorList;
};

export const deleteSelectedFiles = (imageIds: number[]) => {
  return api.delete('library/delete/selected', { data: { ids: imageIds } });
};

export const moveToFolderFiles = (imageIds: number[], folderId: number) => {
  return api.post('library/moveToFolder/selected', { folderId, itemIds: imageIds });
};

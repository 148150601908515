import React, { MouseEvent } from 'react';
import { Flex } from './flexbox.component';
import styled from 'styled-components';

const TagWrapper = styled(Flex)`
  background: #6620c766;
  color: #6620c7;
  padding: 6.5px 10px;
  width: fit-content;
  border-radius: 8px;
`;

const RemoveTagIcon = styled.span`
  transform: rotate(45deg);
  font-size: 20px;
  color: #6620c766;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 22px;
  margin-left: 6px;
  cursor: pointer;
`;

const Tags: React.FC<{
  tag: string | EventTarget;
  handleRemove: (e: MouseEvent<HTMLButtonElement>) => void;
}> = ({ tag, handleRemove }) => {
  return (
    <TagWrapper alignItems="center">
      <div style={{ wordBreak: 'break-all' }}>{tag as string}</div>
      <RemoveTagIcon className="icon-plus" onClick={handleRemove}></RemoveTagIcon>
    </TagWrapper>
  );
};

export default Tags;

import styled, { css } from 'styled-components';
import { Text } from '../../components/text.component';
import { Card } from '../../components/card.component';

export const Wrapper = styled.div`
  display: flex;
  gap: 10px;
`;

export const WhiteHeader = styled.div<{ height?: string }>`
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  background: white;
  width: 100%;
  gap: 10px;
  padding: 20px;
  min-height: ${(p) => p.height || 'fit-content'};
`;

export const FoldersWrapper = styled.ul`
  display: flex;
  flex-direction: row;
  list-style: none;
  justify-content: space-between;
  margin: inherit;
  padding: 0;
  & > div {
    display: flex;
    gap: 6px;
  }
  height: 45px;
`;

export const CustomButton = styled.button<{ active?: boolean }>`
  background: ${(p) => (p.active ? p.theme.lightPrimaryGradient : p.theme.grayGradient)};
  border-radius: 16px;
  width: max-content;
  height: 45px;
  border: none;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: ${(p) => (p.active ? p.theme.primary : p.theme.black)};
  &.active,
  &:hover {
    color: ${(p) => p.theme.primary};
    background: linear-gradient(0deg, rgba(102, 32, 199, 0.4), rgba(102, 32, 199, 0.4)),
      ${(p) => p.theme.white};
  }
  padding: 13px 16px;
  cursor: pointer;

  &:disabled {
    color: ${(p) => p.theme.lightBlack};

    &:hover {
      color: ${(p) => p.theme.lightBlack};
      background: ${(p) => p.theme.grayGradient};
      cursor: default;
    }
  }
`;
export const PageWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
  & > ${Text} {
    color: rgba(0, 0, 0, 0.4);
  }
`;

export const ContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  & > ${Text} {
    color: rgba(0, 0, 0, 0.4);
  }
`;

const ColumnTemplate = styled.div`
  position: relative;
  min-height: 1px;
  display: block;
`;

export const Column = styled(ColumnTemplate)`
  max-width: 50%;
  @media (min-width: 1280px) {
    max-width: 50%;
  }
`;

export const ProjectColumn = styled(ColumnTemplate)`
  max-width: 33.33333%;
  @media (min-width: 1280px) {
    max-width: 25%;
  }
`;

export const CustomColumn = styled(Column)`
  border: 1px solid #00000033;
  border-radius: 8px;
`;

export const CustomCard = styled(Card)`
  height: 160px;
  width: 240px;
  justify-content: center;
  display: flex;
`;

export const ChooseFile = styled(CustomCard)<any>`
  border: 1px solid ${(p) => (p.active ? p.theme.primary : p.theme.lightBlack2)};
  outline: 1px solid ${(p) => (p.active ? p.theme.primary : 'transparent')};
  width: 115px;
  height: 115px;
  padding: 0px;
  overflow: hidden;
  cursor: pointer;
  &:hover {
    border-color: ${(p) => p.theme.primary};
    outline-color: ${(p) => p.theme.primary};
  }
`;

export const SmallImgWrap = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  height: fit-content;
  align-items: center;
  display: flex;
  height: 44px;
  width: 44px;
  cursor: pointer;

  & img {
    border-radius: 7px;
  }
`;

export const CustomProjectColumn = styled(ColumnTemplate)<{ active?: boolean }>`
  display: flex;
  align-items: center;
  gap: 10px;
  height: 81px;
  border: 1px solid ${(p) => (p.active ? p.theme.primary : p.theme.lightBlack2)};
  outline: 1px solid ${(p) => (p.active ? p.theme.primary : 'transparent')};
  border-radius: 16px;
  width: 240px;
  padding: 15px;
  &:hover {
    border-color: ${(p) => p.theme.primary};
    outline-color: ${(p) => p.theme.primary};
  }
`;

export const Thumbnail = styled.div<{
  full?: boolean;
  clickable?: boolean;
  url?: string;
  squared?: boolean;
  scalableImage?: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 34px;
  min-height: 51px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background: transparent;
  background: ${(p) => (p.url ? `url(${p.url}) no-repeat 0 0` : 'transparent')};
  padding: 0.5rem;
  background-size: cover;
  & > span[class^='icon-'] {
    font-size: 3rem;
    color: ${(p) => p.theme.primary};
    transition: all 250ms ease-in-out;
  }
  &:hover {
    & > span[class^='icon-'] {
      transform: scale(1.5);
      color: ${(p) => p.theme.darkPrimary};
    }
  }
  ${(p) =>
    p.full &&
    css`
      flex: 1 1;
      min-height: 13.625rem;
    `}
  ${(p) =>
    p.clickable &&
    css`
      cursor: pointer;
    `}

  ${(p) =>
    p.squared &&
    css`
      width: 200px;
      height: 200px;
      background-size: 'cover';
      background-position: 'top';
    `}

  ${(p) =>
    p.scalableImage &&
    css`
      &:hover {
        img {
          transform: scale(1.143);
          transition: all 250ms;
        }
      }
    `}
`;

const TimeWrap = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(2px);
  border-radius: 16px;
  width: 50px;
  height: 50px;
  position: absolute;
  right: 14px;
  top: 12px;
  background: rgba(255, 255, 255, 0.7);
  & > ${Text} {
    font-weight: 600;
    font-size: 12px;
  }

  & > span[class^='icon-'] {
    font-size: 1.25rem;
  }
`;

const components = {
  Wrapper,
  WhiteHeader,
  FoldersWrapper,
  CustomButton,
  PageWrap,
  ContentWrap,
  Column,
  Thumbnail,
  TimeWrap,
  ProjectColumn,
};

export default components;

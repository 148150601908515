import React from 'react';
import WildberriesMobile from './wildberries-mobile';
import { TProductPageMobile } from '../../../interfaces/preview-image.interface';
import YandexMarket from './yandex-mobile';
import OzonMobile from './ozon-mobile';

const ProductPageMobile: React.FC<TProductPageMobile> = ({
  imagesSrc,
  imageDetails,
  marketPlace,
  loadingImages,
  ozonType,
  yandexType,
  canvasRatio
}) => {
  return (
    <>
      {marketPlace === 'Wildberries' && (
        <WildberriesMobile
          imagesSrc={imagesSrc}
          imageDetails={imageDetails}
          marketPlace={marketPlace}
          loadingImages={loadingImages}
          ozonType={ozonType}
          canvasRatio={canvasRatio}
        />
      )}
      {marketPlace === 'YandexMarket' && (
        <YandexMarket
          imagesSrc={imagesSrc}
          imageDetails={imageDetails}
          marketPlace={marketPlace}
          loadingImages={loadingImages}
          yandexType={yandexType}
          canvasRatio={canvasRatio}
        />
      )}
      {marketPlace === 'Ozon' && (
        <OzonMobile
          imagesSrc={imagesSrc}
          imageDetails={imageDetails}
          marketPlace={marketPlace}
          loadingImages={loadingImages}
          ozonType={ozonType}
          canvasRatio={canvasRatio}
        />
      )}
    </>
  );
};

export default ProductPageMobile;

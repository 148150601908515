import React, { createContext, useContext } from 'react';

interface ICanvasFocusContext {
  isFocused: boolean;
  setIsFocused: (isFocused: boolean) => void;
  isInputFocused: boolean;
  setIsInputFocused: (isInputFocused: boolean) => void;
}

interface IProps {
  children: React.ReactNode;
}

const CanvasFocusContext = createContext<ICanvasFocusContext>({
  isFocused: false,
  setIsFocused: () => null,
  isInputFocused: false,
  setIsInputFocused: () => null,
});
export const useCanvasFocus = (): ICanvasFocusContext => useContext(CanvasFocusContext);

export const CanvasFocusProvider: React.FC<IProps> = ({ children }: IProps) => {
  const [isFocused, setIsFocused] = React.useState<boolean>(false);
  const [isInputFocused, setIsInputFocused] = React.useState<boolean>(false);

  return (
    <CanvasFocusContext.Provider value={{ isFocused, setIsFocused, isInputFocused, setIsInputFocused }}>
      {children}
    </CanvasFocusContext.Provider>
  );
};

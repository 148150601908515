import styled, { css } from 'styled-components';
import { BackgroundType } from '../../interfaces/editor.interface';
import checkeredBackground from '../../assets/images/checkered-background.svg';

export const ColorHeader = styled.div<{ alignEnd?: boolean }>`
  display: flex;
  justify-content: ${(p) => (p.alignEnd ? 'flex-end' : 'space-between')};
  align-items: center;
  margin-bottom: 0.625rem;
  & > div:first-child:not(:only-child) {
    margin: -0.625rem -0.9375rem;
    z-index: 4;
  }
`;

export const Hr = styled.div`
  border-top: 1px solid ${(p) => p.theme.lightGray2};
  margin: 1rem -0.9375rem;
`;

export const Color = styled.div<{ active?: boolean; color?: string }>`
  width: 2.5rem;
  height: 2.5rem;
  border: ${(p) => (p.active ? `2px solid ${p.theme.primary}` : `1px solid ${p.theme.lightGray}`)};
  border-radius: 8px;
  background: ${(p) => p.color};
  cursor: pointer;
`;

export const TransparentBtn = styled.button.attrs({ type: 'button' })<{ active?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.375em;
  padding: 0.8125em 1em;
  background: ${(p) => p.theme.white};
  color: ${(p) => p.theme.black};
  border: 1px solid ${(p) => (p.active ? p.theme.primary : p.theme.lightGray)};
  outline: 1px solid ${(p) => (p.active ? p.theme.primary : 'transparent')};
  border-radius: 0.5em;
  cursor: pointer;
  & > span[class^='icon-'] {
    margin-left: 0.3125em;
    font-size: 1.25em;
  }
  &:hover {
    border-color: ${(p) => p.theme.primary};
    outline-color: ${(p) => p.theme.primary};
  }
`;

export const Gradient = styled(Color)<{ type: string; colors: string[] }>`
  background: ${({ colors }) => `linear-gradient(90deg, ${colors[0]} 0%, ${colors[1]} 100%)`};

  ${({ type, colors }) =>
    type === 'vertical' &&
    css`
      background: linear-gradient(180deg, ${colors[0]} 0%, ${colors[1]} 100%);
    `};

  ${({ type, colors }) =>
    type === 'leftDiagonal' &&
    css`
      background: linear-gradient(135deg, ${colors[0]} 0.63%, ${colors[1]} 100.63%);
    `};

  ${({ type, colors }) =>
    type === 'rightDiagonal' &&
    css`
      background: linear-gradient(225deg, ${colors[0]} -0.63%, ${colors[1]} 99.38%);
    `};

  ${({ type, colors }) =>
    type === 'radialCenter' &&
    css`
      background: radial-gradient(50% 50% at 50% 50%, ${colors[0]} 0%, ${colors[1]} 100%);
    `};

  ${({ type, colors }) =>
    type === 'radialLeftTop' &&
    css`
      background: radial-gradient(101.25% 101.25% at 0% 0%, ${colors[0]} 0%, ${colors[1]} 100%);
    `};

  ${({ type, colors }) =>
    type === 'radialTop' &&
    css`
      background: radial-gradient(100% 100% at 49.37% 0%, ${colors[0]} 0%, ${colors[1]} 100%);
    `};

  ${({ type, colors }) =>
    type === 'radialRightCenter' &&
    css`
      background: radial-gradient(100% 100% at 100% 50%, ${colors[0]} 0%, ${colors[1]} 100%);
    `};
`;

export const ColorIcon = styled.div<{
  bgType: BackgroundType;
  color?: string;
  type?: string;
  colors?: string[];
}>`
  width: 1.25rem;
  height: 1.25rem;
  border: 1px solid ${(p) => p.theme.lightGray};
  border-radius: 50%;
  background: url(${checkeredBackground});
  position: relative;

  ${({ color }) =>
    color === 'transparent'
      ? css`
          background-image: linear-gradient(45deg, #ccc 25%, rgba(0, 0, 0, 0) 25%),
            linear-gradient(-45deg, #ccc 25%, rgba(0, 0, 0, 0) 25%),
            linear-gradient(45deg, rgba(0, 0, 0, 0) 75%, #ccc 75%),
            linear-gradient(-45deg, rgba(0, 0, 0, 0) 75%, #ccc 75%) !important;
          background-size: 10px 10px !important;
          background-position: 0 0, 10px 0, 10px -10px, 0px 10px !important;
        `
      : css`
          &:after {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            border-radius: 50%;
            background: ${color};
          }
        `};

  ${({ bgType, type, colors }) =>
    bgType === 'linear' &&
    type === 'horizontal' &&
    colors?.length === 2 &&
    css`
      background: linear-gradient(90deg, ${colors[0]} 0%, ${colors[1]} 100%);
    `};

  ${({ bgType, type, colors }) =>
    bgType === 'linear' &&
    type === 'vertical' &&
    colors?.length === 2 &&
    css`
      background: linear-gradient(180deg, ${colors[0]} 0%, ${colors[1]} 100%);
    `};

  ${({ bgType, type, colors }) =>
    bgType === 'linear' &&
    type === 'leftDiagonal' &&
    colors?.length === 2 &&
    css`
      background: linear-gradient(135deg, ${colors[0]} 0.63%, ${colors[1]} 100.63%);
    `};

  ${({ bgType, type, colors }) =>
    bgType === 'linear' &&
    type === 'rightDiagonal' &&
    colors?.length === 2 &&
    css`
      background: linear-gradient(225deg, ${colors[0]} -0.63%, ${colors[1]} 99.38%);
    `};

  ${({ bgType, type, colors }) =>
    bgType === 'radial' &&
    type === 'radialCenter' &&
    colors?.length === 2 &&
    css`
      background: radial-gradient(50% 50% at 50% 50%, ${colors[0]} 0%, ${colors[1]} 100%);
    `};

  ${({ bgType, type, colors }) =>
    bgType === 'radial' &&
    type === 'radialLeftTop' &&
    colors?.length === 2 &&
    css`
      background: radial-gradient(101.25% 101.25% at 0% 0%, ${colors[0]} 0%, ${colors[1]} 100%);
    `};

  ${({ bgType, type, colors }) =>
    bgType === 'radial' &&
    type === 'radialTop' &&
    colors?.length === 2 &&
    css`
      background: radial-gradient(100% 100% at 49.37% 0%, ${colors[0]} 0%, ${colors[1]} 100%);
    `};

  ${({ bgType, type, colors }) =>
    bgType === 'radial' &&
    type === 'radialRightCenter' &&
    colors?.length === 2 &&
    css`
      background: radial-gradient(100% 100% at 100% 50%, ${colors[0]} 0%, ${colors[1]} 100%);
    `};
`;

export const EyeDropBtn = styled.button.attrs({ type: 'button', className: 'icon-pipette' })<{
  active?: boolean;
  isGradient?: boolean;
}>`
  position: absolute;
  top: ${(p) => (p.isGradient ? '17.375rem' : '11.6875rem')};
  right: 0.9375rem;
  z-index: 1;
  background: transparent;
  border: 0;
  font-size: 1.25rem;
  padding: 0;
  color: ${(p) => (p.active ? p.theme.primary : p.theme.black)};
  cursor: pointer;
  &:hover {
    color: ${(p) => p.theme.primary};
  }
`;

export const HaveBGText = styled.div`
  text-align: center;
  padding: 1rem 0;
  font-size: 0.8rem;
  color: ${(p) => p.theme.gray};
`;

import React from 'react';
import styled from 'styled-components';
import { Button } from './button.component';
import { RoundButton } from './round-btn.component';
import { cloneProjects, deleteProjects } from '../requests/project.requests';
import { deleteSelectedFiles } from '../requests/files.requests';
import { deleteSelectedFolders } from '../requests/folder.requests';
import { deleteSelected, recoverySelected } from '../requests/trash.requests';
import { useNotification } from '../contexts/notification.context';
import { useUserContext } from '../contexts/user.context';
import DeleteFolderForever from './delete-folder-forever.component';
import { useTranslation } from 'react-i18next';
import { useModal } from '../contexts/modal.context';

const Wrapper = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  bottom: 1.25rem;
  z-index: 1100;
  gap: 0.625rem;
`;

type MultipleChoiceType = {
  choiceList: any;
  setHighlightedIds: React.Dispatch<React.SetStateAction<number[]>>;
  highlightedList: number[];
  type?: 'folder' | 'file' | 'project' | 'trash';
  folderId?: number;
  setShowModal?: React.Dispatch<React.SetStateAction<boolean>>;
  itemType?: 'PROJECT' | 'FOLDER' | 'IMAGE';
  refreshRequest?: () => void;
  setHighlight: React.Dispatch<React.SetStateAction<boolean>>;
  actionsWhenClose?: () => void;
  handleOpenModal?: () => void;
};

const MultipleChoice: React.FC<MultipleChoiceType> = ({
  choiceList,
  setHighlightedIds,
  highlightedList,
  setShowModal,
  type,
  itemType,
  refreshRequest,
  setHighlight,
  actionsWhenClose,
  handleOpenModal,
}) => {
  const { t } = useTranslation();
  const modal = useModal();
  const { setNotification, closeNotification } = useNotification();
  const choiceListIds = choiceList.map((item: any) => item.id);
  const { updateUserDetails } = useUserContext();
  const [loading, setLoading] = React.useState<{
    clone: boolean;
    delete: boolean;
  }>({
    clone: false,
    delete: false,
  });

  const handleHighlightAll = () => {
    if (choiceList.length === highlightedList.length) {
      setHighlightedIds([]);
      return;
    }
    setHighlightedIds(choiceListIds);
  };

  const resetHighlight = (stayOnHighlight?: boolean) => {
    setHighlightedIds([]);
    !stayOnHighlight && setHighlight(false);
  };

  const handleClone = () => {
    setLoading((prev) => ({ ...prev, clone: true }));

    cloneProjects(highlightedList)
      .then((res: any) => {
        if (res.success) {
          refreshRequest && refreshRequest();
          resetHighlight(true);
          updateUserDetails();
        } else {
          if (res.exceptionType === 'LIMIT_EXCEEDED') {
            handleOpenModal && handleOpenModal();
          }
        }
      })
      .finally(() => {
        setLoading((prev) => ({ ...prev, clone: false }));
      });
  };

  const handleDelete = () => {
    setLoading((prev) => ({ ...prev, delete: true }));
    if (type === 'project') {
      deleteProjects(highlightedList)
        .then(() => {
          updateUserDetails();
          refreshRequest && refreshRequest();
          const count = highlightedList.length;
          setNotification({
            text: `Вы перенесли проект${count === 1 ? '' : 'ы'} в корзину`,
            handleFunction: () => handleRecovery(),
            buttonText: 'Отменить',
            handleClose: closeNotification,
          });
          resetHighlight(true);
        })
        .finally(() => {
          setLoading((prev) => ({ ...prev, delete: false }));
        });
    } else if (type === 'file') {
      deleteSelectedFiles(highlightedList)
        .then(() => {
          refreshRequest && refreshRequest();
          const count = highlightedList.length;
          setNotification({
            text: `Вы перенесли файл${count === 1 ? '' : 'ы'} в корзину`,
            buttonText: 'Отменить',
            handleFunction: () => handleRecovery(),
            handleClose: closeNotification,
          });
          resetHighlight(true);
        })
        .finally(() => {
          setLoading((prev) => ({ ...prev, delete: false }));
        });
    } else if (type === 'folder') {
      const content = <DeleteFolderForever message={t('deleteMessages.FOLDER')} />;
      modal.open(content, {
        header: 'Хотите удалить папку?',
        headerAlign: 'left',
        width: 540,
        handleOk: () => {
          deleteSelectedFolders(highlightedList)
            .then(() => {
              refreshRequest && refreshRequest();
              setNotification({
                text: 'Папки были удалены.',
                handleClose: () => {
                  actionsWhenClose && actionsWhenClose();
                  closeNotification();
                },
              });
              resetHighlight(true);
            })
            .finally(() => {
              setLoading((prev) => ({ ...prev, delete: false }));
            });
          modal.close();
        },
        handleCancel: () => {
          modal.close();
        },
      });
    } else if (type === 'trash' && itemType) {
      deleteSelected(itemType, highlightedList).then(() => {
        refreshRequest && refreshRequest();
        resetHighlight();
      });
    }
  };

  const handleRecovery = () => {
    if (itemType) {
      recoverySelected(itemType, highlightedList).then(() => {
        refreshRequest && refreshRequest();
        closeNotification();
        resetHighlight();
      });
    }
  };

  return (
    <Wrapper>
      <Button onClick={handleHighlightAll}>Выделить все</Button>
      {(type === 'project' || type === 'file') && (
        <Button
          disabled={!highlightedList.length}
          onClick={() => {
            setShowModal && setShowModal(true);
          }}
        >
          Поместить в папку
        </Button>
      )}
      {type === 'project' && (
        <Button disabled={!highlightedList.length} onClick={handleClone} isLoading={loading.clone}>
          Дублировать
        </Button>
      )}
      {type !== 'trash' && (
        <Button disabled={!highlightedList.length} onClick={handleDelete} isLoading={loading.delete}>
          {type === 'folder' ? 'Удалить' : 'В корзину'}
        </Button>
      )}
      {type === 'trash' && (
        <Button disabled={!highlightedList.length} onClick={handleRecovery}>
          Восстановить
        </Button>
      )}
      {type === 'trash' && (
        <Button disabled={!highlightedList.length} onClick={handleDelete}>
          Удалить
        </Button>
      )}
      <RoundButton icon="close" onClick={() => resetHighlight()}></RoundButton>
    </Wrapper>
  );
};

export default MultipleChoice;

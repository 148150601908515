import styled from 'styled-components';
import { Headline } from '../../components/headline.component';
import { Text } from '../../components/text.component';

export const Wrapper = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  & > ${Text} {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
  };
  & > ${Headline} {
    margin-bottom: 0.625rem;
  }
`

export const SparkleWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  margin-top: 1.875rem;
`;


export const WhiteBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  background: #FFFFFF;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
  width: 380px;
  height: max-content;
  padding: 20px;
  gap: 20px;
  margin-left: 3rem;
`
export const Form = styled.form`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  gap: 12px;
`

export const ButtonsWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-self: center;
    gap: 16px;
`

const components = {
  Wrapper,
  Form,
  ButtonsWrapper
};

export default components;

import React, { ReactElement } from 'react';
import styled from 'styled-components';

const AccordionHeader = styled.div`
  display: block;
  cursor: pointer;
`;

const AccordionContent = styled.div<{ isOpen?: boolean }>`
  overflow: hidden;
  max-height: ${(props) => (props.isOpen ? 'auto' : '0')};
  transition: max-height 0.3s ease-in-out;
`;

type Props = {
  title: ReactElement;
  children: ReactElement;
  isOpen: boolean;
  handleOpen: () => void;
};

const Accordion: React.FC<Props> = ({ title, children, isOpen, handleOpen }) => {
  const handleToggleAccordionHeader = () => {
    handleOpen();
  };

  return (
    <div>
      <AccordionHeader onClick={handleToggleAccordionHeader}>{title}</AccordionHeader>
      <AccordionContent isOpen={isOpen}>{children}</AccordionContent>
    </div>
  );
};

export default Accordion;

import { IProject, IProjectItem } from '../interfaces/projects.interface';
import black from '../assets/icons/black.svg';
import color from '../assets/icons/color.svg';
import fill from '../assets/icons/fill.svg';
import gradient from '../assets/icons/gradient.svg';
import handDrawn from '../assets/icons/hand-drawn.svg';
import outlineColor from '../assets/icons/outline-color.svg';
import outline from '../assets/icons/outline.svg';
import { colorTypes, shapeTypes } from '../requests/icons.request';

export const EDITOR_PROPERTIES = [
  'width',
  'height',
  'padding',
  'name',
  'evented',
  'selectable',
  'lockMovementX',
  'lockMovementY',
  'lockScalingX',
  'lockScalingY',
  'lockRotation',
  'hasControls',
  'objectCaching',
  'subTargetCheck',
  'noScaleCache',
  'id',
];

export type IconSort = {
  title: string;
  value: string;
  icon?: string;
};

export type IconColorSort = {
  title: string;
  value: colorTypes;
  icon?: string;
};

export type IconStyleSort = {
  title: string;
  value: shapeTypes;
  icon?: string;
};

export const IMAGE_OR_FOLDER = [
  {
    title: 'Файлы',
    value: 'images',
  },
  {
    title: 'Папки',
    value: 'folders',
  },
];

export const ICON_COLOR_SORT_VALUES: IconColorSort[] = [
  { title: 'Все расцветки', value: '' },
  { title: 'Черный', value: 'black', icon: black },
  { title: 'Цвет', value: 'multicolor', icon: color },
  { title: 'Градиент', value: 'gradient', icon: gradient },
];

export const ICON_STYLE_SORT_VALUES: IconStyleSort[] = [
  { title: 'Все стили', value: '' },
  { title: 'Контурные', value: 'outline', icon: outline },
  { title: 'Заполненные', value: 'fill', icon: fill },
  { title: 'Контурные цветные', value: 'lineal-color', icon: outlineColor },
  { title: 'Нарисованные', value: 'hand-drawn', icon: handDrawn },
];

export const ICON_POPULAR_SORT_VALUES: IconSort[] = [
  { title: 'По популярности', value: 'relevance' },
  { title: 'По новизне', value: 'recent' },
];

export const ALIGN_OPTIONS = [
  'start-vertical',
  'end-vertical',
  'center-vertical',
  'center-horizontal',
  'start-horizontal',
  'end-horizontal',
] as const;

export const MOVE_OPTIONS = ['forward', 'front', 'backward', 'back'] as const;

export const IMAGE_FILTERS = [
  'brightness',
  'contrast',
  'saturation',
  'blur',
  'opacity',
  'gamma',
] as const;

export const INITIAL_FILTER: { [key: string]: number | number[] } = {
  opacity: 1,
  blur: 0,
  brightness: 0,
  contrast: 0,
  saturation: 0,
  gamma: [1, 1, 1],
};

export const INITIAL_ENABLE_FILTER: { [key: string]: boolean } = {
  opacity: false,
  blur: false,
  brightness: false,
  contrast: false,
  saturation: false,
  gamma: false,
};

export const INITIAL_PROJECT: IProject = {
  id: 0,
  position: 0,
  projectItems: [
    {
      details: {},
      id: 0,
      modifiedImageId: 0,
      originalImageId: 0,
      position: 0,
    },
  ],
  projectName: '',
  completeDeletionDate: 0,
};

export const INITIAL_PROJECT_ITEM: IProjectItem = {
  id: 0,
  details: {},
  modifiedImageId: 0,
  originalImageId: 0,
  position: 0,
};

export const GOOGLE_FONTS = [
  'Bad Script',
  'Bona Nova',
  'Caveat',
  'Inter',
  'Jost',
  'Jura',
  'Lora',
  'Merriweather',
  'Montserrat',
  'Neucha',
  'Noto Sans',
  'Nunito',
  'Oswald',
  'Philosopher',
  'Playfair Display',
  'Prata',
  'Roboto Condensed',
  'Roboto Slab',
  'Roboto',
  'Tenor Sans',
];

export const FONTS_URL =
  'https://fonts.google.com/share?selection.family=|Bad+Script:wght@400|Bona+Nova:wght@400;700|Caveat:wght@400;700|Inter:wght@100;300;400;500;700;900|Jost:wght@100;300;400;500;700;900|Jura:wght@300;400;500;700|Lora:wght@400;500;700|Merriweather:wght@300;400;700;900|Montserrat:wght@100;300;400;500;700;900|Neucha:wght@400|Noto+Sans:wght@100;300;400;500;700;900|Nunito:wght@300;400;500;700;900|Oranienbaum:wght@400|Oswald:wght@300;400;500;700|Philosopher:wght@400;700|Playfair+Display:wght@400;500;700;900|Prata:wght@400|Roboto+Condensed:wght@300;400;700|Roboto+Slab:wght@100;300;400;500;700;900|Roboto:wght@100;300;400;500;700;900|Tenor+Sans:wght@400';

export const EDITOR_TOOLTIPS: { [key: string]: string } = {
  align: 'action.align',
  filters: 'action.adjustImage',
  crop: 'action.crop',
  rotate: 'action.rotate',
  text: 'action.textStyle',
  lineHeight: 'action.lineHeight',
  textAlign: 'action.textAlign',
  textBackground: 'action.textBackgroundColor',
  shapes: 'action.replaceShape',
  shapeStroke: 'action.shapeStroke',
  imageInShape: 'action.insertImage',
  textbox: 'action.textColor',
  rect: 'action.shapeColor',
  ellipse: 'action.shapeColor',
  path: 'action.shapeColor',
  shadow: 'action.shadow',
  charSpace: 'action.charSpace',
  infographic: 'action.infographic',
  group: 'action.group',
  unGroup: 'action.unGroup',
};

// x4 scale
export const GRID_SIZE = [
  { value: 20, name: '5 px' },
  { value: 40, name: '10 px' },
  { value: 60, name: '15 px' },
];
export const GRID_COLOR = [
  { value: 'rgba(102, 32, 199, 0.1)', iconColor: '#F0E9F9' },
  { value: 'rgba(255, 255, 255, 0.2)', iconColor: '#FFFFFF' },
  { value: 'rgba(0, 0, 0, 0.25)', iconColor: '#B4B4B4' },
];

export const LINE_STROKE_WIDTH = 4;

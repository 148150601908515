import React, { useEffect, useState, useRef } from 'react';
import SwiperCore, { Swiper as SwiperType } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, A11y } from 'swiper/modules';
import { Swiper as SwiperClass } from 'swiper/types';

import { OzonType, TProductPageMobile } from '../../../interfaces/preview-image.interface';
import { SimilarButton, YandexMobileWrapper } from '../preview-image.style';
import 'swiper/css';
import styled from 'styled-components';
import { Flex } from '../../../components/flexbox.component';
import { useTranslation } from 'react-i18next';
import YMView from '../../../assets/images/preview/YM-mobile.png';

SwiperCore.use([Navigation, Pagination, A11y]);

const ImageMobileSlider = styled.img<{ yandexType?: OzonType, canvasRatio?: 'default' | 'square' }>`
  height: ${(p) => (p.yandexType === 'Тип 2' ? '195px' : '320px')};
  width: ${(p) => (p.canvasRatio === 'square' ? p.yandexType === 'Тип 2' ? '195px' : '320px' : p.yandexType === 'Тип 2' ? '146px' : '240px')};
  object-fit: ${(p) => (p.canvasRatio === 'square' ? 'cover' : 'contain')};
  object-position: ${(p) => (p.canvasRatio !== 'square' ? 'center' : '0 0')};
`;

const YandexMarket: React.FC<TProductPageMobile> = ({
  imagesSrc,
  marketPlace,
  yandexType,
  imageDetails,
  canvasRatio = 'default',
}) => {
  const [activeSlide, setActiveSlide] = useState(0);
  const totalSlides = Object.values(imagesSrc).length;
  const swiperRef = useRef<{ swiper: SwiperType } | null>(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (totalSlides === 1) {
      setActiveSlide(0);
    }
  }, [totalSlides]);

  const handleSlideChange = (swiper: SwiperClass) => {
    setActiveSlide(swiper.activeIndex);
  };

  return (
    <Flex style={{ marginTop: 165 }} gap={85} justifyContent="center" alignItems="center">
      {totalSlides > 1 && (
        <div
          className="icon-arrow-left"
          style={{
            fontSize: '20px',
            cursor: 'pointer',
            opacity: activeSlide === 0 ? 0.2 : 1,
          }}
          onClick={() => swiperRef.current?.swiper?.slidePrev()}
        ></div>
      )}
      <YandexMobileWrapper yandexType={yandexType}>
        <Swiper
          ref={swiperRef}
          className={'swiper'}
          slidesPerView={1}
          spaceBetween={0}
          onSlideChange={handleSlideChange}
        >
          {imageDetails.map((image, index) => (
            <SwiperSlide className={'swiper-slide'} key={index}>
              <ImageMobileSlider
                src={imagesSrc[image.modifiedImageId]}
                alt={`image_${index}`}
                yandexType={yandexType}
                canvasRatio={canvasRatio}
              />
            </SwiperSlide>
          ))}
        </Swiper>
        {/* <ImageIcons /> */}
        <SimilarButton
          mobile
          marketPlace={marketPlace}
          data-tooltip={t(`preview-image.similar`) as string}
          data-tooltip-position="left"
        >
          <img src={YMView} width={78} height={30} />
        </SimilarButton>
      </YandexMobileWrapper>
      {totalSlides > 1 && (
        <div
          className="icon-arrow-right"
          style={{
            fontSize: '20px',
            opacity: activeSlide === totalSlides - 1 ? 0.2 : 1,
            cursor: 'pointer',
          }}
          onClick={() => swiperRef.current?.swiper?.slideNext()}
        ></div>
      )}
    </Flex>
  );
};

export default YandexMarket;

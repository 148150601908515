import api from './api';

interface Style {
  id: number;
  name: string;
}

interface Family {
  id: number;
  name: string;
  total: number;
}

interface Author {
  id: number;
  name: string;
  slug: string;
  avatar: string;
  assets: number;
}

interface Thumbnail {
  width: number;
  height: number;
  url: string;
}

export interface iconData {
  id: number;
  name: string;
  created: string;
  slug: string;
  style: Style;
  family: Family;
  free_svg: boolean;
  author: Author;
  thumbnails: Thumbnail[];
}

export type colorTypes =
  | 'gradient'
  | 'solid-black'
  | 'multicolor'
  | 'azure'
  | 'black'
  | 'blue'
  | 'chartreuse'
  | 'cyan'
  | 'gray'
  | 'green'
  | 'magenta'
  | 'orange'
  | 'red'
  | 'rose'
  | 'spring-green'
  | 'violet'
  | 'white'
  | 'yellow'
  | '';

export type shapeTypes = 'outline' | 'fill' | 'lineal-color' | 'hand-drawn' | '';

const headers = {
  'Accept-Language': 'en-US',
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'X-Freepik-API-Key': 'FPSX856399b68a3e44a1b15ead345092fd0a',
};

const IconRequest = {
  getIcons: async (
    term: string,
    page: number,
    order: 'recent' | 'relevance',
    filterColors: colorTypes | undefined,
    filterShape: shapeTypes | undefined,
  ) => {
    const response = await api.get<any, any>('freepik/v1/icons', {
      headers,
      params: {
        term,
        page,
        order,
        'filters[color]': filterColors,
        'filters[shape]': filterShape,
      },
    });
    return response;
  },
};

export default IconRequest;

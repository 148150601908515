import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { fabric } from 'fabric';
import FabricTypes from 'fabric/fabric-impl';
import { FabricJSEditor } from 'fabricjs-react';
import styled from 'styled-components';

import { CanvasRatio, SortOption } from '../../../interfaces/editor.interface';
import { ICreateFolderRequest, IImages } from '../../../interfaces/projects.interface';
import useSortSearch from '../../../hooks/use-sort-search.hooks';
import { FILE_SORT, FOLDER_SORT } from '../../../constants/shared.constants';
import { TGetImageList } from '../../../interfaces/gallery.interface';
import { getImages, saveImage } from '../../../requests/files.requests';
import { getFolders, getLibraryImages } from '../../../requests/folder.requests';
import { IconWithText, Text } from '../../text.component';
import { UploadButton } from '../../upload-button.component';
import PopoverTab from '../popover-tab';
import { loadFullImage } from '../../../requests/project.requests';
import {
  clearBackground,
  getImageId,
  handleAddImageInShape,
  handleChangeObjectAlign,
  handleDeleteImageInShape,
  handleParseImage,
  handleScaleImageToCanvas,
  handleScaleImageToRatio,
  handleShapeScaling,
} from '../../../utils/editor.utils';
import useDebounce from '../../../hooks/use-debounce.hooks';
import { resizeImage } from '../../../utils/imageResize';
import { Flex } from '../../flexbox.component';
import { Row } from '../../grid.component';
import { useCanvasHistory } from '../../../contexts/canvas-history.context';
import { ImageProperties } from '../../image-properties.component';
import { ErrorWrapper } from '../../../pages/login/login.style';
import ImageFilesTab from './image-files-tab.component';
import ImageFoldersTab from './image-folders-tab.component';
import { useShared } from '../../../contexts/shared.context';
import { IMAGE_OR_FOLDER } from '../../../constants/editor.constants';

const ImagePopoverWrapper = styled.div`
  height: fit-content;
  width: 332px;
  & > ${Text} {
    &:nth-of-type(2) {
      opacity: 0.6;
      margin-top: 0.625rem;
    }
  }
`;

type IImagePopoverNew = {
  addToCanvas?: (image: FabricTypes.Object) => void;
  canvasRatio?: CanvasRatio;
  bgImage?: boolean;
  shapeImage?: boolean;
  editor?: FabricJSEditor;
  refreshProjectCard?: VoidFunction;
};

const AllImagePopover: React.FC<IImagePopoverNew> = ({
  addToCanvas,
  canvasRatio,
  bgImage,
  shapeImage,
  editor,
  refreshProjectCard,
}) => {
  const { t } = useTranslation();
  const { setFileDropped } = useShared();
  const { historySaveAction } = useCanvasHistory();
  const [folderImageListOpened, setFolderImageListOpened] = useState<{
    isOpen: boolean;
    name: string;
    id: number;
  }>({ isOpen: false, name: '', id: 0 });

  const activeObject = editor?.canvas.getActiveObject();

  // List of images
  const [imageList, setImageList] = useState<IImages[]>([]);
  const [folderList, setFolderList] = useState<ICreateFolderRequest[]>([]);
  const [folderImagesList, setFolderImagesList] = useState<IImages[]>([]);

  // List loaders
  const [errors, setErrors] = useState<string[]>([]);
  const [loadingImage, setLoadingImage] = useState(false);
  const [loadingFolder, setLoadingFolder] = useState(false);
  const [loadingImageFolder, setLoadingImageFolder] = useState(false);
  const [firstLoaderImage, setFirstLoaderImage] = useState(true);
  const [firstLoaderFolder, setFirstLoaderFolder] = useState(true);
  const [firstLoaderImageFolder, setFirstLoaderImageFolder] = useState(true);

  // Sort images
  const imageSort = useSortSearch(FILE_SORT[0], 'DESC');
  const folderSort = useSortSearch(FOLDER_SORT[0]);
  const folderImageSort = useSortSearch(FILE_SORT[0], 'DESC');

  const debouncedSearchValueFile = useDebounce(imageSort.search, 500);
  const debouncedSearchValueFolder = useDebounce(folderSort.search, 500);
  const debouncedSearchValueFolderImage = useDebounce(folderImageSort.search, 500);

  // Image in shape
  const [activeIndex, setActiveIndex] = useState(-1);
  const [imageId, setImageId] = useState<number>(0);
  const [bgObject, setBgObject] = useState<FabricTypes.Object>();
  // end of Image in shape

  // bg image
  const [selectedImageId, setSelectedImageId] = useState<number>();
  const [defaultBg, setDefaultBg] = useState<FabricTypes.Image>();
  const [squareBg, setSquareBg] = useState<FabricTypes.Image>();
  // end of bg image

  // others
  const [isDragOver, setIsDragOver] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [uploadLoading, setUploadLoading] = useState<boolean>(false);
  // end of others

  // Popover tab image or folder
  const [selectedOptionFolderOrImage, setSelectedOptionFolderOrImage] = useState<SortOption>(
    IMAGE_OR_FOLDER[0],
  );

  // Fetch data
  const getImageList = ({ name, selectedOption, sortAsc, page }: TGetImageList) => {
    setLoadingImage(true);
    getImages(name, selectedOption, sortAsc, page)
      .then(({ list }) => {
        imageSort.setHasMore(!(list.length < 24));
        setImageList((prevList) => [...prevList, ...list]);
      })
      .finally(() => {
        setLoadingImage(false);
        setFirstLoaderImage(false);
      });
  };

  const getFolderList = ({ name, selectedOption, sortAsc, page }: TGetImageList) => {
    setLoadingFolder(true);
    getFolders(name, selectedOption, sortAsc, page)
      .then(({ list }) => {
        folderSort.setHasMore(!(list.length < 24));
        setFolderList((prevList) => [...prevList, ...list]);
      })
      .finally(() => {
        setLoadingFolder(false);
        setFirstLoaderFolder(false);
      });
  };

  const getFolderImagesList = ({
    id,
    name,
    selectedOption,
    sortAsc,
    page,
  }: TGetImageList & { id: number }) => {
    setLoadingImageFolder(true);
    getLibraryImages(id, name, selectedOption, sortAsc, page)
      .then(({ list }) => {
        folderImageSort.setHasMore(!(list.length < 24));
        setFolderImagesList((prevList) => [...prevList, ...list]);
      })
      .finally(() => {
        setLoadingImageFolder(false);
        setFirstLoaderImageFolder(false);
      });
  };

  // Utils
  const handleDropFiles = async (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragOver(false);
    setUploadLoading(true);

    const { files } = event.dataTransfer;
    const resizedImagesPromises = [];

    for (const file of Array.from(files)) {
      const fileType = file.type;
      if (fileType === 'image/jpeg' || fileType === 'image/jpg' || fileType === 'image/png') {
        if (file.size <= 5 * 1024 * 1024) {
          resizedImagesPromises.push(resizeImage(file, 1680, 900));
        } else {
          setErrorMsg(
            `Файл слишком большой (${(file.size / 1048576).toFixed(
              0,
            )} Мб). Максимальный размер: 5 Мб.`,
          );
          return;
        }
      } else {
        setErrorMsg('Допустимые форматы: jpeg, png. Загрузите изображения с верным форматом.');
        return;
      }
    }
    Promise.all(resizedImagesPromises)
      .then((resizedFiles) => {
        return saveImage(resizedFiles);
      })
      .then((data) => {
        setImageList([...data.list, ...imageList]);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setUploadLoading(false));
  };

  const handleBackToFolderList = () => {
    setFolderImagesList([]);
    folderImageSort.setSearch('');
    setFolderImageListOpened({ isOpen: false, name: '', id: 0 });
  };

  const setImagePending = (imageId: number, value: boolean) => {
    setImageList((prevState) => {
      return prevState.map((image) => ({
        ...image,
        pending: image.imageId === imageId ? value : false,
      }));
    });
  };

  const setFolderImagePending = (imageId: number, value: boolean) => {
    setFolderImagesList((prevState) => {
      return prevState.map((image) => ({
        ...image,
        pending: image.imageId === imageId ? value : false,
      }));
    });
  };

  const handleAddToCanvas = (image: FabricTypes.Object, imageId: number) => {
    handleScaleImageToCanvas(image, imageId, canvasRatio);
    addToCanvas && addToCanvas(image);
    setImagePending(imageId, false);
  };

  const handleAddSVG = (imageBlob: Blob, imageId = 0) => {
    const url = URL.createObjectURL(imageBlob);
    fabric.loadSVGFromURL(url, (objects, options) => {
      const image = fabric.util.groupSVGElements(objects, options);
      handleAddToCanvas(image, imageId);
    });
  };

  const handleAddImage = (imageBlob: Blob, imageId = 0) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const imgObj = new Image();
      imgObj.src = event.target?.result as string;
      imgObj.onload = () => {
        const image = new fabric.Image(imgObj);
        handleAddToCanvas(image, imageId);
      };
    };
    reader.readAsDataURL(imageBlob);
  };

  const handleUploadImage = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setUploadLoading(true);
    const files = Array.from(event.target.files as FileList);
    const resizedImagesPromises = [];

    const uploadPromises = [];
    const newImages = [];

    for (const file of files) {
      const fileType = file.type;
      if (fileType === 'image/jpeg' || fileType === 'image/jpg' || fileType === 'image/png') {
        if (file.size <= 5 * 1024 * 1024) {
          resizedImagesPromises.push(resizeImage(file, 1680, 900));
        } else {
          setErrorMsg(
            `Файл слишком большой (${(file.size / 1048576).toFixed(
              0,
            )} Мб). Максимальный размер: 5 Мб.`,
          );
          return;
        }
      } else {
        setErrorMsg('Допустимые форматы: jpeg, png. Загрузите изображения с верным форматом.');
        return;
      }

      const id = Math.random();
      newImages.push({ id, imageId: id, pending: true });
      const uploadPromise = resizeImage(file, 1680, 900)
        .then((resizedFile) => saveImage([resizedFile]).then(() => resizedFile))
        .then(() => {
          return { id, success: true };
        })
        .catch((error) => ({ id, success: false, error }));
      uploadPromises.push(uploadPromise);
    }
    try {
      await Promise.all(uploadPromises);
      imageSort.setPage(0);
      setImageList([]);
      getImageList({
        name: imageSort.search,
        page: 0,
        selectedOption: imageSort.selectedOption.value,
        sortAsc: imageSort.sortAsc,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setUploadLoading(false);
    }
  };

  const handleSetBackgroundImage = (image: FabricTypes.Image, id = 0) => {
    if (!editor) return;
    const { canvas } = editor;
    clearBackground(canvas);
    handleScaleImageToRatio(canvas, image);

    image.setControlsVisibility({
      mt: false,
      mr: false,
      mb: false,
      ml: false,
      mtr: false,
    });

    image.set({
      name: `backgroundImage_${id}`,
      selectable: false,
      evented: false,
      lockMovementX: true,
      lockMovementY: true,
      lockScalingX: true,
      lockScalingY: true,
      lockRotation: true,
      hasControls: false,
    });

    canvas.add(image);
    image.sendToBack();

    handleChangeObjectAlign(canvas, image, 'center-horizontal');
    handleChangeObjectAlign(canvas, image, 'center-vertical');

    setBgObject(image);
    canvas.renderAll();
    historySaveAction(editor);
    setImagePending(id, false);
    setFolderImagePending(id, false);
  };

  const handleLoadFullImageSelect = (id: number, index: number) => {
    setSelectedImageId(id);
    setActiveIndex(index);
    setErrors([]);
    if (imageId != id) {
      setImagePending(id, true);
      setFolderImagePending(id, true);
      setImageId(id);
      loadFullImage(id)
        .then((image) => {
          if (bgImage) {
            handleParseImage(image, (imageShape) => handleSetBackgroundImage(imageShape, id));
          } else if (shapeImage) {
            handleParseImage(image, (imageShape) =>
              handleSetBackgroundImageForShape(imageShape, id),
            );
          }
        })
        .then(() => {
          refreshProjectCard &&
            setTimeout(() => {
              refreshProjectCard();
            }, 500);
        })
        .catch(() => {
          setImagePending(id, false);
          setFolderImagePending(id, false);
        });
      if (bgImage) {
        setDefaultBg(undefined);
        setSquareBg(undefined);
      }
    }
  };

  const handleLoadFullImage = ({ imageId, imageType, imageName }: IImages) => {
    setImagePending(imageId, true);
    loadFullImage(imageId)
      .then((image) => {
        if (imageType === 'SVG') {
          const file = new File([image], imageName ?? 'filename', { type: 'image/svg+xml' });
          handleAddSVG(file, imageId);
        } else {
          handleAddImage(image, imageId);
        }
      })
      .catch(() => setImagePending(imageId, false));
  };

  const handleSetBackgroundImageForShape = (image: FabricTypes.Image, id = 0) => {
    if (activeObject && editor) {
      handleDeleteImageInShape(editor.canvas, activeObject).then(() => {
        const shape = activeObject as FabricTypes.Object;
        const shapeId = Math.random().toString(16).slice(2);
        image.clone((cloned: FabricTypes.Image) => {
          const hiddenImage = cloned.set({
            name: `${shape.name}_${shapeId}_${id}`,
            visible: false,
            scaleX: 1,
            scaleY: 1,
          }) as FabricTypes.Image;
          editor.canvas.add(hiddenImage);
        });
        shape.set('globalCompositeOperation', `shapeImageId_${shapeId}_${id}`);
        shape.off('modified');
        shape.on('modified', handleShapeScaling(editor.canvas, shape));
        handleAddImageInShape(editor.canvas, image).then(() => {
          historySaveAction(editor);
        });
      });
    }
  };

  // end of Image in shape functions

  const navigateFolderTab = (folder: ICreateFolderRequest) => {
    if (folder && folder.id) {
      setSelectedOptionFolderOrImage(IMAGE_OR_FOLDER[1]);
      setFolderImageListOpened({ isOpen: true, name: folder.folderName, id: folder.id });
    }
  };

  useEffect(() => {
    if (canvasRatio === 'square' && squareBg) {
      const id = getImageId(squareBg);
      handleSetBackgroundImage(squareBg, id);
    } else if (canvasRatio === 'default' && defaultBg) {
      const id = getImageId(defaultBg);
      handleSetBackgroundImage(defaultBg, id);
    }
  }, [canvasRatio]);

  useEffect(() => {
    if (folderImageListOpened.isOpen) {
      setFirstLoaderImageFolder(true);
    }
  }, [folderImageListOpened.isOpen]);

  useEffect(() => {
    folderSort.setPage(0);
    setFolderList([]);
    getFolderList({
      name: folderSort.search,
      page: 0,
      selectedOption: folderSort.selectedOption.value,
      sortAsc: folderSort.sortAsc,
    });
  }, [folderSort.selectedOption, folderSort.sortAsc, debouncedSearchValueFolder]);

  // end of bg image

  useEffect(() => {
    if (errorMsg) {
      setTimeout(() => {
        setErrorMsg('');
        setFileDropped({ value: false, message: '' });
      }, 5000);
    }
  }, [errorMsg]);

  const backToFolderList = (
    <Row style={{ maxWidth: '344px', maxHeight: '15px' }} gutter={10}>
      <IconWithText
        iconFirst
        hover
        style={{
          marginBottom: '1.25em',
          alignItems: 'center',
        }}
        onClick={handleBackToFolderList}
      >
        <Text fontSize="20px" className="icon-undo"></Text>
        <Text fontSize="16px" fontWeight={600} ellipsis>
          {folderImageListOpened.name}
        </Text>
      </IconWithText>
    </Row>
  );

  return (
    <ImagePopoverWrapper
      onDragOver={(e) => {
        e.preventDefault();
        setIsDragOver(true);
      }}
      onDragLeave={(e) => {
        e.preventDefault();
        setIsDragOver(false);
      }}
      onDrop={(e) => {
        handleDropFiles(e);
      }}
    >
      <UploadButton
        multiple={true}
        handleUpload={handleUploadImage}
        dragOver={isDragOver}
        isLoading={uploadLoading}
      />

      {selectedImageId && selectedImageId !== 0 && (bgImage || shapeImage) && (
        <Flex alignItems="center" style={{ marginBottom: '20px' }}>
          <Flex justifyContent="flex-start" style={{ width: '100%' }}>
            <img
              src={`/api/project/load/image/png?imageId=${selectedImageId}&imageSizeType=ICON`}
              width={70}
              height={70}
              style={{ borderRadius: '8px', border: '1px solid #00000033' }}
            />
          </Flex>
          <Flex
            style={{
              height: '50px',
              width: '100%',
            }}
            justifyContent="end"
          >
            {shapeImage && editor && activeObject ? (
              <ImageProperties
                imageId={imageId}
                editor={editor}
                activeObject={activeObject}
                handleSetBackgroundImage={handleSetBackgroundImageForShape}
                type="shape"
              />
            ) : (
              editor && (
                <ImageProperties
                  imageId={imageId}
                  editor={editor}
                  activeObject={bgObject}
                  canvasRatio={canvasRatio}
                  handleSetBackgroundImage={handleSetBackgroundImage}
                  isBackground={true}
                  setDefaultBg={setDefaultBg}
                  setSquareBg={setSquareBg}
                  type="bg"
                />
              )
            )}
          </Flex>
        </Flex>
      )}
      <PopoverTab
        selectedOptionFolderOrImage={selectedOptionFolderOrImage}
        setSelectedOptionFolderOrImage={setSelectedOptionFolderOrImage}
        firstLoader={firstLoaderImage}
        firstLoaderFolder={firstLoaderFolder}
        firstLoaderImageFolder={firstLoaderImageFolder}
        errorMessage={errorMsg}
        imagePopover
        folderImageListIsOpen={folderImageListOpened.isOpen}
        optionalContent={folderImageListOpened.isOpen && backToFolderList}
        ImageNotFound={imageList.length === 0 && debouncedSearchValueFile === '' && !loadingImage}
        imageLoading={loadingImage}
        folderLoading={loadingFolder}
        folderImageLoading={loadingImageFolder}
        folderImageListNotFound={
          folderImageListOpened.isOpen
            ? folderImagesList.length === 0 &&
              debouncedSearchValueFolderImage === '' &&
              !loadingImageFolder
            : folderList.length === 0 && debouncedSearchValueFolder === '' && !loadingFolder
        }
        searchResult={
          (imageList.length === 0 && debouncedSearchValueFile !== '' && !loadingImage) ||
          (folderImageListOpened.isOpen
            ? folderImagesList.length === 0 && debouncedSearchValueFolderImage !== ''
            : folderList.length === 0 && debouncedSearchValueFolder !== '')
        }
        tabs={[
          {
            title: 'Файлы',
            sort: {
              selection: FILE_SORT,
              selectedOption: imageSort.selectedOption,
              setSelectedOption: imageSort.setSelectedOption,
              sortOrder: imageSort.sortAsc,
              setSortOrder: imageSort.setSortAsc,
              search: imageSort.search,
              setSearch: imageSort.setSearch,
            },
            content: (
              <ImageFilesTab
                activeIndex={activeIndex}
                activeObject={activeObject}
                editor={editor as FabricJSEditor}
                bgImage={bgImage}
                shapeImage={shapeImage}
                errorMsg={errorMsg}
                handleLoadFullImage={handleLoadFullImage}
                handleLoadFullImageSelect={handleLoadFullImageSelect}
                selectedImageId={selectedImageId}
                setActiveIndex={setActiveIndex}
                setBgObject={setBgObject}
                setImageId={setImageId}
                setSelectedImageId={setSelectedImageId}
                getImageList={getImageList}
                loadingImage={loadingImage}
                imageList={imageList}
                setImageList={setImageList}
                imageSort={imageSort}
                setErrorMsg={setErrorMsg}
                navigateFolderTab={navigateFolderTab}
              />
            ),
          },
          {
            title: 'Папки',
            sort: {
              selection: folderImageListOpened.isOpen ? FILE_SORT : FOLDER_SORT,
              selectedOption: folderImageListOpened.isOpen
                ? folderImageSort.selectedOption
                : folderSort.selectedOption,
              setSelectedOption: folderImageListOpened.isOpen
                ? folderImageSort.setSelectedOption
                : folderSort.setSelectedOption,
              sortOrder: folderImageListOpened.isOpen
                ? folderImageSort.sortAsc
                : folderSort.sortAsc,
              setSortOrder: folderImageListOpened.isOpen
                ? folderImageSort.setSortAsc
                : folderSort.setSortAsc,
              search: folderImageListOpened.isOpen ? folderImageSort.search : folderSort.search,
              setSearch: folderImageListOpened.isOpen
                ? folderImageSort.setSearch
                : folderSort.setSearch,
            },
            content: (
              <ImageFoldersTab
                folderList={folderList}
                loadingFolder={loadingFolder}
                setFolderImageListOpened={setFolderImageListOpened}
                getFolderList={getFolderList}
                folderImageListOpened={folderImageListOpened}
                loadingImageFolder={loadingImageFolder}
                shapeImage={shapeImage}
                bgImage={bgImage}
                handleLoadFullImageSelect={handleLoadFullImageSelect}
                handleLoadFullImage={handleLoadFullImage}
                folderImagesList={folderImagesList}
                setFolderImagesList={setFolderImagesList}
                getFolderImagesList={getFolderImagesList}
                folderImageSort={folderImageSort}
                folderSort={folderSort}
              />
            ),
          },
        ]}
      />
      {errors.length > 0 && (
        <ErrorWrapper>
          {errors.map((error) => (
            <Text key={error} color="red" small>
              {t(`editor.errors.${error}`)}
            </Text>
          ))}
        </ErrorWrapper>
      )}
    </ImagePopoverWrapper>
  );
};

export default AllImagePopover;

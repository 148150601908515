import React, { ButtonHTMLAttributes } from 'react';
import styled, { css } from 'styled-components';
import CircleLoader2 from './circle-loader2';

interface IButtonProps extends ButtonHTMLAttributes<any> {
  icon?: string;
  additionalIcon?: string;
  children?: any;
  btnStyle?: string;
  active?: boolean;
  iconPosition?: 'right' | 'left' | 'center';
  isLoading?: boolean;
  width?: string;
  id?: string;
}

export const ButtonWrapper = styled.button<IButtonProps>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.8125rem 1.25rem;
  background-color: ${(p) => p.theme.primary};
  color: ${(p) => p.theme.white};
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
  border-radius: 1rem;
  border: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5em;
  cursor: pointer;
  transition: all 250ms linear;
  &:hover {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
      ${(p) => p.theme.primary};
  }
  ${({ isLoading }) =>
    css`
      &:disabled {
        background-color: ${(p) => (!isLoading ? p.theme.lightGray2 : p.theme.primary)};
        color: ${!isLoading ? 'rgba(0, 0, 0, 0.2)' : 'white'};
      }
    `}

  & span[class^='icon-'],
  span[class*=' icon-'] {
    margin-left: ${({ iconPosition }) => (iconPosition === 'center' ? '0' : '0.5rem')};
    font-size: 1.25rem;
    order: ${(p) => (p.iconPosition === 'center' ? 0 : p.iconPosition === 'left' ? -1 : 1)};
  }

  ${({ width }) =>
    width &&
    css`
      width: ${width};
    `}

  ${(p) =>
    p.btnStyle === 'inverse' &&
    css`
      background-color: ${p.theme.white};
      color: ${p.theme.primary};
      &:hover {
        background: ${(p) => p.theme.lightPrimaryGradient};
      }
    `}

    ${(p) =>
    p.btnStyle === 'cancel' &&
    css`
      background-color: ${p.theme.white};
      color: ${p.theme.black};
      box-shadow: none;
      &:hover {
        background: none;
        color: ${p.theme.primary};
      }
      &:disabled {
        background: none;
        color: rgba(0, 0, 0, 0.15);
      }
    `}
    ${(p) =>
    p.btnStyle === 'gray' &&
    css`
      background: ${p.active ? p.theme.lightPrimary : p.theme.lightGray2};
      color: ${p.active ? p.theme.primary : p.theme.black};
      padding: 0.75rem 1rem;
      &:hover {
        background: ${p.theme.lightPrimary};
        color: ${p.theme.primary};
      }
    `};

  ${(p) =>
    p.btnStyle === 'white' &&
    css`
      background: #fff;
      color: ${p.theme.black};
      padding: 0.75rem 1rem;
      &:hover {
        background: ${p.theme.lightPrimary};
        color: ${p.theme.primary};
      }
    `};
  ${(p) =>
    p.isLoading &&
    css`
      & > *:not(:first-child) {
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
      }
    `}
`;

const StyledChildren = styled.span`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Button = ({
  icon,
  children,
  iconPosition = 'right',
  isLoading,
  width,
  additionalIcon,
  id,
  disabled,
  ...rest
}: IButtonProps) => {
  return (
    <ButtonWrapper
      id={id}
      disabled={(isLoading && !disabled) || disabled}
      type="button"
      width={width}
      iconPosition={iconPosition}
      isLoading={isLoading}
      {...rest}
    >
      {isLoading && <CircleLoader2 absolute={true} />}
      {children && <StyledChildren>{children}</StyledChildren>}
      {icon && <span className={`icon-${icon}`} />}
      {additionalIcon && <span className={`icon-${additionalIcon}`} />}
    </ButtonWrapper>
  );
};

import React, { createContext, useContext } from 'react';
import { ITokenResponse, IUser } from '../interfaces/auth.interface';
import { logIn } from '../requests/auth.request';
import { setTokens, removeTokens } from '../requests/token.request';
import { AxiosError } from 'axios';
import { Role } from '../requests/user.requests';

interface AuthContextType {
  user: any;
  userRole: Role;
  signIn: (
    userDto: IUser,
    callback: (role: Role) => void,
    errorCallback?: (err: AxiosError) => void,
  ) => void;
  signOut: (callback: VoidFunction) => void;
}

interface IProps {
  children: React.ReactNode;
}

const AuthContext = createContext<AuthContextType>({
  user: null,
  userRole: null,
  signIn: () => null,
  signOut: () => null,
});
export const useAuth = (): AuthContextType => useContext(AuthContext);

export const AuthProvider: React.FC<IProps> = ({ children }: { children: React.ReactNode }) => {
  const [user, setUser] = React.useState<any>(localStorage.getItem('userName'));
  const [userRole, setUserRole] = React.useState<Role>(localStorage.getItem('userRole') as Role);

  const signIn = (
    userDto: IUser,
    callback: (role: Role) => void,
    errorCallback?: (err: AxiosError) => void,
  ) => {
    return logIn(userDto)
      .then(({ accessToken, refreshToken, role }: ITokenResponse) => {
        setUser(userDto.email);
        setUserRole(role);
        setTokens(accessToken, refreshToken);
        localStorage.setItem('userName', userDto.email);
        localStorage.setItem('userRole', role as string);
        callback(role);
      })
      .catch((error) => errorCallback && errorCallback(error));
  };

  const signOut = (callback: VoidFunction) => {
    setUser(null);
    setUserRole(null);
    removeTokens();
    localStorage.removeItem('userName');
    localStorage.removeItem('userRole');
    callback();
  };

  const value = { user, userRole, signIn, signOut };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

import React, { useState, useRef, useEffect, Dispatch, SetStateAction } from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { FabricJSEditor } from 'fabricjs-react';
import FabricTypes from 'fabric/fabric-impl';

import { useCanvasHistory } from '../../contexts/canvas-history.context';
import { CanvasRatio, IArrowBtnProps } from '../../interfaces/editor.interface';
import { IProject, IProjectItem } from '../../interfaces/projects.interface';
import {
  addProjectItem,
  deleteProjectItem,
  handleSaveNewPage,
} from '../../requests/project.requests';
import { ToolbarBtn, ToolbarWrapper } from './toolbar.style';
import ActionPopover from '../action-popover.component';
import { EDITOR_PROPERTIES } from '../../constants/editor.constants';
import SkeletonLoader from '../skeleton-loader.component';
import { fallbackImage } from '../../constants/shared.constants';
import { useShared } from '../../contexts/shared.context';
import CircleLoader2 from '../circle-loader2';

const Wrapper = styled.div`
  position: absolute;
  right: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const PagesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.375rem;
  max-height: 28.875rem;
  overflow: hidden auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding: 2px 1.25rem;
  margin: -2px 0;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const PageCard = styled.img<{ active: boolean; canvasRatio: CanvasRatio }>`
  width: 4.3125rem;
  height: ${(p) => (p.canvasRatio === 'square' ? '4.3125rem' : '5.75rem')};
  min-height: ${(p) => (p.canvasRatio === 'square' ? '4.3125rem' : '5.75rem')};
  background: #ffffff;
  outline: ${({ active, theme }) => (active ? `2px solid ${theme.primary}` : '')};
  border-radius: 8px;
  cursor: pointer;
  &:hover {
    outline: 2px solid ${(p) => p.theme.primary};
  }
`;

const ArrowBtn = styled.button.attrs((p: IArrowBtnProps) => ({
  type: 'button',
  children: <span className={`icon-arrow-${p.direction}`} />,
}))<IArrowBtnProps>`
  background: transparent;
  border: 0;
  font-size: 1.25rem;
  color: ${(p) => p.theme.black};
  text-align: center;
  margin-bottom: ${(p) => (p.direction === 'up' ? '0.75rem' : '0.625rem')};
  margin-top: ${(p) => (p.direction === 'down' ? '0.75rem' : '')};
  cursor: pointer;

  &:disabled {
    &:not(:hover) {
      opacity: 0.2;
    }
  }

  &:hover {
    color: ${(p) => p.theme.primary};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      &:hover {
        opacity: 0.2;
        color: ${(p) => p.theme.black};
        cursor: default;
      }
    `}
`;

interface IProps {
  editor: FabricJSEditor;
  handleSavePage: () => Promise<boolean>;
  project: IProject;
  setProject: Dispatch<SetStateAction<IProject>>;
  thumbnails?: string[];
  setThumbnails: Dispatch<SetStateAction<string[]>>;
  activeIndex: number | undefined;
  setActiveIndex: Dispatch<SetStateAction<number | undefined>>;
  canvasRatio: CanvasRatio;
  isLoading: boolean;
}

export const Pages = ({
  editor,
  handleSavePage,
  project,
  setProject,
  thumbnails = [],
  setThumbnails,
  activeIndex,
  setActiveIndex,
  canvasRatio,
  isLoading,
}: IProps) => {
  const { handleClearHistory } = useCanvasHistory();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const itemsRef = useRef<HTMLImageElement[]>([]);
  const { handleIndexChange } = useShared();
  const [disabledBtn, setDisabledBtn] = useState({ up: true, down: true });
  const [isScrollable, setIsScrollable] = useState(false);
  const [canvasIsSaving, setCanvasIsSaving] = useState(false);
  const [pageAdding, setPageAdding] = useState(false);
  const { t } = useTranslation();

  const { isTiming } = useShared();

  useEffect(() => {
    if (itemsRef.current && activeIndex !== undefined && itemsRef.current[activeIndex]) {
      itemsRef.current[activeIndex].scrollIntoView({ behavior: 'smooth' });
      handleClearHistory();
    }
  }, [activeIndex]);

  useEffect(() => {
    if (wrapperRef.current) {
      const isContentScrollable = wrapperRef.current.scrollHeight > wrapperRef.current.clientHeight;
      setIsScrollable(isContentScrollable);
    }
  }, [project?.projectItems?.length]);

  const handleCheckTextBox = (): Promise<void> => {
    return new Promise<void>((resolve) => {
      const { canvas } = editor as FabricJSEditor;
      const obj = canvas.getActiveObject();
      if (obj?.name === 'temporaryTextBox') {
        (obj as FabricTypes.Textbox).exitEditing();
      }
      resolve();
    });
  };

  const handleSelectPage = (index: number) => {
    handleCheckTextBox().then(async () => {
      if (isTiming && !canvasIsSaving) {
        setCanvasIsSaving(true);
        await handleSavePage();
        setCanvasIsSaving(false);
        setActiveIndex(index);
      }
      !canvasIsSaving && setActiveIndex(index);
      handleIndexChange('current', index);
    });
  };

  const handleDecrement = () => {
    if (wrapperRef.current && wrapperRef.current.scrollTop > 0) {
      wrapperRef.current.scrollTop -= 100;
    }
  };

  const handleIncrement = () => {
    if (wrapperRef.current && wrapperRef.current.scrollTop < wrapperRef.current.scrollHeight) {
      wrapperRef.current.scrollTop += 100;
    }
  };

  const handleAddPage = () => {
    setPageAdding(true);
    handleCheckTextBox().then(async () => {
      await handleSavePage();
      addProjectItem(project.id)
        .then(({ data }) => {
          const { canvas } = editor as FabricJSEditor;
          canvas._objects.splice(0, canvas._objects.length);
          canvas.setBackgroundColor('rgba(255, 255, 255, 1)', canvas.renderAll.bind(canvas));
          canvas.discardActiveObject();
          canvas.renderAll();
          const newItem = { ...data, details: canvas.toJSON(EDITOR_PROPERTIES) } as IProjectItem;
          newItem.details.name = 'new_page';
          setProject((prevState) => ({
            ...prevState,
            projectItems: [...project.projectItems, newItem],
          }));
          setActiveIndex(project.projectItems.length);
          handleIndexChange('current', project.projectItems.length);
        })
        .catch(() => null)
        .finally(() => setPageAdding(false));
    });
  };

  const handleDuplicatePage = (index: number) => {
    handleCheckTextBox().then(() => {
      handleSavePage().then(() => {
        addProjectItem(project.id)
          .then(({ data }) => {
            const { canvas } = editor as FabricJSEditor;
            canvas.discardActiveObject();
            canvas.renderAll();
            const details = project.projectItems[index].details;
            const newItem = { ...data, details } as IProjectItem;
            setProject((prevState) => ({
              ...prevState,
              projectItems: [...project.projectItems, newItem],
            }));
            setActiveIndex(project.projectItems.length);
            handleIndexChange('current', project.projectItems.length);
            handleSaveNewPage(editor as FabricJSEditor, newItem);
          })
          .catch(() => null);
      });
    });
  };

  const handleDeletePage = (index: number) => {
    handleCheckTextBox().then(() => {
      const projectItems = [...project.projectItems];
      deleteProjectItem(projectItems[index].id)
        .then(() => {
          const thumbnailsCopy = [...thumbnails];
          projectItems.splice(index, 1);
          thumbnailsCopy.splice(index, 1);
          setProject((prevState) => ({ ...prevState, projectItems }));
          thumbnailsCopy && setThumbnails(thumbnailsCopy);
          if (activeIndex !== undefined && activeIndex !== 0 && index <= activeIndex) {
            setActiveIndex(activeIndex - 1);
            handleIndexChange('current', activeIndex - 1);
          }
        })
        .catch(() => null);
    });
  };

  const handleScroll = () => {
    if (wrapperRef?.current) {
      setDisabledBtn({
        up: wrapperRef.current.scrollTop === 0,
        down:
          wrapperRef.current.scrollTop ===
          wrapperRef.current.scrollHeight - wrapperRef.current.offsetHeight,
      });
    }
  };

  useEffect(() => {
    if (project?.projectItems?.length && wrapperRef?.current) {
      handleScroll();
    }
  }, [project?.projectItems?.length, wrapperRef]);

  return (
    <Wrapper>
      {isScrollable && !isLoading && (
        <ArrowBtn direction="up" disabled={disabledBtn.up} onClick={handleDecrement} />
      )}
      <PagesWrapper ref={wrapperRef} onScroll={handleScroll}>
        {isLoading ? (
          <>
            <SkeletonLoader width="69px" height="92px" />
            <SkeletonLoader width="69px" height="92px" />
            <SkeletonLoader width="69px" height="92px" />
            <SkeletonLoader width="69px" height="92px" />
            <SkeletonLoader width="69px" height="92px" />
          </>
        ) : (
          project.projectItems.map((_, index) => (
            <ActionPopover
              key={`page_${index}`}
              position={'left'}
              trigger={'hover'}
              content={[
                {
                  icon: 'double',
                  callback: () => handleDuplicatePage(index),
                  hide: project.projectItems.length > 29,
                },
                {
                  icon: 'trash',
                  callback: () => handleDeletePage(index),
                  hide: project.projectItems.length <= 1,
                },
              ]}
            >
              <PageCard
                ref={(el) => (itemsRef.current[index] = el as HTMLImageElement)}
                src={thumbnails[index] || fallbackImage}
                active={activeIndex === index}
                onClick={() => handleSelectPage(index)}
                canvasRatio={canvasRatio}
              />
            </ActionPopover>
          ))
        )}
      </PagesWrapper>
      {isScrollable && !isLoading && (
        <ArrowBtn
          direction="down"
          disabled={disabledBtn.down || activeIndex === project.projectItems?.length - 1}
          onClick={handleIncrement}
        />
      )}
      {!isLoading && (
        <ToolbarWrapper style={{ marginTop: !isScrollable ? '10px' : '' }}>
          <ToolbarBtn
            onClick={() => {
              !pageAdding && handleAddPage();
            }}
            disabled={project.projectItems?.length > 29}
            data-tooltip-position={'bottom'}
            data-tooltip={t('action.newCanvas') as string}
          >
            {pageAdding ? (
              <CircleLoader2 size={20} shineColor="#6620C7" />
            ) : (
              <span className="icon-plus" />
            )}
          </ToolbarBtn>
        </ToolbarWrapper>
      )}
    </Wrapper>
  );
};

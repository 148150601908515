import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { IProjectItem } from '../../../interfaces/projects.interface';
import { getProject } from '../../../requests/project.requests';
import { Button } from '../../button.component';
import { Flex, FlexCol } from '../../flexbox.component';
import { Modal } from '../../modal.component';
import { ModalTitle } from '../../object-toolbar/infographic-tool/infographic.style';
import SkeletonLoader from '../../skeleton-loader.component';
import { InfoGraphicRequest } from '../../../requests/infographic.request';
import FabricTypes from 'fabric/fabric-impl';
import { fabric } from 'fabric';
import { detailsType } from './template-types';
import { CanvasRatio } from '../../../interfaces/editor.interface';
import { useNotification } from '../../../contexts/notification.context';
import { useShared } from '../../../contexts/shared.context';
import {
  ModalFooter,
  TemplateItem,
  TemplateList,
  TemplateModalSubTitle,
} from './template-popover.style';
import { fallbackImage } from '../../../constants/shared.constants';
import { getImageId } from '../../../utils/editor.utils';

type TTemplateAddModal = {
  setTemplateAddModalOpen: (value: boolean) => void;
  canvasRatio: CanvasRatio;
  setLoading: (value: boolean) => void;
  thumbnails: string[];
};

const TemplateAddModal: React.FC<TTemplateAddModal> = ({
  setTemplateAddModalOpen,
  canvasRatio,
  setLoading,
  thumbnails,
}) => {
  const { t } = useTranslation();
  const { projectId } = useParams();
  const { setRecentTemplateIds } = useShared();
  const { setNotification } = useNotification();

  const [projectItems, setProjectItems] = useState<IProjectItem[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedItems, setSelectedItems] = useState<{ id: number; details: FabricTypes.Object }[]>(
    [],
  );
  const [selectedItemsForFetch, setSelectedItemsForFetch] = useState<
    { details: detailsType[]; image: string | ArrayBuffer }[]
  >([]);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isLength, setIsLength] = useState<boolean>(false);

  const handleGetProject = (projectId: number) => {
    setIsLoading(true);
    getProject(projectId)
      .then(({ data }) => {
        if (data) {
          setProjectItems(data.projectItems);
        }
        if (data?.projectItems.length === 1) {
          createImageFromDetails(data.projectItems[0].details);
        } else {
          setIsLength(true);
        }
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (projectId) handleGetProject(Number(projectId));
  }, [projectId]);

  const handleSelectItem = (item: IProjectItem) => {
    if (selectedItems.some((selectedItem) => selectedItem.id === item.id)) {
      setSelectedItems(selectedItems.filter((selectedItem) => selectedItem.id !== item.id));
      return;
    }
    setSelectedItems([...selectedItems, { details: item.details, id: item.id }]);
  };

  const handleSelectAll = () => {
    projectItems.forEach((item) => {
      if (selectedItems.some((selectedItem) => selectedItem.id === item.id)) return;
      setSelectedItems((prev) => [...prev, { details: item.details, id: item.id }]);
    });
  };

  const handleReset = () => {
    setSelectedItems([]);
    setSelectedItemsForFetch([]);
  };

  const handleClose = () => {
    setTemplateAddModalOpen(false);
    handleReset();
  };

  const createImageFromDetails = (details: any) => {
    if (details) {
      const tempCanvas = new fabric.Canvas(null, {
        width: 1800,
        height: 2400,
      });

      tempCanvas.loadFromJSON(details, () => {
        const imageDataUrl = tempCanvas.toDataURL({
          format: 'jpeg',
          quality: 0.5,
        });

        const base64String = imageDataUrl.split(',')[1];
        const templateId = Math.random().toString(16).slice(2);

        details.objects.forEach((obj: FabricTypes.Object) => {
          const imageId = getImageId(obj);
          const bgImage = obj.name?.includes('backgroundImage');
          if (obj.name?.includes('infographic')) {
            obj.name = `infographic_template_${templateId}`;
            if (obj.type === 'image' && !bgImage) {
              obj.name = `infographic_template_${imageId}`;
              return;
            }

            if (obj.type === 'image' && bgImage) {
              obj.name = `infographic_template_backgroundImage_${imageId}`;
              return;
            }
            return;
          } else {
            if (obj.type === 'image' && !bgImage) {
              obj.name = `template_${imageId}`;
              return;
            }

            if (obj.type === 'image' && bgImage) {
              obj.name = `template_backgroundImage_${imageId}`;
              return;
            }
          }

          obj.name = `template_${templateId}`;
        });

        const details_name = !details.name?.includes('template')
          ? `${details.name}_template`
          : details.name;

        setSelectedItemsForFetch((prev) => [
          ...prev,
          {
            details: [
              {
                width: details.width,
                height: details.height,
                objects: details.objects,
                version: details.version,
                background: details.background,
                name: details_name,
              },
            ],
            image: base64String as string,
          },
        ]);
      });
    }
  };

  const handleSave = () => {
    selectedItems.forEach((item) => {
      createImageFromDetails(item.details);
    });
  };

  const saveCanvas = (setLoader: (value: boolean) => void) => {
    setLoader(true);
    InfoGraphicRequest.createCanvas(selectedItemsForFetch)
      .then((item) => {
        setRecentTemplateIds({ ids: item.list, type: 'templates' });
        setNotification({
          text: 'Вы сохранили карточку(и) как шаблон(ы)',
        });
      })
      .finally(() => {
        handleClose();
        setLoader(false);
      });
  };

  useEffect(() => {
    if (!isLength && selectedItemsForFetch.length) {
      saveCanvas(setLoading);
    }
    if (selectedItems.length === selectedItemsForFetch.length && selectedItems.length !== 0) {
      saveCanvas(setIsSaving);
    }
  }, [selectedItemsForFetch]);

  return (
    <>
      {isLength && (
        <Modal
          width="806px"
          height="fit-content"
          style={{ minHeight: '316px' }}
          onClose={handleClose}
        >
          <FlexCol>
            <ModalTitle>{t('action.saveAsTemplates')}</ModalTitle>
            <TemplateModalSubTitle>
              Выберите одну или сразу несколько карточек
            </TemplateModalSubTitle>
          </FlexCol>
          <TemplateList>
            {!isLoading
              ? projectItems.map((item, index) => (
                  <TemplateItem
                    key={item.id}
                    onClick={() => handleSelectItem(item)}
                    active={selectedItems.some((selectedItem) => selectedItem.id === item.id)}
                    height={canvasRatio === 'default' ? '155px' : '115px'}
                  >
                    <img src={thumbnails[index] || fallbackImage} />
                  </TemplateItem>
                ))
              : Array.from({ length: 18 }).map((_, index) => (
                  <TemplateItem key={index} height={canvasRatio === 'default' ? '153px' : '115px'}>
                    <SkeletonLoader width="100%" height="100%" />
                  </TemplateItem>
                ))}
          </TemplateList>

          <ModalFooter>
            <Flex alignItems="center" gap={20}>
              <Button
                btnStyle={'cancel'}
                icon={'select-all'}
                style={{ padding: '0' }}
                onClick={handleSelectAll}
                disabled={isLoading}
              >
                {t('action.selectAll')}
              </Button>
              <Button
                btnStyle={'cancel'}
                icon={'reset'}
                style={{ padding: '0' }}
                onClick={handleReset}
                disabled={isLoading || selectedItems.length === 0}
              >
                {t('action.reset')}
              </Button>
            </Flex>
            <Flex alignItems="center" gap={20}>
              <Button btnStyle={'cancel'} onClick={handleClose}>
                {t('action.cancel')}
              </Button>
              <Button
                icon={'check'}
                disabled={isLoading || selectedItems.length === 0 || isSaving}
                isLoading={isSaving}
                onClick={handleSave}
              >
                {t('action.save')}
              </Button>
            </Flex>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
};

export default TemplateAddModal;

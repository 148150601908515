import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import * as amplitude from '@amplitude/analytics-browser';

import { AuthProvider } from './contexts/auth.context';
import { ModalProvider } from './contexts/modal.context';
import { ClipboardProvider } from './contexts/clipboard.context';
import { CanvasHistoryProvider } from './contexts/canvas-history.context';
import { CanvasFocusProvider } from './contexts/canvas-focus.context';
import { NotificationProvider } from './contexts/notification.context';
import Routes from './pages';
import { THEME } from './constants';
import './i18n';
import './index.scss';
import UserProvider from './contexts/user.context';
import { SharedProvider } from './contexts/shared.context';
import { MobileCheckProvider } from './contexts/mobile-check.context';

amplitude.init('05d746277e4e128466a98c26805175b4', undefined);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <BrowserRouter>
    <AuthProvider>
      <UserProvider>
        <ThemeProvider theme={THEME}>
          <NotificationProvider>
            <MobileCheckProvider>
              <SharedProvider>
                <CanvasFocusProvider>
                  <ModalProvider>
                    <ClipboardProvider>
                      <CanvasHistoryProvider>
                        <Routes />
                      </CanvasHistoryProvider>
                    </ClipboardProvider>
                  </ModalProvider>
                </CanvasFocusProvider>
              </SharedProvider>
            </MobileCheckProvider>
          </NotificationProvider>
        </ThemeProvider>
      </UserProvider>
    </AuthProvider>
  </BrowserRouter>,
);

import React from 'react';
import styled, { keyframes, css } from 'styled-components';

import { useModal } from '../contexts/modal.context';
import { IModalOptions } from '../interfaces/modal.interface';

const fade = keyframes`
  0% { opacity: 0 }
  100% { opacity: 1 }
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1110;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  animation: ${fade} 0.25s linear;

  .buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    padding-top: 2rem;

    button {
      white-space: nowrap;
    }
  }
`;

export const ModalHeader = styled.div<{ textAlign?: string }>`
  font-style: normal;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 2rem;
  text-align: ${({ textAlign }) => (textAlign ? textAlign : 'center')};
  color: ${(p) => p.theme.black};
  margin-bottom: 1.59375rem;
`;

const ModalCloseBtn = styled.button.attrs({ type: 'button', className: 'icon-close' })`
  position: absolute;
  top: 0.625rem;
  right: 0.625rem;
  font-size: 1.25rem;
  color: ${(p) => p.theme.black};
  padding: 0;
  background: transparent;
  border: 0;
  cursor: pointer;
  &:hover {
    color: ${(p) => p.theme.primary};
  }
`;

const ModalWrapper = styled.div<{ width?: number; withoutPadding?: boolean }>`
  position: relative;
  background: ${(p) => p.theme.white};
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
  border-radius: 1rem;
  padding: ${p => p.withoutPadding ? 0 : '1.5rem'};
  width: 100%;
  max-width: ${({ width }) => `${width}px` ?? '30%'};
  animation: ${fade} 0.25s linear;

  ${({ theme, withoutPadding }) => withoutPadding && css`
    & > ${ModalCloseBtn} {
      top: -1.25rem;
      right: -1.25rem;
      color: ${theme.white};
      &:hover {
        color: ${theme.lightPrimary};
      }
    }
  `}
`;

const ModalContent = styled.div``;

interface IModalProps {
  children: React.ReactNode;
  options: IModalOptions;
}

export const ModalTemplate = ({ children, options }: IModalProps) => {
  const { header, width, headerAlign, withoutPadding } = options;
  const { close } = useModal();

  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, options);
    }
    return child;
  });

  const handleClose = () => {
    if (options.handleCancel) {
      options.handleCancel();
    }
    close();
  };

  return (
    <ModalOverlay id={'modal-overlay'}>
      <ModalWrapper width={width} withoutPadding={withoutPadding}>
        {header && <ModalHeader textAlign={headerAlign}>{header}</ModalHeader>}
        <ModalCloseBtn onClick={handleClose} id={'modal-close'} />
        <ModalContent>{childrenWithProps}</ModalContent>
      </ModalWrapper>
    </ModalOverlay>
  );
};
